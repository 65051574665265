/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import DashboardIcon from "@material-ui/icons/Dashboard"
import FolderIcon from "@material-ui/icons/FolderOutlined"
import ReceiptIcon from "@material-ui/icons/Receipt"
import { Label, LabelColors } from "components/labels"
import { NavConfigNode } from "components/Navigation"

const DashboardNotificationLabel: React.FunctionComponent<{}> = () => (
  <Label color={LabelColors.Red}>0</Label>
)

const navConfig: Array<NavConfigNode> = [
  {
    subheader: "Management",
    items: [
      {
        title: "Dashboard",
        icon: DashboardIcon,
        href: "/dashboard",
        label: <DashboardNotificationLabel />,
        flag: "enableDashboard",
        dataTestid: "nav-dashboard"
      },
      {
        title: "Service Tickets",
        href: "/tickets/",
        icon: FolderIcon,
        dataTestid: "nav-tickets"
      },
      {
        title: "Sales Orders",
        href: "/sales-orders/",
        icon: ReceiptIcon,
        dataTestid: "nav-sales-orders"
      }
    ]
  }
]

export default navConfig
