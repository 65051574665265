import { useLocation } from "react-router-dom"
import clsx from "clsx"
import { ListItem } from "@material-ui/core"

import { NavLinkButton } from "components"
import { NavLinkNode, stripTrailingSlash } from "./utils"
import useNavBarStyles from "./useNavBarStyles"

export type NavLinkProps = NavLinkNode & { className?: string }

const NavLink: React.FunctionComponent<NavLinkProps> = ({
  title,
  href,
  className = "nav-link",
  icon: Icon,
  disabled,
  label: Label,
  dataTestid
}) => {
  const classes = useNavBarStyles()
  const { pathname } = useLocation()

  const active = pathname.includes(stripTrailingSlash(href))

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      key={title}
      disabled={disabled}
      data-testid={dataTestid}
    >
      <NavLinkButton
        to={href}
        disabled={disabled}
        Icon={Icon}
        title={title}
        // @ts-ignore
        Label={Label}
        active={active}
      />
    </ListItem>
  )
}

export default NavLink
