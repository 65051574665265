import { Dispatch, SetStateAction, useCallback } from "react"
import { useLocalStorage } from "web-api-hooks"

export type DefaultColumnPreferences = Array<string>

export type UseColumnPreferencesArgs<P> = {
  name: string
  defaultPreferences: P
}

export type UseColumnPreferencesResult<P> = {
  preferences: P
  setPreferences: Dispatch<SetStateAction<P>>
  resetPreferences: () => void
}

export default function useColumnPreferences<
  P extends DefaultColumnPreferences = DefaultColumnPreferences
>({
  name,
  defaultPreferences
}: UseColumnPreferencesArgs<P>): UseColumnPreferencesResult<P> {
  const key = `columns:${name}`

  const [preferences, setPreferences] = useLocalStorage(key, defaultPreferences)

  const resetPreferences = useCallback(
    () => setPreferences(defaultPreferences),
    [setPreferences, defaultPreferences]
  )

  return {
    preferences,
    setPreferences,
    resetPreferences
  }
}
