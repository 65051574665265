import { Suspense } from "react"
import { Outlet } from "react-router-dom"
import { LinearProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import withSentryErrorBoundary from "components/Sentry/withSentryErrorBoundary"
import CrashMessage from "./CrashMessage"

interface IContentProps {}

export const useStyles = makeStyles(theme => ({
  container: {
    minHeight: "100vh",
    display: "flex"
  },
  content: {
    flexGrow: 1,
    // Removing maxWidth will break <PerfectScrollbar /> in child components
    maxWidth: "100%"
  }
}))

const Content: React.FunctionComponent<IContentProps> = () => {
  const classes = useStyles()

  return (
    <div className={classes.content}>
      <Suspense fallback={<LinearProgress />}>
        <Outlet />
      </Suspense>
    </div>
  )
}

export default withSentryErrorBoundary(Content, CrashMessage)
