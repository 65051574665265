import CheckIcon from "@material-ui/icons/Check"
import ButtonBase, { IButtonBaseProps, TitanButtonColor } from "../ButtonBase"

export interface IConfirmButtonRedProps
  extends Omit<
    IButtonBaseProps,
    "startIcon" | "endIcon" | "icon" | "color" | "textColor" | "variant"
  > {}

// Button w/ leading checkmark. Used in modal dialogs and to transition tickets.
const ConfirmButtonRed: React.FunctionComponent<IConfirmButtonRedProps> = props => (
  <ButtonBase
    color={TitanButtonColor.Red}
    startIcon={<CheckIcon />}
    variant="contained"
    {...props}
  />
)

export default ConfirmButtonRed
