// Autocomplete options for different data types

import { gql } from "@apollo/client"

import cities from "fragments/cities"
import companies from "fragments/companies"
import counties from "fragments/counties"
import basins from "fragments/basins"
import inventory from "fragments/inventory"
import runningProcedures from "fragments/runningProcedures"
import user from "fragments/user"
import wells from "fragments/wells"

export const GET_CITY_OPTIONS = gql`
  query getCityOptions($first: Int, $filter: CityFilterInput) {
    options: getCities(first: $first, filter: $filter) {
      edges {
        cursor
        node {
          ...CityOption
        }
      }
    }
  }
  ${cities.cityOption}
`

export const GET_COMPANY_OPTIONS = gql`
  query getCompanyOptions($first: Int, $filter: CompanyFilterInput) {
    options: getCompanies(first: $first, filter: $filter) {
      edges {
        cursor
        node {
          ...CompanyOption
        }
      }
    }
  }
  ${companies.companyOption}
`

export const GET_COUNTY_OPTIONS = gql`
  query getCountyOptions($first: Int, $filter: CountyFilterInput) {
    options: getCounties(first: $first, filter: $filter) {
      edges {
        cursor
        node {
          ...CountyOption
        }
      }
    }
  }
  ${counties.countyOption}
`

export const GET_BASIN_OPTIONS = gql`
  query getBasinOptions($first: Int, $filter: BasinFilterInput) {
    options: getBasins(first: $first, filter: $filter) {
      edges {
        cursor
        node {
          ...BasinOption
        }
      }
    }
  }
  ${basins.basinOption}
`

// The service worker looks for the paged properly on the query variables
// to know whether to return data in the pagination format instead of the
// relay-cursor format.
export const GET_PRODUCT_OPTIONS = gql`
  query getProductOptions(
    $paged: PagedInput
    $filter: InventoryItemFilterInput
  ) {
    options: getPagedProducts(paged: $paged, filter: $filter) {
      objects {
        ...BaseInventoryItem
      }
    }
  }
  ${inventory.baseInventoryItem}
`

// TODO: Include filtering once that's implemented on the back-end
export const GET_RUNNING_PROCEDURE_OPTIONS = gql`
  query getRunningProcedureOptions(
    $first: Int
    $filter: RunningProcedureFilterInput
  ) {
    options: getRunningProcedures(first: $first, filter: $filter) {
      edges {
        cursor
        node {
          ...RunningProcedureOption
        }
      }
    }
  }
  ${runningProcedures.runningProcedureOption}
`

export const GET_USER_OPTIONS = gql`
  query getUserOptions($first: Int, $filter: UserFilterInput) {
    options: getUsers(first: $first, filter: $filter) {
      edges {
        cursor
        node {
          ...UserOption
        }
      }
    }
  }
  ${user.userOption}
`

export const GET_WELL_OPTIONS = gql`
  query getWellOptions($first: Int, $filter: WellFilterInput) {
    options: getWells(first: $first, filter: $filter) {
      edges {
        cursor
        node {
          ...WellOption
          ... on Well {
            company {
              ...CompanyOption
            }
          }
        }
      }
    }
  }
  ${wells.wellOption}
  ${companies.companyOption}
`
