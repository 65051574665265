import {
  Grid,
  GridProps,
  InputAdornment,
  TextField,
  Typography
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useFormikContext } from "formik"
import warning from "tiny-warning"
import castArray from "lodash/castArray"
import cloneDeep from "lodash/cloneDeep"

import { DisplayTicket } from "views/TicketDetails/TicketDetails"
import {
  parseInches,
  ParsedRunningProcedureTicketStepResult
} from "hooks/runningProcedures"
import { FieldType } from "client/types"

import { defaultTextFieldProps } from "components/formik/TextField"
import enums from "client/enums"

import { useFormStateContext } from "hooks/forms/useFormState"
import StepNotFound from "./StepNotFound"

export type MeasurementStepProps = {
  index: number
  units: string | Array<string>
  attributes: FieldType | Array<FieldType>
  inputGrid?: GridProps
  instructions?: string | null
  multiline?: boolean
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  constrainedGridItem: {
    maxWidth: 250
  },
  input: {
    "& input": {
      textAlign: "right"
    }
  },
  lockedWrapper: {
    display: "flex"
  },
  lockedDataSection: {
    display: "flex",
    flexDirection: "column",
    "&:not(:first-child)": {
      marginLeft: theme.spacing(2)
    }
  },
  lockedLabel: {
    color: theme.palette.text.primary,
    textTransform: "uppercase"
  },
  lockedData: {
    display: "flex",
    alignItems: "center"
  },
  lockedDatum: {
    fontWeight: 800,
    "&:not(:first-child)": {
      marginLeft: theme.spacing(0.5)
    }
  },
  lockedUnit: {
    marginLeft: theme.spacing(0.5)
  },
  lockedNoData: {
    color: theme.palette.text.secondary
  }
}))

// TODO: Once we get a better idea of which attributes are always full-width,
// enhance the layout
const nonConstrainedAttributes = [FieldType.Text]

export const dataTestid = "measurement-step"
export const invalidInchesDenominatorMessage =
  "The denominator must be a power of 2"
export const invalidInchesFormatMessage = "Inches must have a valid format"
const MeasurementStep: React.FunctionComponent<MeasurementStepProps> = ({
  index,
  instructions,
  attributes: _attributes,
  units: _units,
  inputGrid = { xs: 12, md: 6 } as GridProps,
  multiline = false
}) => {
  const classes = useStyles()
  const formik = useFormikContext<Partial<DisplayTicket>>()
  const step = formik.values?.steps?.[
    index
  ] as ParsedRunningProcedureTicketStepResult

  const { getMetadata } = useFormStateContext()

  // If the running procedure step order is broken, there might be no step or no
  // parsedData. If so, display the step not found message instead of crashing.
  // parsedData should always be an object, so no need to worry about expected
  // falsey values from short-circuiting the display.
  if (!step?.parsedData) return <StepNotFound index={index} />

  const { isSubmitting } = formik
  const {
    isDocumentationLocked = false,
    isMutationLoading = false
  } = getMetadata()

  const units = castArray(_units)
  const attributes = castArray(_attributes)
  warning(
    units.length === attributes.length,
    "The number of attributes should match the number of units"
  )

  const multilineProps = multiline ? { multiline: true, rows: 3 } : {}

  // Due to the nested nature of the data, we're manually determining/displaying
  // error messages and sending any changes in values to formik via setFieldValue.
  const parsedData = cloneDeep(step.parsedData)
  const fieldType = step.runningProcedureStep.fieldType

  let errors: Record<string, string> = attributes.reduce((acc, attribute) => {
    acc[attribute] = ""
    return acc
  }, {})
  switch (fieldType) {
    case FieldType.Length:
      const inches = (parsedData?.[FieldType.Length]?.data ?? "") as string
      const [_inches, , _denominator] = parseInches(inches)
      const isInchesValid = "" === inches || !isNaN(_inches)
      // If inches is not NaN and the denominator is NaN, the input is valid
      // because there is no fraction.
      // If inches is NaN, the denominator will also be NaN because the the
      // input is invalid.
      // Therefore, consider a denominator of NaN to be valid because the
      // value of isInchesValid will determine the error state
      const isDenominatorValid = isNaN(_denominator)
        ? true
        : Number.isInteger(Math.log2(_denominator)) && _denominator <= 128

      errors[FieldType.Length] = isInchesValid
        ? isDenominatorValid
          ? ""
          : invalidInchesDenominatorMessage
        : invalidInchesFormatMessage
      break
    // Example for multi-attribute field error processing
    // case FieldType.SizeWeightGrade:
    //   errors = attributes.reduce((acc, attribute, index) => {
    //     acc[attribute] = `Test Error ${index}`
    //     return acc
    //   }, {})
    //   break
    default:
  }

  const content = isDocumentationLocked ? (
    <Grid className={classes.lockedWrapper} item xs={12}>
      {units.map((unit, unitIndex) => {
        return (
          <div
            className={classes.lockedDataSection}
            key={attributes[unitIndex]}
          >
            <Typography variant="caption" className={classes.lockedLabel}>
              {enums.FieldType.description(attributes[unitIndex])}
            </Typography>
            {Boolean(parsedData?.[attributes[unitIndex]]?.data) ? (
              <div className={classes.lockedData}>
                <Typography
                  className={classes.lockedDatum}
                  variant="body1"
                  component="span"
                >
                  {parsedData?.[attributes[unitIndex]]?.data}
                </Typography>
                {Boolean(unit) && (
                  <Typography
                    className={classes.lockedUnit}
                    variant="body2"
                    component="span"
                  >
                    {unit}
                  </Typography>
                )}
              </div>
            ) : (
              <Typography variant="body1" className={classes.lockedNoData}>
                No Data
              </Typography>
            )}
          </div>
        )
      })}
    </Grid>
  ) : (
    units.map((unit, unitIndex) => {
      const constrained = !nonConstrainedAttributes.includes(
        attributes[unitIndex]
      )
      const errorMessage = errors[attributes[unitIndex]]

      return (
        <Grid
          className={constrained ? classes.constrainedGridItem : undefined}
          key={attributes[unitIndex]}
          item
          {...inputGrid}
        >
          <TextField
            className={classes.input}
            id={`${index}-${attributes[unitIndex]}`}
            value={parsedData?.[attributes[unitIndex]]?.data ?? ""}
            label={enums.FieldType.description(attributes[unitIndex])}
            {...defaultTextFieldProps}
            margin="dense"
            InputProps={{
              endAdornment:
                "" !== unit ? (
                  <InputAdornment position="end">
                    <Typography variant="caption">{unit}</Typography>
                  </InputAdornment>
                ) : undefined
            }}
            {...multilineProps}
            error={Boolean(errorMessage)}
            helperText={errorMessage}
            disabled={isSubmitting || (isMutationLoading as boolean)}
            onChange={evt => {
              formik.setFieldValue(
                `steps[${index}].parsedData.${attributes[unitIndex]}.data`,
                evt.target.value
              )
            }}
          />
        </Grid>
      )
    })
  )

  return (
    <Grid
      className={classes.root}
      container
      spacing={2}
      data-testid={dataTestid}
    >
      <Grid item xs={12}>
        {instructions}
      </Grid>
      {content}
    </Grid>
  )
}

export default MeasurementStep
