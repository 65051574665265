import ButtonBase, { IButtonBaseProps } from "../ButtonBase"

export interface IButtonProps
  extends Omit<IButtonBaseProps, "color" | "icon" | "textColor" | "variant"> {}

// App-wide Standard Button with blue text
const Button: React.FunctionComponent<IButtonProps> = props => {
  return <ButtonBase {...props} />
}

export default Button
