import { Fragment } from "react"
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography
} from "@material-ui/core"
import Grid, { GridProps } from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import FormInput from "../FormInput/FormInput"
import { FormInputProps } from "../FormInput/FormInput.d"
import { shouldDisplay as _shouldDisplay } from "../utils/utils"
import { FormSectionKinds, FormSectionProps } from "./FormSection.d"

export const defaultContentGridOptions: GridProps = {
  spacing: 2,
  container: true
}

const useStyles = makeStyles(theme => ({
  card: {},
  fullHeight: {
    height: "100%"
  },
  cardHeader: {
    "& .MuiCardHeader-content": {
      display: `flex`,
      justifyContent: `space-between`,
      alignItems: `center`
    }
  },
  flatHeader: {
    marginBottom: theme.spacing(2)
  }
}))

type FieldComponentsProps = {
  fields: FormInputProps[]
  shouldDisplay: (String) => Boolean
  WrappingComponent: React.ComponentType<any>
  wrapProps: any
}

const FieldComponents: React.ComponentType<FieldComponentsProps> = ({
  fields,
  shouldDisplay,
  WrappingComponent,
  wrapProps
}) => (
  <WrappingComponent {...wrapProps}>
    {fields.map((field, index) =>
      shouldDisplay(field.displayWhen) ? (
        <FormInput key={`${field.name}-${index}`} {...field} />
      ) : null
    )}
  </WrappingComponent>
)

const FormSection: React.ComponentType<FormSectionProps> = props => {
  const classes = useStyles()

  const {
    // @ts-ignore
    contentGrid = false,
    header,
    headerTypographyProps,
    subheader,
    subheaderTypographyProps,
    kind,
    formik
  } = props

  const shouldDisplay = _shouldDisplay({ formik })

  const contentGridOptions: GridProps =
    `object` === typeof contentGrid
      ? { ...defaultContentGridOptions, ...(contentGrid as object) }
      : { ...defaultContentGridOptions }

  switch (props.kind) {
    case FormSectionKinds.Custom:
      const { Component, componentProps } = props
      return (
        <Component
          header={header}
          headerTypographyProps={headerTypographyProps}
          subheader={subheader}
          subheaderTypographyProps={subheaderTypographyProps}
          {...componentProps}
        />
      )
    case FormSectionKinds.Card:
      const fullHeight = props.fullHeight ?? true
      return (
        <Card
          className={clsx(classes.card, fullHeight && classes.fullHeight)}
          {...props.cardProps}
        >
          <CardHeader
            className={classes.cardHeader}
            title={header ?? ""}
            titleTypographyProps={headerTypographyProps}
            subheader={subheader}
            subheaderTypographyProps={
              subheaderTypographyProps ?? {
                variant: `body1`,
                color: `textSecondary`
              }
            }
            {...(props.cardHeaderProps ?? {})}
          />
          {(props.showHeaderDivider ?? true) && <Divider />}
          <CardContent {...props.cardContentProps}>
            <FieldComponents
              fields={props.fields}
              WrappingComponent={contentGrid ? Grid : Fragment}
              wrapProps={contentGrid ? contentGridOptions : {}}
              shouldDisplay={shouldDisplay}
            />
          </CardContent>
        </Card>
      )
    case FormSectionKinds.Flat:
      return (
        <>
          {(Boolean(header) || Boolean(subheader)) && (
            <Grid
              item
              container
              xs={12}
              justifyContent="space-between"
              alignItems="baseline"
              className={clsx(
                classes.flatHeader,
                "flat-section__header-wrapper"
              )}
              component="header"
            >
              <Typography
                className="flat-section__header"
                {...(headerTypographyProps ?? { variant: "h4" })}
              >
                {header}
              </Typography>
              {subheader && (
                <Typography
                  className="flat-section__subheader"
                  {...(subheaderTypographyProps ?? { variant: "body1" })}
                >
                  {subheader}
                </Typography>
              )}
            </Grid>
          )}
          <FieldComponents
            fields={props.fields}
            WrappingComponent={contentGrid ? Grid : Fragment}
            wrapProps={contentGrid ? contentGridOptions : {}}
            shouldDisplay={shouldDisplay}
          />
        </>
      )
    default:
      console.error(
        `A FormSection with an unknown kind made it past TypeScript: ${kind}`
      )
      return null
  }
}

export default FormSection
