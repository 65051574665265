import { InputAdornment } from "@material-ui/core"
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
import {
  DateTimePicker as MuiDateTimePicker,
  DateTimePickerProps as MuiDateTimePickerProps
} from "@material-ui/pickers"
import { Label, LabelColors } from "components"
import TimezoneContext from "context/TimezoneContext"
import { FieldProps, getIn } from "formik"
import { useFormStateContext } from "hooks/forms/useFormState"
import compact from "lodash/compact"
import { useContext, useMemo } from "react"
import withProps from "recompose/withProps"
import ClearAdornment from "./ClearAdornment"
import { DateTimePickerToolbar } from "./DateTimePickerToolbar"
import usePickerStyles from "./usePickerStyles"

export type DateTimePickerProps = FieldProps &
  Omit<MuiDateTimePickerProps, "error" | "name" | "onChange" | "value"> & {
    clearable?: boolean
  }

export const DateTimeField: React.ComponentType<DateTimePickerProps> = ({
  children,
  ...props
}: DateTimePickerProps) => {
  const classes = usePickerStyles()

  const { getTimezoneDisplay } = useContext(TimezoneContext)

  const {
    field,
    form,
    disabled,
    onBlur: originalOnBlur,
    clearable = false,
    ...otherProps
  } = props

  const { name } = field
  const {
    touched,
    errors,
    isSubmitting,
    setFieldError,
    setFieldTouched,
    setFieldValue
  } = form
  const {
    clearError,
    getError,
    _default: noFormStateContext
  } = useFormStateContext()

  // Merging back-end errors at the component level to allow forms to be resubmitted
  // even if back-end errors haven't been cleared.
  const fieldError = getIn(errors, name)
  const backEndError = getError(name)
  const showError = (getIn(touched, name) && !!fieldError) || !!backEndError
  const errorText = compact([fieldError, backEndError]).join("\n")

  // If an object field is null because there is no data at all for it, the first time
  // data is set on one of the subfields, the remaining subfields will become undefined.
  // A value of undefined causes the DateTime picker to show the current date and time.
  // So we convert undefineds back to null.
  if (field.value === undefined) field.value = null

  const onBlur = !noFormStateContext
    ? undefined === originalOnBlur
      ? _ => {
          clearError(name)
        }
      : evt => {
          clearError(name)
          originalOnBlur(evt)
        }
    : originalOnBlur

  const clearIcon = useMemo(() => {
    if (!clearable) return null
    if (null === field.value) return null
    return (
      <ClearAdornment
        onClick={evt => {
          evt.stopPropagation()
          setFieldValue(name, null)
        }}
      />
    )
  }, [clearable, field.value, name, setFieldValue])

  const { value } = field

  const tzName = useMemo(() => getTimezoneDisplay(value), [
    value,
    getTimezoneDisplay
  ])

  const BaseInputProps = {
    endAdornment: (
      <>
        {tzName !== null ? (
          <Label color={LabelColors.Black} style={{ fontWeight: "bold" }}>
            {tzName}
          </Label>
        ) : null}
        <InputAdornment position="end">
          <CalendarTodayIcon />
        </InputAdornment>
      </>
    )
  }

  const pickerProps: MuiDateTimePickerProps = {
    ...otherProps,
    ...field,
    InputProps: {
      ...BaseInputProps,
      startAdornment: clearIcon
    },
    ToolbarComponent: DateTimePickerToolbar,
    DialogProps: {
      className: classes.dialog
    },
    error: showError,
    helperText: showError ? errorText : props.helperText,
    disabled: disabled !== undefined ? disabled : isSubmitting,
    onBlur,
    onChange(date) {
      // formik doesn't seem to be automatically setting touched
      setFieldTouched(name)
      setFieldValue(name, date)
    },
    onError(error) {
      // It appears this callback is called w/ an empty string when there are no
      // errors. Prevent "" from being set as an error.
      if (Boolean(error) && error !== fieldError) {
        setFieldError(name, String(error))
      }
    }
  }

  return <MuiDateTimePicker {...pickerProps}>{children}</MuiDateTimePicker>
}

DateTimeField.displayName = "FormikMaterialUIDateTimePicker"

// DateTime style customized
const dateInputProps = withProps({
  fullWidth: true,
  inputVariant: "outlined",
  format: "MM/DD/YY hh:mma"
})

// @ts-ignore
export const StyledDateTimeField = dateInputProps(DateTimeField)
export default StyledDateTimeField
