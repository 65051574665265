import { Fragment } from "react"
import {
  GetWellOptionsQuery,
  GetWellOptionsQueryVariables,
  WellOptionFragment
} from "client/types"
import { GET_WELL_OPTIONS } from "queries/options"
import {
  QueryAutocomplete,
  QueryAutocompleteProps
} from "components/autocomplete/QueryAutoComplete"
import { Typography, Chip } from "@material-ui/core"

type WellOptionType = WellOptionFragment | null | undefined

const Label = ({ option }) => (
  <Typography noWrap component="div">
    {option.name}
    {option.company ? (
      <Fragment>
        &nbsp;
        <Chip
          color="secondary"
          size="small"
          label={`${option.company.name} - ${option.city?.name}`}
        />
      </Fragment>
    ) : null}
  </Typography>
)

export function WellAutocomplete({
  ...props
}): React.ReactElement<QueryAutocompleteProps<WellOptionType>> {
  return (
    <QueryAutocomplete<
      GetWellOptionsQuery,
      GetWellOptionsQueryVariables,
      WellOptionType
    >
      {...props}
      query={GET_WELL_OPTIONS}
      getOptionSelected={(option, value) => option.id === value.id}
      renderOption={option => <Label option={option} />}
      getOptionLabel={option => option.name || ""}
    />
  )
}

export default WellAutocomplete
