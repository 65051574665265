import { makeStyles } from "@material-ui/core/styles"
import * as colors from "theme/palettes/colors"

// Reset theme overrides
const usePickerStyles = makeStyles(theme => ({
  dialog: {
    "& button": {
      backgroundColor: "transparent",
      boxShadow: "none"
    },
    "& .MuiToolbar-root button:hover": {
      backgroundColor: colors.indigo[500]
    },
    "& .MuiTabs-flexContainer button:hover": {
      backgroundColor: colors.indigo[500]
    },
    "& button.MuiPickersDay-daySelected": {
      color: colors.white,
      fontWeight: 500,
      backgroundColor: theme.palette.primary.main
    }
  }
}))

export default usePickerStyles
