import { useEffect, useState } from "react"
import { FastField, useFormikContext } from "formik"
import AutocompleteField from "components/formik/AutocompleteField"
import WellAutocomplete from "components/autocomplete/WellAutocomplete"
import findIndex from "lodash/findIndex"
import slice from "lodash/slice"

import { Well } from "client/types"

import { CreateTicketData, createNewWellOption } from "../utils"

interface ICreateTicketWellInputProps {
  id?: string
  label: string
  name: string
}

// If you're looking for git history, this code was extracted from CreateTicketForm.tsx
const CreateTicketWellInput: React.FunctionComponent<ICreateTicketWellInputProps> = ({
  id,
  label,
  name
}) => {
  const formik = useFormikContext<CreateTicketData>()

  // When the component first loads, grab the createWell values so that we can reset
  // them to a state formik recognizes as non-dirty. We can't use the default create
  // well values, because they may have been overridden with arguments passed to
  // useTicketCreate. We only ever want this to run once, so disabling the dependency
  // linter warning in lieu of restructuring the hook/injecting the initial values into
  // formik.
  const [initialWellValues, setInitialWellValues] = useState<any>({})
  useEffect(() => {
    setInitialWellValues(formik.values.createWell)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FastField
      name={name}
      label={label}
      id={id}
      component={AutocompleteField}
      AutocompleteComponent={WellAutocomplete}
      size="medium"
      onChange={(_, value) => {
        // Handle Create New Well
        if (value && value.id === createNewWellOption.id) {
          // Set the well value so that <QueryAutoComplete />'s required
          // check finds a value
          formik.setFieldValue("well", value)
          formik.setFieldValue("company", null)
          // We're setting both so that deleting the name in the create well card
          // doesn't make the card disappear...
          formik.setFieldValue("createNewWell", true)
          formik.setFieldValue("createWell.name", value.wellName)
        } else {
          formik.setFieldValue("well", value)
          formik.setFieldValue("company", value?.company)
          // ...instead, we wait for the value in the autocomplete to be deleted
          formik.setFieldValue("createNewWell", false)
          formik.setFieldValue("createWell", initialWellValues)
        }
      }}
      createOption={createNewWellOption}
      filterOptions={(options, params) => {
        // WellInput passes the create new well option down to <QueryAutoComplete />,
        // so it's also responsible for determining whether to show it. The create option
        // should always be last in the array, so there's no need to search for it.
        const wellName = params.inputValue

        // If someone types in "Create New Well" as the well name, the match on the
        // createOption doesn't count.
        const exactNameIndex = findIndex(
          options,
          (option: Well) =>
            option.name === params.inputValue &&
            option.id !== createNewWellOption.id
        )

        if (exactNameIndex > -1 || "" === wellName) {
          return slice(options, 0, options.length - 1)
        } else {
          // Add text input to the createOption so that onChange can find it
          options[options.length - 1] = {
            ...options[options.length - 1],
            wellName: params.inputValue
          }
          return options
        }
      }}
    />
  )
}

export default CreateTicketWellInput
