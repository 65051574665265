import * as prettyEnums from "client/prettyEnums"
import {
  ActionFlags,
  AssignmentTypes,
  ChangeOutcome,
  ChargePriceState,
  ChargeState,
  ErpSalesOrderStatuses,
  EventAssociation,
  FieldType,
  NumberFormats,
  SalesOrderItemTypes,
  SalesOrderStatuses,
  ShopLocations,
  SignatureRequestTypes,
  SignatureResponseTypes,
  SignatureStates,
  TicketPhases,
  TicketStatuses,
  TicketTypes,
  TransitionDirection
} from "client/types"
import { ITabItem } from "components/ActionTabs"
import { SelectOption } from "components/formik/SelectField"
import { Dictionary } from "lodash"
import get from "lodash/get"
import map from "lodash/map"
import mapValues from "lodash/mapValues"
import reduce from "lodash/reduce"
import { is } from "ts-type-guards"

export const types = {
  ActionFlags,
  AssignmentTypes,
  ChangeOutcome,
  ChargePriceState,
  ChargeState,
  ErpSalesOrderStatuses,
  EventAssociation,
  FieldType,
  NumberFormats,
  SalesOrderItemTypes,
  SalesOrderStatuses,
  ShopLocations,
  SignatureResponseTypes,
  SignatureRequestTypes,
  SignatureStates,
  TicketPhases,
  TicketStatuses,
  TicketTypes,
  TransitionDirection,
}

// TODO: write a new codegen plugin that gets enum descriptions
// instead of using the client enum types key names
export type EnumAccess<T extends string | number> = {
  values: Array<T>
  options: Array<SelectOption>
  tabs: Array<ITabItem>
  description: (value: T) => string | null
}

export const createEnumAccess = (
  ref: Dictionary<string | number>,
  enumKey: string
): EnumAccess<string | number> => {
  const values: Array<string | number> = map(ref)

  const descriptions = reduce(
    ref,
    (acc, value, key) => ({
      ...acc,
      [value]: prettyEnums[`Pretty${enumKey}`][key]
    }),
    {}
  )
  const options: Array<SelectOption> = map(values, value => ({
    value: String(value),
    description: descriptions[value]
  }))

  const tabs: Array<ITabItem> = map(values, value => ({
    value,
    label: descriptions[value]
  }))

  const description = (value: any): string | null =>
    get(descriptions, value, null)

  return {
    values,
    options,
    tabs,
    description
  }
}

interface ObjectWithValueKey {
  value: any
  [key: string]: any
}

export const filterEnumAccess: (
  base: EnumAccess<string | number>
) => (
  allowedValues: Array<string | number | null>
) => EnumAccess<string | number> = base => allowedValues => {
  // This function assumes that the only properties that need to be filtered
  // are arrays, and that all arrays assigned to properties other than `values`
  // contain objects with a `value` key

  return reduce(
    base,
    (acc, value, key) => {
      if (is(Array)(value) && `values` === key) {
        const newValue = (value as Array<string | number | null>).filter(el =>
          allowedValues.includes(el)
        )
        return { ...acc, [key]: newValue }
      }
      if (is(Array)(value) && `values` !== key) {
        const newValue = (value as Array<ObjectWithValueKey>).filter(el =>
          allowedValues.includes(el.value)
        )
        return { ...acc, [key]: newValue }
      }
      return { ...acc, [key]: value }
    },
    {}
  ) as EnumAccess<string | number>
}

// @ts-ignore - lodash's type definitions seem to be off
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/31265
const enums = mapValues(types, (type, key) =>
  createEnumAccess(type, key)
) as Dictionary<EnumAccess<string | number>>

export default enums
