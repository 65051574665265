import { useRef } from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/styles"
import { Paper, Input, Theme, InputProps } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import { useDebouncedCallback } from "use-debounce/lib"
import { useFormikContext } from "formik"

import { TicketFilterInput } from "client/types"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  search: {
    flexGrow: 1,
    height: 42,
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center"
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    // @ts-ignore
    color: theme.palette.icon
  },
  searchInput: {
    flexGrow: 1
  }
}))

type SearchProps = {
  onSearch: (...args: any[]) => any
  className: string
  inputProps?: InputProps
  searchFilterKey?: string
}

const Search: React.FunctionComponent<SearchProps> = ({
  onSearch,
  className,
  inputProps,
  searchFilterKey = "search",
  ...rest
}) => {
  const searchInputRef = useRef<null | HTMLDivElement>(null)
  const classes = useStyles()
  const formik = useFormikContext<TicketFilterInput>()

  const doSearch = useDebouncedCallback(onSearch, 300)
  const focusInput = e => {
    searchInputRef.current?.focus()
  }

  const onSearchChange = e => {
    const value = e.target.value
    formik?.setFieldValue(searchFilterKey, value)
    doSearch(value)
  }

  // TODO: focus all clicks on paper element into search input

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Paper className={classes.search} elevation={1} onClick={focusInput}>
        <SearchIcon className={classes.searchIcon} />
        <Input
          className={classes.searchInput}
          value={formik?.values?.[searchFilterKey] ?? ""}
          onChange={onSearchChange}
          disableUnderline
          placeholder="Search"
          inputRef={searchInputRef}
          {...inputProps}
        />
      </Paper>
    </div>
  )
}

export default Search
