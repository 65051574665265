import { client } from "client"
import {
  AppState,
  AppVars,
  AppStateInput,
  GetAppStateQuery
} from "client/types"
import isEmpty from "lodash/isEmpty"
import { GET_APP_STATE } from "queries/appState"

export type AppStateReturn = {
  state: AppState
  vars: AppVars
}

export default function appState(input?: AppStateInput): AppStateReturn {
  // @ts-ignore
  const { appState: state, appVars: vars } = client.readQuery<GetAppStateQuery>(
    { query: GET_APP_STATE }
  )
  if (!isEmpty(input)) {
    const newState = { ...state, ...input }
    client.writeQuery({
      query: GET_APP_STATE,
      data: { appState: newState }
    })
    return newState
  }
  return { state, vars }
}
