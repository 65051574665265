import { useEffect, useRef } from "react"
import clsx from "clsx"
import {
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
  Typography
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import CheckIcon from "@material-ui/icons/Check"
import ClearIcon from "@material-ui/icons/Clear"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined"
import SaveIcon from "@material-ui/icons/Save"
import { grey } from "@material-ui/core/colors"
import moment from "moment"
import cond from "lodash/cond"

import { FileUploadStatus } from "client/types"
import { stripMetaTags } from "../utils"

import { DetailsCardProps, listThumbnailSize } from "./DetailsCard"
export type ThumbnailCardProps = DetailsCardProps

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    marginTop: 20,
    marginRight: 20
  },
  rootNoDeleteButton: {
    marginRight: theme.spacing(1)
  },
  thumbnail: {
    position: "relative",
    margin: 0,
    width: listThumbnailSize,
    minWidth: listThumbnailSize,
    height: listThumbnailSize,
    overflow: "hidden",
    borderRadius: theme.spacing(0.5),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  thumbnailImageWrapper: {
    display: "block",
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%"
  },
  thumbnailImage: {
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%"
  },
  thumbnailAttached: {
    cursor: "pointer"
  },
  thumbnailNeutral: {
    backgroundColor: grey[100],
    color: theme.palette.text.secondary
  },
  thumbnailError: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  thumbnailSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText
  },
  localSaveIcon: {
    position: "absolute",
    right: -2,
    bottom: -4,
    borderTopLeftRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary
  },
  progressBar: {
    marginTop: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  deleteButton: {
    borderRadius: "50%",
    position: "absolute",
    top: -15,
    right: -15,
    "& .MuiIconButton-root": {
      padding: 3
    }
  }
}))

export const dataTestid = "thumbnail-card"
export const deleteButtonTestid = "delete-button"
const backgrounds: Record<string, Array<FileUploadStatus>> = {
  success: [FileUploadStatus.Uploaded],
  neutral: [
    FileUploadStatus.Attached,
    FileUploadStatus.AttachedOffline,
    FileUploadStatus.Uploading,
    FileUploadStatus.UploadPending
  ],
  error: [
    FileUploadStatus.UploadError,
    FileUploadStatus.DropError,
    FileUploadStatus.RemovalError
  ]
}

const ThumbnailCard: React.FunctionComponent<DetailsCardProps> = ({
  id = "",
  uuid = "",
  fullThumbnail = "",
  smallThumbnail = "",
  thumbnail = "",
  original = "",
  label = "",
  size = 0,
  created = "",
  status = FileUploadStatus.UploadError,
  disablePreviewDelete = false,
  onDelete = () => {},
  onImgClick = () => {}
}) => {
  const classes = useStyles()
  const thumbnailRef = useRef<HTMLDivElement | null>(null)

  // Typescript doesn't like defining evt as Event -- the types are more complicated
  const handleDelete = evt => {
    onDelete(uuid, evt)
  }

  const handleImgClick = evt => {
    onImgClick(uuid, evt)
  }

  useEffect(() => {
    // On iPad Safari, there's a visual bug where the image thumbnail that's shown
    // is incomplete. The issue fixes itself on the next rerender, which makes the
    // bug seem like a browser painting issue, which we don't have any control over.
    // To fix this, once the status changes to UploadPending, we force a repaint.
    if (FileUploadStatus.UploadPending === status) {
      if (null !== thumbnailRef.current) {
        thumbnailRef.current.style.display = "none"
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        thumbnailRef.current.offsetHeight
        thumbnailRef.current.style.display = "block"
      }
    }
  }, [status])

  const tooltipText =
    status === FileUploadStatus.Attached
      ? `Uploaded by UNKNOWN on
      ${moment.utc(created).local().format(`L (h:mm A)`)}`
      : ""

  let icon
  switch (status) {
    case FileUploadStatus.Attached:
    case FileUploadStatus.AttachedOffline:
      icon = smallThumbnail ? null : (
        <InsertDriveFileOutlinedIcon
          data-icon="attached"
          fontSize="large"
          className="upload"
        />
      )
      break
    case FileUploadStatus.Uploading:
      icon = <CircularProgress size={26} />
      break
    case FileUploadStatus.UploadPending:
      icon = smallThumbnail ? null : (
        <>
          <SaveIcon data-icon="stored" fontSize="large" />{" "}
          <Typography variant="h5" color="inherit">
            <strong>OFFLINE</strong>
          </Typography>
        </>
      )
      break
    case FileUploadStatus.Uploaded:
      icon = <CheckIcon data-icon="uploaded" fontSize="large" />
      break
    case FileUploadStatus.UploadError:
    case FileUploadStatus.DropError:
    case FileUploadStatus.RemovalError:
      icon = <ErrorOutlineIcon data-icon="error" fontSize="large" />
      break
  }

  const content = cond<
    { status: FileUploadStatus | null; hasSmallThumbnail: boolean },
    JSX.Element | null
  >([
    [
      ({ status, hasSmallThumbnail }) =>
        FileUploadStatus.Attached === status && hasSmallThumbnail,
      () => (
        <a
          className={classes.thumbnailImageWrapper}
          // Use the full thumbnail first because the original link will return
          // an HTTP header that forces a file download
          href={fullThumbnail ?? original ?? ""}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={classes.thumbnailImage}
            src={
              status === FileUploadStatus.Attached ? smallThumbnail ?? "" : ""
            }
            alt={stripMetaTags(label ?? "")}
          />
        </a>
      )
    ],
    [
      ({ status, hasSmallThumbnail }) =>
        FileUploadStatus.UploadPending === status && hasSmallThumbnail,
      () => (
        <>
          <img
            className={classes.thumbnailImage}
            src={smallThumbnail as string}
            alt={`Thumbnail for ${stripMetaTags(label ?? "")}`}
          />
          <div className={classes.localSaveIcon}>
            <SaveIcon data-icon="stored" />
          </div>
        </>
      )
    ],
    [
      ({ status, hasSmallThumbnail }) =>
        FileUploadStatus.AttachedOffline === status && hasSmallThumbnail,
      () => (
        <img
          className={classes.thumbnailImage}
          src={smallThumbnail as string}
          alt={`Thumbnail for ${stripMetaTags(label ?? "")}`}
        />
      )
    ],
    [() => true, () => icon]
  ])({ status, hasSmallThumbnail: Boolean(smallThumbnail) })

  return (
    <div
      className={clsx({
        [classes.root]: !disablePreviewDelete,
        [classes.rootNoDeleteButton]: disablePreviewDelete
      })}
    >
      <Tooltip title={tooltipText} placement="top" arrow>
        <div
          ref={thumbnailRef}
          className={clsx(
            classes.thumbnail,
            {
              [classes.thumbnailSuccess]:
                null !== status ? backgrounds.success.includes(status) : false
            },
            {
              [classes.thumbnailNeutral]:
                null !== status ? backgrounds.neutral.includes(status) : false
            },
            {
              [classes.thumbnailError]:
                null !== status ? backgrounds.error.includes(status) : false
            },
            {
              [classes.thumbnailAttached]: status === FileUploadStatus.Attached
            }
          )}
          onClick={handleImgClick}
          data-testid={dataTestid}
          data-status={status}
        >
          {content}
        </div>
      </Tooltip>
      {status !== FileUploadStatus.Uploading && !disablePreviewDelete && (
        <Paper className={classes.deleteButton} elevation={0}>
          <IconButton onClick={handleDelete} data-testid={deleteButtonTestid}>
            <ClearIcon />
          </IconButton>
        </Paper>
      )}
    </div>
  )
}

export default ThumbnailCard
