import { setContext } from "@apollo/client/link/context"
import appState from "client/appState"

const appStateContextLink = setContext((request, previousContext) => {
  const {
    state: { online },
    vars: { authorization, isUpSyncing }
  } = appState()

  return {
    ...previousContext,
    appState: { authorization, isUpSyncing, online }
  }
})

export default appStateContextLink
