import * as React from "react"
import IMask from "imask"
import MaskedField from "./MaskedField"

const PhoneNumberField: React.FunctionComponent<any> = props => {
  const maskProps = {
    mask: IMask.createMask({
      mask: "(000) 000-0000"
    })
  }

  return <MaskedField {...props} {...maskProps} value={props.field.value} />
}

export default PhoneNumberField
