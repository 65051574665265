import { makeVar } from "@apollo/client"
import { AuthorizationType } from "client/types"
export enum AppEnvironments {
  Local = "Local Environment",
  Staging = "Staging Environment",
  Production = "Production Environment",
  Unknown = ""
}

// Store any additional application variables that don't need persisted here.
export const appVars = makeVar({
  authorization: AuthorizationType.None,
  env: AppEnvironments.Unknown,
  isUpSyncing: false,
  updateAvailable: false
})

export const updateAppVars = (newVars: any) => {
  return appVars({ ...appVars(), ...newVars })
}
