import { Dispatch, SetStateAction, useState } from "react"
import get from "lodash/get"

export interface SelectionUtils {
  handleSelectAll: (event: any) => void
  handleSelectOne: (event: any, id: string | number, toggle: boolean) => void
  handleDeselectOne: (event: any, id: string | number) => void
  checkSelected: (id: string | number) => boolean
  selected: any[]
  setSelected: Dispatch<SetStateAction<any>>
}

function useSelection(
  selection: any[],
  key: string = "node.id",
  preSelected: any[] = []
) {
  let [selected, setSelected] = useState(preSelected) as any[]

  const handleSelectAll = event => {
    const selected = event.target.checked
      ? selection.map(item => get(item, key))
      : []

    setSelected(selected)
  }

  const handleSelectOne = (event, id, toggle = true) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (toggle && selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (toggle && selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (toggle && selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    } else {
      newSelected = selected
    }
    setSelected(newSelected)
  }

  // This only deselects, so no toggle
  const handleDeselectOne = (event, id) => {
    let newSelected = selected.filter(el => el !== id)
    setSelected(newSelected)
  }

  return {
    handleSelectAll,
    handleSelectOne,
    handleDeselectOne,
    checkSelected(id) {
      return selected.indexOf(id) !== -1
    },
    selected,
    setSelected
  } as SelectionUtils
}

export default useSelection
