import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

interface IStepNotFound {
  index: number
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  }
}))

export const dataTestid = "step-not-found"
const StepNotFound: React.FunctionComponent<IStepNotFound> = ({ index }) => {
  const classes = useStyles()

  return (
    <Grid
      className={classes.root}
      container
      spacing={2}
      data-testid={dataTestid}
    >
      <Grid item xs={12}>
        Error: Step Not Found
      </Grid>
      <Grid item xs={12}>
        The running procedure step with order {index} was not found. This
        usually means the running procedure is malformed. Please contact
        support.
      </Grid>
    </Grid>
  )
}

export default StepNotFound
