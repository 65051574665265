import {
  CardProps,
  GridProps,
  TypographyProps,
  CardContentProps,
  CardHeaderProps
} from "@material-ui/core"
import { FormInputProps, DisplayWhen } from "../FormInput/FormInput.d"
import { FormikContextType } from "formik"

export enum FormSectionKinds {
  // A section displayed in a card -- currently for forms on the base page
  Card = "Card",
  // A section not display in a card -- currently for forms on modals
  Flat = "Flat",
  // A section with completely custom display -- like well details
  Custom = "Custom"
  // A section for collapsible filter sections?
}

// export type FormSectionGridOptions = GridProps

export type FormSectionGridOptions = Omit<GridProps, "container" | "item">

export type FormContentGridOptions = Omit<GridProps, "container" | "item">

export interface BaseSectionProps {
  kind: FormSectionKinds
  grid?: boolean | FormSectionGridOptions
  contentGrid?: boolean | FormContentGridOptions
  header: JSX.Element | string | null
  headerTypographyProps?: TypographyProps<C, { component?: C }>
  subheader?: JSX.Element | string
  subheaderTypographyProps?: TypographyProps<C, { component?: C }>
  displayWhen?: DisplayWhen[]
  formik?: FormikContextType<any>
}

export interface CardFormSectionProps extends BaseSectionProps {
  kind: FormSectionKinds.Card
  fields: FormInputProps[]
  cardProps?: CardProps
  cardHeaderProps?: CardHeaderProps
  cardContentProps?: CardContentProps
  fullHeight?: boolean
  showHeaderDivider?: boolean
}

export interface FlatFormSectionProps extends BaseSectionProps {
  kind: FormSectionKinds.Flat
  fields: FormInputProps[]
}

export type SourceDataExtractor = (source?: unknown) => unknown
export interface DataDefinition {
  propPath: string
  dataType: DataKinds
  dataPath: string | SourceDataExtractor
}

export interface CustomFormSectionComponentProps {
  data?: DataDefinition[]
  displayWhen?: DisplayWhen[]
  [key: string]: any
}

export interface CustomFormSectionProps
  extends Omit<BaseSectionProps, "contentGrid"> {
  kind: FormSectionKinds.Custom
  Component: React.ComponentType<any>
  componentProps: CustomFormSectionComponentProps
}

export type FormSectionProps =
  | CardFormSectionProps
  | CustomFormSectionProps
  | FlatFormSectionProps

export type FormConfig = Array<FormSectionProps>
