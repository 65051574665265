let config = {
  Auth: {
    region: "us-east-2",
    userPoolId: "us-east-2_OoaiIdySl",
    userPoolWebClientId: "23jj04qpsqd8l521qn9hl7igph",
    oauth: {
      domain: "auth.patriotrcapp.com",
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin"
      ],
      redirectSignIn: `${process.env.REACT_APP_FRONTEND_URL}callback`,
      redirectSignOut: `${process.env.REACT_APP_FRONTEND_URL}logout`,
      responseType: "code"
    }
  }
}

export default config
