import { Suspense } from "react"
import { Outlet } from "react-router-dom"
import { LinearProgress } from "@material-ui/core"

interface IBlankLayoutProps {}

const BlankLayout: React.FunctionComponent<IBlankLayoutProps> = () => {
  return (
    <Suspense fallback={<LinearProgress />}>
      <Outlet />
    </Suspense>
  )
}

export default BlankLayout
