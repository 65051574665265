import { useContext } from "react"
// import { Auth } from 'aws-amplify';
import UserContext from "context/user"

function useUser() {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error("`useUser` must be within a `UserProvider`")
  }
  return context
}

export default useUser
