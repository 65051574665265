import { gql } from "@apollo/client"

export const GET_WAFFLE_BITS = gql`
  query getWaffleBits {
    waffle {
      flags {
        ...WaffleBits
      }
      flagDefault
      switches {
        ...WaffleBits
      }
      switchDefault
      samples {
        ...WaffleBits
      }
      sampleDefault
    }
  }
  fragment WaffleBits on WaffleItem {
    name
    active
  }
`
