import clsx from "clsx"
import { Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"

import * as colors from "theme/palettes/colors"
import useSharedStyles from "./useSharedStyles"

export interface INavLinkListButtonProps {
  active?: boolean
  open?: boolean
  Icon?: React.ComponentType | null
  Label?: JSX.Element | null
  title: string
  onClick: (...args: any[]) => void
}

const useStyles = makeStyles(theme => ({
  root: {
    height: 48,
    width: "100%",
    borderRadius: 0,
    paddingLeft: theme.spacing(2),
    boxShadow: "none",
    "&:hover": {
      backgroundColor:
        "light" === theme.palette.type ? colors.grey[100] : colors.grey[700]
    }
  }
}))

const NavLinkListButton: React.FunctionComponent<INavLinkListButtonProps> = ({
  active = false,
  Icon = null,
  Label = null,
  onClick,
  open = false,
  title
}) => {
  const classes = { ...useStyles(), ...useSharedStyles() }

  return (
    <Button
      className={clsx(
        classes.root,
        active || open ? classes.active : classes.inactive
      )}
      startIcon={Icon && <Icon />}
      endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      onClick={onClick}
    >
      <div className={classes.content}>
        {title}
        {Label}
      </div>
    </Button>
  )
}

export default NavLinkListButton
