import { useCallback, useEffect, useRef, useState } from "react"
import { DocumentNode } from "graphql"
import {
  useApolloClient,
  OperationVariables,
  QueryHookOptions
} from "@apollo/client"
import map from "lodash/map"
import reduce from "lodash/reduce"

import { edgeLess } from "utils/apollo"
import { DefaultFilterStateType } from "hooks/tables"

export interface useSyncQueryArgs<QueryData, QueryVariables>
  extends QueryHookOptions<QueryData, QueryVariables> {
  query: DocumentNode
  propName: string
  batchSize?: number
  paged?: boolean
  trackFields?: Array<string>
}

export type UseSyncQueryPromiseReturn = {
  count: number
  batchCount: number
  trackedFieldData: Record<string, Array<any>>
}
export type UseSyncQueryPromise = Promise<UseSyncQueryPromiseReturn>
export type UseSyncQueryReturn = {
  cancel: () => void
  run: (filter?: DefaultFilterStateType) => UseSyncQueryPromise
  reset: () => void
}

export const syncCancelMessage = "Sync cancelled"
export const syncResetMessage = "Sync reset"

const useSyncQuery = <QueryData = any, QueryVariables = OperationVariables>({
  query,
  propName,
  batchSize = 1000,
  paged = false,
  trackFields = []
}: useSyncQueryArgs<QueryData, QueryVariables>): UseSyncQueryReturn => {
  const [running, setRunning] = useState(false)
  const [batchCount, setBatchCount] = useState(0)
  const [data, setData] = useState({})
  const [error, setError] = useState<any>(false)
  const [filter, setFilter] = useState<DefaultFilterStateType | undefined>(
    undefined
  )
  const [loading, setLoading] = useState(false)
  const [trackedFieldData, setTrackedFieldData] = useState<
    Record<string, Array<any>>
  >({})

  const resolve = useRef<any>(null)
  const reject = useRef<any>(null)
  const promise = useRef<UseSyncQueryPromise | null>(null)
  const _return = useRef<UseSyncQueryReturn | null>(null)

  if (_return.current === null) {
    _return.current = {
      cancel: () => {
        if (promise.current) {
          reject.current(syncCancelMessage)
        }
        setRunning(false)
        setData({})
        setError(false)
        setLoading(false)
        setBatchCount(0)
        setTrackedFieldData({})
        promise.current = null
      },
      run: filter => {
        if (promise.current === null) {
          setFilter(filter)
          setRunning(true)

          const newPromise = new Promise((_resolve, _reject) => {
            resolve.current = _resolve
            reject.current = _reject
          }) as UseSyncQueryPromise

          promise.current = newPromise
        }
        return promise.current
      },
      reset: () => {
        if (promise.current) {
          reject.current(syncResetMessage)
        }
        setRunning(false)
        setData({})
        setError(false)
        setLoading(false)
        setBatchCount(0)
        setTrackedFieldData({})
        promise.current = null
      }
    }
  }

  const client = useApolloClient()

  // Default to true to trigger the first batch (hasNextPage is undefined)
  const hasNextPage = paged
    ? data?.[propName]?.pagedInfo?.hasNextPage ?? true
    : data?.[propName]?.pageInfo?.hasNextPage ?? true
  const tracker = paged
    ? (data?.[propName]?.pagedInfo?.page ?? 0) + 1
    : data?.[propName]?.pageInfo?.endCursor

  const count = paged
    ? data?.[propName]?.pagedInfo?.count
    : data?.[propName]?.pageInfo?.count

  const getBatch = useCallback(async () => {
    setLoading(true)
    try {
      const vars = paged
        ? { paged: { pageSize: batchSize, page: tracker } }
        : { first: batchSize, after: tracker }
      const queryResult = await client.query({
        query,
        variables: {
          ...vars,
          filter
        },
        fetchPolicy: "no-cache"
      })

      const { data, errors } = queryResult

      if (errors) {
        setError(errors)
      } else {
        setData(data)
        if (0 < trackFields.length) {
          const dataSet = paged
            ? data[propName].objects
            : edgeLess(data[propName])
          const newTrackedData: Record<string, Array<any>> = reduce(
            trackFields,
            (acc, field) => ({
              ...acc,
              // Don't compact to preserve ordering between multiple tracked fields
              // when some values are falsey
              [field]: map(dataSet, field)
            }),
            {}
          )
          setTrackedFieldData(data =>
            reduce(
              newTrackedData,
              (acc, fieldData, field) => ({
                ...acc,
                [field]: [...(acc[field] ?? []), ...fieldData]
              }),
              data
            )
          )
        }
        setBatchCount(count => count + 1)
      }
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }, [
    batchSize,
    client,
    filter,
    setBatchCount,
    setData,
    setError,
    setLoading,
    paged,
    propName,
    query,
    tracker,
    trackFields
  ])

  useEffect(() => {
    if (!loading && hasNextPage && !error && running) {
      getBatch()
    }
  }, [
    batchSize,
    filter,
    getBatch,
    hasNextPage,
    loading,
    error,
    running,
    setBatchCount,
    tracker
  ])

  // Settle promise when done or when we get the first error
  useEffect(() => {
    if (!loading && !hasNextPage && !error && running) {
      setRunning(false)
      resolve.current({ count, batchCount, trackedFieldData })
    }
  }, [
    batchCount,
    count,
    error,
    hasNextPage,
    loading,
    running,
    setRunning,
    trackedFieldData
  ])

  useEffect(() => {
    if (error) {
      setRunning(false)
      reject.current(error)
    }
  }, [error, setRunning])

  return _return.current
}

export default useSyncQuery
