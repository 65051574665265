import { useDropzone, DropzoneOptions } from "react-dropzone"
import clsx from "clsx"
import { Paper } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import grey from "@material-ui/core/colors/grey"

import InactiveDropZone from "./InactiveDropZone"
import ActiveDropZone from "./ActiveDropZone"
import DisabledDropZone from "./DisabledDropZone"
import {
  DropzoneHandlers,
  ContentDescriptionMap,
  useContentDescriptionMap
} from "components/Attachments"

export type InternalDropZoneProps = Omit<DropzoneOptions, "accept" | "multiple">

export type DropZoneProps = {
  accept?: ContentDescriptionMap
  dropzoneProps: InternalDropZoneProps
  dropzoneHandlers: DropzoneHandlers
}

const useStyles = makeStyles(theme => ({
  root: {
    // This sets the height to 100% of the last defined height in the DOM tree
    // To reset the height calculations w/o defining a height, wrap <DropZone />
    // in a block tag w/ position: relative
    height: "100%",
    minHeight: theme.spacing(12.5),
    padding: theme.spacing(1),
    borderWidth: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  active: {
    borderStyle: "dashed",
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.light
  },
  inactive: {
    borderStyle: "dashed",
    borderColor: grey[500]
  }
}))

export const dataTestid = "dropzone"

const DropZone: React.FunctionComponent<DropZoneProps> = ({
  accept,
  dropzoneProps,
  dropzoneHandlers
}) => {
  const classes = useStyles()

  const [
    acceptedUploadTypes,
    acceptedUploadDescriptions
  ] = useContentDescriptionMap(accept)

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...dropzoneProps,
    ...dropzoneHandlers,
    accept: acceptedUploadTypes,
    multiple: false,
    preventDropOnDocument: true
  })

  return (
    <Paper
      className={clsx(
        classes.root,
        isDragActive ? classes.active : classes.inactive
      )}
      {...getRootProps()}
      data-testid={dataTestid}
    >
      <input {...getInputProps()} data-testid="dropzone-input" />
      {Boolean(dropzoneProps?.disabled) ? (
        <DisabledDropZone />
      ) : isDragActive ? (
        <ActiveDropZone
          helperText={`Supports ${acceptedUploadDescriptions}.`}
        />
      ) : (
        <InactiveDropZone />
      )}
    </Paper>
  )
}

export default DropZone
