import { useMemo, useState } from "react"
import * as Yup from "yup"
import { makeStyles } from "@material-ui/styles"
import { Theme, GridProps } from "@material-ui/core"
import { useFormikContext } from "formik"
import difference from "lodash/difference"
import intersection from "lodash/intersection"
import pick from "lodash/pick"
import reduce from "lodash/reduce"
import reject from "lodash/reject"

import { Button } from "components"
import { FormConfig } from "components/FormFactory"
import FilterDrawer from "./FilterDrawer"
import { isFilterActive } from "./utils"

export interface IFilterButtonProps {
  filterConfig?: FormConfig
  searchFilterKey?: string
  filterToDisplaySchema?: Yup.ObjectSchema<object>
  baseFilterPaths?: Array<string>
  additionalFilterPaths?: Array<string>
  excludedFilterPaths?: Array<string>
  groupedFilterPaths?: Array<Array<string>>
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  filterButton: {
    marginLeft: "auto"
  }
}))

export type FilterButtonProps = IFilterButtonProps &
  Omit<GridProps, "container">

const FilterButton: React.FunctionComponent<FilterButtonProps> = ({
  className,
  filterConfig = [],
  searchFilterKey = "search",
  baseFilterPaths = [],
  additionalFilterPaths = [],
  excludedFilterPaths = [],
  groupedFilterPaths = [],
  ...rest
}) => {
  const classes = useStyles()
  const formik = useFormikContext()

  const [openFilter, setOpenFilter] = useState(false)

  const handleFilterOpen = () => {
    setOpenFilter(true)
  }

  const handleFilterClose = () => {
    formik.resetForm()
    setOpenFilter(false)
  }

  const filterButtonText = useMemo<string>(() => {
    // Don't include the search bar key or specifically excluded paths
    // when determining how many active filters there are.
    const filterPaths = reject(
      [...baseFilterPaths, ...additionalFilterPaths],
      el => el === searchFilterKey || excludedFilterPaths.includes(el)
    )
    const filterSet = pick(formik.values as Record<string, any>, filterPaths)

    const activeFilterKeys = reduce(
      filterSet,
      (acc, filter, key) => (isFilterActive(filter) ? [...acc, key] : acc),
      [] as Array<string>
    )
    // Sometimes multiple filters count as one for display purposes
    const numberOfActiveFilters = reduce(
      groupedFilterPaths,
      (acc, filterGroup) => {
        const activeFiltersInGroup = intersection(filterGroup, acc)
        if (1 >= activeFiltersInGroup.length) {
          return acc
        } else {
          const filtersToRemove = difference(filterGroup, [
            activeFiltersInGroup[0]
          ])
          return reject(acc, el => filtersToRemove.includes(el))
        }
      },
      activeFilterKeys
    ).length

    const filterButtonText = numberOfActiveFilters
      ? 1 === numberOfActiveFilters
        ? `${numberOfActiveFilters} Active Filter`
        : `${numberOfActiveFilters} Active Filters`
      : "Show Filters"

    return filterButtonText
  }, [
    additionalFilterPaths,
    baseFilterPaths,
    excludedFilterPaths,
    formik.values,
    groupedFilterPaths,
    searchFilterKey
  ])

  const clearableFilterPaths = difference(
    [...baseFilterPaths, ...additionalFilterPaths],
    [...excludedFilterPaths, searchFilterKey]
  )

  return (
    <>
      <Button className={classes.filterButton} onClick={handleFilterOpen}>
        {filterButtonText}
      </Button>
      <FilterDrawer
        onClose={handleFilterClose}
        filterConfig={filterConfig}
        open={openFilter}
        clearableFilterPaths={clearableFilterPaths}
      />
    </>
  )
}

export default FilterButton
