export const atlasMaroon = "#580406"
export const red = {
  100: "#ffcfcf",
  500: "#ed3939"
}
export const orange = {
  100: "#ffe7d1",
  500: "#ff834f"
}
export const gold = "#ffb649"
export const lightGreen = "#ebff33"
export const green = {
  100: "#def7df",
  500: "#60b46d"
}
export const lightBlue = "#58a4ea"
export const blue = {
  100: "#dee5ff",
  500: "#5174ef"
}
export const indigo = {
  100: "#e0dbff",
  500: "#4e39e4"
}
export const white = "#ffffff"
export const black = "#191A1C"

export const grey = {
  50: "#f9f9fb",
  100: "#eff0f4",
  200: "#dfe0e5",
  300: "#c9cacf",
  400: "#999a9f",
  450: "#86878E",
  500: "#797a80",
  550: "#6C6D73",
  600: "#6C6D73",
  650: "#5E5E62",
  700: "#4f5055",
  800: "#3f3f43",
  850: "#303033",
  900: "#262628",
  950: "#1d1d1f"
}
