import { IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ClearIcon from "@material-ui/icons/Clear"
import uniqueId from "lodash/uniqueId"

import { FileUploadStatus } from "client/types"

import { useAttachmentsContext, stripMetaTags } from "components/Attachments"

type PhotoStepErrorsProps = {}

const useStyles = makeStyles(theme => ({
  root: {},
  errorMessage: {
    display: "flex",
    justifyContent: "flexStart",
    alignItems: "center",
    margin: `${theme.spacing(1)}px 0`
  },
  errorMessageButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    padding: 3,
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: theme.palette.error.dark
    },
    marginRight: theme.spacing(0.5)
  },
  errorMessageText: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: theme.spacing(0.5)
  }
}))

export const dataTestid = "photostep-errors"
const PhotoStepErrors: React.FunctionComponent<PhotoStepErrorsProps> = props => {
  const classes = useStyles()

  const {
    clearRemovalError,
    documents,
    previewHandlers
  } = useAttachmentsContext()

  return (
    <section className={classes.root}>
      {documents
        .filter(document => "" !== document.errorMessage)
        .map(document => (
          <div
            className={classes.errorMessage}
            key={document.uuid || uniqueId()}
          >
            <IconButton
              onClick={evt => {
                // These documents weren't already uploaded, so we remove them from the list
                if (document.status !== FileUploadStatus.RemovalError) {
                  previewHandlers.onDelete?.(
                    document.uuid,
                    // Typescript doesn't like defining evt directly as Event
                    // The types are more complicated
                    (evt as unknown) as Event
                  )
                } else {
                  clearRemovalError(document.uuid)
                }
              }}
              className={classes.errorMessageButton}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
            <span className={classes.errorMessageText} data-testid={dataTestid}>
              <strong>{stripMetaTags(document?.label ?? "")}</strong> Error:{" "}
              {document.errorMessage}
            </span>
          </div>
        ))}
    </section>
  )
}

export default PhotoStepErrors
