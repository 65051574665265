import {
  GetCompanyOptionsQuery,
  GetCompanyOptionsQueryVariables,
  CompanyOptionFragment
} from "client/types"
import { GET_COMPANY_OPTIONS } from "queries/options"
import {
  QueryAutocomplete,
  QueryAutocompleteProps
} from "components/autocomplete/QueryAutoComplete"
import { Typography } from "@material-ui/core"

type CompanyOptionType = CompanyOptionFragment | null | undefined

export function CompanyAutocomplete({
  ...props
}): React.ReactElement<QueryAutocompleteProps<CompanyOptionType>> {
  return (
    <QueryAutocomplete<
      GetCompanyOptionsQuery,
      GetCompanyOptionsQueryVariables,
      CompanyOptionType
    >
      {...props}
      query={GET_COMPANY_OPTIONS}
      getOptionSelected={(option, value) => option.number === value.number}
      renderOption={option => <Typography noWrap>{option.name}</Typography>}
      getOptionLabel={option => option.name || ""}
    />
  )
}

export default CompanyAutocomplete
