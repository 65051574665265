import { gql } from "@apollo/client"

const addressFragments = {
  fullAddress: gql`
    fragment FullAddress on Address {
      id
      name
      address1
      address2
      postalCode
      city {
        id
        name
      }
      state {
        id
        name
        code
      }
      country {
        id
        name
        code
      }
    }
  `,
  fullSalesOrderAddress: gql`
    fragment FullSalesOrderAddress on SalesOrderAddress {
      id
      name
      address
      zip
      city
      state
      country
    }
  `
}

export default addressFragments
