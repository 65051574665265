import { IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ClearIcon from "@material-ui/icons/Clear"
import { SnackbarKey, useSnackbar } from "notistack"

const useStyles = makeStyles(theme => ({
  root: {
    color: "inherit"
  }
}))

const SnackbarClose = (key: SnackbarKey) => {
  const classes = useStyles()
  const { closeSnackbar } = useSnackbar()
  const handleClose = () => {
    closeSnackbar(key)
  }

  return (
    <IconButton
      className={classes.root}
      aria-label="close"
      onClick={handleClose}
    >
      <ClearIcon />
    </IconButton>
  )
}

export default SnackbarClose
