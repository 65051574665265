import { Grid, GridProps, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Field, useFormikContext } from "formik"
import moment from "moment"
import castArray from "lodash/castArray"

import { DisplayTicket } from "views/TicketDetails/TicketDetails"
import { ParsedRunningProcedureTicketStepResult } from "hooks/runningProcedures"
import { FieldType } from "client/types"

import DateTimeField from "components/formik/DateTimeField"
import enums from "client/enums"

import { useFormStateContext } from "hooks/forms/useFormState"
import StepNotFound from "./StepNotFound"

export type DateTimeStepProps = {
  index: number
  attributes: FieldType | Array<FieldType>
  inputGrid?: GridProps
  instructions?: string | null
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  constrainedGridItem: {
    maxWidth: 250
  },
  lockedWrapper: {
    display: "flex"
  },
  lockedDataSection: {
    display: "flex",
    flexDirection: "column",
    "&:not(:first-child)": {
      marginLeft: theme.spacing(2)
    }
  },
  lockedLabel: {
    color: theme.palette.text.primary,
    textTransform: "uppercase"
  },
  lockedData: {
    display: "flex",
    alignItems: "center"
  },
  lockedDatum: {
    fontWeight: 800
  },
  lockedUnit: {
    marginLeft: theme.spacing(0.5)
  },
  lockedNoData: {
    color: theme.palette.text.secondary
  },
  clearIcon: {
    cursor: "pointer"
  }
}))

export const dataTestid = "date-time-step"
export const dateTimeFormat = "MM/DD/YY hh:mm a"
const DateTimeStep: React.FunctionComponent<DateTimeStepProps> = ({
  index,
  instructions,
  attributes: _attributes,
  inputGrid = { xs: 12, md: 6 } as GridProps
}) => {
  const classes = useStyles()
  const formik = useFormikContext<Partial<DisplayTicket>>()
  const step = formik.values?.steps?.[
    index
  ] as ParsedRunningProcedureTicketStepResult

  const { getMetadata } = useFormStateContext()

  // If the running procedure step order is broken, there might be no step or no
  // parsedData. If so, display the step not found message instead of crashing.
  // parsedData should always be an object, so no need to worry about expected
  // falsey values from short-circuiting the display.
  if (!step?.parsedData) return <StepNotFound index={index} />

  const { isSubmitting } = formik
  const {
    isDocumentationLocked = false,
    isMutationLoading = false
  } = getMetadata()
  const attributes = castArray(_attributes)

  const dateTime = step?.parsedData?.[attributes[0]]?.data

  const content = isDocumentationLocked ? (
    <Grid className={classes.lockedWrapper} item xs={12}>
      <div className={classes.lockedDataSection} key={attributes[0]}>
        <Typography variant="caption" className={classes.lockedLabel}>
          {enums.FieldType.description(attributes[0])}
        </Typography>
        {dateTime ? (
          <div className={classes.lockedData}>
            <Typography
              className={classes.lockedDatum}
              variant="body1"
              component="span"
            >
              {moment(dateTime).local().format(dateTimeFormat)}
            </Typography>
          </div>
        ) : (
          <Typography variant="body1" className={classes.lockedNoData}>
            No Data
          </Typography>
        )}
      </div>
    </Grid>
  ) : (
    <Grid
      className={classes.constrainedGridItem}
      key={attributes[0]}
      item
      {...inputGrid}
    >
      <Field
        name={`steps[${index}].parsedData.${attributes[0]}.data`}
        id={`${index}-${attributes[0]}`}
        label={enums.FieldType.description(attributes[0])}
        component={DateTimeField}
        disabled={isSubmitting || isMutationLoading}
        clearable
      />
    </Grid>
  )

  return (
    <Grid
      className={classes.root}
      container
      spacing={2}
      data-testid={dataTestid}
    >
      <Grid item xs={12}>
        {instructions}
      </Grid>
      {content}
    </Grid>
  )
}

export default DateTimeStep
