import { useEffect } from "react"
import { Helmet } from "react-helmet"
import { makeStyles } from "@material-ui/core/styles"
import { Container, Theme, ContainerProps } from "@material-ui/core"

import { useLocation } from "react-router-dom"

const NODE_ENV = process.env.NODE_ENV
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID

export type PageProps = ContainerProps & {
  title: String
  head?: React.ReactNode
}

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    height: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}))

const Page: React.ComponentType<PageProps> = ({
  title,
  head,
  children,
  ...rest
}) => {
  const classes = useStyles()
  const location = useLocation()

  useEffect(() => {
    console.debug(`navigated to ${location.pathname}`)
    if (NODE_ENV !== "production") {
      return
    }

    // @ts-ignore
    if (window.gtag) {
      // @ts-ignore
      window.gtag("config", GA_MEASUREMENT_ID, {
        page_path: location.pathname,
        page_name: title
      })
    }
  }, [title, location])

  return (
    <Container className={classes.page} {...rest}>
      <Helmet>
        <title>{title}</title>
        {head}
      </Helmet>
      {children}
    </Container>
  )
}

export default Page
