import { Box, Container, CssBaseline, Typography } from "@material-ui/core"
import Page from "./Page"

interface MessagePageProps {
  title: string
  subTitle?: string
}

const MessagePage: React.FC<MessagePageProps> = ({
  title,
  subTitle = null
}) => {
  return (
    <Page title={title}>
      <CssBaseline />
      <Container>
        <Box py={3}>
          <Typography variant="h1">{title}</Typography>
          {subTitle ? (
            <Typography variant="subtitle2">{subTitle}</Typography>
          ) : null}
        </Box>
      </Container>
    </Page>
  )
}

export default MessagePage
