import { ApolloLink, Observable } from "@apollo/client"
import { AppState, AppVars } from "client/types"
import { db } from "hooks/sync/useSyncDatabase"

const offlineDocumentLink = new ApolloLink((operation, forward) => {
  const { operationName, variables } = operation
  const { appState } = operation.getContext()
  const { online, isUpSyncing } = appState as AppVars & AppState

  // The app's online state doesn't get set to true until after the upsyncs
  // are finished, but addDocument should be processed normally while
  // upsyncing.
  if (
    "addDocument" === operationName &&
    false === online &&
    false === isUpSyncing
  ) {
    const { input } = variables
    const { file, ...otherInputVars } = input
    return new Observable(observer => {
      let handle

      const arrayBufferPromise = new Promise((resolve, reject) => {
        // Because keeping the file as a blob prevents the service
        // worker from being able to convert the request payload to
        // JSON to get the data, convert it to an ArrayBuffer here.
        const reader = new FileReader()

        reader.addEventListener("loadend", e => {
          resolve(reader.result)
        })
        reader.addEventListener("error", err => {
          console.error("Error transforming file to array buffer", err)
          reject(err)
        })
        reader.readAsArrayBuffer(file)
      })

      // This isn't important to storing the file, so the promise never rejects
      const thumbnailPromise = new Promise(resolve => {
        const heicTypes = [
          "image/avci",
          "image/avcs",
          "image/avif",
          "image/avifs",
          "image/heic",
          "image/heics",
          "image/heif",
          "image/heifs"
        ]
        const reader = new FileReader()

        reader.addEventListener("loadend", e => {
          const img = new Image()
          img.src = reader.result as string

          img.addEventListener("load", _ => {
            const canvas = document.createElement("canvas")
            canvas.width = 200
            canvas.height = 200
            const ctx = canvas.getContext("2d")
            if (null === ctx) {
              console.warn(
                "Unable to initialize canvas context to create local thumbnail"
              )
              resolve("")
            }
            ctx?.drawImage(img, 0, 0, 200, 200)
            resolve(canvas.toDataURL("image/png"))
          })
          img.addEventListener("error", err => {
            console.error("Error creating file thumbnail", err)
            resolve("")
          })
        })
        reader.addEventListener("error", err => {
          console.error("Error reading file as data URL", err)
          resolve("")
        })

        if (heicTypes.includes(file.type)) {
          // Converting to sized down data URL for a feedback loop
          // while offline. Only cachedThumbnail is currently used
          // in the UI, and its dimensions are 200px x 200px
          if ("test" === process.env.NODE_ENV) {
            resolve("")
          } else {
            // Using dynamic import to avoid breakingJest tests
            import("heic2any")
              .then(({ default: heic2any }) => {
                console.log(heic2any)
                return heic2any({
                  blob: file,
                  toType: "image/jpeg",
                  quality: 0.5
                })
                  .then(output => {
                    reader.readAsDataURL(output[0] ?? output)
                  })
                  .catch(err => {
                    console.error(err)
                    resolve("")
                  })
              })
              .catch(err => {
                console.error(err)
                resolve("")
              })
          }
        } else if (file.type.includes("image")) {
          // Converting to sized down data URL for a feedback loop
          // while offline. Only cachedThumbnail is currently used
          // in the UI, and its dimensions are 200px x 200px

          reader.readAsDataURL(file)
        } else {
          resolve("")
        }
      })

      Promise.all([arrayBufferPromise, thumbnailPromise])
        .then(async results => {
          const [fileAsArrayBuffer, cachedThumbnail] = results
          // Because the file as an ArrayBuffer isn't making it to the
          // service worker, store it in indexedDB here. Then, the
          // here. Then, the service worker will have the information
          // it needs to create its response.
          const payload = {
            ...otherInputVars,
            file: fileAsArrayBuffer,
            filename: file.name,
            mimetype: file.type,
            cachedThumbnail
          }

          await db.storedDocuments.put(payload)
        })
        .then(_ => {
          // update the operation before passing it through
          delete operation.variables.input.file

          return operation
        })
        .then(operation => {
          // pass the operation through
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
  }
  return forward(operation)
})

export default offlineDocumentLink
