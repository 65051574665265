import { useEffect, useState } from "react"
import clsx from "clsx"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import green from "@material-ui/core/colors/green"
import grey from "@material-ui/core/colors/grey"
import orange from "@material-ui/core/colors/orange"

import find from "lodash/find"
import includes from "lodash/includes"
import last from "lodash/last"

import { useConfirm } from "components/ConfirmDialog"
import { useOnlineContext } from "context/OnlineContext"
import { useDebugModeContext } from "context/DebugContext"
import {
  MessageTypes,
  ResponseTypes,
  useServiceWorkerContext
} from "context/ServiceWorkerContext"
import { useSyncDatabase, useOfflineSync } from "hooks/sync"
import useTimeouts from "hooks/useTimeouts"
import { ButtonBase as Button, TitanButtonColor } from "components"

interface INavSyncProps {}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  button: {},
  indicator: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  dot: {
    height: theme.spacing(1),
    width: theme.spacing(1),
    borderRadius: "50%",
    marginRight: theme.spacing(0.5)
  },
  errorDot: {
    backgroundColor: theme.palette.error.main
  },
  successDot: {
    backgroundColor: green[500]
  },
  neutralDot: {
    backgroundColor: grey[500]
  },
  syncingDot: {
    backgroundColor: orange[500]
  },
  hiddenDot: {
    display: "none"
  }
}))

enum AppStatuses {
  DatabaseNotOk = "Sync DB Error",
  Downloaded = "Downloaded",
  Downloading = "Downloading",
  Offline = "Offline",
  Online = "Online",
  Uploaded = "Uploaded",
  UploadFailed = "Upload Failed",
  Uploading = "Uploading"
}

const badWorkerStates: Array<ServiceWorkerState | undefined> = [
  "activating",
  "redundant"
]
const NavSync: React.FunctionComponent<INavSyncProps> = () => {
  const classes = useStyles()
  const { online, appOnline, setAppOnline, setIsUpSyncing } = useOnlineContext()
  const { flushStaleLogs, setDebugMode } = useDebugModeContext()
  const { clearAll } = useSyncDatabase()
  const confirm = useConfirm()

  const [disabled, setDisabled] = useState(false)
  const [serviceWorkerOK, setServiceWorkerOK] = useState(true)
  const [activeTimeoutId, setActiveTimeoutId] = useState<number | null>(null)
  const [status, setStatus] = useState<AppStatuses>(
    appOnline ? AppStatuses.Online : AppStatuses.Offline
  )
  const {
    worker,
    addMessageHandler,
    removeMessageHandler,
    sendMessage,
    unregister
  } = useServiceWorkerContext()
  const { addTimeout, addInterval } = useTimeouts()
  const {
    checkDownsyncSuccess,
    checkPendingUpsyncData,
    checkUpsyncSuccess,
    downsync,
    flushStaleData,
    flushUpsyncTracker,
    getDiffs,
    getDownsyncFilters,
    notifyDownsyncFailure,
    notifyNoInternet,
    notifyUpsyncFailure,
    resetSyncQueries,
    testConnection,
    upsync
  } = useOfflineSync()

  const statusToDotMap = [
    [[AppStatuses.Uploaded, AppStatuses.Downloaded], classes.successDot],
    [[AppStatuses.Uploading, AppStatuses.Downloading], classes.syncingDot],
    [[AppStatuses.UploadFailed, AppStatuses.DatabaseNotOk], classes.errorDot],
    [[AppStatuses.Offline], classes.neutralDot]
  ]
  const getDotClass = (status: AppStatuses) =>
    last(find(statusToDotMap, el => includes(el[0], status))) ??
    classes.hiddenDot

  const handleClick = async () => {
    if (activeTimeoutId) {
      clearTimeout(activeTimeoutId)
      setActiveTimeoutId(null)
    }
    setDisabled(true)
    if (appOnline) {
      // Turn debug mode on when the attempt to go offline is made, so that any errors
      // that the iPads encounter during down sync are caught in the in-app logs. Debug
      // mode doesn't automatically turn off when going back online, because, in
      // general, it's better for the techs to have debug mode active their iPads than
      // not.
      setDebugMode(true)
      console.debug(`===DEBUG mode set to true`)
      setStatus(AppStatuses.Downloading)

      console.debug(`===Resetting sync queries...`)
      resetSyncQueries()
      console.debug(`===sync queries reset`)
      console.debug(`===clearing out old upsync entries`)
      const oldUpsyncEntries = await flushUpsyncTracker()
      console.debug(
        `===${oldUpsyncEntries} old upsync tracking entries cleared`
      )

      console.debug(`===clearing out old debug logs`)
      const oldDebugLogs = flushStaleLogs()
      console.debug(`===${oldDebugLogs} old debug log entries cleared`)

      console.debug(
        `===clearing sync databases for charges, documents, priceRules and tickets...`
      )
      await flushStaleData()
      console.debug(`===sync databases cleared`)

      console.debug(`===generating last sync filters...`)
      const syncFilters = await getDownsyncFilters()
      console.debug(`===last sync filters generated`)

      console.debug(`===generating queries...`)
      const syncResults = await downsync(syncFilters)
      console.debug(`===queries complete`)

      console.debug(`===checking sync results...`)
      const shouldGoOffline =
        checkDownsyncSuccess(syncResults) ||
        (await notifyDownsyncFailure(syncResults))

      if (shouldGoOffline) {
        setStatus(AppStatuses.Downloaded)
        console.debug(`===setAppOnline:`, String(setAppOnline))
        setAppOnline?.(false)
        console.debug(`===<NavSync /> setting service worker into offline mode`)
        sendMessage({
          type: MessageTypes.SetOnlineState,
          payload: false
        })
      } else {
        console.log(`===switch to offline mode aborted`)
        setStatus(AppStatuses.Online)
      }
    } else {
      // `online` should mirror the state of `navigator.onLine`, which in both Chrome and Safari
      // can be true even if the device doesn't have access to the Internet
      // (https://developer.mozilla.org/en-US/docs/Web/API/NavigatorOnLine/onLine). To make sure
      // we're actually online, let's test via an actual query to the back end.
      const { success, reason } = await Promise.resolve(online).then(
        async online => {
          if (online) {
            console.debug(
              `===<NavSync /> setting service worker to online mode for connection test`
            )
            sendMessage({
              type: MessageTypes.SetOnlineState,
              payload: true
            })
            const { success, reason } = await testConnection()
            console.debug(`===Connection test complete`)

            // Toggle the service worker back to offline mode. Do it here so it's less likely future
            // refactors can forget to do this after moving code around.
            console.debug(
              `===<NavSync /> setting service worker back to offline mode`
            )
            sendMessage({
              type: MessageTypes.SetOnlineState,
              payload: false
            })

            return { online, success, reason }
          } else {
            return {
              online,
              success: false,
              reason: "navigator.onLine is false"
            }
          }
        }
      )

      if (!online || !success) {
        await notifyNoInternet(reason)
      } else {
        setIsUpSyncing?.(true)
        const diffs = await getDiffs()

        if (checkPendingUpsyncData(diffs)) {
          setStatus(AppStatuses.Uploading)
          // Toggle the service worker to online mode
          console.debug(
            `===<NavSync /> setting service worker back to online mode for upsync`
          )
          sendMessage({
            type: MessageTypes.SetOnlineState,
            payload: true
          })

          const syncResults = await upsync(diffs)

          if (checkUpsyncSuccess(syncResults)) {
            console.debug(`===upsync successful`)
            console.debug(
              `===<NavSync /> setting service worker back to online mode for upsync`
            )
            sendMessage({
              type: MessageTypes.SetOnlineState,
              payload: true
            })
            setStatus(AppStatuses.Uploaded)
            setIsUpSyncing?.(false)
            setAppOnline?.(true)
          } else {
            // Return the service worker to offline mode
            console.debug(`===upsync failed`)
            console.debug(
              `===<NavSync /> setting service worker back to offline mode`
            )
            sendMessage({
              type: MessageTypes.SetOnlineState,
              payload: false
            })

            await notifyUpsyncFailure(syncResults)

            setStatus(AppStatuses.UploadFailed)
            setIsUpSyncing?.(false)
            setDisabled(false)
          }
        } else {
          console.debug(`===no changes to upsync`)
          console.debug(`===<NavSync /> setting service worker to online mode`)
          sendMessage({
            type: MessageTypes.SetOnlineState,
            payload: true
          })
          setStatus(AppStatuses.Uploaded)
          setIsUpSyncing?.(false)
          setAppOnline?.(true)
        }
      }
    }
    setDisabled(false)
  }

  const handleUnregister = async () => {
    confirm({
      title: "Confirm Reinstall",
      confirmationText: "Reinstall",
      description: (
        <Typography variant="body1">
          Reinstalling offline mode will reload the page. Any unsaved and
          unsynced changes will be lost. Would you like to continue?
        </Typography>
      )
    })
      .then(async () => {
        // Clear out anything stuck in the sync databases that might causes problems when syncing.
        await clearAll()
        const promises = await unregister()
        // We'll get falses in the result if no registration is found, but that's ok. If the registration
        // is missing, that service worker has already been uninstalled, which is what we want. Just wait
        // for all found registrations to unregister.
        await Promise.allSettled(promises)
        window.location.reload()
      })
      .catch(() => {})
  }

  useEffect(() => {
    if (AppStatuses.Downloaded === status || AppStatuses.Uploaded === status) {
      const nextStatus =
        AppStatuses.Downloaded === status
          ? AppStatuses.Offline
          : AppStatuses.Online

      const timeoutId = addTimeout(() => {
        setStatus(nextStatus)
      }, 6000)
      setActiveTimeoutId(timeoutId)
    }
  }, [addTimeout, status, setActiveTimeoutId, setStatus])

  useEffect(() => {
    const token = addMessageHandler(ResponseTypes.DatabaseStatusResponse)(
      ({ ok }) => {
        setServiceWorkerOK(ok)
        if (!ok) setStatus(AppStatuses.DatabaseNotOk)
      }
    )

    return () => {
      removeMessageHandler(token)
    }
  }, [addMessageHandler, removeMessageHandler, setServiceWorkerOK, setStatus])

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      addInterval(() => {
        sendMessage({
          type: MessageTypes.CheckDatabaseStatus
        })
      }, 1000)
    }
  }, [addInterval, sendMessage])

  const badWorker = badWorkerStates.includes(worker.controller?.state)

  return (
    <div className={classes.root}>
      {badWorker ? (
        <>
          <Typography>Offline mode has a problem.</Typography>
          <Button
            data-testid="unregister-button"
            className={classes.button}
            onClick={handleUnregister}
            color={TitanButtonColor.Red}
            variant="contained"
          >
            Reinstall
          </Button>
        </>
      ) : (
        <>
          <div className={classes.indicator} data-testid="offline-indicator">
            <div className={clsx(classes.dot, getDotClass(status))} />
            {status !== AppStatuses.Online && (
              <Typography variant="body1">{status}</Typography>
            )}
          </div>
          <Button
            data-testid="offline-button"
            className={classes.button}
            onClick={handleClick}
            disabled={disabled || !serviceWorkerOK}
            color={TitanButtonColor.Blue}
            variant="contained"
          >
            Go {appOnline ? "Offline" : "Online"}
          </Button>
        </>
      )}
    </div>
  )
}

export default NavSync
