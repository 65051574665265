import { gql } from "@apollo/client"
import documentFragments from "./documents"
import signatureFragments from "./signatures"
import ticketFragments from "./tickets"
import salesOrderFragments from "./salesOrders"
import chargeFragments from "./charges"

const linkableEvent = gql`
  fragment LinkableEvent on Event {
    actorName
    targetName
    objectName
    # Work-around for inability of getting id field from actor/object/target fields
    actorId
    targetId
    objectId
    # HB-455 FIX:Leaving this in as it needs fixed
    # Document/Cabinet types have the Node interface which is causing issues as
    # Apollo gateway is confusing them as types that comes from our graphql-api endpoint
    # Need to figure out how to tell the gateway they don't exist on there.
    actor {
      ...EventData
    }
    object {
      ...EventData
    }
    target {
      ...EventData
    }
  }
  #
  fragment EventData on Node {
    ... on Node {
      __typename
    }
    ... on Charge {
      ticket {
        number
      }
    }
    ... on TicketInterface {
      number
    }
    ... on SignatureRequest {
      objectSnapshot {
        ... on TicketInterface {
          number
        }
      }
    }
    ... on SignatureResponse {
      objectSnapshot {
        ... on TicketInterface {
          number
        }
      }
    }
  }
`

const snapshotParts = gql`
  fragment TicketSnapshotParts on SignableObjectsUnion {
    ... on ServiceTicket {
      ...FullTicket
      ...PrintTicketDetails
      charges {
        ...BaseCharge
      }
      chargesTotal
      snapshotDocuments {
        ...DocumentSnapshot
      }
    }
    ... on SalesOrder {
      ...FullSalesOrder
    }
    ... on RepairTicket {
      ... on Node {
        id
      }
    }
  }
  ${salesOrderFragments.fullSalesOrder}
  ${ticketFragments.fullTicket}
  ${ticketFragments.printTicketDetails}
  ${chargeFragments.baseCharge}
  ${documentFragments.documentSnapshot}
`

const eventFragments = {
  fullEvent: gql`
    fragment FullEvent on Event {
      id
      verb
      data
      hasAllRequiredData
      type {
        name
        label
      }
      timestamp
      ...LinkableEvent
    }
    ${linkableEvent}
  `,
  smallEventType: gql`
    fragment SmallEventType on EventType {
      id
      label
    }
  `,
  subscribedEventType: gql`
    fragment SubscribedEventType on EventType {
      id
      label
      isObjectSubscribed
      isSubscribed
    }
  `,
  fullSubscription: gql`
    fragment FullSubscription on SharedSubscription {
      ... on Node {
        id
      }
      created
      eventType {
        id
        label
      }
      ... on ObjectEventSubscription {
        object {
          id
        }
      }
    }
  `,
  listSignatureEvent: gql`
    fragment ListSignatureEvent on Event {
      id
      type {
        label
        name
      }
      data
      description
      timestamp
      actorName
      ...LinkableEvent
      object {
        ... on ServiceTicket {
          id
        }
        ... on SalesOrder {
          id
        }
      }
      target {
        ... on SignatureRequest {
          ...ListSignatureRequest
        }
        ... on SignatureResponse {
          id
          created
          modified
          rejectReason
          request {
            id
          }
          signatoryName
          signatoryEmail
          type
          documentUUID
          documentVersionId
          document {
            ...BaseDocument
          }
        }
      }
    }
    ${signatureFragments.listSignatureRequest}
    ${documentFragments.baseDocument}
    ${linkableEvent}
  `,
  signatureEvent: gql`
    fragment SignatureEvent on Event {
      id
      type {
        label
        name
      }
      data
      hasAllRequiredData
      description
      timestamp
      actorName
      object {
        ... on RepairTicket {
          id
        }
        ... on SalesOrder {
          id
        }
        ... on ServiceTicket {
          id
        }
      }
      target {
        ... on SignatureRequest {
          id
          created
          modified
          cancelled
          signatoryName
          signatoryEmail
          objectSnapshot {
            ...TicketSnapshotParts
            ... on ServiceTicket {
              urls {
                detail
              }
            }
          }
          latestResponse {
            createEvent {
              ... on Event {
                id
              }
            }
            id
          }
        }
        ... on SignatureResponse {
          id
          created
          modified
          rejectReason
          request {
            id
          }
          signatoryName
          signatoryEmail
          objectSnapshot {
            ...TicketSnapshotParts
            ... on TicketInterface {
              urls {
                detail
              }
            }
          }
          type
          documentUUID
          documentVersionId
          document {
            ...BaseDocument
          }
          compositeDocumentUUID
          compositeDocumentVersionId
          compositeDocument {
            ...BaseDocument
          }
        }
      }
      ...LinkableEvent
    }
    ${documentFragments.baseDocument}
    ${snapshotParts}
    ${linkableEvent}
  `
}

export default eventFragments
