import clsx from "clsx"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { SalesOrderStatuses } from "client/types"

import { green, grey } from "theme/palettes/colors"
import enums from "client/enums"

interface ISalesOrderStatusLabelProps {
  status?: SalesOrderStatuses
  condensed?: boolean
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 0,
    flexShrink: 0,
    borderRadius: theme.shape.borderRadius,
    whiteSpace: "nowrap",
    fontWeight: "bold"
  },
  normal: {
    padding: theme.spacing(0, 1.5),
    height: 40
  },
  condensed: {
    padding: theme.spacing(0, 0.5),
    height: 20,
    textTransform: "uppercase"
  },
  [SalesOrderStatuses.Complete]: {
    backgroundColor: green[100],
    color: green[500]
  },
  [SalesOrderStatuses.Draft]: {
    backgroundColor: grey[100],
    color: grey[550]
  },
  [SalesOrderStatuses.Invoiced]: {
    backgroundColor: grey[100],
    color: grey[550]
  },
  [SalesOrderStatuses.Unsigned]: {
    backgroundColor: grey[100],
    color: grey[550]
  }
}))

const SalesOrderStatusLabel: React.FunctionComponent<ISalesOrderStatusLabelProps> = ({
  status,
  condensed = false
}) => {
  const classes = useStyles()

  if (undefined === status) return null

  return (
    <Typography
      className={clsx(
        classes.root,
        { [classes.normal]: !condensed, [classes.condensed]: condensed },
        classes[status]
      )}
    >
      {enums.SalesOrderStatuses.description(status)}
    </Typography>
  )
}

export default SalesOrderStatusLabel
