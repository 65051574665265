import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import ButtonBase, {
  IButtonBaseProps,
  TitanButtonTextColor
} from "../ButtonBase"

interface IPaginationButtonProps
  extends Omit<IButtonBaseProps, "color" | "icon" | "textColor" | "variant"> {
  value: number | "prev" | "next"
}

const PaginationButton: React.FunctionComponent<IPaginationButtonProps> = ({
  condensed,
  value,
  ...props
}) => {
  let startIcon
  if ("prev" === value) {
    startIcon = <NavigateBeforeIcon />
  } else if ("next" === value) {
    startIcon = <NavigateNextIcon />
  } else {
    startIcon = <>{value}</>
  }
  return (
    <ButtonBase
      textColor={TitanButtonTextColor.Standard}
      icon
      condensed={condensed}
      startIcon={startIcon}
      {...props}
    />
  )
}

export default PaginationButton
