// vim: foldmethod=marker:foldmarker={{{,}}}
// imports {{{
import moment from "moment"
import cloneDeep from "lodash/cloneDeep"
import difference from "lodash/difference"
import differenceBy from "lodash/differenceBy"
import filter from "lodash/filter"
import find from "lodash/find"
import intersectionBy from "lodash/intersectionBy"
import isEqual from "lodash/isEqual"
import keys from "lodash/keys"
import map from "lodash/map"
import omit from "lodash/omit"
import pick from "lodash/pick"
import reduce from "lodash/reduce"
import reject from "lodash/reject"
import sortBy from "lodash/sortBy"
import zipObject from "lodash/zipObject"

import {
  AddDocumentInput,
  ChargeInput,
  MutationIntroduceChargeArgs,
  RejectSignatureResponseInput,
  RunningProcedureTicketStepResult,
  RunningProcedureTicketStepResultInput,
  SignatureRequestInput,
  SignatureResponseTypes
} from "client/types"
import {
  AddDocumentInputVariables,
  ServiceTicketInputVariables,
  RunningProcedureTicketStepResultInputVariables
} from "client/inputVars"

import {
  SyncCharge,
  SyncInitialCharge,
  SyncInitialTicket,
  SyncStoredDocument,
  SyncStoredSignature,
  SyncTicket
} from "../useSyncDatabase"
// imports }}}

// Diff helpers
// TicketChanges {{{
export type TicketChanges = {
  addedRPSteps: Array<Partial<RunningProcedureTicketStepResultInput>>
  changedRPSteps: Array<Partial<RunningProcedureTicketStepResultInput>>
  removedRPSteps: Array<Partial<RunningProcedureTicketStepResultInput["id"]>>
  updatedFields: Record<string, unknown>
}
// TicketChanges }}}

export type TicketChangesMap = Record<string, TicketChanges> | { error: string }

// getAddedDocuments {{{
export const getAddedDocuments = (documents: Array<SyncStoredDocument>) =>
  map<SyncStoredDocument, Omit<AddDocumentInput, "category">>(
    documents,
    document => {
      const { file: arrayBuffer, mimetype, filename, ...input } = document
      const blob = new Blob([arrayBuffer])
      const file = new File([blob], filename, { type: mimetype ?? undefined })

      return {
        ...pick(input, AddDocumentInputVariables),
        file
      }
    }
  )
// getAddedDocuments }}}

// AddedSignature {{{
export type AddedSignature = Pick<SyncStoredSignature, "sigInput" | "keys"> & {
  id: SyncStoredSignature["objectId"]
  input: AddDocumentInput
}
// AddedSignature }}}

// getAddedSignatures {{{
export const getAddedSignatures = (signatures: Array<SyncStoredSignature>) =>
  map<SyncStoredSignature, AddedSignature>(
    reject(
      signatures,
      signature =>
        [
          SignatureResponseTypes.Rejected,
          SignatureResponseTypes.Email
        ].includes(signature.sigInput?.type) ||
        Boolean(signature.alreadyUploaded)
    ),
    signature => {
      const {
        file: arrayBuffer = new ArrayBuffer(0),
        filename = "filenameNotFound",
        mimetype,
        input,
        sigInput,
        objectId: id,
        keys
      } = signature
      const blob = new Blob([arrayBuffer])
      const file = new File([blob], filename, { type: mimetype ?? undefined })

      return {
        input: {
          ...input,
          file
        } as AddDocumentInput,
        sigInput,
        id,
        keys
      }
    }
  )
// getAddedSignatures }}}

// The format of some timestamps changes after it goes through the UI. Using moment to check
// for whether a value can be interpreted as a date and, if so, converting the values to the
// same format to check is iffy because moment(date).isValid() can be overly broad in what
// it recognizes as a valid date. Thus, a list of date fields on the ticket is used in the
// comparison.
export const dateTimeFields = [
  "arrivedTime",
  "arriveToShop",
  "departedTime",
  "endTime",
  "jsaPerformedTime",
  "scheduledArrivalTime",
  "startTime",
  "workRequestDate"
]
const dateTimeComparisonFormat = "YYYY-MM-DDTHH:mm:ss.SSSSSSZ"

// getChangedFields {{{
export const getChangedFields = (initial: Record<string, unknown>) => (
  updated: Record<string, unknown>
): Record<string, unknown> => {
  const initialKeys = keys(initial)
  const updatedKeys = keys(updated)
  const deletedKeys = difference(initialKeys, updatedKeys)
  const base = zipObject(
    deletedKeys,
    deletedKeys.map(_ => null)
  )

  // @ts-ignore
  return reduce(
    updated,
    (acc, data, key) => {
      if (dateTimeFields.includes(key)) {
        const initialFormattedDate = moment(initial[key] as string).format(
          dateTimeComparisonFormat
        )
        const changedFormattedDate = moment(data as string).format(
          dateTimeComparisonFormat
        )
        return isEqual(initialFormattedDate, changedFormattedDate)
          ? { ...acc }
          : { ...acc, [key]: data }
      } else {
        return isEqual(initial[key], data)
          ? { ...acc }
          : { ...acc, [key]: data }
      }
    },
    base
  )
}
// getChangedFields }}}

export type AddedCharge = ChargeInput &
  Pick<MutationIntroduceChargeArgs, "ticketId">
export type RemovedCharge = { id: string; note: string }

// getChargeChanges {{{
export const getChargeChanges = (initialCharges: Array<SyncInitialCharge>) => (
  changedCharges: Array<SyncCharge>
) => {
  // addedCharges {{{
  // order the charges by created date so their mutations get sent in the correct order
  const addedCharges = sortBy(
    filter(changedCharges, "offline"),
    ({ created }) => moment(created).valueOf()
  ).map(
    charge =>
      ({
        id: charge.id,
        item: charge?.item?.id,
        quantity: charge?.quantity,
        price: charge?.price,
        ticketId: charge?.ticketId
      } as AddedCharge)
  )
  // addedCharges }}}

  // removedCharges {{{
  var removedCharges = differenceBy(initialCharges, changedCharges, "id").map(
    charge => ({ id: charge.id, note: charge.note } as RemovedCharge)
  )
  // removedCharges }}}

  // updatedCharges, deleted required charges, and restoredCharges {{{
  var restoredCharges = new Array<string>()
  var updatedCharges = new Array<ChargeInput>()
  for (const charge of intersectionBy(changedCharges, initialCharges, "id")) {
    const initialCharge = find(
      initialCharges,
      initialCharge => initialCharge.id === charge.id
    )

    if (isEqual(charge, initialCharge)) {
      // no need to do anything with charges that haven't changed
      continue
    }

    const initialWasDeleted = !!initialCharge && !!initialCharge.deleted

    if (initialWasDeleted && charge.fromRequiredCharge && !charge.deleted) {
      // if we restored a required charge that was deleted on the server then
      // we need to restore it, but also update it
      restoredCharges.push(charge.id)
    }

    if (!initialWasDeleted && charge.fromRequiredCharge && !!charge.deleted) {
      // deleted required charges should be removed rather than updated
      removedCharges.push({ id: charge.id, note: charge.note } as RemovedCharge)
    } else {
      // otherwise update the charge
      updatedCharges.push({
        id: charge.id,
        item: charge?.item?.id,
        quantity: charge?.quantity,
        price: charge?.price,
        note: charge?.note
      } as ChargeInput)
    }
  }
  // updatedCharges, deleted required charges, and restoredCharges }}}

  return { addedCharges, removedCharges, updatedCharges, restoredCharges }
}
// getChargeChanges }}}

export type RejectedSignature = Pick<SyncStoredSignature, "keys"> & {
  id: SyncStoredSignature["objectId"]
  input: RejectSignatureResponseInput
}

// getRejectedSignatures {{{
export const getRejectedSignatures = (signatures: Array<SyncStoredSignature>) =>
  map(
    reject(
      signatures,
      signature =>
        SignatureResponseTypes.Rejected !== signature.sigInput?.type ||
        Boolean(signature.alreadyUploaded)
    ),
    signature => {
      const { sigInput, objectId: id, keys } = signature
      const { type, ...input } = sigInput

      return {
        input,
        id,
        keys
      } as RejectedSignature
    }
  )
// getRejectedSignatures }}}

export type SignatureRequest = Pick<SyncStoredSignature, "keys"> & {
  id: SyncStoredSignature["objectId"]
  input: SignatureRequestInput
}

// getSignatureRequests {{{
export const getSignatureRequests = (signatures: Array<SyncStoredSignature>) =>
  map(
    reject(
      signatures,
      signature =>
        SignatureResponseTypes.Email !== signature.sigInput?.type ||
        Boolean(signature.alreadyUploaded)
    ),
    signature => {
      const { sigInput, objectId: id, keys } = signature
      const { type, ...input } = sigInput

      return {
        input,
        id,
        keys
      } as SignatureRequest
    }
  )
// getSignatureRequests }}}

export const mismatchedIdError = {
  error: "The initial tickets and changed tickets don't contain the same ids"
}

// getTicketChanges {{{
export const getTicketChanges = (initialTickets: Array<SyncInitialTicket>) => (
  changedTickets: Array<SyncTicket>
): TicketChangesMap => {
  const initialTicketIds = map(initialTickets, "id")
  const changedTicketIds = map(changedTickets, "id")

  if (!isEqual(initialTicketIds, changedTicketIds)) {
    return mismatchedIdError
  }

  return reduce<string, TicketChangesMap>(
    initialTicketIds,
    (acc, id, index) => {
      // well and company get processed into wellId and companyId when making changes via
      // the UI, so we need to process that here too. Cloning to prevent bugs due to
      // unexpected mutation by reference.
      let initialTicket = cloneDeep(initialTickets[index])
      initialTicket.wellId = initialTicket?.well?.id
      // @ts-ignore
      initialTicket.company = Number(initialTicket?.company?.id)

      let changedTicket = changedTickets[index]
      changedTicket.wellId = changedTicket?.well?.id
      // @ts-ignore
      changedTicket.company = Number(changedTicket?.company?.id)

      // technicians and accountManager are handled differently because all information
      // always needs to be sent during an update mutation
      const updatedFields = getChangedFields(
        pick(initialTicket, [
          ...difference(ServiceTicketInputVariables, [
            "technicians",
            "accountManager"
          ])
        ])
      )(
        pick(changedTicket, [
          ...difference(ServiceTicketInputVariables, [
            "technicians",
            "accountManager"
          ])
        ])
      )

      const initialTechIds = map(initialTicket?.technicians, user =>
        Number(user.id)
      )
      const changedTechIds = map(changedTicket?.technicians, user =>
        Number(user.id)
      )

      const initialAcctManagerId = initialTicket?.accountManager?.id ?? null
      const changedAcctManagerId = changedTicket?.accountManager?.id ?? null

      const hasUpdates =
        0 < keys(updatedFields).length ||
        !isEqual(initialTechIds, changedTechIds) ||
        initialAcctManagerId !== changedAcctManagerId

      if (hasUpdates) {
        updatedFields.technicians = changedTechIds
        updatedFields.accountManager = changedAcctManagerId
      }

      const addedRPSteps: Array<
        Partial<RunningProcedureTicketStepResultInput>
      > = differenceBy(
        // @ts-ignore
        changedTicket.steps,
        initialTicket.steps,
        "id"
      ).map(step =>
        pick(
          {
            ...step,
            id: undefined,
            // @ts-ignore
            runningProcedureStepId: step.runningProcedureStep.id
          },
          RunningProcedureTicketStepResultInputVariables
        )
      )

      const removedRPSteps: Array<
        RunningProcedureTicketStepResult["id"]
      > = differenceBy(
        // @ts-ignore
        initialTicket.steps,
        changedTicket.steps,
        "id"
      ).map(el => (el as any).id)

      const changedRPSteps: Array<
        Partial<RunningProcedureTicketStepResultInput>
      > = intersectionBy(
        // @ts-ignore
        changedTicket.steps,
        initialTicket.steps,
        "id"
      )
        .filter(step => {
          const stepToCompare = find(
            // @ts-ignore
            initialTicket.steps,
            // @ts-ignore
            initialStep => initialStep?.id === step?.id
          )
          // The API doesn't return order and pageNumber for runningProcedureStep
          // when giving step results - HB-297, so we don't compare the runningProcedureStep definitions
          return !isEqual(
            omit(step, "runningProcedureStep"),
            // @ts-ignore
            omit(stepToCompare, "runningProcedureStep")
          )
        })
        .map(step =>
          pick(
            {
              ...step,
              // @ts-ignore
              runningProcedureStepId: step.runningProcedureStep.id
            },
            RunningProcedureTicketStepResultInputVariables
          )
        )

      return {
        ...acc,
        [id]: {
          updatedFields,
          addedRPSteps,
          removedRPSteps,
          changedRPSteps
        } as TicketChanges
      }
    },
    {} as TicketChangesMap
  )
}
// getTicketChanges }}}
