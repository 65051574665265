import { gql } from "@apollo/client"
// import listPageFragments from "fragments/listPages"
import payloadFragments from "fragments/payload"
import eventFragments from "fragments/events"

export const GET_OBJECT_SUBSCRIPTIONS = gql`
  query getObjectSubscriptions($id: ID!, $appLabel: String!, $model: String!) {
    contentType: getContentTypeByKey(
      keys: { appLabel: $appLabel, model: $model }
    ) {
      id
      eventsTypes: allEventTypes(id: $id)
        @connection(key: "contentType", filter: ["id"]) {
        ...SubscribedEventType
      }
    }
  }
  ${eventFragments.subscribedEventType}
`

export const SUBSCRIBE_TO_OBJECT_EVENTS = gql`
  mutation subscribeToObjectEvents(
    $id: ID!
    $eventIds: [ID]!
    $appLabel: String!
    $model: String!
  ) {
    contentType: mutateTypeByKey(keys: { appLabel: $appLabel, model: $model }) {
      id
      payload: addEventSubscriptions(id: $id, eventIds: $eventIds)
        @connection(key: "contentType", filter: ["id"]) {
        ...BasePayload
        events {
          ...SubscribedEventType
        }
      }
    }
  }
  ${eventFragments.subscribedEventType}
  ${payloadFragments.payloadFragment}
`

export const REMOVE_OBJECT_SUBSCRIPTIONS = gql`
  mutation removeObjectSubscriptions(
    $id: ID!
    $eventIds: [ID]
    $appLabel: String!
    $model: String!
  ) {
    contentType: mutateTypeByKey(keys: { appLabel: $appLabel, model: $model }) {
      id
      payload: removeEventSubscriptions(id: $id, eventIds: $eventIds) {
        ...BasePayload
        events {
          ...SubscribedEventType
        }
      }
    }
  }
  ${eventFragments.subscribedEventType}
  ${payloadFragments.payloadFragment}
`
