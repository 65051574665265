import { Suspense } from "react"
import { Outlet } from "react-router-dom"
import { makeStyles } from "@material-ui/styles"
import { LinearProgress } from "@material-ui/core"

type ErrorProps = {}

const useStyles = makeStyles(() => ({
  root: {
    height: "100%"
  }
}))

const Error: React.FunctionComponent<ErrorProps> = () => {
  const classes = useStyles()

  return (
    <main className={classes.root}>
      <Suspense fallback={<LinearProgress />}>
        <Outlet />
      </Suspense>
    </main>
  )
}

export default Error
