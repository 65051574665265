import { useMemo } from "react"
import { InputAdornment } from "@material-ui/core"
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps
} from "@material-ui/pickers"
import { FieldProps, getIn } from "formik"
import withProps from "recompose/withProps"
import compact from "lodash/compact"

import usePickerStyles from "./usePickerStyles"
import ClearAdornment from "./ClearAdornment"
import { useFormStateContext } from "hooks/forms/useFormState"

export type DatePickerProps = FieldProps &
  Omit<MuiDatePickerProps, "error" | "name" | "onChange" | "value"> & {
    clearable?: boolean
  }

const BaseInputProps = {
  endAdornment: (
    <InputAdornment position="end">
      <CalendarTodayIcon />
    </InputAdornment>
  )
}

export const DateField: React.ComponentType<DatePickerProps> = ({
  children,
  ...props
}: DatePickerProps) => {
  const classes = usePickerStyles()

  const {
    field,
    form,
    disabled,
    onBlur: originalOnBlur,
    clearable = false,
    ...otherProps
  } = props

  const { name } = field
  const { touched, errors, isSubmitting, setFieldValue, setFieldError } = form
  const {
    clearError,
    getError,
    _default: noFormStateContext
  } = useFormStateContext()

  // Merging back-end errors at the component level to allow forms to be resubmitted
  // even if back-end errors haven't been cleared.
  const fieldError = getIn(errors, name)
  const backEndError = getError(name)
  const showError = (getIn(touched, name) && !!fieldError) || !!backEndError
  const errorText = compact([fieldError, backEndError]).join("\n")

  // If an object field is null because there is no data at all for it, the first time
  // data is set on one of the subfields, the remaining subfields will become undefined.
  // A value of undefined causes the Date picker to show the current date and time.
  // So we convert undefineds back to null.
  if (field.value === undefined) field.value = null

  const onBlur = !noFormStateContext
    ? undefined === originalOnBlur
      ? _ => {
          clearError(name)
        }
      : evt => {
          clearError(name)
          originalOnBlur(evt)
        }
    : originalOnBlur

  const clearIcon = useMemo(() => {
    if (!clearable) return null
    if (null === field.value) return null
    return (
      <ClearAdornment
        onClick={evt => {
          evt.stopPropagation()
          setFieldValue(name, null)
        }}
      />
    )
  }, [clearable, field.value, name, setFieldValue])

  const pickerProps: MuiDatePickerProps = {
    ...otherProps,
    ...field,
    InputProps: {
      ...BaseInputProps,
      startAdornment: clearIcon
    },
    DialogProps: {
      className: classes.dialog
    },
    error: showError,
    helperText: showError ? errorText : props.helperText,
    disabled: disabled !== undefined ? disabled : isSubmitting,
    onBlur,
    onChange(date) {
      setFieldValue(name, date)
    },
    onError(error) {
      // It appears this callback is called w/ an empty string when there are no
      // errors. Prevent "" from being set as an error.
      if (Boolean(error) && error !== fieldError) {
        setFieldError(name, String(error))
      }
    }
  }

  return <MuiDatePicker {...pickerProps}>{children}</MuiDatePicker>
}

DateField.displayName = "FormikMaterialUIDatePicker"

// Date style customized
const dateInputProps = withProps({
  fullWidth: true,
  inputVariant: "outlined",
  format: "MM/DD/YY"
})

// @ts-ignore
export const StyledDateField = dateInputProps(DateField)
export default StyledDateField
