import clsx from "clsx"
import { List, ListSubheader } from "@material-ui/core"
import map from "lodash/map"

import { createNavNode, NavSectionNode } from "./utils"
import useWaffle from "hooks/useWaffle"

export type NavSectionProps = NavSectionNode & {
  className?: string
}
const NavSection: React.FunctionComponent<NavSectionProps> = ({
  className = "nav-section",
  subheader,
  items,
  dataTestid
}) => {
  const waffle = useWaffle()
  return (
    <List key={subheader} className={clsx(className)} data-testid={dataTestid}>
      <ListSubheader disableSticky>{subheader}</ListSubheader>
      {map(items, item => createNavNode(item, undefined, waffle))}
    </List>
  )
}

export default NavSection
