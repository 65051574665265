import {
  GetRunningProcedureOptionsQuery,
  GetRunningProcedureOptionsQueryVariables,
  BaseRunningProcedureFragment
} from "client/types"
import { GET_RUNNING_PROCEDURE_OPTIONS } from "queries/options"
import {
  QueryAutocomplete,
  QueryAutocompleteProps
} from "components/autocomplete/QueryAutoComplete"
import { Chip, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

type RunningProcedureOptionType =
  | BaseRunningProcedureFragment
  | null
  | undefined

const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: theme.spacing(1)
  }
}))
const Label = ({ option }) => {
  const classes = useStyles()

  return (
    <Typography noWrap component="div">
      <Chip
        className={classes.chip}
        color="secondary"
        size="small"
        label={`${option.title} - ${option.company?.name ?? "Generic"}`}
      />
      {option.description}
    </Typography>
  )
}

export function RunningProcedureAutocomplete({
  ...props
}): React.ReactElement<QueryAutocompleteProps<RunningProcedureOptionType>> {
  return (
    <QueryAutocomplete<
      GetRunningProcedureOptionsQuery,
      GetRunningProcedureOptionsQueryVariables,
      RunningProcedureOptionType
    >
      {...props}
      query={GET_RUNNING_PROCEDURE_OPTIONS}
      getOptionSelected={(option, value) => option.id === value.id}
      renderOption={option => <Label option={option} />}
      getOptionLabel={option => option.description || ""}
    />
  )
}

export default RunningProcedureAutocomplete
