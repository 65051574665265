import {
  GetBasinOptionsQuery,
  GetBasinOptionsQueryVariables,
  BasinOptionFragment
} from "client/types"
import { GET_BASIN_OPTIONS } from "queries/options"
import {
  QueryAutocomplete,
  QueryAutocompleteProps
} from "components/autocomplete/QueryAutoComplete"
import { Typography } from "@material-ui/core"

type BasinOptionType = BasinOptionFragment | null | undefined

export function BasinAutocomplete({
  ...props
}): React.ReactElement<QueryAutocompleteProps<BasinOptionType>> {
  return (
    <QueryAutocomplete<
      GetBasinOptionsQuery,
      GetBasinOptionsQueryVariables,
      BasinOptionType
    >
      {...props}
      query={GET_BASIN_OPTIONS}
      getOptionSelected={(option, value) => option.id === value.id}
      renderOption={option => <Typography noWrap>{option.name}</Typography>}
      getOptionLabel={option => option.name || ""}
    />
  )
}

export default BasinAutocomplete
