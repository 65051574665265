const overrides = (palette, typography) => ({
  root: {
    backgroundColor: palette.chip.background,
    color: palette.chip.text
  },
  deletable: {
    "&:focus": {
      backgroundColor: palette.chip.deleteableBackground
    }
  }
})

export default overrides
