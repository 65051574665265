import { Formik, FormConfig, FormSectionKinds } from "components/FormFactory"
import { GridProps } from "@material-ui/core/Grid"
import CityAutocomplete from "components/autocomplete/CityAutocomplete"
import CompanyAutocomplete from "components/autocomplete/CompanyAutocomplete"
import BasinAutocomplete from "components/autocomplete/BasinAutocomplete"

const grid: GridProps = {
  xs: 12,
  md: 6
}

const sections: FormConfig = [
  {
    kind: FormSectionKinds.Card,
    contentGrid: true,
    header: "New Well Information",
    cardProps: {
      id: "create-new-well-card",
      elevation: 3
    },
    fields: [
      {
        name: "createWell.name",
        label: "Well Name",
        type: Formik.Text,
        grid: { xs: 12 },
        props: {
          id: "well-name",
          autoFocus: true
        }
      },
      {
        name: "createWell.apiNumber",
        label: "API Number",
        type: Formik.Text,
        grid: { xs: 12 },
        props: {
          id: "api-number"
        }
      },
      {
        name: "createWell.country.name",
        label: "Country",
        type: Formik.Text,
        grid,
        props: {
          id: "country",
          disabled: true
        }
      },
      {
        name: "createWell.basin",
        label: "Basin",
        grid,
        type: Formik.Autocomplete,
        props: {
          id: "well-basin",
          AutocompleteComponent: BasinAutocomplete,
          size: `medium`
        }
      },
      {
        name: "createWell.city",
        label: "City",
        grid,
        type: Formik.Autocomplete,
        props: {
          id: "well-city",
          AutocompleteComponent: CityAutocomplete,
          size: `medium`
        }
      },
      {
        name: "createWell.company",
        label: "Company",
        grid,
        type: Formik.Autocomplete,
        props: {
          id: "well-company",
          AutocompleteComponent: CompanyAutocomplete,
          size: `medium`
        }
      },
      {
        name: "createWell.latitude",
        label: "Latitude",
        grid,
        type: Formik.Text,
        props: {
          id: "latitude",
          type: "number"
        }
      },
      {
        name: "createWell.longitude",
        label: "Longitude",
        grid,
        type: Formik.Text,
        props: {
          id: "longitude",
          type: "number"
        }
      },
      {
        name: "createWell.directions",
        label: "Directions to Location",
        grid: { xs: 12 },
        type: Formik.Text,
        props: {
          id: "directions",
          multiline: true,
          rows: 3,
          placeholder: "Directions to well location, streets to take, etc."
        }
      }
    ]
  }
]

export default sections
