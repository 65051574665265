import { useDropzone } from "react-dropzone"
import clsx from "clsx"
import {
  Card,
  CardContent,
  CardMedia,
  CardHeader,
  Typography
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { grey } from "@material-ui/core/colors"
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined"

import {
  listThumbnailSize,
  listThumbnailMargin,
  useAttachmentsContext,
  useContentDescriptionMap
} from "components/Attachments"
import { ActiveDropZone } from "components/DropZone"

import { statusesWithThumbnails } from "./utils"

type PhotoStepDropZoneProps = { instructions?: string | null }

const dropZoneBorderWidth = 2
const useStyles = makeStyles(theme => ({
  root: {
    borderWidth: dropZoneBorderWidth,
    borderRadius: theme.spacing(0.5),
    display: "flex",
    flexDirection: "row",
    cursor: "pointer"
  },
  dropzoneNormal: {
    height:
      listThumbnailSize +
      theme.spacing(listThumbnailMargin) * 2 +
      dropZoneBorderWidth * 2
  },
  dropzoneCondensed: {
    height:
      listThumbnailSize / 2 +
      theme.spacing(listThumbnailMargin) * 2 +
      dropZoneBorderWidth * 2
  },
  active: {
    justifyContent: "center",
    alignItems: "center",
    borderStyle: "dashed",
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.light
  },
  inactive: {
    borderStyle: "dashed",
    borderColor: grey[500]
  },
  thumbnail: {
    margin: theme.spacing(listThumbnailMargin),
    borderRadius: theme.spacing(0.5),
    flexGrow: 0,
    flexShrink: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: grey[100],
    color: theme.palette.text.secondary
  },
  thumbnailCondensed: {
    height: listThumbnailSize / 2,
    flexBasis: listThumbnailSize / 2
  },
  thumbnailNormal: {
    height: listThumbnailSize,
    flexBasis: listThumbnailSize
  },
  header: {
    padding: 0
  },
  title: {
    lineHeight: "1.3333em",
    fontWeight: 800
  },
  content: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0
    }
  }
}))

export const dataTestid = "photostep-dropzone"
const PhotoStepDropZone: React.FunctionComponent<PhotoStepDropZoneProps> = ({
  instructions
}) => {
  const classes = useStyles()
  const {
    accept,
    documents,
    dropzoneProps,
    dropzoneHandlers
  } = useAttachmentsContext()
  const thumbnails = documents.filter(document =>
    statusesWithThumbnails.includes(document.status)
  )

  const [
    acceptedUploadTypes,
    acceptedUploadDescriptions
  ] = useContentDescriptionMap(accept)

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...dropzoneProps,
    ...dropzoneHandlers,
    accept: acceptedUploadTypes,
    multiple: false,
    preventDropOnDocument: true
  })

  return (
    <Card
      className={clsx(
        classes.root,
        isDragActive ? classes.active : classes.inactive,
        thumbnails.length > 0
          ? classes.dropzoneCondensed
          : classes.dropzoneNormal
      )}
      {...getRootProps()}
      data-testid={dataTestid}
    >
      <input {...getInputProps()} data-testid={`${dataTestid}-input`} />
      {isDragActive ? (
        <ActiveDropZone
          helperText={`Supports ${acceptedUploadDescriptions}.`}
        />
      ) : (
        <>
          <CardMedia
            className={clsx(
              classes.thumbnail,
              thumbnails.length > 0
                ? classes.thumbnailCondensed
                : classes.thumbnailNormal
            )}
          >
            <InsertDriveFileOutlinedIcon fontSize="large" />
          </CardMedia>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <CardHeader
              classes={{
                root: classes.header
              }}
              title={instructions}
              titleTypographyProps={{
                className: clsx(classes.title),
                variant: "body1"
              }}
            />
            <CardContent
              classes={{
                root: classes.content
              }}
            >
              <Typography variant="body2">
                Drop files here or click to browse through your machine
              </Typography>
            </CardContent>
          </div>
        </>
      )}
    </Card>
  )
}

export default PhotoStepDropZone
