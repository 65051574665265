const overrides = (palette, typography) => ({
  root: {
    "&$selected": {
      backgroundColor: palette.background.default
    },
    "&$hover": {
      "&:hover": {
        backgroundColor: palette.background.default
      }
    }
  }
})

export default overrides
