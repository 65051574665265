import { Avatar, Theme } from "@material-ui/core"
import { AvatarGroup } from "@material-ui/lab"
import { makeStyles } from "@material-ui/styles"
import { User, Connection, Maybe } from "client/types"
import clsx from "clsx"
import { Fragment } from "react"
import { edgeLess } from "utils/apollo"
import UserAvatar from "./UserAvatar"

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  avatar: {
    "&:hover": {
      zIndex: 2
    }
  },
  more: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium
  }
}))

export type StackAvatarsProps = {
  users?: Maybe<Array<Partial<User>>>
  connection?: Maybe<Partial<Connection>>
  limit?: number
  extra?: number
  className?: string
  AvatarComponent?: React.ComponentType
}

// Extra allows you to show how many extra are available in the set that we haven't passed in
const StackAvatars: React.ComponentType<StackAvatarsProps> = ({
  users,
  connection,
  limit = 2,
  extra = 0,
  className,
  AvatarComponent = UserAvatar,
  ...rest
}) => {
  const classes = useStyles()

  const foundUsers = connection ? edgeLess(connection, "node.user") : users
  if (!foundUsers) return <Fragment />

  const hasExtra = !!extra

  const avatarNodes = foundUsers
    .slice(0, limit)
    .map(user => (
      <AvatarComponent key={user.id} className={classes.avatar} user={user} />
    ))

  if (hasExtra || foundUsers.length > limit) {
    avatarNodes.push(
      <Avatar className={clsx(classes.avatar, classes.more)} key="extra">
        +{hasExtra ? extra : foundUsers.length - limit}
      </Avatar>
    )
  }

  return (
    <AvatarGroup {...rest} className={clsx(classes.root, className)}>
      {avatarNodes}
    </AvatarGroup>
  )
}

export default StackAvatars
