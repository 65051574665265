import createPalette from "@material-ui/core/styles/createPalette"

import {
  atlasMaroon,
  blue,
  gold,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  red
} from "./colors"

// Use this to add new properties to
// https://material-ui.com/guides/typescript/#customization-of-theme

export default createPalette({
  type: "dark",
  // @ts-ignore
  atlasMaroon,
  action: {
    active: grey[300],
    hover: grey[400],
    selected: grey[450],
    disabled: grey[650],
    disabledBackground: grey[800]
  },
  background: {
    paper: grey[850],
    default: grey[900]
  },
  // @ts-ignore
  chip: {
    text: grey[100],
    background: grey[700],
    deleteableBackground: grey[900]
  },
  divider: grey[950],
  error: {
    main: red[500]
  },
  icon: grey[200],
  info: {
    main: lightBlue
  },
  primary: {
    main: blue[500]
  },
  secondary: {
    main: indigo[500]
  },
  success: {
    light: lightGreen,
    main: green[500]
  },
  text: {
    primary: grey[100],
    secondary: grey[200],
    disabled: grey[300]
  },
  warning: {
    main: gold
  },
  table: {
    border: grey[950]
  }
})
