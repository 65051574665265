import { gql } from "@apollo/client"
import eventFragments from "fragments/events"

const notificationFragments = {
  listNotification: gql`
    fragment ListNotification on Notification {
      id
      event {
        ...FullEvent
      }
      created
      read
    }
    ${eventFragments.fullEvent}
  `
}

export default notificationFragments
