import { Typography } from "@material-ui/core"

import {
  Category as DocumentCategory,
  RunningProcedureStep
} from "client/types"

import {
  AttachmentsProvider,
  CabinetProps,
  ContentDescriptionMap,
  InternalDropZoneProps,
  InternalPreviewProps,
  useAttachments
} from "components/Attachments"
import { createStepLabelTag } from "components/Attachments"

import { useFormStateContext } from "hooks/forms/useFormState"

import PhotoStepDropZone from "./PhotoStepDropZone"
import PhotoStepErrors from "./PhotoStepErrors"
import PhotoStepPreview from "./PhotoStepPreview"

type PhotoStepProps = InternalDropZoneProps &
  InternalPreviewProps &
  CabinetProps & {
    stepId: RunningProcedureStep["id"]
    accept?: ContentDescriptionMap
    instructions?: string | null
  }

export const dataTestid = "photostep"
// RunningProcedureStepInput passes in the RunningProcedureStep id, not the
// RunningProcedureTicketStep id because those aren't created until that step
// is filled out.
const PhotoStep: React.FunctionComponent<PhotoStepProps> = ({
  accept: _accept,
  instructions,
  cabinetId,
  cabinetLabel,
  stepId,
  ...props
}) => {
  const attachments = useAttachments({
    accept: _accept,
    propsAndHandlers: props,
    cabinetId,
    cabinetLabel,
    category: DocumentCategory.Tickets,
    labelOverride: filename => `${createStepLabelTag(stepId)}${filename}`,
    documentFilter: {
      label: createStepLabelTag(stepId),
      label_lookup: "istartswith"
    }
  })

  const { getMetadata } = useFormStateContext()
  const isDocumentationLocked = Boolean(getMetadata().isDocumentationLocked)

  const content = isDocumentationLocked ? (
    <>
      <Typography variant="body1" component="div" gutterBottom>
        <strong>{instructions}</strong>
      </Typography>
      <PhotoStepPreview />
    </>
  ) : (
    <>
      <PhotoStepDropZone instructions={instructions} />
      <PhotoStepPreview />
      <PhotoStepErrors />
    </>
  )

  return (
    <AttachmentsProvider value={attachments}>
      <section data-testid={dataTestid}>{content}</section>
    </AttachmentsProvider>
  )
}

export default PhotoStep
