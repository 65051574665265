import { CssBaseline } from "@material-ui/core"
import { SnackbarProvider } from "notistack"

import { GoogleAnalytics, ScrollReset } from "components"
import { ConfirmProvider } from "components/ConfirmDialog"
import { WaffleProvider } from "context/WaffleContext"

import Content from "./Content"

interface IBlankThemedLayoutProps {}

const BlankThemedLayout: React.FunctionComponent<IBlankThemedLayoutProps> = () => {
  return (
    <WaffleProvider polling={false}>
      <CssBaseline />
      <ScrollReset />
      <GoogleAnalytics />
      <ConfirmProvider>
        <SnackbarProvider
          maxSnack={5}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          preventDuplicate
          hideIconVariant
          {...{ "data-testid": "notistack-message" }}
        >
          <Content />
        </SnackbarProvider>
      </ConfirmProvider>
    </WaffleProvider>
  )
}

export default BlankThemedLayout
