import { City, Company, Country, Basin, Maybe, WellInput } from "client/types"

export type CreateWellData = {
  city?: Maybe<City>
  company?: Maybe<Company>
  country?: Country
  basin?: Maybe<Basin>
} & Omit<WellInput, "city" | "company" | "basin">

export const defaultCreateWellValues: CreateWellData = {
  apiNumber: "",
  city: null,
  company: null,
  country: {
    id: "1",
    name: "USA"
  },
  directions: "",
  // @ts-ignore
  basin: null,
  latitude: null,
  longitude: null,
  name: ""
}
