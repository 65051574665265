import { ApolloLink } from "@apollo/client"
import { RetryLink } from "@apollo/client/link/retry"
import SerializingLink from "apollo-link-serialize"

import { AuthorizationType } from "client/types"
import authMiddlewareLink from "./authMiddlewareLink"
import environmentLink from "./environmentLink"
import errorLink from "./errorLink"
import fileUploadEnabledLink from "./fileUploadEnabledLink"
import loggerLink from "./loggerLink"
import offlineDocumentLink from "./offlineDocumentLink"
import offlineSignatureLink from "./offlineSignatureLink"
import appStateContextLink from "./appStateContextLink"
import tokenAuthLink from "./tokenAuthLink"

export {
  appStateContextLink,
  authMiddlewareLink,
  environmentLink,
  errorLink,
  fileUploadEnabledLink,
  loggerLink,
  offlineDocumentLink,
  offlineSignatureLink
}

const baseLink = ApolloLink.from([
  appStateContextLink,
  loggerLink,
  errorLink,
  // @ts-ignore
  new SerializingLink(),
  new RetryLink({
    attempts: async (count, operation, error) => {
      const { appOnline } = operation.getContext()
      const { statusCode = 0 } = error
      const is5xx = 500 <= statusCode && 600 > statusCode

      return appOnline && !is5xx && count <= 4
    }
  }),
  offlineDocumentLink,
  offlineSignatureLink,
  environmentLink
])

const cognitoLink = ApolloLink.from([authMiddlewareLink, fileUploadEnabledLink])

const jwtLink = ApolloLink.from([tokenAuthLink, fileUploadEnabledLink])

const link = baseLink.split(
  operation =>
    AuthorizationType.Jwt === operation.getContext().appState.authorization,
  jwtLink,
  cognitoLink
)

export default link
