import warning from "tiny-warning"
import {
  DefaultFilterStateType,
  IFilterSchemas,
  IUseFiltersArgs,
  IUseFilterResult,
  useFilters
} from "hooks/tables"
import useListPagedQuery, {
  IUseListPagedQueryArgs,
  UseListPagedQueryResult
} from "./useListPagedQuery"
import { stripEmptyFieldsFromObject } from "utils/apollo"

export interface IPagedListUtilsArgs<QueryData, QueryVariables> {
  filter?: IUseFiltersArgs<DefaultFilterStateType>
  filterSchemas?: IFilterSchemas
  query: IUseListPagedQueryArgs<QueryData, QueryVariables>
}

export interface IUseListPagedUtilsResult<
  TFilterState,
  ItemType,
  TData,
  TVariables
> {
  filterData: IUseFilterResult<TFilterState>
  queryData: UseListPagedQueryResult<ItemType, TData, TVariables>
}

export default function useListPagedUtils<
  TFilterState,
  ItemType,
  QueryData,
  QueryVariables
>({
  filter = {
    name: "filter",
    defaultFilters: {}
  },
  filterSchemas = {},
  query
}: IPagedListUtilsArgs<QueryData, QueryVariables>): IUseListPagedUtilsResult<
  TFilterState,
  ItemType,
  QueryData,
  QueryVariables
> {
  // merge passed-in props w/ default props
  // TODO: Get filter name from query name?
  const {
    variables: {
      // @ts-ignore
      filter: queryFilters
    }
  } = query

  const {
    filterStripSchema,
    filterTransformSchema,
    filterValidationSchema
  } = filterSchemas

  // TODO: separate pagination filter
  const filterData = useFilters<TFilterState>({
    ...filter,
    defaultFilters: {
      // Combine filters preset on query with default filters from filter config
      ...queryFilters,
      ...filter.defaultFilters
    }
  })

  // To enforce typing, the schema generated for validation uses strict mode,
  // which prevents transformations from being applied. To avoid a function
  // trying to do too much, break the process down into 4 steps:
  //   1. transform data (so that extra UI data is still present)
  //   2. strip transformed data
  //   3. validate transformed data
  //   4. use transformed data in filter
  const { filters } = filterData
  const transformedFilters = filterTransformSchema
    ? filterTransformSchema.cast(filters)
    : filters
  const finalFilters = filterStripSchema
    ? stripEmptyFieldsFromObject(
        filterStripSchema.cast(transformedFilters, { stripUnknown: true })
      )
    : transformedFilters

  filterValidationSchema?.validate(finalFilters).catch(err => {
    warning(
      false,
      `A list page filter didn't pass validation: ${(err?.errors ?? []).join(
        ", "
      )}`
    )
  })

  const queryData = useListPagedQuery<ItemType, QueryData, QueryVariables>({
    ...query,
    // TODO: fix
    // @ts-ignore
    variables: {
      ...query.variables,
      filter: finalFilters
    }
  })

  // TODO: add other hooks/steps as needed

  // @ts-ignore - we're getting lost in Apollo's type bindings
  return { filterData, queryData }
}
