import { DocumentNode } from "@apollo/client"
import { FetchResult } from "apollo-link"

import { client } from "client"
import { getQueryShapes } from "utils/apollo"
import { db, SyncTable } from "../useSyncDatabase"

export const trackedMutate: (props: {
  mutation: DocumentNode
  variables: Record<string, any>
}) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>> = ({
  mutation,
  variables
}) =>
  new Promise(async (resolve, reject) => {
    const operationName =
      Object.keys(getQueryShapes(mutation))?.[0] ?? "Unknown upsync operation"
    const attempted = new Date()

    const { success, res } = await client
      .mutate({
        mutation,
        variables
      })
      .then(res => ({ success: true, res }))
      .catch(res => ({ success: false, res }))

    await db[SyncTable.UpsyncTracker].add({
      attempted,
      operationName,
      response: res,
      success,
      variables
    })

    if (success) {
      resolve(res)
    } else {
      reject(res)
    }
  })
