import { gql } from "@apollo/client"

import listPageFragments from "fragments/listPages"
import payloadFragments from "fragments/payload"
import runningProcedureFragments from "fragments/runningProcedures"

export const GET_RUNNING_PROCEDURE_BY_ID = gql`
  query getRunningProcedureById($runningProcedureId: ID!) {
    procedure: runningProcedure(runningProcedureId: $runningProcedureId) {
      ...BaseRunningProcedure
    }
  }
  ${runningProcedureFragments.baseRunningProcedure}
`

export const GET_RUNNING_PROCEDURES = gql`
  query getRunningProcedures(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: RunningProcedureFilterInput
  ) {
    procedureData: getRunningProcedures(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      edges {
        cursor
        node {
          ...ListRunningProcedure
        }
      }
      ...PageInfo
    }
  }
  ${listPageFragments.pageInfo}
  ${runningProcedureFragments.listRunningProcedure}
`

export const CREATE_RUNNING_PROCEDURE = gql`
  mutation createRunningProcedure($input: CreateRunningProcedureInput!) {
    procedureData: createRunningProcedure(input: $input) {
      ...BasePayload
      procedure: object {
        id
      }
    }
  }
  ${payloadFragments.payloadFragment}
`

export const CREATE_RUNNING_PROCEDURE_VERSION = gql`
  mutation createRunningProcedureVersion(
    $input: RunningProcedureVersionInput!
  ) {
    versionData: createNewRunningProcedureVersion(input: $input) {
      ...BasePayload
      version: object {
        id
        number
        revisions {
          ...BaseRunningProcedureRevision
        }
      }
    }
  }
  ${payloadFragments.payloadFragment}
  ${runningProcedureFragments.baseRunningProcedureRevision}
`

export const CREATE_RUNNING_PROCEDURE_REVISION = gql`
  mutation createRunningProcedureRevision(
    $input: RunningProcedureRevisionInput!
  ) {
    revisionData: createNewRunningProcedureRevision(input: $input) {
      ...BasePayload
      revision: object {
        ...BaseRunningProcedureRevision
      }
    }
  }
  ${payloadFragments.payloadFragment}
  ${runningProcedureFragments.baseRunningProcedureRevision}
`

export const SAVE_RUNNING_PROCEDURE_STEP_RESULTS = gql`
  mutation saveRunningProcedureStepResults(
    $ticketId: UUID!
    $input: [RunningProcedureTicketStepResultInput]!
  ) {
    procedureStepData: saveRunningProcedureResults(
      ticketId: $ticketId
      input: $input
    ) {
      ...BasePayload
      ticket: object {
        id
        number
        steps {
          ...BaseRunningProcedureTicketStepResult
        }
      }
    }
  }
  ${payloadFragments.payloadFragment}
  ${runningProcedureFragments.baseRunningProcedureTicketStepResult}
`

export const DELETE_RUNNING_PROCEDURE_STEP_RESULTS = gql`
  mutation deleteRunningProcedureStepResults($ticketId: UUID!, $input: [ID]!) {
    procedureStepData: deleteRunningProcedureResults(
      ticketId: $ticketId
      input: $input
    ) {
      ...BasePayload
      ticket: object {
        id
        number
        steps {
          ...BaseRunningProcedureTicketStepResult
        }
      }
    }
  }
  ${payloadFragments.payloadFragment}
  ${runningProcedureFragments.baseRunningProcedureTicketStepResult}
`

export const UPDATE_RUNNING_PROCEDURE = gql`
  mutation updateRunningProcedure(
    $runningProcedureId: ID!
    $input: UpdateRunningProcedureInput!
  ) {
    procedureData: updateRunningProcedure(
      runningProcedureId: $runningProcedureId
      input: $input
    ) {
      ...BasePayload
      procedure: object {
        ...BaseRunningProcedure
      }
    }
  }
  ${payloadFragments.payloadFragment}
  ${runningProcedureFragments.baseRunningProcedure}
`
