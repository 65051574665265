import { makeStyles } from "@material-ui/core/styles"

const useNavBarStyles = makeStyles(theme => ({
  list: {
    padding: 0,
    "& li > div > .MuiButton-root": {
      paddingLeft: theme.spacing(5)
    }
  },
  listItem: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0
  },
  item: {
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%"
  }
}))

export default useNavBarStyles
