import { Navigate } from "react-router-dom"
import Auth from "@aws-amplify/auth"

import { Page } from "components"

const HomePage = history => {
  Auth.signOut()
  return (
    <Page title="Logout">
      <Navigate to="/" />
    </Page>
  )
}

export default HomePage
