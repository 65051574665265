import { useEffect, useState } from "react"
import { DocumentNode } from "graphql"
import {
  LazyQueryResult,
  OperationVariables,
  QueryHookOptions
} from "@apollo/client"
import { useSkipQuery } from "hooks/graphql"
import produce from "immer"
import compact from "lodash/compact"
import get from "lodash/get"
import isEqual from "lodash/isEqual"
import reduce from "lodash/reduce"

import { edgeLess } from "utils/apollo"
import { PageInfo } from "client/types"
import useListError from "./useListError"

export interface IUseListCursorQueryArgs<TData, TVars>
  extends QueryHookOptions<TData, TVars> {
  query: DocumentNode
  propName: string
}

export type UseListCursorQueryResult<
  ItemType,
  TData = any,
  TVariables = OperationVariables
> = LazyQueryResult<TData, TVariables> & {
  displayData: Array<ItemType>
  // TODO: figure out how to fix this.
  // loadMore: () => Promise<ApolloQueryResult<TData>>
  loadMore: () => any
  pageInfo: PageInfo
}

export default function useListCursorQuery<
  ItemType = any,
  QueryData = any,
  QueryVariables = OperationVariables
>({
  query,
  propName,
  skip = false,
  ...queryArgs
}: IUseListCursorQueryArgs<
  QueryData,
  QueryVariables
>): UseListCursorQueryResult<ItemType, QueryData, QueryVariables> {
  const [displayData, setDisplayData] = useState<Array<ItemType>>([])

  const queryResult = useSkipQuery<QueryData, QueryVariables>(query, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    ...queryArgs,
    skip
  })

  const { data, error, fetchMore, loading } = queryResult
  useListError({ error })

  const listData = get(data, propName)
  // If the API gets into a state where database data conflicts with the Django model,
  // like a ticket has a ticket type that isn't one of the allowed ticket type options,
  // perhaps as a result of a bad merge migration, it will return a ticket of null.
  // The front-end assumes that it won't get null values--otherwise, there's nothing
  // to display or recover from--so we strip nulls out via compact so the UI doesn't
  // crash.
  //
  // Wrapping this in useEffect so that displayData has a stable id when listData
  // hasn't changed.
  useEffect(() => {
    const newDisplayData =
      !error && listData ? compact(edgeLess(listData)) : ([] as Array<ItemType>)
    if (!isEqual(newDisplayData, displayData)) {
      setDisplayData(newDisplayData)
    }
    console.groupEnd()
  }, [displayData, error, listData])

  // If the API has crashed but the gateway is still active, loading will be false and
  // error will be undefined because the front end queries the gateway and that worked.
  // Rather than categorize all possible gateway responses, use pageInfo if it's
  // present. If not assume there are no more pages because something went wrong on the
  // other side of the gateway.
  const pageInfo =
    !error && !loading && data && listData?.pageInfo
      ? listData.pageInfo
      : ({ count: 0, hasNextPage: false } as PageInfo)

  const loadMore = () => {
    // return if we haven't loaded yet or if we're currently loading
    if (!data || loading || !fetchMore) return

    const nextCursor = listData.pageInfo.endCursor

    return fetchMore({
      // query,
      variables: {
        ...queryArgs.variables,
        after: nextCursor
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newListData = get(fetchMoreResult, propName) as any
        const newEdges = newListData?.edges ?? []

        return newEdges.length
          ? produce(previousResult, draft => {
              const propPath = propName.split(".")
              // Not using lodash get because it doesn't work with immer's proxies
              const dataToUpdate = reduce(
                propPath,
                (acc, propName) => (acc as object)?.[propName],
                draft
              )
              // @ts-ignore
              if (dataToUpdate?.edges)
                // @ts-ignore
                dataToUpdate.edges = [...dataToUpdate.edges, ...newEdges]
              // @ts-ignore
              if (dataToUpdate?.pageInfo)
                // @ts-ignore
                dataToUpdate.pageInfo =
                  newListData?.pageInfo ??
                  ({ count: 0, hasNextPage: false } as PageInfo)
            })
          : previousResult
      }
    })
  }

  return { ...queryResult, displayData, loadMore, pageInfo }
}
