import ButtonBase, {
  IButtonBaseProps,
  TitanButtonTextColor
} from "../ButtonBase"

export interface IButtonRedProps
  extends Omit<IButtonBaseProps, "color" | "icon" | "textColor" | "variant"> {}

// App-wide Standard Button with blue text
const Button: React.FunctionComponent<IButtonRedProps> = props => {
  return <ButtonBase {...props} textColor={TitanButtonTextColor.Red} />
}

export default Button
