import enums, { filterEnumAccess } from "client/enums"
import reduce from "lodash/reduce"

import { GET_TICKET_PHASE_OPTIONS } from "queries/tickets"
import { useOnlineContext } from "context/OnlineContext"

import {
  TicketPhaseOptions,
  TicketPhaseOptionsQuery,
  TicketPhaseOptionsQueryVariables
} from "client/types"
import { useSkipQuery } from "hooks/graphql"

export interface PhaseMap {
  [key: string]: Array<TicketPhaseOptions>
}
export type PhaseMapTuple = [PhaseMap, boolean]

const filterTicketTypeEnum = filterEnumAccess(enums.TicketTypes)
const useTicketPhaseMap = (): PhaseMapTuple => {
  const { cachePersisted } = useOnlineContext()

  const { data, loading, called } = useSkipQuery<
    TicketPhaseOptionsQuery,
    TicketPhaseOptionsQueryVariables
  >(GET_TICKET_PHASE_OPTIONS, {
    fetchPolicy: "cache-and-network",
    // Wait until the cache is persisted to prevent the query from being swallowed
    skip: !cachePersisted
  })

  const phaseMap = reduce(
    data?.ticketPhaseOptions,
    (acc, p) =>
      p && p.phase && p.types
        ? {
            ...acc,
            [p.phase]: filterTicketTypeEnum(p.types).options
          }
        : acc,
    {}
  )

  return [phaseMap, !called || loading]
}

export default useTicketPhaseMap
