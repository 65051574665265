import { getIn } from "formik"
import {
  FormControlLabel,
  FormHelperText,
  FormLabel,
  makeStyles,
  Theme
} from "@material-ui/core"
import {
  Switch as FormikSwitch,
  SwitchProps as MuiSwitchProps
} from "formik-material-ui"
import compact from "lodash/compact"
import produce from "immer"

import { useFormStateContext } from "hooks/forms/useFormState"

export interface SwitchFieldProps extends MuiSwitchProps {
  label: string
  controlLabel?: string
  helperText?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontWeight: 500,
    lineHeight: 1.8
  }
}))

const SwitchField: React.ComponentType<SwitchFieldProps> = ({
  label,
  controlLabel,
  helperText,
  ...props
}) => {
  const classes = useStyles()
  const finalLabel = controlLabel || label
  const id = props.id
  const checked = Boolean(props.field.value)
  const fieldName = props.field.name
  const {
    clearError,
    getError,
    _default: noFormStateContext
  } = useFormStateContext()

  const fieldError = getIn(props.form.errors, fieldName)
  const backEndError = getError(fieldName)
  const errorText = compact([fieldError, backEndError]).join("\n")

  const newProps = produce(props, draft => {
    if (!noFormStateContext) {
      if (undefined === draft.inputProps) draft.inputProps = {}
      if (undefined === draft.inputProps.onBlur) {
        draft.inputProps.onBlur = _ => {
          clearError(fieldName)
        }
      } else {
        draft.inputProps.onBlur = (...args) => {
          clearError(fieldName)
          props.inputProps!.onBlur?.(...args)
        }
      }
    }

    // providing onChange seems to prevent the connection to formik
  })

  return (
    <>
      {controlLabel && label ? (
        <FormLabel component="legend" className={classes.label}>
          {label}
        </FormLabel>
      ) : null}
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      <FormControlLabel
        control={
          <FormikSwitch
            id={id}
            type="checkbox"
            // @ts-ignore
            checked={checked}
            {...newProps}
          />
        }
        label={Boolean(finalLabel) && <label htmlFor={id}>{finalLabel}</label>}
      />
      {Boolean(errorText) && <FormHelperText error>{errorText}</FormHelperText>}
    </>
  )
}

export default SwitchField
