import { gql } from "@apollo/client"
import charges from "fragments/charges"
import payload from "fragments/payload"

export const GET_TICKET_CHARGES = gql`
  query getTicketCharges($ticketId: UUID!, $filter: ChargeFilterInput) {
    charges: getTicketCharges(ticketId: $ticketId, filter: $filter) {
      id
      ...BaseCharge
    }
  }
  ${charges.baseCharge}
`

export const ADD_CHARGE = gql`
  mutation addCharge($ticketId: UUID!, $input: ChargeInput!) {
    payload: introduceCharge(ticketId: $ticketId, input: $input) {
      ...BasePayload
      object {
        id
        ...BaseCharge
      }
    }
  }
  ${payload.payloadFragment}
  ${charges.baseCharge}
`

export const UPDATE_CHARGE = gql`
  mutation updateCharge($chargeId: UUID!, $input: ChargeInput!) {
    payload: updateCharge(chargeId: $chargeId, input: $input) {
      ...BasePayload
      object {
        id
        ...BaseCharge
      }
    }
  }
  ${payload.payloadFragment}
  ${charges.baseCharge}
`

export const REMOVE_CHARGE = gql`
  mutation removeCharge($chargeId: UUID!, $note: String) {
    payload: removeCharge(chargeId: $chargeId, note: $note) {
      ...BasePayload
      object {
        id
      }
    }
  }
  ${payload.payloadFragment}
`

export const RESTORE_CHARGE = gql`
  mutation restoreCharge($chargeId: UUID!) {
    payload: restoreCharge(chargeId: $chargeId) {
      ...BasePayload
      object {
        id
        ...BaseCharge
      }
    }
  }
  ${payload.payloadFragment}
  ${charges.baseCharge}
`
