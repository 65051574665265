import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

interface IFilterDrawerLabelProps {
  label: string
}

const useStyles = makeStyles(theme => ({
  root: {
    textTransform: "uppercase"
  }
}))

const FilterDrawerLabel: React.FunctionComponent<IFilterDrawerLabelProps> = ({
  label
}) => {
  const classes = useStyles()

  return (
    <Typography className={classes.root} variant="body1">
      {label}
    </Typography>
  )
}

export default FilterDrawerLabel
