// Autocomplete components should let you pass in the object for the row
// representation so an initial request doesn't need made. If it fails make a request
// Allow for easy configuration

import { useCallback, useEffect, useState } from "react"
import { OperationVariables, useLazyQuery } from "@apollo/client"
import { Popper } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import TextField, { TextFieldProps } from "@material-ui/core/TextField"
import Autocomplete, { AutocompleteProps } from "@material-ui/lab/Autocomplete"
import { useDebouncedCallback } from "use-debounce/lib"
import find from "lodash/find"
import isArray from "lodash/isArray"
import unionBy from "lodash/unionBy"

import { edgeLess } from "utils/apollo"
import { Maybe } from "client/types"

export type AutoCompleteQueryData = {
  __typename?: string
  options?: Maybe<any>
}

export type QueryVariablesMask = {
  filter?: Object
}

export type QueryAutocompleteProps<
  T,
  M extends boolean | undefined = boolean | undefined,
  D extends boolean | undefined = boolean | undefined,
  F extends boolean | undefined = boolean | undefined
> = Omit<AutocompleteProps<T, M, D, F>, "open"> & {
  query: any
  queryVariables?: Object
  queryOnMount?: Boolean
  textFieldProps?: Partial<TextFieldProps>
  createOption?: any
  paged?: boolean
}

// If this is defined as a literal default value inside the function signature,
// a new object will be created/assigned each time the component is rendered. Any
// dependency lists that use this will always see a new value because the comparison
// uses Object.is rather than deep compare.
const defaultQueryVariables = { first: 10 }

// TODO: document how to extend and work with the QueryAutocomplete
export function QueryAutocomplete<
  QueryData extends AutoCompleteQueryData,
  // Multiple extends boolean | undefined,
  // DisableClearable extends boolean | undefined,
  // FreeSolo extends boolean | undefined,
  QueryVariables = OperationVariables,
  OptionType = any
>({
  size = "small" as "small" | "medium" | undefined,
  query,
  queryVariables = defaultQueryVariables as QueryVariablesMask,
  queryOnMount = true,
  textFieldProps = {},
  createOption = null,
  filterOptions = el => el,
  paged = false,
  ...autocompleteProps
}): React.ReactElement<QueryAutocompleteProps<OptionType>> {
  // TODO: autocomplete prop merging
  const {
    value: _value,
    multiple,
    getOptionLabel = JSON.stringify
  } = autocompleteProps

  const [open, setOpen] = useState(false)
  const [wasOpened, setWasOpened] = useState(false)
  const [options, setOptions] = useState<OptionType[]>([])
  const [inputValue, setInputValue] = useState("")

  const [
    doMountQuery,
    { loading: loadingOnMount, data: dataOnMount }
  ] = useLazyQuery<QueryData, QueryVariables>(query, {
    // use results from cache first when autocompletes loads up to prevent
    // excessive requests on page loads. But to refresh that cached result,
    // users will need to type something that triggers the query with the
    // cache-and-network fetch policy below.
    fetchPolicy: "cache-first"
  })

  const [_doOptionQuery, { loading, data }] = useLazyQuery<
    QueryData,
    QueryVariables
  >(query, {
    // A cache-and-network query policy results in the loading icon being
    // displayed improperly on charges. The ticket to restructure ticket
    // details to prevent the unnecessary network attempt is
    // https://patriotrc.atlassian.net/browse/TI-978
    fetchPolicy: "network-only"
  })
  const doOptionQuery = useDebouncedCallback(_doOptionQuery, 300)

  useEffect(() => {
    if (loading || loadingOnMount || (!data && !dataOnMount)) {
      return undefined
    }

    const dataToUse = data ?? dataOnMount
    const options = paged
      ? dataToUse?.options?.objects ?? []
      : edgeLess(dataToUse?.options)
    if (options) setOptions(options)
  }, [data, dataOnMount, loading, loadingOnMount, paged])

  const buildQuery: (search: any) => OperationVariables = useCallback(
    search => ({
      variables: {
        ...queryVariables,
        filter: {
          ...(queryVariables.filter || {}),
          search
        }
      }
    }),
    [queryVariables]
  )

  useEffect(() => {
    if (queryOnMount) {
      // If the value is an object, use that to get the search label so that
      // the options match the current value.
      //
      // If the value is an array, there's no way to guarantee a search term
      // which matches all selected options, so query with an empty string. Any
      // missing options will get added below.
      // When expanded autocomplete search gets implemented
      // (https://patriotrc.atlassian.net/browse/TI-93) that should give us
      // additional tools to poke at this with.
      doMountQuery({
        ...buildQuery(isArray(value) ? "" : getOptionLabel(value ?? {}))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Swap out undefineds for empty values to avoid controlled-to-uncontrolled
  // warnings. This also seems to prevent some rendering bugs when values are
  // reset.
  let value
  if (_value === undefined) {
    // Matching the default value for the defaultValue prop
    value = multiple ? [] : null
  } else {
    value = _value
  }

  // CAUSES BY ITSELF, EVEN WITHOUT CALLBACK
  // The input text is sometimes getting reset, so we're controlling the inputValue
  // and ignoring reset events as recommended in
  // https://github.com/mui-org/material-ui/issues/18784#issuecomment-581434808
  const handleChange = useCallback(
    (e: any, value: string = "", reason: string) => {
      if (multiple) {
        if ("reset" !== reason) {
          setInputValue(value)
          doOptionQuery({
            ...buildQuery(value)
          })
        }
      } else {
        // This branch triggers the unnecessary queries when charges are added/updated,
        // even though the code isn't actually run. Hopefully,
        // https://patriotrc.atlassian.net/browse/TI-978 will fix.
        doOptionQuery({
          ...buildQuery(value)
        })
      }
    },
    [buildQuery, doOptionQuery, multiple]
  )

  const numberOfSelections = multiple ? value.length : -1
  // For multiselect autocompletes, reset the inputValue when the selection set
  // changes. Currently, users can only make changes by adding or deleting a
  // selection one at a time.
  useEffect(() => {
    if (multiple) {
      setInputValue("")
      doOptionQuery(buildQuery(""))
    }
  }, [buildQuery, doOptionQuery, multiple, numberOfSelections])

  // Trigger the cache-and-network query the first time the autocomplete is
  // opened to warm the cache for the initial search term to reflect any changes
  const handleOpen = useCallback(() => {
    setOpen(true)
    if (!wasOpened) {
      doOptionQuery({
        ...buildQuery(isArray(value) ? "" : getOptionLabel(value ?? {}))
      })
      setWasOpened(true)
    }
  }, [buildQuery, wasOpened, doOptionQuery, getOptionLabel, value])

  const combinedOptions = createOption ? [...options, createOption] : options
  // If the values aren't in the existing options, which used to happen because searching
  // by first and last name as text used to bring back nothing for <UserAutocomplete />,
  // add it.
  let displayedOptions: Array<OptionType | null>
  if (null == value) {
    displayedOptions = combinedOptions
  } else if (isArray(value)) {
    // This also has the effect of putting currently selected options at the top of the list
    displayedOptions = unionBy(value, combinedOptions, "id")
  } else if (undefined === find(combinedOptions, { id: value?.id })) {
    displayedOptions = [value, ...combinedOptions]
  } else {
    displayedOptions = combinedOptions
  }

  return (
    <Autocomplete
      filterSelectedOptions={multiple}
      {...autocompleteProps}
      value={value}
      open={open}
      size={size}
      onOpen={handleOpen}
      onClose={() => {
        setOpen(false)
      }}
      // Only use controlled inputValue workaround for multiselect autocompletes to avoid
      // having to reproduce onBlur functionality
      inputValue={multiple ? inputValue : undefined}
      // Without filterOptions, the options received when searching don't always
      // show. Not sure why.
      filterOptions={filterOptions}
      options={displayedOptions}
      loading={loading}
      onInputChange={handleChange}
      autoHighlight
      PopperComponent={SizedPopper}
      renderInput={params => (
        <TextField
          {...params}
          {...textFieldProps}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password" // This prevents browser autocomplete
          }}
          fullWidth
          variant="outlined"
          label={autocompleteProps.label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )
}

// Probably still dumb, but now allows general style passthrough and passes TS
// validation. Fix later.
const SizedPopper = props => (
  <Popper
    {...{
      ...props,
      // By changing width to minWidth we allow the width of the popper to be un-restricted
      // Have default maxWidth, but allow overrides
      style: {
        maxWidth: "95vw",
        ...props.style,
        width: undefined,
        minWidth: props.style.width
      }
    }}
  />
)
