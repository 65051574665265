import { Grid, Menu, MenuItem, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import { FieldProps } from "formik"
import TextField from "./TextField"
import get from "lodash/get"
import map from "lodash/map"
import reduce from "lodash/reduce"
import {
  bindMenu,
  bindTrigger,
  usePopupState
} from "material-ui-popup-state/hooks"
import { useRef, useState } from "react"
import { Button } from "components"

export interface TagSelectFieldProps extends FieldProps {
  sourceField: string
  tagGetter: (...args: any[]) => any
  disabled: boolean
  label: string
  placeholder?: string
  sourcePlaceholder?: string
  ignoreSelection?: boolean
}

const insertAt = (str, sub, pos) =>
  `${str.slice(0, pos)}${sub}${str.slice(pos)}`

type TagOption = {
  index: number
  label: string
}

const useStyles = makeStyles(theme => ({
  buttons: {
    "& > :not(:last-child)": {
      marginRight: theme.spacing(0.5)
    }
  }
}))

const TagSelectField: React.ComponentType<TagSelectFieldProps> = ({
  tagGetter,
  sourceField,
  label,
  placeholder,
  sourcePlaceholder,
  ignoreSelection,
  disabled,
  ...props
}) => {
  const { field, form } = props
  const classes = useStyles()
  const menuState = usePopupState({
    variant: "popover",
    popupId: `${label}-option-menu`
  })

  const inputRef = useRef<HTMLInputElement>()
  const [selectionStart, setSelectionStart] = useState<number | null>(null)
  const updateSelectionStart = () =>
    setSelectionStart(
      inputRef?.current?.selectionStart ?? field.value?.length ?? 0
    )
  const source = get(form.values, sourceField)
  const placeholderValue = sourcePlaceholder
    ? get(form.values, sourcePlaceholder)
    : placeholder || ""

  const tags: TagOption[] = map(source, (row, index: number) => ({
    index,
    label: tagGetter(row)
  }))

  // Only set tag options that need inserted
  const options = reduce<TagOption, TagOption[]>(
    tags,
    (acc, cur, _) => {
      if (field.value?.includes(cur.label)) return acc
      acc.push(cur)
      return acc
    },
    []
  )

  const handleAdd = option => {
    menuState.close()
    const existing = field.value ?? ""

    const insert = value => {
      const newValue = insertAt(
        existing,
        value,
        ignoreSelection
          ? field.value?.length ?? 0
          : selectionStart ?? field.value?.length ?? 0
      )
      form.setFieldValue(field.name, newValue)
    }
    if (selectionStart === 0 && existing.length > 0 && !ignoreSelection) {
      insert(`${option.label}, `)
    } else if (existing.length > 0) {
      insert(`, ${option.label}`)
    } else {
      insert(option.label)
    }
  }
  const handleReset = () => {
    form.setFieldValue(field.name, "")
  }

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="subtitle2">{label}</Typography>
        </Grid>
        <Grid className={classes.buttons} item>
          <Button
            size="small"
            disabled={disabled || !field.value}
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            size="small"
            endIcon={<KeyboardArrowDownIcon />}
            aria-label="insert tag"
            aria-haspopup="true"
            disabled={disabled || options.length === 0}
            {...bindTrigger(menuState)}
          >
            Insert Tag
          </Button>
          <Menu
            {...bindMenu(menuState)}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: "20ch"
              }
            }}
          >
            {options.map(option => (
              <MenuItem key={option.index} onClick={() => handleAdd(option)}>
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            {...props}
            disabled={disabled}
            multiline
            fullWidth
            placeholder={disabled ? "Generator Disabled" : placeholderValue}
            inputRef={inputRef}
            variant="standard"
            onSelect={updateSelectionStart}
          />
        </Grid>
      </Grid>
    </>
  )
}

TagSelectField.displayName = "TagSelectField"

export default TagSelectField
