import { Fragment } from "react"
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  TypographyProps,
  Grid
} from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { makeStyles } from "@material-ui/core/styles"
import { Maybe, WellDetailsCardFragment } from "client/types"
import clsx from "clsx"
import formatcoords from "formatcoords"
import { Button, IButtonProps } from "components"

const useStyles = makeStyles(
  theme => ({
    root: {
      width: "100%",
      display: "flex",
      flexWrap: "nowrap",
      "&$vertical": {
        flexDirection: "column",
        maxWidth: 258
      }
    },
    vertical: {},
    horizontal: {},
    details: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1
    },
    content: {
      "&$horizontal": {
        paddingLeft: 0
      }
    },
    wellName: {},
    map: {
      position: "relative",
      flexShrink: 0,
      flexBasis: 250,
      margin: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      ":not($vertical) &": {
        minWidth: 100,
        maxWidth: 250
      },
      "$vertical &": {
        marginBottom: 0,
        minHeight: 100,
        maxHeight: 150
      }
    },
    mapAddy: {
      position: "absolute",
      top: theme.spacing(0.5),
      left: theme.spacing(1),
      display: "flex",
      flexDirection: "column"
    },
    mapLoc: {
      position: "absolute",
      bottom: theme.spacing(0.5),
      left: theme.spacing(1),
      display: "flex"
    },
    directions: {
      // padding: theme.spacing(1)
    }
  }),
  { name: "WellDetailsCard", classNamePrefix: "WDC" }
)

export type WellDetailsCardProps = {
  className?: String
  well: Maybe<WellDetailsCardFragment>
  loading?: Boolean
  vertical?: Boolean
  includeLink?: Boolean
  linkButtonProps?: Partial<IButtonProps>
  header?: React.ReactNode
  headerTypographyProps?: TypographyProps
  subheader?: React.ReactNode
  subheaderTypographyProps?: TypographyProps
}

// TODO: add optional well link
export const WellDetailsCard: React.ComponentType<WellDetailsCardProps> = ({
  className,
  well,
  loading = false,
  vertical = false,
  includeLink = false,
  linkButtonProps = {
    size: "small",
    color: "secondary"
  },
  header,
  headerTypographyProps,
  subheader,
  subheaderTypographyProps,
  ...rest
}) => {
  const classes = useStyles()

  let media: React.ReactNode = null
  let content: React.ReactNode = null

  if (!loading && well) {
    // Map image
    // Address over map
    // Lat/Lng formatted
    media = (
      <CardMedia
        className={classes.map}
        // image={well.thumbnail ?? ""}
        title="map image"
      >
        <div className={classes.mapAddy}>
          <Typography variant="caption" color="primary">
            {well?.city?.name}, {well?.city?.state?.code},{" "}
            {well?.city?.state?.country?.code}
          </Typography>
          <Typography variant="caption" color="primary">
            {well?.basin?.name}
          </Typography>
        </div>
        <div className={classes.mapLoc}>
          <Typography variant="caption" color="primary">
            {well.latitude
              ? formatcoords(well.latitude, well.longitude).format("f")
              : null}
          </Typography>
        </div>
      </CardMedia>
    )
    // Title and API number
    // View well button. (optional)
    content = (
      <Fragment>
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-start"
          direction={vertical ? "column" : "row"}
        >
          <Grid item>
            <Typography
              className={classes.wellName}
              component="h5"
              variant="h5"
            >
              {well?.name}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              API: {well.apiNumber}
            </Typography>
          </Grid>
          {includeLink ? (
            <Grid item>
              <Button {...linkButtonProps}>View Well</Button>
            </Grid>
          ) : null}
        </Grid>
        <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
          {well?.directions}
        </Typography>
      </Fragment>
    )
  } else {
    media = (
      <CardMedia
        className={classes.map}
        image="https://via.placeholder.com/250x150"
        title="map image"
      ></CardMedia>
    )
    content = (
      <Fragment>
        <Skeleton animation="wave" variant="text" height={23} width="40%" />
        <Skeleton
          animation="wave"
          variant="text"
          height={18}
          width="20%"
          style={{ marginBottom: 10 }}
        />
        <Skeleton animation="wave" variant="text" height={15} width="80%" />
        <Skeleton animation="wave" variant="text" height={15} width="60%" />
        <Skeleton animation="wave" variant="text" height={15} width="70%" />
      </Fragment>
    )
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className, {
        [classes.vertical]: vertical
      })}
      data-testid="well-details-card"
    >
      {media}
      <div className={classes.details}>
        <CardContent
          className={clsx(classes.content, { [classes.horizontal]: !vertical })}
        >
          {content}
        </CardContent>
      </div>
    </Card>
  )
}

export default WellDetailsCard
