import { gql } from "@apollo/client"

const countyFragments = {
  countyOption: gql`
    fragment CountyOption on County {
      id
      name
    }
  `
}

export default countyFragments
