import { useContext } from "react"
import { Grid, Icon, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import CheckIcon from "@material-ui/icons/Check"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import clsx from "clsx"
import { Field, useFormikContext } from "formik"
import moment from "moment"
import castArray from "lodash/castArray"

import { DisplayTicket } from "views/TicketDetails/TicketDetails"
import { ParsedRunningProcedureTicketStepResult } from "hooks/runningProcedures"

import UserContext from "context/user"
import SwitchField from "components/formik/SwitchField"
import { useFormStateContext } from "hooks/forms/useFormState"
import StepNotFound from "./StepNotFound"

export type SwitchStepProps = {
  index: number
  attributes: string | Array<string>
  instructions?: string | null
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  content: {
    display: "flex",
    alignItems: "center"
  },
  lockedDataDisplay: {
    display: "flex",
    alignItems: "center"
  },
  lockedIcon: {
    marginRight: theme.spacing(1)
  },
  lockedSuccess: {
    color: theme.palette.success.main
  },
  lockedWarning: {
    color: theme.palette.error.main
  }
}))

export const dataTestid = "switch-step"
export const dateFormat = "MM/DD/YY"
export const timeFormat = "hh:mm a"
const SwitchStep: React.FunctionComponent<SwitchStepProps> = ({
  index,
  instructions,
  attributes: _attributes
}) => {
  const classes = useStyles()
  const formik = useFormikContext<Partial<DisplayTicket>>()
  const user = useContext(UserContext)
  const step = formik.values?.steps?.[
    index
  ] as ParsedRunningProcedureTicketStepResult

  const { getMetadata } = useFormStateContext()

  // If the running procedure step order is broken, there might be no step or no
  // parsedData. If so, display the step not found message instead of crashing.
  // parsedData should always be an object, so no need to worry about expected
  // falsey values from short-circuiting the display.
  if (!step?.parsedData) return <StepNotFound index={index} />

  const { isSubmitting } = formik
  const {
    isDocumentationLocked = false,
    isMutationLoading = false
  } = getMetadata()
  const attributes = castArray(_attributes)

  // Use optional chaining in case a running procedure is malformed
  const checked = step.parsedData?.[attributes[0]]?.data
  const creator = step.creator || user?.displayName
  const completedOn = step.parsedData?.[attributes[0]]?.completedOn

  const verificationInfo = (
    <Typography>
      Verified by <strong>{creator}</strong> at{" "}
      <strong>
        {moment(completedOn as string)
          .local()
          .format(timeFormat)}
      </strong>{" "}
      on{" "}
      <strong>
        {moment(completedOn as string)
          .local()
          .format(dateFormat)}
      </strong>
    </Typography>
  )
  const LockIcon = checked ? CheckIcon : ErrorOutlineIcon

  const content = isDocumentationLocked ? (
    <>
      <Grid item>
        <Typography>{instructions}</Typography>
      </Grid>
      <Grid className={classes.lockedDataDisplay} item xs={12}>
        <Icon
          className={clsx(
            classes.lockedIcon,
            checked ? classes.lockedSuccess : classes.lockedWarning
          )}
        >
          <LockIcon />
        </Icon>
        {checked ? (
          verificationInfo
        ) : (
          <Typography className={classes.lockedWarning}>
            NOT VERIFIED
          </Typography>
        )}
      </Grid>
    </>
  ) : (
    <Grid
      className={classes.content}
      container
      item
      xs={12}
      justifyContent="space-between"
      wrap="nowrap"
    >
      <Grid item>
        <Field
          name={`steps[${index}].parsedData.${attributes[0]}.data`}
          id={`${index}-${attributes[0]}`}
          component={SwitchField}
          disabled={isSubmitting || isMutationLoading}
          label={instructions}
          inputProps={{
            onChange: evt => {
              formik.setFieldValue(
                `steps[${index}].parsedData.${attributes[0]}.data`,
                evt.target.checked
              )

              const newCompletedOn = evt.target.checked
                ? moment.utc().format(`YYYY-MM-DDTHH:mm:ss.SSSSSSZ`)
                : ""

              formik.setFieldValue(
                `steps[${index}].parsedData.${attributes[0]}.completedOn`,
                newCompletedOn
              )
            }
          }}
        />
      </Grid>
      <Grid item>{checked && verificationInfo}</Grid>
    </Grid>
  )

  return (
    <Grid
      className={classes.root}
      container
      spacing={2}
      data-testid={dataTestid}
    >
      {content}
    </Grid>
  )
}

export default SwitchStep
