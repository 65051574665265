import { gql } from "@apollo/client"
import addressFragments from "./addresses"
import inventoryFragments from "./inventory"
import signatureFragments from "./signatures"
import companyFragments from "./companies"

const salesOrderFragments = {
  fullSalesOrder: gql`
    fragment FullSalesOrder on SalesOrder {
      id
      number
      created
      modified
      cabinetId
      billTo {
        ...FullSalesOrderAddress
      }
      shipTo {
        ...FullSalesOrderAddress
      }
      company {
        ...CompanyOption
        ...CompanySignatures
      }
      totalPrice
      totalTax
      itemTotal
      issuedDate
      scheduledDate
      shippedDate
      shippingTerms
      paymentTerms
      carrier
      fobPoint
      salesman {
        id
        email
        fullName
        phoneNumber
      }
      # status management
      soStatus: status
      transitions: availableStatusTransitions {
        priority
        label
        method
        direction
      }
      # Signature info
      signatureState
      items {
        id
        created
        modified
        order
        quantity
        productPrice
        totalPrice
        itemType
        itemNumber
        note
        description
        uom {
          short
          long
        }
        item {
          ...BaseInventoryItem
        }
      }
    }
    ${addressFragments.fullSalesOrderAddress}
    ${inventoryFragments.baseInventoryItem}
    ${companyFragments.companyOption}
    ${companyFragments.companySignatures}
  `,
  listSalesOrderFragment: gql`
    fragment ListSalesOrderFragment on SalesOrder {
      id
      status
      shippedDate
      statusChangedDate
      ...SignatureStateDisplay
      company {
        id
        name
      }
      shipTo {
        id
        name
      }
      created
      ERPClass
      ERPStatus
      itemsCount
      modified
      number
      totalPrice
    }
    ${signatureFragments.signatureInfo}
  `,
  printSalesOrderDetails: gql`
    fragment PrintSalesOrderDetails on SalesOrder {
      urls {
        detail
      }
    }
  `
}

export default salesOrderFragments
