import { isValidElement } from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { CancelButton, ConfirmButton } from "components"

const useStyles = makeStyles(() => ({
  actions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
}))

const ConfirmationDialog = ({ options, onCancel, onConfirm, ...props }) => {
  const classes = useStyles()

  const {
    title,
    description,
    confirmationText,
    dialogProps,
    confirmationButtonProps,
    cancellationButtonProps
  } = options

  return (
    <Dialog {...props} {...dialogProps} onClose={onCancel}>
      {title && (
        <DialogTitle disableTypography>
          <Typography variant="h4">{title}</Typography>
        </DialogTitle>
      )}
      {/* TODO: detect if description is string and wrap in DialogContentText */}
      {/* TODO: the DialogContextText gutterButton prop appears to have no effect */}
      <DialogContent>
        {isValidElement(description)
          ? description
          : description && (
              <DialogContentText gutterBottom={false}>
                {description}
              </DialogContentText>
            )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <CancelButton {...cancellationButtonProps} onClick={onCancel} />
        <ConfirmButton {...confirmationButtonProps} onClick={onConfirm}>
          {confirmationText}
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
