import AddIcon from "@material-ui/icons/Add"
import ButtonBase, { IButtonBaseProps, TitanButtonColor } from "../ButtonBase"

export interface IAddButtonProps
  extends Omit<
    IButtonBaseProps,
    "startIcon" | "endIcon" | "icon" | "color" | "textColor" | "variant"
  > {}

// Button with leading + sign for the various createObject buttons
const AddButton: React.FunctionComponent<IAddButtonProps> = props => (
  <ButtonBase
    color={TitanButtonColor.Green}
    startIcon={<AddIcon />}
    variant="contained"
    {...props}
  />
)

export default AddButton
