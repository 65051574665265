import DropZone from "components/DropZone/DropZone"
import { DropzoneOptions, DropEvent, FileRejection } from "react-dropzone"
import { useAttachmentsContext } from "../hooks/useAttachments"

export enum DropZoneHandlerNames {
  onDrop = "onDrop",
  onDropAccepted = "onDropAccepted",
  onDropRejected = "onDropRejected",
  getFilesFromEvent = "getFilesFromEvent",
  onFileDialogCancel = "onFileDialogCancel",
  onDragEnter = "onDragEnter",
  onDragOver = "onDragOver",
  onDragLeave = "onDragLeave"
}
// These props can be set via props sent to <Attachments />
export enum SettableDropZonePropNames {
  disabled = "disabled",
  maxSize = "maxSize",
  minSize = "minSize",
  // multiple file uploads disabled for now
  // multiple = "multiple",
  noClick = "noClick",
  noDrag = "noDrag",
  noDragEventsBubbling = "noDragEventsBubbling",
  noKeyboard = "noKeyboard",
  preventDropOnDocument = "preventDropOnDocument"
}

export type InternalDropZoneProps = Omit<
  DropzoneOptions,
  "accept" | "multiple" | "onDrop"
> & {
  onDrop?: <T extends File>(
    acceptedFiles: T[],
    rejectedFiles: FileRejection[],
    event: DropEvent,
    ...args: any[]
  ) => void
}

export type DropZoneProps = {}

const AttachmentsDropZone: React.FunctionComponent<DropZoneProps> = () => {
  const { accept, dropzoneProps, dropzoneHandlers } = useAttachmentsContext()
  return (
    <DropZone
      accept={accept}
      dropzoneProps={dropzoneProps}
      dropzoneHandlers={dropzoneHandlers}
    />
  )
}

export default AttachmentsDropZone
