// vim: foldmethod=marker:foldmarker={{{,}}}
// imports {{{
import { createContext, useCallback, useContext, useRef } from "react"
import {
  OptionsObject,
  useSnackbar,
  VariantType as NotistackVariantType
} from "notistack"
import warning from "tiny-warning"
import * as Sentry from "@sentry/react"
import { is } from "ts-type-guards"
import castArray from "lodash/castArray"
import isEqual from "lodash/isEqual"
import join from "lodash/join"
import map from "lodash/map"
import noop from "lodash/noop"
import omit from "lodash/omit"
import reduce from "lodash/reduce"
import produce from "immer"

import { SnackbarClose, SnackbarContent } from "components/Snackbar"

import { Payload } from "client/types"
// imports }}}

// FormStateContextType {{{
export type FormStateContextType = {
  setFieldErrors: (errors: Payload["errors"]) => void
  setFormErrors: (
    errors: string | Error | Array<string | Error>,
    options?: Omit<OptionsObject, "variant">
  ) => void
  setFormInfoMessages: (
    infos: string | Array<string>,
    options?: Omit<OptionsObject, "variant">
  ) => void
  setFormSuccesses: (
    successes: string | Array<string>,
    options?: Omit<OptionsObject, "variant">
  ) => void
  clearError: (field: string) => void
  getError: (field: string) => string | null
  clearMetadata: () => void
  clearMetadataField: (field: string) => void
  getMetadata: () => Record<string, unknown>
  getMetadataField: (field: string) => unknown | null
  setMetadata: (metadata: Record<string, unknown>) => void
  setMetadataField: (field: string, data: unknown) => void
  metadata?: any
  _default?: boolean
}
// FormStateContextType }}}

// default options {{{
export const defaultErrorOptions = {
  persist: true,
  action: SnackbarClose
}
export const defaultInfoOptions = {
  persist: false,
  autoHideDuration: 3000,
  action: SnackbarClose
}
export const defaultSuccessOptions = {
  persist: false,
  autoHideDuration: 3000,
  action: SnackbarClose
}
// default options }}}

// defaultFormStateContext {{{
const defaultFormStateContext: FormStateContextType = {
  setFieldErrors: () => {},
  setFormErrors: () => {},
  setFormInfoMessages: () => {},
  setFormSuccesses: () => {},
  clearError: () => {},
  getError: () => null,
  clearMetadata: () => null,
  clearMetadataField: () => null,
  getMetadata: () => ({}),
  getMetadataField: () => null,
  setMetadata: () => {},
  setMetadataField: () => {},
  _default: true
}
// defaultFormStateContext }}}

const FormStateContext = createContext<FormStateContextType>(
  defaultFormStateContext
)
const FormStateProvider = FormStateContext.Provider
const FormStateConsumer = FormStateContext.Consumer

// useFormStateContext() {{{
const useFormStateContext = () => {
  const formErrorContext = useContext(FormStateContext)
  warning(
    "test" === process.env.NODE_ENV || !formErrorContext._default,
    "A form state consumer did not find a form state provider"
  )

  return formErrorContext
}
// useFormStateContext }}}

// useFormState() {{{
// To avoid conflicts with formik.errors preventing save attempts, we track
// back-end errors separately
const useFormState = (): FormStateContextType => {
  // const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({})
  // const [metadata, setMetadata] = useState<Record<string, unknown>>({
  //   foo: "bar"
  // })

  // Play nice w/ tests
  const utils = useSnackbar()
  const enqueueSnackbar = utils?.enqueueSnackbar ?? noop

  const fieldErrors = useRef<Record<string, string>>({})
  const metadata = useRef<Record<string, unknown>>({})

  // setFieldErrors {{{
  const setFieldErrors = useCallback(
    (
      val: Record<string, string> | ((...args: any[]) => Record<string, string>)
    ) => {
      if (is(Function)(val)) {
        fieldErrors.current = val(fieldErrors.current)
      } else {
        fieldErrors.current = val
      }
    },
    []
  )
  // setFieldErrors }}}

  // setMetadata {{{
  const setMetadata = useCallback(
    (
      val:
        | Record<string, unknown>
        | ((...args: any[]) => Record<string, unknown>)
    ) => {
      if (is(Function)(val)) {
        metadata.current = val(metadata.current)
      } else {
        metadata.current = val
      }
    },
    []
  )
  // setMetadata }}}

  // clearError {{{
  const clearError = useCallback(
    (field: string) => {
      setFieldErrors(errors => omit(errors, field))
    },
    [setFieldErrors]
  )
  // clearError }}}

  // getError {{{
  const getError = useCallback((field: string) => {
    return fieldErrors.current?.[field] ?? null
  }, [])
  // getError }}}

  // setBackEndErrors {{{
  const setBackEndErrors = useCallback(
    (_errors: Payload["errors"]) => {
      const newErrors = reduce(
        _errors,
        (acc, fieldError) =>
          fieldError && fieldError.name
            ? {
                ...acc,
                [fieldError.name]: join(map(fieldError?.values, "error"), ", ")
              }
            : acc,
        {}
      )

      if (!isEqual(fieldErrors.current, newErrors)) {
        setFieldErrors(newErrors)
      }
    },
    [setFieldErrors]
  )
  // setBackEndErrors }}}

  // displaySnackbars {{{
  // We're not tracking overall errors or success for now, just displaying the snackbars
  const displaySnackbars: (
    variant: NotistackVariantType
  ) => (
    defaultOptions: Omit<OptionsObject, "variant">
  ) => (
    messages: string | Array<string>,
    // Not allowing user options to override the snackbar variant -- if we want to add info,
    // warning or other snackbars, that's probably a design decision, as well as a code
    // decision to see if we want to allow overrides to setFormErrors/setFormSuccesses or
    // add new setFormInfoNotifications/setFormWarnings functions.
    options?: Omit<OptionsObject, "variant">
  ) => void = useCallback(
    variant => defaultOptions => (messages, options = {}) => {
      const mergedOptions = { ...defaultOptions, variant, ...options }
      castArray(messages).forEach(message => {
        enqueueSnackbar(
          <SnackbarContent message={message} data-variant={variant} />,
          mergedOptions
        )
      })
    },
    [enqueueSnackbar]
  )
  // displaySnackbars }}}

  // setFormErrors {{{
  const setFormErrors = useCallback(
    (errors: string | Error | Array<string | Error>, options = {}) => {
      const errorText = map<Error | string, string>(
        castArray(errors),
        error => {
          Sentry.captureException(error)
          if (error instanceof Error) {
            return String(error)
          } else {
            return error
          }
        }
      )
      displaySnackbars("error")(defaultErrorOptions)(errorText, options)
    },
    [displaySnackbars]
  )
  // setFormErrors }}}

  // setFormInfoMessages {{{
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setFormInfoMessages = useCallback(
    displaySnackbars("info")(defaultInfoOptions),
    [displaySnackbars]
  )
  // setFormInfoMessages }}}

  // setFormSuccesses {{{
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setFormSuccesses = useCallback(
    displaySnackbars("success")(defaultSuccessOptions),
    [displaySnackbars]
  )
  // setFormSuccesses }}}

  // clearMetadata {{{
  // Using produce and equality checks to avoid infinite render loops
  // The strict equality checks work because immer uses structural sharing
  const clearMetadata = useCallback(() => {
    if (Object.keys(metadata.current).length > 0) setMetadata({})
  }, [setMetadata])
  // clearMetadata }}}

  // clearMetadataField {{{
  const clearMetadataField = useCallback(
    (field: string) => {
      const newMetadata = produce(metadata.current, draft => {
        delete draft[field]
      })
      if (metadata.current !== newMetadata) setMetadata(newMetadata)
    },
    [setMetadata]
  )
  // clearMetadataField }}}

  const getMetadata = useCallback(() => metadata.current, [])

  // getMetadataField {{{
  const getMetadataField = useCallback(
    (field: string) => metadata.current[field] ?? null,
    []
  )
  // getMetadataField }}}

  // _setMetadata {{{
  const _setMetadata = useCallback(
    (newMetadata: Record<string, unknown>) => {
      if (!isEqual(metadata.current, newMetadata)) setMetadata(newMetadata)
    },
    [setMetadata]
  )
  // _setMetadata }}}

  // setMetadataField {{{
  const setMetadataField = useCallback(
    (field: string, data: unknown) => {
      const newMetadata = produce(metadata.current, draft => {
        draft[field] = data
      })
      if (metadata.current !== newMetadata) setMetadata(newMetadata)
    },
    [setMetadata]
  )
  // setMetadataField }}}

  // return {{{
  return {
    clearError,
    getError,
    setFieldErrors: setBackEndErrors,
    setFormErrors,
    setFormInfoMessages,
    setFormSuccesses,
    clearMetadata,
    clearMetadataField,
    getMetadata,
    getMetadataField,
    setMetadata: _setMetadata,
    setMetadataField,
    metadata: metadata.current
  }
  // return }}}
}
// useFormState }}}

export default useFormState
export {
  FormStateContext,
  FormStateConsumer,
  FormStateProvider,
  useFormStateContext
}
