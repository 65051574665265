import { SvgIconProps } from "@material-ui/core"
import { is } from "ts-type-guards"
import map from "lodash/map"
import NavLink from "./NavLink"
import NavLinkList from "./NavLinkList"
import NavSection from "./NavSection"
import uniqueId from "lodash/uniqueId"
import { WaffleValueType } from "context/WaffleContext"

export type WaffleNavNode = {
  flag?: string
  sample?: string
  switch?: string
}

export type NavLinkNode = WaffleNavNode & {
  title: string
  href: string
  icon?: React.ComponentType<SvgIconProps>
  disabled?: boolean
  label?: React.Component | Function | JSX.Element
  dataTestid?: string
}

export type NavLinkListNode = WaffleNavNode & {
  title: string
  icon: React.ComponentType<SvgIconProps>
  items: Array<NavLinkNode | NavLinkListNode>
  dataTestid?: string
}

export type NavSectionNode = WaffleNavNode & {
  subheader: string
  items: Array<NavLinkNode | NavLinkListNode>
  dataTestid?: string
}

export type NavConfigNode = NavLinkNode | NavLinkListNode | NavSectionNode

const checkWaffle = (item, waffle) => {
  if (item.flag) return waffle.flags[item.flag]
  if (item.switch) return waffle.switches[item.switch]
  if (item.sample) return waffle.samples[item.sample]
  return true
}

// TODO: Explore JS morphism libraries, possibly implement as an isomorphism. This works for
// now, but I'm not too comfortable w/ the task-specific recursion. Would need to check on
// strategies for potentially doubly-recursive functions, and then learn how those work.
export const createNavNode = (
  item,
  index = undefined,
  waffle: WaffleValueType
) => {
  // stop traversal if waffle deems this nav item inaccessible
  if (!checkWaffle(item, waffle)) return undefined

  // Array case
  if (is(Array)(item)) {
    return map(item, node => createNavNode(node, index, waffle))
  }
  // Section case
  if (item.subheader) {
    return <NavSection {...item} key={`section${index ?? uniqueId()}`} />
  }
  // Expandable button case
  if (item.items) {
    return <NavLinkList {...item} key={`linklist${index ?? uniqueId()}`} />
  }
  // Base case
  return <NavLink {...item} key={`link${index ?? uniqueId()}`} />
}

export const stripTrailingSlash = (el: string) => el.replace(/(.*)\/$/, "$1")
