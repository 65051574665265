import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ButtonBase, { IButtonBaseProps } from "../ButtonBase"

export interface IMenuDropDownButtonProps
  extends Omit<IButtonBaseProps, "startIcon" | "endIcon" | "icon"> {}

const MenuDropDownButton: React.FunctionComponent<IMenuDropDownButtonProps> = props => (
  <ButtonBase icon startIcon={<ExpandMoreIcon />} {...props} />
)

export default MenuDropDownButton
