import { useCallback, useEffect, useState } from "react"

export interface UsePollingStateArgs {
  polling?: boolean
  pollInterval?: number
}

export interface PollingState {
  on: boolean
  interval: number
}

export interface UsePollingStateReturn {
  // polling active state
  on: boolean
  // requested poll interval
  interval: number
  // Poll interval to use in queries
  pollInterval: number
  // Set new polling state or interval
  setPolling: (on: boolean, interval: number) => void
}

// Micro state handler for unified polling state
export const defaultPollInterval = 60 * 1000 // one minute polling
export default function usePollingState({
  polling = false,
  pollInterval = defaultPollInterval
}: UsePollingStateArgs): UsePollingStateReturn {
  const [on, setOn] = useState<boolean>(polling)
  const [interval, setInterval] = useState<number>(pollInterval)

  useEffect(() => {
    setOn(polling)
  }, [polling, setOn])

  useEffect(() => {
    setInterval(pollInterval)
  }, [pollInterval, setInterval])

  const setPolling = useCallback(
    (on: boolean, interval: number) => {
      setOn(on)
      setInterval(interval)
    },
    [setOn, setInterval]
  )

  return {
    on,
    interval,
    pollInterval: on ? interval : 0,
    setPolling
  }
}
