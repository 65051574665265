import clsx from "clsx"
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Typography
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import {
  CardDisplayStyle,
  listThumbnailSize,
  listThumbnailMargin,
  gridProps,
  titleTypographyVariant,
  subheaderTypographyVariant,
  contentTypographyVariant
} from "./DetailsCard"

interface IPlaceHolderCardProps {
  display?: CardDisplayStyle
}

const useCardStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  header: {
    "& .MuiCardHeader-content": {
      overflow: "hidden"
    }
  },
  headerAndContent: {},
  thumbnail: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: 0,
    borderRadius: theme.spacing(0.5),
    height: 120,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.text.secondary
  },
  truncText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  title: {
    lineHeight: "1.3333em"
  },
  status: {
    paddingTop: 0,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end"
  }
}))

const useListStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row"
  },
  header: {
    paddingLeft: theme.spacing(0),
    "& .MuiCardHeader-content": {
      overflow: "hidden"
    }
  },
  headerAndContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: `calc(100% - ${
      listThumbnailSize + theme.spacing(listThumbnailMargin * 2)
    }px)`
  },
  thumbnail: {
    margin: theme.spacing(listThumbnailMargin),
    width: listThumbnailSize,
    minWidth: listThumbnailSize,
    height: listThumbnailSize,
    borderRadius: theme.spacing(0.5),
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  truncText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  title: {
    lineHeight: "1.3333em",
    fontWeight: 800
  },
  status: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end"
  }
}))

export const dataTestid = "placeholder-card"

const PlaceHolderCard: React.FunctionComponent<IPlaceHolderCardProps> = ({
  display = CardDisplayStyle.List
}) => {
  const classes = {
    [CardDisplayStyle.Card]: useCardStyles(),
    [CardDisplayStyle.List]: useListStyles()
  }

  return (
    <Grid item {...gridProps[display]} data-testid={dataTestid}>
      <Card className={classes[display].root}>
        <CardMedia className={classes[display].thumbnail}>
          <Typography variant="h1" component="span">
            ?
          </Typography>
        </CardMedia>
        <div className={classes[display].headerAndContent}>
          <CardHeader
            className={classes[display].header}
            title="No Attachments Found"
            titleTypographyProps={{
              className: clsx(
                classes[display].truncText,
                classes[display].title
              ),
              variant: titleTypographyVariant[display]
            }}
            subheader="...so lonely"
            subheaderTypographyProps={{
              className: clsx(classes[display].truncText),
              variant: subheaderTypographyVariant[display]
            }}
          />
          <CardContent className={classes[display].status}>
            <Typography
              variant={contentTypographyVariant[display]}
              color="textSecondary"
            >
              Tell me I'm your everything
            </Typography>
          </CardContent>
        </div>
      </Card>
    </Grid>
  )
}

export default PlaceHolderCard
