import clsx from "clsx"
import { makeStyles } from "@material-ui/styles"
import {
  Grid,
  IconButton,
  Theme,
  GridProps,
  Hidden,
  InputProps
} from "@material-ui/core"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import RotateLeftIcon from "@material-ui/icons/RotateLeft"

import Search from "./Search"
import useWaffle from "hooks/useWaffle"
import { IFilterUndo } from "hooks/tables"

export interface ISearchBarProps {
  undo?: IFilterUndo<any>
  onSearch?: (...args: any[]) => any
  onReset?: (...args: any[]) => any
  searchInputProps?: InputProps
  searchFilterKey?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  searchArea: {
    display: "flex",
    flexGrow: 0.2,
    flexDirection: "row",
    alignItems: "center",
    // Allow for wider search area on large screen sizes.
    [theme.breakpoints.up("md")]: {
      minWidth: 560
    }
  },
  search: {
    flexGrow: 1,
    // maxWidth: 480,
    // flexBasis: 480,
    marginRight: theme.spacing(1)
  },
  filterButton: {
    marginLeft: "auto"
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  }
}))

export type SearchBarProps = ISearchBarProps & Omit<GridProps, "container">

const SearchBar: React.FunctionComponent<SearchBarProps> = ({
  onSearch,
  onReset,
  className,
  undo,
  searchInputProps,
  searchFilterKey = "search",
  ...rest
}) => {
  const classes = useStyles()
  const waffle = useWaffle()

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      spacing={3}
      {...rest}
    >
      <Grid item className={classes.searchArea} style={{ flexGrow: 1 }}>
        {onSearch !== undefined ? (
          <Search
            className={classes.search}
            onSearch={onSearch}
            inputProps={searchInputProps}
            searchFilterKey={searchFilterKey}
          />
        ) : null}
        {/* Hide the undo feature in production for now... */}
        <Hidden smDown xlDown={!waffle.flags.showFilterUndo}>
          {undo ? (
            <>
              <span>
                <IconButton
                  aria-label="back"
                  size="small"
                  disabled={!undo.hasPast}
                  onClick={() => undo.undo()}
                >
                  <NavigateBeforeIcon />
                </IconButton>
              </span>
              <span>
                <IconButton
                  aria-label="forward"
                  size="small"
                  disabled={!undo.hasFuture}
                  onClick={() => undo.redo()}
                >
                  <NavigateNextIcon />
                </IconButton>
              </span>
            </>
          ) : null}
          {onReset ? (
            <IconButton aria-label="reset" size="small" onClick={onReset}>
              <RotateLeftIcon />
            </IconButton>
          ) : null}
        </Hidden>
      </Grid>
    </Grid>
  )
}

export default SearchBar
