import { default as React, Suspense } from "react"
import { Outlet } from "react-router-dom"
import { CssBaseline, LinearProgress } from "@material-ui/core"
import { makeStyles, ThemeProvider } from "@material-ui/core/styles"

import { GoogleAnalytics } from "components"
import { WaffleProvider } from "context/WaffleContext"
import { themes } from "theme"

// TODO: web and print differences in container and content styles
const useStyles = makeStyles(
  theme => ({
    "@global": {
      body: {
        backgroundColor: "#fff"
      }
    },
    printContainer: {
      minHeight: "100vh",
      display: "flex",
      justifyContent: "center"
    },
    content: {
      flexGrow: 1,
      maxWidth: "560pt",
      "@media print &": {
        maxWidth: "auto"
      }
    }
  }),
  { name: "PrintLayoutStyles" }
)

const printContext = {
  triggerPrint() {
    window.print()
  },
  notifyCapture() {}
}

export const PrintContext = React.createContext({})

interface IPrintLayoutProps {}

const PrintLayout: React.FunctionComponent<IPrintLayoutProps> = () => {
  const classes = useStyles()

  return (
    <WaffleProvider polling={false}>
      <PrintContext.Provider value={printContext}>
        {/* Make sure we always use the light theme for printing */}
        <ThemeProvider theme={themes.light}>
          <CssBaseline />
          <GoogleAnalytics />
          <div className={classes.printContainer}>
            <div className={classes.content}>
              <Suspense fallback={<LinearProgress />}>
                <Outlet />
              </Suspense>
            </div>
          </div>
        </ThemeProvider>
      </PrintContext.Provider>
    </WaffleProvider>
  )
}

export default PrintLayout
