import { gql } from "@apollo/client"
import listPageFragments from "fragments/listPages"
import eventFragments from "fragments/events"
import notificationFragments from "fragments/notifications"
import payloadFragments from "fragments/payload"

export const GET_EVENT_BY_ID = gql`
  query getEventById($id: ID!) {
    event(id: $id) {
      ...SignatureEvent
    }
  }
  ${eventFragments.signatureEvent}
`
export const GET_OBJECT_EVENTS = gql`
  query getObjectEvents(
    $id: ID!
    $appLabel: String!
    $model: String!
    $first: Int
    $last: Int
    $after: String
    $before: String
    $association: EventAssociation = OBJECT
    $eventFilter: EventFilterInput
  ) {
    contentType: getContentTypeByKey(
      keys: { appLabel: $appLabel, model: $model }
    ) {
      id
      events: getEventsForObject(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $eventFilter
        association: $association
        id: $id
      ) {
        edges {
          cursor
          node {
            ...FullEvent
          }
        }
        ...PageInfo
      }
    }
  }
  ${eventFragments.fullEvent}
  ${listPageFragments.pageInfo}
`

export const GET_PAGED_OBJECT_EVENTS = gql`
  query getPagedObjectEvents(
    $id: ID!
    $appLabel: String!
    $model: String!
    $paged: PagedInput
    $association: EventAssociation = OBJECT
    $eventFilter: EventFilterInput
  ) {
    contentType: getContentTypeByKey(
      keys: { appLabel: $appLabel, model: $model }
    ) {
      events: getPagedEventsForObject(
        paged: $paged
        filter: $eventFilter
        association: $association
        id: $id
      ) {
        events: objects {
          ...FullEvent
        }
        ...PagedInfo
      }
    }
  }
  ${eventFragments.fullEvent}
  ${listPageFragments.pagedInfo}
`

export const GET_PAGED_EVENTS = gql`
  query getPagedEvents($paged: PagedInput, $eventFilter: EventFilterInput) {
    events: getPagedEvents(paged: $paged, filter: $eventFilter) {
      events: objects {
        ...FullEvent
      }
      ...PagedInfo
    }
  }
  ${eventFragments.fullEvent}
  ${listPageFragments.pagedInfo}
`

export const GET_ALL_EVENT_TYPES = gql`
  query getAllEventTypes {
    namespaces: allEventTypeNamespaces {
      name
      label
      types {
        id
        name
        label
      }
    }
  }
`

export const GET_USER_NOTIFICATIONS = gql`
  query getUserNotifications(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: NotificationFilterInput
  ) {
    user: currentUser @connection(key: "notificationUser") {
      id
      notifications: getNotifications(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: $filter
      ) {
        edges {
          cursor
          node {
            ...ListNotification
          }
        }
        ...PageInfo
      }
    }
  }
  ${notificationFragments.listNotification}
  ${listPageFragments.pageInfo}
`

export const MARK_NOTIFICATION_READ = gql`
  mutation markNotificationRead($ids: [ID]!) {
    markRead: markNotificationsRead(ids: $ids) {
      ...BasePayload
      notifications: objects {
        ...ListNotification
      }
    }
  }
  ${notificationFragments.listNotification}
  ${payloadFragments.payloadFragment}
`

export const GET_SIGNATURE_EVENTS = gql`
  query getSignatureEvents($id: ID!, $appLabel: String!, $model: String!) {
    contentType: getContentTypeByKey(
      keys: { appLabel: $appLabel, model: $model }
    ) {
      id
      events: getEventsForObject(
        first: 200
        filter: {
          verbIn: [
            "signatures.signature_request_edit"
            "signatures.signature_request_cancel"
            "signatures.signature_request_emailed"
            "signatures.signature_rejection_create"
            "signatures.signature_request_view"
            "signatures.signature_response_create"
            "signatures.signature_response_edit"
            "signatures.signature_response_remove"
            "tickets.ticket_create"
            "tickets.ticket_status_change"
          ]
        }
        association: OBJECT
        id: $id
      ) {
        edges {
          cursor
          node {
            ...ListSignatureEvent
          }
        }
        ...PageInfo
      }
    }
  }
  ${eventFragments.listSignatureEvent}
  ${listPageFragments.pageInfo}
`
