import { gql } from "@apollo/client"
import lastActiveFragments from "./lastActive"

const companyFragments = {
  companyOption: gql`
    fragment CompanyOption on Company {
      id
      name
      description
      number
    }
  `,
  companyTicker: gql`
    fragment CompanyTicker on Company {
      name
      ticker
    }
  `,
  companySignatures: gql`
    fragment CompanySignatures on Company {
      sigDigitalEnabled
      sigImageUpload
      sigRemoteEmail
      sigStampImageUpload
      sigDigitalStamp
    }
  `,
  listCompanyFragment: gql`
    fragment ListCompany on Company {
      id
      # created
      modified
      name
      ticker
      number
      lastActiveTicket {
        ...LastActiveTicket
      }
      wellCount
      ticketCount
    }
    ${lastActiveFragments.lastActiveTicket}
  `
}

export default companyFragments
