// vim: foldmethod=marker:foldmarker={{{,}}}
import { gql } from "@apollo/client"

// smallThumbnail {{{
const smallThumbnail = gql`
  fragment SmallThumbnail on Document {
    smallThumbnail: thumbnail(input: { width: 220, height: 500 })
  }
`
// smallThumbnail }}}

// fullThumbnail {{{
const fullThumbnail = gql`
  fragment FullThumbnail on Document {
    fullThumbnail: thumbnail(input: {})
  }
`
// fullThumbnail }}}

// documentFragments {{{
// Putting the client fields into a fragment gives an error about an
// unknown fragment. Maybe it's because there are no server-side fields
// in that fragment.
const documentFragments = {
  baseDocument: gql`
    fragment BaseDocument on Document {
      id
      uuid
      versionId
      size
      created
      label
      mimetype
      original
      originalDownload: original(forceDownload: true)
      exif {
        imageWidth
        imageHeight
      }
      type
      ...SmallThumbnail
      ...FullThumbnail
      cabinets {
        edges {
          node {
            ... on Cabinet {
              id
              label
            }
          }
        }
      }
      status @client
      errorMessage @client
    }
    ${smallThumbnail}
    ${fullThumbnail}
  `,
  smallThumbnail,
  fullThumbnail,
  documentLabel: gql`
    fragment DocumentLabel on Document {
      label
      uuid
      versionId
    }
  `,
  documentSnapshot: gql`
    fragment DocumentSnapshot on SnapshotDocument {
      id
      versionId
    }
  `
}
// documentFragments }}}

export default documentFragments
