import { Navigate } from "react-router-dom"
// import { FormattedMessage, useIntl } from 'react-intl';
import useUser from "hooks/useUser"
import MessagePage from "components/Page/MessagePage"

const CallbackView = () => {
  const user = useUser()

  // const { formatMessage: f } = useIntl()

  if (user) {
    return <Navigate to="/" />
  }
  return (
    <MessagePage
      title="Done Authenticating"
      subTitle="If this page persists, please contact support"
    />
  )
}

export default CallbackView
