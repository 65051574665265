import { gql } from "@apollo/client"
import basins from "./basins"
import cities from "./cities"
import companies from "./companies"
import locations from "./locations"
import lastActiveFragments from "./lastActive"

const wellFragments = {
  wellOption: gql`
    fragment WellOption on Well {
      id
      name
      latitude
      longitude
      directions
      basin {
        name
      }
      city {
        ...CityStack
      }
    }
    ${locations.cityStack}
  `,
  wellListItem: gql`
    fragment WellListItem on Well {
      id
      name
      basin {
        name
      }
      city {
        ...CityStack
      }
      company {
        ...CompanyTicker
      }
      lastActiveTicket {
        ...LastActiveTicket
      }
      ticketCount
      totalCharges
    }
    ${locations.cityStack}
    ${companies.companyTicker}
    ${lastActiveFragments.lastActiveTicket}
  `,
  wellDetailCard: gql`
    fragment WellDetailsCard on Well {
      id
      name
      apiNumber
      latitude
      longitude
      directions
      basin {
        name
      }
      city {
        ...CityStack
      }
      # thumbnail: tile(input: {width: 250, height: 150})
    }
    ${locations.cityStack}
  `,
  wellDetail: gql`
    fragment WellDetail on Well {
      id
      name
      apiNumber
      latitude
      longitude
      detailsId
      cabinetId
      directions
      basin {
        ...BasinOption
      }
      city {
        ...CityOption
      }
      company {
        name
      }
      # thumbnail: tile(input: {width: 250, height: 150})
    }
    ${cities.cityOption}
    ${basins.basinOption}
  `
}

export default wellFragments
