// https://github.com/siddharthkp/use-timeout/blob/master/index.js
import moment from "moment-timezone"
import { createContext, useCallback, useEffect, useState } from "react"

export type TimezoneContextType = {
  localTimezone: string
  currentTimezone: string
  setTimezone: (tz: string) => void
  resetTimezone: () => void
  getTimezoneDisplay: (time: string | moment.Moment | null) => string | null
}

const defaultCtx: TimezoneContextType = {
  localTimezone: "",
  currentTimezone: "",
  setTimezone() {},
  resetTimezone() {},
  getTimezoneDisplay(time) {
    return null
  }
}

const TimezoneContext = createContext<TimezoneContextType>(defaultCtx)

export type TimezoneProviderProps = {
  children: React.ReactNode
}

export const TimezoneProvider: React.FC<TimezoneProviderProps> = ({
  children
}) => {
  // Timezone of the local user's browser
  const [localTimezone, setLocalTimezone] = useState<string>("")
  // Timezone perceived by the user in the interface
  const [currentTimezone, setCurrentTimezone] = useState<string>("")

  // Set initial timezones
  useEffect(() => {
    const tz = moment.tz.guess(true)
    setCurrentTimezone(tz)
    setLocalTimezone(tz)
    console.info("current timezone is:", tz)
  }, [setCurrentTimezone, setLocalTimezone])

  const setTimezone = useCallback(
    (tz: string) => {
      if (tz !== currentTimezone) {
        moment.tz.setDefault(tz)
        setCurrentTimezone(tz)
        console.info("set timezone to:", tz)
      }
    },
    [setCurrentTimezone, currentTimezone]
  )

  const resetTimezone = useCallback(() => {
    if (currentTimezone !== localTimezone) {
      console.log("resetting to:", localTimezone)
      moment.tz.setDefault(localTimezone)
      setCurrentTimezone(localTimezone)
    }
  }, [setCurrentTimezone, localTimezone, currentTimezone])

  const getTimezoneDisplay = useCallback(
    (time: string | moment.Moment | null) => {
      if (!!currentTimezone && !!time) {
        return moment(time)
          .tz(currentTimezone as string)
          .zoneAbbr()
      }
      return null
    },
    [currentTimezone]
  )

  const timezone = {
    localTimezone,
    currentTimezone,
    setTimezone,
    resetTimezone,
    getTimezoneDisplay
  }

  return (
    <TimezoneContext.Provider value={timezone}>
      {children}
    </TimezoneContext.Provider>
  )
}

export default TimezoneContext
