import { useMemo } from "react"
import {
  ApolloQueryResult,
  DocumentNode,
  OperationVariables,
  QueryHookOptions
} from "@apollo/client"

import {
  Document,
  FullThumbnailFragment,
  SmallThumbnailFragment
} from "client/types"
import { edgeLess } from "utils/apollo"
import useSkipQuery from "./useSkipQuery"

export type UseDocumentsQueryArgs<TData, TVars> = QueryHookOptions<
  TData,
  TVars
> & {
  query: DocumentNode
}

export type UseDocumentsQueryResult<T, V> = {
  documents: Array<Document & SmallThumbnailFragment & FullThumbnailFragment>
  data: T | undefined
  loading: boolean
  startPolling: ((pollInterval: number) => void) | (() => void)
  stopPolling: (() => void) | (() => void)
  refetch:
    | ((variables?: Partial<V> | undefined) => Promise<ApolloQueryResult<T>>)
    | (() => void)
}

const _refetch = () => {
  console.warn(
    "calling refresh() before the useDocumentsQuery has run won't do anything"
  )
}
const _startPolling = () => {
  console.warn(
    "calling startPolling() before the useDocumentsQuery has run won't do anything"
  )
}
const _stopPolling = () => {
  console.warn(
    "calling stopPolling() before the useDocumentsQuery has run won't do anything"
  )
}

export default function useDocumentsQuery<
  QueryData = any,
  QueryVariables = OperationVariables
>({
  query,
  ...queryArgs
}: UseDocumentsQueryArgs<QueryData, QueryVariables>): UseDocumentsQueryResult<
  QueryData,
  QueryVariables
> {
  const {
    called,
    data,
    loading,
    refetch,
    startPolling,
    stopPolling
  } = useSkipQuery<QueryData, QueryVariables>(query, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    returnPartialData: true,
    errorPolicy: "all",
    ...queryArgs
  })

  const documents = useMemo<
    Array<Document & SmallThumbnailFragment & FullThumbnailFragment>
    // @ts-ignore
  >(() => edgeLess((data as QueryData)?.documents), [data])

  return {
    documents,
    data,
    // Indicate a loading state if the query hasn't been called
    loading: !called || loading,
    refetch: refetch || _refetch,
    startPolling: startPolling || _startPolling,
    stopPolling: stopPolling || _stopPolling
  }
}
