import { alpha, IconButton, IconButtonProps, Theme } from "@material-ui/core"
import RotateLeftIcon from "@material-ui/icons/RotateLeft"
import { withStyles } from "@material-ui/styles"
import clsx from "clsx"
import React from "react"

interface ResetButtonProps extends IconButtonProps {
  // children: React.ReactNode
}

const styles = (theme: Theme) => ({
  colorPrimary: {
    // color: theme.palette.text.primary,
    // backgroundColor: alpha(
    //   theme.palette.text.primary,
    //   theme.palette.action.hoverOpacity
    // ),
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: alpha(
        theme.palette.secondary.main,
        theme.palette.action.activatedOpacity
      )
    }
  }
})

const ResetButton: React.ComponentType<ResetButtonProps> = React.forwardRef(
  ({ classes, className, ...buttonProps }, ref) => {
    return (
      // @ts-ignore
      <IconButton
        ref={ref}
        color={"inherit"}
        className={clsx(className, classes?.colorPrimary)}
        {...buttonProps}
      >
        <RotateLeftIcon />
      </IconButton>
    )
  }
)

export default withStyles(styles, { name: "ResetButton" })(ResetButton)
