// import { Auth } from 'aws-amplify';
import { useContext } from "react"
import WaffleContext from "context/WaffleContext"

function useWaffle() {
  const context = useContext(WaffleContext)
  if (context === undefined) {
    throw new Error("`useWaffle` must be within a `WaffleContext`")
  }
  return context
}

export default useWaffle
