import castArray from "lodash/castArray"
import every from "lodash/every"
import flatMap from "lodash/flatMap"
import get from "lodash/get"
import isEqual from "lodash/isEqual"
import map from "lodash/map"
import sortBy from "lodash/sortBy"
import { is } from "ts-type-guards"
import { DisplayCondition, DisplayWhen } from "../FormInput/FormInput.d"
import { DataKinds } from "../FormInput/FormInput.d"
import { DataDefinition, FormConfig } from "../FormSection/FormSection.d"
import { FormikContextType } from "formik"

export const getFieldNames = (config: FormConfig): Array<string> =>
  flatMap(config, section => map(section["fields"], "name"))

export const meetsDisplayCondition: (
  condition: DisplayCondition<string | number | boolean>
) => (testValue: any) => boolean = condition => testValue => {
  // @ts-ignore
  if (is(Function)(condition)) return condition(testValue)
  if (is(Array)(condition)) {
    return isEqual(sortBy(condition), sortBy(castArray(testValue)))
  }
  return isEqual(condition, testValue)
}

export const shouldDisplay: (
  dataSources?: Record<string, FormikContextType<any> | undefined>
) => (
  displayWhen: DisplayWhen[] | undefined | null
) => boolean = dataSources => displayWhen => {
  return every(displayWhen, config => {
    const { dataType, dataPath, displayCondition } = config
    let testValue: any
    switch (dataType) {
      case DataKinds.Formik:
        const values = dataSources?.formik?.values
        testValue = get(values, dataPath)
        break
      case DataKinds.Literal:
        testValue = dataPath
        break
      default:
        return false
    }
    return meetsDisplayCondition(displayCondition)(testValue)
  })
}

export const parseDataProps = (
  dataSources?: Record<string, FormikContextType<any> | undefined>
) => data => {
  return (data as any).reduce((acc: object, datumDef: DataDefinition) => {
    const { propPath, dataType, dataPath } = datumDef
    switch (dataType) {
      case DataKinds.Formik:
        const value = is(Function)(dataPath)
          ? dataPath(dataSources?.formik?.values)
          : get(dataSources?.formik?.values, dataPath)
        return null == value ? acc : { ...acc, [propPath]: value }
      case DataKinds.Literal:
        return {
          ...acc,
          [propPath]: is(Function)(dataPath) ? dataPath() : dataPath
        }
      default:
        return acc
    }
  }, {})
}
