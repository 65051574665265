import { gql } from "@apollo/client"

const smallUser = gql`
  fragment SmallUser on User {
    id
    firstName
    lastName
    email
  }
`

const userOption = gql`
  fragment UserOption on User {
    id
    fullName
    email
  }
`

const largeUser = gql`
  fragment LargeUser on User {
    id
    firstName
    lastName
    fullName
    email
    # title
    # phone
  }
`

const listUser = gql`
  fragment ListUser on User {
    id
    firstName
    lastName
    fullName
    email
    followerCount
    isActive
    # title
    # phone
  }
`

const detailUser = gql`
  fragment DetailUser on User {
    id
    firstName
    lastName
    fullName
    email
    companies {
      id
      name
      description
    }
    # title
    # phone
  }
`

const assignmentOption = gql`
  fragment AssignmentOption on Assignment {
    id
    type
    user {
      ...UserOption
    }
  }
  ${userOption}
`

const userFragments = {
  smallUser,
  userOption,
  largeUser,
  listUser,
  detailUser,
  assignmentOption
}

export default userFragments
