import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import { Button, IButtonProps } from "../index"

export enum CancelButtonType {
  Back = "Back",
  Cancel = "Cancel"
}

interface ICancelButtonProps
  extends Omit<IButtonProps, "startIcon" | "endIcon"> {
  cancelType?: CancelButtonType
}

// Cancel button that says "Cancel" or "Back". Used in modal dialogs.
const CancelButton: React.FunctionComponent<ICancelButtonProps> = ({
  cancelType = CancelButtonType.Cancel,
  ...props
}) => {
  return (
    <Button startIcon={<NavigateBeforeIcon />} {...props}>
      {cancelType}
    </Button>
  )
}

export default CancelButton
