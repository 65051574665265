// vim: foldmethod=marker:foldmarker={{{,}}}
// Any context passed to the query is stripped by the time
// it reaches the service worker. The operation name is the
// cleanest way to tell what's happening there, so we're
// defining queries that are used to fully sync data. The
// service worker can then use these queries to check
// whether it needs to update the lastFullSync timestamps.
// imports {{{
import { gql } from "@apollo/client"
import charges from "fragments/charges"
import companies from "fragments/companies"
import documentFragments from "fragments/documents"
import inventory from "fragments/inventory"
import listPageFragments from "fragments/listPages"
import priceRule from "fragments/priceRule"
import runningProcedures from "fragments/runningProcedures"
import salesOrderFragments from "fragments/salesOrders"
import ticketFragments from "fragments/tickets"
import user from "fragments/user"
import wells from "fragments/wells"
// imports }}}

// SYNC_CHARGES {{{
export const SYNC_CHARGES = gql`
  query syncCharges($first: Int, $after: String, $filter: ChargeFilterInput) {
    charges: getCharges(first: $first, after: $after, filter: $filter) {
      edges {
        cursor
        node {
          ...BaseCharge
        }
      }
      ...PageInfo
    }
  }
  ${charges.baseCharge}
  ${listPageFragments.pageInfo}
`
// SYNC_CHARGES }}}

// SYNC_DOCUMENTS {{{
export const SYNC_DOCUMENTS = gql`
  query syncDocuments(
    $first: Int
    $after: String
    $filter: DocumentFilterInput
  ) {
    documents: getDocuments(first: $first, after: $after, filter: $filter) {
      edges {
        cursor
        node {
          ...BaseDocument
          ... on Document {
            cachedThumbnail
            originalDownload: original(forceDownload: true)
          }
        }
      }
      ...PageInfo
    }
  }
  ${documentFragments.baseDocument}
  ${listPageFragments.pageInfo}
`
// SYNC_DOCUMENTS }}}

// SYNC_PRICE_RULES {{{
export const SYNC_PRICE_RULES = gql`
  query syncPriceRules(
    $first: Int
    $after: String
    $filter: PriceRuleFilterInput
  ) {
    priceRules: getPriceRules(first: $first, after: $after, filter: $filter) {
      edges {
        cursor
        node {
          ...BasePriceRule
        }
      }
      ...PageInfo
    }
  }
  ${priceRule.basePriceRule}
  ${listPageFragments.pageInfo}
`
// SYNC_PRICE_RULES }}}

// SYNC_PRODUCTS {{{
export const SYNC_PRODUCTS = gql`
  query syncProducts(
    $first: Int
    $after: String
    $filter: InventoryItemFilterInput
  ) {
    products: getProducts(first: $first, after: $after, filter: $filter) {
      edges {
        cursor
        node {
          ...BaseInventoryItem
        }
      }
      ...PageInfo
    }
  }
  ${inventory.baseInventoryItem}
  ${listPageFragments.pageInfo}
`
// SYNC_PRODUCTS }}}

// SYNC_RUNNING_PROCEDURES {{{
// TODO: Include filtering once that's implemented on the back-end
export const SYNC_RUNNING_PROCEDURES = gql`
  query syncRunningProcedures(
    $first: Int
    $after: String
    $filter: RunningProcedureFilterInput
  ) {
    procedures: getRunningProcedures(
      first: $first
      after: $after
      filter: $filter
    ) {
      edges {
        cursor
        node {
          ...BaseRunningProcedure
        }
      }
      ...PageInfo
    }
  }
  ${runningProcedures.baseRunningProcedure}
  ${listPageFragments.pageInfo}
`
// SYNC_RUNNING_PROCEDURES }}}

// SYNC_SALES_ORDERS {{{
export const SYNC_SALES_ORDERS = gql`
  query syncSalesOrders($paged: PagedInput, $filter: SalesOrderFilterInput) {
    salesOrders: getPagedSalesOrders(paged: $paged, filter: $filter) {
      objects {
        ...FullSalesOrder
        ...ListSalesOrderFragment
        ...PrintSalesOrderDetails
      }
      ...PagedInfo
    }
  }
  ${salesOrderFragments.fullSalesOrder}
  ${salesOrderFragments.listSalesOrderFragment}
  ${salesOrderFragments.printSalesOrderDetails}
  ${listPageFragments.pagedInfo}
`
// SYNC_SALES_ORDERS }}}

// SYNC_TICKETS {{{
export const SYNC_TICKETS = gql`
  query syncTickets($first: Int, $after: String, $filter: TicketFilterInput) {
    tickets: getTickets(first: $first, after: $after, filter: $filter) {
      edges {
        cursor
        node {
          ...ListTicketFragment
          ...FullTicket
          ...PrintTicketDetails
        }
      }
      ...PageInfo
    }
  }
  ${ticketFragments.fullTicket}
  ${ticketFragments.listTicketFragment}
  ${ticketFragments.printTicketDetails}
  ${listPageFragments.pageInfo}
`
// SYNC_TICKETS }}}

// SYNC_USERS {{{
export const SYNC_USERS = gql`
  query syncUsers($first: Int, $after: String, $filter: UserFilterInput) {
    users: getUsers(first: $first, after: $after, filter: $filter) {
      edges {
        cursor
        node {
          ...LargeUser
          ... on User {
            groups {
              id
              name
            }
          }
        }
      }
      ...PageInfo
    }
  }
  ${user.largeUser}
  ${listPageFragments.pageInfo}
`
// SYNC_USERS }}}

// SYNC_WELLS {{{
export const SYNC_WELLS = gql`
  query syncWells($first: Int, $after: String, $filter: WellFilterInput) {
    wells: getWells(first: $first, after: $after, filter: $filter) {
      edges {
        cursor
        node {
          ...WellOption
          ... on Well {
            company {
              ...CompanyOption
            }
          }
        }
      }
      ...PageInfo
    }
  }
  ${wells.wellOption}
  ${companies.companyOption}
  ${listPageFragments.pageInfo}
`
// SYNC_WELLS }}}
