import { FormikContextType, useFormikContext } from "formik"
import FormFactory from "components/FormFactory"
import useInitialFormikValidation from "hooks/forms/useInitialFormikValidation"

import formConfigs from "./createNewWellCardConfig"

interface ICreateNewWellCardProps {}

const CreateNewWellCard: React.FunctionComponent<ICreateNewWellCardProps> = () => {
  const formik: FormikContextType<any> = useFormikContext()
  useInitialFormikValidation({
    formik,
    additionalFieldsToTouch: ["createWell.name"]
  })

  return <FormFactory formConfigs={formConfigs} formik={formik} />
}

export default CreateNewWellCard
