import { updateCastId } from "utils/yup"
import * as Yup from "yup"

const location = Yup.number()
  .nullable()
  .transform(function (value) {
    // If we aren't able to convert into a number return null,
    // prevents errors from no value in input being set as an empty string
    if (this.isType(value) && value !== null) {
      return value
    }
    return null
  })

export const latitude = location.max(90.0).min(-90.0)
export const longitude = location.max(180.0).min(-180.0)

const updateWellSchema = Yup.object().shape({
  apiNumber: Yup.string().nullable(),
  city: updateCastId(
    Yup.object()
      .shape({
        id: Yup.number()
      })
      .nullable()
      .required("City is required")
  ),
  basin: updateCastId(
    Yup.object()
      .shape({
        id: Yup.number()
      })
      .nullable()
      .required("Basin is required")
  ),
  directions: Yup.string(),
  latitude,
  longitude
})

export default updateWellSchema
