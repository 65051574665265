import { IconButton, IconButtonProps, alpha, Theme } from "@material-ui/core"
import ClearIcon from "@material-ui/icons/Clear"
import { withStyles } from "@material-ui/styles"
import clsx from "clsx"

interface RemoveButtonProps extends IconButtonProps {
  // children: React.ReactNode
}

const styles = (theme: Theme) => ({
  colorPrimary: {
    // color: theme.palette.text.primary,
    // backgroundColor: alpha(
    //   theme.palette.text.primary,
    //   theme.palette.action.hoverOpacity
    // ),
    "&:hover": {
      color: theme.palette.error.main,
      backgroundColor: alpha(
        theme.palette.error.main,
        theme.palette.action.activatedOpacity
      )
    }
  }
})

const RemoveButton: React.ComponentType<RemoveButtonProps> = ({
  classes,
  className,
  ...buttonProps
}) => {
  return (
    // @ts-ignore
    <IconButton
      color={"inherit"}
      className={clsx(className, classes?.colorPrimary)}
      {...buttonProps}
    >
      <ClearIcon />
    </IconButton>
  )
}

export default withStyles(styles, { name: "RemoveButton" })(RemoveButton)
