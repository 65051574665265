import { makeStyles } from "@material-ui/core/styles"
import { grey } from "theme/palettes/colors"

const useSharedStyles = makeStyles(theme => ({
  content: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  inactive: {
    backgroundColor: theme.palette.background.default
  },
  active: {
    backgroundColor: theme.palette.background.paper,
    borderTop: `1px solid ${grey[600]}`,
    borderBottom: `1px solid ${grey[600]}`
  }
}))

export default useSharedStyles
