import { useEffect } from "react"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { ThemeProvider } from "@material-ui/core/styles"
import { createTheme } from "@material-ui/core"

import palettes from "./palettes"
import typography from "./typography"
import typographyIPad from "./typographyIPad"
import overrides from "./overrides"
import { useQuery, useMutation } from "@apollo/client"
import { GET_DARK_MODE, CHANGE_USER_SETTINGS } from "queries/userSettings"

export const buildTheme = typographyFn => (
  spacing?: number | ((factor: number) => number | string) | Array<number>
) => palette => {
  const typo = typographyFn(palette)
  return createTheme({
    palette,
    // @ts-ignore
    typography: typo,
    // @ts-ignore
    overrides: overrides(palette, typo),
    spacing
  })
}

export const themeFromPalette = buildTheme(typography)()
export const iPadThemeFromPalette = buildTheme(typographyIPad)()

// Generate themes on import of module
export const themes = {
  light: themeFromPalette(palettes.light),
  dark: themeFromPalette(palettes.dark),
  iPadPrint: iPadThemeFromPalette(palettes.light)
}

function Theme({ children }) {
  const { data: userData, loading: userDataLoading } = useQuery(GET_DARK_MODE)
  const [setDarkModeMutation] = useMutation(CHANGE_USER_SETTINGS)

  // TODO: use dark mode hook so it can be used elsewhere
  let prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")

  useEffect(() => {
    // set the dark mode from MQ if not specified by user
    setDarkModeMutation({
      variables: {
        input: {
          darkMode: prefersDarkMode,
          darkModeSet: false // user didn't set dark mode
        }
      }
    })
  }, [setDarkModeMutation, prefersDarkMode])

  // prefer selected mode over media query, set after saving default dark mode from MQ
  if (!userDataLoading && userData?.userSettings.darkModeSet) {
    prefersDarkMode = userData.userSettings.darkMode
  }

  const type = prefersDarkMode ? "dark" : "light"

  return <ThemeProvider theme={themes[type]} children={children} />
}

export default Theme

export function makeThemes() {
  return [themes.light, themes.dark]
}
