import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { useFormStateContext } from "hooks/forms/useFormState"
import { useOnlineContext } from "context/OnlineContext"
import { ThumbnailCard, useAttachmentsContext } from "components/Attachments"
import { statusesWithThumbnails } from "./utils"

import { FileUploadStatus } from "client/types"

type PhotoStepPreviewProps = {}

const useStyles = makeStyles(theme => ({
  thumbnailGrid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start"
  },
  noImages: {
    backgroundColor: theme.palette.action.disabledBackground,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(2),
    color: theme.palette.text.secondary
  }
}))

export const dataTestid = "photostep-preview"
const PhotoStepPreview: React.FunctionComponent<PhotoStepPreviewProps> = props => {
  const classes = useStyles()

  const { appOnline } = useOnlineContext()
  const { documents, previewProps, previewHandlers } = useAttachmentsContext()
  const thumbnails = documents
    .map(document => ({
      ...document,
      status:
        !appOnline && FileUploadStatus.Attached === document.status
          ? FileUploadStatus.AttachedOffline
          : document.status
    }))
    .filter(document => statusesWithThumbnails.includes(document.status))

  const { getMetadata } = useFormStateContext()
  const isDocumentationLocked = Boolean(getMetadata().isDocumentationLocked)

  const fallback = isDocumentationLocked ? (
    <div className={classes.noImages}>
      <Typography variant="body1">No images uploaded</Typography>
    </div>
  ) : null

  return thumbnails.length > 0 ? (
    <section className={classes.thumbnailGrid} data-testid={dataTestid}>
      {thumbnails.map(document => (
        <ThumbnailCard
          key={document.id}
          {...document}
          {...{
            ...previewProps,
            disablePreviewDelete: isDocumentationLocked
          }}
          {...previewHandlers}
        />
      ))}
    </section>
  ) : (
    fallback
  )
}

export default PhotoStepPreview
