import { MenuItem } from "@material-ui/core"
import { useFormikContext, FormikContextType } from "formik"

import {
  TextField as FormikTextField,
  TextFieldProps
} from "formik-material-ui"
import defaultProps from "recompose/defaultProps"
import compact from "lodash/compact"
import set from "lodash/set"
import produce from "immer"

import { useFormStateContext } from "hooks/forms/useFormState"

export const defaultSelectFieldProps: Pick<
  TextFieldProps,
  "fullWidth" | "variant" | "inputProps"
> = {
  fullWidth: true,
  variant: "outlined",
  inputProps: {
    autoComplete: "new-password" // This disables autcomplete in modern browsers
  }
}

export const selectInputProps = defaultProps<Partial<TextFieldProps>>(
  defaultSelectFieldProps
)

export interface SelectOption {
  value: string
  description: string
}

type OptionFunction<T> = (formik: FormikContextType<any>) => T

export interface SelectFieldProps extends TextFieldProps {
  options: SelectOption[] | OptionFunction<SelectOption[]>
}

const SelectField: React.FunctionComponent<SelectFieldProps> = ({
  options,
  ...props
}) => {
  const formik = useFormikContext()
  const fieldName = props.field.name

  const {
    clearError,
    getError,
    _default: noFormStateContext
  } = useFormStateContext()

  // get options
  if (typeof options === "function") {
    options = options(formik)
  }

  // Normally we'd clone to prevent unexpected mutations to props, but that crashes
  // the app sometimes :-S
  const newProps = produce(props, draft => {
    // hack to prevent null values from GraphQL from causing problems w/ Material UI
    if (null == draft.field.value) draft.field.value = ""

    if (!noFormStateContext) {
      if (undefined === draft.inputProps) draft.inputProps = {}
      if (undefined === draft.inputProps.onBlur) {
        draft.inputProps.onBlur = () => {
          clearError(fieldName)
        }
      } else {
        draft.inputProps.onBlur = (...args) => {
          clearError(fieldName)
          props.inputProps!.onBlur?.(...args)
        }
      }
    }

    // Merging back-end errors at the component level to allow forms to be resubmitted
    // even if back-end errors haven't been cleared.
    const backEndError = getError(fieldName)
    if (backEndError) {
      const frontEndValidationErrors = draft.form.errors?.[fieldName]
      const combinedValidationErrors = compact([
        frontEndValidationErrors,
        backEndError
      ]).join("\n")

      // Use set to handle nested paths correctly
      set(draft.form.errors, fieldName, combinedValidationErrors)
      set(draft.form.touched, fieldName, true)
    }
  })

  // output ListSubheader and change the values to be unpacked or make a new list of
  // options that are combo/ ticket type/ phase and just segment them all.
  return (
    <FormikTextField {...newProps} select={true}>
      {options.map(option => (
        <MenuItem key={`val-${option.value}`} value={option.value}>
          {option.description}
        </MenuItem>
      ))}
    </FormikTextField>
  )
}

export default selectInputProps(SelectField)
