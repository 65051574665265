import Brightness4Icon from "@material-ui/icons/Brightness4"
import Brightness7Icon from "@material-ui/icons/Brightness7"
import { Tooltip, IconButton, IconButtonProps } from "@material-ui/core"
import { useQuery, useMutation } from "@apollo/client"
import { GET_DARK_MODE, CHANGE_USER_SETTINGS } from "queries/userSettings"
import {
  DetermineDarkModeQuery,
  ChangeUserSettingsMutation,
  ChangeUserSettingsMutationVariables
} from "client/types"

export type DarkModeToggleProps = Omit<IconButtonProps, "color" | "onClick">

const DarkModeToggle: React.ComponentType<DarkModeToggleProps> = props => {
  // @ts-ignore
  const {
    data: userData,
    loading: userDataLoading
  } = useQuery<DetermineDarkModeQuery>(GET_DARK_MODE)
  let darkMode = false

  const [toggleMutation] = useMutation<
    ChangeUserSettingsMutation,
    ChangeUserSettingsMutationVariables
  >(CHANGE_USER_SETTINGS)

  if (!userDataLoading) {
    darkMode = userData?.userSettings?.darkMode || false
  }

  const handleToggle = () => {
    toggleMutation({
      variables: {
        input: {
          darkMode: !darkMode,
          darkModeSet: true
        }
      }
    })
  }

  return (
    <Tooltip title="Toggle Dark Mode" enterDelay={300}>
      <IconButton color="inherit" onClick={handleToggle} {...props}>
        {!darkMode ? <Brightness4Icon /> : <Brightness7Icon />}
      </IconButton>
    </Tooltip>
  )
}

export default DarkModeToggle
