import * as React from "react"

import { useStyles } from "./Content"
import { IFallbackProps } from "components/Sentry/withSentryErrorBoundary"
import CrashCard from "components/CrashCard"
import { Page } from "components"

const CrashMessage: React.FunctionComponent<IFallbackProps> = props => {
  const classes = useStyles()

  return (
    <Page title="Unexpected Error" maxWidth="xl">
      <div className={classes.container}>
        <div className={classes.content}>
          <CrashCard {...props} />
        </div>
      </div>
    </Page>
  )
}

export default CrashMessage
