import { gql } from "@apollo/client"

const signatureFragments = {
  // Because StatusableTicket and SalesOrder use different enums for `status`, we
  // get errors when trying to define fragments on both that use the same name:
  //
  // ... on StatusableTicket {
  //   status
  // }
  // ... on SalesOrder {
  //   status
  // }
  //
  // We can alias the fields to have different names, but because we rely on this
  // response to update the ticket/sales order data in the Apollo cache, we need
  // the field to still have the name `status`.
  //
  // That's why, until the API side is finalized, I'm using a different fragment
  // for sales orders.
  listSignatureResponse: gql`
    fragment ListSignatureResponse on SignatureResponse {
      id
      documentUUID
      object {
        ... on Node {
          id
        }
        ... on StatusableTicket {
          status
        }
        ... on SalesOrder {
          soStatus: status
          transitions: availableStatusTransitions {
            direction
            label
            method
            priority
          }
        }
        ... on ServiceTicket {
          transitions: availableStatusTransitions {
            transition
            label
            priority
            direction
          }
        }
      }
    }
  `,
  listSignatureRequest: gql`
    fragment ListSignatureRequest on SignatureRequest {
      id
      created
      modified
      cancelled
      signatoryName
      signatoryEmail
      latestResponse {
        id
      }
    }
  `,
  signatureInfo: gql`
    fragment SignatureStateDisplay on Signable {
      signatureState
      latestResponse: latestSignatureResponse {
        id
        type
      }
      latestRequest: latestSignatureRequest {
        id
        type
        latestResponse {
          id
          type
        }
      }
    }
  `
}

export default signatureFragments
