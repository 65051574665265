import { Icon, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import FileCopyIcon from "@material-ui/icons/FileCopy"

interface IInactiveDropZoneProps {}

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: "400%",
    marginRight: theme.spacing(1)
  }
}))

export const dataTestid = "inactive-dropzone-text"

const InactiveDropZone: React.FunctionComponent<IInactiveDropZoneProps> = () => {
  const classes = useStyles()

  return (
    <>
      <Icon className={classes.icon}>
        <FileCopyIcon fontSize="inherit" />
      </Icon>
      <section data-testid={dataTestid}>
        <Typography variant="h5" component="p">
          Upload Files
        </Typography>
        <Typography>
          Drop files here or click to browse through your machine
        </Typography>
      </section>
    </>
  )
}

export default InactiveDropZone
