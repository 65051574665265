import { useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import * as Sentry from "@sentry/react"

import {
  getAuthToken,
  fetchAuthAPI,
  TokenOperation,
  TokenNames
} from "client/links/tokenAuthLink"
import { updateAppVars } from "client/localVars"
import {
  AuthorizationType,
  GetAppVarsQuery,
  GetAppVarsQueryVariables
} from "client/types"
import { GET_APP_VARS } from "queries/appState"

import { useOnlineContext } from "context/OnlineContext"
import { UserProvider } from "../context/user"
import MessagePage from "./Page/MessagePage"
import AccessDenied from "views/AccessDenied"

interface IGuestAccessProps {}

const GuestAccess: React.FunctionComponent<IGuestAccessProps> = () => {
  const { cachePersisted, onlineChecked } = useOnlineContext()
  const authToken = getAuthToken() ?? ""

  const [validToken, setValidToken] = useState<boolean | null>(null)
  const [tokenChecked, setTokenChecked] = useState<boolean>(false)

  const { data } = useQuery<GetAppVarsQuery, GetAppVarsQueryVariables>(
    GET_APP_VARS
  )
  const currentAuth =
    (data?.appVars?.authorization as AuthorizationType) ??
    AuthorizationType.None

  useEffect(() => {
    Sentry.configureScope(scope => {
      scope.setContext("action", {
        customer: true,
        action: "login/logout"
      })
    })

    return () => {
      Sentry.configureScope(scope => {
        scope.setContext("action", null)
      })
    }
  }, [])

  // Set/unset the authorization method. The actual token lookup will be done
  // in the Apollo link. (Add finalized filename)
  useEffect(() => {
    return () => {
      updateAppVars({ authorization: AuthorizationType.None })
    }
  }, [])

  // Handle querystring token login
  useEffect(() => {
    const run = async () => {
      if (authToken) {
        // If there is an auth token, verify with that -- probably the start of a session
        const { accessToken, refreshToken } = await fetchAuthAPI(
          authToken,
          TokenOperation.Auth
        )

        const validToken = Boolean(accessToken) && Boolean(refreshToken)
        setValidToken(validToken)
        if (validToken) {
          updateAppVars({ authorization: AuthorizationType.Jwt })
        } else {
          // Using captureMessage bcause captureEvent leads to unlabelled items
          // in the Sentry error list
          Sentry.captureMessage("Invalid Auth Token")
        }
        setTokenChecked(true)
      } else {
        // If not, check for the cookies. The access token will disappear when expired,
        // so as long as there's a refresh token, allow access.
        const refreshCookie = document.cookie
          .split("; ")
          .find(row => row.startsWith(TokenNames.Refresh))
          ?.split("=")[1]

        const validToken = Boolean(refreshCookie)
        setValidToken(validToken)
        if (validToken) {
          updateAppVars({ authorization: AuthorizationType.Jwt })
        } else {
          // Using captureMessage bcause captureEvent leads to unlabelled items
          // in the Sentry error list
          Sentry.captureMessage("No Auth Token or Refresh Cookie")
        }
        setTokenChecked(true)
      }
    }

    run()
  }, [authToken])

  // Log out if the tokens have expired - should only be set on failed refresh or
  // failed queryString re-auth in src/client/tokenAuthLink.ts
  useEffect(() => {
    if (AuthorizationType.Expired === currentAuth) {
      // Using captureMessage bcause captureEvent leads to unlabelled items
      // in the Sentry error list
      Sentry.captureMessage("Authorization Expired, Logging Out")
      setValidToken(false)
    }
  }, [currentAuth])

  let content: JSX.Element
  // After a hard reload, there will be an active service worker but no
  // controller. To avoid a UI flash as the page reloads, keep the loading
  // message up in production if we don't have a controller until the
  // page reload triggers. This isn't as much of an issue on iPads/mobile;
  // there's not a really a way to hard reload a page.
  if (
    !cachePersisted ||
    !onlineChecked ||
    (process.env.NODE_ENV === "production" &&
      (navigator.serviceWorker?.controller ?? null) === null) ||
    !tokenChecked
  ) {
    content = <MessagePage title="Loading..." />
  } else if (tokenChecked && validToken) {
    content = <UserProvider guest />
  } else {
    content = <AccessDenied />
  }

  return content
}

export default GuestAccess
