import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react"
import warning from "tiny-warning"
import * as Sentry from "@sentry/react"
import forEach from "lodash/forEach"

import appState from "client/appState"
import { updateAppVars } from "client/localVars"
import useSyncDatabase, {
  clearSentry,
  SyncTable
} from "hooks/sync/useSyncDatabase"
import { Maybe } from "client/types"

export type OnlineContextType = {
  online: Maybe<boolean>
  cachePersisted?: boolean
  onlineChecked: boolean
  appOnline: Maybe<boolean>
  isUpSyncing: boolean
  setAppOnline?: (online: boolean | null) => void
  setIsUpSyncing?: Dispatch<SetStateAction<boolean>>
  _default?: boolean
}

const defaultOnlineContext: OnlineContextType = {
  online: false,
  cachePersisted: false,
  onlineChecked: false,
  appOnline: false,
  isUpSyncing: false,
  _default: true
}

export const OnlineContext = createContext<OnlineContextType>(
  defaultOnlineContext
)
export const OnlineProvider = OnlineContext.Provider
export const OnlineConsumer = OnlineContext.Consumer

export const useOnlineContext = () => {
  const onlineContext = useContext(OnlineContext)
  warning(
    "test" === process.env.NODE_ENV || !onlineContext._default,
    "An Online consumer did not find an Online provider"
  )

  return onlineContext
}

export const useOnline: (
  cachePersisted: boolean
) => OnlineContextType = cachePersisted => {
  const { db } = useSyncDatabase()
  const {
    state: { online: appStateOnline }
  } = appState()

  // Default app to online mode if there's nothing in the persisted cache
  const storedOnline = cachePersisted ? appStateOnline : null
  const [online, setOnline] = useState<Maybe<boolean>>(null)
  // whether or not we checked if the app is in offline mode
  const [onlineChecked, setOnlineChecked] = useState(false)
  // whether or not the app has been forced into offline mode
  const [appOnline, setAppOnline] = useState<Maybe<boolean>>(null)
  const [isUpSyncing, setIsUpSyncing] = useState(false)

  useEffect(() => {
    setOnline(navigator.onLine)
  }, [])

  // TODO: If navigator shows we're offline or if the connection drops,
  // force app into offline state
  useEffect(() => {
    const _online = () => {
      setOnline(true)
    }
    const _offline = () => {
      setOnline(false)
    }

    window.addEventListener("online", _online)
    window.addEventListener("offline", _offline)

    return () => {
      window.removeEventListener("online", _online)
      window.removeEventListener("offline", _offline)
    }
  }, [])

  // update persisted online state to match appOnline state, but not on
  // initial page load
  useEffect(() => {
    if (cachePersisted && null !== appOnline && appOnline !== storedOnline) {
      appState({ online: appOnline })
    }
  }, [appOnline, cachePersisted, storedOnline])

  useEffect(() => {
    if (cachePersisted && appOnline === null && storedOnline !== null) {
      Sentry.setContext("appState", {
        online: storedOnline
      })
      setAppOnline(storedOnline)
    }
  }, [appOnline, cachePersisted, appStateOnline, storedOnline])

  useEffect(() => {
    // onlineChecked must be true for <Authenticator /> to display the UI,
    // and the rendering of the UI triggers the GraphQL queries.
    // Don't set it to true until we know what the initial appOnline state
    // should be.
    if (null !== appOnline) setOnlineChecked(true)
  }, [appOnline])

  useEffect(() => {
    if (appOnline) {
      // after going back online, send events to Sentry
      const upsyncSentry = async () => {
        const events = await db[SyncTable.Sentry].toArray()

        // TODO: Sentry.captureEvent doesn't currently return a failure
        // status if the upload to sentry fails. And Sentry.init doesn't
        // allow initializing with an error handler.
        //
        // For now, if app is online and navigator.onLine is true (to catch
        // if localForage is wiped) send it and assume success.
        //
        // HB-592 logged to add a workaround to the service worker.
        if (navigator.onLine) {
          Sentry.withScope(function (scope) {
            scope.setContext("upsync", { upsync: true })
            forEach(events, evt => {
              Sentry.captureEvent(evt)
            })
          })
          await clearSentry()
        }
      }

      upsyncSentry()
    }
  }, [appOnline, db])

  useEffect(() => {
    updateAppVars({ isUpSyncing })
  }, [isUpSyncing])

  const _setAppOnline = useCallback(online => {
    Sentry.setContext("appState", {
      online
    })
    setAppOnline(online)
  }, [])

  return {
    appOnline,
    isUpSyncing,
    online,
    onlineChecked,
    setAppOnline: _setAppOnline,
    setIsUpSyncing
  }
}
export default useOnline
