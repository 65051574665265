import { useCallback, useMemo } from "react"
import { TextFieldProps } from "formik-material-ui"
import produce from "immer"
import cond from "lodash/cond"

import TextField from "./TextField"
import { getDecimalSeparator } from "utils/intl"

// Don't allow people to manually enter thousands separator
const numericChars = "1234567890"
const decimalChar = getDecimalSeparator("en-US")
const negativeChar = "-"

const negativeChars = numericChars + negativeChar
const decimalChars = numericChars + decimalChar
const negativeDecimalChars = numericChars + negativeChar + decimalChar

export const getAllowedInputs = cond<[boolean, boolean], string>([
  [
    ([allowNegative, allowDecimal]) => allowNegative && allowDecimal,
    () => negativeDecimalChars
  ],
  [([allowNegative]) => allowNegative, () => negativeChars],
  [([, allowDecimal]) => allowDecimal, () => decimalChars],
  [() => true, () => numericChars]
])

export interface NumberFieldProps extends TextFieldProps {
  allowNegative?: boolean
  allowDecimal?: boolean
}

// Decorate onKeyPress handler to limit inputs to acceptable ranges
const NumberField: React.FunctionComponent<NumberFieldProps> = ({
  allowNegative = false,
  allowDecimal = false,
  ...props
}) => {
  const {
    field: { name: fieldName, value: fieldValue },
    form: { setFieldValue }
  } = props

  const allowedInputs = useMemo(
    () => getAllowedInputs([allowNegative, allowDecimal]),
    [allowNegative, allowDecimal]
  )

  const _onKeyPress = useCallback(
    evt => {
      const { key } = evt

      // Swallow non-allowed inputs
      if (!allowedInputs.includes(key)) {
        evt.preventDefault()
      }
      // Swallow the input if it's the negative character when the field contains
      // only the negative character
      else if (negativeChar === key && negativeChar === fieldValue) {
        evt.preventDefault()
      }
      // Swallow the input if the decimal character is typed more than once
      else if (
        decimalChar === key &&
        String(fieldValue).includes(decimalChar)
      ) {
        evt.preventDefault()
      }
      // If there is data, switch the sign when pressing the negative key, except for
      // zeros and empty fields.
      else if (negativeChar === key && 0 !== Number(fieldValue)) {
        setFieldValue(fieldName, String(Number(fieldValue) * -1))
        evt.preventDefault()
      }
      // Add a leading zero to the input if the decimal character is typed first
      else if (["", undefined].includes(fieldValue) && decimalChar === key) {
        setFieldValue(fieldName, "0" + key)
        evt.preventDefault()
      }
      // Replace a zero with the first typed character, unless it's the decimal character
      else if (["0", 0].includes(fieldValue) && key !== decimalChar) {
        console.log(`replacing character`)
        setFieldValue(fieldName, key)
        evt.preventDefault()
      }
    },
    [allowedInputs, fieldName, fieldValue, setFieldValue]
  )

  // Normally we'd clone to prevent unexpected mutations to props, but that crashes
  // the app sometimes :-S
  const newProps = produce(props, draft => {
    if (undefined === draft.inputProps) draft.inputProps = {}
    if (undefined === draft.inputProps.onKeyPress) {
      draft.inputProps.onKeyPress = _onKeyPress
    } else {
      draft.inputProps.onKeyPress = (...args) => {
        _onKeyPress(...args)
        return props.inputProps!.onKeyPress?.(...args)
      }
    }
  }) as TextFieldProps

  // @ts-ignore
  return <TextField {...newProps} />
}

export default NumberField
