import { useEffect, useRef, useState } from "react"
import {
  ApolloError,
  DocumentNode,
  NetworkStatus,
  OperationVariables,
  useQuery
} from "@apollo/client"

import { useOnlineContext } from "context/OnlineContext"
import useTimeouts from "hooks/useTimeouts"

export type UseSmartPollingQueryArgs<V> = {
  query: DocumentNode
  variables?: V
  on?: boolean
  interval?: number
  enableOffline?: boolean
}

const defaultVariables = {}

// Queries set to poll via the polling options on useQuery always trigger once.
// They also trigger once when the polling interval changes -- even when it's set
// to 0. Sometimes we don't want to do that.
const useSmartPollingQuery = <T = any, V = OperationVariables>({
  query,
  variables = defaultVariables as V,
  on = true,
  interval = 60 * 1000,
  enableOffline = false
}: UseSmartPollingQueryArgs<V>) => {
  const [data, setData] = useState<T | undefined>()
  const [error, setError] = useState<ApolloError | undefined>()
  const [loading, setLoading] = useState(false)
  const [networkStatus, setNetworkStatus] = useState<
    NetworkStatus | undefined
  >()
  const currentIntervalId = useRef<number | null>(null)
  const { online, appOnline, cachePersisted } = useOnlineContext()
  const { addInterval } = useTimeouts()

  const canPoll = enableOffline || (online && appOnline)

  const { refetch } = useQuery<T, V>(query, {
    variables,
    fetchPolicy: "cache-and-network",
    skip: true
  })

  useEffect(() => {
    if (!cachePersisted) return

    if (on && canPoll && interval !== 0) {
      clearInterval(currentIntervalId.current ?? 0)
      setLoading(true)
      setNetworkStatus(NetworkStatus.refetch)

      refetch()
        .then(result => {
          const { data, loading, networkStatus } = result
          setData(data)
          setLoading(loading)
          setError(undefined)
          setNetworkStatus(networkStatus)
          return result
        })
        .catch(err => {
          setData(undefined)
          setLoading(false)
          setError(err)
          setNetworkStatus(NetworkStatus.error)

          return err
        })
      currentIntervalId.current = addInterval(() => {
        setLoading(true)
        setNetworkStatus(NetworkStatus.refetch)

        refetch()
          .then(result => {
            const { data, loading, networkStatus } = result
            setData(data)
            setLoading(loading)
            setError(undefined)
            setNetworkStatus(networkStatus)
            return result
          })
          .catch(err => {
            setData(undefined)
            setLoading(false)
            setError(err)
            setNetworkStatus(NetworkStatus.error)

            return err
          })
      }, interval)
    } else {
      clearInterval(currentIntervalId.current ?? 0)
      currentIntervalId.current = null
    }
  }, [
    addInterval,
    cachePersisted,
    canPoll,
    interval,
    on,
    refetch,
    setData,
    setError,
    setLoading,
    setNetworkStatus
  ])

  return {
    data,
    error,
    loading,
    networkStatus,
    refetch
  }
}

export default useSmartPollingQuery
