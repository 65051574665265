import clsx from "clsx"
import {
  ButtonGroup as MUIButtonGroup,
  ButtonGroupProps
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

interface IButtonGroupProps
  extends Omit<ButtonGroupProps, "color" | "variant"> {}

// Starting to replicate MUI Button Group so it can be used with custom buttons
const useStyles = makeStyles(theme => ({
  horizontal: {
    "& button:not(:first-child):not(:last-child)": {
      borderRadius: 0
    },
    "& button:first-child:not(:last-child)": {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0
    },
    "& button:last-child:not(:first-child)": {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0
    }
  },
  vertical: {
    "& button:not(:first-child):not(:last-child)": {
      borderRadius: 0
    },
    "& button:first-child:not(:last-child)": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    },
    "& button:last-child:not(:first-child)": {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0
    }
  }
}))

// Because it's based on MUI's ButtonGroup, this component will also eat
// individual button styles
const ButtonGroup: React.FunctionComponent<IButtonGroupProps> = ({
  orientation = "horizontal",
  className,
  ...props
}) => {
  const classes = useStyles()

  return (
    <MUIButtonGroup
      className={clsx(className, classes[orientation])}
      orientation={orientation}
      {...props}
    />
  )
}

export default ButtonGroup
