import { useEffect, useState } from "react"
import {
  ApolloClient,
  ApolloProvider,
  gql,
  InMemoryCache
} from "@apollo/client"

import fragmentTypes from "./fragmentTypes.json"
import typeDefs from "./localTypeDefs"
import resolvers from "./resolvers"
import typePolicies from "./typePolicies"
import link from "./links"

import useOnline, { OnlineProvider } from "context/OnlineContext"
import useDebugMode, { DebugModeProvider } from "context/DebugContext"
import useServiceWorker, {
  MessageTypes,
  ServiceWorkerProvider
} from "context/ServiceWorkerContext"
import usePersistedCache from "./usePersistedCache"

export const cache = new InMemoryCache({
  possibleTypes: fragmentTypes.possibleTypes,
  typePolicies
})

export const client = new ApolloClient({
  name: "app-badger",
  link,
  cache,
  typeDefs,
  resolvers
})

cache.writeQuery({
  query: gql`
    {
      userSettings {
        darkModeSet
        darkMode
      }
      appState {
        online
        debug
      }
    }
  `,
  data: {
    userSettings: {
      __typename: "UserSettings",
      darkModeSet: false, // A user can explicitly set the dark mode
      darkMode: false
    },
    appState: {
      __typename: "AppState",
      // Default to online mode
      online: true,
      // Default to debug mode off
      debug: false
    }
  }
})

interface ApolloOfflineProviderProps {
  children: React.ReactNode
}

// Turn this into a apollo client component that handles making online state related queries and toggling the queue link + offline indicator state
const ApolloOfflineProvider: React.ComponentType<ApolloOfflineProviderProps> = ({
  children
}) => {
  // cache persisted and loaded successfully
  const [cachePersisted, setCachePersisted] = useState(false)
  const _onlineValues = useOnline(cachePersisted)
  const { appOnline } = _onlineValues
  const _serviceWorker = useServiceWorker()
  const { sendMessage } = _serviceWorker
  const debugValues = useDebugMode(cachePersisted)

  usePersistedCache(cache, setCachePersisted)

  useEffect(() => {
    if (appOnline !== null) {
      console.debug(
        `===<ApolloOfflineProvider /> setting service worker to ${
          appOnline ? "online" : "offline"
        } mode`
      )
      sendMessage({
        type: MessageTypes.SetOnlineState,
        payload: appOnline
      })
    }
  }, [sendMessage, appOnline])

  const onlineValues = {
    ..._onlineValues,
    cachePersisted
  }

  return (
    <ApolloProvider client={client}>
      <ServiceWorkerProvider value={_serviceWorker}>
        <OnlineProvider value={onlineValues}>
          <DebugModeProvider value={debugValues}>{children}</DebugModeProvider>
        </OnlineProvider>
      </ServiceWorkerProvider>
    </ApolloProvider>
  )
}

export default ApolloOfflineProvider
