import { Icon, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined"

export type ActiveDropZoneProps = {
  helperText?: string
}

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: "center"
  },
  icon: {
    fontSize: "400%",
    marginRight: theme.spacing(1)
  }
}))

export const dataTestid = "active-dropzone"

const ActiveDropZone: React.FunctionComponent<ActiveDropZoneProps> = ({
  helperText
}) => {
  const classes = useStyles()

  return (
    <section className={classes.root} data-testid={dataTestid}>
      <Icon className={classes.icon}>
        <CloudUploadOutlinedIcon fontSize="inherit" />
      </Icon>
      <Typography color="inherit">Drop your file here. {helperText}</Typography>
    </section>
  )
}

export default ActiveDropZone
