import Grid, { GridProps } from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import { FormikContextType } from "formik"
import cloneDeep from "lodash/cloneDeep"
import FormSection from "./FormSection/FormSection"
import {
  CustomFormSectionProps,
  FormConfig,
  FormSectionGridOptions,
  FormSectionKinds,
  FormSectionProps
} from "./FormSection/FormSection.d"
import { FormInputGridOptions } from "./FormInput/FormInput.d"
import {
  parseDataProps as _parseDataProps,
  shouldDisplay as _shouldDisplay
} from "./utils/utils"

export interface IFormFactoryProps {
  formConfigs: FormConfig
  formik?: FormikContextType<any>
}

export const defaultGridOptions: GridProps = {
  item: true
}

export const fulls = {
  xs: 12
} as FormInputGridOptions | FormSectionGridOptions

export const halves = {
  xs: 12,
  sm: 6
} as FormInputGridOptions | FormSectionGridOptions

export const thirds = {
  xs: 12,
  sm: 6,
  lg: 4
} as FormInputGridOptions | FormSectionGridOptions

export const quarters = {
  xs: 12,
  md: 4,
  lg: 3
} as FormInputGridOptions | FormSectionGridOptions

export const sectionGrid = {
  item: true,
  xs: 12
} as FormSectionGridOptions

export const gridWrapperId = "grid-wrapper"

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
    maxWidth: "100%"
  }
}))

/*
FormFactory is often wrapped in a Grid to enable grid layout of sections, it is not a requirement.
This the Grid is outside of the FormFactory to allow dynamic container properties without
having the put the logic into the FormFactory.
If using grid styling on FormSections make sure to wrap the FormFactor in a Grid container.
*/
const FormFactory: React.FunctionComponent<IFormFactoryProps> = ({
  formConfigs,
  formik
}) => {
  const classes = useStyles()
  const shouldDisplay = _shouldDisplay({ formik })
  const parseDataProps = _parseDataProps({ formik })

  return (
    <>
      {formConfigs.map((section: FormSectionProps, sectionIndex: number) => {
        const { displayWhen, grid, ...sectionProps } = section

        if (!shouldDisplay(displayWhen)) return null

        let sectionComponent: React.ReactNode | null
        switch (section.kind) {
          case FormSectionKinds.Custom:
            const {
              componentProps: _componentProps,
              ...baseSectionProps
            } = sectionProps as CustomFormSectionProps

            const componentProps = cloneDeep(_componentProps)
            const { data } = componentProps
            delete componentProps.data

            const parsedComponentProps = data ? parseDataProps(data) : {}

            const parsedSectionProps = {
              ...baseSectionProps,
              componentProps: {
                ...componentProps,
                ...parsedComponentProps
              }
            }

            sectionComponent = (
              <FormSection
                key={`section-${sectionIndex}`}
                {...parsedSectionProps}
                formik={formik}
              />
            )
            break

          default:
            sectionComponent = (
              <FormSection
                key={`section-${sectionIndex}`}
                {...sectionProps}
                formik={formik}
              />
            )
        }

        if (grid) {
          const gridOptions: GridProps =
            `object` === typeof grid
              ? { ...defaultGridOptions, ...(grid as FormSectionGridOptions) }
              : { ...defaultGridOptions }

          sectionComponent = (
            <Grid
              className={classes.grow}
              key={`grid-section-${sectionIndex}`}
              data-testid={gridWrapperId}
              {...gridOptions}
            >
              {sectionComponent}
            </Grid>
          )
        }

        return sectionComponent
      })}
    </>
  )
}

export default FormFactory
