import { AvatarProps } from "@material-ui/core"
import { CognitoUser } from "@aws-amplify/auth"
import reduce from "lodash/reduce"
import some from "lodash/some"

import { GetCurrentUserQuery, Group, Maybe, User } from "client/types"

export type UserContextType = {
  firstName: string | undefined | null
  lastName: string | undefined | null
  displayName: string
  username?: string
  avatar?: AvatarProps["src"]
  email: string
  user: GetCurrentUserQuery["currentUser"] | (CognitoUser & { fullName: never })
  companies?: Array<User>
  groups?: Array<Group>
  id?: Maybe<User["id"]>
}

export function getDisplayName({
  user,
  firstName,
  lastName,
  email
}: Partial<Pick<UserContextType, "user">> &
  Pick<UserContextType, "firstName" | "lastName" | "email">): string {
  if (user?.fullName) {
    return user.fullName
  }
  return firstName && lastName ? `${firstName} ${lastName}` : email
}

// If a user is assigned to any of the groups in the map, they will be limited to
// paths that match the regexes. Otherwise, they have full access for their
// authentication type--token vs. Cognito--defined in src/routes.tsx
//
// The routes uses the group name because the group ids could be different between
// local/staging/production.
export type AllowListMap = Record<string, Array<RegExp>>
// TODO: Switch to permission strings rather than group names once that's implemented
// on the back end.
export const allowListMap: AllowListMap = {
  Customer: [/^\/customer$/, /^\/customer\/.*/]
}
export const _verifyAccess = (map: AllowListMap) => (groups?: Array<Group>) => (
  path: string = window.location.pathname
) => {
  const regexes = reduce<Group, Array<RegExp>>(
    groups,
    (acc, group) => [...acc, ...(map?.[group?.name ?? ""] ?? [])],
    []
  )

  // If a user isn't in any restricted groups, allow them full access
  return 0 === regexes.length ? true : some(regexes, regex => regex.test(path))
}
export const verifyAccess = _verifyAccess(allowListMap)
