import jwt from "jsonwebtoken"
import Cookies from "universal-cookie"

import { setContext } from "@apollo/client/link/context"
import { updateAppVars } from "client/localVars"
import { AuthorizationType } from "client/types"

const cookies = new Cookies()

export enum TokenNames {
  Access = "titan_access_token",
  Refresh = "titan_refresh_token"
}

export type Tokens = {
  accessToken: string | null
  refreshToken: string | null
}

export enum TokenOperation {
  Auth = "Auth",
  Refresh = "Refresh"
}

const paths = {
  [TokenOperation.Auth]: "token/tokenauth/",
  [TokenOperation.Refresh]: "token/refresh/"
}

export const getAuthToken = () => {
  const params = new URLSearchParams(window.location.search)
  return params.get("authtoken")
}

export const fetchAuthAPI = (
  token: string,
  operation: TokenOperation
): Promise<Tokens> => {
  const path = paths[operation]
  let body: string
  switch (operation) {
    case TokenOperation.Auth:
      body = JSON.stringify({ token })
      break
    case TokenOperation.Refresh:
      body = JSON.stringify({ refresh: token })
      break
  }
  const options: RequestInit = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      "X-Requested-With": "XMLHttpRequest"
    },
    body
  }

  return (
    fetch(`${process.env.REACT_APP_TOKEN_URL}${path}`, options)
      .then(response =>
        response.ok ? response.json() : Promise.reject(`Bad ${operation} Token`)
      )
      // Every time new tokens are fetched, they should be put in the cookies
      .then(tokens => {
        const refreshToken =
          TokenOperation.Refresh === operation ? token : tokens.refresh

        const decodedAccess = jwt.decode(tokens.access)
        const decodedRefresh = jwt.decode(refreshToken)

        cookies.set(TokenNames.Access, tokens.access, {
          path: "/",
          expires: new Date((decodedAccess?.["exp"] ?? 0) * 1000)
        })
        cookies.set(TokenNames.Refresh, refreshToken, {
          path: "/",
          expires: new Date((decodedRefresh?.["exp"] ?? 0) * 1000)
        })
        return {
          accessToken: tokens.access,
          refreshToken: refreshToken
        }
      })
      // If the attempt fails for any reason, also remove any existing cookies
      .catch(err => {
        console.error(err)
        cookies.remove(TokenNames.Access)
        cookies.remove(TokenNames.Refresh)
        return { accessToken: null, refreshToken: null }
      })
  )
}

const getAccessTokens = async (): Promise<Tokens> => {
  const accessToken = cookies.get(TokenNames.Access)
  const refreshToken = cookies.get(TokenNames.Refresh)

  // If there is a refresh token, but not an access token, it means the
  // access token has expired and the cookie disappeared.
  if (accessToken) {
    return { accessToken, refreshToken }
  } else if (refreshToken) {
    return fetchAuthAPI(refreshToken ?? "", TokenOperation.Refresh).then(
      tokens => {
        if (!tokens.accessToken || !tokens.refreshToken)
          updateAppVars({ authorization: AuthorizationType.Expired })
        return tokens
      }
    )
  } else {
    const authToken = getAuthToken()
    return fetchAuthAPI(authToken ?? "", TokenOperation.Auth).then(tokens => {
      if (!tokens.accessToken || !tokens.refreshToken)
        updateAppVars({ authorization: AuthorizationType.Expired })
      return tokens
    })
  }
}

const getBearerToken = async () => {
  const { accessToken } = await getAccessTokens()
  return accessToken ? `Titan-Bearer ${accessToken}` : null
}

const tokenAuthLink = setContext(async (operation, context) => {
  const { headers = {} } = context
  const token = await getBearerToken()

  if (token) {
    return {
      headers: {
        ...headers,
        Authorization: token
      }
    }
  }
})

export default tokenAuthLink
