import {
  Grid,
  GridProps,
  InputAdornment,
  TextField,
  Typography
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useFormikContext } from "formik"
import castArray from "lodash/castArray"

import { DisplayTicket } from "views/TicketDetails/TicketDetails"
import {
  ftRegex,
  parseInches,
  ParsedRunningProcedureTicketStepResult
} from "hooks/runningProcedures"

import { defaultTextFieldProps } from "components/formik/TextField"
import { useFormStateContext } from "hooks/forms/useFormState"
import StepNotFound from "./StepNotFound"

import enums from "client/enums"
import { FieldType } from "client/types"

export type FeetInchesMeasurementStepProps = {
  index: number
  units: string | Array<string>
  inputGrid?: GridProps
  instructions?: string | null
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  constrainedGridItem: {
    maxWidth: 250
  },
  input: {
    "& input": {
      textAlign: "right"
    }
  },
  lockedWrapper: {
    display: "flex"
  },
  lockedDataSection: {
    display: "flex",
    flexDirection: "column",
    "&:not(:first-child)": {
      marginLeft: theme.spacing(2)
    }
  },
  lockedLabel: {
    color: theme.palette.text.primary,
    textTransform: "uppercase"
  },
  lockedData: {
    display: "flex",
    alignItems: "center"
  },
  lockedDatum: {
    fontWeight: 800,
    "&:not(:first-child)": {
      marginLeft: theme.spacing(0.5)
    }
  },
  lockedUnit: {
    marginLeft: theme.spacing(0.5)
  },
  lockedNoData: {
    color: theme.palette.text.secondary
  }
}))

export const dataTestid = "feet-inches-measurement-step"
export const invalidFeetFormatMessage = "Feet must be a whole number"
export const invalidInchesFormatMessage = "Inches must have a valid format"
export const invalidInchesDenominatorMessage =
  "The denominator must be a power of 2"
const FeetInchesMeasurementStep: React.FunctionComponent<FeetInchesMeasurementStepProps> = ({
  index,
  instructions,
  units: _units,
  inputGrid = { xs: 12, md: 6 } as GridProps
}) => {
  const classes = useStyles()
  const formik = useFormikContext<Partial<DisplayTicket>>()
  const step = formik.values?.steps?.[
    index
  ] as ParsedRunningProcedureTicketStepResult

  const { getMetadata } = useFormStateContext()

  // If the running procedure step order is broken, there might be no step or no
  // parsedData. If so, display the step not found message instead of crashing.
  // parsedData should always be an object, so no need to worry about expected
  // falsey values from short-circuiting the display.
  if (!step?.parsedData) return <StepNotFound index={index} />

  const { isSubmitting } = formik
  const {
    isDocumentationLocked = false,
    isMutationLoading = false
  } = getMetadata()

  const [feet, inches] = (step.parsedData?.[FieldType.Length]?.data ?? [
    "",
    ""
  ]) as string[]
  const [_inches, , _denominator] = parseInches(inches)

  const isFeetValid = "" === feet || ftRegex.test(feet)
  const isInchesValid = "" === inches || !isNaN(_inches)
  // If inches is not NaN and the denominator is NaN, the input is valid
  // because there is no fraction.
  // If inches is NaN, the denominator will also be NaN because the the
  // input is invalid.
  // Therefore, consider a denominator of NaN to be valid because the
  // value of isInchesValid will determine the error state
  const isDenominatorValid = isNaN(_denominator)
    ? true
    : Number.isInteger(Math.log2(_denominator)) && _denominator <= 128

  const units = castArray(_units)

  const content = isDocumentationLocked ? (
    <Grid className={classes.lockedWrapper} item xs={12}>
      <div className={classes.lockedDataSection}>
        <Typography variant="caption" className={classes.lockedLabel}>
          {enums.FieldType.description(FieldType.LengthFt) ?? "No Label"}
        </Typography>
        {"" !== feet && "" !== inches ? (
          <div className={classes.lockedData}>
            <Typography
              className={classes.lockedDatum}
              variant="body1"
              component="span"
            >
              {feet}
            </Typography>
            <Typography
              className={classes.lockedUnit}
              variant="body2"
              component="span"
            >
              {units[0]}
            </Typography>
            <Typography
              className={classes.lockedDatum}
              variant="body1"
              component="span"
            >
              {inches}
            </Typography>
            <Typography
              className={classes.lockedUnit}
              variant="body2"
              component="span"
            >
              {units[1]}
            </Typography>
          </div>
        ) : (
          <Typography variant="body1" className={classes.lockedNoData}>
            No Data
          </Typography>
        )}
      </div>
    </Grid>
  ) : (
    <>
      <Grid className={classes.constrainedGridItem} item {...inputGrid}>
        <TextField
          className={classes.input}
          value={feet ?? ""}
          id={`${index}-FEET`}
          label="Feet"
          {...defaultTextFieldProps}
          margin="dense"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="caption">{units[0]}</Typography>
              </InputAdornment>
            )
          }}
          error={!isFeetValid}
          helperText={!isFeetValid && invalidFeetFormatMessage}
          disabled={isSubmitting || (isMutationLoading as boolean)}
          onChange={evt => {
            formik.setFieldValue(`steps[${index}].parsedData.LENGTH.data`, [
              evt.target.value,
              inches
            ])
          }}
        />
      </Grid>
      <Grid className={classes.constrainedGridItem} item {...inputGrid}>
        <TextField
          className={classes.input}
          id={`${index}-INCHES`}
          value={inches ?? ""}
          label="Inches"
          {...defaultTextFieldProps}
          margin="dense"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="caption">{units[1]}</Typography>
              </InputAdornment>
            )
          }}
          error={!isInchesValid || !isDenominatorValid}
          helperText={
            isInchesValid
              ? isDenominatorValid
                ? ""
                : invalidInchesDenominatorMessage
              : invalidInchesFormatMessage
          }
          disabled={isSubmitting || (isMutationLoading as boolean)}
          onChange={evt => {
            formik.setFieldValue(`steps[${index}].parsedData.LENGTH.data`, [
              feet,
              evt.target.value
            ])
          }}
        />
      </Grid>
    </>
  )

  return (
    <Grid
      className={classes.root}
      container
      spacing={2}
      data-testid={dataTestid}
    >
      <Grid item xs={12}>
        {instructions}
      </Grid>
      {content}
    </Grid>
  )
}

export default FeetInchesMeasurementStep
