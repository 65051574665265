import MomentUtils from "@date-io/moment"
import { CssBaseline } from "@material-ui/core"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { SnackbarProvider } from "notistack"

import { GoogleAnalytics, ScrollReset } from "components"
import { ConfirmProvider } from "components/ConfirmDialog"
import { WaffleProvider } from "context/WaffleContext"

import "layouts/Main/main.scss"
import Content from "./Content"

// the sidebar can pull from the params

interface ITicketReviewLayoutProps {}

const TicketReviewLayout: React.FunctionComponent<ITicketReviewLayoutProps> = () => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <WaffleProvider polling={true}>
        <CssBaseline />
        <ScrollReset />
        <GoogleAnalytics />
        <ConfirmProvider>
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            preventDuplicate
            hideIconVariant
          >
            <Content />
          </SnackbarProvider>
        </ConfirmProvider>
      </WaffleProvider>
    </MuiPickersUtilsProvider>
  )
}

export default TicketReviewLayout
