// vim: foldmethod=marker:foldmarker={{{,}}}
// imports {{{
import { useCallback } from "react"
import { FormattedMessage } from "react-intl"
import { useApolloClient } from "@apollo/client"
import { Typography } from "@material-ui/core"
import moment from "moment"

import compact from "lodash/compact"
import every from "lodash/every"
import filter from "lodash/filter"
import flatten from "lodash/flatten"
import flatMap from "lodash/flatMap"
import map from "lodash/map"
import reduce from "lodash/reduce"
import reject from "lodash/reject"
import size from "lodash/size"
import some from "lodash/some"

import { useConfirm } from "components/ConfirmDialog"
import { getRevisionPDFInfo } from "hooks/runningProcedures"
import useSyncDatabase, {
  SyncDocumentData,
  SyncRemovedDocument,
  SyncTable
} from "./useSyncDatabase"
import useSyncQuery from "./useSyncQuery"

import {
  AddedCharge,
  RemovedCharge,
  AddedSignature,
  convertSyncDateToFilter,
  getAddedDocuments,
  getAddedSignatures,
  getChargeChanges,
  getRejectedSignatures,
  getSignatureRequests,
  getTicketChanges,
  processSyncQuery,
  ProductSyncFilter,
  RejectedSignature,
  SignatureRequest,
  TicketChangesMap,
  upsyncCharges,
  upsyncDocuments,
  upsyncTickets,
  upsyncSignatures
} from "./utils"
import { edgeLess } from "utils/apollo"
import { AttachmentTagType, getTagTypes } from "components/Attachments/utils"

import { GET_CURRENT_USER } from "queries/currentUser"
import {
  SYNC_CHARGES,
  SYNC_DOCUMENTS,
  SYNC_PRODUCTS,
  SYNC_PRICE_RULES,
  SYNC_RUNNING_PROCEDURES,
  SYNC_SALES_ORDERS,
  SYNC_TICKETS,
  SYNC_USERS,
  SYNC_WELLS
} from "queries/sync"
import { GET_TICKET_PHASE_OPTIONS } from "queries/tickets"
import { GET_WAFFLE_BITS } from "queries/waffle"

import {
  AddDocumentInput,
  AssignmentTypes,
  ChargeInput,
  DocumentType,
  SalesOrderStatuses,
  SyncChargesQuery,
  SyncChargesQueryVariables,
  SyncDocumentsQuery,
  SyncDocumentsQueryVariables,
  SyncPriceRulesQuery,
  SyncPriceRulesQueryVariables,
  SyncProductsQuery,
  SyncProductsQueryVariables,
  SyncRunningProceduresQuery,
  SyncRunningProceduresQueryVariables,
  SyncSalesOrdersQuery,
  SyncSalesOrdersQueryVariables,
  SyncTicketsQuery,
  SyncTicketsQueryVariables,
  SyncUsersQuery,
  SyncUsersQueryVariables,
  SyncWellsQuery,
  SyncWellsQueryVariables,
  TicketStatuses
} from "client/types"
import messageValues from "utils/messageValues"
// imports }}}

// DownsyncResult {{{
export type DownsyncResult = {
  sync: string
  success: boolean
}
// DownsyncResult }}}

// UpsyncDiffs {{{
export type UpsyncDiffs = {
  addedCharges: Array<AddedCharge>
  addedDocuments: Array<Omit<AddDocumentInput, "category">>
  addedSignatures: Array<AddedSignature>
  rejectedSignatures: Array<RejectedSignature>
  removedCharges: Array<RemovedCharge>
  removedDocuments: Array<string>
  signatureRequests: Array<SignatureRequest>
  ticketChanges: TicketChangesMap
  updatedCharges: Array<ChargeInput>
  restoredCharges: Array<string>
}
// UpsyncDiffs }}}

// UpsyncResults {{{
export type UpsyncResults = {
  allTicketsSynced: boolean
  allChargesSynced: boolean
  allDocumentsSynced: boolean
  allSignaturesSynced: boolean
}
// UpsyncResults }}}

// useOfflineSync {{{
const useOfflineSync = () => {
  const {
    db,
    clearCharges,
    clearDocumentData,
    clearDocuments,
    clearInitialCharges,
    clearInitialTickets,
    clearPriceRules,
    clearRemovedDocuments,
    clearSalesOrders,
    clearStoredDocuments,
    clearStoredSignatures,
    clearTickets
  } = useSyncDatabase()
  const client = useApolloClient()
  const confirm = useConfirm()

  // chargeSyncQuery {{{
  const chargeSyncQuery = useSyncQuery<
    SyncChargesQuery,
    SyncChargesQueryVariables
  >({
    query: SYNC_CHARGES,
    propName: "charges",
    batchSize: 1000,
    paged: false
  })
  // chargeSyncQuery }}}

  // documentSyncQuery {{{
  const documentSyncQuery = useSyncQuery<
    SyncDocumentsQuery,
    SyncDocumentsQueryVariables
  >({
    query: SYNC_DOCUMENTS,
    propName: "documents",
    batchSize: 1000,
    paged: false,
    trackFields: ["id", "label", "original", "smallThumbnail", "type"]
  })
  // documentSyncQuery }}}

  // priceRuleSyncQuery {{{
  const priceRuleSyncQuery = useSyncQuery<
    SyncPriceRulesQuery,
    SyncPriceRulesQueryVariables
  >({
    query: SYNC_PRICE_RULES,
    propName: "priceRules",
    batchSize: 1000,
    paged: false
  })
  // priceRuleSyncQuery }}}

  // productSyncQuery {{{
  const productSyncQuery = useSyncQuery<
    SyncProductsQuery,
    SyncProductsQueryVariables
  >({
    query: SYNC_PRODUCTS,
    propName: "products",
    batchSize: 3000,
    paged: false
  })
  // productSyncQuery }}}

  // runningProcedureSyncQuery {{{
  const runningProcedureSyncQuery = useSyncQuery<
    SyncRunningProceduresQuery,
    SyncRunningProceduresQueryVariables
  >({
    query: SYNC_RUNNING_PROCEDURES,
    propName: "procedures",
    batchSize: 1000,
    paged: false
  })
  // runningProcedureSyncQuery }}}

  // salesOrderSyncQuery {{{
  const salesOrderSyncQuery = useSyncQuery<
    SyncSalesOrdersQuery,
    SyncSalesOrdersQueryVariables
  >({
    query: SYNC_SALES_ORDERS,
    propName: "salesOrders",
    batchSize: 1000,
    paged: true
  })
  // salesOrderSyncQuery }}}

  // ticketSyncQuery {{{
  const ticketSyncQuery = useSyncQuery<
    SyncTicketsQuery,
    SyncTicketsQueryVariables
  >({
    query: SYNC_TICKETS,
    propName: "tickets",
    batchSize: 1000,
    paged: false,
    trackFields: ["id", "number", "company", "procedure", "procedureRevisionId"]
  })
  // ticketSyncQuery }}}

  // userSyncQuery {{{
  const userSyncQuery = useSyncQuery<SyncUsersQuery, SyncUsersQueryVariables>({
    query: SYNC_USERS,
    propName: "users",
    batchSize: 1000,
    paged: false
  })
  // userSyncQuery }}}

  // wellSyncQuery {{{
  const wellSyncQuery = useSyncQuery<SyncWellsQuery, SyncWellsQueryVariables>({
    query: SYNC_WELLS,
    propName: "wells",
    batchSize: 1000,
    paged: false
  })
  // wellSyncQuery }}}

  // getDiffs {{{
  const getDiffs = useCallback(async (): Promise<UpsyncDiffs> => {
    const initialTickets = await db[SyncTable.InitialTickets].toArray()
    const changedTicketIds = map(initialTickets, "id")
    const changedTickets = compact(await db.tickets.bulkGet(changedTicketIds))

    const initialCharges = await db[SyncTable.InitialCharges].toArray()
    const changedCharges = await db[SyncTable.Charges].toArray()

    const addedDocuments = getAddedDocuments(
      await db[SyncTable.StoredDocuments].toArray()
    )
    const removedDocuments = map<SyncRemovedDocument>(
      await db[SyncTable.RemovedDocuments].toArray(),
      "uuid"
    )

    const {
      addedCharges,
      removedCharges,
      updatedCharges,
      restoredCharges
    } = getChargeChanges(initialCharges)(changedCharges)

    const storedSignatures = await db[SyncTable.StoredSignatures].toArray()
    const addedSignatures = getAddedSignatures(storedSignatures)
    const rejectedSignatures = getRejectedSignatures(storedSignatures)
    const signatureRequests = getSignatureRequests(storedSignatures)
    const ticketChanges = getTicketChanges(initialTickets)(changedTickets)

    return {
      addedCharges,
      addedDocuments,
      addedSignatures,
      rejectedSignatures,
      removedCharges,
      removedDocuments,
      signatureRequests,
      ticketChanges,
      updatedCharges,
      restoredCharges
    }
  }, [db])
  // getDiffs }}}

  // checkPendingUpsyncData {{{
  const checkPendingUpsyncData = useCallback((diffs: UpsyncDiffs) => {
    const { ticketChanges, ...arrayDiffs } = diffs
    return (
      some(arrayDiffs, arr => 0 < arr.length) ||
      (0 < size(ticketChanges) && !ticketChanges.error)
    )
  }, [])
  // checkPendingUpsyncData }}}

  // checkDownsyncSuccess {{{
  const checkDownsyncSuccess = useCallback(
    (results: Array<DownsyncResult>) =>
      every(results, ({ success }) => success),
    []
  )
  // checkDownsyncSuccess }}}

  // checkUpsyncSuccess {{{
  const checkUpsyncSuccess = useCallback(
    (results: UpsyncResults): boolean =>
      reduce<Record<string, boolean>, boolean>(
        results,
        (acc, result) => acc && result,
        true
      ),
    []
  )
  // checkUpsyncSuccess }}}

  // downsync {{{
  const downsync = useCallback(
    async (filters: Record<string, ProductSyncFilter | undefined>) => {
      const {
        productSyncFilter,
        runningProcedureSyncFilter,
        wellSyncFilter,
        userSyncFilter
      } = filters

      // ticketPhaseOptionsQuery {{{
      const ticketPhaseOptionsQuery = client
        .query({
          query: GET_TICKET_PHASE_OPTIONS,
          fetchPolicy: "no-cache"
        })
        .then(queryResult => {
          const { errors } = queryResult
          return errors
            ? { sync: "Ticket Phase Options", success: false }
            : { sync: "Ticket Phase Options", success: true }
        })
        .catch(err => {
          return {
            sync: "Ticket Phase Options",
            success: false
          }
        })
      // ticketPhaseOptionsQuery }}}

      // waffleQuery {{{
      const waffleQuery = client
        .query({
          query: GET_WAFFLE_BITS,
          fetchPolicy: "no-cache"
        })
        .then(queryResult => {
          const { errors } = queryResult
          return errors
            ? { sync: "Waffle Flags", success: false }
            : { sync: "Waffle Flags", success: true }
        })
        .catch(err => {
          return {
            sync: "Waffle Flags",
            success: false
          }
        })
      // waffleQuery }}}

      return flatten<DownsyncResult>(
        await Promise.all([
          processSyncQuery("Products")(productSyncQuery.run(productSyncFilter)),
          processSyncQuery("Running Procedures")(
            runningProcedureSyncQuery.run(runningProcedureSyncFilter)
          ),
          // ticketSyncQuery (tons of stuff chains after this query) {{{
          ticketSyncQuery
            .run({
              assignment: { type: AssignmentTypes.Tech },
              status: TicketStatuses.Dispatched
            })
            .then(({ count, batchCount, trackedFieldData }) => {
              console.log(`Synced ${count} Tickets in ${batchCount} batches.`)
              // The syncing process does include syncing the information for all running
              // procedures, but that's happening in parallel, so instead of waiting for
              // that to finish, we use the information about procedures that comes back
              // with the tickets.
              const pdfInfo = getRevisionPDFInfo(trackedFieldData.procedure)(
                trackedFieldData.procedureRevisionId
              )

              // sync pdfs for ticket rps {{{
              const rpPDFPromises = Promise.all(
                map(pdfInfo, ({ cabinetId, cabinetLabel, label }) =>
                  client.query({
                    query: SYNC_DOCUMENTS,
                    fetchPolicy: "no-cache",
                    variables: {
                      filter: {
                        cabinetId,
                        cabinetLabel,
                        label,
                        label_lookup: "iexact"
                      }
                    }
                  })
                )
              )
                .then(resultSet => {
                  const allEdges = flatMap(
                    resultSet,
                    result => result?.data?.documents?.edges
                  )
                  return edgeLess(allEdges).map(
                    ({ id, label, mimetype, original }) => ({
                      id,
                      label,
                      mimetype,
                      original
                    })
                  )
                })
                .then(async pdfData => {
                  return Promise.all(
                    map(pdfData, async ({ id, label, original }) => {
                      const response = await fetch(original)
                      const blob = await response.blob()

                      return new Promise((resolve, reject) => {
                        const reader = new FileReader()
                        reader.onload = evt => {
                          // Wrapping in try/catch to keep error handling within promise chain
                          try {
                            resolve({
                              id,
                              label,
                              dataURL: evt?.target?.result
                            })
                          } catch (err) {
                            reject(err)
                          }
                        }
                        reader.onerror = evt => {
                          reject(reader.error)
                          reader.abort()
                        }
                        reader.readAsDataURL(blob)
                      })
                    })
                  )
                })
                .then(results => {
                  return db[SyncTable.DocumentData].bulkPut(
                    results as Array<SyncDocumentData>,
                    {
                      allKeys: true
                    }
                  )
                })
                .then(keys => {
                  console.log(`Synced ${keys.length} Running Procedure PDFs.`)
                  return {
                    sync: "Running Procedure PDFs",
                    success: true
                  }
                })
                .catch(err => {
                  console.error("Sync error for RP PDFs: ", err)
                  return {
                    sync: "Running Procedure PDFs",
                    success: false
                  }
                })
              // sync pdfs for ticket rps }}}

              // Currently, the back end treats an empty array as no filter, so working around it
              // here until that gets looked at: https://patriotrc.atlassian.net/browse/HB-498
              // Update: This works well for list page filtering on multiselect inputs, where no
              // selection/no filter is usually sent as an empty array.
              return Promise.all([
                // sync charges for tickets {{{
                trackedFieldData.id.length > 0
                  ? processSyncQuery("Charges")(
                      chargeSyncQuery.run({
                        ticketIn: trackedFieldData.id,
                        showDeletedRequired: true
                      })
                    )
                  : { sync: "Charges", success: true },
                // sync charges for tickets }}}

                // sync documents for tickets (based on number == cabinet) {{{
                trackedFieldData.number.length > 0
                  ? documentSyncQuery
                      .run({
                        cabinetLabelIn: trackedFieldData.number,
                        // Only sync documents that will be displayed while offline
                        typeIn: [DocumentType.Image, DocumentType.Document]
                      })
                      .then(({ count, batchCount, trackedFieldData }) => {
                        console.log(
                          `Synced info for ${count} Documents in ${batchCount} batches.`
                        )
                        const {
                          id,
                          label,
                          original,
                          type,
                          smallThumbnail
                        } = trackedFieldData
                        const attachmentData = reduce(
                          id,
                          (acc, id, index) => [
                            ...acc,
                            {
                              id,
                              label: label[index],
                              original: original[index],
                              type: type[index],
                              smallThumbnail: smallThumbnail[index]
                            }
                          ],
                          [] as Array<Record<string, any>>
                        )

                        return Promise.all(
                          // Only fetch dataURLs for non-RP step photos
                          reject(attachmentData, ({ label }) =>
                            some(
                              getTagTypes(label),
                              el => AttachmentTagType.Step === el
                            )
                          ).map(async ({ id, label, original }) => {
                            const dataURL = await fetch(original)
                              .then(res => res.blob())
                              .then(
                                blob =>
                                  new Promise((resolve, reject) => {
                                    const reader = new FileReader()
                                    reader.onload = evt => {
                                      // Wrapping in try/catch to keep error handling within promise chain
                                      try {
                                        resolve(evt?.target?.result)
                                      } catch (err) {
                                        reject(err)
                                      }
                                    }
                                    reader.onerror = evt => {
                                      reject(reader.error)
                                      reader.abort()
                                    }
                                    reader.readAsDataURL(blob)
                                  })
                              )

                            return {
                              id,
                              label,
                              dataURL
                            } as SyncDocumentData
                          })
                        )
                          .then(results =>
                            db[SyncTable.DocumentData].bulkPut(results, {
                              allKeys: true
                            })
                          )
                          .then(keys => {
                            console.log(
                              `Synced data for ${keys.length} Documents.`
                            )
                            return {
                              sync: "Documents",
                              success: true
                            }
                          })
                      })
                      .catch(() => ({
                        sync: "Documents",
                        success: false
                      }))
                  : {
                      sync: "Documents",
                      success: true
                    },
                // sync documents for tickets (based on number == cabinet) }}}

                // sync price rules for companies {{{
                trackedFieldData.company.length > 0
                  ? processSyncQuery("Price Rules")(
                      priceRuleSyncQuery.run({
                        companyIn: map(
                          trackedFieldData.company,
                          company => company.id
                        )
                      })
                    )
                  : {
                      sync: "Price Rules",
                      success: true
                    },
                // sync price rules for companies }}}

                // sync sales orders for companies {{{
                trackedFieldData.company.length > 0
                  ? processSyncQuery("Sales Orders")(
                      salesOrderSyncQuery.run({
                        status: SalesOrderStatuses.Unsigned,
                        companies: map(
                          trackedFieldData.company,
                          company => company.id
                        )
                      })
                    )
                  : {
                      sync: "Sales Orders",
                      success: true
                    },
                // sync sales orders for companies }}}
                {
                  sync: "Tickets",
                  success: true
                },
                rpPDFPromises
              ])
            })
            .catch(err => {
              console.error(`Sync error for Tickets: `, err)

              return {
                sync: "Tickets",
                success: false
              }
            }),
          // ticketSyncQuery }}}
          processSyncQuery("Users")(userSyncQuery.run(userSyncFilter)),
          processSyncQuery("Wells")(wellSyncQuery.run(wellSyncFilter)),
          ticketPhaseOptionsQuery,
          waffleQuery
        ])
      )
    },
    [
      chargeSyncQuery,
      client,
      db,
      documentSyncQuery,
      priceRuleSyncQuery,
      productSyncQuery,
      runningProcedureSyncQuery,
      salesOrderSyncQuery,
      ticketSyncQuery,
      userSyncQuery,
      wellSyncQuery
    ]
  )
  // downsync }}}

  // flushUpsyncTracker {{{
  const flushUpsyncTracker = useCallback(
    async () =>
      await db[SyncTable.UpsyncTracker]
        .where("attempted")
        .below(moment().subtract(14, "days").toDate())
        .delete(),
    [db]
  )
  // flushUpsyncTracker }}}

  // getDownsyncFilters {{{
  const getDownsyncFilters = async (): Promise<
    Record<string, ProductSyncFilter | undefined>
  > => {
    const productSyncFilter = await db[
      SyncTable.LastFullSync
    ].get("syncProducts", el => convertSyncDateToFilter(el?.date))
    const runningProcedureSyncFilter = await db[
      SyncTable.LastFullSync
    ].get("syncRunningProcedures", el => convertSyncDateToFilter(el?.date))
    const wellSyncFilter = await db[SyncTable.LastFullSync].get(
      "syncWells",
      el => convertSyncDateToFilter(el?.date)
    )
    const userSyncFilter = await db[SyncTable.LastFullSync].get(
      "syncUsers",
      el => convertSyncDateToFilter(el?.date)
    )

    return {
      productSyncFilter,
      runningProcedureSyncFilter,
      wellSyncFilter,
      userSyncFilter
    }
  }
  // getDownsyncFilters }}}

  // flushStaleData {{{
  // * Clear existing charges, documents and documents to flush out stale data.
  // * Clear tickets so only currenly assigned tickets show up in the ticket list.
  // * Clear out diff tracking tables for fresh state. They should be cleared after
  //   upsync, but you never know. And if there is a problem during upsync, the app
  //   stays in offline mode, the diff data will still be in Dexie if needed.
  const flushStaleData = useCallback(async () => {
    const clearFns = [
      clearCharges,
      clearDocumentData,
      clearDocuments,
      clearInitialCharges,
      clearInitialTickets,
      clearPriceRules,
      clearRemovedDocuments,
      clearSalesOrders,
      clearStoredDocuments,
      clearStoredSignatures,
      clearTickets
    ]

    return Promise.allSettled(map(clearFns, fn => fn().catch(console.error)))
  }, [
    clearCharges,
    clearDocumentData,
    clearDocuments,
    clearInitialCharges,
    clearInitialTickets,
    clearPriceRules,
    clearRemovedDocuments,
    clearSalesOrders,
    clearStoredDocuments,
    clearStoredSignatures,
    clearTickets
  ])
  // flushStaleData }}}

  // notifyDownsyncFailure {{{
  const notifyDownsyncFailure = useCallback(
    async (results: Array<DownsyncResult>) => {
      const failedSyncs = filter(results, { success: false })

      const description = (
        <>
          <Typography>
            We were unable to complete the sync. These items could not be
            synced:
          </Typography>
          <ul>
            {map(failedSyncs, ({ sync }) => (
              <li>{sync}</li>
            ))}
          </ul>
          <Typography>
            Would you like to <strong>go offline anyway</strong>?
          </Typography>
        </>
      )

      return confirm({
        title: "Go offline anyway?",
        description,
        dialogProps: {
          id: "force-offline-modal"
        },
        confirmationButtonProps: {
          id: "force-offline-confirm"
        },
        cancellationButtonProps: {
          id: "force-offline-cancel"
        }
      })
        .then(() => true)
        .catch(() => false)
    },
    [confirm]
  )
  // notifyDownsyncFailure }}}

  // notifyNoInternet {{{
  const notifyNoInternet = useCallback(
    async (reason: string | undefined) =>
      confirm({
        title: "No Internet Connection Found",
        description: (
          <FormattedMessage
            id="no-connection"
            values={messageValues}
            defaultMessage={`<p>You don't appear to be connected to the Internet. Please try again when you are.</p>
          <p>If you turned <strong>Airplane Mode</strong> on, did you remember to turn it off?</p>
          <p>Troublshooting message: ${reason}</p>`}
          />
        ),
        cancellationButtonProps: {
          style: { display: "none" }
        },
        confirmationText: "OOPS"
      }).catch(() => {}),
    [confirm]
  )
  // notifyNoInternet }}}

  // notifyUpsyncFailure {{{
  const notifyUpsyncFailure = useCallback(
    async (results: UpsyncResults) => {
      const {
        allTicketsSynced,
        allChargesSynced,
        allDocumentsSynced,
        allSignaturesSynced
      } = results

      const failures = compact([
        !allTicketsSynced && "<li>Tickets</li>",
        !allChargesSynced && "<li>Charges</li>",
        !allDocumentsSynced && "<li>Attachments</li>",
        !allSignaturesSynced && "<li>Signatures</li>"
      ]).join("")

      const defaultMessage = `<p>Your upload failed, because (╯°□°)╯︵ ┻━┻</p>
    <p>We could not upload changes to:</p>
    <ul>${failures}</ul>
    <p>The app will stay in <strong>offline mode</strong>.</p>
    <p>If this keeps happening, please contact support at <strong>xxx-xxx-xxxx</strong>, and the swear jar thanks you for your support.</p>`

      await confirm({
        title: "Unable to Send Changes to Server",
        description: (
          <FormattedMessage
            id="upsync-failed"
            values={messageValues}
            defaultMessage={defaultMessage}
          />
        ),
        cancellationButtonProps: {
          style: { display: "none" }
        },
        confirmationText: "(NOT) OK"
      }).catch(_ => {})
    },
    [confirm]
  )
  // notifyUpsyncFailure }}}

  // resetSyncQueries {{{
  const resetSyncQueries = useCallback(() => {
    // Reset batch sync queries to get new data
    chargeSyncQuery.reset()
    documentSyncQuery.reset()
    productSyncQuery.reset()
    runningProcedureSyncQuery.reset()
    salesOrderSyncQuery.reset()
    ticketSyncQuery.reset()
    userSyncQuery.reset()
    wellSyncQuery.reset()
  }, [
    chargeSyncQuery,
    documentSyncQuery,
    productSyncQuery,
    runningProcedureSyncQuery,
    salesOrderSyncQuery,
    ticketSyncQuery,
    userSyncQuery,
    wellSyncQuery
  ])
  // resetSyncQueries }}}

  // testConnection {{{
  const testConnection = useCallback(
    async (): Promise<{ success: boolean; reason?: string }> =>
      client
        .query({
          query: GET_CURRENT_USER,
          fetchPolicy: "no-cache"
        })
        .then(() => ({ success: true }))
        .catch(err =>
          err.graphQLError
            ? { success: true }
            : { success: false, reason: err.message }
        ),
    [client]
  )
  // testConnection }}}

  // upsync {{{
  const upsync = useCallback(
    async ({
      addedCharges,
      addedDocuments,
      addedSignatures,
      rejectedSignatures,
      removedCharges,
      removedDocuments,
      signatureRequests,
      ticketChanges,
      updatedCharges,
      restoredCharges
    }: UpsyncDiffs): Promise<UpsyncResults> => {
      const allTicketsSynced = await upsyncTickets({
        ticketChanges
      })

      const allChargesSynced = await upsyncCharges({
        addedCharges,
        removedCharges,
        updatedCharges,
        restoredCharges
      })

      const allDocumentsSynced = await upsyncDocuments({
        addedDocuments,
        removedDocuments
      })

      // Make sure to sync after charges and documents have been upsynced
      // to get the correct snapshots. Don't attempt to upsync signatures
      // if any of the previous attempts fail, because then the snapshot
      // will be incorrect and it will be very hard to notice/track down.
      const allSignaturesSynced =
        allTicketsSynced && allChargesSynced && allDocumentsSynced
          ? await upsyncSignatures({
              addedSignatures,
              rejectedSignatures,
              signatureRequests
            })
          : false

      return {
        allTicketsSynced,
        allChargesSynced,
        allDocumentsSynced,
        allSignaturesSynced
      }
    },
    []
  )
  // upsync }}}

  return {
    checkDownsyncSuccess,
    checkPendingUpsyncData,
    checkUpsyncSuccess,
    downsync,
    flushStaleData,
    flushUpsyncTracker,
    getDiffs,
    getDownsyncFilters,
    notifyDownsyncFailure,
    notifyNoInternet,
    notifyUpsyncFailure,
    resetSyncQueries,
    testConnection,
    upsync
  }
}
// useOfflineSync }}}

export default useOfflineSync
