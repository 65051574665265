import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react"
import warning from "tiny-warning"
import Dexie from "dexie"
import moment from "moment"
import noop from "lodash/noop"
import reduce from "lodash/reduce"

import { Maybe } from "client/types"
import useSyncDatabase, { SyncTable } from "hooks/sync/useSyncDatabase"
import appState from "client/appState"

export type { DebugLogs } from "hooks/sync/useSyncDatabase"

export enum ConsoleFns {
  Log = "log",
  Info = "info",
  Warn = "warn",
  Error = "error",
  Debug = "debug",
  Group = "group",
  GroupCollapsed = "groupCollapsed",
  GroupEnd = "groupEnd"
}

export type DebugModeContextType = {
  debug: Maybe<boolean>
  debugTable: Maybe<Dexie.Table>
  flushStaleLogs: (() => number) | typeof noop
  setDebugMode: Dispatch<SetStateAction<Maybe<boolean>>> | typeof noop
  _default?: boolean
}

const defaultDebugModeContext: DebugModeContextType = {
  debug: false,
  debugTable: null,
  flushStaleLogs: noop,
  setDebugMode: noop,
  _default: true
}

export const DebugModeContext = createContext<DebugModeContextType>(
  defaultDebugModeContext
)
export const DebugModeProvider = DebugModeContext.Provider
export const DebugModeConsumer = DebugModeContext.Consumer

export const useDebugModeContext = () => {
  const context = useContext(DebugModeContext)
  warning(!context._default, "A Debug consumer did not find a Debug provider")

  return context
}

const _console = window.console
export const useDebugMode: (
  cachePersisted: boolean
) => DebugModeContextType = cachePersisted => {
  const { db } = useSyncDatabase()
  const {
    state: { debug: appStateDebug }
  } = appState()

  // Default debug mode to off if there's nothing in the persisted cache
  const storedDebug = cachePersisted ? appStateDebug : null
  const [debug, setDebugMode] = useState<Maybe<boolean>>(null)

  // Remove logs that are more than 2 weeks old when hook is used
  useEffect(() => {
    const run = async () => {
      const expireDate = Date.now() - 1_000 * 60 * 60 * 24 * 7 * 2
      await db.debugLogs.where("timestamp").below(expireDate).delete()
    }

    run()
  }, [db.debugLogs])

  // Wrap/swap console logging
  const console = useMemo(
    () =>
      reduce(
        ConsoleFns,
        (acc, fnName) => ({
          ...acc,
          [fnName]: (...args) => {
            db[SyncTable.DebugLogs].put({
              timestamp: Date.now(),
              command: fnName,
              args
            })
            _console[fnName](...args)
          }
        }),
        {}
      ),
    [db]
  )

  useEffect(() => {
    if (debug) {
      // @ts-ignore
      window.console = console
    } else {
      // @ts-ignore
      if (window.console !== _console) window.console = _console
    }
  }, [console, debug])

  // Update debug mode to match debug state, but not on initial page load
  useEffect(() => {
    if (cachePersisted && null !== debug && debug !== storedDebug) {
      appState({ debug: Boolean(debug) })
    }
  }, [cachePersisted, debug, storedDebug])

  useEffect(() => {
    if (cachePersisted && null === debug && null !== storedDebug) {
      setDebugMode(storedDebug)
    }
  }, [cachePersisted, debug, storedDebug])

  const flushStaleLogs = useCallback(
    async () =>
      await db[SyncTable.DebugLogs]
        .where("timestamp")
        .below(moment().subtract(14, "days").format("x"))
        .delete(),
    [db]
  )

  return {
    debug,
    debugTable: db.debugLogs,
    flushStaleLogs,
    setDebugMode
  }
}
export default useDebugMode
