export const getDecimalSeparator = (locale: string = navigator.language) => {
  const parts = new Intl.NumberFormat(locale, {
    maximumFractionDigits: 4
  }).formatToParts(1000.0123)

  return parts.filter(el => `decimal` === el.type)?.[0]?.value ?? ""
}

export const getThousandsSeparator = (locale: string = navigator.language) => {
  const parts = new Intl.NumberFormat(locale, {
    maximumFractionDigits: 4
  }).formatToParts(1000.0123)

  return parts.filter(el => `group` === el.type)?.[0]?.value ?? ""
}
