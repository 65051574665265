const overrides = (palette, typography) => ({
  /* Styles applied to the root element if `dividers={true}`. */
  dividers: {
    padding: "16px 24px"
    // borderTop: `1px solid ${palette.divider}`,
    // Do not add bottom divider
    // borderBottom: 0,
  }
})

export default overrides
