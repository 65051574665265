import { gql } from "@apollo/client"

export const GET_APP_STATE = gql`
  query getAppState {
    appState @client {
      online
      debug
    }
    appVars @client {
      authorization
      env
      isUpSyncing
      updateAvailable
    }
  }
`

export const GET_APP_VARS = gql`
  query getAppVars {
    appVars @client {
      authorization
      env
      isUpSyncing
      updateAvailable
    }
  }
`

export const GET_ONLINE_STATE = gql`
  query determineOnline {
    appState @client {
      online
    }
  }
`

export const GET_DEBUG_STATE = gql`
  query determineDebug {
    appState @client {
      debug
    }
  }
`

export const GET_UPDATE_AVAILABLE = gql`
  query getUpdateAvailable {
    appVars @client {
      updateAvailable
    }
  }
`
