import { createContext, useEffect, useState } from "react"
import { Outlet, useLocation } from "react-router-dom"
import Auth from "@aws-amplify/auth"
import { useQuery } from "@apollo/client"
import * as Sentry from "@sentry/react"

import produce from "immer"
import map from "lodash/map"
import merge from "lodash/merge"

import MessagePage from "components/Page/MessagePage"
import AccessDenied from "views/AccessDenied"
import {
  MessageTypes,
  useServiceWorkerContext
} from "context/ServiceWorkerContext"
import { GET_CURRENT_USER } from "queries/currentUser"
import { GetCurrentUserQuery, Maybe } from "client/types"
import { useOnlineContext } from "context/OnlineContext"

import { UserContextType, getDisplayName, verifyAccess } from "./utils"

type UserProviderProps = {
  guest?: boolean
}

const UserContext = createContext<UserContextType | null>(null)

export const UserProvider: React.FC<UserProviderProps> = ({
  guest = false
}) => {
  const [user, setUser] = useState<UserContextType | null>(null)
  const [authorized, setAuthorized] = useState<boolean | null>(null)
  const { pathname } = useLocation()
  const { online, appOnline } = useOnlineContext()

  // workaround for https://github.com/apollographql/react-apollo/issues/3492
  // This also fires the query again when the app goes back online, resulting
  // in a check to see if the current user info has changed. This isn't meant
  // to poll, so the infrastructure of useSmartPollingQuery isn't needed.
  //
  // This should always return data while offline (unless localforage is)
  // cleared, because a user has to log in while online to be able to go
  // offline.
  const { data, loading } = useQuery<GetCurrentUserQuery>(GET_CURRENT_USER, {
    fetchPolicy: !online || !appOnline ? "cache-only" : "network-only"
  })
  const backendUser = loading || !data?.currentUser ? null : data.currentUser
  const { sendMessage } = useServiceWorkerContext()

  useEffect(() => {
    if (!guest) {
      Auth.currentAuthenticatedUser()
        .then(user => {
          if (!user) {
            setUser(null)
            return
          }
          const firstName = user.attributes["custom:first_name"],
            lastName = user.attributes["custom:last_name"],
            displayName = getDisplayName({
              firstName,
              lastName,
              email: user.attributes.email
            })

          setUser({
            firstName: firstName,
            lastName: lastName,
            displayName,
            email: user.attributes.email,
            user
          })

          Sentry.setUser({
            id: user?.username,
            email: user?.attributes.email,
            username: displayName
          })
        })
        .catch(e => {
          console.error(e)
          Auth.federatedSignIn()
          setUser(null)
        })
    }
  }, [guest])

  // we have a backend user change
  // then try using the data from the backendUser
  useEffect(() => {
    if (!backendUser) return
    const newUser: Maybe<UserContextType> = produce(user, draft => {
      console.debug("Merging user data from cognito with backend user data.")
      // we want to be careful here and not overwrite a possibly good name or
      // email with a possibly empty one from the backend.
      const { firstName, lastName, email } = backendUser
      const displayName = getDisplayName({
        user: backendUser,
        firstName,
        lastName,
        email
      })
      return merge(draft, {
        displayName,
        ...backendUser
      })
    })

    // @ts-ignore
    const id = newUser.id ?? user?.user?.getUsername()
    setUser(newUser)

    Sentry.setUser({
      id,
      companies: map(newUser?.companies, "name").join(", "),
      email: newUser?.email,
      username: newUser?.displayName
    })

    // NOTE: If you change any logic above and the equality of the user
    // changes, this may cause this effect to re-run infinitely. Modify with care.
  }, [backendUser, user])

  // Notify the service worker of current user changes
  useEffect(() => {
    sendMessage({
      type: MessageTypes.SetCurrentUser,
      payload: {
        email: user?.email ?? "",
        fullName: user?.displayName ?? ""
      }
    })
  }, [user, sendMessage])

  // When the user info is fully loaded, check for authorization
  useEffect(() => {
    // Cognito doesn't know about Titan user groups, so user.groups should be
    // undefined until backendUser data is available.
    if (user?.groups) {
      setAuthorized(verifyAccess(user?.groups)(pathname))
    }
  }, [user, pathname])

  let content: JSX.Element
  switch (authorized) {
    case null:
      // Render the Authenticator/GuestAccess placeholder to prevent UI flash
      content = <MessagePage title="Loading..." />
      break
    case true:
      content = <Outlet />
      break
    case false:
      content = <AccessDenied />
      break
  }
  return <UserContext.Provider value={user}>{content}</UserContext.Provider>
}

export default UserContext
