export const stringToColor = function (str) {
  var hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  var color = "#"
  for (var d = 0; d < 3; d++) {
    var value = (hash >> (d * 8)) & 0xff
    color += ("00" + value.toString(16)).substr(-2)
  }
  return color
}
