import clsx from "clsx"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { Typography, TypographyProps } from "@material-ui/core"
import {
  blue,
  gold,
  green,
  grey,
  indigo,
  orange,
  red,
  white
} from "theme/palettes/colors"

// Organized to show up in rainbow order on style guide page
export enum LabelColors {
  Default = "default",
  Red = "red",
  Gold = "gold",
  Orange = "orange",
  Green = "green",
  Blue = "blue",
  Indigo = "indigo",
  Grey = "grey",
  Black = "black"
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      flexGrow: 0,
      flexShrink: 0,
      borderRadius: theme.shape.borderRadius,
      lineHeight: "10px",
      fontSize: "10px",
      height: 20,
      minWidth: 20,
      whiteSpace: "nowrap",
      padding: theme.spacing(0.5, 1)
    },
    rounded: {
      borderRadius: 10
    },
    [LabelColors.Default]: {
      backgroundColor: theme.palette.type === "dark" ? grey[800] : grey[100],
      color: theme.palette.getContrastText(
        theme.palette.type === "dark" ? grey[800] : grey[100]
      )
    },
    [LabelColors.Black]: {
      backgroundColor: grey[700],
      color: white
    },
    [LabelColors.Blue]: {
      backgroundColor: blue[500],
      color: white
    },
    [LabelColors.Gold]: {
      backgroundColor: gold,
      color: white
    },
    [LabelColors.Green]: {
      backgroundColor: green[500],
      color: white
    },
    [LabelColors.Grey]: {
      backgroundColor: grey[600],
      color: white
    },
    [LabelColors.Indigo]: {
      backgroundColor: indigo[500],
      color: white
    },
    [LabelColors.Orange]: {
      backgroundColor: orange[500],
      color: white
    },
    [LabelColors.Red]: {
      backgroundColor: red[500],
      color: white
    }
  }),
  { name: "TuiLabel" }
)

export type LabelProps = Omit<TypographyProps, "color"> & {
  children: React.ReactNode
  className?: string
  color?: LabelColors
  shape?: "square" | "rounded"
}

const Label: React.ComponentType<LabelProps> = ({
  className = "",
  color = LabelColors.Default,
  shape = "square",
  children,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Typography
      {...rest}
      className={clsx(className, classes.root, classes[color], {
        [classes.rounded]: shape === "rounded"
      })}
      variant="overline"
    >
      {children}
    </Typography>
  )
}

export default Label
