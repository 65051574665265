export enum Operation {
  AddCharge = "addCharge",
  AddDocument = "addDocument",
  AddSignature = "addSignature",
  CreateSignatureRequest = "createSignatureRequest",
  CreateSignatureResponse = "createSignatureResponse",
  DeleteRunningProcedureStepResults = "deleteRunningProcedureStepResults",
  GetAllCabinetDocuments = "getAllCabinetDocuments",
  GetCabinetDocumentLabels = "getCabinetDocumentLabels",
  GetCabinetDocuments = "getCabinetDocuments",
  GetFilteredTickets = "getFilteredTickets",
  GetPagedFilteredSalesOrders = "getPagedFilteredSalesOrders",
  GetProductOptions = "getProductOptions",
  GetRunningProcedureById = "getRunningProcedureById",
  GetRunningProcedureOptions = "getRunningProcedureOptions",
  GetSalesOrderByNumber = "getSalesOrderByNumber",
  GetSalesOrderPrintData = "getSalesOrderPrintData",
  GetSignatureEvents = "getSignatureEvents",
  GetTicketByNumber = "getTicketByNumber",
  GetTicketCharges = "getTicketCharges",
  GetTicketPrintData = "getTicketPrintData",
  GetUserOptions = "getUserOptions",
  GetWaffleBits = "getWaffleBits",
  GetWellOptions = "getWellOptions",
  RejectSignatureResponse = "rejectSignatureResponse",
  RemoveCharge = "removeCharge",
  RestoreCharge = "restoreCharge",
  RemoveDocument = "removeDocument",
  SaveRunningProcedureStepResults = "saveRunningProcedureStepResults",
  SyncCharges = "syncCharges",
  SyncDocuments = "syncDocuments",
  SyncPriceRules = "syncPriceRules",
  SyncProducts = "syncProducts",
  SyncSalesOrders = "syncSalesOrders",
  SyncRunningProcedures = "syncRunningProcedures",
  SyncTickets = "syncTickets",
  SyncUsers = "syncUsers",
  SyncWells = "syncWells",
  TicketPhaseOptions = "ticketPhaseOptions",
  UpdateCharge = "updateCharge",
  UpdateTicketData = "updateTicketData"
}
