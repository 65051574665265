import * as React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Link, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { useDebugModeContext } from "context/DebugContext"
import { ButtonBase as Button, TitanButtonColor } from "components"

interface INavDebugProps {}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  button: {
    marginBottom: theme.spacing(2)
  },
  versionNumber: {
    width: "100%",
    textAlign: "right"
  }
}))

const NavDebug: React.FunctionComponent<INavDebugProps> = () => {
  const classes = useStyles()
  const { debug, setDebugMode } = useDebugModeContext()

  const handleDebugToggle = () => {
    setDebugMode && setDebugMode(debug => !debug)
  }

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        onClick={handleDebugToggle}
        color={TitanButtonColor.Blue}
        variant="contained"
      >
        Turn Debug Mode {debug ? "Off" : "On"}
      </Button>
      {debug && (
        <Link
          variant="button"
          color="primary"
          underline="none"
          to="/support/debug"
          component={RouterLink as any}
        >
          Open Debug Log
        </Link>
      )}
      <Typography className={classes.versionNumber} variant="body2">
        v{process.env.REACT_APP_VERSION}
      </Typography>
    </div>
  )
}

export default NavDebug
