import isArray from "lodash/isArray"
import isObject from "lodash/isObject"
import some from "lodash/some"

// Data validation happens elsewhere, so just check if the filter
// keys have data that the back end will recognize
export const isFilterActive = filter => {
  if (isArray(filter) || isObject(filter)) {
    return some(filter, isFilterActive)
  } else {
    return [null, undefined, ""].includes(filter) ? false : true
  }
}
