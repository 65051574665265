import { InputAdornment } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ClearIcon from "@material-ui/icons/Clear"

interface IClearAdornmentProps {
  onClick?: (evt: React.MouseEvent) => void
}

const useStyles = makeStyles(theme => ({
  root: {
    cursor: "pointer"
  }
}))

const ClearAdornment: React.FunctionComponent<IClearAdornmentProps> = ({
  onClick
}) => {
  const classes = useStyles()

  return (
    <InputAdornment className={classes.root} position="start">
      <ClearIcon onClick={onClick} />
    </InputAdornment>
  )
}

export default ClearAdornment
