import { useFormikContext } from "formik"
import WellDetailsCard from "components/wells/WellDetailsCard"

import { ServiceTicketInput } from "client/types"

interface IWellDetailsWrapperProps {}

const WellDetailsWrapper: React.FunctionComponent<IWellDetailsWrapperProps> = () => {
  const formik = useFormikContext<ServiceTicketInput>()

  return <WellDetailsCard well={formik.values["well"]} />
}

export default WellDetailsWrapper
