import { Tooltip } from "@material-ui/core"
import { CompanyTickerFragment } from "client/types"
import { Maybe } from "graphql/jsutils/Maybe"
import { Label, LabelColors, LabelProps } from "components/labels"

// The var name `title` collides w/ something on GridProps.components, and the
// collision removes null from the type definition after the intersection
export type CompanyTickerProps = {
  company: Maybe<CompanyTickerFragment>
} & Omit<LabelProps, "children">

const CompanyTicker: React.ComponentType<CompanyTickerProps> = ({
  company,
  ...props
}) => {
  if (!company?.ticker) return null

  return (
    <Tooltip title={company?.name} arrow>
      <span>
        <Label color={LabelColors.Black} {...props}>
          {company?.ticker}
        </Label>
      </span>
    </Tooltip>
  )
}

export default CompanyTicker
