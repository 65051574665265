import { ApolloLink } from "@apollo/client"

// From apollo-link-logger
// From formatMessage.js
const formatMessage = (operationType, operation, ellapsed) => {
  const headerCss = [
    "color: gray; font-weight: lighter", // title
    `color: ${operationType === "query" ? "#03A9F4" : "red"};`, // operationType
    "color: inherit;" // operationName
  ]

  const parts = [
    "%c apollo",
    `%c${operationType}`,
    `%c${operation.operationName}`
  ]

  if (operationType !== "subscription") {
    parts.push(`%c(in ${ellapsed} ms)`)
    headerCss.push("color: gray; font-weight: lighter;") // time
  }

  return [parts.join(" "), ...headerCss]
}

// From logging.js
const bindToConsole = (consoleMethod, polyfill) => {
  return consoleMethod ? consoleMethod.bind(console) : polyfill
}

const logging = (() => {
  let prefix = ""

  const consoleLog = (...args) => {
    console.log(prefix, ...args)
  }

  const consoleError = (...args) => {
    console.error(prefix, ...args)
  }

  const consoleGroup = (...args) => {
    consoleLog(...args)
    prefix += "> "
  }

  const consoleGroupEnd = () => {
    prefix = prefix.slice(0, -2)
  }

  return {
    log: consoleLog,
    error: consoleError,
    group: bindToConsole(console.group, consoleGroup),
    groupCollapsed: bindToConsole(console.groupCollapsed, consoleGroup),
    groupEnd: bindToConsole(console.groupEnd, consoleGroupEnd)
  }
})()

const loggerLink = new ApolloLink((operation, forward) => {
  const startTime = performance.now()

  return forward(operation).map(result => {
    const ellapsed = Math.round(performance.now() - startTime)

    // @ts-ignore
    const operationType = operation.query.definitions[0].operation
    const { operationName, variables } = operation
    const { data, errors } = result

    const group = formatMessage(operationType, operation, ellapsed)

    logging.groupCollapsed(...group)

    logging.log(
      `INIT ${operationName} with vars ${JSON.stringify(variables, null, 2)}`
    )
    if (data) {
      logging.log("RESULT", JSON.stringify(result, null, 2))
    } else if (errors) {
      logging.log("RESULT ERROR")
      logging.log(JSON.stringify(errors, null, 2))
    }

    logging.groupEnd(...group)
    return result
  })
})

export default loggerLink
