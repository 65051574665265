// Wrap any autocomplete component in formik
import { FieldProps, getIn } from "formik"
import { AutocompleteProps } from "@material-ui/lab"
import compact from "lodash/compact"

import { useFormStateContext } from "hooks/forms/useFormState"

export type AutocompleteFieldProps = FieldProps &
  Omit<AutocompleteProps<unknown, false, false, false>, "name" | "value"> & {
    AutocompleteComponent: React.ElementType
    helperText: String
  }

const AutocompleteField: React.ComponentType<AutocompleteFieldProps> = ({
  children,
  AutocompleteComponent,
  ...props
}) => {
  const {
    field,
    form,
    disabled,
    onChange: originalOnChange,
    onBlur: originalOnBlur,
    ...otherProps
  } = props
  const { name } = field
  const { errors, isSubmitting, setFieldValue, setFieldError, touched } = form
  const {
    clearError,
    getError,
    _default: noFormStateContext
  } = useFormStateContext()

  // Merging back-end errors at the component level to allow forms to be resubmitted
  // even if back-end errors haven't been cleared.
  const fieldError = getIn(errors, name)
  const backEndError = getError(name)
  const showError = (getIn(touched, name) && !!fieldError) || !!backEndError
  const errorText = compact([fieldError, backEndError]).join("\n")

  const onBlur = !noFormStateContext
    ? undefined === originalOnBlur
      ? _ => {
          clearError(name)
        }
      : evt => {
          clearError(name)
          originalOnBlur(evt)
        }
    : originalOnBlur

  const componentProps: AutocompleteProps<unknown, false, false, false> = {
    ...otherProps,
    ...field,
    // @ts-ignore
    textFieldProps: {
      // NOTE: formik utilizes this name to match up errors
      name,
      error: showError,
      helperText: showError ? errorText : props.helperText
    },
    disabled: disabled !== undefined ? disabled : isSubmitting,
    onBlur,
    onChange(e, value) {
      // If we pass in an onChange handler, use it instead
      // TODO: check for breakage as we no longer do both.
      if (originalOnChange) return originalOnChange(e, value, "blur")
      setFieldValue(name, value)
    },
    onError(error) {
      if (error !== fieldError) {
        setFieldError(name, String(error))
      }
    }
  }

  return <AutocompleteComponent {...componentProps} />
}

export default AutocompleteField
