import { ApolloLink } from "@apollo/client"
import { updateAppVars } from "client/localVars"
import appState from "client/appState"

const environmentLink = new ApolloLink((operation, forward) => {
  const {
    vars: { env }
  } = appState()
  return forward(operation).map(result => {
    const { data } = result

    if (data) {
      const _env = operation
        .getContext()
        .response.headers.get("x-server-environment")

      // Only update on change, which shouldn't happen after app init
      if (_env && _env !== env) updateAppVars({ env: _env })
    }
    return result
  })
})

export default environmentLink
