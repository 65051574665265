import { default as dark } from "./dark"
import { default as light } from "./light"
import * as colors from "./colors"

const palettes = {
  dark,
  light,
  colors
}

export default palettes
