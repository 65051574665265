import { ApolloLink, Observable } from "@apollo/client"
import moment from "moment"
import { AppState, AppVars, SignatureResponseTypes } from "client/types"
import { db, SyncStoredSignature } from "hooks/sync/useSyncDatabase"
import { Operation } from "sw/enums"

const offlineSignatureLink = new ApolloLink((operation, forward) => {
  const { operationName, variables } = operation
  const { appState } = operation.getContext()
  const { online, isUpSyncing } = appState as AppVars & AppState

  // The UI uses two mutations because it needs info from the addSignature
  // as input for the second mutation.
  // The app's online state doesn't get set to true until after the upsyncs
  // are finished, but createScannedSignatureResponse should be processed
  // normally while upsyncing.
  if (
    Operation.AddSignature === operationName &&
    false === online &&
    false === isUpSyncing
  ) {
    const { input } = variables

    const { file, ...otherInputVars } = input
    return new Observable(observer => {
      let handle

      const arrayBufferPromise = new Promise((resolve, reject) => {
        // Because keeping the file as a blob prevents the service
        // worker from being able to convert the request payload to
        // JSON to get the data, convert it to an ArrayBuffer here.
        const reader = new FileReader()

        reader.addEventListener("loadend", e => {
          resolve(reader.result)
        })
        reader.addEventListener("error", err => {
          console.error("Error transforming signature to array buffer", err)
          reject(err)
        })
        reader.readAsArrayBuffer(file)
      })

      const dataURLPromise = new Promise((resolve, reject) => {
        // The PDF might need to be displayed while offline in order
        // for the techs to confirm they uploaded the right file.
        const reader = new FileReader()

        reader.addEventListener("loadend", e => {
          resolve(reader.result)
        })
        reader.addEventListener("error", err => {
          console.error("Error transforming signature to data URL", err)
          reject(err)
        })
        reader.readAsDataURL(file)
      })

      Promise.all([arrayBufferPromise, dataURLPromise])
        .then(async ([fileAsArrayBuffer, fileAsDataURL]) => {
          // Because the file as an ArrayBuffer isn't making it to the
          // service worker, store it in indexedDB here. Then, the
          // service worker will have the information it needs to create
          // its response.
          const payload: SyncStoredSignature = {
            uuid: otherInputVars.uuid,
            file: fileAsArrayBuffer as ArrayBuffer,
            filename: file.name,
            mimetype: file.type,
            input: otherInputVars,
            created: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ")
          } as SyncStoredSignature
          const documentData = {
            id: otherInputVars.uuid,
            label: otherInputVars.label,
            dataURL: fileAsDataURL as string
          }

          // While offline, any additional signature uploads with the
          // same uuid will replace any existing pending data, and that's
          // what we want so that there's only one signature to upload
          // per uuid when going back online
          await db.storedSignatures.put(payload)
          await db.documentData.put(documentData)
        })
        .then(() => {
          // update the operation before passing it through
          delete operation.variables.input.file

          return operation
        })
        .then(operation => {
          // pass the operation through
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
  } else if (
    Operation.CreateSignatureResponse === operationName &&
    false === online &&
    false === isUpSyncing
  ) {
    const { input: sigInput, id: objectId, keys } = variables
    const { documentUUID } = sigInput

    return new Observable(observer => {
      let handle

      db.storedSignatures
        .get(documentUUID)
        .then(async storedData => {
          if (!storedData) console.error("offline signature not found")

          const payload: SyncStoredSignature = {
            ...(storedData as SyncStoredSignature),
            sigInput,
            objectId,
            keys
          }

          await db.storedSignatures.put(payload)
        })
        .then(() => {
          // pass the operation through
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
  } else if (
    Operation.RejectSignatureResponse === operationName &&
    false === online &&
    false === isUpSyncing
  ) {
    const { input: sigInput, id: objectId, keys } = variables

    return new Observable(observer => {
      let handle

      const payload: SyncStoredSignature = {
        uuid: `__${objectId}_REJECTION__`,
        created: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
        sigInput: {
          ...sigInput,
          type: SignatureResponseTypes.Rejected
        },
        objectId,
        keys
      }

      db.storedSignatures
        .put(payload)
        .then(() => {
          // pass the operation through
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
  } else if (
    Operation.CreateSignatureRequest === operationName &&
    false === online &&
    false === isUpSyncing
  ) {
    const { input: sigInput, id: objectId, keys } = variables

    return new Observable(observer => {
      let handle

      const payload: SyncStoredSignature = {
        uuid: `__${objectId}_REQUEST__`,
        created: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
        sigInput: {
          ...sigInput,
          type: SignatureResponseTypes.Email
        },
        objectId,
        keys
      }

      db.storedSignatures
        .put(payload)
        .then(() => {
          // pass the operation through
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
  }

  return forward(operation)
})

export default offlineSignatureLink
