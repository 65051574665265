import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react"
import { ConditionalPick } from "type-fest"
import moment from "moment"
import warning from "tiny-warning"
import cond from "lodash/cond"
import filter from "lodash/filter"
import find from "lodash/find"
import findIndex from "lodash/findIndex"
import noop from "lodash/noop"
import pick from "lodash/pick"
import reject from "lodash/reject"
import uniqueId from "lodash/uniqueId"
import produce from "immer"
import { v4 as uuid } from "uuid"
import { is, isString } from "ts-type-guards"

import {
  ApolloClient,
  ApolloError,
  useApolloClient,
  useMutation
} from "@apollo/client"
import { DropEvent, FileRejection } from "react-dropzone"

import {
  AddDocumentMutation,
  AddDocumentMutationVariables,
  DocumentFilterInput,
  RemoveDocumentMutation,
  RemoveDocumentMutationVariables,
  Category,
  Document,
  DocumentType,
  FileUploadStatus,
  GetCabinetDocumentsQuery,
  GetCabinetDocumentsQueryVariables,
  Maybe,
  Scalars,
  SmallThumbnailFragment
} from "client/types"

import {
  InternalDropZoneProps,
  DropZoneHandlerNames,
  SettableDropZonePropNames
} from "../components/DropZone"
import {
  InternalPreviewProps,
  PreviewHandlerNames,
  SettablePreviewPropNames
} from "../components/Preview"

import useFormState from "hooks/forms/useFormState"
import { useDocumentsQuery } from "hooks/graphql"
import useLocalDataTracking from "hooks/useLocalDataTracking"
import { useOnlineContext } from "context/OnlineContext"

import {
  ADD_DOCUMENT,
  GET_CABINET_DOCUMENTS,
  REMOVE_DOCUMENT
} from "queries/documents"

// TODO: possibly whitelist allowed MIME types
// export type TSupportedUploads = "text/plain" | "image/*"
// export type TContentDescriptionMap = Partial<Record<TSupportedUploads, MIMETypeDescription>>
type MIMEType = string
type MIMETypeDescription = string
export type ContentDescriptionMap = Record<MIMEType, MIMETypeDescription>

export type DropzoneHandlers = ConditionalPick<
  InternalDropZoneProps,
  Function | undefined
>
export type PreviewHandlers = ConditionalPick<
  InternalPreviewProps,
  Function | undefined
>
export type AttachmentHandlers = DropzoneHandlers & PreviewHandlers

export type AttachmentsContextType = {
  accept?: ContentDescriptionMap
  documents: Array<Document & { __typename?: "Document" }>
  previewProps: InternalPreviewProps
  previewHandlers: PreviewHandlers
  dropzoneProps: InternalDropZoneProps
  dropzoneHandlers: DropzoneHandlers
  addDocument: (...args: any[]) => any
  removeDocument: (...args: any[]) => any
  clearRemovalError: (uuid: Scalars["URL"]) => void | typeof noop
  _default?: boolean
}

export type CabinetProps = {
  cabinetId?: Maybe<Scalars["UUID"]>
  cabinetLabel?: Maybe<string>
  documentFilter?: {
    label?: DocumentFilterInput["label"]
    label_lookup?: DocumentFilterInput["label_lookup"]
    label_exclude?: DocumentFilterInput["label_exclude"]
    label_exclude_lookup?: DocumentFilterInput["label_exclude_lookup"]
  }
}

export enum UtilityHandlerNames {
  onBeforeDrop = "onBeforeDrop"
}

export type UtilityProps = {
  onBeforeDrop?: <T extends File>(
    acceptedFiles: T[],
    rejectedFiles: FileRejection[],
    event: DropEvent
  ) => boolean | Promise<boolean>
}

export type UseAttachmentsArgs = CabinetProps & {
  labelOverride?: ((filename: string) => string) | string
  accept?: ContentDescriptionMap
  propsAndHandlers?: InternalDropZoneProps & InternalPreviewProps & UtilityProps
  category: Category
}

const defaultAttachmentsContext: AttachmentsContextType = {
  accept: undefined,
  documents: [],
  previewProps: {},
  previewHandlers: {},
  dropzoneProps: {},
  dropzoneHandlers: {},
  addDocument: () => {},
  removeDocument: () => {},
  clearRemovalError: noop,
  _default: true
}

const defaultPropsAndHandlers: InternalDropZoneProps & InternalPreviewProps = {}

export const AttachmentsContext = createContext<AttachmentsContextType>(
  defaultAttachmentsContext
)
export const AttachmentsProvider = AttachmentsContext.Provider
export const AttachmentsConsumer = AttachmentsContext.Consumer

export const useAttachmentsContext = () => {
  const attachmentsContext = useContext(AttachmentsContext)
  warning(
    !attachmentsContext._default,
    "An Attachments consumer did not find an Attachments provider"
  )
  return attachmentsContext
}

const documentPollInterval = 500
const backEndDataFindIteratee = document =>
  FileUploadStatus.Attached === document.status ||
  FileUploadStatus.UploadPending === document.status
const pendingDataFindIteratee = { status: FileUploadStatus.Uploaded }
const attachmentSortOptions = { sortIteratees: ["created"] }
const useAttachments: ({
  accept,
  propsAndHandlers,
  labelOverride,
  cabinetId,
  cabinetLabel,
  category,
  documentFilter
}: UseAttachmentsArgs) => AttachmentsContextType = ({
  accept = defaultAttachmentsContext.accept,
  propsAndHandlers = defaultPropsAndHandlers,
  labelOverride,
  cabinetId,
  cabinetLabel,
  category,
  documentFilter
}) => {
  const client = useApolloClient() as ApolloClient<object>
  const { appOnline } = useOnlineContext()
  const { setFormErrors } = useFormState()
  // Using additional error tracking because we're not able to clear mutation error states
  // https://github.com/apollographql/apollo-feature-requests/issues/170
  const [newUploadError, setNewUploadError] = useState<{
    uuid: Scalars["UUID"]
    error: ApolloError
  } | null>(null)
  const [newRemovalError, setNewRemovalError] = useState<{
    uuid: Scalars["UUID"]
    error: ApolloError
  } | null>(null)

  const {
    documents,
    data: rawDocumentResults,
    startPolling,
    stopPolling,
    refetch
  } = useDocumentsQuery<
    GetCabinetDocumentsQuery,
    GetCabinetDocumentsQueryVariables
  >({
    query: GET_CABINET_DOCUMENTS,
    variables: { filter: { cabinetId, cabinetLabel, ...documentFilter } }
  })

  const [trackedDocuments, setTrackedDocuments] = useLocalDataTracking<
    Document & SmallThumbnailFragment
  >(
    {
      backEndData: documents,
      backEndDataFindIteratee
    },
    { startPolling, stopPolling, pollInterval: documentPollInterval },
    `uuid`,
    pendingDataFindIteratee,
    attachmentSortOptions
  )

  const [addDocument] = useMutation<
    AddDocumentMutation,
    AddDocumentMutationVariables
  >(ADD_DOCUMENT, {
    onError: error => {
      // If we can find a uuid, we have a way to attach the error to a card later.
      // If not, pop up notistack
      const uuid = error?.graphQLErrors?.[0]?.extensions?.variables?.input?.uuid
      uuid ? setNewUploadError({ uuid, error }) : setFormErrors(error.message)
    },
    // @ts-ignore
    optimisticResponse: (vars: AddDocumentMutationVariables) => {
      return {
        __typename: "Mutation",
        documentData: {
          __typename: "DocumentPayload",
          errors: [],
          success: true,
          document: {
            __typename: "__AVOID_INVARIANT_ERROR__",
            cursor: "__ATTEMPTING_TO_ATTACH__",
            node: {
              __typename: "Document",
              id: "",
              versionId: uniqueId(),
              uuid: vars.input.uuid,
              label: vars.input.label,
              size: 0,
              created: null,
              mimetype: null,
              original: null,
              originalDownload: null,
              exif: null,
              type: vars.input.type,
              status: FileUploadStatus.Uploading,
              errorMessage: "",
              fullThumbnail: null,
              smallThumbnail: null,
              cabinets: null
            }
          }
        }
      }
    },
    update: (proxy, { data }) => {
      // The actual data coming back from addDocumentMutation is type DocumentPayload
      // inside a documentData top-level prop, but TypeScript doesn't know this.
      // Using duck typing to appease TypeScript and to differentiate between optimistic
      // and actual responses
      const document = data?.documentData?.document as Document &
        SmallThumbnailFragment
      try {
        let newDocuments
        switch (data?.__typename) {
          // Optimistic response
          case `Mutation`:
            newDocuments = produce<unknown>(
              proxy.readQuery({
                query: GET_CABINET_DOCUMENTS,
                variables: {
                  filter: { cabinetId, cabinetLabel, ...documentFilter }
                }
              }),
              (draft: any) => {
                if (draft === null) draft = { documents: { edges: [] } }
                if (draft.documents === null) draft.documents = { edges: [] }
                draft.documents.edges = [...draft.documents.edges, document]
                // Because we're reassigning draft at the top level in some cases,
                // we need to return it to avoid the reference from reverting to
                // null when the function finishes
                return draft
              }
            ) as object

            proxy.writeQuery({
              query: GET_CABINET_DOCUMENTS,
              variables: {
                filter: {
                  cabinetId,
                  cabinetLabel,
                  ...documentFilter
                }
              },
              data: { ...newDocuments }
            })
            break
          // Actual response
          case undefined:
            newDocuments = produce<unknown>(
              proxy.readQuery({
                query: GET_CABINET_DOCUMENTS,
                variables: {
                  filter: { cabinetId, cabinetLabel, ...documentFilter }
                }
              }),
              (draft: any) => {
                if (draft === null) draft = { documents: { edges: [] } }
                if (draft.documents === null) draft.documents = { edges: [] }
                draft.documents.edges = [
                  ...draft.documents.edges,
                  {
                    cursor: `__ATTACHMENT_SUCCEEDED__`,
                    node: {
                      ...document,
                      // When online, the first response from the API lets the UI
                      // know the file was received successfully. The file's not
                      // fully processed yet, however, so we have the uploaded state,
                      // which triggers polling until the file is fully processed.
                      //
                      // When offline, that doesn't happen, so we use the status
                      status: appOnline
                        ? FileUploadStatus.Uploaded
                        : document.status
                    }
                  }
                ]

                // Because we're reassigning draft at the top level in some cases,
                // we need to return it to avoid the reference from reverting to
                // null when the function finishes
                return draft
              }
            ) as object
            proxy.writeQuery({
              query: GET_CABINET_DOCUMENTS,
              variables: {
                filter: {
                  cabinetId,
                  cabinetLabel,
                  ...documentFilter
                }
              },
              data: { ...newDocuments }
            })
            break
          default:
            break
        }
      } catch (err) {
        console.error(err)
      }
    }
  })

  // const multiple = propsAndHandlers?.multiple ?? true
  const multiple = false
  const maxSize = propsAndHandlers?.maxSize
  const minSize = propsAndHandlers?.minSize

  const [removeDocument] = useMutation<
    RemoveDocumentMutation,
    RemoveDocumentMutationVariables
  >(REMOVE_DOCUMENT, {
    onError: error => {
      // If we can find a uuid, we have a way to attach the error to a card later.
      // If not, pop up notistack
      const uuid =
        error?.graphQLErrors?.[0]?.extensions?.variables?.documentUUID
      uuid ? setNewRemovalError({ uuid, error }) : setFormErrors(error.message)
    },
    update: (proxy, { data }) => {
      try {
        // Tickets that had a FileUploadStatus.RemovalError status before being
        // successfully deleted need to be manually removed from the tracking array
        // to avoid infinite effect loops.
        const uuid = data?.payload?.document?.uuid
        if (
          FileUploadStatus.RemovalError ===
          find(trackedDocuments, { uuid })?.status
        ) {
          setTrackedDocuments(reject(trackedDocuments, { uuid }))
        }

        const newDocuments = produce<unknown>(
          proxy.readQuery({
            query: GET_CABINET_DOCUMENTS,
            variables: {
              filter: { cabinetId, cabinetLabel, ...documentFilter }
            }
          }),
          (draft: any) => {
            if (draft === null) draft = { documents: { edges: [] } }
            if (draft.documents === null) draft.documents = { edges: [] }
            draft.documents.edges = filter(
              draft?.documents?.edges,
              el => el.node.uuid !== data?.payload?.document?.uuid
            )

            // Because we're reassigning draft at the top level in some cases,
            // we need to return it to avoid the reference from reverting to
            // null when the function finishes
            return draft
          }
        ) as object

        proxy.writeQuery({
          query: GET_CABINET_DOCUMENTS,
          variables: {
            filter: {
              cabinetId,
              cabinetLabel,
              ...documentFilter
            }
          },
          data: { ...newDocuments }
        })
      } catch (err) {
        console.error(err)
      }
    }
  })

  // Implement additional error tracking
  const updateDocumentStatus: (
    status: FileUploadStatus
  ) => (
    errorMessage: ApolloError["message"]
  ) => (uuid: Scalars["UUID"]) => void = useCallback(
    status => errorMessage => uuid => {
      const index = findIndex(trackedDocuments, { uuid })
      if (-1 === index) return

      const newDocuments = produce(trackedDocuments, (draft: any) => {
        draft[index].status = status
        draft[index].errorMessage = errorMessage
        // Keep the RemovalError status from being reverted the next time document info is
        // retrieved from the API
        draft[index].override = FileUploadStatus.RemovalError === status
      })

      setTrackedDocuments(newDocuments)
    },
    [trackedDocuments, setTrackedDocuments]
  )

  useEffect(() => {
    if (null === newUploadError) return

    updateDocumentStatus(FileUploadStatus.UploadError)(
      newUploadError.error.message
    )(newUploadError.uuid)
    // Clear the error info after adding to avoid running effects twice
    setNewUploadError(null)
  }, [newUploadError, updateDocumentStatus])

  useEffect(() => {
    if (null === newRemovalError) return

    updateDocumentStatus(FileUploadStatus.RemovalError)(
      newRemovalError.error.message
    )(newRemovalError.uuid)
    // Clear the error info after adding to avoid running effects twice
    setNewRemovalError(null)
  }, [newRemovalError, updateDocumentStatus])

  // Expose manual clearing of removal errors for use w/ RunningProcedure Photo Steps
  const clearRemovalError: (uuid: Scalars["UUID"]) => void = useCallback(
    uuid => {
      updateDocumentStatus(FileUploadStatus.Attached)("")(uuid)
    },
    [updateDocumentStatus]
  )

  // The service worker does some property swapping while offline, so refetch docs if the online
  // state changes.
  useEffect(() => {
    refetch()
  }, [appOnline, refetch])

  const {
    previewHandlers,
    dropzoneHandlers,
    dropzoneProps,
    previewProps,
    utilityHandlers
  } = useMemo(
    () => ({
      previewHandlers: pick(propsAndHandlers, Object.keys(PreviewHandlerNames)),
      dropzoneHandlers: pick(
        propsAndHandlers,
        Object.keys(DropZoneHandlerNames)
      ),
      dropzoneProps: pick(
        propsAndHandlers,
        Object.keys(SettableDropZonePropNames)
      ),
      previewProps: pick(
        propsAndHandlers,
        Object.keys(SettablePreviewPropNames)
      ),
      utilityHandlers: pick(
        propsAndHandlers,
        Object.keys(UtilityHandlerNames)
      ) as UtilityProps
    }),
    [propsAndHandlers]
  )

  /* Wrap any handlers that the component also needs to set */
  const wrappedOnDrop = dropzoneHandlers?.onDrop
  dropzoneHandlers.onDrop = async (acceptedFiles, rejectedFiles, event) => {
    const shouldProceed = utilityHandlers?.onBeforeDrop
      ? await utilityHandlers.onBeforeDrop(acceptedFiles, rejectedFiles, event)
      : true

    if (!shouldProceed) return
    const errors = rejectedFiles.map(({ file }: { file: File }) => {
      let errorMessage
      if (!multiple && rejectedFiles.length > 1) {
        errorMessage = `Attaching multiple files at once is not allowed.`
      } else if (maxSize && file.size > maxSize) {
        errorMessage = `The file is too large.`
      } else if (minSize && file.size < minSize) {
        errorMessage = `The file is too small.`
      } else {
        errorMessage = `Attaching ${file.type} is not allowed. Please double-check the file type and try again.`
      }

      const label = cond([
        [isString, () => labelOverride],
        // @ts-ignore
        [is(Function), () => labelOverride(file.name)],
        // @ts-ignore
        [() => true, () => file.name]
      ])(labelOverride)

      return {
        __typename: "Document",
        id: "",
        versionId: "",
        uuid: uuid(),
        label,
        size: 0,
        created: moment.utc().format(`YYYY-MM-DDTHH:mm:ss.SSSSSS+00:00`),
        mimetype: null,
        original: null,
        exif: null,
        type: null,
        status: FileUploadStatus.DropError,
        cabinets: null,
        errorMessage,
        smallThumbnail: null
      }
    })

    // Add local-only error states to the hook cache instead of the app cache for easier
    // async reconciliation
    setTrackedDocuments(
      documents =>
        [...documents, ...errors] as Array<Document & SmallThumbnailFragment>
    )

    // TODO: Expand into error array to pass to callbacks once multiple file upload is
    // enabled
    let success = 0 === errors.length
    acceptedFiles.forEach(async (file: File) => {
      const label = cond([
        [isString, () => labelOverride],
        // @ts-ignore
        [is(Function), () => labelOverride(file.name)],
        // @ts-ignore
        [() => true, () => file.name]
      ])(labelOverride)

      // TODO: Include logic to set this to PrintedTicket and ProcessImage when needed
      const type = file.type.includes(`image`)
        ? DocumentType.Image
        : DocumentType.Document

      const input = {
        file,
        uuid: uuid(),
        label,
        type,
        cabinetId,
        cabinetLabel,
        category
      }

      try {
        await addDocument({
          variables: {
            input
          }
        })
      } catch (err) {
        console.error(err)
        success = false
      }
    })

    wrappedOnDrop &&
      (await wrappedOnDrop(acceptedFiles, rejectedFiles, event, success))
  }

  const wrappedOnDelete = previewHandlers?.onDelete
  previewHandlers.onDelete = async (...args) => {
    const [uuid] = args

    const documentIndex = findIndex(trackedDocuments, { uuid })
    if (documentIndex > -1) {
      switch (trackedDocuments[documentIndex].status) {
        // DropErrors only exist in the hook cache, so we don't need to update the
        // Apollo cache
        case FileUploadStatus.DropError:
          setTrackedDocuments(documents =>
            filter(documents, document => document.uuid !== uuid)
          )
          break
        // UploadErrors will be in the Apollo cache because of the optimistic response
        // TODO: Write Cypress integration tests to confirm error state displays
        case FileUploadStatus.UploadError:
          const newDocumentResults = produce(
            rawDocumentResults,
            (draft: any) => {
              draft.documents.edges = filter(
                draft?.documents?.edges,
                el => el.node.uuid !== uuid
              )
            }
          )

          try {
            await client.writeQuery({
              query: GET_CABINET_DOCUMENTS,
              variables: {
                filter: {
                  cabinetId,
                  cabinetLabel
                }
              },
              data: {
                ...newDocumentResults
              }
            })
            // Also remove from tracked documents so that it doesn't get stuck in the UI
            setTrackedDocuments(documents =>
              filter(documents, document => document.uuid !== uuid)
            )
          } catch (err) {
            console.error(err)
          }
          break
        // Everything else is probably a remove request
        default:
          try {
            await removeDocument({
              variables: {
                documentUUID: uuid
              }
            })
          } catch (err) {
            console.error(err)
          }
      }
    }
    // @ts-ignore
    wrappedOnDelete && (await wrappedOnDelete(...args))
  }

  const wrappedOnImgClick = previewHandlers?.onImgClick
  previewHandlers.onImgClick = async (...args) => {
    // @ts-ignore
    wrappedOnImgClick && (await wrappedOnImgClick(...args))
  }

  return {
    accept,
    documents: trackedDocuments,
    clearRemovalError,
    previewProps,
    previewHandlers,
    dropzoneProps,
    dropzoneHandlers,
    addDocument,
    removeDocument
  }
}

export default useAttachments
