import { gql } from "@apollo/client"
import inventoryFragments from "./inventory"

const chargeKitFragments = {
  fullRequiredCharge: gql`
    fragment FullRequiredCharge on RequiredCharge {
      id
      created
      modified
      item {
        ...BaseInventoryItem
      }
      order
      quantity
      suggestedPrice
      price
      total
      priceRule {
        id
      }
    }
    ${inventoryFragments.baseInventoryItem}
  `
}

export default chargeKitFragments
