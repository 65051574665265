import { IMaskMixin } from "react-imask"
import TextField from "./TextField"
import produce from "immer"
import isNil from "lodash/isNil"

/*
Example phone input
import IMask from 'imask';

<Field
  name="companyManPhone"
  component={MaskedField}
  mask={IMask.createMask({
    mask: '(000) 000-0000',
  })}
  label="Company Man Phone"
/>
*/

/* IMaskMixin sets a default value when it's controlled. I think
 * it assumes that wrapped inputs are uncontrolled by default.
 * This causes problems if you try to control the input at the
 * <MaskedField /> level, because then the final component has both
 * `defaultValue` set by MaskedField and `value` set by formik.
 *
 * If both are set and are equal, it's probably because we're
 * trying to initialize the IMask value, so delete the default props */
const MaskedField = IMaskMixin(({ inputRef, ...props }) => {
  const newProps = produce(props, draft => {
    draft.defaultValue !== undefined &&
    draft.field.value !== undefined &&
    draft.defaultValue === draft.field.value
      ? delete draft.defaultValue
      : console.warn(
          `The value of the mask (${draft.defaultValue}) has been initialized to a value different from the formik value. (${props.field.value})`
        )

    if (isNil(draft.field.value)) draft.field.value = ""
  })

  return <TextField inputRef={inputRef} {...newProps} />
})

export default MaskedField
