export enum PrettyActionFlags {
  Addition = "Addition",
  Change = "Change",
  Deletion = "Deletion"
}

export enum PrettyAssignmentTypes {
  Created = "Created",
  Owner = "Owner",
  Assigned = "Assigned",
  Watching = "Watching",
  Sales = "Inside Sales",
  Tech = "Technician",
  LeadTech = "Lead Technician",
  CompanyMan = "Company Man"
}

export enum PrettyAuthorizationType {
  Cognito = "Cognito",
  Expired = "Expired",
  Jwt = "JWT",
  None = "None"
}

export enum PrettyCategory {
  Tickets = "Ticket",
  RunningProcedure = "Running Procedure",
  Drawing = "Technical Drawing",
  SalesOrders = "Sales Orders",
  Wells = "Wells"
}

export enum PrettyChangeOutcome {
  Failed = "Failed",
  New = "New",
  Updated = "Updated"
}

export enum PrettyChargePriceState {
  Unset = "Not set",
  Unedited = "Not edited",
  Edited = "Edited"
}

export enum PrettyChargeState {
  Locked = "Locked",
  Editable = "Editable"
}

export enum PrettyDocumentType {
  PrintedTicket = "Printed Ticket",
  SignedDocument = "Signed Document",
  ProcessImage = "Process Image",
  Image = "Image",
  Document = "Document",
  Signature = "Signature"
}

export enum PrettyErpSalesOrderStatuses {
  Estimate = "Estimate",
  Issued = "Issued",
  InProgress = "In Progress",
  Fulfilled = "Fulfilled",
  ClosedShort = "Closed Short",
  Voided = "Voided",
  Cancelled = "Cancelled",
  Expired = "Expired",
  Historical = "Historical"
}

export enum PrettyEventAssociation {
  Actor = "ACTOR",
  Target = "TARGET",
  Object = "OBJECT"
}

export enum PrettyFieldType {
  Length = "Length (Inches Only)",
  LengthFt = "Length (Feet and Inches)",
  Photo = "Photo",
  Temperature = "Temperature",
  Weight = "Weight",
  Pressure = "Pressure",
  Duration = "Duration",
  PressureAndDuration = "Pressure and Duration",
  SingleSelect = "Single Select",
  Text = "Text",
  Boolean = "Confirmation",
  SizeWeightGrade = "Casing Spec",
  Torque = "Torque",
  Size = "Size",
  Grade = "Grade",
  DateTime = "Date/Time",
  YesNo = "Yes/No",
  DigitalPressureTest = "Digital Pressure Test"
}

export enum PrettyFileUploadStatus {
  Attached = "ATTACHED",
  AttachedOffline = "ATTACHED_OFFLINE",
  AttachedOfflineEnabled = "ATTACHED_OFFLINE_ENABLED",
  DropError = "DROP_ERROR",
  UploadError = "UPLOAD_ERROR",
  UploadPending = "UPLOAD_PENDING",
  Uploaded = "UPLOADED",
  Uploading = "UPLOADING",
  RemovalError = "REMOVAL_ERROR"
}

export enum PrettyNumberFormats {
  FourDigit = "4 Digit (service)",
  SixDigit = "6 Digit",
  SevenDigit = "7 Digit"
}

export enum PrettySalesOrderItemTypes {
  AssocPrice = "Assoc. Price",
  CreditReturn = "Credit Return",
  DiscountAmount = "Discount Amount",
  DiscountPercentage = "Discount Percentage",
  DropShip = "Drop Ship",
  Kit = "Kit",
  MiscCredit = "Misc. Credit",
  MiscSale = "Misc. Sale",
  Note = "Note",
  Sale = "Sale",
  Shipping = "Shipping",
  Subtotal = "Subtotal",
  Tax = "Tax"
}

export enum PrettySalesOrderStatuses {
  Draft = "Draft",
  Unsigned = "Unsigned",
  Complete = "Complete",
  Invoiced = "Invoiced"
}

export enum PrettyShopLocations {
  Odessa = "Odessa, TX",
  Wellington = "Wellington, CO",
  CorpusChristi = "Corpus Christi, TX"
}

export enum PrettySignatureRequestTypes {
  Email = "Email"
}

export enum PrettySignatureResponseTypes {
  Digital = "Digital Signature",
  Upload = "Uploaded Signature",
  TicketUpload = "Uploaded Ticket",
  Stamp = "Customer Stamp",
  StampUpload = "Uploaded Customer Stamp",
  Email = "Email",
  Rejected = "Rejected"
}

export enum PrettySignatureStates {
  Unsigned = "Unsigned",
  Pending = "Pending",
  Signed = "Signed",
  Rejected = "Rejected"
}

export enum PrettySort {
  Asc = "asc",
  Desc = "desc"
}

export enum PrettyTicketPhases {
  Drilling = "Drilling",
  Completions = "Completions",
  Production = "Production"
}

export enum PrettyTicketStatusTransitions {
  Schedule = "Schedule",
  Dispatch = "Dispatch",
  Complete = "Complete",
  Reject = "Reject",
  ReDispatch = "Re-dispatch",
  ForceReviewed = "Force Reviewed",
  Review = "Review",
  Finalize = "Finalize",
  Invoice = "Invoice",
  Unreview = "Unreview",
  Unfinalize = "Unfinalize",
  Uncomplete = "Uncomplete",
  ReturnToScheduled = "Return to Scheduled",
  Unschedule = "Unschedule"
}

export enum PrettyTicketStatuses {
  Draft = "Draft",
  Scheduled = "Scheduled",
  Dispatched = "Dispatched",
  Complete = "Complete",
  Rejected = "Rejected",
  Reviewed = "Reviewed",
  Finalized = "Finalized",
  Invoiced = "Invoiced"
}

export enum PrettyTicketTypes {
  LandingMandrelInstall = "Landing Mandrel Install",
  LandingRingInstall = "Landing Ring Install",
  BopQuickConnect = "BOP Quick Connect",
  CasingHeadInstall = "Casing Head Install",
  DrillingTools = "Drilling Tools",
  IntermediateHangerInstall = "Intermediate Hanger Install",
  SecondIntermediateHangerInstall = "2nd Intermediate Hanger Install",
  CasingSpoolInstall = "Casing Spool Install",
  ProductionHangerInstall = "Production Hanger Install",
  TaCap = "TA Cap",
  TubingHeadInstall = "Tubing Head Install",
  ProductionTreeInstall = "Production Tree Install",
  TestTrailer = "Test Trailer",
  Lubricator = "Lubricator",
  FracStackInstall = "Frac Stack Install",
  FracWatch = "Frac Watch",
  FracStackRemoval = "Frac Stack Removal",
  Greasing = "Greasing",
  Troubleshoot = "Troubleshoot",
  Decompletion = "Decompletion",
  Miscellaneous = "Miscellaneous"
}

export enum PrettyTransitionDirection {
  Forward = "FORWARD",
  Backward = "BACKWARD"
}
