import { useCallback, useContext } from "react"
import { FormattedMessage } from "react-intl"
import { useApolloClient } from "@apollo/client"
import moment from "moment"
import compact from "lodash/compact"
import cond from "lodash/cond"
import difference from "lodash/difference"
import filter from "lodash/filter"
import flatMap from "lodash/flatMap"
import intersection from "lodash/intersection"
import map from "lodash/map"
import some from "lodash/some"
import sortBy from "lodash/sortBy"
import uniq from "lodash/uniq"

import {
  GetCabinetDocumentLabelsQuery,
  GetCabinetDocumentLabelsQueryVariables,
  SignatureResponseTypes
} from "client/types"

import { GET_CABINET_DOCUMENT_LABELS } from "queries/documents"

import UserContext from "context/user"
import { ParsedRunningProcedureTicketStepResult } from "hooks/runningProcedures"

import { getTagIdentifiers, searchForStepTag } from "components/Attachments"
import { useConfirm } from "components/ConfirmDialog"
import { DisplayTicket } from "views/TicketDetails/TicketDetails"
import { edgeLess } from "utils/apollo"
import messageValues from "utils/messageValues"

export type StepDataValidationResponse = {
  isValid?: boolean
  allOptionalStepsFilledOut?: boolean
  allRequiredStepsFilledOut?: boolean
  someOptionalStepsFilledOut?: boolean
  error?: Error
}
export type ConfirmTicketCompletionVars = {
  allOptionalStepsFilledOut: boolean
  allRequiredStepsFilledOut: boolean
  someOptionalStepsFilledOut: boolean
}
export type StepDataValidationAndConfirmResponse = {
  shouldComplete: boolean
  error?: Error
}

export const getStepsWithPhotos = (documentLabels: Array<string>) =>
  uniq(
    // The tag identifiers for the photos should be the step ids
    flatMap(documentLabels, getTagIdentifiers)
  ).map(el => `${el}:PHOTO`)

// Add attributes to the step ids to correctly handle multi-input steps, treat false on
// boolean inputs as no data. This way toggling the switch to false doesn't read as
// being filled out.
export const getStepsWithData = steps =>
  compact(
    flatMap(steps, (step: ParsedRunningProcedureTicketStepResult) => {
      return map(step.parsedData, (val, attribute) => {
        if (Array.isArray(val.data)) {
          return some(val.data, el => Boolean(el))
            ? `${step?.runningProcedureStep?.id}:${attribute}`
            : null
        } else {
          return Boolean(val.data)
            ? `${step?.runningProcedureStep?.id}:${attribute}`
            : null
        }
      })
    })
  )

const questions = {
  [SignatureResponseTypes.Rejected]:
    "Are you sure you want to complete the ticket without a signature?",
  default: "Are you sure you want to sign the ticket?"
}

// Verifies if RP steps have been filled out/pops up a confirmation dialog
const useRPDataValidation = () => {
  const client = useApolloClient()
  const user = useContext(UserContext)
  const confirm = useConfirm()

  const confirmTicketCompletion = useCallback(
    (signatureType: SignatureResponseTypes) => async ({
      allOptionalStepsFilledOut,
      allRequiredStepsFilledOut,
      someOptionalStepsFilledOut
    }: ConfirmTicketCompletionVars) => {
      const userTimestampString = `<p><strong>${
        user?.displayName
      }</strong> is completing the ticket at <strong>${moment()
        .local()
        .format("hh:mm a")}</strong> on <strong>${moment()
        .local()
        .format("MM/DD/YY")}</strong>.</p>`
      const questionString = `<p>${
        questions?.[signatureType] ?? questions.default
      }</p>`

      const defaultMessage = cond<
        {
          allRequiredStepsFilledOut: boolean
          someOptionalStepsFilledOut: boolean
          allOptionalStepsFilledOut: boolean
        },
        string
      >([
        [
          ({
            allRequiredStepsFilledOut,
            someOptionalStepsFilledOut,
            allOptionalStepsFilledOut
          }) =>
            someOptionalStepsFilledOut &&
            !allOptionalStepsFilledOut &&
            !allRequiredStepsFilledOut,
          () =>
            `${questionString}
<p>Some of the required step documentation is missing, and it looks like the emergency procedure was started, but not finished.</p>
${userTimestampString}`
        ],
        [
          ({ allRequiredStepsFilledOut, allOptionalStepsFilledOut }) =>
            allOptionalStepsFilledOut && !allRequiredStepsFilledOut,
          () => `${questionString}
<p>Some of the required step documentation is missing. (If those steps weren't done due to the switch to the emergency procedure, please ignore this message.)</p>
${userTimestampString}`
        ],
        [
          ({ someOptionalStepsFilledOut, allOptionalStepsFilledOut }) =>
            someOptionalStepsFilledOut && !allOptionalStepsFilledOut,
          () =>
            `${questionString}
<p>It looks like the emergency procedure was started, but not finished.</p>
${userTimestampString}`
        ],
        [
          ({ allRequiredStepsFilledOut, allOptionalStepsFilledOut }) =>
            !allOptionalStepsFilledOut && !allRequiredStepsFilledOut,
          () =>
            `${questionString}
<p>Some of the required step documentation is missing.</p>
${userTimestampString}`
        ]
      ])({
        allRequiredStepsFilledOut,
        someOptionalStepsFilledOut,
        allOptionalStepsFilledOut
      })

      return confirm({
        description: (
          <FormattedMessage
            id="ticket.confirmTransition"
            values={messageValues}
            defaultMessage={defaultMessage}
          />
        )
      })
        .then(_ => true)
        .catch(_ => false)
    },
    [confirm, user]
  )

  const validateStepData = useCallback(
    async (ticket: DisplayTicket): Promise<StepDataValidationResponse> => {
      const { cabinetId, number: cabinetLabel, steps } = ticket

      // 1. All required steps should be filled out (this catches optional steps filled out but
      // some still-required steps are missing) - living with the false positives for now
      // 2. If some optional steps are filled out, all optional steps need to be filled out

      try {
        const { data } = await client.query<
          GetCabinetDocumentLabelsQuery,
          GetCabinetDocumentLabelsQueryVariables
        >({
          query: GET_CABINET_DOCUMENT_LABELS,
          fetchPolicy: "network-only",
          variables: {
            cabinetId,
            cabinetLabel,
            label: searchForStepTag,
            label_lookup: "istartswith"
          }
        })
        const documentLabels = edgeLess((data as any)?.documents).map(
          document => document.label
        )

        const stepsWithPhotos = getStepsWithPhotos(documentLabels)
        // Add attributes to the step ids to correctly handle multi-input steps, treat false on
        // boolean inputs as no data. This way toggling the switch to false doesn't read as
        // being filled out.
        const stepsWithData = getStepsWithData(steps)

        const filledOutSteps = sortBy([...stepsWithPhotos, ...stepsWithData])
        const requiredSteps = sortBy(
          filter(
            steps,
            (step: ParsedRunningProcedureTicketStepResult) =>
              step?.runningProcedureStep?.required
          )
            .map(step =>
              map(
                (step as ParsedRunningProcedureTicketStepResult).parsedData,
                (_, attribute) =>
                  `${
                    (step as ParsedRunningProcedureTicketStepResult)
                      ?.runningProcedureStep?.id
                  }:${attribute}`
              )
            )
            .flat()
        )
        const optionalSteps = sortBy(
          filter(
            steps,
            (step: ParsedRunningProcedureTicketStepResult) =>
              !step?.runningProcedureStep?.required
          )
            .map(step =>
              map(
                (step as ParsedRunningProcedureTicketStepResult).parsedData,
                (_, attribute) =>
                  `${
                    (step as ParsedRunningProcedureTicketStepResult)
                      ?.runningProcedureStep?.id
                  }:${attribute}`
              )
            )
            .flat()
        )

        const allRequiredStepsFilledOut =
          difference(requiredSteps, filledOutSteps).length === 0
        const someOptionalStepsFilledOut =
          intersection(filledOutSteps, optionalSteps).length > 0
        const allOptionalStepsFilledOut =
          optionalSteps.length > 0 &&
          difference(optionalSteps, filledOutSteps).length === 0
        const isValid = allRequiredStepsFilledOut || allOptionalStepsFilledOut

        return {
          isValid,
          allRequiredStepsFilledOut,
          someOptionalStepsFilledOut,
          allOptionalStepsFilledOut
        }
      } catch (err) {
        return { isValid: undefined, error: err }
      }
    },
    [client]
  )

  const validateStepDataAndConfirm = useCallback(
    (signatureType: SignatureResponseTypes) => async (
      ticket: DisplayTicket
    ) => {
      const {
        isValid,
        allOptionalStepsFilledOut = false,
        allRequiredStepsFilledOut = false,
        someOptionalStepsFilledOut = false,
        error
      } = await validateStepData(ticket)

      if (error)
        return {
          shouldComplete: false,
          error
        }

      if (isValid)
        return {
          shouldComplete: true
        }

      const shouldComplete = await confirmTicketCompletion(signatureType)({
        allOptionalStepsFilledOut,
        allRequiredStepsFilledOut,
        someOptionalStepsFilledOut
      })
      return {
        shouldComplete
      }
    },
    [confirmTicketCompletion, validateStepData]
  )

  return {
    confirmTicketCompletion,
    validateStepData,
    validateStepDataAndConfirm
  }
}

export default useRPDataValidation
