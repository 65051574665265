import { useEffect, useRef } from "react"
import { FormikContextType, FormikErrors } from "formik"
import get from "lodash/get"
import map from "lodash/map"

type useInitialFormikValidationProps<T> = {
  formik: FormikContextType<T>
  additionalFieldsToTouch?: Array<string>
  subPath?: string
}

const useInitialFormikValidation: <T>(
  props: useInitialFormikValidationProps<T>
) => void = ({ formik, additionalFieldsToTouch = [], subPath }) => {
  // Use ref because we're intentionally running this only once
  const formikRef = useRef(formik)
  const fieldsRef = useRef(additionalFieldsToTouch)
  // Some initial states rely on the results of GraphQL queries. If so, that logic should
  // set this field to false in useFormik to prevent form validation from running too early
  const isInitialStateStable =
    (formik as any)?.values?.isInitialStateStable ?? true
  useEffect(() => {
    // Handle nested error paths
    const touchFields: <T>(
      errors: FormikErrors<T>,
      basePath?: string
    ) => void = (errors, basePath = "") => {
      map(errors, (value, key) => {
        const path = basePath ? `${basePath}.${key}` : key
        if (`string` === typeof value) {
          formikRef.current.setFieldTouched(path, true, false)
        } else {
          touchFields(value as FormikErrors<any>, path)
        }
      })
    }

    const effect = async () => {
      const errors = await formikRef.current.validateForm()
      const errorsToCheck = subPath
        ? { [subPath]: get(errors, subPath) }
        : errors
      touchFields(errorsToCheck)
    }

    if (isInitialStateStable) {
      effect()
      // When touching the additional fields, run validation again because this is meant to
      // catch the fields which need rerendering because formik has incorrectly flagged them
      // as having errors. (These can't be caught easily by validating the form again)
      fieldsRef.current.map(path =>
        formikRef.current.setFieldTouched(path, true, true)
      )
    }
  }, [isInitialStateStable, subPath])
}

export default useInitialFormikValidation
