import { useApolloClient, useMutation } from "@apollo/client"
import enums from "client/enums"
import {
  CreateTicketMutation,
  CreateTicketMutationVariables,
  CreateWellMutation,
  CreateWellMutationVariables,
  RunningProcedure
} from "client/types"
import {
  createNewWellOption,
  CreateTicketData
} from "components/forms/CreateTicketForm"
import { CreateWellData } from "components/forms/CreateWellForm"
import { useFormik } from "formik"
import { FormStateContextType } from "hooks/forms/useFormState"
import { getMostRecentRevisionId } from "hooks/runningProcedures"
import { useTicketPhaseMap } from "hooks/graphql"
import { GET_RUNNING_PROCEDURE_BY_ID } from "queries/runningProcedures"
import { CREATE_TICKET } from "queries/tickets"
import { CREATE_WELL } from "queries/wells"
import { createCastId } from "utils/yup"
import * as Yup from "yup"
import { createWellSchema } from "./useWellCreate"
import map from "lodash/map"

type UseTicketCreateProps = {
  onAdd: (...args: any[]) => any
  onError: FormStateContextType["setFormErrors"]
  onFieldError: FormStateContextType["setFieldErrors"]
  initialValues?: CreateTicketData
  initialWellValues?: Partial<CreateWellData>
}

const createTicketSchema = Yup.object().shape({
  type: Yup.string()
    .required("Ticket type required")
    .oneOf(enums.TicketTypes.values as string[], "Please set a ticket type")
    .nullable(),
  phase: Yup.string()
    .required("Ticket phase required")
    .oneOf(enums.TicketPhases.values as string[], "Please set a ticket phase")
    .nullable(),
  company: createCastId(
    Yup.object()
      .shape({
        id: Yup.number()
      })
      .nullable()
  ),
  well: Yup.object()
    .shape({
      id: Yup.string()
    })
    .nullable()
    .required("A well is required"),
  wellId: Yup.ref("well.id"),
  workRequestDate: Yup.date().default(() => new Date()),
  scheduledArrivalTime: Yup.date().nullable(),
  // only include create well when well isn't present
  createWell: Yup.object().when("well", {
    is: well => well?.id === createNewWellOption.id,
    then: createWellSchema.required(),
    otherwise: Yup.object().nullable()
  }),
  companyMan: Yup.string(),
  phaseMap: Yup.object().strip(true),
  isInitialStateStable: Yup.boolean().strip(true),
  // @ts-ignore
  procedure: Yup.mixed().isNotEmptyRP()

  // test({
  //   name: "emptyRPCheck",
  //   exclusive: false,
  //   message: "This running procedure has no steps",
  //   test: function (value) {
  //     return value == null

  //     // console.log(`===value`, value)
  //     return false
  //   }
  // })
})

const useTicketCreate = ({
  onAdd,
  onError,
  onFieldError,
  initialValues = {},
  initialWellValues = {}
}: UseTicketCreateProps) => {
  const client = useApolloClient()

  const [createTicket, { loading: mutationRunning }] = useMutation<
    CreateTicketMutation,
    CreateTicketMutationVariables
  >(CREATE_TICKET)

  const [createWell, { loading: wellMutationRunning }] = useMutation<
    CreateWellMutation,
    CreateWellMutationVariables
  >(CREATE_WELL)

  const [phaseMap, isPhaseMapLoading] = useTicketPhaseMap()

  const mutating = wellMutationRunning || mutationRunning

  const formik = useFormik<CreateTicketData>({
    initialValues: {
      ...initialValues,
      createNewWell: false,
      createWell: {
        name: "",
        ...initialWellValues
      },
      phaseMap,
      isInitialStateStable:
        !isPhaseMapLoading && Boolean(Object.keys(phaseMap).length)
    },
    enableReinitialize: true,
    validationSchema: createTicketSchema,
    onSubmit: async (values, actions) => {
      const castValues = createTicketSchema.cast(values, {
        context: { cast: "create" }
      }) as CreateTicketData & {
        procedure?: RunningProcedure
      }
      const createWellData = castValues.createWell

      delete castValues.well
      // remove create well data
      delete castValues.createWell
      delete castValues.createNewWell

      if (!!castValues.procedure) {
        // look up the running procedure to get the revision id
        // always get the most recent version
        const fullRunningProcedure: RunningProcedure = await client
          .query({
            query: GET_RUNNING_PROCEDURE_BY_ID,
            fetchPolicy: "network-only",
            variables: {
              runningProcedureId: castValues.procedure.id
            }
          })
          .then(({ data, errors }) => {
            if (errors) {
              // This won't have field errors because it's not a mutation
              // @ts-ignore
              onError(errors)
              return null
            }
            return data.procedure
          })
          .catch(err => {
            onError(err)
            return null
          })

        // parse running procedure info
        castValues.procedureRevisionId = fullRunningProcedure
          ? getMostRecentRevisionId(fullRunningProcedure)
          : null
      }
      delete castValues.procedure

      try {
        // logic to determine if we're creating a well first.
        if (
          createWellData &&
          String(castValues.wellId) === createNewWellOption.id
        ) {
          const {
            // @ts-ignore
            data: { wellData }
          } = await createWell({
            variables: {
              // @ts-ignore
              input: createWellData
            }
          })
          if (wellData.success) {
            castValues.wellId = wellData.well.id
          } else {
            // Add the prefix to the name
            const mappedErrors = map(wellData.errors, error => ({
              ...error,
              name: `createWell.${error.name}`
            }))
            console.error(mappedErrors)
            onFieldError(mappedErrors)
            return
          }
          // Attach company to ticket data to be saved
          // @ts-ignore
          castValues.company = createWellData.company
        }

        // Finally, create the ticket
        const { data } = await createTicket({
          variables: {
            // @ts-ignore
            input: castValues
          }
        })
        const {
          // @ts-ignore
          ticketData: { ticket, success, errors }
        } = data
        // TODO: figure out why we are refreshing ticket list query on submit
        if (success) {
          onAdd(ticket)
        } else {
          onFieldError(errors)
        }
      } catch (err) {
        onError(err)
      }
      actions.setSubmitting(false)
    }
  })

  return {
    formik,
    mutating
  }
}

export default useTicketCreate
