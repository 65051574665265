import { colors } from "@material-ui/core"

const overrides = (palette, typography) => ({
  root: {
    borderRadius: 3,
    overflow: "hidden"
  },
  colorPrimary: {
    backgroundColor: colors.blueGrey[50]
  }
})

export default overrides
