import { Grid, Link, Typography } from "@material-ui/core"
import { RunningProcedureStep } from "client/types"
import { ButtonBase, TitanButtonColor } from "components"
import { useFormikContext } from "formik"
import { useFormStateContext } from "hooks/forms"
import { FormattedMessage } from "react-intl"
import { DisplayTicket } from "views/TicketDetails/TicketDetails"
import SwitchStep, { SwitchStepProps } from "./SwitchStep"

type ChartStepProps = {
  stepId: RunningProcedureStep["id"]
} & SwitchStepProps

export const dataTestid = "chart-step"

const ChartStep: React.FunctionComponent<ChartStepProps> = ({
  stepId,
  ...props
}) => {
  const { values, isSubmitting } = useFormikContext<Partial<DisplayTicket>>()
  const { getMetadata } = useFormStateContext()
  const { id: ticketId } = values
  const {
    isDocumentationLocked = false,
    isMutationLoading = false
  } = getMetadata()

  return (
    <section data-testid={dataTestid}>
      <Grid container spacing={2} wrap="nowrap">
        <Grid item>
          <Typography variant="body1">
            <FormattedMessage
              id="chart-step-control-app-info"
              defaultMessage="Open control app by clicking button to the right, run the test and click the toggle below to confirm completion."
            />
          </Typography>
        </Grid>
        <Grid item>
          <ButtonBase
            // @ts-ignore
            component={Link}
            href={`atlas-control://pressure-tests/${ticketId}`}
            variant="contained"
            color={TitanButtonColor.Blue}
            underline="none"
            disabled={
              isSubmitting ||
              (isDocumentationLocked as boolean) ||
              (isMutationLoading as boolean)
            }
          >
            <FormattedMessage
              id="chart-step-control-app-button"
              defaultMessage="Open Control App"
            />
          </ButtonBase>
        </Grid>
      </Grid>
      <SwitchStep {...props} />
    </section>
  )
}

export default ChartStep
