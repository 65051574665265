import { grey } from "../palettes/colors"

const overrides = (palette, typography) => ({
  root: {
    textTransform: "none",
    backgroundColor: grey[50],
    boxShadow: "0 1px 1px 0 rgba(0,0,0,0.14)",
    "&:hover": { backgroundColor: grey[100] }
  },
  outlined: {
    borderColor: grey[200]
  }
})

export default overrides
