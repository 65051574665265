import * as Sentry from "@sentry/react"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef
} from "react"
import { v4 as uuid } from "uuid"
import warning from "tiny-warning"
import map from "lodash/map"
import noop from "lodash/noop"
import over from "lodash/over"
import reject from "lodash/reject"

export enum MessageTypes {
  FlushStaleTickets = "FLUSH_STALE_TICKETS",
  GetOnlineState = "GET_ONLINE_STATE",
  RegisterSentryClient = "REGISTER_SENTRY_CLIENT",
  SetCurrentUser = "SET_CURRENT_USER",
  SetOnlineState = "SET_ONLINE_STATE",
  SkipWaiting = "SKIP_WAITING",
  CheckDatabaseStatus = "CHECK_DATABASE_STATUS"
}
export enum ResponseTypes {
  Sentry = "SENTRY",
  ServiceWorkerOnlineState = "WORKER_ONLINE_STATE",
  DatabaseStatusResponse = "DATABASE_STATUS_RESPONSE",
  NewWorkerReady = "NEW_WORKER_READY"
}

export type MessageHandler = (payload: any) => void
export type MessageHandlers = Record<
  string,
  Array<{ id: string; handler: MessageHandler }>
>
export type HandlerToken = {
  type: ResponseTypes | ""
  id: string
}
export type ServiceWorkerMessage = {
  type: string
  payload?: any
}
export type ServiceWorkerContextType = {
  worker: ServiceWorkerContainer
  addMessageHandler: (
    type: ResponseTypes
  ) => (handler: MessageHandler) => HandlerToken
  removeMessageHandler: (token: HandlerToken) => void
  sendMessage: (message: ServiceWorkerMessage) => void
  skipWaiting: () => void
  unregister: () => Promise<Array<Promise<boolean>>>
  _default?: boolean
}

const defaultServiceWorkerContext: ServiceWorkerContextType = {
  worker: navigator.serviceWorker,
  addMessageHandler: () => () => ({ type: "", id: "" }),
  removeMessageHandler: noop,
  sendMessage: noop,
  skipWaiting: noop,
  unregister: () => Promise.resolve([]),
  _default: true
}

export const ServiceWorkerContext = createContext<ServiceWorkerContextType>(
  defaultServiceWorkerContext
)
export const ServiceWorkerProvider = ServiceWorkerContext.Provider
export const ServiceWorkerConsumer = ServiceWorkerContext.Consumer

export const useServiceWorkerContext = () => {
  const context = useContext(ServiceWorkerContext)
  warning(
    !context._default,
    "A Service Worker Context consumer did not find an Service Worker Context provider"
  )

  return context
}

const worker = navigator.serviceWorker
// Placing outside hook because we want to catch multiple invocations of the hook
let listenerTracker: ((...args: any[]) => void) | null = null
export const useServiceWorker: () => ServiceWorkerContextType = () => {
  const handlers = useRef<MessageHandlers>({})

  useEffect(() => {
    if (listenerTracker) {
      console.warn(
        "useServiceWorker() was called after a listener was attached to the service worker message event. Did you mean to use useServiceWorkerContext() instead?"
      )
      return
    }

    const handler = evt => {
      const { data } = evt
      const { type, payload } = data
      const fns = map(handlers.current[type], "handler")

      over(fns)(payload)
    }

    worker?.addEventListener("message", handler)
    listenerTracker = handler

    return () => {
      worker?.removeEventListener("message", handler)
      listenerTracker = null
    }
  }, [])

  const addMessageHandler: ServiceWorkerContextType["addMessageHandler"] = useCallback(
    (type: ResponseTypes) => (handler: (...args: any[]) => any) => {
      const id = uuid()
      handlers.current = {
        ...handlers.current,
        [type]: [...(handlers.current[type] ?? []), { id, handler }]
      }

      return { id, type }
    },
    []
  )

  const removeMessageHandler: ServiceWorkerContextType["removeMessageHandler"] = useCallback(
    ({ id, type }: { id: string; type: ResponseTypes | "" }) => {
      handlers.current = {
        ...handlers.current,
        [type]: reject(handlers.current[type], handler => handler.id === id)
      }
    },
    []
  )

  const sendMessage: ServiceWorkerContextType["sendMessage"] = useCallback(
    message => {
      const _send = async () => {
        await worker?.ready

        worker?.controller?.postMessage(message)
      }

      _send()
    },
    []
  )

  const skipWaiting: ServiceWorkerContextType["skipWaiting"] = useCallback(() => {
    navigator.serviceWorker.ready.then(registration => {
      const newWorker = registration.waiting
      if (newWorker) {
        newWorker.postMessage({
          type: MessageTypes.SkipWaiting
        })
      }
    })
  }, [])

  const unregister: ServiceWorkerContextType["unregister"] = useCallback(async () => {
    const registrations = await navigator.serviceWorker.getRegistrations()
    return registrations.map(registration => registration.unregister())
  }, [])

  useEffect(() => {
    const token = addMessageHandler(ResponseTypes.Sentry)(error => {
      Sentry.captureException(error)
    })
    const token2 = addMessageHandler(ResponseTypes.DatabaseStatusResponse)(
      ({ error }) => {
        if (error) {
          Sentry.captureException(error)
        }
      }
    )
    const token3 = addMessageHandler(ResponseTypes.NewWorkerReady)(() => {
      window.location.reload()
    })

    return () => {
      removeMessageHandler(token)
      removeMessageHandler(token2)
      removeMessageHandler(token3)
    }
  }, [addMessageHandler, removeMessageHandler])

  return {
    worker,
    addMessageHandler,
    removeMessageHandler,
    sendMessage,
    skipWaiting,
    unregister
  }
}

export default useServiceWorker
