import Button from "./Button"
import { Link, LinkProps } from "react-router-dom"

export interface IViewDetailsProps extends LinkProps {
  className?: string
}

const ViewDetails: React.FunctionComponent<IViewDetailsProps> = ({
  className,
  ...props
}) => (
  <Link {...props}>
    <Button className={className} condensed>
      View
    </Button>
  </Link>
)

export default ViewDetails
