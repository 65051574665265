import {
  City,
  GetCityOptionsQuery,
  GetCityOptionsQueryVariables
} from "client/types"
import { GET_CITY_OPTIONS } from "queries/options"
import {
  QueryAutocomplete,
  QueryAutocompleteProps
} from "components/autocomplete/QueryAutoComplete"
import { Typography, Chip } from "@material-ui/core"

type CityOptionType = City | null | undefined

const Label = ({ option }) => (
  <Typography noWrap component="div">
    {option.name}
    &nbsp;
    <Chip
      color="secondary"
      size="small"
      label={`${option.county.name} - ${option.state.name}`}
    />
  </Typography>
)

export function CityAutocomplete({
  ...props
}): React.ReactElement<QueryAutocompleteProps<CityOptionType>> {
  return (
    <QueryAutocomplete<
      GetCityOptionsQuery,
      GetCityOptionsQueryVariables,
      CityOptionType
    >
      {...props}
      query={GET_CITY_OPTIONS}
      getOptionSelected={(option, value) => option.id === value.id}
      renderOption={option => <Label option={option} />}
      getOptionLabel={option => option.name || ""}
    />
  )
}

export default CityAutocomplete
