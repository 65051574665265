import { Typography } from "@material-ui/core"

export enum UpdateMessageType {
  Customer = "Customer",
  Staff = "Staff"
}

export const updateMessages: Record<UpdateMessageType, JSX.Element> = {
  [UpdateMessageType.Customer]: (
    <>
      <Typography variant="body1" gutterBottom>
        We're constantly improving our app to provide a better experience. A new
        update is available and will be installed automatically once all Atlas
        browser windows are closed.
      </Typography>
      <Typography variant="body1" gutterBottom>
        You can click <strong>Install Update Now</strong> to restart the app and
        install the update now. Some features might no longer work until you do.
      </Typography>
    </>
  ),
  [UpdateMessageType.Staff]: (
    <Typography variant="body1">
      A new app update is available. Click <strong>Install Update Now</strong>{" "}
      to restart the app and finish installing the update. If you have any other
      tabs open to this app, please close them first.
    </Typography>
  )
}
