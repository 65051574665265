import Auth from "@aws-amplify/auth"
import { setContext } from "@apollo/client/link/context"
import queryString from "query-string"

const authMiddlewareLink = setContext((operation, { headers = {} }) => {
  // Find auth token from querystring - used for printing

  let token = localStorage.getItem("token")

  try {
    if (!token) {
      const query = window.location.search
      if (!!query) {
        // TODO: store it and continue to use it.
        const qs = queryString.parse(query)
        if (!!qs.token) {
          token = qs.token as string
          localStorage.setItem("token", token)
        }
      }
    }
  } catch (e) {
    console.error(e)
  }

  // If a token is present use it if Authorization is not already set
  if (!("Authorization" in headers) && token) {
    return {
      headers: {
        ...headers,
        Authorization: `Token ${token}`
      }
    }
  }

  return Auth.currentSession()
    .then(session => ({
      headers: {
        ...headers,
        ACCESSTOKEN: session.getAccessToken().getJwtToken(),
        IDTOKEN: session.getIdToken().getJwtToken(),
        REFRESHTOKEN: session.getRefreshToken().getToken()
      }
    }))
    .catch(error => {
      // No user was found.
      console.error(error)
    })
})

export default authMiddlewareLink
