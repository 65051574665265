import { useEffect } from "react"
import { DocumentNode } from "graphql"
import {
  LazyQueryResult,
  OperationVariables,
  QueryHookOptions,
  useLazyQuery
} from "@apollo/client"

// Workaround for skip not working
// https://github.com/apollographql/apollo-client/issues/6190
const useSkipQuery = <QueryData = any, QueryVariables = OperationVariables>(
  query: DocumentNode,
  args?: QueryHookOptions<QueryData, QueryVariables> & { skip?: boolean }
): LazyQueryResult<QueryData, QueryVariables> => {
  const { skip = false, ...queryArgs } = args ?? {}

  const [doQuery, result] = useLazyQuery<QueryData, QueryVariables>(query, {
    ...queryArgs
  })

  useEffect(() => {
    if (!skip) {
      doQuery()
    }
  }, [skip, doQuery])

  // Some functions, like refetch, will be undefined until the query is run at least once
  return result
}

export default useSkipQuery
