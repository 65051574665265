import useUpdateConfirm from "hooks/update"
import { ConfirmButton, IConfirmButtonProps } from "components"
import { UpdateMessageType } from "./messages"

interface IAppUpdateButtonProps extends IConfirmButtonProps {
  buttonText?: string
  messageType: UpdateMessageType
}

const AppUpdateButton: React.FunctionComponent<IAppUpdateButtonProps> = props => {
  const { messageType, onClick, ...otherProps } = props

  const { confirmReload, updateAvailable } = useUpdateConfirm(messageType)

  const handleClick =
    undefined === onClick
      ? confirmReload
      : e => {
          confirmReload()
          onClick(e)
        }

  return updateAvailable ? (
    <ConfirmButton onClick={handleClick} {...otherProps}>
      Update Available!
    </ConfirmButton>
  ) : null
}

export default AppUpdateButton
