import clsx from "clsx"
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  GridProps,
  IconButton,
  LinearProgress,
  Link,
  Typography,
  TypographyProps
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import CheckIcon from "@material-ui/icons/Check"
import ClearIcon from "@material-ui/icons/Clear"
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined"
import SaveIcon from "@material-ui/icons/Save"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"
import { grey } from "@material-ui/core/colors"
import filesize from "filesize"
import moment from "moment"

import {
  Document,
  DocumentType,
  FullThumbnailFragment,
  SmallThumbnailFragment,
  Maybe,
  Scalars
} from "client/types"

import { FileUploadStatus } from "client/types"

import { stripMetaTags } from "../utils"

export enum CardDisplayStyle {
  Card = "card",
  List = "list"
}

// Breaking this down into the displayed file attributes so that the file itself doesn't
// have to be in memory for this component to work.
export type FileDetails = Document &
  Partial<SmallThumbnailFragment> &
  Partial<FullThumbnailFragment> & {
    display?: CardDisplayStyle
    status?: Maybe<FileUploadStatus>
    errorMessage?: Maybe<string>
  }

export type DetailsCardProps = FileDetails & {
  disablePreviewDelete?: boolean
  onDelete?(id: Scalars["UUID"], event: Event): void
  onImgClick?(id: Scalars["UUID"], event: Event): void
}

const useCardStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  header: {
    position: "relative",
    "& .MuiCardHeader-content": {
      overflow: "hidden"
    }
  },
  headerAndContent: {},
  thumbnail: {
    position: "relative",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: 0,
    borderRadius: theme.spacing(0.5),
    overflow: "hidden",
    height: 120,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  thumbnailImageWrapper: {
    display: "flex",
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  thumbnailImage: {
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    margin: 0,
    objectFit: "cover",
    backgroundSize: "cover"
  },
  thumbnailNeutral: {
    backgroundColor: grey[100],
    color: theme.palette.text.secondary
  },
  thumbnailSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText
  },
  localSaveWrapper: {},
  localSaveIcon: {
    position: "absolute",
    right: -2,
    bottom: -4,
    borderTopLeftRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary
  },
  truncText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  title: {
    lineHeight: "1.3333em"
  },
  status: {
    paddingTop: 0,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end"
  },
  progressBar: {
    width: "100%",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  statusMessage: {
    width: "100%",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: theme.spacing(0.5)
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  }
}))

export const listThumbnailSize = 120
export const listThumbnailMargin = 1.5
const useListStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row"
  },
  header: {
    position: "relative",
    paddingLeft: theme.spacing(0),
    "& .MuiCardHeader-content": {
      overflow: "hidden"
    }
  },
  headerAndContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: `calc(100% - ${
      listThumbnailSize + theme.spacing(listThumbnailMargin * 2)
    }px)`
  },
  thumbnail: {
    margin: theme.spacing(listThumbnailMargin),
    width: listThumbnailSize,
    minWidth: listThumbnailSize,
    height: listThumbnailSize,
    borderRadius: theme.spacing(0.5),
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  thumbnailImageWrapper: {
    display: "flex",
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  thumbnailImage: {
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    margin: 0,
    objectFit: "cover",
    backgroundSize: "cover"
  },
  thumbnailNeutral: {
    backgroundColor: grey[100],
    color: theme.palette.text.secondary
  },
  thumbnailSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText
  },
  localSaveWrapper: {
    position: "relative"
  },
  localSaveIcon: {
    position: "absolute",
    right: -2,
    bottom: -4,
    borderTopLeftRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary
  },
  truncText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  title: {
    lineHeight: "1.3333em",
    fontWeight: 800
  },
  status: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end"
  },
  progressBar: {
    marginTop: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  statusMessage: {
    marginRight: theme.spacing(1.5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: theme.spacing(0.5)
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  }
}))

export const gridProps = {
  [CardDisplayStyle.Card]: { xs: 6, sm: 4, md: 3 } as GridProps,
  [CardDisplayStyle.List]: { xs: 12 } as GridProps
}
export const titleTypographyVariant = {
  [CardDisplayStyle.Card]: undefined,
  [CardDisplayStyle.List]: "subtitle1" as TypographyProps["variant"]
}
export const subheaderTypographyVariant = {
  [CardDisplayStyle.Card]: undefined,
  [CardDisplayStyle.List]: "subtitle2" as TypographyProps["variant"]
}
export const contentTypographyVariant = {
  [CardDisplayStyle.Card]: "body1" as TypographyProps["variant"],
  [CardDisplayStyle.List]: "body1" as TypographyProps["variant"]
}

const imageDocumentTypes: Array<DocumentType | null | undefined> = [
  DocumentType.Image,
  DocumentType.ProcessImage
]

export const dataTestid = "details-card"
export const deleteButtonTestid = "delete-button"
export const thumbnailTestid = "card-thumbnail"
export const offlinePendingMessage =
  "We'll upload the image when the app is back online"
const DetailsCard: React.FunctionComponent<DetailsCardProps> = ({
  display = CardDisplayStyle.Card,
  id = "",
  uuid = "",
  smallThumbnail = "",
  fullThumbnail = "",
  thumbnail = "",
  original,
  label = "",
  size = 0,
  created = "",
  type,
  status = FileUploadStatus.UploadError,
  errorMessage = "No error message passed into component",
  disablePreviewDelete = false,
  onDelete = () => {},
  onImgClick = () => {}
}) => {
  const classes = {
    [CardDisplayStyle.Card]: useCardStyles(),
    [CardDisplayStyle.List]: useListStyles()
  }

  let subheader: string, content: JSX.Element
  switch (status) {
    case FileUploadStatus.Attached:
    case FileUploadStatus.AttachedOffline:
    case FileUploadStatus.AttachedOfflineEnabled:
      subheader = filesize(size ?? 0)
      content = (
        <Typography
          color="textSecondary"
          variant={contentTypographyVariant[display]}
        >
          Uploaded by UNKNOWN on{" "}
          {moment.utc(created).local().format(`L (h:mm A)`)}
        </Typography>
      )
      break
    case FileUploadStatus.DropError:
    case FileUploadStatus.UploadError:
      subheader = `Upload Failed`
      content = (
        <Typography
          className={clsx(
            classes[display].statusMessage,
            classes[display].error
          )}
          variant={contentTypographyVariant[display]}
        >
          Error: {errorMessage}
        </Typography>
      )
      break
    case FileUploadStatus.UploadPending:
      subheader = "File Stored - App Offline"
      content = (
        <Typography
          color="textSecondary"
          variant={contentTypographyVariant[display]}
        >
          {offlinePendingMessage}
        </Typography>
      )
      break
    case FileUploadStatus.Uploading:
      subheader = `Uploading...`
      content = <LinearProgress className={classes[display].progressBar} />
      break
    case FileUploadStatus.Uploaded:
      subheader = `Upload Finished`
      content = (
        <Typography
          className={clsx(
            classes[display].statusMessage,
            classes[display].success
          )}
          variant={contentTypographyVariant[display]}
        >
          File successfully uploaded
        </Typography>
      )
      break
    case FileUploadStatus.RemovalError:
      subheader = `Delete Failed`
      content = (
        <Typography
          className={clsx(
            classes[display].statusMessage,
            classes[display].error
          )}
          variant={contentTypographyVariant[display]}
        >
          Error: {errorMessage}
        </Typography>
      )
      break
    default:
      subheader = `Unknown status`
      content = (
        <Typography
          className={clsx(
            classes[display].statusMessage,
            classes[display].error
          )}
          variant={contentTypographyVariant[display]}
        >
          Error: {"<DetailsCard />"} received an unknown status: {status}.
        </Typography>
      )
  }

  let thumbnailContent: JSX.Element, thumbnailClass: string
  switch (status) {
    case FileUploadStatus.Attached:
    case FileUploadStatus.AttachedOfflineEnabled:
      if (Boolean(smallThumbnail)) {
        if (imageDocumentTypes.includes(type)) {
          thumbnailContent = (
            <a
              className={classes[display].thumbnailImageWrapper}
              // Use the full thumbnail first because the original link will return
              // an HTTP header that forces a file download
              href={fullThumbnail ?? original ?? ""}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={classes[display].thumbnailImage}
                src={smallThumbnail ?? ""}
                alt={stripMetaTags(label ?? "")}
              />
            </a>
          )
        } else {
          thumbnailContent = (
            <a
              className={classes[display].thumbnailImageWrapper}
              href={original ?? ""}
              target="_blank"
              rel="noopener noreferrer"
            >
              <figure
                className={classes[display].thumbnailImage}
                style={{
                  backgroundImage: `url(${smallThumbnail})`
                }}
              />
            </a>
          )
        }
      } else {
        thumbnailContent = (
          <Link
            className={classes[display].thumbnailImageWrapper}
            href={original ?? ""}
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
          >
            <InsertDriveFileOutlinedIcon fontSize="large" />
          </Link>
        )
      }
      thumbnailClass = classes[display].thumbnailNeutral
      break
    case FileUploadStatus.Uploaded:
      thumbnailContent = <CheckIcon fontSize="large" />
      thumbnailClass = classes[display].thumbnailSuccess
      break
    case FileUploadStatus.AttachedOffline:
      thumbnailContent = (
        <>
          <VisibilityOffIcon fontSize="large" />
          <Typography variant="h5" color="inherit" align="center">
            <strong>OFFLINE</strong>
          </Typography>
        </>
      )
      thumbnailClass = classes[display].thumbnailNeutral
      break
    case FileUploadStatus.UploadPending:
      thumbnailContent = Boolean(smallThumbnail) ? (
        <>
          <img
            className={classes[display].thumbnailImage}
            src={smallThumbnail as string}
            alt={`Thumbnail for ${stripMetaTags(label ?? "")}`}
          />
          <div className={classes[display].localSaveIcon}>
            <SaveIcon data-icon="stored" />
          </div>
        </>
      ) : (
        <>
          <SaveIcon fontSize="large" />
          <Typography variant="h5" color="inherit" align="center">
            <strong>NO OFFLINE PREVIEW</strong>
          </Typography>
        </>
      )
      thumbnailClass = classes[display].thumbnailNeutral
      break
    case FileUploadStatus.DropError:
    case FileUploadStatus.UploadError:
    case FileUploadStatus.Uploading:
    case FileUploadStatus.RemovalError:
    default:
      thumbnailContent = <InsertDriveFileOutlinedIcon fontSize="large" />
      thumbnailClass = classes[display].thumbnailNeutral
  }

  // Typescript doesn't like defining evt as Event -- the types are more complicated
  const handleDelete = evt => {
    onDelete(uuid, evt)
  }

  const handleImgClick = evt => {
    onImgClick(uuid, evt)
  }

  const showDeleteButton =
    (!disablePreviewDelete && status !== FileUploadStatus.Uploading) ||
    (disablePreviewDelete &&
      (status === FileUploadStatus.UploadError ||
        status === FileUploadStatus.DropError))

  return (
    <Grid item {...gridProps[display]} data-testid={dataTestid}>
      <Card className={classes[display].root} data-status={status}>
        <CardMedia
          className={clsx(classes[display].thumbnail, thumbnailClass)}
          onClick={handleImgClick}
          data-testid={thumbnailTestid}
        >
          {thumbnailContent}
        </CardMedia>
        <div className={classes[display].headerAndContent}>
          <CardHeader
            className={classes[display].header}
            title={stripMetaTags(label ?? "")}
            titleTypographyProps={{
              className: clsx(
                classes[display].truncText,
                classes[display].title
              ),
              variant: titleTypographyVariant[display]
            }}
            subheader={subheader}
            subheaderTypographyProps={{
              className: clsx(classes[display].truncText),
              variant: subheaderTypographyVariant[display]
            }}
            action={
              showDeleteButton && (
                <IconButton
                  onClick={handleDelete}
                  data-testid={deleteButtonTestid}
                >
                  <ClearIcon />
                </IconButton>
              )
            }
          ></CardHeader>
          <CardContent className={classes[display].status}>
            {content}
          </CardContent>
        </div>
      </Card>
    </Grid>
  )
}

export default DetailsCard
