import { useContext } from "react"
import { MuiPickersContext } from "@material-ui/pickers"

interface IAgeLabelProps {
  date: any
}

const AgeLabel: React.FunctionComponent<IAgeLabelProps> = ({ date }) => {
  const dateUtil = useContext(MuiPickersContext)

  if (!date || !dateUtil) {
    return null
  }

  const currentDate = dateUtil.date()
  const targetDate = dateUtil.date(date)
  const diff = targetDate?.isValid() ? currentDate?.diff(targetDate, "days") : 0

  return (
    <strong>
      {diff} day{diff !== 1 ? "s" : ""}
    </strong>
  )
}

export default AgeLabel
