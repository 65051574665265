import { gql } from "@apollo/client"

export const GET_CURRENT_USER = gql`
  query getCurrentUser {
    currentUser {
      id
      companies {
        id
        name
      }
      fullName
      firstName
      lastName
      email
      groups {
        id
        name
        permissions {
          name
          contentType
          codename
        }
      }
    }
  }
`
