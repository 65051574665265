import { Icon, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import NotInterestedIcon from "@material-ui/icons/NotInterested"

interface IDisabledDropZoneProps {}

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: "400%",
    marginRight: theme.spacing(1)
  }
}))

export const dataTestid = "disabled-dropzone-text"

const DisabledDropZone: React.FunctionComponent<IDisabledDropZoneProps> = () => {
  const classes = useStyles()

  return (
    <>
      <Icon className={classes.icon}>
        <NotInterestedIcon fontSize="inherit" />
      </Icon>
      <section data-testid={dataTestid}>
        <Typography variant="h5" component="p">
          Uploading Files Disabled
        </Typography>
        <Typography>File uploads are not currently available</Typography>
      </section>
    </>
  )
}

export default DisabledDropZone
