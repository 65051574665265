import { Typography, Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import PropTypes from "prop-types"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: "center",
    padding: theme.spacing(3)
  }
}))

const EmptyList = props => {
  const { className, ...rest } = props

  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography variant="h4">There's nothing here...</Typography>
    </div>
  )
}

EmptyList.propTypes = {
  className: PropTypes.string
}

export default EmptyList
