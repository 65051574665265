// vim: foldmethod=marker:foldmarker={{{,}}}
// imports {{{
import { gql } from "@apollo/client"
import documentFragments from "fragments/documents"
import payloadFragments from "fragments/payload"
// imports }}}

// GET_ALL_CABINET_DOCUMENTS {{{
// This also gets documents that have been unattached from tickets
export const GET_ALL_CABINET_DOCUMENTS = gql`
  query getAllCabinetDocuments($filter: DocumentFilterInput) {
    documents: getAllDocuments(first: 200, filter: $filter) {
      edges {
        cursor
        node {
          ...BaseDocument
        }
      }
    }
  }
  ${documentFragments.baseDocument}
`
// GET_ALL_CABINET_DOCUMENTS }}}

// GET_CABINET_DOCUMENT_LABELS {{{
export const GET_CABINET_DOCUMENT_LABELS = gql`
  query getCabinetDocumentLabels(
    $cabinetId: ID
    $cabinetLabel: String
    $label: String
    $label_lookup: String
    $label_exclude: String
    $label_exclude_lookup: String
  ) {
    documents: getDocuments(
      first: 200
      filter: {
        cabinetId: $cabinetId
        cabinetLabel: $cabinetLabel
        label: $label
        label_lookup: $label_lookup
        label_exclude: $label_exclude
        label_exclude_lookup: $label_exclude_lookup
      }
    ) {
      edges {
        cursor
        node {
          ...DocumentLabel
        }
      }
    }
  }
  ${documentFragments.documentLabel}
`
// GET_CABINET_DOCUMENT_LABELS }}}

// GET_CABINET_DOCUMENTS {{{
export const GET_CABINET_DOCUMENTS = gql`
  query getCabinetDocuments($filter: DocumentFilterInput) {
    documents: getDocuments(first: 200, filter: $filter) {
      edges {
        cursor
        node {
          ...BaseDocument
        }
      }
    }
  }
  ${documentFragments.baseDocument}
`
// GET_CABINET_DOCUMENTS }}}

// GET_DOCUMENT_BY_UUID {{{
export const GET_DOCUMENT_BY_UUID = gql`
  query getDocumentByUUID($documentUUID: UUID!) {
    document: documentByUUID(documentUUID: $documentUUID) {
      ...BaseDocument
    }
  }
  ${documentFragments.baseDocument}
`
// GET_DOCUMENT_BY_UUID }}}

// ADD_DOCUMENT {{{
export const ADD_DOCUMENT = gql`
  mutation addDocument($input: AddDocumentInput!) {
    documentData: addDocument(input: $input) {
      ...BasePayload
      document: object {
        ... on PartialDocument {
          uuid
          type
          label
        }
        ...BaseDocument
      }
    }
  }
  ${payloadFragments.payloadFragment}
  ${documentFragments.baseDocument}
`
// ADD_DOCUMENT }}}

// REMOVE_DOCUMENT {{{
export const REMOVE_DOCUMENT = gql`
  mutation removeDocument($documentUUID: UUID!) {
    payload: removeDocument(documentUUID: $documentUUID) {
      ...BasePayload
      document: object {
        ... on PartialDocument {
          uuid
        }
        ... on Document {
          uuid
          versionId
        }
      }
    }
  }
  ${payloadFragments.payloadFragment}
`
// REMOVE_DOCUMENT }}}
