// This hook allows you to set timeouts that are automatically cancelled
// when the component is no longer rendered
import { useCallback, useEffect, useRef, useState } from "react"

const useTimeouts = () => {
  // use a ref so that the effect cleanup only runs once on component "unmount"
  // but still has the updated value so it can cancel any pending timeouts
  // to prevent memory leaks
  const ref = useRef([] as number[])
  const [ids, setIds] = useState([] as number[])
  ref.current = ids

  useEffect(
    () => () => {
      ref.current.forEach(id => {
        clearTimeout(id)
      })
    },
    []
  )

  // this returns the timeout id in case the consumer needs it
  const addTimeout: (
    cb: (...args: any[]) => any,
    delay?: number,
    ...args: any[]
  ) => number = useCallback(
    (cb, delay = 0, ...args) => {
      const id = (setTimeout(cb, delay, ...args) as unknown) as number
      setIds(ids => [...ids, id])
      return id
    },
    [setIds]
  )

  const addInterval: (
    cb: (...args: any[]) => any,
    delay?: number,
    ...args: any[]
  ) => number = useCallback(
    (cb, delay = 0, ...args) => {
      const id = (setInterval(cb, delay, ...args) as unknown) as number
      setIds(ids => [...ids, id])
      return id
    },
    [setIds]
  )

  // this returns the timeout id in case the consumer needs it

  return { addTimeout, addInterval }
}

export default useTimeouts
