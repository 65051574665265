import { Tabs, Tab, makeStyles } from "@material-ui/core"
import some from "lodash/some"

// Action Tabs are used for actions within a page or content switching
export interface ITabItem {
  value: string | number
  label: string
}

export interface IActionTabsProps {
  tabs: Array<ITabItem>
  selectedValue?: string | number
  onChange: (event: any, type: any) => void
}

const useTabStyles = makeStyles(theme => ({
  /* Styles applied to the root element. */
  root: {
    ...theme.typography.button,
    minWidth: "auto",
    minHeight: 40,
    padding: "6px 0",
    marginRight: theme.spacing(2),
    // overwrite some defaults for media query to keep width small
    [theme.breakpoints.up("sm")]: {
      padding: "inherit",
      minWidth: "auto",
      marginRight: theme.spacing(3)
    },
    "&:last-child": {
      marginRight: 0
    }
  }
}))

const useTabsStyles = makeStyles(theme => ({
  root: {
    minHeight: 40
  }
}))

export const dataTestid = "actiontabs"
const ActionTabs: React.FunctionComponent<IActionTabsProps> = ({
  tabs,
  selectedValue,
  onChange
}) => {
  const classes = useTabStyles()
  const tabsClasses = useTabsStyles()

  return (
    <Tabs
      className={tabsClasses.root}
      onChange={onChange}
      scrollButtons="auto"
      value={some(tabs, { value: selectedValue }) ? selectedValue : false}
      variant="scrollable"
      data-testid={dataTestid}
    >
      {tabs.map(tab => (
        <Tab
          key={tab.value}
          label={tab.label}
          value={tab.value}
          className={classes.root}
          textColor="primary"
        />
      ))}
    </Tabs>
  )
}

export default ActionTabs
