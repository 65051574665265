import { Typography, TypographyProps } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

type SnackbarContentProps = TypographyProps & {
  message: JSX.Element | string
}

const useStyles = makeStyles(theme => ({
  root: { color: "inherit" }
}))

const SnackbarContent: React.FunctionComponent<SnackbarContentProps> = ({
  message,
  ...props
}) => {
  const classes = useStyles()
  return (
    <Typography variant="body1" className={classes.root} {...props}>
      {message}
    </Typography>
  )
}

export default SnackbarContent
