const overrides = (palette, typography) => ({
  root: {
    color: palette.action.selected,
    "&$shrink": {
      color: palette.text.secondary
    },
    "&$disabled": {
      color: palette.action.disabled
    }
  }
})

export default overrides
