import { useQuery } from "@apollo/client"
import {
  GetUpdateAvailableQuery,
  GetUpdateAvailableQueryVariables
} from "client/types"
import { GET_UPDATE_AVAILABLE } from "queries/appState"

export const useUpdateAvailable = (): boolean => {
  const { data } = useQuery<
    GetUpdateAvailableQuery,
    GetUpdateAvailableQueryVariables
  >(GET_UPDATE_AVAILABLE)
  return data?.appVars?.updateAvailable ?? false
}

export default useUpdateAvailable
