import { useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { MuiPickersContext } from "@material-ui/pickers"
import { MomentInput } from "moment"
import toPairs from "lodash/toPairs"
import sortBy from "lodash/sortBy"
import findLast from "lodash/findLast"

import Label, { LabelColors } from "./Label"

const useStyles = makeStyles(theme => ({
  root: {
    textTransform: "lowercase"
  }
}))

export interface IFromNowLabelProps {
  date: any
}

// Color scale
const dayColor = {
  0: LabelColors.Blue,
  1: LabelColors.Red,
  7: LabelColors.Orange,
  28: LabelColors.Gold
}

const sortedColors = sortBy(toPairs(dayColor), c => parseInt(c[0])).reverse()

const dayMin = 1440

const findColor = (minutes: Number) => {
  const found = findLast(sortedColors, (c: Array<any>) => {
    const min = c[0] * dayMin
    return min > minutes
  })
  return found ? found[1] : LabelColors.Grey
}

const FromNowLabel: React.FunctionComponent<IFromNowLabelProps> = ({
  date
}) => {
  const dateUtil = useContext(MuiPickersContext)
  const classes = useStyles()

  if (!date || !dateUtil) {
    return <></>
  }

  const currentDate = dateUtil.date()
  const targetDate = dateUtil.date(date)
  const diff = targetDate?.diff(currentDate as MomentInput, "minutes") ?? 0

  let label: null | string = null
  let color: null | string = null
  if (diff <= -dayMin) {
    // Nothing to return if time has past
    return <></>
  } else if (diff <= 0) {
    label = "Now"
    color = dayColor[0]
  } else {
    const days = targetDate?.diff(currentDate as MomentInput, "days") ?? 0
    const targetHours = targetDate?.diff(currentDate as MomentInput, "hours")
    const hours = (targetHours && targetHours - days * 24) ?? 0
    label = `${days}d ${hours}h`
    color = findColor(diff)
  }

  return (
    // @ts-ignore
    <Label color={color} classes={classes}>
      {label}
    </Label>
  )
}

export default FromNowLabel
