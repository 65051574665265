// vim: foldmethod=marker:foldmarker={{{,}}}
// imports {{{
import { withMobileDialog } from "@material-ui/core"
import { Fragment, useCallback, useState } from "react"
import BaseConfirmationDialog from "./ConfirmationDialog"
import ConfirmContext from "./ConfirmContext"
// imports }}}

// ConfirmationDialog {{{
const ConfirmationDialog = withMobileDialog({
  breakpoint: "xs"
})(BaseConfirmationDialog)
// ConfirmationDialog }}}

// DEFAULT_OPTIONS {{{
const DEFAULT_OPTIONS = {
  title: "Are you sure?",
  description: "",
  confirmationText: "Confirm",
  dialogProps: {},
  confirmationButtonProps: {},
  cancellationButtonProps: {}
}
// DEFAULT_OPTIONS }}}

// buildOptions {{{
const buildOptions = (defaultOptions, options) => {
  const dialogProps = {
    ...(defaultOptions.dialogProps || DEFAULT_OPTIONS.dialogProps),
    ...(options.dialogProps || {})
  }
  const confirmationButtonProps = {
    ...(defaultOptions.confirmationButtonProps ||
      DEFAULT_OPTIONS.confirmationButtonProps),
    ...(options.confirmationButtonProps || {})
  }
  const cancellationButtonProps = {
    ...(defaultOptions.cancellationButtonProps ||
      DEFAULT_OPTIONS.cancellationButtonProps),
    ...(options.cancellationButtonProps || {})
  }

  return {
    ...DEFAULT_OPTIONS,
    ...defaultOptions,
    ...options,
    dialogProps,
    confirmationButtonProps,
    cancellationButtonProps
  }
}
// buildOptions }}}

// ConfirmProvider {{{
const ConfirmProvider = ({ children, defaultOptions = {} }) => {
  const [options, setOptions] = useState({
    ...DEFAULT_OPTIONS,
    ...defaultOptions
  })
  const [resolveReject, setResolveReject] = useState([])
  const [resolve, reject] = resolveReject

  const confirm = useCallback(
    (options = {}) => {
      return new Promise((resolve, reject) => {
        setOptions(buildOptions(defaultOptions, options))
        setResolveReject([resolve, reject])
      })
    },
    [defaultOptions]
  )

  const handleClose = useCallback(() => {
    setResolveReject([])
  }, [])

  const handleCancel = useCallback(() => {
    reject()
    handleClose()
  }, [reject, handleClose])

  const handleConfirm = useCallback(() => {
    resolve()
    handleClose()
  }, [resolve, handleClose])

  return (
    <Fragment>
      <ConfirmContext.Provider value={confirm}>
        {children}
      </ConfirmContext.Provider>
      <ConfirmationDialog
        open={resolveReject.length === 2}
        options={options}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </Fragment>
  )
}
// ConfirmProvider }}}

export default ConfirmProvider
