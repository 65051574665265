import { Grid } from "@material-ui/core"
import { Form, useFormikContext } from "formik"

import { WellInput } from "client/types"
import FormFactory from "components/FormFactory"
import useInitialFormikValidation from "hooks/forms/useInitialFormikValidation"

import formConfigs from "./config"

interface ICreateWellFormProps {
  initialValues?: WellInput
}

const CreateWellForm: React.FunctionComponent<ICreateWellFormProps> = ({
  initialValues
}) => {
  const formik = useFormikContext<WellInput>()
  useInitialFormikValidation({ formik })
  return (
    <Form id="create-well-form">
      <Grid container spacing={2}>
        <FormFactory formConfigs={formConfigs} formik={formik} />
      </Grid>
    </Form>
  )
}

export default CreateWellForm
