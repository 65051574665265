import clsx from "clsx"
import { makeStyles } from "@material-ui/styles"
import { Drawer, Theme } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import DeleteIcon from "@material-ui/icons/DeleteOutlined"
import intersection from "lodash/intersection"
import keys from "lodash/keys"
import reduce from "lodash/reduce"

import FormFactory, { FormConfig } from "../FormFactory"
import { Form, useFormikContext } from "formik"
import useFormState, { FormStateProvider } from "hooks/forms/useFormState"
import { ButtonBase as Button, TitanButtonColor } from "components"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  drawer: {
    width: 420,
    maxWidth: "100%"
  },
  header: {
    padding: theme.spacing(2, 3),
    display: "flex",
    justifyContent: "space-between"
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer"
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0)
  },
  fieldGroup: {
    display: "flex",
    alignItems: "center"
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  flexGrow: {
    flexGrow: 1
  },
  addButton: {
    marginLeft: theme.spacing(1)
  },
  tags: {
    marginTop: theme.spacing(1)
  },
  minAmount: {
    marginRight: theme.spacing(3)
  },
  maxAmount: {
    marginLeft: theme.spacing(3)
  },
  radioGroup: {},
  actions: {
    padding: theme.spacing(3),
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}))
export interface FilterDrawerProps {
  className?: string
  onClose?: () => any
  onFilter?: (any) => any
  open: boolean
  filterConfig: FormConfig
  clearableFilterPaths: Array<string>
}

const FilterDrawer: React.FunctionComponent<FilterDrawerProps> = ({
  open,
  onClose,
  onFilter,
  className,
  filterConfig,
  clearableFilterPaths,
  ...rest
}) => {
  const classes = useStyles()
  const formik = useFormikContext()
  const formState = useFormState()

  const handleClear = () => {
    const keysToClear = intersection(clearableFilterPaths, keys(formik.values))
    const newValues = reduce(
      keysToClear,
      (acc, path) => {
        // @ts-ignore
        const value = formik.values?.[path]
        return Array.isArray(value)
          ? { ...acc, [path]: [] }
          : { ...acc, [path]: null }
      },
      {}
    )
    formik.setValues(newValues, false)
  }

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <FormStateProvider value={formState}>
        <Form {...rest} className={clsx(classes.root, className)}>
          <div className={classes.header}>
            <Button onClick={onClose} size="small" startIcon={<CloseIcon />}>
              Close
            </Button>
          </div>
          <div className={classes.content}>
            <FormFactory formConfigs={filterConfig} formik={formik} />
          </div>
          <div className={classes.actions}>
            <Button fullWidth onClick={handleClear} startIcon={<DeleteIcon />}>
              Clear
            </Button>
            <Button
              color={TitanButtonColor.Blue}
              fullWidth
              type="submit"
              variant="contained"
            >
              Apply Filters
            </Button>
          </div>
        </Form>
      </FormStateProvider>
    </Drawer>
  )
}

export default FilterDrawer
