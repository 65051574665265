import { useCallback, useEffect, useState } from "react"
// Handle authentication state so we can hide interactive UI until a user is signed in
import Auth from "@aws-amplify/auth"
import { Hub } from "@aws-amplify/core"
import awsconfig from "awsconfig"

import { AuthorizationType } from "client/types"
import { updateAppVars } from "client/localVars"
import { useOnlineContext } from "context/OnlineContext"
import { UserProvider } from "../context/user"
import MessagePage from "./Page/MessagePage"

export const Constants = {
  SIGNING_IN_WITH_HOSTEDUI_KEY: "badger-hosted-ui-sign-in",
  AUTHENTICATOR_AUTHSTATE: "badger-authenticator-authState"
}

type AuthenticatorProps = {
  onStateChange?: Function
}

interface StateAction {
  currentState?: string
  authData: object | null
  error: string | null
}

const Authenticator: React.FC<AuthenticatorProps> = ({ onStateChange }) => {
  const { cachePersisted, onlineChecked } = useOnlineContext()

  const [authState, setAuthState] = useState<StateAction>({
    currentState: "loading",
    authData: null,
    error: null
  })

  const handleStateChange = useCallback(
    (newState: string, data: object | null) => {
      if (newState === authState.currentState) {
        return
      }

      if (newState === "signedOut") {
        newState = "signIn"
      }

      try {
        localStorage.setItem(Constants.AUTHENTICATOR_AUTHSTATE, newState)
      } catch (e) {
        console.debug("Failed to set the auth state into local storage", e)
      }

      setAuthState({
        currentState: newState,
        authData: data,
        error: null
        // showToast: false,
      })

      if (onStateChange) {
        onStateChange(newState, data)
      }
    },
    [authState, onStateChange]
  )

  const checkUser = useCallback(() => {
    return Auth.currentAuthenticatedUser()
      .then(user => {
        handleStateChange("signedIn", user)
      })
      .catch(() => {
        Auth.federatedSignIn()
      })
  }, [handleStateChange])

  const authListener = useCallback(
    ({ channel, payload }) => {
      if (channel !== "auth") return
      switch (payload.event) {
        case "cognitoHostedUI":
          handleStateChange("signedIn", payload.data)
          break
        case "cognitoHostedUI_failure":
          handleStateChange("signIn", null)
          break
        case "parsingUrl_failure":
          handleStateChange("signIn", null)
          break
        case "signOut":
          handleStateChange("signIn", null)
          break
        case "customGreetingSignOut":
          handleStateChange("signIn", null)
          break
        case "parsingCallbackUrl":
          // set that we've used the callback from a hosted ui process
          localStorage.setItem(Constants.SIGNING_IN_WITH_HOSTEDUI_KEY, "true")
          break
        default:
          break
      }
    },
    [handleStateChange]
  )

  useEffect(() => {
    // Listen for auth events
    Hub.listen("auth", authListener)

    // These actions are only performed when mounted
    Auth.configure(awsconfig)

    // the workaround for Cognito Hosted UI
    // don't check the user immediately if redirected back from Hosted UI
    // instead waiting for the hub event sent from Auth module
    // the item in the localStorage is a mark to indicate whether
    // the app is redirected back from Hosted UI or not
    const byHostedUI = localStorage.getItem(
      Constants.SIGNING_IN_WITH_HOSTEDUI_KEY
    )
    localStorage.removeItem(Constants.SIGNING_IN_WITH_HOSTEDUI_KEY)

    if (byHostedUI) {
      checkUser()
    }

    return () => {
      // Stop listening if we are unmounted
      Hub.remove("auth", authListener)
    }
  }, [authState, authListener, checkUser])

  // Set/unset the authorization method so the apollo link chain knows which
  // headers to add to requests
  useEffect(() => {
    updateAppVars({ authorization: AuthorizationType.Cognito })

    return () => {
      updateAppVars({ authorization: AuthorizationType.None })
    }
  }, [])

  let content: JSX.Element
  // After a hard reload, there will be an active service worker but no
  // controller. To avoid a UI flash as the page reloads, keep the loading
  // message up in production if we don't have a controller until the
  // page reload triggers. This isn't as much of an issue on iPads/mobile;
  // there's not a really a way to hard reload a page.
  if (
    !cachePersisted ||
    !onlineChecked ||
    (process.env.NODE_ENV === "production" &&
      (navigator.serviceWorker?.controller ?? null) === null)
  ) {
    content = <MessagePage title="Loading..." />
  } else if (authState.currentState === "signedIn") {
    content = <UserProvider />
  } else {
    content = <MessagePage title="Authenticating" />
  }

  return content
}

export default Authenticator
