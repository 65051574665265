import { Divider, Link, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { Page } from "components"
import AtlasTitanLogoLarge from "components/icons/AtlasPressureControlLogoLarge"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100vh",
    margin: theme.spacing(-1, 0)
  },
  atlasLogo: {
    height: 54,
    marginBottom: theme.spacing(3)
  },
  divider: {
    marginBottom: theme.spacing(3)
  },
  text: {
    marginBottom: theme.spacing(3)
  },
  logo: {
    color:
      "light" === theme.palette.type
        ? // @ts-ignore
          theme.palette.atlasMaroon
        : theme.palette.text.primary,
    height: 54,
    width: 175
  }
}))

interface IAccessDeniedProps {}

const AccessDenied: React.FunctionComponent<IAccessDeniedProps> = props => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Page title="Access Denied" maxWidth="md">
        <div className={classes.atlasLogo}>
          <AtlasTitanLogoLarge className={classes.logo} />
        </div>
        <Divider className={classes.divider} />
        <div className={classes.text}>
          <Typography variant="h4">
            This page doesn't exist or has restricted access.
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <Typography variant="body1">
          We're here to help. Contact us at{" "}
          <Link
            href="mailto:support@atlaswhd.com"
            underline="none"
            color="primary"
          >
            support@atlaswhd.com
          </Link>
          .
        </Typography>
      </Page>
    </div>
  )
}

export default AccessDenied
