import { lazy } from "react"
import { Navigate, RouteObject } from "react-router-dom"

import Authenticator from "components/Authenticator"
import GuestAccess from "components/GuestAccess"
import {
  BlankLayout,
  BlankThemedLayout,
  MainLayout,
  PrintLayout,
  ReviewLayout
} from "layouts"
import LogoutView from "views/AuthViews/LogoutView"
import CallbackView from "views/AuthViews/CallbackView"

import { NavBarType } from "views/print/common/PrintNavBar"
import { PrintParamType } from "views/print/utils"

const LazyCompanyDetail = lazy(
  () => import(/* webpackChunkName: "company" */ "views/CompanyDetail")
)
const LazyCompanyList = lazy(
  () => import(/* webpackChunkName: "company" */ "views/CompanyList")
)
const LazyCustomerSignature = lazy(
  () => import(/* webpackChunkName: "ticket" */ "views/CustomerSignature")
)
const LazyDashboard = lazy(
  () => import(/* webpackChunkName: "dashboard" */ "views/Dashboard/Dashboard")
)
const LazyDashboardNotifications = lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard" */ "views/Dashboard/DashboardNotificationList"
    )
)
const LazyDashboardTickets = lazy(
  () =>
    import(/* webpackChunkName: "dashboard" */ "views/Dashboard/UserTicketList")
)
const LazyExcelViewer = lazy(
  () => import(/* webpackChunkName: "offline-xlsx" */ "views/Excel/ExcelViewer")
)
const LazyImageViewer = lazy(
  () =>
    import(/* webpackChunkName: "offline-image" */ "views/Image/ImageViewer")
)
const LazyInventoryList = lazy(
  () => import(/* webpackChunkName: "inventory" */ "views/InventoryList")
)
const LazyObjectPrint = lazy(
  () => import(/* webpackChunkName: "print" */ "views/print/ObjectPrint")
)
const LazyPartsList = lazy(
  () =>
    import(/* webpackChunkName: "inventory" */ "views/InventoryList/PartsList")
)
const LazyParttypeDetails = lazy(
  () => import(/* webpackChunkName: "part_types" */ "views/PartTypeDetails")
)
const LazyPartTypeList = lazy(
  () => import(/* webpackChunkName: "part_types" */ "views/PartTypeList")
)
const LazyPDFViewer = lazy(
  () => import(/* webpackChunkName: "pdf" */ "views/PDF/PDFViewer")
)
const LazyProductsList = lazy(
  () =>
    import(
      /* webpackChunkName: "inventory" */ "views/InventoryList/ProductList"
    )
)
const LazyReview = lazy(
  () => import(/* webpackChunkName: "review" */ "views/Review")
)
const LazyRPDetails = lazy(
  () =>
    import(/* webpackChunkName: "procedures" */ "views/RunningProcedureDetails")
)
const LazyRPList = lazy(
  () =>
    import(
      /* webpackChunkName: "procedures" */ "views/RunningProcedureList/RunningProcedureList"
    )
)
const LazySalesOrderDetails = lazy(
  () => import(/* webpackChunkName: "salesorder" */ "views/SalesOrderDetails")
)
const LazySalesOrderList = lazy(
  () => import(/* webpackChunkName: "salesorder" */ "views/SalesOrderList")
)
const LazyServicesList = lazy(
  () =>
    import(
      /* webpackChunkName: "inventory" */ "views/InventoryList/ServiceList"
    )
)
const LazySignaturePrint = lazy(
  () => import(/* webpackChunkName: "print" */ "views/print/SignaturePrint")
)
const LazySupport = lazy(
  () => import(/* webpackChunkName: "support" */ "views/Support/Support")
)
const LazyTicketDetails = lazy(
  () => import(/* webpackChunkName: "ticket" */ "views/TicketDetails")
)
const LazyTicketDownload = lazy(
  () => import(/* webpackChunkName: "ticket" */ "views/TicketDownload")
)
const LazyTicketList = lazy(
  () =>
    import(/* webpackChunkName: "ticket" */ "views/TicketList/TicketListSwitch")
)
const LazyUserDetails = lazy(
  () => import(/* webpackChunkName: "users" */ "views/user/UserDetail")
)
const LazyUserList = lazy(
  () => import(/* webpackChunkName: "users" */ "views/user/UserList")
)
const LazyWellDetails = lazy(
  () => import(/* webpackChunkName: "well" */ "views/WellDetails")
)
const LazyWellList = lazy(
  () => import(/* webpackChunkName: "well" */ "views/WellList")
)

const LazyAttachmentsGuide = lazy(
  () => import(/* webpackChunkName: "dev" */ "views/style_guide/Attachments")
)
const LazyButtonsGuide = lazy(
  () => import(/* webpackChunkName: "dev" */ "views/style_guide/Buttons")
)
const LazyCardsGuide = lazy(
  () => import(/* webpackChunkName: "dev" */ "views/style_guide/Cards")
)
const LazyChipsGuide = lazy(
  () => import(/* webpackChunkName: "dev" */ "views/style_guide/Chips")
)
const LazyLabelsGuide = lazy(
  () => import(/* webpackChunkName: "dev" */ "views/style_guide/Labels")
)
const LazyListsGuide = lazy(
  () => import(/* webpackChunkName: "dev" */ "views/style_guide/Lists")
)
const LazyMiscGuide = lazy(
  () => import(/* webpackChunkName: "dev" */ "views/style_guide/Misc")
)
const LazyPagesGuide = lazy(
  () => import(/* webpackChunkName: "dev" */ "views/style_guide/PageElements")
)
const LazyTypographyGuide = lazy(
  () => import(/* webpackChunkName: "dev" */ "views/style_guide/Typography")
)

let extra: Array<RouteObject> = []
// development routes
if (process.env.NODE_ENV === "development") {
  extra = [
    // Development style_guide routes
    {
      path: "components/attachments",
      element: <LazyAttachmentsGuide />
    },
    {
      path: "components/buttons",
      element: <LazyButtonsGuide />
    },
    {
      path: "components/cards",
      element: <LazyCardsGuide />
    },
    {
      path: "components/chips",
      element: <LazyChipsGuide />
    },
    {
      path: "components/labels",
      element: <LazyLabelsGuide />
    },
    {
      path: "components/lists",
      element: <LazyListsGuide />
    },
    {
      path: "components/misc",
      element: <LazyMiscGuide />
    },
    {
      path: "components/pages",
      element: <LazyPagesGuide />
    },
    {
      path: "components/typography",
      element: <LazyTypographyGuide />
    }
  ]
}

// All authenticator components should wrap their output in <UserProvider /> before
// rendering the routes. To edit/update front-end enforcement of user group access, look in
// src/context/user/**
const routes: Array<RouteObject> = [
  {
    path: "/",
    element: <Navigate replace to="/tickets" />
  },
  // Un-authenticated views for lambda usage. This may need to go away or be changed to be
  // more secure.
  {
    path: "/lambda/print/",
    element: <PrintLayout />,
    children: [
      {
        path: "signatures/:eventId",
        element: <LazySignaturePrint />
      },
      {
        path: ":type/:number",
        element: (
          <LazyObjectPrint
            paramType={PrintParamType.ObjectNumber}
            navType={NavBarType.Print}
          />
        )
      }
    ]
  },
  // Query-string token views. These are authed via jwt tokens.
  {
    path: "/customer/",
    element: <GuestAccess />,
    children: [
      {
        path: "print/",
        element: <PrintLayout />,
        children: [
          {
            path: ":type/:number",
            element: (
              <LazyObjectPrint
                paramType={PrintParamType.EventId}
                navType={NavBarType.Print}
                isCustomerView
              />
            )
          }
        ]
      },
      {
        path: "review/",
        element: <ReviewLayout />,
        children: [
          {
            path: ":eventId",
            element: <LazyReview isCustomerView />
          }
        ]
      },
      {
        path: "sign/",
        element: <BlankThemedLayout />,
        children: [
          {
            path: ":eventId",
            element: <LazyCustomerSignature />
          }
        ]
      }
    ]
  },
  // Authenticated routes
  // These are all wrapped in the Authenticator component and provide the user via context.
  // Also will force authentication if not logged in and handles the login cycle from cognito.
  {
    path: "/*",
    element: <Authenticator />,
    children: [
      {
        path: "callback",
        element: <CallbackView />
      },
      {
        path: "image/",
        element: <BlankLayout />,
        children: [
          {
            path: ":id",
            element: <LazyImageViewer />
          }
        ]
      },
      {
        path: "logout",
        element: <LogoutView />
      },
      {
        path: "pdf/",
        element: <BlankLayout />,
        children: [
          {
            path: ":id",
            element: <LazyPDFViewer />
          }
        ]
      },
      {
        path: "review/",
        element: <ReviewLayout />,
        children: [
          {
            path: ":eventId",
            element: <LazyReview isCustomerView={false} />
          }
        ]
      },
      {
        path: "preview/",
        element: <PrintLayout />,
        children: [
          {
            path: ":type/:number",
            element: (
              <LazyObjectPrint
                navType={NavBarType.SignaturePreview}
                paramType={PrintParamType.ObjectNumber}
              />
            )
          }
        ]
      },
      {
        path: "print/",
        element: <PrintLayout />,
        children: [
          {
            path: ":type/:number",
            element: (
              <LazyObjectPrint
                navType={NavBarType.Print}
                paramType={PrintParamType.ObjectNumber}
              />
            )
          }
        ]
      },
      {
        path: "xlsx/",
        element: <BlankLayout />,
        children: [
          {
            path: ":id",
            element: <LazyExcelViewer />
          }
        ]
      },
      {
        path: "",
        element: <MainLayout />,
        children: [
          // Dashboards
          {
            path: "dashboard",
            element: <LazyDashboard />
          },
          {
            path: "dashboard/tickets",
            element: <LazyDashboardTickets />
          },
          {
            path: "dashboard/notifications",
            element: <LazyDashboardNotifications />
          },
          // Companies
          {
            path: "companies",
            element: <LazyCompanyList />
          },
          {
            path: "companies/:id/",
            element: <LazyCompanyDetail />
          },
          {
            path: "companies/:id/:tab",
            element: <LazyCompanyDetail />
          },
          // Tickets
          {
            path: "tickets",
            element: <LazyTicketList />
          },
          {
            path: "tickets/:number",
            element: <LazyTicketDetails />
          },
          {
            path: "tickets/:number/download",
            element: <LazyTicketDownload />
          },
          {
            path: "tickets/:number/:tab",
            element: <LazyTicketDetails />
          },
          // Sales Orders
          {
            path: "sales-orders",
            element: <LazySalesOrderList />
          },
          {
            path: "sales-orders/:number",
            element: <LazySalesOrderDetails />
          },
          {
            path: "sales-orders/:number/:tab",
            element: <LazySalesOrderDetails />
          },
          // Wells
          {
            path: "wells",
            element: <LazyWellList />
          },
          {
            path: "wells/:wellId",
            element: <LazyWellDetails />
          },
          {
            path: "wells/:wellId/:tab",
            element: <LazyWellDetails />
          },
          // Inventory
          {
            path: "inventory",
            element: <LazyInventoryList />
          },
          {
            path: "parts",
            element: <LazyPartsList />
          },
          {
            path: "services",
            element: <LazyServicesList />
          },
          {
            path: "products",
            element: <LazyProductsList />
          },
          // Part Types
          {
            path: "part-types",
            element: <LazyPartTypeList />
          },
          {
            path: "part-types/:partTypeId",
            element: <LazyParttypeDetails />
          },
          {
            path: "part-types/:partTypeId/:tab",
            element: <LazyParttypeDetails />
          },
          // Running Procedures
          {
            path: "running-procedures",
            element: <LazyRPList />
          },
          {
            path: "running-procedures/:procedureId",
            element: <LazyRPDetails />
          },
          {
            path: "running-procedures/:procedureId/:tab",
            element: <LazyRPDetails />
          },
          // Users
          {
            path: "users",
            element: <LazyUserList />
          },
          {
            path: "users/:userId",
            element: <LazyUserDetails />
          },
          {
            path: "users/:userId/:tab",
            element: <LazyUserDetails />
          },
          // Application Support
          {
            path: "support",
            element: <LazySupport />
          },
          {
            path: "support/:tab",
            element: <LazySupport />
          },
          ...extra
        ]
      }
    ]
  }
]

export default routes
