const overrides = (palette, typography) => ({
  root: {
    border: `1px solid ${palette.table.border}`,
    backgroundColor: palette.background.paper,
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: palette.action.disabledBackground
    },
    "& .MuiDataGrid-row": {
      "&:hover": {
        backgroundColor: palette.background.default
      }
    },
    "& .MuiDataGrid-cell": {
      borderBottom: `1px solid ${palette.table.border}`
    },
    "& .MuiDataGrid-footerContainer": {
      minHeight: 32
    }
  }
})

export default overrides
