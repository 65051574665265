import { gql } from "@apollo/client"
import documentFragments from "fragments/documents"
import payloadFragments from "fragments/payload"
import signatureFragments from "fragments/signatures"

// This should be identical to ADD_DOCUMENT, but it's given a different name
// so that differences in handling general attachments vs. signatures are
// easier to handle in offline mode.
export const ADD_SIGNATURE = gql`
  mutation addSignature($input: AddDocumentInput!) {
    documentData: addDocument(input: $input) {
      ...BasePayload
      document: object {
        ... on PartialDocument {
          uuid
          type
          label
        }
        ... on Document {
          ...BaseDocument
          versionId
        }
      }
    }
  }
  ${payloadFragments.payloadFragment}
  ${documentFragments.baseDocument}
`

export const CREATE_SIGNATURE_RESPONSE = gql`
  mutation createSignatureResponse(
    $input: SignatureResponseInput!
    $id: ID!
    $keys: ContentTypeKeys!
    $requestId: ID
  ) {
    signatureData: mutateNodeByKey(nodeId: $id, keys: $keys) {
      response: createSignatureResponse(requestId: $requestId, input: $input) {
        ...BasePayload
        signature: object {
          ...ListSignatureResponse
        }
        event {
          id
        }
      }
    }
  }
  ${payloadFragments.payloadFragment}
  ${signatureFragments.listSignatureResponse}
`

// The back end creates a phantom request for rejections, if there isn't
// already a request, because the rejection info gets attached to the
// SignatureRequest object instead of being a separate response.
export const REJECT_SIGNATURE_RESPONSE = gql`
  mutation rejectSignatureResponse(
    $input: RejectSignatureResponseInput!
    $id: ID!
    $keys: ContentTypeKeys!
    $requestId: ID
  ) {
    signatureData: mutateNodeByKey(nodeId: $id, keys: $keys) {
      response: rejectSignatureResponse(requestId: $requestId, input: $input) {
        ...BasePayload
        signature: object {
          ...ListSignatureResponse
        }
        event {
          id
        }
      }
    }
  }
  ${payloadFragments.payloadFragment}
  ${signatureFragments.listSignatureResponse}
`

export const CREATE_SIGNATURE_REQUEST = gql`
  mutation createSignatureRequest(
    $input: SignatureRequestInput!
    $id: ID!
    $keys: ContentTypeKeys!
  ) {
    mutateNode: mutateNodeByKey(nodeId: $id, keys: $keys) {
      data: createSignatureRequest(input: $input) {
        ...BasePayload
        request: object {
          ...ListSignatureRequest
          object {
            ... on Node {
              id
            }
            ... on StatusableTicket {
              status
            }
            ... on SalesOrder {
              soStatus: status
            }
            ... on Signable {
              signatureState
            }
          }
        }
      }
    }
  }
  ${payloadFragments.payloadFragment}
  ${signatureFragments.listSignatureRequest}
`

export const RESEND_SIGNATURE_REQUEST = gql`
  mutation resendSignatureRequest($requestId: ID!) {
    data: resendSignatureRequest(id: $requestId) {
      ...BasePayload
      request: object {
        ...ListSignatureRequest
      }
    }
  }
  ${payloadFragments.payloadFragment}
  ${signatureFragments.listSignatureRequest}
`

export const CANCEL_SIGNATURE_REQUEST = gql`
  mutation cancelSignatureRequest($requestId: ID!) {
    data: cancelSignatureRequest(id: $requestId) {
      ...BasePayload
      request: object {
        ...ListSignatureRequest
      }
    }
  }
  ${payloadFragments.payloadFragment}
  ${signatureFragments.listSignatureRequest}
`
