import { gql } from "@apollo/client"

export const GET_ALL_SETTINGS = gql`
  query getSettings {
    userSettings @client {
      darkModeSet
      darkMode
    }
  }
`

export const GET_DARK_MODE = gql`
  query determineDarkMode {
    userSettings @client {
      darkModeSet
      darkMode
    }
  }
`

export const CHANGE_USER_SETTINGS = gql`
  mutation ChangeUserSettings($input: UserSettingsInput!) {
    updateUserSettings(input: $input) @client {
      darkModeSet
      darkMode
    }
  }
`
