import { TextField } from "@material-ui/core"
import { useFormikContext } from "formik"

interface ICompanyInputProps {
  id?: string
  name: string
  label: string
}

const CompanyInput: React.FunctionComponent<ICompanyInputProps> = ({
  id,
  name,
  label
}) => {
  const formik = useFormikContext<any>()

  /* If we're writing custom components that needs to sync w/ other
   * parts of the form data model, I think hard-coding the data paths
   * is acceptable, because synchronizing data from two parts of a
   * model probably always needs to be looked at */
  return (
    <TextField
      id={id}
      value={
        formik.values?.[name]?.name ??
        formik.values?.createWell?.company?.name ??
        ""
      }
      variant="outlined"
      label={label}
      fullWidth
      disabled
    />
  )
}

export default CompanyInput
