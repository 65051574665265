import { IUtils } from "@date-io/core/IUtils"
import MomentUtils from "@date-io/moment"
import Grid from "@material-ui/core/Grid"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { DateTimePickerView } from "@material-ui/pickers/DateTimePicker"
import DateTimePickerTabs from "@material-ui/pickers/DateTimePicker/DateTimePickerTabs"
import { ToolbarComponentProps } from "@material-ui/pickers/Picker/Picker"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import {
  convertToMeridiem,
  getMeridiem
} from "@material-ui/pickers/_helpers/time-utils"
import PickerToolbar from "@material-ui/pickers/_shared/PickerToolbar"
import ToolbarButton from "@material-ui/pickers/_shared/ToolbarButton"
// import * as React from 'react';
// import Grid from '@material-ui/core/Grid';
import ToolbarText from "@material-ui/pickers/_shared/ToolbarText"
import TimezoneContext from "context/TimezoneContext"
import * as React from "react"
import { Label, LabelColors } from "components"

export const useStyles = makeStyles(
  _ => ({
    toolbar: {
      paddingLeft: 16,
      paddingRight: 16,
      justifyContent: "space-around"
    },
    tzLabel: {
      fontWeight: "bold",
      marginLeft: 8,
      marginBottom: 5
    },
    separator: {
      margin: "0 4px 0 2px",
      cursor: "default"
    }
  }),
  { name: "MuiPickerDTToolbar" }
)

export function useMeridiemMode(
  date: MaterialUiPickersDate,
  ampm: boolean | undefined,
  onChange: (
    date: MaterialUiPickersDate,
    isFinished?: boolean | undefined
  ) => void,
  utils: IUtils<MaterialUiPickersDate>
) {
  const meridiemMode = getMeridiem(date, utils)

  const handleMeridiemChange = React.useCallback(
    (mode: "am" | "pm") => {
      const timeWithMeridiem = convertToMeridiem(
        date,
        mode,
        Boolean(ampm),
        utils
      )
      onChange(timeWithMeridiem, false)
    },
    [ampm, date, onChange, utils]
  )

  return { meridiemMode, handleMeridiemChange }
}

export const DateTimePickerToolbar: React.FC<ToolbarComponentProps> = ({
  date: _date,
  openView,
  setOpenView,
  ampm,
  hideTabs,
  dateRangeIcon,
  timeIcon,
  onChange
}) => {
  const { currentTimezone, getTimezoneDisplay } = React.useContext(
    TimezoneContext
  )

  // At this point, the date is a moment object with its own stored timezone. Convert it
  // to the display timezone.
  const date = _date?.tz(currentTimezone) ?? _date

  const utils = new MomentUtils()
  const classes = useStyles()
  const showTabs =
    !hideTabs && typeof window !== "undefined" && window.innerHeight > 667
  const { meridiemMode, handleMeridiemChange } = useMeridiemMode(
    date,
    ampm,
    onChange,
    utils
  )
  const theme = useTheme()
  const rtl = theme.direction === "rtl"
  const tzName = getTimezoneDisplay(date)

  return (
    <>
      <PickerToolbar isLandscape={false} className={classes.toolbar}>
        <Grid container justifyContent="center" wrap="nowrap">
          <Grid
            item
            container
            xs={5}
            justifyContent="flex-start"
            direction="column"
          >
            <div>
              <ToolbarButton
                variant="subtitle1"
                onClick={() => setOpenView("year")}
                selected={openView === "year"}
                // @ts-ignore
                label={utils.getYearText(date)}
              />
            </div>
            <div>
              <ToolbarButton
                variant="h4"
                onClick={() => setOpenView("date")}
                selected={openView === "date"}
                // @ts-ignore
                label={utils.getDateTimePickerHeaderText(date)}
              />
            </div>
          </Grid>

          <Grid
            item
            container
            xs={6}
            justifyContent="center"
            alignItems="flex-end"
            direction={rtl ? "row-reverse" : "row"}
          >
            <ToolbarButton
              variant="h3"
              onClick={() => setOpenView("hours")}
              selected={openView === "hours"}
              // @ts-ignore
              label={utils.getHourText(date, ampm!)}
            />

            <ToolbarText variant="h3" label=":" className={classes.separator} />

            <ToolbarButton
              variant="h3"
              onClick={() => setOpenView("minutes")}
              selected={openView === "minutes"}
              // @ts-ignore
              label={utils.getMinuteText(date)}
            />
            {tzName !== null ? (
              <Label color={LabelColors.Black} className={classes.tzLabel}>
                {tzName}
              </Label>
            ) : null}
          </Grid>

          {ampm && (
            <Grid
              item
              container
              xs={1}
              direction="column"
              justifyContent="flex-end"
            >
              <ToolbarButton
                variant="subtitle1"
                selected={meridiemMode === "am"}
                label={utils.getMeridiemText("am")}
                onClick={() => handleMeridiemChange("am")}
              />

              <ToolbarButton
                variant="subtitle1"
                selected={meridiemMode === "pm"}
                label={utils.getMeridiemText("pm")}
                onClick={() => handleMeridiemChange("pm")}
              />
            </Grid>
          )}
        </Grid>
      </PickerToolbar>

      {showTabs && (
        <DateTimePickerTabs
          dateRangeIcon={dateRangeIcon}
          timeIcon={timeIcon}
          view={openView as DateTimePickerView}
          onChange={setOpenView}
        />
      )}
    </>
  )
}
