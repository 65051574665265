import { Fragment, useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import { useLocation } from "react-router-dom"
import clsx from "clsx"
import {
  Box,
  Divider,
  Drawer,
  Fab,
  Hidden,
  Theme,
  Typography
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import MenuIcon from "@material-ui/icons/Menu"

import * as colors from "theme/palettes/colors"
import { AppEnvironments } from "client/localVars"
import { GetAppVarsQuery, GetAppVarsQueryVariables } from "client/types"
import { GET_APP_VARS } from "queries/appState"

import AppUpdateButton, { UpdateMessageType } from "components/AppUpdateButton"
import AtlasTitanLogoLarge from "components/icons/AtlasTitanLogoLarge"
import createNavNode from "components/Navigation"
import { useOnlineContext } from "context/OnlineContext"

import useWaffle from "hooks/useWaffle"

import NavActions from "./NavActions"
import NavDebug from "./NavDebug"
import navConfig from "./navConfig"
import navConfigOffline from "./navConfigOffline"
import NavProfile from "./NavProfile"
import NavSync from "./NavSync"
import { useUpdateAvailable } from "hooks/update"

const labelColors = {
  [AppEnvironments.Local]: colors.green[500],
  [AppEnvironments.Staging]: colors.gold,
  [AppEnvironments.Production]: colors.red[500]
}

interface INavBarProps {
  className?: string
  onMobileClose?: (...args: any[]) => any
  openMobile?: boolean
}

const drawerWidth = 256
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
    borderRight: `1px solid ${colors.grey[600]}`
  },
  drawer: {
    width: drawerWidth
  },
  drawerPaper: {
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    width: drawerWidth
  },
  navigation: {
    overflow: "auto",
    padding: theme.spacing(0, 0, 2, 0),
    flexGrow: 1
  },
  header: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    color:
      "light" === theme.palette.type
        ? // @ts-ignore
          theme.palette.atlasMaroon
        : theme.palette.text.primary
  },
  atlasLogo: {
    fontSize: Math.floor(parseInt(String(theme.typography.h1.fontSize)) * 1.25)
  },
  atlasLogoSVG: {
    width: "3.55em"
  },
  envLabel: {
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.5),
    textAlign: "center"
  },
  title: {
    fontFamily: `"Arpona", ${theme.typography.h1.fontFamily}`,
    padding: theme.spacing(1)
  },
  menuToggle: {
    position: "fixed",
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    zIndex: theme.zIndex.speedDial
  }
}))

const NavBar: React.FunctionComponent<INavBarProps> = ({
  onMobileClose,
  className,
  ...rest
}) => {
  const classes = useStyles()
  const location = useLocation()
  const waffle = useWaffle()
  const { appOnline } = useOnlineContext()
  const [open, setOpen] = useState(false)

  const updateAvailable = useUpdateAvailable()
  const { data } = useQuery<GetAppVarsQuery, GetAppVarsQueryVariables>(
    GET_APP_VARS
  )
  const env = data?.appVars?.env ?? ""

  // When the pathname changes, close the menu.
  useEffect(() => {
    if (open) {
      setOpen(false)
    }
    // eslint-disable-next-line
  }, [location.pathname])

  const config = appOnline ? navConfig : navConfigOffline
  const labelColor = labelColors[env ?? AppEnvironments.Unknown]

  const content = (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.header}>
        <div className={classes.atlasLogo}>
          <AtlasTitanLogoLarge
            fontSize="inherit"
            className={classes.atlasLogoSVG}
          />
        </div>
        {AppEnvironments.Unknown !== env ? (
          <Typography
            className={classes.envLabel}
            variant="h4"
            component="div"
            style={{ backgroundColor: labelColor, color: "#fff" }}
          >
            <strong>{env}</strong>
          </Typography>
        ) : null}
      </div>
      <Divider variant="middle" />

      <nav className={classes.navigation}>
        {createNavNode(config, undefined, waffle)}
      </nav>
      {updateAvailable ? (
        <>
          <Divider />
          <Box m={2}>
            <AppUpdateButton fullWidth messageType={UpdateMessageType.Staff} />
          </Box>
        </>
      ) : null}
      <Divider />
      <NavDebug />
      <Divider />
      <NavProfile />
      <NavActions />
      {waffle.flags.allowSync || !appOnline ? (
        <>
          <Divider />
          <NavSync />
        </>
      ) : null}
    </div>
  )

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            root: classes.drawer,
            paper: classes.drawerPaper
          }}
          onClose={() => {
            setOpen(false)
          }}
          open={open}
          variant="temporary"
        >
          {content}
        </Drawer>
        <Fab
          color="secondary"
          className={classes.menuToggle}
          aria-label="menu"
          size="small"
          onClick={() => {
            setOpen(open => !open)
          }}
          data-testid="main-fab"
          style={{
            backgroundColor: labelColor
          }}
        >
          <MenuIcon />
        </Fab>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            root: classes.drawer,
            paper: classes.drawerPaper
          }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </Fragment>
  )
}

export default NavBar
