import { Avatar, AvatarProps, Tooltip, TooltipProps } from "@material-ui/core"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { User } from "client/types"
import clsx from "clsx"
import { memo } from "react"
import { getGravatarUrl, GravatarOptions } from "react-awesome-gravatar"
import { stringToColor } from "utils/color"
import getInitials from "utils/getInitials"

export type UserAvatarProps = Omit<AvatarProps, "alt" | "src" | "key"> & {
  key?: React.Key
  user: Partial<User>
  options?: GravatarOptions
  tooltipProps?: Omit<TooltipProps, "title" | "key" | "children">
}

interface customColorProps {
  color: string
}

const useStyles = makeStyles((theme: Theme) => ({
  customColor: (props: customColorProps) => ({
    color: theme.palette.getContrastText(props.color),
    // @ts-ignore
    backgroundColor: props.color
  })
}))

const UserAvatar: React.ComponentType<UserAvatarProps> = ({
  user,
  options = {
    rating: "g",
    default: "404" // 404 will allow avatar component to fall back on initials and color display
  },
  tooltipProps,
  key,
  className,
  ...props
}) => {
  const fullName = `${user.firstName} ${user.lastName}`
  const initials = getInitials(fullName)
  const color = stringToColor(fullName)
  const classes = useStyles({
    color
  })

  const avatarProps: AvatarProps = {
    alt: fullName,
    src: undefined
  }

  if (user.email) {
    avatarProps.src = getGravatarUrl(user.email, options)
  }

  return (
    <Tooltip key={key} title={fullName} {...tooltipProps}>
      <Avatar
        className={clsx(classes.customColor, className)}
        {...avatarProps}
        {...props}
      >
        {initials}
      </Avatar>
    </Tooltip>
  )
}

export default memo(UserAvatar)
