import { gql } from "@apollo/client"
import companyFragments from "./companies"
import chargeKitFragments from "./chargeKits"

const baseRunningProcedureStep = gql`
  fragment BaseRunningProcedureStep on RunningProcedureStep {
    id
    title
    description
    fieldType
    order
    pageNumber
    required
  }
`

const baseRunningProcedureTicketStepResult = gql`
  fragment BaseRunningProcedureTicketStepResult on RunningProcedureTicketStepResult {
    id
    attribute
    data
    completedOn
    runningProcedureStep {
      ...BaseRunningProcedureStep
    }
    creator: assignment(type: CREATED) {
      id
      fullName
      email
    }
    modified
  }
  ${baseRunningProcedureStep}
`

const baseRunningProcedureRevision = gql`
  fragment BaseRunningProcedureRevision on RunningProcedureRevision {
    id
    number
    steps {
      ...BaseRunningProcedureStep
    }
    requiredCharges {
      ...FullRequiredCharge
    }
  }
  ${chargeKitFragments.fullRequiredCharge}
  ${baseRunningProcedureStep}
`

const baseRunningProcedureVersion = gql`
  fragment BaseRunningProcedureVersion on RunningProcedureVersion {
    id
    number
    revisions {
      ...BaseRunningProcedureRevision
    }
  }
  ${baseRunningProcedureRevision}
`

const baseRunningProcedure = gql`
  fragment BaseRunningProcedure on RunningProcedure {
    id
    title
    description
    cabinetId
    company {
      ...CompanyOption
    }
    versions {
      ...BaseRunningProcedureVersion
    }
  }
  ${baseRunningProcedureVersion}
  ${companyFragments.companyOption}
`

const runningProcedureFragments = {
  baseRunningProcedureStep,
  baseRunningProcedureTicketStepResult,
  baseRunningProcedureRevision,
  baseRunningProcedureVersion,
  baseRunningProcedure,
  listRunningProcedure: gql`
    fragment ListRunningProcedure on RunningProcedure {
      id
      title
      description
      company {
        id
        name
      }
      versions {
        number
        revisions {
          number
        }
      }
    }
  `,
  runningProcedureOption: gql`
    fragment RunningProcedureOption on RunningProcedure {
      id
      title
      description
      company {
        id
        name
      }
    }
  `
}

export default runningProcedureFragments
