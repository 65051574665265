import { Event as SentryEvent } from "@sentry/react"
import Dexie from "dexie"
import { ConsoleFns } from "context/DebugContext"
import {
  AddDocumentInput,
  Cabinet,
  Charge,
  Company,
  ContentTypeKeys,
  Document,
  FullSalesOrderFragment,
  FullThumbnailFragment,
  FullTicketFragment,
  InventoryItem,
  ListSalesOrderFragmentFragment,
  ListTicketFragmentFragment,
  PriceRule,
  PrintSalesOrderDetailsFragment,
  RejectSignatureResponseInput,
  RunningProcedure,
  SalesOrder,
  Scalars,
  ServiceTicket,
  SignatureResponseInput,
  SmallThumbnailFragment,
  TicketPhaseOptions,
  TicketPhases,
  User,
  Waffle,
  Well
} from "client/types"

export enum SyncTable {
  Charges = "charges",
  DebugLogs = "debugLogs",
  DocumentData = "documentData",
  Documents = "documents",
  InitialCharges = "initialCharges",
  InitialTickets = "initialTickets",
  LastFullSync = "lastFullSync",
  PriceRules = "priceRules",
  Products = "products",
  RemovedDocuments = "removedDocuments",
  RunningProcedures = "runningProcedures",
  SalesOrders = "salesOrders",
  Sentry = "sentry",
  StoredDocuments = "storedDocuments",
  StoredSignatures = "storedSignatures",
  TicketExpiration = "ticketExpiration",
  TicketPhaseOptions = "ticketPhaseOptions",
  Tickets = "tickets",
  UpsyncTracker = "upsyncTracker",
  Users = "users",
  Waffle = "waffle",
  Wells = "wells"
}

export type DebugLogs = {
  id?: number
  timestamp: Scalars["DateTime"]
  command: ConsoleFns
  args: Array<any>
}

export type SyncCharge = Charge & {
  offline?: boolean
  _number?: InventoryItem["number"]
}
export type SyncDocumentData = {
  id: Document["id"]
  label: Document["label"]
  mimetype?: Document["mimetype"]
  type?: Document["type"]
  cachedThumbnail?: string
  dataURL?: string
}
export type SyncDocument = Document &
  Partial<SmallThumbnailFragment> &
  Partial<FullThumbnailFragment> & {
    originalDownload?: Scalars["URL"]
    _cabinetId?: Cabinet["id"]
    _cabinetLabel?: Cabinet["label"]
  }
export type SyncInitialCharge = Charge
export type SyncInitialTicket = ServiceTicket &
  FullTicketFragment &
  ListTicketFragmentFragment & {
    wellId?: Well["id"]
    company?: Company["id"] | number
  }
export type SyncLastSynced = {
  query: string
  date: Date
}
export type SyncPriceRule = PriceRule & {
  _companyId?: Company["id"]
  _itemId?: InventoryItem["id"]
}
export type SyncProduct = InventoryItem
export type SyncRemovedDocument = {
  uuid: Document["uuid"]
}
export type SyncRunningProcedure = RunningProcedure
export type SyncSalesOrder = FullSalesOrderFragment &
  ListSalesOrderFragmentFragment &
  PrintSalesOrderDetailsFragment
export type SyncSentry = SentryEvent & {
  id?: number
}
export type SyncStoredDocument = Omit<Document, "id"> & {
  file: ArrayBuffer
  filename: string
  cabinetId?: Cabinet["id"]
  cabinetLabel?: Cabinet["label"]
}
export type SyncStoredSignature = Partial<SmallThumbnailFragment> & {
  alreadyUploaded?: boolean
  uuid: Document["uuid"]
  file?: ArrayBuffer
  filename?: string
  mimetype?: string
  input?: Omit<AddDocumentInput, "file">
  sigInput: SignatureResponseInput & RejectSignatureResponseInput
  objectId?: ServiceTicket["id"] | SalesOrder["id"]
  keys?: ContentTypeKeys
  created: string
  cabinetId?: Cabinet["id"]
  cabinetLabel?: Cabinet["label"]
  label?: string
}
export type SyncTicketExpiration = {
  id: ServiceTicket["id"]
  date: Scalars["Date"]
}
export type SyncTicket = ServiceTicket &
  FullTicketFragment &
  ListTicketFragmentFragment & {
    wellId?: Well["id"]
    company?: Company["id"] | number
    _companyName?: Company["name"]
  }
export type SyncTicketPhaseOptions = TicketPhaseOptions
export type SyncUpsyncTracker = {
  id?: number
  attempted: Date
  operationName: string
  response: Record<string, any> | Error
  success: boolean
  variables: Record<string, any>
}
export type SyncUser = User
export type SyncWaffle = Waffle
export type SyncWell = Well

// Remember to update sw-custom.js if this changes
export const dbName = "badger-offline-sync"

class OfflineSyncDB extends Dexie {
  [SyncTable.Charges]: Dexie.Table<SyncCharge, Charge["id"]>;
  [SyncTable.DebugLogs]: Dexie.Table<DebugLogs, number>;
  [SyncTable.Documents]: Dexie.Table<SyncDocument, Document["uuid"]>;
  [SyncTable.DocumentData]: Dexie.Table<SyncDocumentData, Document["id"]>;
  [SyncTable.InitialCharges]: Dexie.Table<SyncInitialCharge, Charge["id"]>;
  [SyncTable.InitialTickets]: Dexie.Table<
    SyncInitialTicket,
    ServiceTicket["id"]
  >;
  [SyncTable.LastFullSync]: Dexie.Table<SyncLastSynced, string>;
  [SyncTable.PriceRules]: Dexie.Table<SyncPriceRule, PriceRule["id"]>;
  [SyncTable.Products]: Dexie.Table<SyncProduct, InventoryItem["id"]>;
  [SyncTable.RemovedDocuments]: Dexie.Table<
    SyncRemovedDocument,
    Document["uuid"]
  >;
  [SyncTable.RunningProcedures]: Dexie.Table<
    SyncRunningProcedure,
    RunningProcedure["id"]
  >;
  [SyncTable.SalesOrders]: Dexie.Table<SyncSalesOrder, SalesOrder["id"]>;
  [SyncTable.Sentry]: Dexie.Table<SyncSentry, number>;
  [SyncTable.StoredSignatures]: Dexie.Table<
    SyncStoredSignature,
    Document["uuid"]
  >;
  [SyncTable.StoredDocuments]: Dexie.Table<
    SyncStoredDocument,
    Document["uuid"]
  >;
  [SyncTable.TicketExpiration]: Dexie.Table<
    SyncTicketExpiration,
    ServiceTicket["id"]
  >;
  [SyncTable.TicketPhaseOptions]: Dexie.Table<
    SyncTicketPhaseOptions,
    TicketPhases
  >;
  [SyncTable.Tickets]: Dexie.Table<SyncTicket, ServiceTicket["id"]>;
  [SyncTable.UpsyncTracker]: Dexie.Table<SyncUpsyncTracker, number>;
  [SyncTable.Users]: Dexie.Table<SyncUser, User["id"]>;
  [SyncTable.Waffle]: Dexie.Table<
    SyncWaffle,
    NonNullable<Waffle["__typename"]>
  >;
  [SyncTable.Wells]: Dexie.Table<SyncWell, Well["id"]>

  constructor(databaseName) {
    super(databaseName)

    this.version(1).stores({
      [SyncTable.Charges]: "id, created, _number, suggestedPrice, ticketId",
      [SyncTable.Documents]: "uuid, id, _cabinetId, _cabinetLabel",
      [SyncTable.InitialCharges]: "id",
      [SyncTable.InitialTickets]: "id",
      [SyncTable.LastFullSync]: "query",
      [SyncTable.Products]: "id, number, suggestedPrice",
      [SyncTable.RemovedDocuments]: "uuid",
      [SyncTable.RunningProcedures]: "id",
      [SyncTable.StoredDocuments]: "uuid",
      [SyncTable.TicketExpiration]: "id, date",
      [SyncTable.TicketPhaseOptions]: "phase",
      [SyncTable.Tickets]:
        "id, created, number, status, phase, type, _companyName, _leadTech, *_sales, *_techs",
      [SyncTable.Users]: "id, fullName",
      [SyncTable.Wells]: "id"
    })
    this.version(2).stores({
      [SyncTable.PriceRules]: "id, _itemId, _companyId, [_itemId+_companyId]"
    })
    this.version(3).stores({
      [SyncTable.Wells]: "id, name"
    })
    this.version(4).stores({
      [SyncTable.DocumentData]: "id, label"
    })
    this.version(5).stores({
      [SyncTable.Sentry]: "++id, level, timestamp"
    })
    this.version(6).stores({
      [SyncTable.StoredSignatures]: "uuid",
      [SyncTable.Waffle]: "__typename"
    })
    this.version(7).stores({
      [SyncTable.DebugLogs]: "++id, timestamp"
    })
    this.version(8).stores({
      [SyncTable.Documents]: "uuid, id, created, _cabinetId, _cabinetLabel"
    })
    this.version(9).stores({
      [SyncTable.StoredSignatures]: "uuid, ticketId"
    })
    this.version(10).stores({
      [SyncTable.Charges]: "id, created, ticketId",
      [SyncTable.PriceRules]: "id, item.id, company.id, [item.id+company.id]",
      [SyncTable.Tickets]: "id, created, number"
    })
    this.version(11).stores({
      [SyncTable.SalesOrders]: "id, created, number",
      [SyncTable.StoredSignatures]: "uuid, objectId"
    })
    this.version(12).stores({
      [SyncTable.UpsyncTracker]: "++, attempted"
    })

    for (const key in SyncTable) {
      this[SyncTable[key]] = this.table(SyncTable[key])
    }
  }
}

export const db = new OfflineSyncDB(dbName)

export const clearCharges = () => db[SyncTable.Charges].clear()
export const clearDebugLogs = () => db[SyncTable.DebugLogs].clear()
export const clearDocumentData = () => db[SyncTable.DocumentData].clear()
export const clearDocuments = () => db[SyncTable.Documents].clear()
export const clearInitialCharges = () => db[SyncTable.InitialCharges].clear()
export const clearInitialTickets = () => db[SyncTable.InitialTickets].clear()
export const clearPriceRules = () => db[SyncTable.PriceRules].clear()
export const clearProducts = () => db[SyncTable.Products].clear()
export const clearRemovedDocuments = () =>
  db[SyncTable.RemovedDocuments].clear()
export const clearRunningProcedures = () =>
  db[SyncTable.RunningProcedures].clear()
export const clearSalesOrders = () => db[SyncTable.SalesOrders].clear()
export const clearSentry = () => db[SyncTable.Sentry].clear()
export const clearStoredSignatures = () =>
  db[SyncTable.StoredSignatures].clear()
export const clearStoredDocuments = () => db[SyncTable.StoredDocuments].clear()
export const clearSyncTimestamps = () => db[SyncTable.LastFullSync].clear()
export const clearTicketExpiration = () =>
  db[SyncTable.TicketExpiration].clear()
export const clearTicketPhaseOptions = () =>
  db[SyncTable.TicketPhaseOptions].clear()
export const clearTickets = () => db[SyncTable.Tickets].clear()
export const clearUpsyncTracker = () => db[SyncTable.UpsyncTracker].clear()
export const clearUsers = () => db[SyncTable.Users].clear()
export const clearWaffle = () => db[SyncTable.Waffle].clear()
export const clearWells = () => db[SyncTable.Wells].clear()

export const clearAll = () =>
  Promise.all([
    clearCharges()
      .then(() => "charges cleared from sync db")
      .catch(err => err),
    clearDebugLogs()
      .then(() => "debug logs cleared from db")
      .catch(err => err),
    clearDocumentData()
      .then(() => "PDFs cleared from sync db")
      .catch(err => err),
    clearDocuments()
      .then(() => "documents cleared from sync db")
      .catch(err => err),
    clearInitialCharges()
      .then(() => "tracked charge changes cleared from sync db")
      .catch(err => err),
    clearInitialTickets()
      .then(() => "tracked ticket changes cleared from sync db")
      .catch(err => err),
    clearPriceRules()
      .then(() => "price rules cleared from sync db")
      .catch(err => err),
    clearProducts()
      .then(() => "products cleared from sync db")
      .catch(err => err),
    clearRemovedDocuments()
      .then(() => "tracked document removals cleared from sync db")
      .catch(err => err),
    clearRunningProcedures()
      .then(() => "running procedures cleared from sync db")
      .catch(err => err),
    clearSalesOrders()
      .then(() => "sales orders cleared from sync db")
      .catch(err => err),
    clearStoredSignatures()
      .then(() => "signatures stored for upload cleared from sync db")
      .catch(err => err),
    clearSentry()
      .then(() => "stored Sentry events cleared from sync db")
      .catch(err => err),
    clearStoredDocuments()
      .then(() => "pending uploads cleared from sync db")
      .catch(err => err),
    clearSyncTimestamps()
      .then(() => "sync timestamps cleared from sync db")
      .catch(err => err),
    clearTicketExpiration()
      .then(() => "ticket expiration timestamps cleared from sync db")
      .catch(err => err),
    clearTicketPhaseOptions()
      .then(() => "ticket phase options cleared from sync db")
      .catch(err => err),
    clearTickets()
      .then(() => "tickets cleared from sync db")
      .catch(err => err),
    clearUpsyncTracker()
      .then(() => "upsync data cleared from sync db")
      .catch(err => err),
    clearUsers()
      .then(() => "users cleared from sync db")
      .catch(err => err),
    clearWaffle()
      .then(() => "waffle flags cleared from sync db")
      .catch(err => err),
    clearWells()
      .then(() => "wells cleared from sync db")
      .catch(err => err)
  ])

const useSyncDatabase = () => {
  return {
    clearAll,
    clearCharges,
    clearDebugLogs,
    clearDocumentData,
    clearDocuments,
    clearInitialCharges,
    clearInitialTickets,
    clearPriceRules,
    clearProducts,
    clearRemovedDocuments,
    clearRunningProcedures,
    clearSalesOrders,
    clearStoredSignatures,
    clearSentry,
    clearStoredDocuments,
    clearSyncTimestamps,
    clearTicketExpiration,
    clearTicketPhaseOptions,
    clearTickets,
    clearUpsyncTracker,
    clearUsers,
    clearWaffle,
    clearWells,
    db
  }
}

export default useSyncDatabase
