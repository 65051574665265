import { gql } from "@apollo/client"
import companyFragments from "./companies"
import inventoryFragments from "./inventory"
import runningProcedureFragments from "./runningProcedures"
import signatureFragments from "./signatures"
import userFragments from "./user"
import wellFragments from "./wells"
import chargeKitFragments from "./chargeKits"

const ticketFragments = {
  listTicketFragment: gql`
    fragment ListTicketFragment on ServiceTicket {
      id
      modified
      number
      linkNumber: number
      type
      status
      ...SignatureStateDisplay
      company {
        name
      }
      well {
        id
        name
      }
      workRequestDate
      scheduledArrivalTime
      fromNowTime: scheduledArrivalTime
      techs: assignments(first: 3, filter: { type: TECH }) {
        pageInfo {
          count
        }
        edges {
          node {
            ... on Assignment {
              user {
                ...SmallUser
              }
            }
          }
        }
      }
      accountManager {
        ...SmallUser
      }
    }
    ${signatureFragments.signatureInfo}
    ${userFragments.smallUser}
  `,
  fullTicket: gql`
    fragment FullTicket on ServiceTicket {
      id
      created
      modified
      number
      type
      phase
      timezone

      creator {
        ...UserOption
      }
      accountManager {
        ...UserOption
      }

      # status management
      signatureState
      status
      transitions: availableStatusTransitions {
        transition
        label
        priority
        direction
      }

      # well/customer/company info
      well {
        ...WellOption
        ...WellDetailsCard
      }
      company {
        ...CompanyOption
        ...CompanyTicker
        ...CompanySignatures
      }
      companyMan
      companyManPhone
      companyManEmail

      # generic job info
      procedure {
        ...BaseRunningProcedure
      }
      procedureRevisionId
      workRequestDate
      scheduledArrivalTime
      rigName
      crewName
      jobDescription
      spareParts
      salesOrderNumber

      # RP Step results
      steps {
        ...BaseRunningProcedureTicketStepResult
      }

      # Work Performed
      startTime
      arrivedTime
      departedTime
      jsaPerformedTime
      endTime
      workPerformed
      afeNumber
      jobCode

      # Dispatch info
      technicians {
        ...UserOption
      }
      arriveToShop
      shopLocation
      dispatchNotes

      # Attachments
      cabinetId

      requiredCharges {
        ...FullRequiredCharge
      }
    }
    ${wellFragments.wellOption}
    ${wellFragments.wellDetailCard}
    ${companyFragments.companyOption}
    ${companyFragments.companyTicker}
    ${companyFragments.companySignatures}
    ${runningProcedureFragments.baseRunningProcedure}
    ${runningProcedureFragments.baseRunningProcedureTicketStepResult}
    ${userFragments.userOption}
    ${chargeKitFragments.fullRequiredCharge}
  `,
  printTicketDetails: gql`
    fragment PrintTicketDetails on ServiceTicket {
      urls {
        detail
      }

      # Charge data
      charges {
        id
        item {
          ...BaseInventoryItem
        }
        quantity
        price
        total
      }
    }
    ${inventoryFragments.baseInventoryItem}
  `,
  typePhaseOptions: gql`
    fragment PhaseOptions on Query {
      ticketPhaseOptions {
        phase
        types
      }
    }
  `
}

export default ticketFragments
