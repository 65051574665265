import { gql } from "@apollo/client"

const locationFragments = {
  cityStack: gql`
    fragment CityStack on City {
      name
      state {
        name
        code
        country {
          name
          code
        }
      }
      county {
        name
      }
    }
  `
}

export default locationFragments
