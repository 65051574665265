import { ApolloError } from "@apollo/client"
import * as Sentry from "@sentry/react"
import { SnackbarClose, SnackbarContent } from "components/Snackbar"
import { useSnackbar, VariantType } from "notistack"
import { useEffect } from "react"

const errorOptions = {
  variant: "error" as VariantType,
  persist: true,
  action: SnackbarClose
}

export interface IUseListErrorArgs {
  error?: ApolloError
}

export default function useListError({ error }: IUseListErrorArgs) {
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (error) {
      Sentry.captureException(error)
      // Not using useFormState() because the setFormErrors fn there might eventually
      // develop into something more form-specific, instead of just a snackbar
      // notification.
      enqueueSnackbar(<SnackbarContent message={String(error)} />, errorOptions)
    }
  }, [enqueueSnackbar, error])
}
