import Attachments from "components/Attachments"
import {
  CustomFormSectionProps,
  DataKinds,
  FormConfig,
  FormSectionKinds
} from "components/FormFactory"
import { sectionGrid } from "components/FormFactory"

export const createAttachmentsConfig = (
  sectionProps: Partial<CustomFormSectionProps>
): FormConfig => {
  return [
    {
      kind: FormSectionKinds.Custom,
      grid: sectionGrid,
      header: "Attachments",
      Component: Attachments,
      ...sectionProps,
      componentProps: {
        showFilePreview: true,
        display: `card`,
        accept: {
          "image/*": "all image files",
          ".avci": "",
          ".avcs": "",
          ".avif": "",
          ".avifs": "",
          ".heic": "",
          ".heics": "",
          ".heif": "",
          ".heifs": "",
          ".xls": "Excel files",
          ".xlsx": "",
          "application/pdf": "Adobe PDF"
        },
        data: [
          {
            propPath: `cabinetId`,
            dataType: DataKinds.Formik,
            dataPath: `cabinetId`
          },
          {
            propPath: `cabinetLabel`,
            dataType: DataKinds.Formik,
            dataPath: `number`
          }
        ],
        ...sectionProps.componentProps
      }
    }
  ]
}
