import { Fragment } from "react"
import { Avatar, Badge, Theme, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { useOnlineContext } from "context/OnlineContext"
import useUser from "hooks/useUser"

import { getGravatarUrl } from "react-awesome-gravatar"

const useStyles = makeStyles((theme: Theme) => ({
  profile: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    minHeight: "fit-content",
    padding: theme.spacing(2)
  },
  avatar: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(1)
  },
  userInfo: {
    overflow: "hidden"
  },
  userInfoText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.text.primary
  }
}))

const NavProfile = () => {
  const classes = useStyles()
  const { appOnline } = useOnlineContext()
  const user = useUser()

  if (!user) {
    return <Fragment></Fragment>
  }

  const avatarUrl = getGravatarUrl(user.email)
  return (
    <>
      <div className={classes.profile} data-testid="nav-profile">
        <Badge
          color="error"
          overlap="circular"
          badgeContent=" "
          variant="dot"
          invisible={Boolean(appOnline)}
        >
          <Avatar
            alt="Person"
            className={classes.avatar}
            src={user.avatar || avatarUrl}
          />
        </Badge>
        <div className={classes.userInfo}>
          <Typography className={classes.userInfoText} variant="body1">
            <strong>{user.displayName}</strong>
          </Typography>
          <Typography className={classes.userInfoText} variant="body1">
            {user.email}
          </Typography>
        </div>
      </div>
    </>
  )
}

export default NavProfile
