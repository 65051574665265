import { useNavigate } from "react-router-dom"
import { Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"

import { CancelButton, ConfirmButton, Button } from "components"

export enum NavBarType {
  Print = "print",
  SignaturePreview = "preview"
}

interface IPrintNavBarProps {
  ready: boolean
  type: NavBarType
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2, 0),
    // Use media query doesn't work when triggering the print dialog
    // via JavaScript.
    "@media print": {
      display: "none"
    }
  },
  button: {
    // To compensate for the increased lineheight on the ticket number
    zIndex: theme.zIndex.speedDial
  }
}))

const PrintNavBar: React.FunctionComponent<IPrintNavBarProps> = ({
  ready,
  type
}) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }

  return (
    <Container className={classes.root} maxWidth="lg">
      {NavBarType.Print === type ? (
        <CancelButton className={classes.button} onClick={goBack} />
      ) : (
        <Button
          startIcon={<NavigateBeforeIcon />}
          className={classes.button}
          onClick={goBack}
        >
          Back
        </Button>
      )}
      {NavBarType.Print === type && (
        <ConfirmButton
          className={classes.button}
          disabled={!ready}
          onClick={() => {
            try {
              // Deprecated, but works to prevent long lag times in Safari
              // https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand
              // Catching both an error due to execCommand not existing and returning
              // false due to the specific command not being supported. Optional
              // chaining doesn't work w/ document or window
              // @ts-ignore
              document.execCommand("print", true, null) || window.print()
            } catch (e) {
              window.print()
            }
          }}
        >
          Print
        </ConfirmButton>
      )}
    </Container>
  )
}

export default PrintNavBar
