export const AddDocumentInputVariables = [
  "uuid",
  "category",
  "file",
  "label",
  "type",
  "cabinetId",
  "cabinetLabel"
]

export const AddSignatureInputVariables = [
  "label",
  "file"
]

export const AddressFilterInputVariables = [
  "address1",
  "address1__icontains"
]

export const AddressInputVariables = [
  "name",
  "address1",
  "address2",
  "postalCode",
  "city_id",
  "state_id",
  "country_id"
]

export const AppStateInputVariables = [
  "online",
  "debug"
]

export const AssignmentFilterInputVariables = [
  "id",
  "type",
  "type__in"
]

export const AssignmentInputVariables = [
  "id",
  "type",
  "user"
]

export const AttachCompositeInputVariables = [
  "documentUUID",
  "documentVersionId",
  "compositeDocumentUUID",
  "compositeDocumentVersionId"
]

export const BasinFilterInputVariables = [
  "search",
  "name",
  "name__icontains"
]

export const BasinInputVariables = [
  "name"
]

export const CabinetFilterInputVariables = [
  "id",
  "label"
]

export const CabinetInputVariables = [
  "label",
  "category"
]

export const ChangeRecordFilterInputVariables = [
  "outcome",
  "search"
]

export const ChargeFilterInputVariables = [
  "id",
  "showDeleted",
  "showDeletedRequired",
  "item__in",
  "order_by",
  "ticketIn",
  "createdGt",
  "createdLt",
  "modifiedGt",
  "modifiedLt"
]

export const ChargeInputVariables = [
  "id",
  "item",
  "quantity",
  "price",
  "priceRule",
  "note"
]

export const CityFilterInputVariables = [
  "search",
  "name",
  "name__icontains",
  "countyId",
  "stateId",
  "shop",
  "shops"
]

export const CompanyAcquiresWellInputVariables = [
  "company",
  "effectiveDate",
  "wellName"
]

export const CompanyFilterInputVariables = [
  "search",
  "name",
  "name__icontains",
  "ticker",
  "ticker__icontains"
]

export const CompanyInputVariables = [
  "name",
  "number",
  "sigDigitalEnabled",
  "sigImageUpload",
  "sigRemoteEmail",
  "sigStampImageUpload",
  "sigDigitalStamp"
]

export const CompanyRenamesWellInputVariables = [
  "newName",
  "effectiveDate"
]

export const ContentTypeKeysVariables = [
  "appLabel",
  "model"
]

export const ConversionTableFilterInputVariables = [
  "syncedGt"
]

export const ConversionTableInputVariables = [
  "created",
  "modified",
  "deleted",
  "name",
  "inputName",
  "outputName",
  "inverted",
  "clamped",
  "inputMax",
  "inputMin",
  "outputMax",
  "outputMin",
  "points"
]

export const CountryFilterInputVariables = [
  "name",
  "name__icontains"
]

export const CountyFilterInputVariables = [
  "search",
  "name",
  "name__icontains",
  "stateId"
]

export const CreateRunningProcedureInputVariables = [
  "title",
  "description",
  "company"
]

export const CustomerInputVariables = [
  "firstName",
  "lastName",
  "email",
  "phoneNumber"
]

export const DeleteRunningProcedureTicketStepResultInputVariables = [
  "id"
]

export const DocIdInputVariables = [
  "id",
  "versionId"
]

export const DocumentFilterInputVariables = [
  "id",
  "uuid",
  "uuidIn",
  "idsIn",
  "type",
  "typeIn",
  "cabinetId",
  "cabinetLabel",
  "cabinetLabelIn",
  "label",
  "label_lookup",
  "label_exclude",
  "label_exclude_lookup"
]

export const EmailTicketInputVariables = [
  "ticketIds",
  "to",
  "subject",
  "body"
]

export const EventFilterInputVariables = [
  "id",
  "id__in",
  "timestamp__gt",
  "timestamp__gte",
  "timestamp__lt",
  "timestamp__lte",
  "public",
  "verb",
  "verbIn",
  "verb__startswith"
]

export const FollowFilterInputVariables = [
  "public"
]

export const IntroduceRequiredChargesInputVariables = [
  "charges"
]

export const InventoryItemFilterInputVariables = [
  "order",
  "id",
  "search",
  "number",
  "number__istartswith",
  "description",
  "description__istartswith",
  "description__icontains",
  "longDescription",
  "long_description__istartswith",
  "long_description__icontains",
  "inactive",
  "partType",
  "partTypes",
  "createdGt",
  "createdLt",
  "modifiedGt",
  "modifiedLt"
]

export const InventoryOrderInputVariables = [
  "rank",
  "number",
  "id"
]

export const LimitOffsetInputVariables = [
  "limit",
  "offset"
]

export const NotificationFilterInputVariables = [
  "read"
]

export const PagedInputVariables = [
  "pageSize",
  "page"
]

export const PartTypeFieldInputVariables = [
  "id",
  "index",
  "title",
  "tag",
  "required",
  "fieldType",
  "options"
]

export const PartTypeFieldOptionInputVariables = [
  "id",
  "shortValue",
  "longValue"
]

export const PartTypeFilterInputVariables = [
  "id",
  "name",
  "search",
  "name__icontains",
  "name__istartswith",
  "createdGt",
  "createdLt",
  "modifiedGt",
  "modifiedLt"
]

export const PartTypeInputVariables = [
  "name",
  "description",
  "numberFormat",
  "numberPrefix",
  "createProducts",
  "trackRevision",
  "trackSerial",
  "partTemplate",
  "productTemplate",
  "fields",
  "engineers"
]

export const PressureReadingInputVariables = [
  "ms",
  "psi"
]

export const PressureRecordingFilterInputVariables = [
  "syncedGt"
]

export const PressureRecordingInputVariables = [
  "created",
  "modified",
  "deleted",
  "ticket",
  "salesOrder",
  "tester",
  "startTime",
  "stopTime",
  "transducerSerial",
  "type",
  "msMarker",
  "duration",
  "signature",
  "startPsi",
  "stopPsi",
  "readings"
]

export const PressureTransducerFilterInputVariables = [
  "syncedGt"
]

export const PressureTransducerInputVariables = [
  "created",
  "modified",
  "deleted",
  "name",
  "label",
  "serialNumber",
  "dataRateMax",
  "dataRateMin",
  "psiMax",
  "psiMin",
  "conversionTable"
]

export const PriceRuleFilterInputVariables = [
  "productId",
  "companyId",
  "companyIn",
  "createdGt",
  "createdLt",
  "modifiedGt",
  "modifiedLt"
]

export const RejectSignatureResponseInputVariables = [
  "signatoryName",
  "signatoryEmail",
  "rejectReason"
]

export const RequiredChargeInputVariables = [
  "item",
  "quantity",
  "order"
]

export const RunningProcedureFilterInputVariables = [
  "id",
  "search",
  "title",
  "title__istartswith",
  "title__icontains",
  "description",
  "description__istartswith",
  "description__icontains",
  "company",
  "companies",
  "createdGt",
  "createdLt",
  "modifiedGt",
  "modifiedLt"
]

export const RunningProcedureRevisionInputVariables = [
  "versionId",
  "steps",
  "requiredCharges"
]

export const RunningProcedureStepInputVariables = [
  "id",
  "title",
  "order",
  "fieldType",
  "description",
  "pageNumber",
  "required"
]

export const RunningProcedureTicketStepResultInputVariables = [
  "id",
  "runningProcedureStepId",
  "data",
  "attribute",
  "completedOn"
]

export const RunningProcedureVersionInputVariables = [
  "runningProcedureId",
  "number"
]

export const SalesOrderFilterInputVariables = [
  "id",
  "search",
  "excludeTickets",
  "order",
  "status",
  "statuses",
  "statusChangedDateGt",
  "statusChangedDateLt",
  "erpClass",
  "erpClass_istartswith",
  "erpClass_icontains",
  "erpStatus",
  "erpStatuses",
  "number",
  "number__istartswith",
  "number__icontains",
  "createdGt",
  "createdLt",
  "modifiedGt",
  "modifiedLt",
  "issuedGt",
  "issuedLt",
  "scheduledGte",
  "scheduledLte",
  "company",
  "companies",
  "shipToNameIcontains",
  "salesman",
  "salesmen",
  "signatureState",
  "signatureStates",
  "signatureStateChangedGt",
  "signatureStateChangedLt"
]

export const SalesOrderOrderInputVariables = [
  "number",
  "status",
  "statusChangedDate",
  "ERPStatus",
  "signatureState",
  "signatureStateChangedDate",
  "issuedDate",
  "scheduledDate",
  "modified",
  "created"
]

export const ServiceTicketInputVariables = [
  "id",
  "number",
  "salesOrderNumber",
  "type",
  "phase",
  "wellId",
  "accountManager",
  "company",
  "companyMan",
  "companyManPhone",
  "companyManEmail",
  "procedureRevisionId",
  "workRequestDate",
  "scheduledArrivalTime",
  "rigName",
  "crewName",
  "jobDescription",
  "spareParts",
  "technicians",
  "arriveToShop",
  "shopLocation",
  "dispatchNotes",
  "startTime",
  "arrivedTime",
  "departedTime",
  "jsaPerformedTime",
  "endTime",
  "workPerformed",
  "afeNumber",
  "jobCode"
]

export const SignatureRequestInputVariables = [
  "signatoryName",
  "signatoryEmail",
  "documentUUID",
  "documentVersionId",
  "message"
]

export const SignatureResponseFilterInputVariables = [
  "createdGt",
  "createdLt",
  "modifiedGt",
  "modifiedLt",
  "type"
]

export const SignatureResponseInputVariables = [
  "signatoryName",
  "signatoryEmail",
  "type",
  "emailCustomer",
  "documentUUID",
  "documentVersionId"
]

export const StateFilterInputVariables = [
  "name",
  "name__icontains"
]

export const ThumbnailInputVariables = [
  "width",
  "height"
]

export const TicketFilterInputVariables = [
  "order",
  "id",
  "search",
  "number",
  "number__istartswith",
  "number__icontains",
  "ticketType",
  "status",
  "statusIn",
  "assignment",
  "createdGt",
  "createdLt",
  "modifiedGt",
  "modifiedLt",
  "startGt",
  "startLt",
  "company",
  "companies",
  "well",
  "wells",
  "procedure",
  "procedures",
  "rigName",
  "rigName_istartswith",
  "rigName_icontains",
  "customer",
  "owners",
  "creators",
  "assigned",
  "sales",
  "lead_technicians",
  "technicians",
  "signatureState",
  "signatureStates",
  "signatureStateChangedGt",
  "signatureStateChangedLt"
]

export const TicketOrderInputVariables = [
  "number",
  "status",
  "type",
  "scheduledArrivalTime",
  "signatureState",
  "modified",
  "created"
]

export const TorqueWrenchFilterInputVariables = [
  "syncedGt"
]

export const TorqueWrenchInputVariables = [
  "created",
  "modified",
  "deleted",
  "serialNumber",
  "modelNumber",
  "torquewareId",
  "manufacturer",
  "calibrationDate",
  "expirationDate",
  "conversionTable"
]

export const UpdateRunningProcedureInputVariables = [
  "description",
  "company"
]

export const UserFilterInputVariables = [
  "order",
  "id",
  "search",
  "group",
  "groupId",
  "createdGt",
  "createdLt",
  "modifiedGt",
  "modifiedLt",
  "company",
  "companies"
]

export const UserOrderInputVariables = [
  "modified",
  "created"
]

export const UserSettingsInputVariables = [
  "darkModeSet",
  "darkMode"
]

export const WellDetailInputVariables = [
  "apiNumber",
  "city",
  "basin",
  "latitude",
  "longitude",
  "directions"
]

export const WellFilterInputVariables = [
  "search",
  "name",
  "name__icontains",
  "companyId",
  "createdGt",
  "createdLt",
  "modifiedGt",
  "modifiedLt"
]

export const WellInputVariables = [
  "id",
  "name",
  "company",
  "apiNumber",
  "city",
  "basin",
  "latitude",
  "longitude",
  "directions"
]

export const WellRenameInputVariables = [
  "newName"
]
