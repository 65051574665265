import MomentUtils from "@date-io/moment"
import { CssBaseline } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { GoogleAnalytics, ScrollReset } from "components"
import { ConfirmProvider } from "components/ConfirmDialog"
import { TimezoneProvider } from "context/TimezoneContext"
import { WaffleProvider } from "context/WaffleContext"
import { SnackbarProvider } from "notistack"
import { default as React } from "react"
import Content from "./Content"
import "./main.scss"
import NavBar from "./NavBar"

export interface IMainLayoutProps {}

export const useStyles = makeStyles(theme => ({
  container: {
    minHeight: "100vh",
    display: "flex"
  }
}))

const MainLayout: React.FunctionComponent<IMainLayoutProps> = () => {
  const classes = useStyles()

  // {/* <TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} /> */}
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <TimezoneProvider>
        <WaffleProvider polling={true}>
          <CssBaseline />
          <ScrollReset />
          <GoogleAnalytics />
          <ConfirmProvider>
            <SnackbarProvider
              maxSnack={5}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              preventDuplicate
              hideIconVariant
              {...{ "data-testid": "notistack-message" }}
            >
              <div className={classes.container}>
                <NavBar className="main-nav" data-testid="main-nav" />
                <Content />
              </div>
            </SnackbarProvider>
          </ConfirmProvider>
        </WaffleProvider>
      </TimezoneProvider>
    </MuiPickersUtilsProvider>
  )
}

export default MainLayout
