import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Theme
} from "@material-ui/core"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import CodeIcon from "@material-ui/icons/Code"
import PaymentIcon from "@material-ui/icons/Payment"
import PeopleIcon from "@material-ui/icons/PeopleOutlined"
import StoreIcon from "@material-ui/icons/Store"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
// import moment from "moment"
import PropTypes from "prop-types"
import { Link as RouterLink } from "react-router-dom"
import gradients from "utils/gradients"

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  listItem: {
    "&:hover": {
      backgroundColor: theme.palette.background.default
    }
  },
  read: {
    backgroundColor: theme.palette.background.default
  },
  avatarBlue: {
    backgroundImage: gradients.blue
  },
  avatarGreen: {
    backgroundImage: gradients.green
  },
  avatarOrange: {
    backgroundImage: gradients.orange
  },
  avatarIndigo: {
    backgroundImage: gradients.indigo
  },
  arrowForwardIcon: {
    // @ts-ignore
    color: theme.palette.icon
  }
}))

const NotificationList = ({
  notifications,
  className = null,
  markRead,
  ...rest
}) => {
  const classes = useStyles()

  // TODO: possibly match up icons/colors with object types on events.
  const avatars = {
    order: (
      <Avatar className={classes.avatarBlue}>
        <PaymentIcon />
      </Avatar>
    ),
    user: (
      <Avatar className={classes.avatarOrange}>
        <PeopleIcon />
      </Avatar>
    ),
    project: (
      <Avatar className={classes.avatarGreen}>
        <StoreIcon />
      </Avatar>
    ),
    feature: (
      <Avatar className={classes.avatarIndigo}>
        <CodeIcon />
      </Avatar>
    )
  }

  return (
    <List {...rest} className={clsx(classes.root, className)} disablePadding>
      {notifications.map((notification, i) => (
        <ListItem
          className={clsx(classes.listItem, {
            [classes.read]: notification.read
          })}
          component={RouterLink}
          onClick={() => markRead(notification.id)}
          divider={i < notifications.length - 1}
          key={notification.id}
          to="#"
        >
          <ListItemAvatar>{avatars["project"]}</ListItemAvatar>
          <ListItemText
            primary={notification.event.type.label}
            primaryTypographyProps={{ variant: "body1" }}
            // secondary={}
          />
          <ArrowForwardIcon className={classes.arrowForwardIcon} />
        </ListItem>
      ))}
    </List>
  )
}

NotificationList.propTypes = {
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired
}

export default NotificationList
