import { Suspense } from "react"
import { LinearProgress } from "@material-ui/core"
import { Outlet } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"

import withSentryErrorBoundary from "components/Sentry/withSentryErrorBoundary"
import CrashMessage from "layouts/Main/CrashMessage"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 0)
  }
}))

interface IContentProps {}

const Content: React.FunctionComponent<IContentProps> = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Suspense fallback={<LinearProgress />}>
        <Outlet />
      </Suspense>
    </div>
  )
}

export default withSentryErrorBoundary(Content, CrashMessage)
