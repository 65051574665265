import * as Yup from "yup"
import moment from "moment"
import castArray from "lodash/castArray"
import every from "lodash/every"
import map from "lodash/map"
import reduce from "lodash/reduce"
import reject from "lodash/reject"

import { RunningProcedureStep } from "client/types"
import { isRPEmpty } from "hooks/runningProcedures"

/***********
 * Schemas *
 ***********/
export const user = Yup.object()
  .shape({
    id: Yup.number()
  })
  .nullable()

export const castId = Yup.mixed()
  .nullable()
  // Transform object to just the id
  .transform((value, original) => {
    if (!original) return null
    return original?.id
  })

/************************
 * Casts and Transforms *
 ************************/
export const castID = cast => origSchema =>
  // @ts-ignore
  Yup.lazy((value: any, config: any) => {
    if (config.context?.cast === cast) {
      // Store stripped value in context so other validation can still use original value,
      // for instance Yup.ref("$well.id") if casting the well field to some other value.
      config.context[config.path] = value
      return castId
    }
    return origSchema
  })

export const updateCastId = castID("update")
export const createCastId = castID("create")

export const stripOnCast = cast => origSchema =>
  // @ts-ignore
  Yup.lazy((value: any, config: any) => {
    if (config.context?.cast === cast) {
      // Store stripped value in context so other validation can still use original value,
      // for instance Yup.ref("$well.id") if stripping the well field.
      config.context[config.path] = value
      return origSchema.strip(true)
    }
    return origSchema
  })

export const updateStrip = stripOnCast("update")
export const createStrip = stripOnCast("create")

export function minuteResolution(value, original) {
  const date = moment(value)
  if (date.isValid()) {
    return new Date(date.second(0).milliseconds(0).toISOString())
  } else {
    return value
  }
}

/*********************
 * Custom validators *
 *********************/
function dateTimeGt(ref, msg) {
  // @ts-ignore
  return this.test({
    name: "dateTimeGt",
    exclusive: false,
    // eslint-disable-next-line
    message: msg ?? "${path} must be greater than ${reference}",
    params: { reference: ref.path },
    test: function (value) {
      const compare = this.resolve(ref)
      if (null == value || null == compare) return true
      return (value?.getTime() ?? -1) > (compare?.getTime() ?? -1)
    }
  })
}

Yup.addMethod(Yup.date, "dateTimeGt", dateTimeGt)

function dateTimeGte(ref, msg) {
  // @ts-ignore
  return this.test({
    name: "dateTimeGte",
    exclusive: false,
    // eslint-disable-next-line
    message: msg ?? "${path} must be greater than or equal to ${reference}",
    params: { reference: ref.path },
    test: function (value) {
      const compare = this.resolve(ref)
      if (null == value || null == compare) return true
      return (value?.getTime() ?? -1) >= (compare?.getTime() ?? -1)
    }
  })
}

Yup.addMethod(Yup.date, "dateTimeGte", dateTimeGte)

function dateTimeGtOffset(ref, offset, msg) {
  // @ts-ignore
  return this.test({
    name: "dateTimeGtOffset",
    exclusive: false,
    message:
      msg ??
      // eslint-disable-next-line
      "${path} must be greater than ${reference} with offset ${offset} ms",
    params: { reference: ref.path, offset },
    test: function (value) {
      const compare = this.resolve(ref)
      if (null == value || null == compare) return true
      return (value?.getTime() ?? -1) > (compare?.getTime() ?? -1) + offset
    }
  })
}

Yup.addMethod(Yup.date, "dateTimeGtOffset", dateTimeGtOffset)

function dateTimeGteOffset(ref, offset, msg) {
  // @ts-ignore
  return this.test({
    name: "dateTimeGteOffset",
    exclusive: false,
    message:
      msg ??
      // eslint-disable-next-line
      "${path} must be greater than or equal to ${reference} with offset ${offset} ms",
    params: { reference: ref.path, offset },
    test: function (value) {
      const compare = this.resolve(ref)
      if (null == value || null == compare) return true
      return (value?.getTime() ?? -1) >= (compare?.getTime() ?? -1) + offset
    }
  })
}

Yup.addMethod(Yup.date, "dateTimeGteOffset", dateTimeGteOffset)

function mustAlsoBeFilled(refs, msg) {
  // @ts-ignore
  return this.test({
    name: "mustAlsoBeFilled",
    exclusive: false,
    // eslint-disable-next-line
    message: msg ?? "${references} must be filled out",
    params: { references: map(castArray(refs), "path").join(",") },
    test: function (value) {
      if (!value) return true
      const values = map(castArray(refs), this.resolve)
      return every(values, value => null != value)
    }
  })
}

Yup.addMethod(Yup.mixed, "mustAlsoBeFilled", mustAlsoBeFilled)

export const defaultDateSchema = Yup.date().default(function () {
  return new Date()
})

function equalTo(ref: any, msg: string) {
  return Yup.mixed().test({
    name: "equalTo",
    exclusive: false,
    // message: msg || "${path} must be the same as ${reference}",
    message: msg,
    params: {
      reference: ref.path
    },
    test: function (value: any) {
      return value === this.resolve(ref)
    }
  })
}

Yup.addMethod(Yup.string, "equalTo", equalTo)

function isNotEmptyRP() {
  return Yup.mixed().test({
    name: "isNotEmptyRP",
    exclusive: false,
    message: "This running procedure has no steps",
    test: function (value: any) {
      if (value == null) return true
      return !isRPEmpty(value)
    }
  })
}

Yup.addMethod(Yup.mixed, "isNotEmptyRP", isNotEmptyRP)

function uniqueRPStepTitles(ref: any) {
  return Yup.array().test({
    name: "uniqueRPStepTitles",
    exclusive: false,
    test: function () {
      const steps = this.resolve(ref) as Array<RunningProcedureStep>
      const titles = steps.map(step => step.title?.toLowerCase() ?? "")
      const isUnique = steps.length === new Set(titles).size

      if (!isUnique) {
        // map titles to indices
        const titleMap = reduce(
          titles,
          (acc, title, index) =>
            acc[title]
              ? {
                  ...acc,
                  [title]: [...acc[title], index]
                }
              : { ...acc, [title]: [index] },
          {} as Record<string, Array<number>>
        )
        // strip out any titles with only one index
        // reject converts it into an array of arrays, but join handles that
        const message = reject(titleMap, arr => 1 === arr.length).join()

        return this.createError({
          path: "duplicateStepTitles",
          message: message
        })
      }
      return isUnique
    }
  })
}

Yup.addMethod(Yup.array, "uniqueRPStepTitles", uniqueRPStepTitles)
