import { useMutation, useQuery } from "@apollo/client"
import {
  GetObjectSubscriptionsQuery,
  GetObjectSubscriptionsQueryVariables,
  RemoveObjectSubscriptionsMutation,
  RemoveObjectSubscriptionsMutationVariables,
  Scalars,
  SubscribedEventTypeFragment,
  SubscribeToObjectEventsMutation,
  SubscribeToObjectEventsMutationVariables
} from "client/types"
import { ContentTypeDetails } from "content-types"
import produce from "immer"
import difference from "lodash/difference"
import find from "lodash/find"
import forEach from "lodash/forEach"
import {
  GET_OBJECT_SUBSCRIPTIONS,
  REMOVE_OBJECT_SUBSCRIPTIONS,
  SUBSCRIBE_TO_OBJECT_EVENTS
} from "queries/subscriptions"

export enum SubscriptionCoverage {
  None = "NONE",
  Partial = "PARTIAL",
  Full = "FULL"
}

interface UseSubscriptionStateArgs {
  contentType: ContentTypeDetails
  id: Scalars["ID"] | undefined
  defaultSubscriptionEventIds?: Array<Scalars["ID"]>
}

interface UseSubscriptionStateReturn {
  subscriptionEventIds: Array<Scalars["ID"]>
  eventTypes: Array<SubscribedEventTypeFragment>
  missing: Array<Scalars["ID"]>
  loading: boolean
  subscribed: boolean
  coverage: SubscriptionCoverage
  add: (...ids: Array<Scalars["ID"]>) => void
  remove: (...ids: Array<Scalars["ID"]>) => void
}

export default function useSubscriptionState({
  contentType,
  id = "",
  defaultSubscriptionEventIds = []
}: UseSubscriptionStateArgs): UseSubscriptionStateReturn {
  const { appLabel, model } = contentType

  const objectVariables = {
    appLabel,
    model,
    id
  }

  const { data, loading: getting } = useQuery<
    GetObjectSubscriptionsQuery,
    GetObjectSubscriptionsQueryVariables
  >(GET_OBJECT_SUBSCRIPTIONS, {
    fetchPolicy: "cache-and-network",
    skip: !id,
    variables: {
      ...objectVariables
    }
  })

  const eventTypes = (data?.contentType?.eventsTypes ||
    []) as Array<SubscribedEventTypeFragment>

  const subscriptionEventIds: Array<Scalars["ID"]> = []

  eventTypes.reduce(
    // @ts-ignore
    (acc, ev) => {
      if (ev.isObjectSubscribed) {
        acc.push(ev.id)
      }
      return acc
    },
    subscriptionEventIds
  )

  const optimisticReturn = (toValue: boolean) => vars => {
    const events = produce(eventTypes, draft => {
      forEach(vars.eventIds, id => {
        const event = find(
          draft,
          ev => ev.id === id
        ) as SubscribedEventTypeFragment
        event.isObjectSubscribed = toValue
      })
    })

    return {
      __typename: "Mutation",
      contentType: {
        __typename: "ContentTypeMutation",
        id: vars.id,
        payload: {
          __typename: "EventContentSubscriptionPayload",
          success: true,
          errors: [],
          events
        }
      }
    }
  }

  const [subscribeToEvent, { loading: subscribing }] = useMutation<
    SubscribeToObjectEventsMutation,
    SubscribeToObjectEventsMutationVariables
  >(SUBSCRIBE_TO_OBJECT_EVENTS, {
    variables: {
      ...objectVariables,
      eventIds: []
    },
    // @ts-ignore
    optimisticResponse: optimisticReturn(true)
  })

  const [removeSubscriptions, { loading: removing }] = useMutation<
    RemoveObjectSubscriptionsMutation,
    RemoveObjectSubscriptionsMutationVariables
  >(REMOVE_OBJECT_SUBSCRIPTIONS, {
    variables: {
      ...objectVariables,
      eventIds: []
    },
    // @ts-ignore
    optimisticResponse: optimisticReturn(false)
  })

  const remove = (...ids: Array<Scalars["ID"]>) => {
    return removeSubscriptions({
      // @ts-ignore
      variables: { ...objectVariables, eventIds: ids }
    })
  }

  const add = (...ids: Array<Scalars["ID"]>) => {
    return subscribeToEvent({
      // @ts-ignore
      variables: { ...objectVariables, eventIds: ids }
    })
  }

  const loading = getting || subscribing || removing

  const missing = difference(defaultSubscriptionEventIds, subscriptionEventIds)

  const coverage =
    missing.length === defaultSubscriptionEventIds.length
      ? SubscriptionCoverage.None
      : missing.length === 0
      ? SubscriptionCoverage.Full
      : SubscriptionCoverage.Partial

  return {
    subscriptionEventIds,
    eventTypes,
    subscribed: !!subscriptionEventIds.length,
    missing,
    loading,
    coverage,
    add,
    remove
  }
}
