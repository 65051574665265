import { Dispatch, SetStateAction, useEffect, useMemo } from "react"
import { InMemoryCache, NormalizedCacheObject } from "@apollo/client"
import { persistCache } from "apollo-cache-persist"
import { PersistedData, PersistentStorage } from "apollo-cache-persist/types"
import localForage from "localforage"
// This library breaks tests due to using only ES modules
// import useLocalStorage from "web-api-hooks/dist-src/useLocalStorage"

export const localStorageKey = "version"
const usePersistedCache = (
  cache: InMemoryCache,
  setReady: Dispatch<SetStateAction<boolean>>
) => {
  // Memoizing to only run the effect once
  const appVersion = useMemo(() => localStorage.getItem(localStorageKey), [])

  useEffect(() => {
    const execute = async () => {
      const currentVersion = process.env.REACT_APP_VERSION

      // if the appVersion hasn't been set, or there's been a minor version change,
      // clear localForage
      if (null === appVersion) {
        await localForage.clear()
      } else {
        const [oldMajor = "-1", oldMinor = "-1"] = appVersion?.split(".") ?? []
        const [currentMajor = "-1", currentMinor = "-1"] =
          currentVersion?.split(".") ?? []
        if (oldMinor !== currentMinor || oldMajor !== currentMajor) {
          await localForage.clear()
        }
      }

      localStorage.setItem(localStorageKey, currentVersion ?? "")

      await persistCache({
        cache,
        storage: localForage as PersistentStorage<
          PersistedData<NormalizedCacheObject>
        >
      })
      setReady(true)
    }

    execute()
  }, [appVersion, cache, setReady])
}

export default usePersistedCache
