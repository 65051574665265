import { darken, emphasize, lighten } from "@material-ui/core/styles"

const darkenAmount = 0.2

export const labelColors = (theme, backgroundColor, color) => {
  const dark = theme.palette.type === "dark"
  if (dark) {
    backgroundColor = emphasize(backgroundColor, 0.66)
    color = emphasize(lighten(color, 0.1), 0.3)
  } else {
    color = darken(color, darkenAmount)
  }
  return {
    backgroundColor,
    color
  }
}
