import clsx from "clsx"
import { NavLink, NavLinkProps } from "react-router-dom"
import { Button, ButtonProps } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import * as colors from "theme/palettes/colors"
import useSharedStyles from "./useSharedStyles"

export interface INavLinkButtonProps {
  active?: boolean
  disabled?: ButtonProps["disabled"]
  Icon?: React.ComponentType | null
  Label?: JSX.Element | null
  title: string
  to: NavLinkProps["to"]
  dataTestid?: string
}

const useStyles = makeStyles(theme => ({
  root: {
    height: 48,
    width: "100%"
  },
  button: {
    borderRadius: 0,
    paddingLeft: theme.spacing(2),
    boxShadow: "none",
    backgroundColor: "transparent",
    width: "100%",
    height: "100%",
    "&:hover": {
      backgroundColor:
        "light" === theme.palette.type ? colors.grey[100] : colors.grey[700]
    }
  }
}))

const NavLinkButton: React.FunctionComponent<INavLinkButtonProps> = ({
  active = false,
  disabled = false,
  Icon = null,
  Label = null,
  title,
  to,
  dataTestid
}) => {
  const classes = { ...useStyles(), ...useSharedStyles() }

  return (
    <div
      className={clsx(classes.root, active ? classes.active : classes.inactive)}
    >
      {/* // @ts-ignore */}
      <Button
        className={classes.button}
        startIcon={Icon && <Icon />}
        disabled={disabled}
        component={NavLink}
        to={to}
        end
      >
        <div className={classes.content}>
          {title}
          {Label}
        </div>
      </Button>
    </div>
  )
}

export default NavLinkButton
