import { gql } from "@apollo/client"
import chargeFragments from "fragments/charges"

import listPageFragments from "fragments/listPages"
import payloadFragments from "fragments/payload"
import ticketFragments from "fragments/tickets"

export const GET_TICKET_BY_NUMBER = gql`
  query getTicketByNumber($ticketNumber: String!) {
    ticket: filterTicket(filter: { number: $ticketNumber }) {
      ...FullTicket
    }
  }
  ${ticketFragments.fullTicket}
`

export const GET_TICKET_PDF = gql`
  query getTicketPrintUrlByNumber($ticketNumber: String!) {
    ticket: filterTicket(filter: { number: $ticketNumber }) {
      printedPDF
    }
  }
`

export const GET_TICKET_PHASE_OPTIONS = gql`
  query ticketPhaseOptions {
    ...PhaseOptions
  }
  ${ticketFragments.typePhaseOptions}
`

export const GET_TICKET_PRINT_DATA = gql`
  query getTicketPrintData($ticketNumber: String!) {
    ticket: filterTicket(filter: { number: $ticketNumber }) {
      ...FullTicket
      ...PrintTicketDetails
    }
  }
  ${ticketFragments.fullTicket}
  ${ticketFragments.printTicketDetails}
`

export const GET_TICKETS = gql`
  query getFilteredTickets(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: TicketFilterInput
  ) {
    ticketData: getTickets(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      edges {
        cursor
        node {
          ...ListTicketFragment
        }
      }
      ...PageInfo
    }
  }
  ${ticketFragments.listTicketFragment}
  ${listPageFragments.pageInfo}
`

export const GET_PAGED_TICKETS = gql`
  query getPagedFilteredTickets(
    $paged: PagedInput
    $filter: TicketFilterInput
  ) {
    ticketData: getPagedTickets(paged: $paged, filter: $filter) {
      ...PagedInfo
      tickets: objects {
        ...ListTicketFragment
      }
    }
  }
  ${ticketFragments.listTicketFragment}
  ${listPageFragments.pagedInfo}
`

export const CREATE_TICKET = gql`
  mutation createTicket($input: ServiceTicketInput!) {
    ticketData: introduceTicket(input: $input) {
      ...BasePayload
      ticket: object {
        number
        # ... FullTicket
      }
    }
  }
  ${payloadFragments.payloadFragment}
`

export const UPDATE_TICKET = gql`
  mutation updateTicketData($ticketId: UUID!, $input: ServiceTicketInput!) {
    ticketData: updateTicket(ticketId: $ticketId, input: $input) {
      ...BasePayload
      ticket: object {
        ...FullTicket
      }
    }
  }
  ${payloadFragments.payloadFragment}
  ${ticketFragments.fullTicket}
`

export const TRANSITION_TICKET = gql`
  mutation transitionTicket(
    $ticketId: UUID!
    $transition: TicketStatusTransitions!
  ) {
    ticketData: transitionTicketStatus(
      ticketId: $ticketId
      transition: $transition
    ) {
      ...BasePayload
      ticket: object {
        ...FullTicket
        # charges to update cache in case they are generated in a transition
        charges {
          ...BaseCharge
        }
      }
    }
  }
  ${chargeFragments.baseCharge}
  ${payloadFragments.payloadFragment}
  ${ticketFragments.fullTicket}
`
