// Most of the time, we can poll the backend to get new information, but for some
// situations, like keeping track of uploaded files, the back end won't always send
// back needed data until it finishes an async processing pipeline.
//
// This hook provides a reusable way to track that data and automatically provide
// basic async state reconciliation.
//
// In order for this hook to work, certain conditions need to be met:
//
// 1. Your useQuery returns a collection of data, and it's not easy or possible to
// set up individual queries to track specific data (e.g., attachments are tracked
// by UUID, and we can't know the UUIDs of new attachments in advance).
// 2. There's local-only processing which doesn't involve the back end (e.g.,
// attachments of the wrong file type are not sent to the back end, so that info
// never comes back via polling, but we still need to keep track of it in the UI).
// 3. The data that you're polling for contains a field that indicates when the
// results have come back from the back end (and only the back end). This is to
// handle delete operations correctly.
// 4. The data that you're polling for contains a field that indicates when the
// data is in a transitional state and is waiting for a back-end response to reach
// its final state. The UI might be using an optimistic response or a partial
// response from the back end for cleaner UX, but as long as at least one tracked
// item has this transitional flag, the hook will keep polling the query.
//
// If your use case doesn't meet these constraints, please use refetch or the
// Apollo Cache instead. If you're not sure if you need this, you probably don't.

import { useEffect, useState } from "react"
import filter from "lodash/filter"
import find from "lodash/find"
import isEqual from "lodash/isEqual"
import orderBy from "lodash/orderBy"
import reject from "lodash/reject"
import unionBy from "lodash/unionBy"

const useLocalDataTracking = <T>(
  { backEndData, backEndDataFindIteratee },
  { startPolling, stopPolling, pollInterval },
  unionByIteratee,
  pendingDataFindIteratee,
  {
    sortIteratees = [] as Array<unknown>,
    sortOrders = [] as Array<boolean | "asc" | "desc">
  }
): [Array<T>, Function] => {
  const [isPolling, setIsPolling] = useState(false)
  const [trackedData, setTrackedData] = useState([] as Array<T>)

  useEffect(() => {
    // Remove data that comes from back end to handle item removal
    const localData = reject(trackedData, backEndDataFindIteratee)
    // This can be a bit tricky. For base ticket attachments, Attached and UploadPending
    // documents have already been removed via backEndDataFindIteratee, so that they're
    // replaced with new back end data. But RemovalError documents aren't. If they were,
    // that would cause infinite loops, where they're reset to Attached because that's the
    // back-end data, which triggers the update to RemovalError status, which triggers the
    // reset...

    // To avoid this, we set data to have override: true. The double merge isn't the most
    // efficient, but it is only three lines.
    const overrides = filter(localData, { override: true })
    const firstMerge = unionBy(backEndData ?? [], localData, unionByIteratee)
    const mergedData = orderBy(
      unionBy(overrides, firstMerge, unionByIteratee),
      sortIteratees,
      sortOrders
    ) as Array<T>
    const shouldPoll = Boolean(find(mergedData, pendingDataFindIteratee))

    if (!isEqual(mergedData, trackedData)) {
      setTrackedData(mergedData)
    }
    if (shouldPoll && !isPolling) {
      startPolling(pollInterval)
      setIsPolling(true)
    }
    if (!shouldPoll && isPolling) {
      stopPolling()
      setIsPolling(false)
    }
  }, [
    backEndData,
    backEndDataFindIteratee,
    pendingDataFindIteratee,
    sortIteratees,
    unionByIteratee,
    sortOrders,
    trackedData,
    isPolling,
    setIsPolling,
    startPolling,
    stopPolling,
    pollInterval
  ])

  return [trackedData, setTrackedData]
}

export default useLocalDataTracking
