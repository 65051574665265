/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined"
import BusinessOutlinedIcon from "@material-ui/icons/BusinessOutlined"
import DashboardIcon from "@material-ui/icons/Dashboard"
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined"
import FlagIcon from "@material-ui/icons/Flag"
import FolderIcon from "@material-ui/icons/FolderOutlined"
import ImportContactsIcon from "@material-ui/icons/ImportContacts"
import MenuBookIcon from "@material-ui/icons/MenuBook"
import PeopleIcon from "@material-ui/icons/People"
import ReceiptIcon from "@material-ui/icons/Receipt"
import RoomIcon from "@material-ui/icons/Room"
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined"
import ViewConfigIcon from "@material-ui/icons/ViewComfy"
import ViewModuleIcon from "@material-ui/icons/ViewModule"
import ListAltIcon from "@material-ui/icons/ListAlt"
import { Label, LabelColors } from "components/labels"
import {
  NavConfigNode,
  NavLinkListNode,
  NavLinkNode
} from "components/Navigation"

const VersionLabel: React.FunctionComponent<{}> = () => (
  <Label color={LabelColors.Blue}>v{process.env.REACT_APP_VERSION}</Label>
)

const DashboardNotificationLabel: React.FunctionComponent<{}> = () => (
  <Label color={LabelColors.Red}>0</Label>
)

const extra_support: Array<NavLinkNode | NavLinkListNode> = [
  // TODO: extra github links and other misc for developers
  {
    title: "Components",
    href: "/components",
    icon: ViewConfigIcon,
    items: [
      {
        title: "Buttons",
        href: "/components/buttons"
      },
      {
        title: "Cards",
        href: "/components/cards"
      },
      {
        title: "Chips",
        href: "/components/chips"
      },
      {
        title: "File Attachments",
        href: "/components/Attachments"
      },
      {
        title: "Labels",
        href: "/components/labels"
      },
      {
        title: "Lists",
        href: "/components/lists"
      },
      {
        title: "Miscellaneous",
        href: "/components/misc"
      },
      {
        title: "Page Elements",
        href: "/components/pages"
      },
      // Not currently implemented
      // {
      //   title: "Forms",
      //   href: "/components/forms"
      // },
      // {
      //   title: "Modals",
      //   href: "/components/modals"
      // },
      {
        title: "Typography",
        href: "/components/typography"
      }
    ]
  }
]
const navConfig: Array<NavConfigNode> = [
  {
    subheader: "Management",
    items: [
      {
        title: "Dashboard",
        icon: DashboardIcon,
        href: "/dashboard",
        label: <DashboardNotificationLabel />,
        flag: "enableDashboard",
        dataTestid: "nav-dashboard"
      },
      {
        title: "Service Tickets",
        href: "/tickets/",
        icon: FolderIcon,
        dataTestid: "nav-tickets"
      },
      {
        title: "Sales Orders",
        href: "/sales-orders/",
        icon: ReceiptIcon,
        dataTestid: "nav-sales-orders"
      },
      {
        title: "Wells",
        icon: RoomIcon,
        disabled: false,
        href: "/wells/",
        dataTestid: "nav-wells"
      },
      {
        title: "Companies",
        href: "/companies/",
        icon: BusinessOutlinedIcon,
        dataTestid: "nav-companies"
      },
      {
        title: "Quotes",
        icon: AttachMoneyOutlinedIcon,
        href: "/quotes/",
        disabled: true,
        flag: "showAllNav",
        dataTestid: "nav-quotes"
      },
      {
        title: "Technicians",
        icon: SupervisedUserCircleOutlinedIcon,
        href: "/technicians/",
        disabled: true,
        flag: "showAllNav",
        dataTestid: "nav-technicians"
      },
      {
        title: "Inventory",
        icon: ListAltIcon,
        dataTestid: "nav-inventory",
        items: [
          {
            title: "All Inventory",
            href: "/inventory/",
            dataTestid: "nav-all-inventory"
          },
          {
            title: "All Parts",
            href: "/parts/",
            dataTestid: "nav-parts"
          },
          {
            title: "All Products",
            href: "/products/",
            dataTestid: "nav-products"
          },
          {
            title: "All Services",
            href: "/services/",
            dataTestid: "nav-services"
          },
          {
            title: "Part Types",
            href: "/part-types/",
            dataTestid: "nav-part-types"
          }
        ]
      },
      {
        title: "Equipment",
        icon: FlagIcon,
        flag: "showAllNav",
        items: [
          {
            title: "Requested Equipment",
            href: "/equipment/dashboard/",
            icon: ViewModuleIcon,
            disabled: true
          },
          {
            title: "All Equipment",
            href: "/equipment/",
            icon: FlagIcon,
            disabled: true
          },
          {
            title: "Some Equipment",
            href: "/equipment/some/",
            icon: FlagIcon,
            disabled: true
          }
        ]
      },
      {
        title: "Boards",
        icon: ViewModuleIcon,
        flag: "showAllNav",
        items: [
          {
            title: "Requested Equipment",
            href: "/requested-equipment/",
            icon: ViewModuleIcon,
            disabled: true
          }
        ]
      },
      {
        title: "Dispatch",
        icon: FilterListOutlinedIcon,
        href: "/dispatch/",
        disabled: true,
        flag: "showAllNav"
      },
      {
        title: "Documentation",
        icon: ImportContactsIcon,
        dataTestid: "nav-documentation",
        items: [
          {
            title: "Running Procedures",
            href: "/running-procedures",
            icon: MenuBookIcon,
            disabled: false,
            dataTestid: "nav-rps"
          }
        ]
      },
      {
        title: "Users",
        icon: PeopleIcon,
        href: "/users",
        dataTestid: "nav-users"
      }
    ]
  },
  {
    subheader: "Support",
    flag: "showAllNav",
    items: [
      ...(process.env.NODE_ENV === "development" ? extra_support : []),
      {
        title: "Changelog",
        href: "/changelog/",
        disabled: true,
        icon: ViewModuleIcon,
        label: <VersionLabel />
      }
    ]
  }
]

export default navConfig
