import CheckIcon from "@material-ui/icons/Check"
import ButtonBase, { IButtonBaseProps, TitanButtonColor } from "../ButtonBase"

export interface IConfirmButtonProps
  extends Omit<
    IButtonBaseProps,
    "startIcon" | "endIcon" | "icon" | "color" | "textColor" | "variant"
  > {}

// Button w/ leading checkmark. Used in modal dialogs and to transition tickets.
const ConfirmButton: React.FunctionComponent<IConfirmButtonProps> = props => (
  <ButtonBase
    color={TitanButtonColor.Green}
    startIcon={<CheckIcon />}
    variant="contained"
    {...props}
  />
)

export default ConfirmButton
