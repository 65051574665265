// vim: foldmethod=marker:foldmarker={{{,}}}
// imports {{{
import { ComponentType, useMemo } from "react"
import { Link, LinkProps, Typography, TypographyProps } from "@material-ui/core"

import Fraction from "fraction.js"
import { is } from "ts-type-guards"
import { Dictionary } from "lodash"
import compact from "lodash/compact"
import differenceWith from "lodash/differenceWith"
import every from "lodash/every"
import find from "lodash/find"
import filter from "lodash/filter"
import flatMap from "lodash/flatMap"
import head from "lodash/head"
import intersectionWith from "lodash/intersectionWith"
import isEqual from "lodash/isEqual"
import map from "lodash/map"
import omit from "lodash/omit"
import pick from "lodash/pick"
import reduce from "lodash/reduce"
import reject from "lodash/reject"
import some from "lodash/some"
import uniqueId from "lodash/uniqueId"
import zipObject from "lodash/zipObject"

import {
  FormInputProps,
  FormSectionKinds,
  FormSectionProps,
  fulls
} from "components/FormFactory"
import {
  ChartStep,
  DateTimeStep,
  FeetInchesMeasurementStep,
  MeasurementStep,
  PhotoStep,
  SwitchStep,
  unitsMap,
  YesNoStep
} from "components/RunningProcedureStepInputs"
import { convertToDisplayText } from "views/RunningProcedureDetails/utils"

import {
  BaseRunningProcedureTicketStepResultFragment,
  ServiceTicket,
  Document,
  FieldType,
  Maybe,
  RunningProcedure,
  RunningProcedureRevision,
  RunningProcedureTicketStepResult,
  RunningProcedureVersion,
  Scalars
} from "client/types"
// imports }}}

// ParsedRunningProcedureTicketStepResult {{{
export type ParsedRunningProcedureTicketStepResult = Pick<
  RunningProcedureTicketStepResult,
  "id" | "runningProcedureStep"
> & {
  parsedData: Dictionary<Dictionary<string | string[]>>
  creator: string
  modified: string | Scalars["DateTime"]
  completedOn?: string | Scalars["DateTime"]
}
// ParsedRunningProcedureTicketStepResult }}}

// fieldTypeToAttributeMap {{{
export const fieldTypeToAttributeMap = {
  [FieldType.Boolean]: [FieldType.Boolean],
  [FieldType.DateTime]: [FieldType.DateTime],
  [FieldType.Duration]: [FieldType.Duration],
  [FieldType.Grade]: [FieldType.Grade],
  [FieldType.Length]: [FieldType.Length],
  [FieldType.LengthFt]: [FieldType.Length],
  [FieldType.Photo]: [FieldType.Photo],
  [FieldType.Pressure]: [FieldType.Pressure],
  [FieldType.Size]: [FieldType.Size],
  [FieldType.Temperature]: [FieldType.Temperature],
  [FieldType.Text]: [FieldType.Text],
  [FieldType.Torque]: [FieldType.Torque],
  [FieldType.Weight]: [FieldType.Weight],
  [FieldType.YesNo]: [FieldType.YesNo],
  [FieldType.DigitalPressureTest]: [FieldType.DigitalPressureTest],

  [FieldType.PressureAndDuration]: [FieldType.Pressure, FieldType.Duration],
  [FieldType.SizeWeightGrade]: [
    FieldType.Size,
    FieldType.Weight,
    FieldType.Grade
  ],
  "": ["UNKNOWN"]
}
// fieldTypeToAttributeMap }}}

// All RP step data gets stored as text anyway, so it's less convoluted to have yes/no strings
// than to convert to null/true/false for front end data modelling.
export enum YesNo {
  Yes = "yes",
  No = "no"
}

// combineRevisionWithStepData {{{
export const combineRevisionWithStepData: (
  revision?: Maybe<RunningProcedureRevision>
) => (
  stepData?: Array<BaseRunningProcedureTicketStepResultFragment>
) => Array<ParsedRunningProcedureTicketStepResult> = revision => stepData => {
  const procedureSteps = revision?.steps

  return map(procedureSteps, step => {
    const existingData = filter(
      stepData,
      testStep => testStep?.runningProcedureStep?.id === step?.id
    )

    const attributes = fieldTypeToAttributeMap[step?.fieldType ?? ""]
    const data = map(attributes, attribute => {
      const datum = find(existingData, { attribute })
      let data
      switch (attribute) {
        case FieldType.Boolean:
          data = "true" === datum?.data
          break
        case FieldType.DateTime:
          data = Boolean(datum?.data) ? datum?.data : null
          break
        case FieldType.Length:
          if (step?.fieldType === FieldType.LengthFt) {
            data = normalizeFeetInches(["", String(datum?.data)])
          } else {
            const inchesData = parseInches(String(datum?.data))
            // @ts-ignore
            const [, inches] = getDisplayText([NaN, ...inchesData])
            data = inches
          }
          break
        default:
          data = datum?.data ?? ""
      }

      return {
        id: datum?.id ?? "",
        creator: datum?.creator?.fullName ?? datum?.creator?.email ?? "",
        modified: datum?.modified ?? "",
        completedOn: datum?.completedOn ?? "",
        data
      }
    })
    const parsedData = zipObject(
      attributes,
      map(data, obj => pick(obj, "id", "data", "completedOn"))
    )

    return {
      id: map(data, "id").join(":"),
      creator: map(data, "creator").join(":"),
      modified: map(data, "modified").join(":"),
      completedOn: map(data, "completedOn").join(":"),
      runningProcedureStep: step,
      parsedData
    } as ParsedRunningProcedureTicketStepResult
  })
}
// combineRevisionWithStepData }}}

// diffSteps {{{
export const diffSteps: (
  oldSteps: Array<ParsedRunningProcedureTicketStepResult>
) => (
  newSteps: Array<ParsedRunningProcedureTicketStepResult>
) => {
  addedSteps: Array<ParsedRunningProcedureTicketStepResult>
  removedSteps: Array<ParsedRunningProcedureTicketStepResult>
  changedSteps: Array<ParsedRunningProcedureTicketStepResult>
} = oldSteps => newSteps => {
  const addedSteps = differenceWith(
    newSteps,
    oldSteps,
    (newStep, oldStep) =>
      `${newStep.id}:${newStep?.runningProcedureStep?.id}:${newStep?.runningProcedureStep?.order}` ===
      `${oldStep.id}:${oldStep?.runningProcedureStep?.id}:${oldStep?.runningProcedureStep?.order}`
  )
  const removedSteps = differenceWith(
    oldSteps,
    newSteps,
    (oldStep, newStep) =>
      `${newStep.id}:${newStep?.runningProcedureStep?.id}:${newStep?.runningProcedureStep?.order}` ===
      `${oldStep.id}:${oldStep?.runningProcedureStep?.id}:${oldStep?.runningProcedureStep?.order}`
  )
  const potentiallyChangedSteps = intersectionWith(
    newSteps,
    oldSteps,
    (newStep, oldStep) =>
      `${newStep.id}:${newStep?.runningProcedureStep?.id}:${newStep?.runningProcedureStep?.order}` ===
      `${oldStep.id}:${oldStep?.runningProcedureStep?.id}:${oldStep?.runningProcedureStep?.order}`
  )
  const changedSteps = reject(potentiallyChangedSteps, newStep => {
    const oldStep = find(
      oldSteps,
      oldStep =>
        oldStep.id === newStep.id &&
        oldStep?.runningProcedureStep?.id ===
          newStep.runningProcedureStep?.id &&
        oldStep?.runningProcedureStep?.order ===
          newStep?.runningProcedureStep?.order
    )
    return isEqual(omit(newStep, "modified"), omit(oldStep, "modified"))
  })

  return {
    addedSteps,
    changedSteps,
    removedSteps
  }
}
// diffSteps }}}

// diffDisplayModelSteps {{{
export const diffDisplayModelSteps: (
  oldSteps: Array<ParsedRunningProcedureTicketStepResult>
) => (
  newSteps: Array<ParsedRunningProcedureTicketStepResult>
) => {
  addedSteps: Array<ParsedRunningProcedureTicketStepResult>
  removedSteps: Array<ParsedRunningProcedureTicketStepResult>
  changedSteps: Array<ParsedRunningProcedureTicketStepResult>
} = oldSteps => newSteps => {
  const {
    addedSteps: _addedSteps,
    changedSteps: _changedSteps,
    removedSteps: _removedSteps
  } = diffSteps(oldSteps)(newSteps)

  const addedSteps = reject(_addedSteps, step =>
    every(step.parsedData, { id: "", data: "" })
  )
  const changedSteps = reject(_changedSteps, step =>
    every(step.parsedData, { id: "", data: "" })
  )
  const removedSteps = reject(_removedSteps, step =>
    every(step.parsedData, { id: "", data: "" })
  )

  return { addedSteps, changedSteps, removedSteps }
}
// diffDisplayModelSteps }}}

// extractStepData {{{
export const extractStepData: (
  dipslayedStepData: Array<ParsedRunningProcedureTicketStepResult>
) => Array<RunningProcedureTicketStepResult> = displayedStepData => {
  return compact(
    flatMap(displayedStepData, parsedStep =>
      map(parsedStep?.parsedData, (data, attribute) => {
        if ("" === data.id) return null
        return {
          id: String(data.id),
          runningProcedureStep: parsedStep?.runningProcedureStep,
          data:
            FieldType.LengthFt === parsedStep?.runningProcedureStep?.fieldType
              ? convertToInchesString(data?.data as [string, string])
              : String(data.data),
          completedOn: String(data.completedOn),
          attribute
        }
      })
    )
  )
}
// extractStepData }}}

// getMostRecentRevisionId {{{
export const getMostRecentRevisionId: (
  runningProcedure: RunningProcedure | null | undefined
) => Maybe<Scalars["ID"]> = runningProcedure => {
  const versions = runningProcedure?.versions
  const currentVersion = head(versions)
  const currentRevision = head(currentVersion?.revisions)
  const currentRevisionId = currentRevision?.id ?? ""
  return currentRevisionId
}
// getMostRecentRevisionId }}}

// getProcedureVersionAndRevision {{{
export const getProcedureVersionAndRevision: (
  procedures?: Array<RunningProcedure>
) => (
  procedureRevisionId?: Maybe<RunningProcedureRevision["id"]>
) => [
  Maybe<RunningProcedure>,
  Maybe<RunningProcedureVersion>,
  Maybe<RunningProcedureRevision>
] = procedures => procedureRevisionId => {
  const procedure =
    find(procedures, procedure =>
      some(procedure?.versions, version =>
        some(
          version?.revisions,
          revision => revision?.id === procedureRevisionId
        )
      )
    ) ?? null
  const [version, revision] = getVersionAndRevision(procedure)(
    procedureRevisionId
  )
  return [procedure, version, revision]
}
// getProcedureVersionAndRevision }}}

// getVersionAndRevision {{{
export const getVersionAndRevision: (
  procedure?: Maybe<RunningProcedure>
) => (
  procedureRevisionId?: Maybe<RunningProcedureRevision["id"]>
) => [
  Maybe<RunningProcedureVersion>,
  Maybe<RunningProcedureRevision>
] = procedure => procedureRevisionId => {
  const version =
    find(procedure?.versions, version =>
      some(version?.revisions, revision => revision?.id === procedureRevisionId)
    ) ?? null
  const revision = (find(version?.revisions, {
    id: procedureRevisionId
  }) ?? null) as Maybe<RunningProcedureRevision>
  return [version, revision]
}
// getVersionAndRevision }}}

// useRunningProcedureUtils {{{
const useRunningProcedureUtils = (
  runningProcedure: RunningProcedure | null | undefined
) => {
  const versions = runningProcedure?.versions
  const currentVersion = head(versions) ?? null
  const currentVersionId = currentVersion?.id ?? ""
  const currentVersionNumber = currentVersion?.number ?? "0"
  const currentRevision = head(currentVersion?.revisions) ?? null
  const currentRevisionId = currentRevision?.id ?? ""
  const currentRevisionNumber = currentRevision?.number ?? "0"
  const currentSteps = currentRevision?.steps ?? []
  const currentRequiredCharges = currentRevision?.requiredCharges ?? []

  const allRevisions = useMemo(() => flatMap(versions, "revisions"), [versions])
  const versionAndRevisionMap = useMemo(
    () =>
      reduce(
        versions,
        (acc, version) => {
          return {
            ...acc,
            [version?.number ?? uniqueId("unknownversion")]: map(
              version?.revisions,
              "number"
            )
          }
        },
        {}
      ),
    [versions]
  )

  const getVersionById = (id?: Scalars["ID"]): Maybe<RunningProcedureVersion> =>
    find(runningProcedure?.versions, { id }) ?? null

  const getRevisionById: (
    id?: Maybe<Scalars["ID"]>
  ) => Maybe<RunningProcedureRevision> = useMemo(
    () => id => find(allRevisions, { id }) ?? null,
    [allRevisions]
  )

  const _getVersionAndRevision = getVersionAndRevision(runningProcedure)

  const getStepsDisplayModel: (
    revisionId?: Maybe<Scalars["ID"]>
  ) => (
    stepData: Array<RunningProcedureTicketStepResult>
  ) => Array<ParsedRunningProcedureTicketStepResult> = useMemo(
    () => revisionId => stepData => {
      const revision = getRevisionById(revisionId ?? currentRevisionId)

      return combineRevisionWithStepData(revision)(stepData)
    },
    [currentRevisionId, getRevisionById]
  )

  return {
    currentVersion,
    currentVersionId,
    currentVersionNumber,
    currentRevision,
    currentSteps,
    currentRequiredCharges,
    currentRevisionId,
    currentRevisionNumber,
    versionAndRevisionMap,
    getVersionAndRevision: _getVersionAndRevision,
    getVersionById,
    getRevisionById,
    getStepsDisplayModel
  }
}
// useRunningProcedureUtils }}}

export default useRunningProcedureUtils

/* Other utility functions, not used in the hook */
// unit tuples {{{
export type FeetInchesTuple = [number, number]
export type FractionTuple = [number, number]
export type MixedInchesTuple = [number, number, number]
export type FeetInchesFractionTuple = [number, number, number, number]
export type FeetInchesDisplay = [string, string]
// unit tuples }}}

// parseInches {{{
export const parseInches = (_inches: string): MixedInchesTuple => {
  // fraction.js doesn't handle mixed numbers with a dash
  const inches = _inches.replace("-", " ")
  try {
    const f = new Fraction(inches)
    const stringFraction = f.toFraction(true)

    if (stringFraction.includes(" ") || !stringFraction.includes("/")) {
      const [_whole, _numerator, _denominator] = stringFraction.split(/[/ ]/)
      return [parseInt(_whole), parseInt(_numerator), parseInt(_denominator)]
    } else {
      const [_numerator, _denominator] = stringFraction.split("/")
      return [0, parseInt(_numerator), parseInt(_denominator)]
    }
  } catch (e) {
    return [NaN, NaN, NaN]
  }
}
// parseInches }}}

// calculateFeetInches {{{
export const calculateFeetInches: (
  args: FeetInchesTuple
) => FeetInchesTuple = ([_feet, _inches]) => {
  const extraFeet = Math.floor(_inches / 12)
  const feet = isNaN(_inches)
    ? _feet
    : isNaN(_feet) && extraFeet > 0
    ? extraFeet
    : _feet + extraFeet
  const inches = _inches % 12

  return [feet, inches]
}
// calculateFeetInches }}}

// getDisplayText {{{
export const getDisplayText: (
  args: FeetInchesFractionTuple
) => FeetInchesDisplay = ([feet, inches, numerator, denominator]) => {
  const feetString = isNaN(feet) ? "" : String(feet)

  const inchesString = [
    isNaN(inches) ? "" : String(inches),
    isNaN(numerator) || isNaN(denominator) ? "" : `${numerator}/${denominator}`
  ]
    .filter(el => "" !== el)
    .join("-")

  return [feetString, inchesString]
}
// getDisplayText }}}

// normalizeFeetInches {{{
export const ftRegex = /^\d+$/
export const normalizeFeetInches: (
  args: FeetInchesDisplay
) => FeetInchesDisplay = ([feetString, inchesString]) => {
  const [_inches, numerator, denominator] = parseInches(inchesString)
  const _feet = ftRegex.test(feetString) ? parseInt(feetString) : NaN
  const [feet, inches] = calculateFeetInches([_feet, _inches])

  return getDisplayText([feet, inches, numerator, denominator])
}
// normalizeFeetInches }}}

// convertToInchesString {{{
export const convertToInchesString: (args: FeetInchesDisplay) => string = ([
  feetString,
  inchesString
]) => {
  const [_inches, numerator, denominator] = parseInches(inchesString)
  const _feet = /^\d+$/.test(feetString) ? parseInt(feetString) : NaN

  let inches: number
  if (isNaN(_inches) && isNaN(_feet)) {
    inches = NaN
  } else if (isNaN(_inches)) {
    inches = _feet * 12
  } else if (isNaN(_feet)) {
    inches = _inches
  } else {
    inches = _feet * 12 + _inches
  }

  return [
    isNaN(inches) ? "" : String(inches),
    isNaN(numerator) || isNaN(denominator) ? "" : `${numerator}/${denominator}`
  ]
    .filter(el => "" !== el)
    .join("-")
}
// convertToInchesString }}}

// createPDFLink {{{
export const createPDFLink = ({
  stepDef,
  pdfId,
  pdfLink,
  appOnline
}: {
  stepDef?: RunningProcedureTicketStepResult["runningProcedureStep"]
  pdfId?: Document["id"]
  pdfLink?: Document["original"]
  appOnline: Maybe<boolean>
}): [React.FunctionComponent, LinkProps | TypographyProps] => {
  let LinkComponent: ComponentType
  if (appOnline) {
    LinkComponent = pdfLink ? Link : Typography
  } else {
    LinkComponent = pdfId ? Link : Typography
  }

  const linkText = `(page ${stepDef?.pageNumber})`
  const href = appOnline
    ? `${pdfLink}#page=${stepDef?.pageNumber}`
    : `/pdf/${pdfId}`
  const linkProps =
    Link === LinkComponent
      ? {
          children: linkText,
          href,
          target: "_blank",
          underline: "none" as LinkProps["underline"],
          rel: "noopener noreferrer"
        }
      : {
          children: linkText,
          color: "primary" as TypographyProps["color"]
        }

  return [LinkComponent, linkProps]
}
// createPDFLink }}}

// createRPStepConfigs {{{
export const createRPStepConfigs = ({
  steps,
  pdfId,
  pdfLink,
  appOnline,
  docSubheader,
  cabinetId,
  cabinetLabel
}: {
  steps: Array<ParsedRunningProcedureTicketStepResult>
  pdfId?: Document["id"]
  pdfLink?: Document["original"]
  appOnline: Maybe<boolean>
  docSubheader?: JSX.Element | null
  cabinetId?: ServiceTicket["cabinetId"]
  cabinetLabel?: ServiceTicket["number"]
}): Array<FormSectionProps> =>
  steps.map((step, labelIndex) => {
    const { runningProcedureStep: stepDef } = step
    const index = (stepDef?.order ?? 1) - 1

    const grid =
      stepDef?.fieldType === FieldType.Photo ? fulls : { xs: 12, md: 6 }

    const [PdfLinkComponent, pdfLinkProps] = createPDFLink({
      stepDef,
      pdfId,
      pdfLink,
      appOnline
    })

    const header = (
      <Typography variant="h5">
        Step {labelIndex + 1}: {stepDef?.title}{" "}
        <PdfLinkComponent {...pdfLinkProps} />
      </Typography>
    )

    const instructions = (
      <Typography variant="body1">{stepDef?.description ?? ""}</Typography>
    )

    let fields: Array<FormInputProps> = []
    switch (stepDef?.fieldType) {
      case FieldType.Photo:
        fields = [
          {
            type: "custom",
            Component: PhotoStep,
            grid: fulls,
            props: {
              instructions,
              cabinetId,
              cabinetLabel,
              accept: {
                "image/*": "all image files",
                ".avci": "",
                ".avcs": "",
                ".avif": "",
                ".avifs": "",
                ".heic": "",
                ".heics": "",
                ".heif": "",
                ".heifs": ""
              },
              stepId: stepDef.id
            }
          }
        ]
        break
      case FieldType.Boolean:
        fields = [
          {
            type: "custom",
            Component: SwitchStep,
            grid: fulls,
            props: {
              index,
              instructions,
              attributes: fieldTypeToAttributeMap[stepDef.fieldType]
            }
          }
        ]
        break
      case FieldType.DateTime:
        fields = [
          {
            type: "custom",
            Component: DateTimeStep,
            grid: fulls,
            props: {
              instructions,
              index,
              attributes: fieldTypeToAttributeMap[stepDef.fieldType]
            }
          }
        ]
        break
      case FieldType.LengthFt:
        fields = [
          {
            type: "custom",
            Component: FeetInchesMeasurementStep,
            grid: fulls,
            props: {
              instructions,
              index,
              units: unitsMap[stepDef.fieldType]
            }
          }
        ]
        break
      case FieldType.YesNo:
        fields = [
          {
            type: "custom",
            Component: YesNoStep,
            grid: fulls,
            props: {
              instructions,
              index,
              attributes: fieldTypeToAttributeMap[stepDef.fieldType]
            }
          }
        ]
        break
      case FieldType.DigitalPressureTest:
        fields = [
          {
            type: "custom",
            Component: ChartStep,
            grid: fulls,
            props: {
              instructions,
              index,
              attributes: fieldTypeToAttributeMap[stepDef.fieldType]
            }
          }
        ]
        break
      default:
        const inputGrid =
          FieldType.Text === stepDef.fieldType ? { xs: 12 } : undefined
        const multiline = FieldType.Text === stepDef.fieldType
        fields = [
          {
            type: "custom",
            Component: MeasurementStep,
            grid: fulls,
            props: {
              instructions,
              units: unitsMap[stepDef.fieldType],
              attributes: fieldTypeToAttributeMap[stepDef.fieldType],
              index,
              inputGrid,
              multiline
            }
          }
        ]
    }

    return {
      kind: FormSectionKinds.Card,
      grid,
      contentGrid: true,
      header,
      subheader: docSubheader,
      cardHeaderProps: {
        disableTypography: true
      },
      fields
    }
  }) as Array<FormSectionProps>
// createRPStepConfigs }}}

// PDFInfo {{{
type PDFInfo = {
  cabinetId: RunningProcedure["cabinetId"]
  cabinetLabel: RunningProcedure["title"]
  label: string
}
// PDFInfo }}}

// getRevisionPDFInfo {{{
export const getRevisionPDFInfo = (procedures: Array<RunningProcedure>) => (
  procedureRevisionIds: Array<RunningProcedureRevision["id"]>
) =>
  reduce<RunningProcedureRevision["id"], Array<PDFInfo>>(
    procedureRevisionIds,
    (acc, procedureRevisionId) => {
      const [procedure, version] = getProcedureVersionAndRevision(procedures)(
        procedureRevisionId
      )

      return procedure
        ? [
            ...acc,
            {
              cabinetId: procedure?.cabinetId,
              cabinetLabel: procedure?.title,
              label: `${convertToDisplayText(version?.number)}.pdf`
            }
          ]
        : acc
    },
    []
  )
// getRevisionPDFInfo }}}

// isRPEmpty {{{
export const isRPEmpty = (procedure: RunningProcedure): boolean => {
  const { versions } = procedure
  if (!is(Array)(versions)) return true
  if (!versions.length) return true

  const { revisions } = head(versions as Array<RunningProcedureVersion>) ?? {}
  if (!is(Array)(revisions)) return true
  if (!revisions.length) return true

  // Does not check if step data is malformed -- trusting the API/data structures
  const { steps } = head(revisions as Array<RunningProcedureRevision>) ?? {}
  if (!is(Array)(steps)) return true
  if (!steps.length) return true

  return false
}
// isRPEmpty }}}
