import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import CheckIcon from "@material-ui/icons/Check"
import { useFormikContext } from "formik"
import castArray from "lodash/castArray"

import { ButtonGroup, ButtonBase as Button, TitanButtonColor } from "components"
import { DisplayTicket } from "views/TicketDetails/TicketDetails"
import {
  ParsedRunningProcedureTicketStepResult,
  YesNo
} from "hooks/runningProcedures"

import { useFormStateContext } from "hooks/forms/useFormState"
import StepNotFound from "./StepNotFound"

interface IYesNoStepProps {
  index: number
  attributes: string | Array<string>
  instructions?: string | null
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  content: {
    display: "flex",
    alignItems: "center"
  },
  lockedWrapper: {
    display: "flex"
  },
  lockedData: {
    textTransform: "uppercase"
  },
  buttonGroup: {
    width: "100%"
  },
  button: {
    width: "50%"
  }
}))

export const dataTestid = "yes-no-step"
const YesNoStep: React.FunctionComponent<IYesNoStepProps> = ({
  index,
  instructions,
  attributes: _attributes
}) => {
  const classes = useStyles()
  const formik = useFormikContext<Partial<DisplayTicket>>()
  const step = formik.values?.steps?.[
    index
  ] as ParsedRunningProcedureTicketStepResult

  const { getMetadata } = useFormStateContext()

  // If the running procedure step order is broken, there might be no step or no
  // parsedData. If so, display the step not found message instead of crashing.
  // parsedData should always be an object, so no need to worry about expected
  // falsey values from short-circuiting the display.
  if (!step?.parsedData) return <StepNotFound index={index} />

  const { isSubmitting } = formik
  const {
    isDocumentationLocked = false,
    isMutationLoading = false
  } = getMetadata()
  const attributes = castArray(_attributes)
  const value = step.parsedData[attributes[0]].data

  const handleYesClick = () => {
    if (YesNo.Yes === value) {
      formik.setFieldValue(
        `steps[${index}].parsedData.${attributes[0]}.data`,
        ""
      )
    } else {
      formik.setFieldValue(
        `steps[${index}].parsedData.${attributes[0]}.data`,
        YesNo.Yes
      )
    }
  }
  const handleNoClick = () => {
    if (YesNo.No === value) {
      formik.setFieldValue(
        `steps[${index}].parsedData.${attributes[0]}.data`,
        ""
      )
    } else {
      formik.setFieldValue(
        `steps[${index}].parsedData.${attributes[0]}.data`,
        YesNo.No
      )
    }
  }

  const content = isDocumentationLocked ? (
    <Grid className={classes.lockedWrapper} item xs={12}>
      <Typography className={classes.lockedData}>
        <strong>{value || "Unknown"}</strong>
      </Typography>
    </Grid>
  ) : (
    <Grid className={classes.content} item xs={12}>
      <ButtonGroup className={classes.buttonGroup}>
        <Button
          className={classes.button}
          variant={YesNo.Yes === value ? "contained" : "outlined"}
          color={TitanButtonColor.Blue}
          endIcon={YesNo.Yes === value ? <CheckIcon /> : undefined}
          disabled={isSubmitting || (isMutationLoading as boolean)}
          onClick={handleYesClick}
        >
          Yes
        </Button>
        <Button
          className={classes.button}
          variant={YesNo.No === value ? "contained" : "outlined"}
          color={TitanButtonColor.Blue}
          endIcon={YesNo.No === value ? <CheckIcon /> : undefined}
          disabled={isSubmitting || (isMutationLoading as boolean)}
          onClick={handleNoClick}
        >
          No
        </Button>
      </ButtonGroup>
    </Grid>
  )

  return (
    <Grid
      className={classes.root}
      container
      spacing={2}
      data-testid={dataTestid}
    >
      <Grid item xs={12}>
        {instructions}
      </Grid>
      {content}
    </Grid>
  )
}

export default YesNoStep
