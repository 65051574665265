import ClearIcon from "@material-ui/icons/Clear"
import ButtonRed, { IButtonRedProps } from "./ButtonRed"

export interface IRejectButtonProps
  extends Omit<IButtonRedProps, "startIcon" | "endIcon"> {}

// Button to reject things
const RejectButton: React.FunctionComponent<IRejectButtonProps> = props => (
  <ButtonRed startIcon={<ClearIcon />} {...props} />
)

export default RejectButton
