import { gql } from "@apollo/client"

const listPageFragments = {
  pageInfo: gql`
    fragment PageInfo on ListPageInfo {
      pageInfo {
        endCursor
        hasNextPage
        count
      }
    }
  `,
  pagedInfo: gql`
    fragment PagedInfo on Paginated {
      pagedInfo {
        hasNextPage
        hasPreviousPage
        page
        totalPages
        count
      }
    }
  `
}

export default listPageFragments
