// vim: foldmethod=marker:foldmarker={{{,}}}
// imports {{{
import { gql } from "@apollo/client"
import compact from "lodash/compact"
import difference from "lodash/difference"
import flatten from "lodash/flatten"
import keys from "lodash/keys"
import map from "lodash/map"
import omit from "lodash/omit"
import reduce from "lodash/reduce"
import reject from "lodash/reject"
import size from "lodash/size"
import union from "lodash/union"
import unionBy from "lodash/unionBy"

import { client } from "client"
import {
  AddDocumentInput,
  ChargeInput,
  FileUploadStatus,
  RunningProcedureTicketStepResult,
  Scalars,
  ServiceTicket
} from "client/types"

import {
  ADD_CHARGE,
  REMOVE_CHARGE,
  RESTORE_CHARGE,
  UPDATE_CHARGE
} from "queries/charges"
import { ADD_DOCUMENT, REMOVE_DOCUMENT } from "queries/documents"
import {
  DELETE_RUNNING_PROCEDURE_STEP_RESULTS,
  SAVE_RUNNING_PROCEDURE_STEP_RESULTS
} from "queries/runningProcedures"
import {
  ADD_SIGNATURE,
  CREATE_SIGNATURE_REQUEST,
  CREATE_SIGNATURE_RESPONSE,
  REJECT_SIGNATURE_RESPONSE
} from "queries/signatures"
import { UPDATE_TICKET } from "queries/tickets"

import {
  db,
  SyncCharge,
  SyncInitialTicket,
  SyncStoredSignature,
  SyncTable
} from "../useSyncDatabase"
import {
  RemovedCharge,
  AddedSignature,
  RejectedSignature,
  SignatureRequest,
  TicketChanges,
  TicketChangesMap
} from "./diff"
import { trackedMutate } from "./tracking"
// imports }}}

// upsyncCharges {{{
export const upsyncCharges: (props: {
  addedCharges: Array<ChargeInput>
  removedCharges: Array<RemovedCharge>
  updatedCharges: Array<ChargeInput>
  restoredCharges: Array<string>
}) => Promise<boolean> = async ({
  addedCharges,
  removedCharges,
  updatedCharges,
  restoredCharges
}) => {
  const modifiedChargeIds = union(
    map(addedCharges, "id"),
    map(updatedCharges, "id"),
    map(removedCharges, "id"),
    restoredCharges
  )

  // addChargePromises {{{
  // The correct charge data should already be in the cache, so there's
  // no update function.
  // Charges should be added in the order they were added to the sync db,
  // so chain promises instead of Promise.all
  const addChargePromises = reduce<any, Promise<Array<SyncCharge["id"]>>>(
    addedCharges,
    (acc, { ticketId, ...input }) =>
      acc.then(chargeIds =>
        trackedMutate({
          mutation: ADD_CHARGE,
          variables: {
            ticketId,
            input
          }
        })
          .then(() => [...chargeIds, input.id])
          .catch(() => chargeIds)
      ) as Promise<Array<SyncCharge["id"]>>,
    Promise.resolve([])
  ).then(async _chargeIds => {
    const chargeIds = compact(_chargeIds)

    // Remove the offine flag from charges that have been uploaded
    await Promise.all(
      map(chargeIds, async chargeId => {
        const _charge = await db.charges.get(chargeId)
        // @ts-ignore
        const { offline, ...charge } = _charge

        return db.charges.put(charge)
      })
    )
      .then(() => {})
      .catch(err => {
        console.error(err)
      })

    return chargeIds
  })
  // addChargePromises }}}

  // removeChargePromises {{{
  const removeChargePromises = Promise.all(
    map(removedCharges, charge =>
      trackedMutate({
        mutation: REMOVE_CHARGE,
        variables: {
          chargeId: charge.id,
          note: charge.note
        }
      })
        .then(_ => charge.id)
        .catch(() => undefined)
    )
  )
  // removeChargePromises }}}

  // restoreChargePromises {{{
  // ensure restores happen before updates!
  const restoreChargePromises = await Promise.all(
    map(restoredCharges, chargeId =>
      trackedMutate({
        mutation: RESTORE_CHARGE,
        variables: {
          chargeId
        }
      })
        .then(_ => chargeId)
        .catch(() => undefined)
    )
  )
  // restoreChargePromises }}}

  // updateChargePromises {{{
  const updateChargePromises = Promise.all(
    map(updatedCharges, ({ id: chargeId, ...input }) =>
      trackedMutate({
        mutation: UPDATE_CHARGE,
        variables: {
          chargeId,
          input
        }
      })
        .then(_ => chargeId)
        .catch(() => undefined)
    )
  )
  // updateChargePromises }}}

  // updatedChargeIds {{{
  const updatedChargeIds = compact(
    flatten(
      await Promise.all([
        addChargePromises,
        removeChargePromises,
        restoreChargePromises,
        updateChargePromises
      ])
    )
  )
  // updatedChargeIds }}}

  // delete all successful mutations from the tracking table
  await db.initialCharges.bulkDelete(updatedChargeIds)
  const failedMutations = difference(modifiedChargeIds, updatedChargeIds)
  const allChargesSynced = 0 === failedMutations.length

  if (!allChargesSynced) {
    console.error("Unable to upsync charge ids: ", failedMutations.join(", "))
  }

  return allChargesSynced
}
// upsyncCharges }}}

// upsyncDocuments {{{
export const upsyncDocuments: (props: {
  addedDocuments: Array<Omit<AddDocumentInput, "category">>
  removedDocuments: Array<Scalars["UUID"]>
}) => Promise<boolean> = async ({ addedDocuments, removedDocuments }) => {
  const addDocumentUUIDs = map(addedDocuments, "uuid")

  const addedDocumentUUIDs = compact(
    await Promise.all(
      map(addedDocuments, input =>
        trackedMutate({
          mutation: ADD_DOCUMENT,
          variables: { input }
        })
          .then(() => {
            // The document info should already be in the cache via serviceworker
            // so we just need to update the status to trigger polling by any
            // components currently displaying the data
            client.writeFragment({
              id: input.uuid,
              fragment: gql`
                fragment DocumentStatus on Document {
                  status
                }
              `,
              data: {
                status: FileUploadStatus.Uploaded
              }
            })
            return input.uuid
          })
          .catch(() => undefined)
      )
    )
  )

  const removedDocumentUUIDs = compact(
    await Promise.all(
      map(removedDocuments, uuid =>
        trackedMutate({
          // The documents have already been removed from the cache
          // while offline, so there's no need to remove them again
          mutation: REMOVE_DOCUMENT,
          variables: {
            documentUUID: uuid
          }
        })
          .then(_ => uuid)
          .catch(() => undefined)
      )
    )
  )

  // delete successful mutations from the tracking tables
  await db.storedDocuments.bulkDelete(addedDocumentUUIDs)
  await db.removedDocuments.bulkDelete(removedDocumentUUIDs)
  const allDocumentsAttached =
    addedDocumentUUIDs.length === addDocumentUUIDs.length
  const allDocumentsRemoved =
    removedDocumentUUIDs.length === removedDocuments.length

  if (!allDocumentsAttached) {
    console.error(
      "Unable to attach document uuids: ",
      difference(addDocumentUUIDs, addedDocumentUUIDs).join(", ")
    )
  }
  if (!allDocumentsRemoved) {
    console.error(
      "Unable to unattach document uuids: ",
      difference(removedDocuments, removedDocumentUUIDs).join(", ")
    )
  }

  return allDocumentsAttached && allDocumentsRemoved
}
// upsyncDocuments }}}

// upsyncSignatures {{{
export const upsyncSignatures: (props: {
  addedSignatures: Array<AddedSignature>
  rejectedSignatures: Array<RejectedSignature>
  signatureRequests: Array<SignatureRequest>
}) => Promise<boolean> = async ({
  addedSignatures,
  rejectedSignatures,
  signatureRequests
}) => {
  const addSignatureUUIDs = map(addedSignatures, "input.uuid")
  const addedSignatureUUIDs = compact(
    await Promise.all(
      map(addedSignatures, variables => {
        const { input, sigInput, id, keys } = variables

        return trackedMutate({
          mutation: ADD_SIGNATURE,
          variables: {
            input
          }
        })
          .then(({ data, errors }) => {
            if (errors || !data)
              return Promise.reject("General mutation failure.")
            const { documentData } = data ?? {}
            const { success, errors: documentErrors, document } =
              documentData ?? {}
            if (0 < documentErrors.length || !success)
              return Promise.reject("Signature upload not successful.")

            const { versionId } = document ?? {}
            if (!versionId)
              return Promise.reject("Signature version id not found.")

            return versionId
          })
          .then(async documentVersionId => {
            await trackedMutate({
              mutation: CREATE_SIGNATURE_RESPONSE,
              variables: {
                input: { ...sigInput, documentVersionId },
                id,
                keys
              }
            })
          })
          .then(() => input?.uuid)
          .catch(() => undefined)
      })
    )
  )
  const rejectSignatureUUIDs = map(
    rejectedSignatures,
    variables => `__${variables.id}_REJECTION__`
  )
  const rejectedSignatureUUIDs = compact(
    await Promise.all(
      map(rejectedSignatures, variables =>
        trackedMutate({
          mutation: REJECT_SIGNATURE_RESPONSE,
          variables
        })
          .then(() => `__${variables.id}_REJECTION__`)
          .catch(() => undefined)
      )
    )
  )
  const requestUUIDs = map(
    signatureRequests,
    variables => `__${variables.id}_REQUEST__`
  )
  const requestedUUIDs = compact(
    await Promise.all(
      map(signatureRequests, variables =>
        trackedMutate({
          mutation: CREATE_SIGNATURE_REQUEST,
          variables
        })
          .then(() => `__${variables.id}_REQUEST__`)
          .catch(() => undefined)
      )
    )
  )

  const allSignaturesAttached =
    addSignatureUUIDs.length === addedSignatureUUIDs.length
  const allSignaturesRejected =
    rejectSignatureUUIDs.length === rejectedSignatureUUIDs.length
  const allRequestsMade = requestUUIDs.length === requestedUUIDs.length
  if (!allSignaturesAttached) {
    console.error(
      "Unable to attach signature uuids: ",
      difference(addSignatureUUIDs, addedSignatureUUIDs).join(", ")
    )
  }
  if (!allSignaturesRejected) {
    console.error(
      "Unable to reject signature uuids: ",
      difference(rejectSignatureUUIDs, rejectedSignatureUUIDs).join(", ")
    )
  }
  if (!allRequestsMade) {
    console.error(
      "Unable to request signature email uuids: ",
      difference(requestUUIDs, requestedUUIDs).join(", ")
    )
  }

  const success =
    allSignaturesAttached && allSignaturesRejected && allRequestsMade
  const successUUIDs = [
    ...addedSignatureUUIDs,
    ...rejectedSignatureUUIDs,
    ...requestedUUIDs
  ]

  if (success) {
    // If all signatures mutations succeeded, delete the stored signatures and document data.
    // This should be all of them, to include any previous uploaded signatures.
    const alreadyUploadedUUIDs = compact(
      map(await db[SyncTable.StoredSignatures].toArray(), "uuid")
    )
    await db[SyncTable.StoredSignatures].clear()
    await db[SyncTable.DocumentData]
      .where(":id")
      .anyOf([...alreadyUploadedUUIDs, ...successUUIDs])
      .delete()
    await db[SyncTable.Documents]
      .where(":id")
      .anyOf([...alreadyUploadedUUIDs, ...successUUIDs])
      .delete()
  } else {
    // Otherwise, set the alreadyUploaded flag for successful mutations. This way, the techs
    // will still see the successful signatures in the UI while in offline mode.
    const updatedData = map<SyncStoredSignature, SyncStoredSignature>(
      compact(await db[SyncTable.StoredSignatures].bulkGet(successUUIDs)),
      storedData => ({
        ...storedData,
        alreadyUploaded: true
      })
    )
    await db[SyncTable.StoredSignatures].bulkPut(updatedData)

    // We don't update the addSignature document data because add another document version
    // shouldn't break anything.
  }

  return success
}
// upsyncSignatures }}}

// upsyncTickets {{{
export const upsyncTickets: (props: {
  ticketChanges: TicketChangesMap
}) => Promise<boolean> = async ({ ticketChanges }) => {
  // Multiple calls to useMutation lose the state of previous mutations
  // Using the client directly to track successes/failures
  const successIds = Boolean(ticketChanges.error)
    ? []
    : compact<ServiceTicket["id"]>(
        await Promise.all(
          map(ticketChanges, async (changeSet, ticketId) => {
            const {
              addedRPSteps,
              changedRPSteps,
              removedRPSteps,
              updatedFields
            } = changeSet as TicketChanges
            const updatedSteps = [...addedRPSteps, ...changedRPSteps]

            const initialTicket = await db[SyncTable.InitialTickets].get(
              ticketId
            )

            const updatePromise =
              0 < keys(updatedFields).length
                ? trackedMutate({
                    mutation: UPDATE_TICKET,
                    variables: {
                      ticketId,
                      input: updatedFields
                    }
                  })
                : Promise.resolve()

            const addStepsPromise =
              0 < updatedSteps.length
                ? trackedMutate({
                    mutation: SAVE_RUNNING_PROCEDURE_STEP_RESULTS,
                    variables: {
                      ticketId,
                      input: updatedSteps
                    }
                  })
                : Promise.resolve()

            const removeStepsPromise =
              0 < removedRPSteps.length
                ? trackedMutate({
                    mutation: DELETE_RUNNING_PROCEDURE_STEP_RESULTS,
                    variables: {
                      ticketId,
                      input: removedRPSteps
                    }
                  })
                : Promise.resolve()

            // @ts-ignore
            const allPromisesSucceeded = await Promise.all([
              updatePromise,
              addStepsPromise,
              removeStepsPromise
            ])
              .then(() => true)
              .catch(() => false)

            if (allPromisesSucceeded) {
              await db[SyncTable.InitialTickets].delete(ticketId)
              return ticketId
            } else {
              const { steps: existingSteps = [] } = initialTicket ?? {}
              const updatedBaseTicketData = await updatePromise
                .then(
                  res =>
                    omit(
                      res.data.ticketData.ticket,
                      "steps"
                    ) as SyncInitialTicket
                )
                .catch(() => omit(initialTicket, "steps"))

              const newStepData = await addStepsPromise
                .then(res => {
                  const { steps: updatedSteps = [] } =
                    res.data.procedureStepData.ticket ?? {}
                  return unionBy<RunningProcedureTicketStepResult>(
                    updatedSteps,
                    existingSteps,
                    "id"
                  )
                })
                .catch(() => existingSteps)

              const removeStepsSuccess = await removeStepsPromise
                .then(() => true)
                .catch(() => false)
              const steps = removeStepsSuccess
                ? reject(newStepData, ({ id }) => removedRPSteps.includes(id))
                : newStepData
              const newInitialTicketState = {
                ...updatedBaseTicketData,
                steps
              }

              await db[SyncTable.InitialTickets].put(newInitialTicketState)
              return undefined
            }
          })
        )
      )

  const allTicketsSynced = successIds.length === size(ticketChanges)

  if (!allTicketsSynced) {
    console.error(
      "Unable to upsync ticket ids: ",
      difference(keys(ticketChanges), successIds).join(", ")
    )
  }

  return allTicketsSynced
}
// upsyncTickets }}}
