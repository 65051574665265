export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Email: any;
  JSON: any;
  URL: string;
  UUID: any;
  Upload: any;
  _FieldSet: any;
};





export enum ActionFlags {
  /** Addition */
  Addition = 'ADDITION',
  /** Change */
  Change = 'CHANGE',
  /** Deletion */
  Deletion = 'DELETION'
}

export type AddDocumentInput = {
  uuid?: Maybe<Scalars['UUID']>;
  category: Category;
  file: Scalars['Upload'];
  label?: Maybe<Scalars['String']>;
  type?: Maybe<DocumentType>;
  cabinetId?: Maybe<Scalars['ID']>;
  cabinetLabel?: Maybe<Scalars['String']>;
};

export type AddSignatureInput = {
  label?: Maybe<Scalars['String']>;
  /** file containing the signature image to add to the document */
  file: Scalars['Upload'];
};

export type AddSignaturePayload = Payload & {
  __typename?: 'AddSignaturePayload';
  success: Scalars['Boolean'];
  object?: Maybe<DocumentResult>;
  signature?: Maybe<DocumentResult>;
  errors: Array<FieldError>;
};

export type Address = Node & {
  __typename?: 'Address';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  city: City;
  state: State;
  country?: Maybe<Country>;
};

export type AddressFilterInput = {
  address1?: Maybe<Scalars['String']>;
  address1__icontains?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  name?: Maybe<Scalars['String']>;
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  city_id: Scalars['Int'];
  state_id: Scalars['Int'];
  country_id?: Maybe<Scalars['Int']>;
};

export type AddressPayload = Payload & {
  __typename?: 'AddressPayload';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
  object?: Maybe<Address>;
};

/** Application state that perists locally and is stored in the cache */
export type AppState = {
  __typename?: 'AppState';
  online: Scalars['Boolean'];
  debug: Scalars['Boolean'];
};

export type AppStateInput = {
  online?: Maybe<Scalars['Boolean']>;
  debug?: Maybe<Scalars['Boolean']>;
};

/** Application variables that are not persisted */
export type AppVars = {
  __typename?: 'AppVars';
  authorization: AuthorizationType;
  env: Scalars['String'];
  isUpSyncing: Scalars['Boolean'];
  updateAvailable: Scalars['Boolean'];
};

export type Assignable = {
  assignments?: Maybe<Connection>;
  /** Retrieve an Assignment from singular AssignmentType */
  assignment?: Maybe<Assignment>;
};


export type AssignableAssignmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AssignmentFilterInput>;
};


export type AssignableAssignmentArgs = {
  type: AssignmentTypes;
};

export type Assignment = Node & {
  __typename?: 'Assignment';
  id: Scalars['ID'];
  type?: Maybe<AssignmentTypes>;
  label?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  /** Proxy fullName to attached user */
  fullName?: Maybe<Scalars['String']>;
  /** Proxy email to attached user */
  email?: Maybe<Scalars['String']>;
};

export type AssignmentFilterInput = {
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<AssignmentTypes>;
  type__in?: Maybe<Array<Maybe<AssignmentTypes>>>;
};

export type AssignmentInput = {
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<AssignmentTypes>;
  user: Scalars['ID'];
};

export enum AssignmentTypes {
  /** Created */
  Created = 'CREATED',
  /** Owner */
  Owner = 'OWNER',
  /** Assigned */
  Assigned = 'ASSIGNED',
  /** Watching */
  Watching = 'WATCHING',
  /** Inside Sales */
  Sales = 'SALES',
  /** Technician */
  Tech = 'TECH',
  /** Lead Technician */
  LeadTech = 'LEAD_TECH',
  /** Company Man */
  CompanyMan = 'COMPANY_MAN'
}

export type AttachCompositeInput = {
  documentUUID?: Maybe<Scalars['UUID']>;
  documentVersionId?: Maybe<Scalars['ID']>;
  compositeDocumentUUID: Scalars['UUID'];
  compositeDocumentVersionId: Scalars['ID'];
};

export enum AuthorizationType {
  /** Cognito */
  Cognito = 'COGNITO',
  /** Expired */
  Expired = 'EXPIRED',
  /** JWT */
  Jwt = 'JWT',
  /** None */
  None = 'NONE'
}

export type BaseFieldConfiguration = {
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  fieldType: FieldType;
  required: Scalars['Boolean'];
};

export type BaseFieldOption = {
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  shortValue?: Maybe<Scalars['String']>;
  longValue?: Maybe<Scalars['String']>;
};

export type BaseResult = {
  id: Scalars['ID'];
  data: Scalars['String'];
  attribute: Scalars['String'];
};

export type Basin = Node & {
  __typename?: 'Basin';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BasinFilterInput = {
  search?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name__icontains?: Maybe<Scalars['String']>;
};

export type BasinInput = {
  name: Scalars['String'];
};

export type Cabinet = Node & {
  __typename?: 'Cabinet';
  id: Scalars['ID'];
  category?: Maybe<Category>;
  label?: Maybe<Scalars['String']>;
  documents?: Maybe<Connection>;
};


export type CabinetDocumentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<DocumentFilterInput>;
};

export type CabinetFilterInput = {
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
};

export type CabinetInput = {
  label?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
};

export type CabinetPayload = Payload & {
  __typename?: 'CabinetPayload';
  success?: Maybe<Scalars['Boolean']>;
  object?: Maybe<Cabinet>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export enum Category {
  /** Ticket */
  Tickets = 'TICKETS',
  /** Running Procedure */
  RunningProcedure = 'RUNNING_PROCEDURE',
  /** Technical Drawing */
  Drawing = 'DRAWING',
  /** Sales Orders */
  SalesOrders = 'SALES_ORDERS',
  /** Wells */
  Wells = 'WELLS'
}

export enum ChangeOutcome {
  /** Failed */
  Failed = 'FAILED',
  /** New */
  New = 'NEW',
  /** Updated */
  Updated = 'UPDATED'
}

export type ChangeRecord = Node & {
  __typename?: 'ChangeRecord';
  id: Scalars['ID'];
  item?: Maybe<InventoryItem>;
  part?: Maybe<InventoryItem>;
  product?: Maybe<InventoryItem>;
  outcome: ChangeOutcome;
  error?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  partDescription?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  rental?: Maybe<Scalars['Boolean']>;
  customerOwned?: Maybe<Scalars['Boolean']>;
  remanufactured?: Maybe<Scalars['Boolean']>;
  used?: Maybe<Scalars['Boolean']>;
  row?: Maybe<Scalars['String']>;
  createPart?: Maybe<Scalars['Boolean']>;
  createProduct?: Maybe<Scalars['Boolean']>;
  uom?: Maybe<UnitOfMeasure>;
  fields?: Maybe<Array<Maybe<ChangeRecordField>>>;
};

export type ChangeRecordField = {
  __typename?: 'ChangeRecordField';
  /** Cell for fields being updated via a spreadsheet upload. */
  cell?: Maybe<Scalars['String']>;
  /** Raw value from spreadsheet cell if applicable. */
  value?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type ChangeRecordFilterInput = {
  outcome?: Maybe<ChangeOutcome>;
  search?: Maybe<Scalars['String']>;
};

export type Charge = Node & TimeStamped & {
  __typename?: 'Charge';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  state?: Maybe<ChargeState>;
  priceState?: Maybe<ChargePriceState>;
  item?: Maybe<InventoryItem>;
  quantity?: Maybe<Scalars['Float']>;
  /** The suggested price is calculated from the charge item pricing fields */
  suggestedPrice?: Maybe<Scalars['Float']>;
  /** Customized price of the charge */
  price?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  ticketId?: Maybe<Scalars['ID']>;
  ticket?: Maybe<ServiceTicket>;
  priceRule?: Maybe<PriceRule>;
  /** If this charge was created by a required charge */
  fromRequiredCharge?: Maybe<Scalars['Boolean']>;
  /** Charge note, this should never be displayed to the customer */
  note?: Maybe<Scalars['String']>;
};

export type ChargeFilterInput = {
  id?: Maybe<Scalars['UUID']>;
  /** Show deleted and un-deleted charges */
  showDeleted?: Maybe<Scalars['Boolean']>;
  /** Show deleted required charges and only non-deleted regular charges */
  showDeletedRequired?: Maybe<Scalars['Boolean']>;
  item__in?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  order_by?: Maybe<Scalars['String']>;
  ticketIn?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  createdGt?: Maybe<Scalars['DateTime']>;
  createdLt?: Maybe<Scalars['DateTime']>;
  modifiedGt?: Maybe<Scalars['DateTime']>;
  modifiedLt?: Maybe<Scalars['DateTime']>;
};

export type ChargeInput = {
  id?: Maybe<Scalars['UUID']>;
  item?: Maybe<Scalars['ID']>;
  quantity?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priceRule?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
};

export type ChargePayload = Payload & {
  __typename?: 'ChargePayload';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
  object?: Maybe<Charge>;
};

export enum ChargePriceState {
  /** Not set */
  Unset = 'UNSET',
  /** Not edited */
  Unedited = 'UNEDITED',
  /** Edited */
  Edited = 'EDITED'
}

export enum ChargeState {
  /** Locked */
  Locked = 'LOCKED',
  /** Editable */
  Editable = 'EDITABLE'
}

export type City = Node & {
  __typename?: 'City';
  id: Scalars['ID'];
  name: Scalars['String'];
  state?: Maybe<State>;
  county?: Maybe<County>;
};

export type CityFilterInput = {
  search?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name__icontains?: Maybe<Scalars['String']>;
  countyId?: Maybe<Scalars['ID']>;
  stateId?: Maybe<Scalars['ID']>;
  shop?: Maybe<Scalars['ID']>;
  shops?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Company = Node & TimeStamped & {
  __typename?: 'Company';
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  name: Scalars['String'];
  description: Scalars['String'];
  ticker?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  number: Scalars['Int'];
  lastActiveTicket?: Maybe<TicketTypesUnion>;
  wellCount: Scalars['Int'];
  ticketCount: Scalars['Int'];
  sigDigitalEnabled: Scalars['Boolean'];
  sigImageUpload: Scalars['Boolean'];
  sigRemoteEmail: Scalars['Boolean'];
  sigStampImageUpload: Scalars['Boolean'];
  sigDigitalStamp: Scalars['Boolean'];
};

export type CompanyAcquiresWellInput = {
  company: Scalars['ID'];
  effectiveDate: Scalars['DateTime'];
  wellName?: Maybe<Scalars['String']>;
};

export type CompanyFilterInput = {
  search?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name__icontains?: Maybe<Scalars['String']>;
  ticker?: Maybe<Scalars['String']>;
  ticker__icontains?: Maybe<Scalars['String']>;
};

export type CompanyInput = {
  name: Scalars['String'];
  number: Scalars['Int'];
  sigDigitalEnabled: Scalars['Boolean'];
  sigImageUpload: Scalars['Boolean'];
  sigRemoteEmail: Scalars['Boolean'];
  sigStampImageUpload: Scalars['Boolean'];
  sigDigitalStamp: Scalars['Boolean'];
};

export type CompanyPayload = Payload & {
  __typename?: 'CompanyPayload';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
  object?: Maybe<Company>;
};

export type CompanyRenamesWellInput = {
  newName: Scalars['String'];
  effectiveDate?: Maybe<Scalars['DateTime']>;
};

export type Connection = ListPageInfo & {
  __typename?: 'Connection';
  pageInfo?: Maybe<PageInfo>;
  edges?: Maybe<Array<Maybe<Edge>>>;
};

export type ContentType = {
  __typename?: 'ContentType';
  id?: Maybe<Scalars['ID']>;
  appLabel?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  getEventsForObject: Connection;
  getPagedEventsForObject: Paginated;
  /** Given a content type retrieve a object's subscription via a connection */
  getEventSubscriptions?: Maybe<Connection>;
  /** Get event types in relation to an object content type */
  allEventTypes: Array<Maybe<EventType>>;
};


export type ContentTypeGetEventsForObjectArgs = {
  id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  association?: Maybe<EventAssociation>;
  filter?: Maybe<EventFilterInput>;
};


export type ContentTypeGetPagedEventsForObjectArgs = {
  id: Scalars['ID'];
  paged?: Maybe<PagedInput>;
  association?: Maybe<EventAssociation>;
  filter?: Maybe<EventFilterInput>;
};


export type ContentTypeGetEventSubscriptionsArgs = {
  id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
};


export type ContentTypeAllEventTypesArgs = {
  id: Scalars['ID'];
};

export type ContentTypeKeys = {
  appLabel: Scalars['String'];
  model: Scalars['String'];
};

export type ContentTypeMutation = {
  __typename?: 'ContentTypeMutation';
  id?: Maybe<Scalars['ID']>;
  appLabel: Scalars['String'];
  model: Scalars['String'];
  subscribeTo: SubscriptionPayload;
  /** add subscriptions for given events and object */
  addSubscriptions: MultipleSubscriptionPayload;
  /** remove given list of event subscriptions from object, remove all if no IDs are passed. */
  removeSubscriptions: MultipleSubscriptionPayload;
  subscribeToEvent: EventContentSubscriptionPayload;
  /** add subscriptions for given events and object */
  addEventSubscriptions: EventContentSubscriptionPayload;
  /** remove given list of event subscriptions from object, remove all if no IDs are passed. */
  removeEventSubscriptions: EventContentSubscriptionPayload;
};


export type ContentTypeMutationSubscribeToArgs = {
  id: Scalars['ID'];
  eventId?: Maybe<Scalars['ID']>;
};


export type ContentTypeMutationAddSubscriptionsArgs = {
  id: Scalars['ID'];
  eventIds: Array<Maybe<Scalars['ID']>>;
};


export type ContentTypeMutationRemoveSubscriptionsArgs = {
  id: Scalars['ID'];
  eventIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type ContentTypeMutationSubscribeToEventArgs = {
  id: Scalars['ID'];
  eventId?: Maybe<Scalars['ID']>;
};


export type ContentTypeMutationAddEventSubscriptionsArgs = {
  id: Scalars['ID'];
  eventIds: Array<Maybe<Scalars['ID']>>;
};


export type ContentTypeMutationRemoveEventSubscriptionsArgs = {
  id: Scalars['ID'];
  eventIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ConversionTable = Node & {
  __typename?: 'ConversionTable';
  id: Scalars['ID'];
  synced?: Maybe<Scalars['DateTime']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  inputName?: Maybe<Scalars['String']>;
  outputName?: Maybe<Scalars['String']>;
  inverted?: Maybe<Scalars['Boolean']>;
  clamped?: Maybe<Scalars['Boolean']>;
  inputMax?: Maybe<Scalars['Float']>;
  inputMin?: Maybe<Scalars['Float']>;
  outputMax?: Maybe<Scalars['Float']>;
  outputMin?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['String']>;
};

export type ConversionTableFilterInput = {
  syncedGt?: Maybe<Scalars['DateTime']>;
};

export type ConversionTableInput = {
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  inputName?: Maybe<Scalars['String']>;
  outputName?: Maybe<Scalars['String']>;
  inverted?: Maybe<Scalars['Boolean']>;
  clamped?: Maybe<Scalars['Boolean']>;
  inputMax?: Maybe<Scalars['Float']>;
  inputMin?: Maybe<Scalars['Float']>;
  outputMax?: Maybe<Scalars['Float']>;
  outputMin?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['String']>;
};

export type ConversionTablePayload = Payload & {
  __typename?: 'ConversionTablePayload';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
  object?: Maybe<ConversionTable>;
};

export type Country = Node & {
  __typename?: 'Country';
  id: Scalars['ID'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
};

export type CountryFilterInput = {
  name?: Maybe<Scalars['String']>;
  name__icontains?: Maybe<Scalars['String']>;
};

export type County = Node & {
  __typename?: 'County';
  id: Scalars['ID'];
  name: Scalars['String'];
  state?: Maybe<State>;
};

export type CountyFilterInput = {
  search?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name__icontains?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['ID']>;
};

export type CreateRunningProcedureInput = {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['ID']>;
};

export type Currency = {
  __typename?: 'Currency';
  name?: Maybe<Scalars['String']>;
};

export type CustomerInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type CustomerPayload = Payload & {
  __typename?: 'CustomerPayload';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
  object?: Maybe<User>;
};



export type DeleteRunningProcedureTicketStepResultInput = {
  id: Scalars['ID'];
};

export type DocIdInput = {
  id: Scalars['ID'];
  versionId?: Maybe<Scalars['ID']>;
};

export type Document = Node & {
  __typename?: 'Document';
  cabinets?: Maybe<Connection>;
  cachedThumbnail?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  exif?: Maybe<ExifData>;
  id: Scalars['ID'];
  inTrash?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  original?: Maybe<Scalars['URL']>;
  size?: Maybe<Scalars['Int']>;
  status?: Maybe<FileUploadStatus>;
  thumbnail?: Maybe<Scalars['URL']>;
  type?: Maybe<DocumentType>;
  uuid?: Maybe<Scalars['UUID']>;
  versionId?: Maybe<Scalars['ID']>;
};


export type DocumentCabinetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CabinetFilterInput>;
};


export type DocumentOriginalArgs = {
  forceDownload?: Maybe<Scalars['Boolean']>;
};


export type DocumentThumbnailArgs = {
  input: ThumbnailInput;
};

export type DocumentFilterInput = {
  /** Find one document by ID */
  id?: Maybe<Scalars['ID']>;
  /** Find one document by UUID */
  uuid?: Maybe<Scalars['UUID']>;
  /** Filter by a list of document UUIDs */
  uuidIn?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Used the retrieve a set of documents at specific version */
  idsIn?: Maybe<Array<Maybe<DocIdInput>>>;
  type?: Maybe<DocumentType>;
  typeIn?: Maybe<Array<Maybe<DocumentType>>>;
  cabinetId?: Maybe<Scalars['ID']>;
  cabinetLabel?: Maybe<Scalars['String']>;
  cabinetLabelIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  label?: Maybe<Scalars['String']>;
  label_lookup?: Maybe<Scalars['String']>;
  label_exclude?: Maybe<Scalars['String']>;
  label_exclude_lookup?: Maybe<Scalars['String']>;
};

export type DocumentPayload = Payload & {
  __typename?: 'DocumentPayload';
  success: Scalars['Boolean'];
  object?: Maybe<DocumentResult>;
  errors: Array<FieldError>;
};

export type DocumentResult = Document | PartialDocument;

export enum DocumentType {
  /** Printed Ticket */
  PrintedTicket = 'PRINTED_TICKET',
  /** Signed Document */
  SignedDocument = 'SIGNED_DOCUMENT',
  /** Process Image */
  ProcessImage = 'PROCESS_IMAGE',
  /** Image */
  Image = 'IMAGE',
  /** Document */
  Document = 'DOCUMENT',
  /** Signature */
  Signature = 'SIGNATURE'
}

export type EdmsCabinetTicket = {
  cabinetId?: Maybe<Scalars['ID']>;
};

export type ExifData = {
  __typename?: 'EXIFData';
  createDate?: Maybe<Scalars['DateTime']>;
  imageHeight?: Maybe<Scalars['Int']>;
  imageWidth?: Maybe<Scalars['Int']>;
  mimetype?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type Edge = {
  __typename?: 'Edge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Node>;
};


export type EmailTicketInput = {
  ticketIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  to: Array<Maybe<Scalars['String']>>;
  subject: Scalars['String'];
  body: Scalars['String'];
};

export type EmailTicketPayload = Payload & {
  __typename?: 'EmailTicketPayload';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export enum ErpSalesOrderStatuses {
  /** Estimate */
  Estimate = 'ESTIMATE',
  /** Issued */
  Issued = 'ISSUED',
  /** In Progress */
  InProgress = 'IN_PROGRESS',
  /** Fulfilled */
  Fulfilled = 'FULFILLED',
  /** Closed Short */
  ClosedShort = 'CLOSED_SHORT',
  /** Voided */
  Voided = 'VOIDED',
  /** Cancelled */
  Cancelled = 'CANCELLED',
  /** Expired */
  Expired = 'EXPIRED',
  /** Historical */
  Historical = 'HISTORICAL'
}

export type ErrorDetail = {
  __typename?: 'ErrorDetail';
  error?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

/**
 * Events are triggered by different backend actions and records relations between different
 * participating objects.
 *
 * Is a representation of the Action model from django-activity-stream module.
 * More details can be found at: https://django-activity-stream.readthedocs.io/en/latest/concepts.html
 */
export type Event = Node & {
  __typename?: 'Event';
  id: Scalars['ID'];
  /** The object that generated this event */
  actor: Node;
  /** The verb phrase that identifies the action of the activity */
  verb: Scalars['String'];
  /** Local event type representation */
  type: EventType;
  /** The object to which the activity was performed */
  target?: Maybe<Node>;
  /** The object linked to the action itself */
  object: Node;
  /** A Custom description of this event */
  description?: Maybe<Scalars['String']>;
  /** The time this event was performed */
  timestamp: Scalars['DateTime'];
  /** Public events are available for all to see */
  public: Scalars['Boolean'];
  /** misc data attached to event */
  data?: Maybe<Scalars['JSON']>;
  /** False if required data is missing for default event */
  hasAllRequiredData?: Maybe<Scalars['Boolean']>;
  /** Proxy to actor name identifier */
  actorName: Scalars['String'];
  /** Proxy to target name identifier */
  targetName?: Maybe<Scalars['String']>;
  /** Proxy to object name identifier */
  objectName?: Maybe<Scalars['String']>;
  actorId?: Maybe<Scalars['ID']>;
  targetId?: Maybe<Scalars['ID']>;
  objectId?: Maybe<Scalars['ID']>;
  /** formatted time since event was created, can pass optional comparison time as date */
  timesince?: Maybe<Scalars['String']>;
};


/**
 * Events are triggered by different backend actions and records relations between different
 * participating objects.
 *
 * Is a representation of the Action model from django-activity-stream module.
 * More details can be found at: https://django-activity-stream.readthedocs.io/en/latest/concepts.html
 */
export type EventTimesinceArgs = {
  date?: Maybe<Scalars['DateTime']>;
};

export enum EventAssociation {
  Actor = 'ACTOR',
  Target = 'TARGET',
  Object = 'OBJECT'
}

export type EventContentSubscriptionPayload = Payload & {
  __typename?: 'EventContentSubscriptionPayload';
  success: Scalars['Boolean'];
  errors?: Maybe<Array<Maybe<FieldError>>>;
  events: Array<Maybe<EventType>>;
};

export type EventFilterInput = {
  id?: Maybe<Scalars['ID']>;
  id__in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  timestamp__gt?: Maybe<Scalars['DateTime']>;
  timestamp__gte?: Maybe<Scalars['DateTime']>;
  timestamp__lt?: Maybe<Scalars['DateTime']>;
  timestamp__lte?: Maybe<Scalars['DateTime']>;
  public?: Maybe<Scalars['Boolean']>;
  verb?: Maybe<Scalars['String']>;
  verbIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  verb__startswith?: Maybe<Scalars['String']>;
};

export type EventMessage = {
  __typename?: 'EventMessage';
  context: Scalars['String'];
  message: Scalars['String'];
};

export type EventSubscription = Node & TimeStamped & SharedSubscription & {
  __typename?: 'EventSubscription';
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  user: User;
  eventType: EventType;
};

export type EventSubscriptionUnion = EventSubscription | ObjectEventSubscription;

/**
 * An event type is a reference to potential event verbs that users may be
 * interested in subscribing to.
 */
export type EventType = {
  __typename?: 'EventType';
  id: Scalars['ID'];
  label: Scalars['String'];
  name: Scalars['String'];
  namespace?: Maybe<EventTypeNamespace>;
  messages?: Maybe<Array<Maybe<EventMessage>>>;
  /**
   * When retrieving an event under a subscribable object,
   * this returns true when the user is subscribed to the object with an event of this type.
   */
  isObjectSubscribed: Scalars['Boolean'];
  /** Returns true when the current user is subscribed to this event globally. */
  isSubscribed: Scalars['Boolean'];
};

/** Event type namespace used to group events by app or area of concern. */
export type EventTypeNamespace = {
  __typename?: 'EventTypeNamespace';
  label: Scalars['String'];
  name: Scalars['String'];
  types?: Maybe<Array<Maybe<EventType>>>;
};

export type FieldError = {
  __typename?: 'FieldError';
  name?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<ErrorDetail>>>;
};

export enum FieldType {
  /** Length (Inches Only) */
  Length = 'LENGTH',
  /** Length (Feet and Inches) */
  LengthFt = 'LENGTH_FT',
  /** Photo */
  Photo = 'PHOTO',
  /** Temperature */
  Temperature = 'TEMPERATURE',
  /** Weight */
  Weight = 'WEIGHT',
  /** Pressure */
  Pressure = 'PRESSURE',
  /** Duration */
  Duration = 'DURATION',
  /** Pressure and Duration */
  PressureAndDuration = 'PRESSURE_AND_DURATION',
  /** Single Select */
  SingleSelect = 'SINGLE_SELECT',
  /** Text */
  Text = 'TEXT',
  /** Confirmation */
  Boolean = 'BOOLEAN',
  /** Casing Spec */
  SizeWeightGrade = 'SIZE_WEIGHT_GRADE',
  /** Torque */
  Torque = 'TORQUE',
  /** Size */
  Size = 'SIZE',
  /** Grade */
  Grade = 'GRADE',
  /** Date/Time */
  DateTime = 'DATE_TIME',
  /** Yes/No */
  YesNo = 'YES_NO',
  /** Digital Pressure Test */
  DigitalPressureTest = 'DIGITAL_PRESSURE_TEST'
}

export enum FileUploadStatus {
  Attached = 'ATTACHED',
  AttachedOffline = 'ATTACHED_OFFLINE',
  AttachedOfflineEnabled = 'ATTACHED_OFFLINE_ENABLED',
  DropError = 'DROP_ERROR',
  UploadError = 'UPLOAD_ERROR',
  UploadPending = 'UPLOAD_PENDING',
  Uploaded = 'UPLOADED',
  Uploading = 'UPLOADING',
  RemovalError = 'REMOVAL_ERROR'
}

/** A type that represents a user following the activities of a specific actor */
export type Follow = Node & TimeStamped & {
  __typename?: 'Follow';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  /** The user that this follow belongs to */
  user?: Maybe<User>;
  /** The object that is being followed */
  actor?: Maybe<Node>;
  /** A flag can be filtered by when looking up all follows */
  flag?: Maybe<Scalars['String']>;
  /** The date the user started following the actor */
  started?: Maybe<Scalars['DateTime']>;
  /** Only show events where the target is only the specified actor */
  actorOnly?: Maybe<Scalars['Boolean']>;
};

export type FollowFilterInput = {
  public?: Maybe<Scalars['Boolean']>;
};

export type Followable = {
  /** A connection of all users that follow this node */
  followers?: Maybe<Connection>;
  /** The count of users following this node  */
  followerCount: Scalars['Int'];
  /** Whether or not the current user is following this node */
  isFollowing?: Maybe<Scalars['Boolean']>;
};


export type FollowableFollowersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<FollowFilterInput>;
};

export type Group = Node & {
  __typename?: 'Group';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Permission>>>;
};

export type IntroduceRequiredChargesInput = {
  charges: Array<RequiredChargeInput>;
};

export type IntroduceRequiredChargesPayload = Payload & {
  __typename?: 'IntroduceRequiredChargesPayload';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
  objects: Array<RequiredCharge>;
};

export type InventoryItem = Node & TimeStamped & {
  __typename?: 'InventoryItem';
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  number: Scalars['String'];
  description: Scalars['String'];
  longDescription: Scalars['String'];
  currency?: Maybe<Currency>;
  suggestedPrice?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  standardCost?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['String']>;
  itemUom?: Maybe<UnitOfMeasure>;
  sizeUom?: Maybe<UnitOfMeasure>;
  weightUom?: Maybe<UnitOfMeasure>;
  active?: Maybe<Scalars['Boolean']>;
  partType?: Maybe<PartType>;
};

export type InventoryItemFilterInput = {
  order?: Maybe<InventoryOrderInput>;
  id?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  number__istartswith?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description__istartswith?: Maybe<Scalars['String']>;
  description__icontains?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  long_description__istartswith?: Maybe<Scalars['String']>;
  long_description__icontains?: Maybe<Scalars['String']>;
  inactive?: Maybe<Scalars['Boolean']>;
  partType?: Maybe<Scalars['ID']>;
  partTypes?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdGt?: Maybe<Scalars['DateTime']>;
  createdLt?: Maybe<Scalars['DateTime']>;
  modifiedGt?: Maybe<Scalars['DateTime']>;
  modifiedLt?: Maybe<Scalars['DateTime']>;
};

export type InventoryOrderInput = {
  rank?: Maybe<Sort>;
  number?: Maybe<Sort>;
  id?: Maybe<Sort>;
};


export type LimitOffsetInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ListPageInfo = {
  pageInfo?: Maybe<PageInfo>;
};

export type LogEntry = Node & {
  __typename?: 'LogEntry';
  id: Scalars['ID'];
  actionTime?: Maybe<Scalars['DateTime']>;
  actionFlag?: Maybe<ActionFlags>;
  changeMessage?: Maybe<Scalars['String']>;
};

export type MultipleNotificationsPayload = Payload & {
  __typename?: 'MultipleNotificationsPayload';
  success: Scalars['Boolean'];
  errors?: Maybe<Array<Maybe<FieldError>>>;
  objects: Array<Maybe<Notification>>;
};

export type MultipleSubscriptionPayload = Payload & {
  __typename?: 'MultipleSubscriptionPayload';
  success: Scalars['Boolean'];
  errors?: Maybe<Array<Maybe<FieldError>>>;
  objects: Array<Maybe<EventSubscriptionUnion>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAddress: AddressPayload;
  addCabinet?: Maybe<CabinetPayload>;
  addDocument?: Maybe<DocumentPayload>;
  addSignatureToDocument?: Maybe<AddSignaturePayload>;
  attachCompositeDocumentToResponse: SignatureResponsePayload;
  cancelSignatureRequest: SignatureRequestPayload;
  /** When a company acquires the well name and the related company changes */
  companyAcquiresWell: WellPayload;
  /** When a company has requested the well name be changed */
  companyRenameWell: WellPayload;
  createNewRunningProcedureRevision?: Maybe<RunningProcedureRevisionPayload>;
  createNewRunningProcedureVersion?: Maybe<RunningProcedureVersionPayload>;
  createRunningProcedure?: Maybe<RunningProcedurePayload>;
  deleteRunningProcedureResults: RunningProcedureTicketStepResultPayload;
  emailTickets: EmailTicketPayload;
  introduceCharge?: Maybe<ChargePayload>;
  introduceConversionTable?: Maybe<ConversionTablePayload>;
  introduceCustomer?: Maybe<CustomerPayload>;
  /** Create a new PartType with the provided data */
  introducePartType?: Maybe<PartTypePayload>;
  introducePressureRecording?: Maybe<PressureRecordingPayload>;
  introducePressureTransducer?: Maybe<PressureTransducerPayload>;
  introduceTicket: ServiceTicketPayload;
  introduceTorqueWrench?: Maybe<TorqueWrenchPayload>;
  introduceWell: WellPayload;
  markNotificationsRead?: Maybe<MultipleNotificationsPayload>;
  mutateNodeByKey?: Maybe<NodeMutation>;
  mutateNodeByType?: Maybe<NodeMutation>;
  mutateType?: Maybe<ContentTypeMutation>;
  mutateTypeByKey?: Maybe<ContentTypeMutation>;
  removeCharge?: Maybe<ChargePayload>;
  removeDocument?: Maybe<DocumentPayload>;
  /** Use when a well rename needs to affect all instances of a well */
  renameWell: WellPayload;
  resendSignatureRequest: SignatureRequestPayload;
  restoreCharge?: Maybe<ChargePayload>;
  saveRunningProcedureResults: RunningProcedureTicketStepResultPayload;
  sendEmail: SendEmailPayload;
  transitionSalesOrderStatus: SalesOrderPayload;
  transitionTicketStatus: ServiceTicketPayload;
  updateAppState: AppState;
  updateCabinet?: Maybe<CabinetPayload>;
  updateCharge?: Maybe<ChargePayload>;
  updateCompany: CompanyPayload;
  updateConversionTable?: Maybe<ConversionTablePayload>;
  /** Save some set of changes to the specific PartType */
  updatePartType?: Maybe<PartTypePayload>;
  updatePressureRecording?: Maybe<PressureRecordingPayload>;
  updatePressureTransducer?: Maybe<PressureTransducerPayload>;
  updateRunningProcedure?: Maybe<RunningProcedurePayload>;
  updateSignatureRequest: SignatureRequestPayload;
  updateTicket: ServiceTicketPayload;
  updateTorqueWrench?: Maybe<TorqueWrenchPayload>;
  updateUserSettings: UserSettings;
  /** Update well details, this updates well details which is shared between all well name versions */
  updateWellDetails: WellPayload;
  /** Upload a spreadsheet to be evaulated by a PartType configuration */
  uploadPartTypeSheet?: Maybe<PartTypePayload>;
};


export type MutationAddAddressArgs = {
  input: AddressInput;
};


export type MutationAddCabinetArgs = {
  input: CabinetInput;
};


export type MutationAddDocumentArgs = {
  input: AddDocumentInput;
};


export type MutationAddSignatureToDocumentArgs = {
  documentUUID: Scalars['UUID'];
  documentVersionId?: Maybe<Scalars['ID']>;
  input: AddSignatureInput;
};


export type MutationAttachCompositeDocumentToResponseArgs = {
  id: Scalars['ID'];
  input: AttachCompositeInput;
};


export type MutationCancelSignatureRequestArgs = {
  id: Scalars['ID'];
};


export type MutationCompanyAcquiresWellArgs = {
  wellId: Scalars['UUID'];
  input: CompanyAcquiresWellInput;
};


export type MutationCompanyRenameWellArgs = {
  wellId: Scalars['UUID'];
  input: CompanyRenamesWellInput;
};


export type MutationCreateNewRunningProcedureRevisionArgs = {
  input: RunningProcedureRevisionInput;
};


export type MutationCreateNewRunningProcedureVersionArgs = {
  input: RunningProcedureVersionInput;
};


export type MutationCreateRunningProcedureArgs = {
  input: CreateRunningProcedureInput;
};


export type MutationDeleteRunningProcedureResultsArgs = {
  ticketId: Scalars['UUID'];
  input: Array<Maybe<Scalars['ID']>>;
};


export type MutationEmailTicketsArgs = {
  input: EmailTicketInput;
};


export type MutationIntroduceChargeArgs = {
  ticketId: Scalars['UUID'];
  input: ChargeInput;
};


export type MutationIntroduceConversionTableArgs = {
  input: ConversionTableInput;
};


export type MutationIntroduceCustomerArgs = {
  input?: Maybe<CustomerInput>;
};


export type MutationIntroducePartTypeArgs = {
  input: PartTypeInput;
};


export type MutationIntroducePressureRecordingArgs = {
  input: PressureRecordingInput;
};


export type MutationIntroducePressureTransducerArgs = {
  input: PressureTransducerInput;
};


export type MutationIntroduceTicketArgs = {
  input: ServiceTicketInput;
};


export type MutationIntroduceTorqueWrenchArgs = {
  input: TorqueWrenchInput;
};


export type MutationIntroduceWellArgs = {
  input: WellInput;
};


export type MutationMarkNotificationsReadArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
};


export type MutationMutateNodeByKeyArgs = {
  keys: ContentTypeKeys;
  nodeId: Scalars['ID'];
};


export type MutationMutateNodeByTypeArgs = {
  id: Scalars['ID'];
  nodeId: Scalars['ID'];
};


export type MutationMutateTypeArgs = {
  id: Scalars['ID'];
};


export type MutationMutateTypeByKeyArgs = {
  keys: ContentTypeKeys;
};


export type MutationRemoveChargeArgs = {
  chargeId: Scalars['UUID'];
  note?: Maybe<Scalars['String']>;
};


export type MutationRemoveDocumentArgs = {
  documentUUID: Scalars['UUID'];
};


export type MutationRenameWellArgs = {
  wellId: Scalars['UUID'];
  input: WellRenameInput;
};


export type MutationResendSignatureRequestArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreChargeArgs = {
  chargeId: Scalars['UUID'];
};


export type MutationSaveRunningProcedureResultsArgs = {
  ticketId: Scalars['UUID'];
  input: Array<Maybe<RunningProcedureTicketStepResultInput>>;
};


export type MutationSendEmailArgs = {
  to: Array<Scalars['Email']>;
  from?: Maybe<Scalars['Email']>;
  subject: Scalars['String'];
  body: Scalars['String'];
};


export type MutationTransitionSalesOrderStatusArgs = {
  id: Scalars['ID'];
  method: Scalars['String'];
};


export type MutationTransitionTicketStatusArgs = {
  ticketId: Scalars['UUID'];
  transition: TicketStatusTransitions;
};


export type MutationUpdateAppStateArgs = {
  input: AppStateInput;
};


export type MutationUpdateCabinetArgs = {
  cabinetId: Scalars['ID'];
  input: CabinetInput;
};


export type MutationUpdateChargeArgs = {
  chargeId: Scalars['UUID'];
  input: ChargeInput;
};


export type MutationUpdateCompanyArgs = {
  id: Scalars['ID'];
  input: CompanyInput;
};


export type MutationUpdateConversionTableArgs = {
  id: Scalars['ID'];
  input: ConversionTableInput;
};


export type MutationUpdatePartTypeArgs = {
  id: Scalars['ID'];
  input: PartTypeInput;
  apply?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePressureRecordingArgs = {
  id: Scalars['ID'];
  input: PressureRecordingInput;
};


export type MutationUpdatePressureTransducerArgs = {
  id: Scalars['ID'];
  input: PressureTransducerInput;
};


export type MutationUpdateRunningProcedureArgs = {
  runningProcedureId: Scalars['ID'];
  input: UpdateRunningProcedureInput;
};


export type MutationUpdateSignatureRequestArgs = {
  id: Scalars['ID'];
  input: SignatureRequestInput;
};


export type MutationUpdateTicketArgs = {
  ticketId: Scalars['UUID'];
  input: ServiceTicketInput;
};


export type MutationUpdateTorqueWrenchArgs = {
  id: Scalars['ID'];
  input: TorqueWrenchInput;
};


export type MutationUpdateUserSettingsArgs = {
  input: UserSettingsInput;
};


export type MutationUpdateWellDetailsArgs = {
  wellId: Scalars['UUID'];
  input: WellDetailInput;
};


export type MutationUploadPartTypeSheetArgs = {
  id: Scalars['ID'];
  file: Scalars['Upload'];
  apply?: Maybe<Scalars['Boolean']>;
};

export type Node = {
  id: Scalars['ID'];
};

export type NodeMutation = Node & {
  __typename?: 'NodeMutation';
  id: Scalars['ID'];
  introduceRequiredCharges: IntroduceRequiredChargesPayload;
  /** Start a new or completed Signature Request */
  createSignatureRequest: SignatureRequestPayload;
  /** Upload signed document to a Signature Request, or create a stand alone Signature Response */
  createSignatureResponse: SignatureResponsePayload;
  /** Reject an existing or new signature request in relation to this node */
  rejectSignatureResponse?: Maybe<SignatureResponsePayload>;
};


export type NodeMutationIntroduceRequiredChargesArgs = {
  input: IntroduceRequiredChargesInput;
};


export type NodeMutationCreateSignatureRequestArgs = {
  input: SignatureRequestInput;
};


export type NodeMutationCreateSignatureResponseArgs = {
  requestId?: Maybe<Scalars['ID']>;
  input: SignatureResponseInput;
};


export type NodeMutationRejectSignatureResponseArgs = {
  requestId?: Maybe<Scalars['ID']>;
  input?: Maybe<RejectSignatureResponseInput>;
};

export type NodeUrls = {
  __typename?: 'NodeUrls';
  list: Scalars['URL'];
  detail: Scalars['URL'];
  print?: Maybe<Scalars['URL']>;
  pdf?: Maybe<Scalars['URL']>;
};

export type Notification = Node & TimeStamped & {
  __typename?: 'Notification';
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  event: Event;
  read: Scalars['Boolean'];
};

export type NotificationFilterInput = {
  read?: Maybe<Scalars['Boolean']>;
};

export enum NumberFormats {
  /** 4 Digit (service) */
  FourDigit = 'FOUR_DIGIT',
  /** 6 Digit */
  SixDigit = 'SIX_DIGIT',
  /** 7 Digit */
  SevenDigit = 'SEVEN_DIGIT'
}

export type ObjectEventSubscription = Node & TimeStamped & SharedSubscription & {
  __typename?: 'ObjectEventSubscription';
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  user: User;
  eventType: EventType;
  object: Node;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type PagedInfo = {
  __typename?: 'PagedInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  page: Scalars['Int'];
  totalPages: Scalars['Int'];
  count: Scalars['Int'];
};

export type PagedInput = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type Paginated = {
  __typename?: 'Paginated';
  pagedInfo: PagedInfo;
  objects: Array<Node>;
};

export type PartType = Node & Assignable & TimeStamped & {
  __typename?: 'PartType';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  numberFormat: NumberFormats;
  numberPrefix?: Maybe<Scalars['String']>;
  /** Whether or not this PartType will generate related Products when building descriptions */
  createProducts: Scalars['Boolean'];
  /** Whether or not this PartType will track revision levels */
  trackRevision: Scalars['Boolean'];
  /** Whether or not this PartType track serial numbers */
  trackSerial: Scalars['Boolean'];
  /** The default template based on the field order */
  defaultTemplate?: Maybe<Scalars['String']>;
  /** The template used to generate the Part description */
  partTemplate?: Maybe<Scalars['String']>;
  /** The template used to generate the Product description */
  productTemplate?: Maybe<Scalars['String']>;
  /** Add fields assoicated with this PartType */
  fields?: Maybe<Array<Maybe<PartTypeField>>>;
  /** Part count associated with PartType */
  partCount: Scalars['Int'];
  /** Part and Service count associated with PartType */
  partServiceCount: Scalars['Int'];
  /** Product count associated with PartType */
  productCount: Scalars['Int'];
  /** Retrieve the update spreadsheet URL for this PartType */
  spreadsheet?: Maybe<Scalars['URL']>;
  /** Count of all changes */
  totalChanges: Scalars['Int'];
  /** Count of changes that will fail */
  failedChanges: Scalars['Int'];
  /** Count of changes that will create a new part or product */
  newChanges: Scalars['Int'];
  /** Count of changes that will update a part or product */
  updatedChanges: Scalars['Int'];
  /** If this part type has/is a dirty version */
  hasDirty: Scalars['Boolean'];
  /** Change records related to part type */
  changes?: Maybe<Connection>;
  /** Engineer assignment users */
  engineers: Array<User>;
  /** Connection to all assignments */
  assignments?: Maybe<Connection>;
  assignment?: Maybe<Assignment>;
};


export type PartTypeSpreadsheetArgs = {
  filled?: Maybe<Scalars['Boolean']>;
};


export type PartTypeChangesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ChangeRecordFilterInput>;
};


export type PartTypeAssignmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AssignmentFilterInput>;
};


export type PartTypeAssignmentArgs = {
  type: AssignmentTypes;
};

export type PartTypeField = Node & TimeStamped & BaseFieldConfiguration & {
  __typename?: 'PartTypeField';
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  fieldType: FieldType;
  tag: Scalars['String'];
  wrappedTag: Scalars['String'];
  required: Scalars['Boolean'];
  options?: Maybe<Array<Maybe<PartTypeFieldOption>>>;
};

export type PartTypeFieldInput = {
  id?: Maybe<Scalars['ID']>;
  index?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  fieldType?: Maybe<FieldType>;
  options?: Maybe<Array<Maybe<PartTypeFieldOptionInput>>>;
};

export type PartTypeFieldOption = BaseFieldOption & {
  __typename?: 'PartTypeFieldOption';
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  shortValue?: Maybe<Scalars['String']>;
  longValue?: Maybe<Scalars['String']>;
};

export type PartTypeFieldOptionInput = {
  id?: Maybe<Scalars['ID']>;
  shortValue?: Maybe<Scalars['String']>;
  longValue?: Maybe<Scalars['String']>;
};

export type PartTypeFilterInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  name__icontains?: Maybe<Scalars['String']>;
  name__istartswith?: Maybe<Scalars['String']>;
  createdGt?: Maybe<Scalars['DateTime']>;
  createdLt?: Maybe<Scalars['DateTime']>;
  modifiedGt?: Maybe<Scalars['DateTime']>;
  modifiedLt?: Maybe<Scalars['DateTime']>;
};

export type PartTypeInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  numberFormat?: Maybe<NumberFormats>;
  numberPrefix?: Maybe<Scalars['String']>;
  createProducts?: Maybe<Scalars['Boolean']>;
  trackRevision?: Maybe<Scalars['Boolean']>;
  trackSerial?: Maybe<Scalars['Boolean']>;
  partTemplate?: Maybe<Scalars['String']>;
  productTemplate?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<Maybe<PartTypeFieldInput>>>;
  engineers?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type PartTypePayload = Payload & {
  __typename?: 'PartTypePayload';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
  mutationError?: Maybe<Scalars['String']>;
  object?: Maybe<PartType>;
};

export type PartialDocument = {
  __typename?: 'PartialDocument';
  uuid?: Maybe<Scalars['UUID']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<DocumentType>;
};

export type Payload = {
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export type Permission = Node & {
  __typename?: 'Permission';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  codename?: Maybe<Scalars['String']>;
};

export type PressureReading = {
  __typename?: 'PressureReading';
  ms?: Maybe<Scalars['Int']>;
  psi?: Maybe<Scalars['Float']>;
};

export type PressureReadingInput = {
  ms?: Maybe<Scalars['Int']>;
  psi?: Maybe<Scalars['Float']>;
};

export type PressureRecording = Node & {
  __typename?: 'PressureRecording';
  id: Scalars['ID'];
  synced?: Maybe<Scalars['DateTime']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  ticket?: Maybe<ServiceTicket>;
  salesOrder?: Maybe<SalesOrder>;
  tester?: Maybe<User>;
  startTime?: Maybe<Scalars['DateTime']>;
  stopTime?: Maybe<Scalars['DateTime']>;
  transducerSerial?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  msMarker?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  signature?: Maybe<Scalars['String']>;
  startPsi?: Maybe<Scalars['Float']>;
  stopPsi?: Maybe<Scalars['Float']>;
  readings?: Maybe<Array<Maybe<PressureReading>>>;
};

export type PressureRecordingFilterInput = {
  syncedGt?: Maybe<Scalars['DateTime']>;
};

export type PressureRecordingInput = {
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  ticket?: Maybe<Scalars['ID']>;
  salesOrder?: Maybe<Scalars['ID']>;
  tester?: Maybe<Scalars['ID']>;
  startTime?: Maybe<Scalars['DateTime']>;
  stopTime?: Maybe<Scalars['DateTime']>;
  transducerSerial?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  msMarker?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  signature?: Maybe<Scalars['String']>;
  startPsi?: Maybe<Scalars['Float']>;
  stopPsi?: Maybe<Scalars['Float']>;
  readings?: Maybe<Array<Maybe<PressureReadingInput>>>;
};

export type PressureRecordingPayload = Payload & {
  __typename?: 'PressureRecordingPayload';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
  object?: Maybe<PressureRecording>;
};

export type PressureTransducer = Node & {
  __typename?: 'PressureTransducer';
  id: Scalars['ID'];
  synced?: Maybe<Scalars['DateTime']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  dataRateMax?: Maybe<Scalars['Int']>;
  dataRateMin?: Maybe<Scalars['Int']>;
  psiMax?: Maybe<Scalars['Float']>;
  psiMin?: Maybe<Scalars['Float']>;
  conversionTable?: Maybe<ConversionTable>;
};

export type PressureTransducerFilterInput = {
  syncedGt?: Maybe<Scalars['DateTime']>;
};

export type PressureTransducerInput = {
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  dataRateMax?: Maybe<Scalars['Int']>;
  dataRateMin?: Maybe<Scalars['Int']>;
  psiMax?: Maybe<Scalars['Float']>;
  psiMin?: Maybe<Scalars['Float']>;
  conversionTable?: Maybe<Scalars['ID']>;
};

export type PressureTransducerPayload = Payload & {
  __typename?: 'PressureTransducerPayload';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
  object?: Maybe<PressureTransducer>;
};

export type PriceRule = Node & TimeStamped & {
  __typename?: 'PriceRule';
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  item: InventoryItem;
  company: Company;
  price: Scalars['Float'];
};

export type PriceRuleFilterInput = {
  productId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  companyIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdGt?: Maybe<Scalars['DateTime']>;
  createdLt?: Maybe<Scalars['DateTime']>;
  modifiedGt?: Maybe<Scalars['DateTime']>;
  modifiedLt?: Maybe<Scalars['DateTime']>;
};

export type Query = {
  __typename?: 'Query';
  address?: Maybe<Address>;
  allEventTypeNamespaces?: Maybe<Array<Maybe<EventTypeNamespace>>>;
  allEventTypes?: Maybe<Array<Maybe<EventType>>>;
  appState: AppState;
  appVars: AppVars;
  basin?: Maybe<Basin>;
  cabinet?: Maybe<Cabinet>;
  charge?: Maybe<Charge>;
  /** Allows the user to check a signature image visually before attaching it to a ticket */
  checkSignature?: Maybe<SignatureCheckPayload>;
  city?: Maybe<City>;
  company?: Maybe<Company>;
  conversionTable?: Maybe<ConversionTable>;
  country?: Maybe<Country>;
  county?: Maybe<County>;
  currentUser: User;
  document?: Maybe<Document>;
  documentByUUID?: Maybe<Document>;
  event?: Maybe<Event>;
  filterSalesOrder?: Maybe<SalesOrder>;
  filterTicket?: Maybe<ServiceTicket>;
  follow?: Maybe<Follow>;
  getAddresses?: Maybe<Connection>;
  /** Get all documents, even trashed ones. */
  getAllDocuments?: Maybe<Connection>;
  getAllPagedPartsServices: Paginated;
  getBasins: Connection;
  getCabinets?: Maybe<Connection>;
  getCharges?: Maybe<Connection>;
  getCities?: Maybe<Connection>;
  getCompanies?: Maybe<Connection>;
  getContentType?: Maybe<ContentType>;
  getContentTypeByKey?: Maybe<ContentType>;
  getConversionTables?: Maybe<Connection>;
  getCounties?: Maybe<Connection>;
  getCountries?: Maybe<Connection>;
  /** Get only valid documents. */
  getDocuments?: Maybe<Connection>;
  getEvents?: Maybe<Connection>;
  getFollows?: Maybe<Connection>;
  getInventoryItems?: Maybe<Connection>;
  getNodeByType?: Maybe<Node>;
  getNodeByTypeKey?: Maybe<Node>;
  getNotifications?: Maybe<Connection>;
  getOffsetTickets: Paginated;
  getPagedCompanies: Paginated;
  getPagedEvents: Paginated;
  getPagedInventoryItems: Paginated;
  getPagedParts: Paginated;
  getPagedProducts: Paginated;
  getPagedSalesOrders: Paginated;
  getPagedServices: Paginated;
  getPagedTickets: Paginated;
  getPagedUsers: Paginated;
  getPagedWells: Paginated;
  getPartTypes?: Maybe<Connection>;
  getParts?: Maybe<Connection>;
  getPressureRecordings?: Maybe<Connection>;
  getPressureTransducers?: Maybe<Connection>;
  getPriceRules?: Maybe<Connection>;
  getProducts?: Maybe<Connection>;
  getRunningProcedures?: Maybe<Connection>;
  getServices?: Maybe<Connection>;
  getSignatureRequest?: Maybe<SignatureRequest>;
  getSignatureResponse?: Maybe<SignatureResponse>;
  getStates?: Maybe<Connection>;
  getTicketCharges?: Maybe<Array<Maybe<Charge>>>;
  getTickets: Connection;
  getTorqueWrenches?: Maybe<Connection>;
  /** Only get trashed documents */
  getTrashedDocuments?: Maybe<Connection>;
  getUsers?: Maybe<Connection>;
  getWells: Connection;
  inventoryItem?: Maybe<InventoryItem>;
  objectSnapshot?: Maybe<SignableObjectsUnion>;
  part?: Maybe<InventoryItem>;
  partType?: Maybe<PartType>;
  pressureRecording?: Maybe<PressureRecording>;
  pressureTransducer?: Maybe<PressureTransducer>;
  priceRule?: Maybe<PriceRule>;
  product?: Maybe<InventoryItem>;
  runningProcedure?: Maybe<RunningProcedure>;
  runningProcedureRevision?: Maybe<RunningProcedureRevision>;
  runningProcedureVersion?: Maybe<RunningProcedureVersion>;
  salesOrder?: Maybe<SalesOrder>;
  service?: Maybe<InventoryItem>;
  /** TODO: move all service ticket related types+queries to this file */
  serviceTicket?: Maybe<ServiceTicket>;
  state?: Maybe<State>;
  ticket?: Maybe<ServiceTicket>;
  ticketPhaseOptions: Array<TicketPhaseOptions>;
  ticketSnapshot?: Maybe<TicketTypesUnion>;
  torqueWrench?: Maybe<TorqueWrench>;
  user?: Maybe<User>;
  userSettings: UserSettings;
  waffle?: Maybe<Waffle>;
  well?: Maybe<Well>;
};


export type QueryAddressArgs = {
  id: Scalars['ID'];
  filter?: Maybe<AddressFilterInput>;
};


export type QueryBasinArgs = {
  basinId: Scalars['ID'];
};


export type QueryCabinetArgs = {
  cabinetId: Scalars['ID'];
};


export type QueryChargeArgs = {
  chargeId: Scalars['UUID'];
};


export type QueryCheckSignatureArgs = {
  file: Scalars['Upload'];
};


export type QueryCityArgs = {
  id: Scalars['ID'];
  filter?: Maybe<CityFilterInput>;
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryConversionTableArgs = {
  id: Scalars['ID'];
};


export type QueryCountryArgs = {
  id: Scalars['ID'];
  filter?: Maybe<CountryFilterInput>;
};


export type QueryCountyArgs = {
  id: Scalars['ID'];
  filter?: Maybe<CountyFilterInput>;
};


export type QueryDocumentArgs = {
  documentId: Scalars['ID'];
};


export type QueryDocumentByUuidArgs = {
  documentUUID: Scalars['UUID'];
};


export type QueryEventArgs = {
  id: Scalars['ID'];
};


export type QueryFilterSalesOrderArgs = {
  filter: SalesOrderFilterInput;
};


export type QueryFilterTicketArgs = {
  filter: TicketFilterInput;
};


export type QueryFollowArgs = {
  id: Scalars['ID'];
};


export type QueryGetAddressesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AddressFilterInput>;
};


export type QueryGetAllDocumentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<DocumentFilterInput>;
};


export type QueryGetAllPagedPartsServicesArgs = {
  paged?: Maybe<PagedInput>;
  filter?: Maybe<InventoryItemFilterInput>;
};


export type QueryGetBasinsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<BasinFilterInput>;
};


export type QueryGetCabinetsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CabinetFilterInput>;
};


export type QueryGetChargesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ChargeFilterInput>;
};


export type QueryGetCitiesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CityFilterInput>;
};


export type QueryGetCompaniesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CompanyFilterInput>;
};


export type QueryGetContentTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetContentTypeByKeyArgs = {
  keys: ContentTypeKeys;
};


export type QueryGetConversionTablesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ConversionTableFilterInput>;
};


export type QueryGetCountiesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CountyFilterInput>;
};


export type QueryGetCountriesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<CountryFilterInput>;
};


export type QueryGetDocumentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<DocumentFilterInput>;
};


export type QueryGetEventsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<EventFilterInput>;
};


export type QueryGetFollowsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<FollowFilterInput>;
};


export type QueryGetInventoryItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<InventoryItemFilterInput>;
};


export type QueryGetNodeByTypeArgs = {
  id: Scalars['ID'];
  nodeId: Scalars['ID'];
};


export type QueryGetNodeByTypeKeyArgs = {
  keys: ContentTypeKeys;
  nodeId: Scalars['ID'];
};


export type QueryGetNotificationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<NotificationFilterInput>;
};


export type QueryGetOffsetTicketsArgs = {
  paged?: Maybe<LimitOffsetInput>;
  filter?: Maybe<TicketFilterInput>;
};


export type QueryGetPagedCompaniesArgs = {
  paged?: Maybe<PagedInput>;
  filter?: Maybe<CompanyFilterInput>;
};


export type QueryGetPagedEventsArgs = {
  paged?: Maybe<PagedInput>;
  filter?: Maybe<EventFilterInput>;
};


export type QueryGetPagedInventoryItemsArgs = {
  paged?: Maybe<PagedInput>;
  filter?: Maybe<InventoryItemFilterInput>;
};


export type QueryGetPagedPartsArgs = {
  paged?: Maybe<PagedInput>;
  filter?: Maybe<InventoryItemFilterInput>;
};


export type QueryGetPagedProductsArgs = {
  paged?: Maybe<PagedInput>;
  filter?: Maybe<InventoryItemFilterInput>;
};


export type QueryGetPagedSalesOrdersArgs = {
  paged?: Maybe<PagedInput>;
  filter?: Maybe<SalesOrderFilterInput>;
};


export type QueryGetPagedServicesArgs = {
  paged?: Maybe<PagedInput>;
  filter?: Maybe<InventoryItemFilterInput>;
};


export type QueryGetPagedTicketsArgs = {
  paged?: Maybe<PagedInput>;
  filter?: Maybe<TicketFilterInput>;
};


export type QueryGetPagedUsersArgs = {
  paged?: Maybe<PagedInput>;
  filter?: Maybe<UserFilterInput>;
};


export type QueryGetPagedWellsArgs = {
  paged?: Maybe<PagedInput>;
  filter?: Maybe<WellFilterInput>;
};


export type QueryGetPartTypesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<PartTypeFilterInput>;
};


export type QueryGetPartsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<InventoryItemFilterInput>;
};


export type QueryGetPressureRecordingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<PressureRecordingFilterInput>;
};


export type QueryGetPressureTransducersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<PressureTransducerFilterInput>;
};


export type QueryGetPriceRulesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<PriceRuleFilterInput>;
};


export type QueryGetProductsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<InventoryItemFilterInput>;
};


export type QueryGetRunningProceduresArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<RunningProcedureFilterInput>;
};


export type QueryGetServicesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<InventoryItemFilterInput>;
};


export type QueryGetSignatureRequestArgs = {
  id: Scalars['ID'];
};


export type QueryGetSignatureResponseArgs = {
  id: Scalars['ID'];
};


export type QueryGetStatesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<StateFilterInput>;
};


export type QueryGetTicketChargesArgs = {
  ticketId: Scalars['UUID'];
  filter?: Maybe<ChargeFilterInput>;
};


export type QueryGetTicketsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<TicketFilterInput>;
};


export type QueryGetTorqueWrenchesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<TorqueWrenchFilterInput>;
};


export type QueryGetTrashedDocumentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<DocumentFilterInput>;
};


export type QueryGetUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<UserFilterInput>;
};


export type QueryGetWellsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<WellFilterInput>;
};


export type QueryInventoryItemArgs = {
  id: Scalars['ID'];
  filter?: Maybe<InventoryItemFilterInput>;
};


export type QueryObjectSnapshotArgs = {
  id: Scalars['ID'];
};


export type QueryPartArgs = {
  id: Scalars['ID'];
  filter?: Maybe<InventoryItemFilterInput>;
};


export type QueryPartTypeArgs = {
  id: Scalars['ID'];
};


export type QueryPressureRecordingArgs = {
  id: Scalars['ID'];
};


export type QueryPressureTransducerArgs = {
  id: Scalars['ID'];
};


export type QueryPriceRuleArgs = {
  id: Scalars['ID'];
};


export type QueryProductArgs = {
  id: Scalars['ID'];
  filter?: Maybe<InventoryItemFilterInput>;
};


export type QueryRunningProcedureArgs = {
  runningProcedureId: Scalars['ID'];
};


export type QueryRunningProcedureRevisionArgs = {
  revisionId: Scalars['ID'];
};


export type QueryRunningProcedureVersionArgs = {
  versionId: Scalars['ID'];
};


export type QuerySalesOrderArgs = {
  id: Scalars['ID'];
};


export type QueryServiceArgs = {
  id: Scalars['ID'];
  filter?: Maybe<InventoryItemFilterInput>;
};


export type QueryServiceTicketArgs = {
  id: Scalars['UUID'];
};


export type QueryStateArgs = {
  id: Scalars['ID'];
  filter?: Maybe<StateFilterInput>;
};


export type QueryTicketArgs = {
  ticketId: Scalars['UUID'];
};


export type QueryTicketSnapshotArgs = {
  snapshotId: Scalars['ID'];
};


export type QueryTorqueWrenchArgs = {
  id: Scalars['ID'];
};


export type QueryUserArgs = {
  userId: Scalars['ID'];
};


export type QueryWellArgs = {
  wellId: Scalars['UUID'];
  filter?: Maybe<WellFilterInput>;
};

export type RejectSignatureResponseInput = {
  signatoryName?: Maybe<Scalars['String']>;
  signatoryEmail?: Maybe<Scalars['Email']>;
  rejectReason?: Maybe<Scalars['String']>;
};

export type RepairTicket = Signable & TicketInterface & Node & Assignable & TimeStamped & StatusableTicket & EdmsCabinetTicket & {
  __typename?: 'RepairTicket';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  number: Scalars['ID'];
  status: TicketStatuses;
  cabinetId?: Maybe<Scalars['ID']>;
  assignments?: Maybe<Connection>;
  /** Retrieve an Assignment from singular AssignmentType */
  assignment?: Maybe<Assignment>;
  signatureState: SignatureStates;
  signatureStateChangedDate: Scalars['DateTime'];
  latestSignatureResponse?: Maybe<SignatureResponse>;
  latestSignatureRequest?: Maybe<SignatureRequest>;
  urls: NodeUrls;
};


export type RepairTicketAssignmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AssignmentFilterInput>;
};


export type RepairTicketAssignmentArgs = {
  type: AssignmentTypes;
};

export type RequiredCharge = Node & TimeStamped & {
  __typename?: 'RequiredCharge';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['Int']>;
  item?: Maybe<InventoryItem>;
  quantity: Scalars['Float'];
  chargeableObject: Node;
  suggestedPrice?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  priceRule?: Maybe<PriceRule>;
};

export type RequiredChargeInput = {
  item: Scalars['ID'];
  quantity: Scalars['Float'];
  order?: Maybe<Scalars['Int']>;
};

export type RunningProcedure = Node & TimeStamped & Assignable & {
  __typename?: 'RunningProcedure';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  cabinetId?: Maybe<Scalars['ID']>;
  versions?: Maybe<Array<Maybe<RunningProcedureVersion>>>;
  assignments?: Maybe<Connection>;
  assignment?: Maybe<Assignment>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  documents?: Maybe<Connection>;
};


export type RunningProcedureAssignmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AssignmentFilterInput>;
};


export type RunningProcedureAssignmentArgs = {
  type: AssignmentTypes;
};


export type RunningProcedureDocumentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<DocumentFilterInput>;
};

export type RunningProcedureFilterInput = {
  id?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  title__istartswith?: Maybe<Scalars['String']>;
  title__icontains?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description__istartswith?: Maybe<Scalars['String']>;
  description__icontains?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['ID']>;
  companies?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdGt?: Maybe<Scalars['DateTime']>;
  createdLt?: Maybe<Scalars['DateTime']>;
  modifiedGt?: Maybe<Scalars['DateTime']>;
  modifiedLt?: Maybe<Scalars['DateTime']>;
};

export type RunningProcedurePayload = Payload & {
  __typename?: 'RunningProcedurePayload';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
  object?: Maybe<RunningProcedure>;
};

export type RunningProcedureRevision = Node & TimeStamped & Assignable & {
  __typename?: 'RunningProcedureRevision';
  id: Scalars['ID'];
  version?: Maybe<RunningProcedureVersion>;
  number?: Maybe<Scalars['String']>;
  steps?: Maybe<Array<Maybe<RunningProcedureStep>>>;
  assignments?: Maybe<Connection>;
  assignment?: Maybe<Assignment>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  requiredCharges: Array<RequiredCharge>;
};


export type RunningProcedureRevisionAssignmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AssignmentFilterInput>;
};


export type RunningProcedureRevisionAssignmentArgs = {
  type: AssignmentTypes;
};

export type RunningProcedureRevisionInput = {
  versionId: Scalars['ID'];
  steps?: Maybe<Array<RunningProcedureStepInput>>;
  requiredCharges?: Maybe<Array<RequiredChargeInput>>;
};

export type RunningProcedureRevisionPayload = Payload & {
  __typename?: 'RunningProcedureRevisionPayload';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
  object?: Maybe<RunningProcedureRevision>;
};

export type RunningProcedureStep = Node & TimeStamped & BaseFieldConfiguration & Assignable & {
  __typename?: 'RunningProcedureStep';
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  fieldType: FieldType;
  required: Scalars['Boolean'];
  order?: Maybe<Scalars['Int']>;
  pageNumber?: Maybe<Scalars['Int']>;
  assignments?: Maybe<Connection>;
  assignment?: Maybe<Assignment>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
};


export type RunningProcedureStepAssignmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AssignmentFilterInput>;
};


export type RunningProcedureStepAssignmentArgs = {
  type: AssignmentTypes;
};

export type RunningProcedureStepInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  order: Scalars['Int'];
  fieldType: FieldType;
  description?: Maybe<Scalars['String']>;
  pageNumber?: Maybe<Scalars['Int']>;
  required?: Maybe<Scalars['Boolean']>;
};

export type RunningProcedureTicketStepResult = Node & TimeStamped & Assignable & BaseResult & {
  __typename?: 'RunningProcedureTicketStepResult';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  runningProcedureStep: RunningProcedureStep;
  data: Scalars['String'];
  attribute: Scalars['String'];
  completedOn?: Maybe<Scalars['DateTime']>;
  assignments?: Maybe<Connection>;
  assignment?: Maybe<Assignment>;
};


export type RunningProcedureTicketStepResultAssignmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AssignmentFilterInput>;
};


export type RunningProcedureTicketStepResultAssignmentArgs = {
  type: AssignmentTypes;
};

export type RunningProcedureTicketStepResultInput = {
  id?: Maybe<Scalars['ID']>;
  runningProcedureStepId: Scalars['ID'];
  data: Scalars['String'];
  attribute?: Maybe<Scalars['String']>;
  completedOn?: Maybe<Scalars['DateTime']>;
};

export type RunningProcedureTicketStepResultPayload = Payload & {
  __typename?: 'RunningProcedureTicketStepResultPayload';
  success?: Maybe<Scalars['Boolean']>;
  object?: Maybe<ServiceTicket>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export type RunningProcedureVersion = Node & TimeStamped & Assignable & {
  __typename?: 'RunningProcedureVersion';
  id: Scalars['ID'];
  runningProcedure?: Maybe<RunningProcedure>;
  number?: Maybe<Scalars['String']>;
  revisions?: Maybe<Array<Maybe<RunningProcedureRevision>>>;
  assignments?: Maybe<Connection>;
  assignment?: Maybe<Assignment>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
};


export type RunningProcedureVersionAssignmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AssignmentFilterInput>;
};


export type RunningProcedureVersionAssignmentArgs = {
  type: AssignmentTypes;
};

export type RunningProcedureVersionInput = {
  runningProcedureId?: Maybe<Scalars['ID']>;
  number: Scalars['String'];
};

export type RunningProcedureVersionPayload = Payload & {
  __typename?: 'RunningProcedureVersionPayload';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
  object?: Maybe<RunningProcedureVersion>;
};

export type SalesOrder = Signable & TicketInterface & Node & Assignable & TimeStamped & EdmsCabinetTicket & {
  __typename?: 'SalesOrder';
  id: Scalars['ID'];
  number: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  company?: Maybe<Company>;
  cabinetId?: Maybe<Scalars['ID']>;
  status: SalesOrderStatuses;
  statusChangedDate: Scalars['DateTime'];
  availableStatusTransitions: Array<TransitionMethod>;
  urls: NodeUrls;
  billTo?: Maybe<SalesOrderAddress>;
  shipTo?: Maybe<SalesOrderAddress>;
  totalPrice?: Maybe<Scalars['Float']>;
  totalTax?: Maybe<Scalars['Float']>;
  itemTotal?: Maybe<Scalars['Float']>;
  issuedDate?: Maybe<Scalars['DateTime']>;
  scheduledDate?: Maybe<Scalars['DateTime']>;
  shippedDate?: Maybe<Scalars['DateTime']>;
  shippingTerms?: Maybe<Scalars['String']>;
  paymentTerms?: Maybe<Scalars['String']>;
  carrier?: Maybe<Scalars['String']>;
  fobPoint?: Maybe<Scalars['String']>;
  salesman?: Maybe<User>;
  signatureState: SignatureStates;
  signatureStateChangedDate: Scalars['DateTime'];
  assignments?: Maybe<Connection>;
  /** Retrieve an Assignment from singular AssignmentType */
  assignment?: Maybe<Assignment>;
  items: Array<SalesOrderItem>;
  itemsCount?: Maybe<Scalars['Int']>;
  ERPClass?: Maybe<Scalars['String']>;
  ERPStatus: ErpSalesOrderStatuses;
  latestSignatureResponse?: Maybe<SignatureResponse>;
  latestSignatureRequest?: Maybe<SignatureRequest>;
};


export type SalesOrderAssignmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AssignmentFilterInput>;
};


export type SalesOrderAssignmentArgs = {
  type: AssignmentTypes;
};

export type SalesOrderAddress = Node & TimeStamped & {
  __typename?: 'SalesOrderAddress';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type SalesOrderFilterInput = {
  id?: Maybe<Scalars['UUID']>;
  search?: Maybe<Scalars['String']>;
  excludeTickets?: Maybe<Scalars['Boolean']>;
  order?: Maybe<SalesOrderOrderInput>;
  status?: Maybe<SalesOrderStatuses>;
  statuses?: Maybe<Array<Maybe<SalesOrderStatuses>>>;
  statusChangedDateGt?: Maybe<Scalars['DateTime']>;
  statusChangedDateLt?: Maybe<Scalars['DateTime']>;
  erpClass?: Maybe<Scalars['String']>;
  erpClass_istartswith?: Maybe<Scalars['String']>;
  erpClass_icontains?: Maybe<Scalars['String']>;
  erpStatus?: Maybe<ErpSalesOrderStatuses>;
  erpStatuses?: Maybe<Array<Maybe<ErpSalesOrderStatuses>>>;
  number?: Maybe<Scalars['String']>;
  number__istartswith?: Maybe<Scalars['String']>;
  number__icontains?: Maybe<Scalars['String']>;
  createdGt?: Maybe<Scalars['DateTime']>;
  createdLt?: Maybe<Scalars['DateTime']>;
  modifiedGt?: Maybe<Scalars['DateTime']>;
  modifiedLt?: Maybe<Scalars['DateTime']>;
  issuedGt?: Maybe<Scalars['DateTime']>;
  issuedLt?: Maybe<Scalars['DateTime']>;
  scheduledGte?: Maybe<Scalars['DateTime']>;
  scheduledLte?: Maybe<Scalars['DateTime']>;
  company?: Maybe<Scalars['ID']>;
  companies?: Maybe<Array<Maybe<Scalars['ID']>>>;
  shipToNameIcontains?: Maybe<Scalars['String']>;
  salesman?: Maybe<Scalars['ID']>;
  salesmen?: Maybe<Array<Maybe<Scalars['ID']>>>;
  signatureState?: Maybe<SignatureStates>;
  signatureStates?: Maybe<Array<Maybe<SignatureStates>>>;
  signatureStateChangedGt?: Maybe<Scalars['DateTime']>;
  signatureStateChangedLt?: Maybe<Scalars['DateTime']>;
};

export type SalesOrderItem = Node & TimeStamped & {
  __typename?: 'SalesOrderItem';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Float']>;
  productPrice?: Maybe<Scalars['Float']>;
  totalPrice?: Maybe<Scalars['Float']>;
  itemType?: Maybe<SalesOrderItemTypes>;
  note?: Maybe<Scalars['String']>;
  uom?: Maybe<UnitOfMeasure>;
  item?: Maybe<InventoryItem>;
  itemNumber?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export enum SalesOrderItemTypes {
  /** Assoc. Price */
  AssocPrice = 'ASSOC_PRICE',
  /** Credit Return */
  CreditReturn = 'CREDIT_RETURN',
  /** Discount Amount */
  DiscountAmount = 'DISCOUNT_AMOUNT',
  /** Discount Percentage */
  DiscountPercentage = 'DISCOUNT_PERCENTAGE',
  /** Drop Ship */
  DropShip = 'DROP_SHIP',
  /** Kit */
  Kit = 'KIT',
  /** Misc. Credit */
  MiscCredit = 'MISC_CREDIT',
  /** Misc. Sale */
  MiscSale = 'MISC_SALE',
  /** Note */
  Note = 'NOTE',
  /** Sale */
  Sale = 'SALE',
  /** Shipping */
  Shipping = 'SHIPPING',
  /** Subtotal */
  Subtotal = 'SUBTOTAL',
  /** Tax */
  Tax = 'TAX'
}

export type SalesOrderOrderInput = {
  number?: Maybe<Sort>;
  status?: Maybe<Sort>;
  statusChangedDate?: Maybe<Sort>;
  ERPStatus?: Maybe<Sort>;
  signatureState?: Maybe<Sort>;
  signatureStateChangedDate?: Maybe<Sort>;
  issuedDate?: Maybe<Sort>;
  scheduledDate?: Maybe<Sort>;
  modified?: Maybe<Sort>;
  created?: Maybe<Sort>;
};

export type SalesOrderPayload = Payload & {
  __typename?: 'SalesOrderPayload';
  success: Scalars['Boolean'];
  errors: Array<FieldError>;
  object?: Maybe<SalesOrder>;
};

export enum SalesOrderStatuses {
  /** Draft */
  Draft = 'DRAFT',
  /** Unsigned */
  Unsigned = 'UNSIGNED',
  /** Complete */
  Complete = 'COMPLETE',
  /** Invoiced */
  Invoiced = 'INVOICED'
}

export type SendEmailPayload = Payload & {
  __typename?: 'SendEmailPayload';
  success: Scalars['Boolean'];
  errors: Array<FieldError>;
  messageId?: Maybe<Scalars['UUID']>;
};

export type ServiceTicket = TicketInterface & Signable & Node & Assignable & TimeStamped & StatusableTicket & EdmsCabinetTicket & Followable & Subscribable & {
  __typename?: 'ServiceTicket';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  number: Scalars['ID'];
  salesOrderNumber?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  ticketType?: Maybe<TicketTypes>;
  type: TicketTypes;
  phase: TicketPhases;
  status: TicketStatuses;
  timezone: Scalars['String'];
  cabinetId?: Maybe<Scalars['ID']>;
  well?: Maybe<Well>;
  company?: Maybe<Company>;
  companyMan?: Maybe<Scalars['String']>;
  companyManPhone?: Maybe<Scalars['String']>;
  companyManEmail?: Maybe<Scalars['String']>;
  procedure?: Maybe<RunningProcedure>;
  procedureRevisionId?: Maybe<Scalars['ID']>;
  workRequestDate?: Maybe<Scalars['DateTime']>;
  scheduledArrivalTime?: Maybe<Scalars['DateTime']>;
  rigName?: Maybe<Scalars['String']>;
  crewName?: Maybe<Scalars['String']>;
  jobDescription?: Maybe<Scalars['String']>;
  spareParts?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  leftShopTime?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['DateTime']>;
  arrivedTime?: Maybe<Scalars['DateTime']>;
  departedTime?: Maybe<Scalars['DateTime']>;
  jsaPerformedTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  workPerformed?: Maybe<Scalars['String']>;
  afeNumber?: Maybe<Scalars['String']>;
  jobCode?: Maybe<Scalars['String']>;
  /** Time technician should arrive at the shop */
  arriveToShop?: Maybe<Scalars['DateTime']>;
  /** Shop location where parts are stored */
  shopLocation?: Maybe<ShopLocations>;
  /** Any additional notes for the technician */
  dispatchNotes?: Maybe<Scalars['String']>;
  /** Given the current state of the ticket, show available transitions */
  availableStatusTransitions: Array<StatusTransitionOption>;
  signatureState: SignatureStates;
  signatureStateChangedDate: Scalars['DateTime'];
  creator?: Maybe<User>;
  accountManager?: Maybe<User>;
  technicians: Array<User>;
  /** Connection to all assignments */
  assignments?: Maybe<Connection>;
  assignment?: Maybe<Assignment>;
  charges: Array<Charge>;
  chargesTotal: Scalars['Float'];
  steps: Array<RunningProcedureTicketStepResult>;
  urls: NodeUrls;
  snapshotDocuments?: Maybe<Array<SnapshotDocument>>;
  latestSignatureResponse?: Maybe<SignatureResponse>;
  latestSignatureRequest?: Maybe<SignatureRequest>;
  requiredCharges: Array<RequiredCharge>;
  followers?: Maybe<Connection>;
  followerCount: Scalars['Int'];
  isFollowing?: Maybe<Scalars['Boolean']>;
  events?: Maybe<Connection>;
  isObjectSubscribed: Scalars['Boolean'];
  /** Ticket Event object Subscription connection */
  getEventSubscriptions: Connection;
  printedPDF?: Maybe<Scalars['URL']>;
  documents?: Maybe<Connection>;
};


export type ServiceTicketAssignmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AssignmentFilterInput>;
};


export type ServiceTicketAssignmentArgs = {
  type: AssignmentTypes;
};


export type ServiceTicketFollowersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<FollowFilterInput>;
};


export type ServiceTicketEventsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<EventFilterInput>;
};


export type ServiceTicketGetEventSubscriptionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
};


export type ServiceTicketDocumentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<DocumentFilterInput>;
};

export type ServiceTicketInput = {
  id?: Maybe<Scalars['UUID']>;
  number?: Maybe<Scalars['String']>;
  salesOrderNumber?: Maybe<Scalars['String']>;
  type?: Maybe<TicketTypes>;
  phase?: Maybe<TicketPhases>;
  wellId?: Maybe<Scalars['UUID']>;
  accountManager?: Maybe<Scalars['ID']>;
  company?: Maybe<Scalars['ID']>;
  companyMan?: Maybe<Scalars['String']>;
  companyManPhone?: Maybe<Scalars['String']>;
  companyManEmail?: Maybe<Scalars['String']>;
  procedureRevisionId?: Maybe<Scalars['ID']>;
  workRequestDate?: Maybe<Scalars['DateTime']>;
  scheduledArrivalTime?: Maybe<Scalars['DateTime']>;
  rigName?: Maybe<Scalars['String']>;
  crewName?: Maybe<Scalars['String']>;
  jobDescription?: Maybe<Scalars['String']>;
  spareParts?: Maybe<Scalars['String']>;
  technicians?: Maybe<Array<Maybe<Scalars['ID']>>>;
  arriveToShop?: Maybe<Scalars['DateTime']>;
  shopLocation?: Maybe<ShopLocations>;
  dispatchNotes?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['DateTime']>;
  arrivedTime?: Maybe<Scalars['DateTime']>;
  departedTime?: Maybe<Scalars['DateTime']>;
  jsaPerformedTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  workPerformed?: Maybe<Scalars['String']>;
  afeNumber?: Maybe<Scalars['String']>;
  jobCode?: Maybe<Scalars['String']>;
};

export type ServiceTicketPayload = Payload & {
  __typename?: 'ServiceTicketPayload';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
  object?: Maybe<ServiceTicket>;
};

export type SharedSubscription = {
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  user: User;
  eventType: EventType;
};

export enum ShopLocations {
  /** Odessa, TX */
  Odessa = 'ODESSA',
  /** Wellington, CO */
  Wellington = 'WELLINGTON',
  /** Corpus Christi, TX */
  CorpusChristi = 'CORPUS_CHRISTI'
}

export type Signable = {
  /** Signing state of this object */
  signatureState: SignatureStates;
  /** Datetime signature state last changed */
  signatureStateChangedDate: Scalars['DateTime'];
  /** Latest response on object */
  latestSignatureResponse?: Maybe<SignatureResponse>;
  /** Latest request on object */
  latestSignatureRequest?: Maybe<SignatureRequest>;
};

export type SignableObjectsUnion = ServiceTicket | RepairTicket | SalesOrder;

export type SignatureCheckPayload = {
  __typename?: 'SignatureCheckPayload';
  success: Scalars['Boolean'];
  data: Scalars['String'];
  error: Scalars['String'];
};

export type SignatureRequest = SignatureShared & TimeStamped & Node & {
  __typename?: 'SignatureRequest';
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  /** The date and time this signature request was cancelled */
  cancelled?: Maybe<Scalars['DateTime']>;
  signatoryName?: Maybe<Scalars['String']>;
  signatoryEmail?: Maybe<Scalars['Email']>;
  /** @deprecated Field no longer supported */
  ticketSnapshot?: Maybe<TicketTypesUnion>;
  /** @deprecated Field no longer supported */
  ticket?: Maybe<TicketTypesUnion>;
  objectSnapshot?: Maybe<SignableObjectsUnion>;
  object: SignableObjectsUnion;
  createEvent?: Maybe<Event>;
  type: SignatureRequestTypes;
  message?: Maybe<Scalars['String']>;
  documentUUID?: Maybe<Scalars['UUID']>;
  documentVersionId?: Maybe<Scalars['ID']>;
  responses?: Maybe<Connection>;
  latestResponse?: Maybe<SignatureResponse>;
  document?: Maybe<Document>;
};


export type SignatureRequestResponsesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<SignatureResponseFilterInput>;
};

export type SignatureRequestInput = {
  signatoryName?: Maybe<Scalars['String']>;
  signatoryEmail?: Maybe<Scalars['Email']>;
  /** Document that contains the snapshot of the object at the time of the request */
  documentUUID?: Maybe<Scalars['UUID']>;
  /** Version of the document where this snapshot was added */
  documentVersionId?: Maybe<Scalars['ID']>;
  /** Optional message to attach to the request type */
  message?: Maybe<Scalars['String']>;
};

export type SignatureRequestPayload = Payload & {
  __typename?: 'SignatureRequestPayload';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
  object?: Maybe<SignatureRequest>;
};

export enum SignatureRequestTypes {
  /** Email */
  Email = 'EMAIL'
}

export type SignatureResponse = SignatureShared & TimeStamped & Node & {
  __typename?: 'SignatureResponse';
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  signatoryName?: Maybe<Scalars['String']>;
  signatoryEmail?: Maybe<Scalars['Email']>;
  /** @deprecated Field no longer supported */
  ticketSnapshot?: Maybe<TicketTypesUnion>;
  /** @deprecated Field no longer supported */
  ticket?: Maybe<TicketTypesUnion>;
  objectSnapshot?: Maybe<SignableObjectsUnion>;
  object: SignableObjectsUnion;
  createEvent?: Maybe<Event>;
  type: SignatureResponseTypes;
  request?: Maybe<SignatureRequest>;
  documentUUID?: Maybe<Scalars['UUID']>;
  documentVersionId?: Maybe<Scalars['ID']>;
  /** Composite document generated on response creation */
  compositeDocumentUUID?: Maybe<Scalars['UUID']>;
  compositeDocumentVersionId?: Maybe<Scalars['ID']>;
  /** Reason given for rejecting the signature request */
  rejectReason?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
  compositeDocument?: Maybe<Document>;
};

export type SignatureResponseFilterInput = {
  createdGt?: Maybe<Scalars['DateTime']>;
  createdLt?: Maybe<Scalars['DateTime']>;
  modifiedGt?: Maybe<Scalars['DateTime']>;
  modifiedLt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<SignatureResponseTypes>;
};

export type SignatureResponseInput = {
  signatoryName?: Maybe<Scalars['String']>;
  signatoryEmail?: Maybe<Scalars['Email']>;
  type: SignatureResponseTypes;
  emailCustomer?: Maybe<Scalars['Boolean']>;
  /** Document that contains the signature */
  documentUUID?: Maybe<Scalars['UUID']>;
  /** Version of the document where this signature was added */
  documentVersionId?: Maybe<Scalars['ID']>;
};

export type SignatureResponsePayload = Payload & {
  __typename?: 'SignatureResponsePayload';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
  object?: Maybe<SignatureResponse>;
  event?: Maybe<Event>;
};

/** Types of responses that signal different signature storage methods */
export enum SignatureResponseTypes {
  /** Digital Signature */
  Digital = 'DIGITAL',
  /** Uploaded Signature */
  Upload = 'UPLOAD',
  /** Uploaded Ticket */
  TicketUpload = 'TICKET_UPLOAD',
  /** Customer Stamp */
  Stamp = 'STAMP',
  /** Uploaded Customer Stamp */
  StampUpload = 'STAMP_UPLOAD',
  /** Email */
  Email = 'EMAIL',
  /** Rejected */
  Rejected = 'REJECTED'
}

export type SignatureShared = {
  signatoryName?: Maybe<Scalars['String']>;
  signatoryEmail?: Maybe<Scalars['Email']>;
  /** @deprecated Field no longer supported */
  ticketSnapshot?: Maybe<TicketTypesUnion>;
  /** @deprecated Field no longer supported */
  ticket?: Maybe<TicketTypesUnion>;
  /** A snapshot of the state of the object at time of signing */
  objectSnapshot?: Maybe<SignableObjectsUnion>;
  /** Relationship to the signed object, current state */
  object?: Maybe<SignableObjectsUnion>;
  createEvent?: Maybe<Event>;
};

export enum SignatureStates {
  /** Unsigned */
  Unsigned = 'UNSIGNED',
  /** Pending */
  Pending = 'PENDING',
  /** Signed */
  Signed = 'SIGNED',
  /** Rejected */
  Rejected = 'REJECTED'
}

export type SnapshotDocument = {
  __typename?: 'SnapshotDocument';
  id: Scalars['ID'];
  versionId: Scalars['ID'];
};

export enum Sort {
  Asc = 'asc',
  Desc = 'desc'
}

export type State = Node & {
  __typename?: 'State';
  id: Scalars['ID'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
};

export type StateFilterInput = {
  name?: Maybe<Scalars['String']>;
  name__icontains?: Maybe<Scalars['String']>;
};

export type StatusTransitionOption = {
  __typename?: 'StatusTransitionOption';
  transition: TicketStatusTransitions;
  label: Scalars['String'];
  priority: Scalars['Int'];
  direction: TransitionDirection;
};

export type StatusableTicket = {
  status: TicketStatuses;
};

export type Subscribable = {
  isObjectSubscribed: Scalars['Boolean'];
  getEventSubscriptions: Connection;
};


export type SubscribableGetEventSubscriptionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
};

export type SubscriptionPayload = Payload & {
  __typename?: 'SubscriptionPayload';
  success: Scalars['Boolean'];
  errors?: Maybe<Array<Maybe<FieldError>>>;
  object: EventSubscriptionUnion;
};

export type ThumbnailInput = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type TicketAssignmentPayload = Payload & {
  __typename?: 'TicketAssignmentPayload';
  success?: Maybe<Scalars['Boolean']>;
  object?: Maybe<Assignment>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export type TicketFilterInput = {
  order?: Maybe<TicketOrderInput>;
  id?: Maybe<Scalars['UUID']>;
  search?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  number__istartswith?: Maybe<Scalars['String']>;
  number__icontains?: Maybe<Scalars['String']>;
  ticketType?: Maybe<TicketTypes>;
  status?: Maybe<TicketStatuses>;
  statusIn?: Maybe<Array<Maybe<TicketStatuses>>>;
  assignment?: Maybe<AssignmentFilterInput>;
  createdGt?: Maybe<Scalars['DateTime']>;
  createdLt?: Maybe<Scalars['DateTime']>;
  modifiedGt?: Maybe<Scalars['DateTime']>;
  modifiedLt?: Maybe<Scalars['DateTime']>;
  startGt?: Maybe<Scalars['DateTime']>;
  startLt?: Maybe<Scalars['DateTime']>;
  company?: Maybe<Scalars['ID']>;
  companies?: Maybe<Array<Maybe<Scalars['ID']>>>;
  well?: Maybe<Scalars['UUID']>;
  wells?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  procedure?: Maybe<Scalars['ID']>;
  procedures?: Maybe<Array<Maybe<Scalars['ID']>>>;
  rigName?: Maybe<Scalars['String']>;
  rigName_istartswith?: Maybe<Scalars['String']>;
  rigName_icontains?: Maybe<Scalars['String']>;
  /** Temporary, search customer by company man name */
  customer?: Maybe<Scalars['String']>;
  owners?: Maybe<Array<Maybe<Scalars['ID']>>>;
  creators?: Maybe<Array<Maybe<Scalars['ID']>>>;
  assigned?: Maybe<Array<Maybe<Scalars['ID']>>>;
  sales?: Maybe<Array<Maybe<Scalars['ID']>>>;
  lead_technicians?: Maybe<Array<Maybe<Scalars['ID']>>>;
  technicians?: Maybe<Array<Maybe<Scalars['ID']>>>;
  signatureState?: Maybe<SignatureStates>;
  signatureStates?: Maybe<Array<Maybe<SignatureStates>>>;
  signatureStateChangedGt?: Maybe<Scalars['DateTime']>;
  signatureStateChangedLt?: Maybe<Scalars['DateTime']>;
};

export type TicketInterface = {
  number: Scalars['ID'];
  urls: NodeUrls;
};

export type TicketOrderInput = {
  number?: Maybe<Sort>;
  status?: Maybe<Sort>;
  type?: Maybe<Sort>;
  scheduledArrivalTime?: Maybe<Sort>;
  signatureState?: Maybe<Sort>;
  modified?: Maybe<Sort>;
  created?: Maybe<Sort>;
};

export type TicketPhaseOptions = {
  __typename?: 'TicketPhaseOptions';
  phase?: Maybe<TicketPhases>;
  types?: Maybe<Array<Maybe<TicketTypes>>>;
};

export enum TicketPhases {
  /** Drilling */
  Drilling = 'DRILLING',
  /** Completions */
  Completions = 'COMPLETIONS',
  /** Production */
  Production = 'PRODUCTION'
}

export enum TicketStatusTransitions {
  /** Schedule */
  Schedule = 'SCHEDULE',
  /** Dispatch */
  Dispatch = 'DISPATCH',
  /** Complete */
  Complete = 'COMPLETE',
  /** Reject */
  Reject = 'REJECT',
  /** Re-dispatch */
  ReDispatch = 'RE_DISPATCH',
  /** Force Reviewed */
  ForceReviewed = 'FORCE_REVIEWED',
  /** Review */
  Review = 'REVIEW',
  /** Finalize */
  Finalize = 'FINALIZE',
  /** Invoice */
  Invoice = 'INVOICE',
  /** Unreview */
  Unreview = 'UNREVIEW',
  /** Unfinalize */
  Unfinalize = 'UNFINALIZE',
  /** Uncomplete */
  Uncomplete = 'UNCOMPLETE',
  /** Return to Scheduled */
  ReturnToScheduled = 'RETURN_TO_SCHEDULED',
  /** Unschedule */
  Unschedule = 'UNSCHEDULE'
}

export enum TicketStatuses {
  /** Draft */
  Draft = 'DRAFT',
  /** Scheduled */
  Scheduled = 'SCHEDULED',
  /** Dispatched */
  Dispatched = 'DISPATCHED',
  /** Complete */
  Complete = 'COMPLETE',
  /** Rejected */
  Rejected = 'REJECTED',
  /** Reviewed */
  Reviewed = 'REVIEWED',
  /** Finalized */
  Finalized = 'FINALIZED',
  /** Invoiced */
  Invoiced = 'INVOICED'
}

export enum TicketTypes {
  /** Landing Mandrel Install */
  LandingMandrelInstall = 'LANDING_MANDREL_INSTALL',
  /** Landing Ring Install */
  LandingRingInstall = 'LANDING_RING_INSTALL',
  /** BOP Quick Connect */
  BopQuickConnect = 'BOP_QUICK_CONNECT',
  /** Casing Head Install */
  CasingHeadInstall = 'CASING_HEAD_INSTALL',
  /** Drilling Tools */
  DrillingTools = 'DRILLING_TOOLS',
  /** Intermediate Hanger Install */
  IntermediateHangerInstall = 'INTERMEDIATE_HANGER_INSTALL',
  /** 2nd Intermediate Hanger Install */
  SecondIntermediateHangerInstall = 'SECOND_INTERMEDIATE_HANGER_INSTALL',
  /** Casing Spool Install */
  CasingSpoolInstall = 'CASING_SPOOL_INSTALL',
  /** Production Hanger Install */
  ProductionHangerInstall = 'PRODUCTION_HANGER_INSTALL',
  /** TA Cap */
  TaCap = 'TA_CAP',
  /** Tubing Head Install */
  TubingHeadInstall = 'TUBING_HEAD_INSTALL',
  /** Production Tree Install */
  ProductionTreeInstall = 'PRODUCTION_TREE_INSTALL',
  /** Test Trailer */
  TestTrailer = 'TEST_TRAILER',
  /** Lubricator */
  Lubricator = 'LUBRICATOR',
  /** Frac Stack Install */
  FracStackInstall = 'FRAC_STACK_INSTALL',
  /** Frac Watch */
  FracWatch = 'FRAC_WATCH',
  /** Frac Stack Removal */
  FracStackRemoval = 'FRAC_STACK_REMOVAL',
  /** Greasing */
  Greasing = 'GREASING',
  /** Troubleshoot */
  Troubleshoot = 'TROUBLESHOOT',
  /** Decompletion */
  Decompletion = 'DECOMPLETION',
  /** Miscellaneous */
  Miscellaneous = 'MISCELLANEOUS'
}

export type TicketTypesUnion = ServiceTicket | RepairTicket;

export type TimeStamped = {
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
};

export type TorqueWrench = Node & {
  __typename?: 'TorqueWrench';
  id: Scalars['ID'];
  synced?: Maybe<Scalars['DateTime']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  serialNumber?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  torquewareId?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  calibrationDate?: Maybe<Scalars['Date']>;
  expirationDate?: Maybe<Scalars['Date']>;
  conversionTable?: Maybe<ConversionTable>;
};

export type TorqueWrenchFilterInput = {
  syncedGt?: Maybe<Scalars['DateTime']>;
};

export type TorqueWrenchInput = {
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  serialNumber?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  torquewareId?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  calibrationDate?: Maybe<Scalars['Date']>;
  expirationDate?: Maybe<Scalars['Date']>;
  conversionTable?: Maybe<Scalars['ID']>;
};

export type TorqueWrenchPayload = Payload & {
  __typename?: 'TorqueWrenchPayload';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
  object?: Maybe<TorqueWrench>;
};

export enum TransitionDirection {
  /** FORWARD */
  Forward = 'FORWARD',
  /** BACKWARD */
  Backward = 'BACKWARD'
}

export type TransitionMethod = {
  __typename?: 'TransitionMethod';
  /** Of multiple transitions, this is the visual priority */
  priority: Scalars['Int'];
  /** Label used to represent this transition */
  label: Scalars['String'];
  /** Method string to send object transition mutation when transitioning */
  method: Scalars['String'];
  /** Is this a forward or backwards transition? Can be used to differentiate the interface */
  direction: TransitionDirection;
};



export type UnitOfMeasure = {
  __typename?: 'UnitOfMeasure';
  short?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['String']>;
};

export type UpdateRunningProcedureInput = {
  description?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['ID']>;
};


export type User = Node & TimeStamped & Followable & Subscribable & {
  __typename?: 'User';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  isStaff?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<Group>>>;
  userPermissions?: Maybe<Array<Maybe<Permission>>>;
  allPermissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupPermissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  companies?: Maybe<Array<Maybe<Company>>>;
  getNotifications?: Maybe<Connection>;
  /** A connection of objects that this user is following */
  following?: Maybe<Connection>;
  followers?: Maybe<Connection>;
  followerCount: Scalars['Int'];
  isFollowing?: Maybe<Scalars['Boolean']>;
  isObjectSubscribed: Scalars['Boolean'];
  /** Non-object Event Subscription connection */
  getEventSubscriptions: Connection;
};


export type UserGetNotificationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<NotificationFilterInput>;
};


export type UserFollowingArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<FollowFilterInput>;
};


export type UserFollowersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<FollowFilterInput>;
};


export type UserGetEventSubscriptionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
};

export type UserFilterInput = {
  order?: Maybe<UserOrderInput>;
  id?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['ID']>;
  createdGt?: Maybe<Scalars['DateTime']>;
  createdLt?: Maybe<Scalars['DateTime']>;
  modifiedGt?: Maybe<Scalars['DateTime']>;
  modifiedLt?: Maybe<Scalars['DateTime']>;
  company?: Maybe<Scalars['ID']>;
  companies?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type UserOrderInput = {
  modified?: Maybe<Sort>;
  created?: Maybe<Sort>;
};

export type UserSettings = {
  __typename?: 'UserSettings';
  darkModeSet: Scalars['Boolean'];
  darkMode: Scalars['Boolean'];
};

export type UserSettingsInput = {
  darkModeSet?: Maybe<Scalars['Boolean']>;
  darkMode?: Maybe<Scalars['Boolean']>;
};

export type Waffle = {
  __typename?: 'Waffle';
  flags?: Maybe<Array<Maybe<WaffleFlag>>>;
  switches?: Maybe<Array<Maybe<WaffleSwitch>>>;
  samples?: Maybe<Array<Maybe<WaffleSample>>>;
  all?: Maybe<Array<Maybe<WaffleItem>>>;
  /** Retrieve a flag */
  flag?: Maybe<WaffleFlag>;
  /** Retrieve a switch */
  switch?: Maybe<WaffleSwitch>;
  /** Retrieve a sample */
  sample?: Maybe<WaffleSample>;
  /** The default flag value as specified in the settings */
  flagDefault?: Maybe<Scalars['Boolean']>;
  /** The default switch value as specified in the settings */
  switchDefault?: Maybe<Scalars['Boolean']>;
  /** The default sample value as specified in the settings */
  sampleDefault?: Maybe<Scalars['Boolean']>;
};


export type WaffleFlagArgs = {
  name?: Maybe<Scalars['String']>;
};


export type WaffleSwitchArgs = {
  name?: Maybe<Scalars['String']>;
};


export type WaffleSampleArgs = {
  name?: Maybe<Scalars['String']>;
};

export type WaffleFlag = WaffleItem & TimeStamped & {
  __typename?: 'WaffleFlag';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
};

export type WaffleItem = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
};

export type WaffleSample = WaffleItem & TimeStamped & {
  __typename?: 'WaffleSample';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
};

export type WaffleSwitch = WaffleItem & TimeStamped & {
  __typename?: 'WaffleSwitch';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
};

export type Well = Node & TimeStamped & {
  __typename?: 'Well';
  id: Scalars['ID'];
  detailsId: Scalars['ID'];
  cabinetId?: Maybe<Scalars['ID']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  apiNumber?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  city?: Maybe<City>;
  basin?: Maybe<Basin>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  directions?: Maybe<Scalars['String']>;
  obsolete?: Maybe<Scalars['Boolean']>;
  lastActiveTicket?: Maybe<TicketTypesUnion>;
  ticketCount: Scalars['Int'];
  totalCharges: Scalars['Float'];
};

export type WellDetailInput = {
  apiNumber?: Maybe<Scalars['String']>;
  city: Scalars['ID'];
  basin: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  directions?: Maybe<Scalars['String']>;
};

export type WellFilterInput = {
  search?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name__icontains?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['ID']>;
  createdGt?: Maybe<Scalars['DateTime']>;
  createdLt?: Maybe<Scalars['DateTime']>;
  modifiedGt?: Maybe<Scalars['DateTime']>;
  modifiedLt?: Maybe<Scalars['DateTime']>;
};

export type WellInput = {
  id?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  company: Scalars['ID'];
  apiNumber?: Maybe<Scalars['String']>;
  city: Scalars['ID'];
  basin: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  directions?: Maybe<Scalars['String']>;
};

export type WellPayload = Payload & {
  __typename?: 'WellPayload';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
  object?: Maybe<Well>;
};

export type WellRenameInput = {
  newName: Scalars['String'];
};


export type FullAddressFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'id' | 'name' | 'address1' | 'address2' | 'postalCode'>
  & { city: (
    { __typename?: 'City' }
    & Pick<City, 'id' | 'name'>
  ), state: (
    { __typename?: 'State' }
    & Pick<State, 'id' | 'name' | 'code'>
  ), country?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name' | 'code'>
  )> }
);

export type FullSalesOrderAddressFragment = (
  { __typename?: 'SalesOrderAddress' }
  & Pick<SalesOrderAddress, 'id' | 'name' | 'address' | 'zip' | 'city' | 'state' | 'country'>
);

export type BasinOptionFragment = (
  { __typename?: 'Basin' }
  & Pick<Basin, 'id' | 'name'>
);

export type FullRequiredChargeFragment = (
  { __typename?: 'RequiredCharge' }
  & Pick<RequiredCharge, 'id' | 'created' | 'modified' | 'order' | 'quantity' | 'suggestedPrice' | 'price' | 'total'>
  & { item?: Maybe<(
    { __typename?: 'InventoryItem' }
    & BaseInventoryItemFragment
  )>, priceRule?: Maybe<(
    { __typename?: 'PriceRule' }
    & Pick<PriceRule, 'id'>
  )> }
);

export type BaseChargeFragment = (
  { __typename?: 'Charge' }
  & Pick<Charge, 'id' | 'deleted' | 'quantity' | 'price' | 'suggestedPrice' | 'total' | 'priceState' | 'ticketId' | 'created' | 'note' | 'fromRequiredCharge'>
  & { item?: Maybe<(
    { __typename?: 'InventoryItem' }
    & BaseInventoryItemFragment
  )>, priceRule?: Maybe<(
    { __typename?: 'PriceRule' }
    & SmallPriceRuleFragment
  )> }
);

export type CityOptionFragment = (
  { __typename?: 'City' }
  & Pick<City, 'id' | 'name'>
  & { county?: Maybe<(
    { __typename?: 'County' }
    & Pick<County, 'name'>
  )>, state?: Maybe<(
    { __typename?: 'State' }
    & Pick<State, 'name'>
  )> }
);

export type CompanyOptionFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'description' | 'number'>
);

export type CompanyTickerFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'name' | 'ticker'>
);

export type CompanySignaturesFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'sigDigitalEnabled' | 'sigImageUpload' | 'sigRemoteEmail' | 'sigStampImageUpload' | 'sigDigitalStamp'>
);

export type ListCompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'modified' | 'name' | 'ticker' | 'number' | 'wellCount' | 'ticketCount'>
  & { lastActiveTicket?: Maybe<(
    { __typename?: 'ServiceTicket' }
    & LastActiveTicket_ServiceTicket_Fragment
  ) | (
    { __typename?: 'RepairTicket' }
    & LastActiveTicket_RepairTicket_Fragment
  )> }
);

export type CountyOptionFragment = (
  { __typename?: 'County' }
  & Pick<County, 'id' | 'name'>
);

export type SmallThumbnailFragment = (
  { __typename?: 'Document' }
  & { smallThumbnail: Document['thumbnail'] }
);

export type FullThumbnailFragment = (
  { __typename?: 'Document' }
  & { fullThumbnail: Document['thumbnail'] }
);

export type BaseDocumentFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'id' | 'uuid' | 'versionId' | 'size' | 'created' | 'label' | 'mimetype' | 'original' | 'type' | 'status' | 'errorMessage'>
  & { originalDownload: Document['original'] }
  & { exif?: Maybe<(
    { __typename?: 'EXIFData' }
    & Pick<ExifData, 'imageWidth' | 'imageHeight'>
  )>, cabinets?: Maybe<(
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | (
        { __typename?: 'Cabinet' }
        & Pick<Cabinet, 'id' | 'label'>
      ) | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    )>>> }
  )> }
  & SmallThumbnailFragment
  & FullThumbnailFragment
);

export type DocumentLabelFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'label' | 'uuid' | 'versionId'>
);

export type DocumentSnapshotFragment = (
  { __typename?: 'SnapshotDocument' }
  & Pick<SnapshotDocument, 'id' | 'versionId'>
);

export type LinkableEventFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'actorName' | 'targetName' | 'objectName' | 'actorId' | 'targetId' | 'objectId'>
  & { actor: (
    { __typename?: 'Address' }
    & EventData_Address_Fragment
  ) | (
    { __typename?: 'Assignment' }
    & EventData_Assignment_Fragment
  ) | (
    { __typename?: 'Basin' }
    & EventData_Basin_Fragment
  ) | (
    { __typename?: 'Cabinet' }
    & EventData_Cabinet_Fragment
  ) | (
    { __typename?: 'ChangeRecord' }
    & EventData_ChangeRecord_Fragment
  ) | (
    { __typename?: 'Charge' }
    & EventData_Charge_Fragment
  ) | (
    { __typename?: 'City' }
    & EventData_City_Fragment
  ) | (
    { __typename?: 'Company' }
    & EventData_Company_Fragment
  ) | (
    { __typename?: 'ConversionTable' }
    & EventData_ConversionTable_Fragment
  ) | (
    { __typename?: 'Country' }
    & EventData_Country_Fragment
  ) | (
    { __typename?: 'County' }
    & EventData_County_Fragment
  ) | (
    { __typename?: 'Document' }
    & EventData_Document_Fragment
  ) | (
    { __typename?: 'Event' }
    & EventData_Event_Fragment
  ) | (
    { __typename?: 'EventSubscription' }
    & EventData_EventSubscription_Fragment
  ) | (
    { __typename?: 'Follow' }
    & EventData_Follow_Fragment
  ) | (
    { __typename?: 'Group' }
    & EventData_Group_Fragment
  ) | (
    { __typename?: 'InventoryItem' }
    & EventData_InventoryItem_Fragment
  ) | (
    { __typename?: 'LogEntry' }
    & EventData_LogEntry_Fragment
  ) | (
    { __typename?: 'NodeMutation' }
    & EventData_NodeMutation_Fragment
  ) | (
    { __typename?: 'Notification' }
    & EventData_Notification_Fragment
  ) | (
    { __typename?: 'ObjectEventSubscription' }
    & EventData_ObjectEventSubscription_Fragment
  ) | (
    { __typename?: 'PartType' }
    & EventData_PartType_Fragment
  ) | (
    { __typename?: 'PartTypeField' }
    & EventData_PartTypeField_Fragment
  ) | (
    { __typename?: 'Permission' }
    & EventData_Permission_Fragment
  ) | (
    { __typename?: 'PressureRecording' }
    & EventData_PressureRecording_Fragment
  ) | (
    { __typename?: 'PressureTransducer' }
    & EventData_PressureTransducer_Fragment
  ) | (
    { __typename?: 'PriceRule' }
    & EventData_PriceRule_Fragment
  ) | (
    { __typename?: 'RepairTicket' }
    & EventData_RepairTicket_Fragment
  ) | (
    { __typename?: 'RequiredCharge' }
    & EventData_RequiredCharge_Fragment
  ) | (
    { __typename?: 'RunningProcedure' }
    & EventData_RunningProcedure_Fragment
  ) | (
    { __typename?: 'RunningProcedureRevision' }
    & EventData_RunningProcedureRevision_Fragment
  ) | (
    { __typename?: 'RunningProcedureStep' }
    & EventData_RunningProcedureStep_Fragment
  ) | (
    { __typename?: 'RunningProcedureTicketStepResult' }
    & EventData_RunningProcedureTicketStepResult_Fragment
  ) | (
    { __typename?: 'RunningProcedureVersion' }
    & EventData_RunningProcedureVersion_Fragment
  ) | (
    { __typename?: 'SalesOrder' }
    & EventData_SalesOrder_Fragment
  ) | (
    { __typename?: 'SalesOrderAddress' }
    & EventData_SalesOrderAddress_Fragment
  ) | (
    { __typename?: 'SalesOrderItem' }
    & EventData_SalesOrderItem_Fragment
  ) | (
    { __typename?: 'ServiceTicket' }
    & EventData_ServiceTicket_Fragment
  ) | (
    { __typename?: 'SignatureRequest' }
    & EventData_SignatureRequest_Fragment
  ) | (
    { __typename?: 'SignatureResponse' }
    & EventData_SignatureResponse_Fragment
  ) | (
    { __typename?: 'State' }
    & EventData_State_Fragment
  ) | (
    { __typename?: 'TorqueWrench' }
    & EventData_TorqueWrench_Fragment
  ) | (
    { __typename?: 'User' }
    & EventData_User_Fragment
  ) | (
    { __typename?: 'Well' }
    & EventData_Well_Fragment
  ), object: (
    { __typename?: 'Address' }
    & EventData_Address_Fragment
  ) | (
    { __typename?: 'Assignment' }
    & EventData_Assignment_Fragment
  ) | (
    { __typename?: 'Basin' }
    & EventData_Basin_Fragment
  ) | (
    { __typename?: 'Cabinet' }
    & EventData_Cabinet_Fragment
  ) | (
    { __typename?: 'ChangeRecord' }
    & EventData_ChangeRecord_Fragment
  ) | (
    { __typename?: 'Charge' }
    & EventData_Charge_Fragment
  ) | (
    { __typename?: 'City' }
    & EventData_City_Fragment
  ) | (
    { __typename?: 'Company' }
    & EventData_Company_Fragment
  ) | (
    { __typename?: 'ConversionTable' }
    & EventData_ConversionTable_Fragment
  ) | (
    { __typename?: 'Country' }
    & EventData_Country_Fragment
  ) | (
    { __typename?: 'County' }
    & EventData_County_Fragment
  ) | (
    { __typename?: 'Document' }
    & EventData_Document_Fragment
  ) | (
    { __typename?: 'Event' }
    & EventData_Event_Fragment
  ) | (
    { __typename?: 'EventSubscription' }
    & EventData_EventSubscription_Fragment
  ) | (
    { __typename?: 'Follow' }
    & EventData_Follow_Fragment
  ) | (
    { __typename?: 'Group' }
    & EventData_Group_Fragment
  ) | (
    { __typename?: 'InventoryItem' }
    & EventData_InventoryItem_Fragment
  ) | (
    { __typename?: 'LogEntry' }
    & EventData_LogEntry_Fragment
  ) | (
    { __typename?: 'NodeMutation' }
    & EventData_NodeMutation_Fragment
  ) | (
    { __typename?: 'Notification' }
    & EventData_Notification_Fragment
  ) | (
    { __typename?: 'ObjectEventSubscription' }
    & EventData_ObjectEventSubscription_Fragment
  ) | (
    { __typename?: 'PartType' }
    & EventData_PartType_Fragment
  ) | (
    { __typename?: 'PartTypeField' }
    & EventData_PartTypeField_Fragment
  ) | (
    { __typename?: 'Permission' }
    & EventData_Permission_Fragment
  ) | (
    { __typename?: 'PressureRecording' }
    & EventData_PressureRecording_Fragment
  ) | (
    { __typename?: 'PressureTransducer' }
    & EventData_PressureTransducer_Fragment
  ) | (
    { __typename?: 'PriceRule' }
    & EventData_PriceRule_Fragment
  ) | (
    { __typename?: 'RepairTicket' }
    & EventData_RepairTicket_Fragment
  ) | (
    { __typename?: 'RequiredCharge' }
    & EventData_RequiredCharge_Fragment
  ) | (
    { __typename?: 'RunningProcedure' }
    & EventData_RunningProcedure_Fragment
  ) | (
    { __typename?: 'RunningProcedureRevision' }
    & EventData_RunningProcedureRevision_Fragment
  ) | (
    { __typename?: 'RunningProcedureStep' }
    & EventData_RunningProcedureStep_Fragment
  ) | (
    { __typename?: 'RunningProcedureTicketStepResult' }
    & EventData_RunningProcedureTicketStepResult_Fragment
  ) | (
    { __typename?: 'RunningProcedureVersion' }
    & EventData_RunningProcedureVersion_Fragment
  ) | (
    { __typename?: 'SalesOrder' }
    & EventData_SalesOrder_Fragment
  ) | (
    { __typename?: 'SalesOrderAddress' }
    & EventData_SalesOrderAddress_Fragment
  ) | (
    { __typename?: 'SalesOrderItem' }
    & EventData_SalesOrderItem_Fragment
  ) | (
    { __typename?: 'ServiceTicket' }
    & EventData_ServiceTicket_Fragment
  ) | (
    { __typename?: 'SignatureRequest' }
    & EventData_SignatureRequest_Fragment
  ) | (
    { __typename?: 'SignatureResponse' }
    & EventData_SignatureResponse_Fragment
  ) | (
    { __typename?: 'State' }
    & EventData_State_Fragment
  ) | (
    { __typename?: 'TorqueWrench' }
    & EventData_TorqueWrench_Fragment
  ) | (
    { __typename?: 'User' }
    & EventData_User_Fragment
  ) | (
    { __typename?: 'Well' }
    & EventData_Well_Fragment
  ), target?: Maybe<(
    { __typename?: 'Address' }
    & EventData_Address_Fragment
  ) | (
    { __typename?: 'Assignment' }
    & EventData_Assignment_Fragment
  ) | (
    { __typename?: 'Basin' }
    & EventData_Basin_Fragment
  ) | (
    { __typename?: 'Cabinet' }
    & EventData_Cabinet_Fragment
  ) | (
    { __typename?: 'ChangeRecord' }
    & EventData_ChangeRecord_Fragment
  ) | (
    { __typename?: 'Charge' }
    & EventData_Charge_Fragment
  ) | (
    { __typename?: 'City' }
    & EventData_City_Fragment
  ) | (
    { __typename?: 'Company' }
    & EventData_Company_Fragment
  ) | (
    { __typename?: 'ConversionTable' }
    & EventData_ConversionTable_Fragment
  ) | (
    { __typename?: 'Country' }
    & EventData_Country_Fragment
  ) | (
    { __typename?: 'County' }
    & EventData_County_Fragment
  ) | (
    { __typename?: 'Document' }
    & EventData_Document_Fragment
  ) | (
    { __typename?: 'Event' }
    & EventData_Event_Fragment
  ) | (
    { __typename?: 'EventSubscription' }
    & EventData_EventSubscription_Fragment
  ) | (
    { __typename?: 'Follow' }
    & EventData_Follow_Fragment
  ) | (
    { __typename?: 'Group' }
    & EventData_Group_Fragment
  ) | (
    { __typename?: 'InventoryItem' }
    & EventData_InventoryItem_Fragment
  ) | (
    { __typename?: 'LogEntry' }
    & EventData_LogEntry_Fragment
  ) | (
    { __typename?: 'NodeMutation' }
    & EventData_NodeMutation_Fragment
  ) | (
    { __typename?: 'Notification' }
    & EventData_Notification_Fragment
  ) | (
    { __typename?: 'ObjectEventSubscription' }
    & EventData_ObjectEventSubscription_Fragment
  ) | (
    { __typename?: 'PartType' }
    & EventData_PartType_Fragment
  ) | (
    { __typename?: 'PartTypeField' }
    & EventData_PartTypeField_Fragment
  ) | (
    { __typename?: 'Permission' }
    & EventData_Permission_Fragment
  ) | (
    { __typename?: 'PressureRecording' }
    & EventData_PressureRecording_Fragment
  ) | (
    { __typename?: 'PressureTransducer' }
    & EventData_PressureTransducer_Fragment
  ) | (
    { __typename?: 'PriceRule' }
    & EventData_PriceRule_Fragment
  ) | (
    { __typename?: 'RepairTicket' }
    & EventData_RepairTicket_Fragment
  ) | (
    { __typename?: 'RequiredCharge' }
    & EventData_RequiredCharge_Fragment
  ) | (
    { __typename?: 'RunningProcedure' }
    & EventData_RunningProcedure_Fragment
  ) | (
    { __typename?: 'RunningProcedureRevision' }
    & EventData_RunningProcedureRevision_Fragment
  ) | (
    { __typename?: 'RunningProcedureStep' }
    & EventData_RunningProcedureStep_Fragment
  ) | (
    { __typename?: 'RunningProcedureTicketStepResult' }
    & EventData_RunningProcedureTicketStepResult_Fragment
  ) | (
    { __typename?: 'RunningProcedureVersion' }
    & EventData_RunningProcedureVersion_Fragment
  ) | (
    { __typename?: 'SalesOrder' }
    & EventData_SalesOrder_Fragment
  ) | (
    { __typename?: 'SalesOrderAddress' }
    & EventData_SalesOrderAddress_Fragment
  ) | (
    { __typename?: 'SalesOrderItem' }
    & EventData_SalesOrderItem_Fragment
  ) | (
    { __typename?: 'ServiceTicket' }
    & EventData_ServiceTicket_Fragment
  ) | (
    { __typename?: 'SignatureRequest' }
    & EventData_SignatureRequest_Fragment
  ) | (
    { __typename?: 'SignatureResponse' }
    & EventData_SignatureResponse_Fragment
  ) | (
    { __typename?: 'State' }
    & EventData_State_Fragment
  ) | (
    { __typename?: 'TorqueWrench' }
    & EventData_TorqueWrench_Fragment
  ) | (
    { __typename?: 'User' }
    & EventData_User_Fragment
  ) | (
    { __typename?: 'Well' }
    & EventData_Well_Fragment
  )> }
);

type EventData_Address_Fragment = { __typename: 'Address' };

type EventData_Assignment_Fragment = { __typename: 'Assignment' };

type EventData_Basin_Fragment = { __typename: 'Basin' };

type EventData_Cabinet_Fragment = { __typename: 'Cabinet' };

type EventData_ChangeRecord_Fragment = { __typename: 'ChangeRecord' };

type EventData_Charge_Fragment = (
  { __typename: 'Charge' }
  & { ticket?: Maybe<(
    { __typename?: 'ServiceTicket' }
    & Pick<ServiceTicket, 'number'>
  )> }
);

type EventData_City_Fragment = { __typename: 'City' };

type EventData_Company_Fragment = { __typename: 'Company' };

type EventData_ConversionTable_Fragment = { __typename: 'ConversionTable' };

type EventData_Country_Fragment = { __typename: 'Country' };

type EventData_County_Fragment = { __typename: 'County' };

type EventData_Document_Fragment = { __typename: 'Document' };

type EventData_Event_Fragment = { __typename: 'Event' };

type EventData_EventSubscription_Fragment = { __typename: 'EventSubscription' };

type EventData_Follow_Fragment = { __typename: 'Follow' };

type EventData_Group_Fragment = { __typename: 'Group' };

type EventData_InventoryItem_Fragment = { __typename: 'InventoryItem' };

type EventData_LogEntry_Fragment = { __typename: 'LogEntry' };

type EventData_NodeMutation_Fragment = { __typename: 'NodeMutation' };

type EventData_Notification_Fragment = { __typename: 'Notification' };

type EventData_ObjectEventSubscription_Fragment = { __typename: 'ObjectEventSubscription' };

type EventData_PartType_Fragment = { __typename: 'PartType' };

type EventData_PartTypeField_Fragment = { __typename: 'PartTypeField' };

type EventData_Permission_Fragment = { __typename: 'Permission' };

type EventData_PressureRecording_Fragment = { __typename: 'PressureRecording' };

type EventData_PressureTransducer_Fragment = { __typename: 'PressureTransducer' };

type EventData_PriceRule_Fragment = { __typename: 'PriceRule' };

type EventData_RepairTicket_Fragment = (
  { __typename: 'RepairTicket' }
  & Pick<RepairTicket, 'number'>
);

type EventData_RequiredCharge_Fragment = { __typename: 'RequiredCharge' };

type EventData_RunningProcedure_Fragment = { __typename: 'RunningProcedure' };

type EventData_RunningProcedureRevision_Fragment = { __typename: 'RunningProcedureRevision' };

type EventData_RunningProcedureStep_Fragment = { __typename: 'RunningProcedureStep' };

type EventData_RunningProcedureTicketStepResult_Fragment = { __typename: 'RunningProcedureTicketStepResult' };

type EventData_RunningProcedureVersion_Fragment = { __typename: 'RunningProcedureVersion' };

type EventData_SalesOrder_Fragment = (
  { __typename: 'SalesOrder' }
  & Pick<SalesOrder, 'number'>
);

type EventData_SalesOrderAddress_Fragment = { __typename: 'SalesOrderAddress' };

type EventData_SalesOrderItem_Fragment = { __typename: 'SalesOrderItem' };

type EventData_ServiceTicket_Fragment = (
  { __typename: 'ServiceTicket' }
  & Pick<ServiceTicket, 'number'>
);

type EventData_SignatureRequest_Fragment = (
  { __typename: 'SignatureRequest' }
  & { objectSnapshot?: Maybe<(
    { __typename?: 'ServiceTicket' }
    & Pick<ServiceTicket, 'number'>
  ) | (
    { __typename?: 'RepairTicket' }
    & Pick<RepairTicket, 'number'>
  ) | (
    { __typename?: 'SalesOrder' }
    & Pick<SalesOrder, 'number'>
  )> }
);

type EventData_SignatureResponse_Fragment = (
  { __typename: 'SignatureResponse' }
  & { objectSnapshot?: Maybe<(
    { __typename?: 'ServiceTicket' }
    & Pick<ServiceTicket, 'number'>
  ) | (
    { __typename?: 'RepairTicket' }
    & Pick<RepairTicket, 'number'>
  ) | (
    { __typename?: 'SalesOrder' }
    & Pick<SalesOrder, 'number'>
  )> }
);

type EventData_State_Fragment = { __typename: 'State' };

type EventData_TorqueWrench_Fragment = { __typename: 'TorqueWrench' };

type EventData_User_Fragment = { __typename: 'User' };

type EventData_Well_Fragment = { __typename: 'Well' };

export type EventDataFragment = EventData_Address_Fragment | EventData_Assignment_Fragment | EventData_Basin_Fragment | EventData_Cabinet_Fragment | EventData_ChangeRecord_Fragment | EventData_Charge_Fragment | EventData_City_Fragment | EventData_Company_Fragment | EventData_ConversionTable_Fragment | EventData_Country_Fragment | EventData_County_Fragment | EventData_Document_Fragment | EventData_Event_Fragment | EventData_EventSubscription_Fragment | EventData_Follow_Fragment | EventData_Group_Fragment | EventData_InventoryItem_Fragment | EventData_LogEntry_Fragment | EventData_NodeMutation_Fragment | EventData_Notification_Fragment | EventData_ObjectEventSubscription_Fragment | EventData_PartType_Fragment | EventData_PartTypeField_Fragment | EventData_Permission_Fragment | EventData_PressureRecording_Fragment | EventData_PressureTransducer_Fragment | EventData_PriceRule_Fragment | EventData_RepairTicket_Fragment | EventData_RequiredCharge_Fragment | EventData_RunningProcedure_Fragment | EventData_RunningProcedureRevision_Fragment | EventData_RunningProcedureStep_Fragment | EventData_RunningProcedureTicketStepResult_Fragment | EventData_RunningProcedureVersion_Fragment | EventData_SalesOrder_Fragment | EventData_SalesOrderAddress_Fragment | EventData_SalesOrderItem_Fragment | EventData_ServiceTicket_Fragment | EventData_SignatureRequest_Fragment | EventData_SignatureResponse_Fragment | EventData_State_Fragment | EventData_TorqueWrench_Fragment | EventData_User_Fragment | EventData_Well_Fragment;

type TicketSnapshotParts_ServiceTicket_Fragment = (
  { __typename?: 'ServiceTicket' }
  & Pick<ServiceTicket, 'chargesTotal'>
  & { charges: Array<(
    { __typename?: 'Charge' }
    & BaseChargeFragment
  )>, snapshotDocuments?: Maybe<Array<(
    { __typename?: 'SnapshotDocument' }
    & DocumentSnapshotFragment
  )>> }
  & FullTicketFragment
  & PrintTicketDetailsFragment
);

type TicketSnapshotParts_RepairTicket_Fragment = (
  { __typename?: 'RepairTicket' }
  & Pick<RepairTicket, 'id'>
);

type TicketSnapshotParts_SalesOrder_Fragment = (
  { __typename?: 'SalesOrder' }
  & FullSalesOrderFragment
);

export type TicketSnapshotPartsFragment = TicketSnapshotParts_ServiceTicket_Fragment | TicketSnapshotParts_RepairTicket_Fragment | TicketSnapshotParts_SalesOrder_Fragment;

export type FullEventFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'verb' | 'data' | 'hasAllRequiredData' | 'timestamp'>
  & { type: (
    { __typename?: 'EventType' }
    & Pick<EventType, 'name' | 'label'>
  ) }
  & LinkableEventFragment
);

export type SmallEventTypeFragment = (
  { __typename?: 'EventType' }
  & Pick<EventType, 'id' | 'label'>
);

export type SubscribedEventTypeFragment = (
  { __typename?: 'EventType' }
  & Pick<EventType, 'id' | 'label' | 'isObjectSubscribed' | 'isSubscribed'>
);

type FullSubscription_EventSubscription_Fragment = (
  { __typename?: 'EventSubscription' }
  & Pick<EventSubscription, 'id' | 'created'>
  & { eventType: (
    { __typename?: 'EventType' }
    & Pick<EventType, 'id' | 'label'>
  ) }
);

type FullSubscription_ObjectEventSubscription_Fragment = (
  { __typename?: 'ObjectEventSubscription' }
  & Pick<ObjectEventSubscription, 'id' | 'created'>
  & { object: (
    { __typename?: 'Address' }
    & Pick<Address, 'id'>
  ) | (
    { __typename?: 'Assignment' }
    & Pick<Assignment, 'id'>
  ) | (
    { __typename?: 'Basin' }
    & Pick<Basin, 'id'>
  ) | (
    { __typename?: 'Cabinet' }
    & Pick<Cabinet, 'id'>
  ) | (
    { __typename?: 'ChangeRecord' }
    & Pick<ChangeRecord, 'id'>
  ) | (
    { __typename?: 'Charge' }
    & Pick<Charge, 'id'>
  ) | (
    { __typename?: 'City' }
    & Pick<City, 'id'>
  ) | (
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
  ) | (
    { __typename?: 'ConversionTable' }
    & Pick<ConversionTable, 'id'>
  ) | (
    { __typename?: 'Country' }
    & Pick<Country, 'id'>
  ) | (
    { __typename?: 'County' }
    & Pick<County, 'id'>
  ) | (
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
  ) | (
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
  ) | (
    { __typename?: 'EventSubscription' }
    & Pick<EventSubscription, 'id'>
  ) | (
    { __typename?: 'Follow' }
    & Pick<Follow, 'id'>
  ) | (
    { __typename?: 'Group' }
    & Pick<Group, 'id'>
  ) | (
    { __typename?: 'InventoryItem' }
    & Pick<InventoryItem, 'id'>
  ) | (
    { __typename?: 'LogEntry' }
    & Pick<LogEntry, 'id'>
  ) | (
    { __typename?: 'NodeMutation' }
    & Pick<NodeMutation, 'id'>
  ) | (
    { __typename?: 'Notification' }
    & Pick<Notification, 'id'>
  ) | (
    { __typename?: 'ObjectEventSubscription' }
    & Pick<ObjectEventSubscription, 'id'>
  ) | (
    { __typename?: 'PartType' }
    & Pick<PartType, 'id'>
  ) | (
    { __typename?: 'PartTypeField' }
    & Pick<PartTypeField, 'id'>
  ) | (
    { __typename?: 'Permission' }
    & Pick<Permission, 'id'>
  ) | (
    { __typename?: 'PressureRecording' }
    & Pick<PressureRecording, 'id'>
  ) | (
    { __typename?: 'PressureTransducer' }
    & Pick<PressureTransducer, 'id'>
  ) | (
    { __typename?: 'PriceRule' }
    & Pick<PriceRule, 'id'>
  ) | (
    { __typename?: 'RepairTicket' }
    & Pick<RepairTicket, 'id'>
  ) | (
    { __typename?: 'RequiredCharge' }
    & Pick<RequiredCharge, 'id'>
  ) | (
    { __typename?: 'RunningProcedure' }
    & Pick<RunningProcedure, 'id'>
  ) | (
    { __typename?: 'RunningProcedureRevision' }
    & Pick<RunningProcedureRevision, 'id'>
  ) | (
    { __typename?: 'RunningProcedureStep' }
    & Pick<RunningProcedureStep, 'id'>
  ) | (
    { __typename?: 'RunningProcedureTicketStepResult' }
    & Pick<RunningProcedureTicketStepResult, 'id'>
  ) | (
    { __typename?: 'RunningProcedureVersion' }
    & Pick<RunningProcedureVersion, 'id'>
  ) | (
    { __typename?: 'SalesOrder' }
    & Pick<SalesOrder, 'id'>
  ) | (
    { __typename?: 'SalesOrderAddress' }
    & Pick<SalesOrderAddress, 'id'>
  ) | (
    { __typename?: 'SalesOrderItem' }
    & Pick<SalesOrderItem, 'id'>
  ) | (
    { __typename?: 'ServiceTicket' }
    & Pick<ServiceTicket, 'id'>
  ) | (
    { __typename?: 'SignatureRequest' }
    & Pick<SignatureRequest, 'id'>
  ) | (
    { __typename?: 'SignatureResponse' }
    & Pick<SignatureResponse, 'id'>
  ) | (
    { __typename?: 'State' }
    & Pick<State, 'id'>
  ) | (
    { __typename?: 'TorqueWrench' }
    & Pick<TorqueWrench, 'id'>
  ) | (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) | (
    { __typename?: 'Well' }
    & Pick<Well, 'id'>
  ), eventType: (
    { __typename?: 'EventType' }
    & Pick<EventType, 'id' | 'label'>
  ) }
);

export type FullSubscriptionFragment = FullSubscription_EventSubscription_Fragment | FullSubscription_ObjectEventSubscription_Fragment;

export type ListSignatureEventFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'data' | 'description' | 'timestamp' | 'actorName'>
  & { type: (
    { __typename?: 'EventType' }
    & Pick<EventType, 'label' | 'name'>
  ), object: { __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | (
    { __typename?: 'SalesOrder' }
    & Pick<SalesOrder, 'id'>
  ) | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | (
    { __typename?: 'ServiceTicket' }
    & Pick<ServiceTicket, 'id'>
  ) | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }, target?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | (
    { __typename?: 'SignatureRequest' }
    & ListSignatureRequestFragment
  ) | (
    { __typename?: 'SignatureResponse' }
    & Pick<SignatureResponse, 'id' | 'created' | 'modified' | 'rejectReason' | 'signatoryName' | 'signatoryEmail' | 'type' | 'documentUUID' | 'documentVersionId'>
    & { request?: Maybe<(
      { __typename?: 'SignatureRequest' }
      & Pick<SignatureRequest, 'id'>
    )>, document?: Maybe<(
      { __typename?: 'Document' }
      & BaseDocumentFragment
    )> }
  ) | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
  & LinkableEventFragment
);

export type SignatureEventFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'data' | 'hasAllRequiredData' | 'description' | 'timestamp' | 'actorName'>
  & { type: (
    { __typename?: 'EventType' }
    & Pick<EventType, 'label' | 'name'>
  ), object: { __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | (
    { __typename?: 'RepairTicket' }
    & Pick<RepairTicket, 'id'>
  ) | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | (
    { __typename?: 'SalesOrder' }
    & Pick<SalesOrder, 'id'>
  ) | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | (
    { __typename?: 'ServiceTicket' }
    & Pick<ServiceTicket, 'id'>
  ) | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }, target?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | (
    { __typename?: 'SignatureRequest' }
    & Pick<SignatureRequest, 'id' | 'created' | 'modified' | 'cancelled' | 'signatoryName' | 'signatoryEmail'>
    & { objectSnapshot?: Maybe<(
      { __typename?: 'ServiceTicket' }
      & { urls: (
        { __typename?: 'NodeUrls' }
        & Pick<NodeUrls, 'detail'>
      ) }
      & TicketSnapshotParts_ServiceTicket_Fragment
    ) | (
      { __typename?: 'RepairTicket' }
      & TicketSnapshotParts_RepairTicket_Fragment
    ) | (
      { __typename?: 'SalesOrder' }
      & TicketSnapshotParts_SalesOrder_Fragment
    )>, latestResponse?: Maybe<(
      { __typename?: 'SignatureResponse' }
      & Pick<SignatureResponse, 'id'>
      & { createEvent?: Maybe<(
        { __typename?: 'Event' }
        & Pick<Event, 'id'>
      )> }
    )> }
  ) | (
    { __typename?: 'SignatureResponse' }
    & Pick<SignatureResponse, 'id' | 'created' | 'modified' | 'rejectReason' | 'signatoryName' | 'signatoryEmail' | 'type' | 'documentUUID' | 'documentVersionId' | 'compositeDocumentUUID' | 'compositeDocumentVersionId'>
    & { request?: Maybe<(
      { __typename?: 'SignatureRequest' }
      & Pick<SignatureRequest, 'id'>
    )>, objectSnapshot?: Maybe<(
      { __typename?: 'ServiceTicket' }
      & { urls: (
        { __typename?: 'NodeUrls' }
        & Pick<NodeUrls, 'detail'>
      ) }
      & TicketSnapshotParts_ServiceTicket_Fragment
    ) | (
      { __typename?: 'RepairTicket' }
      & { urls: (
        { __typename?: 'NodeUrls' }
        & Pick<NodeUrls, 'detail'>
      ) }
      & TicketSnapshotParts_RepairTicket_Fragment
    ) | (
      { __typename?: 'SalesOrder' }
      & { urls: (
        { __typename?: 'NodeUrls' }
        & Pick<NodeUrls, 'detail'>
      ) }
      & TicketSnapshotParts_SalesOrder_Fragment
    )>, document?: Maybe<(
      { __typename?: 'Document' }
      & BaseDocumentFragment
    )>, compositeDocument?: Maybe<(
      { __typename?: 'Document' }
      & BaseDocumentFragment
    )> }
  ) | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
  & LinkableEventFragment
);

export type BaseInventoryItemFragment = (
  { __typename?: 'InventoryItem' }
  & Pick<InventoryItem, 'id' | 'number' | 'description' | 'longDescription' | 'created' | 'modified' | 'suggestedPrice' | 'standardCost' | 'price' | 'revision'>
  & { itemUom?: Maybe<(
    { __typename?: 'UnitOfMeasure' }
    & Pick<UnitOfMeasure, 'long' | 'short'>
  )>, currency?: Maybe<(
    { __typename?: 'Currency' }
    & Pick<Currency, 'name'>
  )>, partType?: Maybe<(
    { __typename?: 'PartType' }
    & Pick<PartType, 'id' | 'name'>
  )> }
);

export type ActiveBaseInventoryItemFragment = (
  { __typename?: 'InventoryItem' }
  & Pick<InventoryItem, 'active'>
  & BaseInventoryItemFragment
);

type LastActiveTicket_RepairTicket_Fragment = (
  { __typename?: 'RepairTicket' }
  & Pick<RepairTicket, 'number'>
);

type LastActiveTicket_SalesOrder_Fragment = (
  { __typename?: 'SalesOrder' }
  & Pick<SalesOrder, 'number'>
);

type LastActiveTicket_ServiceTicket_Fragment = (
  { __typename?: 'ServiceTicket' }
  & Pick<ServiceTicket, 'type' | 'phase' | 'scheduledArrivalTime' | 'number'>
);

export type LastActiveTicketFragment = LastActiveTicket_RepairTicket_Fragment | LastActiveTicket_SalesOrder_Fragment | LastActiveTicket_ServiceTicket_Fragment;

export type PageInfoFragment = (
  { __typename?: 'Connection' }
  & { pageInfo?: Maybe<(
    { __typename?: 'PageInfo' }
    & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'count'>
  )> }
);

export type PagedInfoFragment = (
  { __typename?: 'Paginated' }
  & { pagedInfo: (
    { __typename?: 'PagedInfo' }
    & Pick<PagedInfo, 'hasNextPage' | 'hasPreviousPage' | 'page' | 'totalPages' | 'count'>
  ) }
);

export type CityStackFragment = (
  { __typename?: 'City' }
  & Pick<City, 'name'>
  & { state?: Maybe<(
    { __typename?: 'State' }
    & Pick<State, 'name' | 'code'>
    & { country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'name' | 'code'>
    )> }
  )>, county?: Maybe<(
    { __typename?: 'County' }
    & Pick<County, 'name'>
  )> }
);

export type ListNotificationFragment = (
  { __typename?: 'Notification' }
  & Pick<Notification, 'id' | 'created' | 'read'>
  & { event: (
    { __typename?: 'Event' }
    & FullEventFragment
  ) }
);

export type ListPartTypeFragment = (
  { __typename?: 'PartType' }
  & Pick<PartType, 'id' | 'modified' | 'name' | 'description' | 'numberPrefix' | 'numberFormat' | 'partServiceCount'>
);

export type FullPartTypeFragment = (
  { __typename?: 'PartType' }
  & Pick<PartType, 'id' | 'created' | 'modified' | 'name' | 'description' | 'numberFormat' | 'numberPrefix' | 'createProducts' | 'trackRevision' | 'trackSerial' | 'partTemplate' | 'defaultTemplate' | 'productTemplate'>
  & { filledSheet: PartType['spreadsheet'], emptySheet: PartType['spreadsheet'] }
  & { engineers: Array<(
    { __typename?: 'User' }
    & UserOptionFragment
  )>, fields?: Maybe<Array<Maybe<(
    { __typename?: 'PartTypeField' }
    & Pick<PartTypeField, 'id' | 'index' | 'title' | 'tag' | 'fieldType' | 'required'>
    & { options?: Maybe<Array<Maybe<(
      { __typename?: 'PartTypeFieldOption' }
      & Pick<PartTypeFieldOption, 'id' | 'index' | 'shortValue' | 'longValue'>
    )>>> }
  )>>> }
);

export type ListChangeRecordFragment = (
  { __typename?: 'ChangeRecord' }
  & Pick<ChangeRecord, 'id' | 'outcome' | 'error' | 'row' | 'number' | 'partDescription' | 'productDescription' | 'createPart' | 'createProduct' | 'active'>
  & { fields?: Maybe<Array<Maybe<(
    { __typename?: 'ChangeRecordField' }
    & Pick<ChangeRecordField, 'cell' | 'value' | 'error'>
  )>>>, part?: Maybe<(
    { __typename?: 'InventoryItem' }
    & Pick<InventoryItem, 'number' | 'description'>
  )>, product?: Maybe<(
    { __typename?: 'InventoryItem' }
    & Pick<InventoryItem, 'number' | 'description'>
  )>, item?: Maybe<(
    { __typename?: 'InventoryItem' }
    & Pick<InventoryItem, 'number' | 'description' | 'longDescription' | 'active'>
  )>, uom?: Maybe<(
    { __typename?: 'UnitOfMeasure' }
    & Pick<UnitOfMeasure, 'short' | 'long'>
  )> }
);

type BasePayload_AddSignaturePayload_Fragment = (
  { __typename?: 'AddSignaturePayload' }
  & Pick<AddSignaturePayload, 'success'>
  & { errors: Array<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )> }
);

type BasePayload_AddressPayload_Fragment = (
  { __typename?: 'AddressPayload' }
  & Pick<AddressPayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_CabinetPayload_Fragment = (
  { __typename?: 'CabinetPayload' }
  & Pick<CabinetPayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_ChargePayload_Fragment = (
  { __typename?: 'ChargePayload' }
  & Pick<ChargePayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_CompanyPayload_Fragment = (
  { __typename?: 'CompanyPayload' }
  & Pick<CompanyPayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_ConversionTablePayload_Fragment = (
  { __typename?: 'ConversionTablePayload' }
  & Pick<ConversionTablePayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_CustomerPayload_Fragment = (
  { __typename?: 'CustomerPayload' }
  & Pick<CustomerPayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_DocumentPayload_Fragment = (
  { __typename?: 'DocumentPayload' }
  & Pick<DocumentPayload, 'success'>
  & { errors: Array<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )> }
);

type BasePayload_EmailTicketPayload_Fragment = (
  { __typename?: 'EmailTicketPayload' }
  & Pick<EmailTicketPayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_EventContentSubscriptionPayload_Fragment = (
  { __typename?: 'EventContentSubscriptionPayload' }
  & Pick<EventContentSubscriptionPayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_IntroduceRequiredChargesPayload_Fragment = (
  { __typename?: 'IntroduceRequiredChargesPayload' }
  & Pick<IntroduceRequiredChargesPayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_MultipleNotificationsPayload_Fragment = (
  { __typename?: 'MultipleNotificationsPayload' }
  & Pick<MultipleNotificationsPayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_MultipleSubscriptionPayload_Fragment = (
  { __typename?: 'MultipleSubscriptionPayload' }
  & Pick<MultipleSubscriptionPayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_PartTypePayload_Fragment = (
  { __typename?: 'PartTypePayload' }
  & Pick<PartTypePayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_PressureRecordingPayload_Fragment = (
  { __typename?: 'PressureRecordingPayload' }
  & Pick<PressureRecordingPayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_PressureTransducerPayload_Fragment = (
  { __typename?: 'PressureTransducerPayload' }
  & Pick<PressureTransducerPayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_RunningProcedurePayload_Fragment = (
  { __typename?: 'RunningProcedurePayload' }
  & Pick<RunningProcedurePayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_RunningProcedureRevisionPayload_Fragment = (
  { __typename?: 'RunningProcedureRevisionPayload' }
  & Pick<RunningProcedureRevisionPayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_RunningProcedureTicketStepResultPayload_Fragment = (
  { __typename?: 'RunningProcedureTicketStepResultPayload' }
  & Pick<RunningProcedureTicketStepResultPayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_RunningProcedureVersionPayload_Fragment = (
  { __typename?: 'RunningProcedureVersionPayload' }
  & Pick<RunningProcedureVersionPayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_SalesOrderPayload_Fragment = (
  { __typename?: 'SalesOrderPayload' }
  & Pick<SalesOrderPayload, 'success'>
  & { errors: Array<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )> }
);

type BasePayload_SendEmailPayload_Fragment = (
  { __typename?: 'SendEmailPayload' }
  & Pick<SendEmailPayload, 'success'>
  & { errors: Array<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )> }
);

type BasePayload_ServiceTicketPayload_Fragment = (
  { __typename?: 'ServiceTicketPayload' }
  & Pick<ServiceTicketPayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_SignatureRequestPayload_Fragment = (
  { __typename?: 'SignatureRequestPayload' }
  & Pick<SignatureRequestPayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_SignatureResponsePayload_Fragment = (
  { __typename?: 'SignatureResponsePayload' }
  & Pick<SignatureResponsePayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_SubscriptionPayload_Fragment = (
  { __typename?: 'SubscriptionPayload' }
  & Pick<SubscriptionPayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_TicketAssignmentPayload_Fragment = (
  { __typename?: 'TicketAssignmentPayload' }
  & Pick<TicketAssignmentPayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_TorqueWrenchPayload_Fragment = (
  { __typename?: 'TorqueWrenchPayload' }
  & Pick<TorqueWrenchPayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

type BasePayload_WellPayload_Fragment = (
  { __typename?: 'WellPayload' }
  & Pick<WellPayload, 'success'>
  & { errors?: Maybe<Array<Maybe<(
    { __typename?: 'FieldError' }
    & Pick<FieldError, 'name'>
    & { values?: Maybe<Array<Maybe<(
      { __typename?: 'ErrorDetail' }
      & Pick<ErrorDetail, 'error' | 'code'>
    )>>> }
  )>>> }
);

export type BasePayloadFragment = BasePayload_AddSignaturePayload_Fragment | BasePayload_AddressPayload_Fragment | BasePayload_CabinetPayload_Fragment | BasePayload_ChargePayload_Fragment | BasePayload_CompanyPayload_Fragment | BasePayload_ConversionTablePayload_Fragment | BasePayload_CustomerPayload_Fragment | BasePayload_DocumentPayload_Fragment | BasePayload_EmailTicketPayload_Fragment | BasePayload_EventContentSubscriptionPayload_Fragment | BasePayload_IntroduceRequiredChargesPayload_Fragment | BasePayload_MultipleNotificationsPayload_Fragment | BasePayload_MultipleSubscriptionPayload_Fragment | BasePayload_PartTypePayload_Fragment | BasePayload_PressureRecordingPayload_Fragment | BasePayload_PressureTransducerPayload_Fragment | BasePayload_RunningProcedurePayload_Fragment | BasePayload_RunningProcedureRevisionPayload_Fragment | BasePayload_RunningProcedureTicketStepResultPayload_Fragment | BasePayload_RunningProcedureVersionPayload_Fragment | BasePayload_SalesOrderPayload_Fragment | BasePayload_SendEmailPayload_Fragment | BasePayload_ServiceTicketPayload_Fragment | BasePayload_SignatureRequestPayload_Fragment | BasePayload_SignatureResponsePayload_Fragment | BasePayload_SubscriptionPayload_Fragment | BasePayload_TicketAssignmentPayload_Fragment | BasePayload_TorqueWrenchPayload_Fragment | BasePayload_WellPayload_Fragment;

export type SmallPriceRuleFragment = (
  { __typename?: 'PriceRule' }
  & Pick<PriceRule, 'id'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
  ) }
);

export type BasePriceRuleFragment = (
  { __typename?: 'PriceRule' }
  & Pick<PriceRule, 'id' | 'created' | 'modified' | 'price'>
  & { item: (
    { __typename?: 'InventoryItem' }
    & Pick<InventoryItem, 'id'>
  ), company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
  ) }
);

export type BaseRunningProcedureStepFragment = (
  { __typename?: 'RunningProcedureStep' }
  & Pick<RunningProcedureStep, 'id' | 'title' | 'description' | 'fieldType' | 'order' | 'pageNumber' | 'required'>
);

export type BaseRunningProcedureTicketStepResultFragment = (
  { __typename?: 'RunningProcedureTicketStepResult' }
  & Pick<RunningProcedureTicketStepResult, 'id' | 'attribute' | 'data' | 'completedOn' | 'modified'>
  & { runningProcedureStep: (
    { __typename?: 'RunningProcedureStep' }
    & BaseRunningProcedureStepFragment
  ), creator?: Maybe<(
    { __typename?: 'Assignment' }
    & Pick<Assignment, 'id' | 'fullName' | 'email'>
  )> }
);

export type BaseRunningProcedureRevisionFragment = (
  { __typename?: 'RunningProcedureRevision' }
  & Pick<RunningProcedureRevision, 'id' | 'number'>
  & { steps?: Maybe<Array<Maybe<(
    { __typename?: 'RunningProcedureStep' }
    & BaseRunningProcedureStepFragment
  )>>>, requiredCharges: Array<(
    { __typename?: 'RequiredCharge' }
    & FullRequiredChargeFragment
  )> }
);

export type BaseRunningProcedureVersionFragment = (
  { __typename?: 'RunningProcedureVersion' }
  & Pick<RunningProcedureVersion, 'id' | 'number'>
  & { revisions?: Maybe<Array<Maybe<(
    { __typename?: 'RunningProcedureRevision' }
    & BaseRunningProcedureRevisionFragment
  )>>> }
);

export type BaseRunningProcedureFragment = (
  { __typename?: 'RunningProcedure' }
  & Pick<RunningProcedure, 'id' | 'title' | 'description' | 'cabinetId'>
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & CompanyOptionFragment
  )>, versions?: Maybe<Array<Maybe<(
    { __typename?: 'RunningProcedureVersion' }
    & BaseRunningProcedureVersionFragment
  )>>> }
);

export type ListRunningProcedureFragment = (
  { __typename?: 'RunningProcedure' }
  & Pick<RunningProcedure, 'id' | 'title' | 'description'>
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )>, versions?: Maybe<Array<Maybe<(
    { __typename?: 'RunningProcedureVersion' }
    & Pick<RunningProcedureVersion, 'number'>
    & { revisions?: Maybe<Array<Maybe<(
      { __typename?: 'RunningProcedureRevision' }
      & Pick<RunningProcedureRevision, 'number'>
    )>>> }
  )>>> }
);

export type RunningProcedureOptionFragment = (
  { __typename?: 'RunningProcedure' }
  & Pick<RunningProcedure, 'id' | 'title' | 'description'>
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )> }
);

export type FullSalesOrderFragment = (
  { __typename?: 'SalesOrder' }
  & Pick<SalesOrder, 'id' | 'number' | 'created' | 'modified' | 'cabinetId' | 'totalPrice' | 'totalTax' | 'itemTotal' | 'issuedDate' | 'scheduledDate' | 'shippingTerms' | 'paymentTerms' | 'carrier' | 'fobPoint' | 'signatureState'>
  & { soStatus: SalesOrder['status'] }
  & { billTo?: Maybe<(
    { __typename?: 'SalesOrderAddress' }
    & FullSalesOrderAddressFragment
  )>, shipTo?: Maybe<(
    { __typename?: 'SalesOrderAddress' }
    & FullSalesOrderAddressFragment
  )>, company?: Maybe<(
    { __typename?: 'Company' }
    & CompanyOptionFragment
    & CompanySignaturesFragment
  )>, salesman?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'fullName' | 'phoneNumber'>
  )>, transitions: Array<(
    { __typename?: 'TransitionMethod' }
    & Pick<TransitionMethod, 'priority' | 'label' | 'method' | 'direction'>
  )>, items: Array<(
    { __typename?: 'SalesOrderItem' }
    & Pick<SalesOrderItem, 'id' | 'created' | 'modified' | 'order' | 'quantity' | 'productPrice' | 'totalPrice' | 'itemType' | 'itemNumber' | 'note' | 'description'>
    & { uom?: Maybe<(
      { __typename?: 'UnitOfMeasure' }
      & Pick<UnitOfMeasure, 'short' | 'long'>
    )>, item?: Maybe<(
      { __typename?: 'InventoryItem' }
      & BaseInventoryItemFragment
    )> }
  )> }
);

export type ListSalesOrderFragmentFragment = (
  { __typename?: 'SalesOrder' }
  & Pick<SalesOrder, 'id' | 'status' | 'statusChangedDate' | 'created' | 'ERPClass' | 'ERPStatus' | 'itemsCount' | 'modified' | 'number' | 'totalPrice'>
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )>, shipTo?: Maybe<(
    { __typename?: 'SalesOrderAddress' }
    & Pick<SalesOrderAddress, 'id' | 'name'>
  )> }
  & SignatureStateDisplay_SalesOrder_Fragment
);

export type PrintSalesOrderDetailsFragment = (
  { __typename?: 'SalesOrder' }
  & { urls: (
    { __typename?: 'NodeUrls' }
    & Pick<NodeUrls, 'detail'>
  ) }
);

export type ListSignatureResponseFragment = (
  { __typename?: 'SignatureResponse' }
  & Pick<SignatureResponse, 'id' | 'documentUUID'>
  & { object: (
    { __typename?: 'ServiceTicket' }
    & Pick<ServiceTicket, 'id' | 'status'>
    & { transitions: Array<(
      { __typename?: 'StatusTransitionOption' }
      & Pick<StatusTransitionOption, 'transition' | 'label' | 'priority' | 'direction'>
    )> }
  ) | (
    { __typename?: 'RepairTicket' }
    & Pick<RepairTicket, 'id' | 'status'>
  ) | (
    { __typename?: 'SalesOrder' }
    & Pick<SalesOrder, 'id'>
    & { soStatus: SalesOrder['status'] }
    & { transitions: Array<(
      { __typename?: 'TransitionMethod' }
      & Pick<TransitionMethod, 'direction' | 'label' | 'method' | 'priority'>
    )> }
  ) }
);

export type ListSignatureRequestFragment = (
  { __typename?: 'SignatureRequest' }
  & Pick<SignatureRequest, 'id' | 'created' | 'modified' | 'cancelled' | 'signatoryName' | 'signatoryEmail'>
  & { latestResponse?: Maybe<(
    { __typename?: 'SignatureResponse' }
    & Pick<SignatureResponse, 'id'>
  )> }
);

type SignatureStateDisplay_RepairTicket_Fragment = (
  { __typename?: 'RepairTicket' }
  & Pick<RepairTicket, 'signatureState'>
  & { latestResponse?: Maybe<(
    { __typename?: 'SignatureResponse' }
    & Pick<SignatureResponse, 'id' | 'type'>
  )>, latestRequest?: Maybe<(
    { __typename?: 'SignatureRequest' }
    & Pick<SignatureRequest, 'id' | 'type'>
    & { latestResponse?: Maybe<(
      { __typename?: 'SignatureResponse' }
      & Pick<SignatureResponse, 'id' | 'type'>
    )> }
  )> }
);

type SignatureStateDisplay_SalesOrder_Fragment = (
  { __typename?: 'SalesOrder' }
  & Pick<SalesOrder, 'signatureState'>
  & { latestResponse?: Maybe<(
    { __typename?: 'SignatureResponse' }
    & Pick<SignatureResponse, 'id' | 'type'>
  )>, latestRequest?: Maybe<(
    { __typename?: 'SignatureRequest' }
    & Pick<SignatureRequest, 'id' | 'type'>
    & { latestResponse?: Maybe<(
      { __typename?: 'SignatureResponse' }
      & Pick<SignatureResponse, 'id' | 'type'>
    )> }
  )> }
);

type SignatureStateDisplay_ServiceTicket_Fragment = (
  { __typename?: 'ServiceTicket' }
  & Pick<ServiceTicket, 'signatureState'>
  & { latestResponse?: Maybe<(
    { __typename?: 'SignatureResponse' }
    & Pick<SignatureResponse, 'id' | 'type'>
  )>, latestRequest?: Maybe<(
    { __typename?: 'SignatureRequest' }
    & Pick<SignatureRequest, 'id' | 'type'>
    & { latestResponse?: Maybe<(
      { __typename?: 'SignatureResponse' }
      & Pick<SignatureResponse, 'id' | 'type'>
    )> }
  )> }
);

export type SignatureStateDisplayFragment = SignatureStateDisplay_RepairTicket_Fragment | SignatureStateDisplay_SalesOrder_Fragment | SignatureStateDisplay_ServiceTicket_Fragment;

export type ListTicketFragmentFragment = (
  { __typename?: 'ServiceTicket' }
  & Pick<ServiceTicket, 'id' | 'modified' | 'number' | 'type' | 'status' | 'workRequestDate' | 'scheduledArrivalTime'>
  & { linkNumber: ServiceTicket['number'], fromNowTime: ServiceTicket['scheduledArrivalTime'] }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'name'>
  )>, well?: Maybe<(
    { __typename?: 'Well' }
    & Pick<Well, 'id' | 'name'>
  )>, techs?: Maybe<(
    { __typename?: 'Connection' }
    & { pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'count'>
    )>, edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & { node?: Maybe<{ __typename?: 'Address' } | (
        { __typename?: 'Assignment' }
        & { user?: Maybe<(
          { __typename?: 'User' }
          & SmallUserFragment
        )> }
      ) | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    )>>> }
  )>, accountManager?: Maybe<(
    { __typename?: 'User' }
    & SmallUserFragment
  )> }
  & SignatureStateDisplay_ServiceTicket_Fragment
);

export type FullTicketFragment = (
  { __typename?: 'ServiceTicket' }
  & Pick<ServiceTicket, 'id' | 'created' | 'modified' | 'number' | 'type' | 'phase' | 'timezone' | 'signatureState' | 'status' | 'companyMan' | 'companyManPhone' | 'companyManEmail' | 'procedureRevisionId' | 'workRequestDate' | 'scheduledArrivalTime' | 'rigName' | 'crewName' | 'jobDescription' | 'spareParts' | 'salesOrderNumber' | 'startTime' | 'arrivedTime' | 'departedTime' | 'jsaPerformedTime' | 'endTime' | 'workPerformed' | 'afeNumber' | 'jobCode' | 'arriveToShop' | 'shopLocation' | 'dispatchNotes' | 'cabinetId'>
  & { creator?: Maybe<(
    { __typename?: 'User' }
    & UserOptionFragment
  )>, accountManager?: Maybe<(
    { __typename?: 'User' }
    & UserOptionFragment
  )>, transitions: Array<(
    { __typename?: 'StatusTransitionOption' }
    & Pick<StatusTransitionOption, 'transition' | 'label' | 'priority' | 'direction'>
  )>, well?: Maybe<(
    { __typename?: 'Well' }
    & WellOptionFragment
    & WellDetailsCardFragment
  )>, company?: Maybe<(
    { __typename?: 'Company' }
    & CompanyOptionFragment
    & CompanyTickerFragment
    & CompanySignaturesFragment
  )>, procedure?: Maybe<(
    { __typename?: 'RunningProcedure' }
    & BaseRunningProcedureFragment
  )>, steps: Array<(
    { __typename?: 'RunningProcedureTicketStepResult' }
    & BaseRunningProcedureTicketStepResultFragment
  )>, technicians: Array<(
    { __typename?: 'User' }
    & UserOptionFragment
  )>, requiredCharges: Array<(
    { __typename?: 'RequiredCharge' }
    & FullRequiredChargeFragment
  )> }
);

export type PrintTicketDetailsFragment = (
  { __typename?: 'ServiceTicket' }
  & { urls: (
    { __typename?: 'NodeUrls' }
    & Pick<NodeUrls, 'detail'>
  ), charges: Array<(
    { __typename?: 'Charge' }
    & Pick<Charge, 'id' | 'quantity' | 'price' | 'total'>
    & { item?: Maybe<(
      { __typename?: 'InventoryItem' }
      & BaseInventoryItemFragment
    )> }
  )> }
);

export type PhaseOptionsFragment = (
  { __typename?: 'Query' }
  & { ticketPhaseOptions: Array<(
    { __typename?: 'TicketPhaseOptions' }
    & Pick<TicketPhaseOptions, 'phase' | 'types'>
  )> }
);

export type SmallUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
);

export type UserOptionFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'fullName' | 'email'>
);

export type LargeUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'fullName' | 'email'>
);

export type ListUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'fullName' | 'email' | 'followerCount' | 'isActive'>
);

export type DetailUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'fullName' | 'email'>
  & { companies?: Maybe<Array<Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'description'>
  )>>> }
);

export type AssignmentOptionFragment = (
  { __typename?: 'Assignment' }
  & Pick<Assignment, 'id' | 'type'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserOptionFragment
  )> }
);

export type WellOptionFragment = (
  { __typename?: 'Well' }
  & Pick<Well, 'id' | 'name' | 'latitude' | 'longitude' | 'directions'>
  & { basin?: Maybe<(
    { __typename?: 'Basin' }
    & Pick<Basin, 'name'>
  )>, city?: Maybe<(
    { __typename?: 'City' }
    & CityStackFragment
  )> }
);

export type WellListItemFragment = (
  { __typename?: 'Well' }
  & Pick<Well, 'id' | 'name' | 'ticketCount' | 'totalCharges'>
  & { basin?: Maybe<(
    { __typename?: 'Basin' }
    & Pick<Basin, 'name'>
  )>, city?: Maybe<(
    { __typename?: 'City' }
    & CityStackFragment
  )>, company?: Maybe<(
    { __typename?: 'Company' }
    & CompanyTickerFragment
  )>, lastActiveTicket?: Maybe<(
    { __typename?: 'ServiceTicket' }
    & LastActiveTicket_ServiceTicket_Fragment
  ) | (
    { __typename?: 'RepairTicket' }
    & LastActiveTicket_RepairTicket_Fragment
  )> }
);

export type WellDetailsCardFragment = (
  { __typename?: 'Well' }
  & Pick<Well, 'id' | 'name' | 'apiNumber' | 'latitude' | 'longitude' | 'directions'>
  & { basin?: Maybe<(
    { __typename?: 'Basin' }
    & Pick<Basin, 'name'>
  )>, city?: Maybe<(
    { __typename?: 'City' }
    & CityStackFragment
  )> }
);

export type WellDetailFragment = (
  { __typename?: 'Well' }
  & Pick<Well, 'id' | 'name' | 'apiNumber' | 'latitude' | 'longitude' | 'detailsId' | 'cabinetId' | 'directions'>
  & { basin?: Maybe<(
    { __typename?: 'Basin' }
    & BasinOptionFragment
  )>, city?: Maybe<(
    { __typename?: 'City' }
    & CityOptionFragment
  )>, company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'name'>
  )> }
);

export type GetAppStateQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAppStateQuery = (
  { __typename?: 'Query' }
  & { appState: (
    { __typename?: 'AppState' }
    & Pick<AppState, 'online' | 'debug'>
  ), appVars: (
    { __typename?: 'AppVars' }
    & Pick<AppVars, 'authorization' | 'env' | 'isUpSyncing' | 'updateAvailable'>
  ) }
);

export type GetAppVarsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAppVarsQuery = (
  { __typename?: 'Query' }
  & { appVars: (
    { __typename?: 'AppVars' }
    & Pick<AppVars, 'authorization' | 'env' | 'isUpSyncing' | 'updateAvailable'>
  ) }
);

export type DetermineOnlineQueryVariables = Exact<{ [key: string]: never; }>;


export type DetermineOnlineQuery = (
  { __typename?: 'Query' }
  & { appState: (
    { __typename?: 'AppState' }
    & Pick<AppState, 'online'>
  ) }
);

export type DetermineDebugQueryVariables = Exact<{ [key: string]: never; }>;


export type DetermineDebugQuery = (
  { __typename?: 'Query' }
  & { appState: (
    { __typename?: 'AppState' }
    & Pick<AppState, 'debug'>
  ) }
);

export type GetUpdateAvailableQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUpdateAvailableQuery = (
  { __typename?: 'Query' }
  & { appVars: (
    { __typename?: 'AppVars' }
    & Pick<AppVars, 'updateAvailable'>
  ) }
);

export type CreateRequiredChargesForRevisionMutationVariables = Exact<{
  nodeId: Scalars['ID'];
  keys: ContentTypeKeys;
  input: IntroduceRequiredChargesInput;
}>;


export type CreateRequiredChargesForRevisionMutation = (
  { __typename?: 'Mutation' }
  & { mutateNodeByKey?: Maybe<(
    { __typename?: 'NodeMutation' }
    & Pick<NodeMutation, 'id'>
    & { introduceRequiredCharges: (
      { __typename?: 'IntroduceRequiredChargesPayload' }
      & { objects: Array<(
        { __typename?: 'RequiredCharge' }
        & FullRequiredChargeFragment
      )> }
      & BasePayload_IntroduceRequiredChargesPayload_Fragment
    ) }
  )> }
);

export type GetTicketChargesQueryVariables = Exact<{
  ticketId: Scalars['UUID'];
  filter?: Maybe<ChargeFilterInput>;
}>;


export type GetTicketChargesQuery = (
  { __typename?: 'Query' }
  & { charges?: Maybe<Array<Maybe<(
    { __typename?: 'Charge' }
    & Pick<Charge, 'id'>
    & BaseChargeFragment
  )>>> }
);

export type AddChargeMutationVariables = Exact<{
  ticketId: Scalars['UUID'];
  input: ChargeInput;
}>;


export type AddChargeMutation = (
  { __typename?: 'Mutation' }
  & { payload?: Maybe<(
    { __typename?: 'ChargePayload' }
    & { object?: Maybe<(
      { __typename?: 'Charge' }
      & Pick<Charge, 'id'>
      & BaseChargeFragment
    )> }
    & BasePayload_ChargePayload_Fragment
  )> }
);

export type UpdateChargeMutationVariables = Exact<{
  chargeId: Scalars['UUID'];
  input: ChargeInput;
}>;


export type UpdateChargeMutation = (
  { __typename?: 'Mutation' }
  & { payload?: Maybe<(
    { __typename?: 'ChargePayload' }
    & { object?: Maybe<(
      { __typename?: 'Charge' }
      & Pick<Charge, 'id'>
      & BaseChargeFragment
    )> }
    & BasePayload_ChargePayload_Fragment
  )> }
);

export type RemoveChargeMutationVariables = Exact<{
  chargeId: Scalars['UUID'];
  note?: Maybe<Scalars['String']>;
}>;


export type RemoveChargeMutation = (
  { __typename?: 'Mutation' }
  & { payload?: Maybe<(
    { __typename?: 'ChargePayload' }
    & { object?: Maybe<(
      { __typename?: 'Charge' }
      & Pick<Charge, 'id'>
    )> }
    & BasePayload_ChargePayload_Fragment
  )> }
);

export type RestoreChargeMutationVariables = Exact<{
  chargeId: Scalars['UUID'];
}>;


export type RestoreChargeMutation = (
  { __typename?: 'Mutation' }
  & { payload?: Maybe<(
    { __typename?: 'ChargePayload' }
    & { object?: Maybe<(
      { __typename?: 'Charge' }
      & Pick<Charge, 'id'>
      & BaseChargeFragment
    )> }
    & BasePayload_ChargePayload_Fragment
  )> }
);

export type GetPagedFilteredCompaniesQueryVariables = Exact<{
  paged?: Maybe<PagedInput>;
  filter?: Maybe<CompanyFilterInput>;
}>;


export type GetPagedFilteredCompaniesQuery = (
  { __typename?: 'Query' }
  & { companyData: (
    { __typename?: 'Paginated' }
    & { companies: Array<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | (
      { __typename?: 'Company' }
      & ListCompanyFragment
    ) | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    & PagedInfoFragment
  ) }
);

export type GetCompanyDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCompanyDetailQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'created' | 'modified' | 'name' | 'number' | 'ticker' | 'description' | 'sigDigitalEnabled' | 'sigImageUpload' | 'sigRemoteEmail' | 'sigStampImageUpload' | 'sigDigitalStamp'>
  )> }
);

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
  input: CompanyInput;
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { companyData: (
    { __typename?: 'CompanyPayload' }
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & CompanySignaturesFragment
    )> }
    & BasePayload_CompanyPayload_Fragment
  ) }
);

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fullName' | 'firstName' | 'lastName' | 'email'>
    & { companies?: Maybe<Array<Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>>>, groups?: Maybe<Array<Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
      & { permissions?: Maybe<Array<Maybe<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'name' | 'contentType' | 'codename'>
      )>>> }
    )>>> }
  ) }
);

export type GetAllCabinetDocumentsQueryVariables = Exact<{
  filter?: Maybe<DocumentFilterInput>;
}>;


export type GetAllCabinetDocumentsQuery = (
  { __typename?: 'Query' }
  & { documents?: Maybe<(
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | (
        { __typename?: 'Document' }
        & BaseDocumentFragment
      ) | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    )>>> }
  )> }
);

export type GetCabinetDocumentLabelsQueryVariables = Exact<{
  cabinetId?: Maybe<Scalars['ID']>;
  cabinetLabel?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  label_lookup?: Maybe<Scalars['String']>;
  label_exclude?: Maybe<Scalars['String']>;
  label_exclude_lookup?: Maybe<Scalars['String']>;
}>;


export type GetCabinetDocumentLabelsQuery = (
  { __typename?: 'Query' }
  & { documents?: Maybe<(
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | (
        { __typename?: 'Document' }
        & DocumentLabelFragment
      ) | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    )>>> }
  )> }
);

export type GetCabinetDocumentsQueryVariables = Exact<{
  filter?: Maybe<DocumentFilterInput>;
}>;


export type GetCabinetDocumentsQuery = (
  { __typename?: 'Query' }
  & { documents?: Maybe<(
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | (
        { __typename?: 'Document' }
        & BaseDocumentFragment
      ) | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    )>>> }
  )> }
);

export type GetDocumentByUuidQueryVariables = Exact<{
  documentUUID: Scalars['UUID'];
}>;


export type GetDocumentByUuidQuery = (
  { __typename?: 'Query' }
  & { document?: Maybe<(
    { __typename?: 'Document' }
    & BaseDocumentFragment
  )> }
);

export type AddDocumentMutationVariables = Exact<{
  input: AddDocumentInput;
}>;


export type AddDocumentMutation = (
  { __typename?: 'Mutation' }
  & { documentData?: Maybe<(
    { __typename?: 'DocumentPayload' }
    & { document?: Maybe<(
      { __typename?: 'Document' }
      & BaseDocumentFragment
    ) | (
      { __typename?: 'PartialDocument' }
      & Pick<PartialDocument, 'uuid' | 'type' | 'label'>
    )> }
    & BasePayload_DocumentPayload_Fragment
  )> }
);

export type RemoveDocumentMutationVariables = Exact<{
  documentUUID: Scalars['UUID'];
}>;


export type RemoveDocumentMutation = (
  { __typename?: 'Mutation' }
  & { payload?: Maybe<(
    { __typename?: 'DocumentPayload' }
    & { document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'uuid' | 'versionId'>
    ) | (
      { __typename?: 'PartialDocument' }
      & Pick<PartialDocument, 'uuid'>
    )> }
    & BasePayload_DocumentPayload_Fragment
  )> }
);

export type EmailTicketsMutationVariables = Exact<{
  input: EmailTicketInput;
}>;


export type EmailTicketsMutation = (
  { __typename?: 'Mutation' }
  & { payload: (
    { __typename?: 'EmailTicketPayload' }
    & BasePayload_EmailTicketPayload_Fragment
  ) }
);

export type GetEventByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetEventByIdQuery = (
  { __typename?: 'Query' }
  & { event?: Maybe<(
    { __typename?: 'Event' }
    & SignatureEventFragment
  )> }
);

export type GetObjectEventsQueryVariables = Exact<{
  id: Scalars['ID'];
  appLabel: Scalars['String'];
  model: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  association?: Maybe<EventAssociation>;
  eventFilter?: Maybe<EventFilterInput>;
}>;


export type GetObjectEventsQuery = (
  { __typename?: 'Query' }
  & { contentType?: Maybe<(
    { __typename?: 'ContentType' }
    & Pick<ContentType, 'id'>
    & { events: (
      { __typename?: 'Connection' }
      & { edges?: Maybe<Array<Maybe<(
        { __typename?: 'Edge' }
        & Pick<Edge, 'cursor'>
        & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | (
          { __typename?: 'Event' }
          & FullEventFragment
        ) | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
      )>>> }
      & PageInfoFragment
    ) }
  )> }
);

export type GetPagedObjectEventsQueryVariables = Exact<{
  id: Scalars['ID'];
  appLabel: Scalars['String'];
  model: Scalars['String'];
  paged?: Maybe<PagedInput>;
  association?: Maybe<EventAssociation>;
  eventFilter?: Maybe<EventFilterInput>;
}>;


export type GetPagedObjectEventsQuery = (
  { __typename?: 'Query' }
  & { contentType?: Maybe<(
    { __typename?: 'ContentType' }
    & { events: (
      { __typename?: 'Paginated' }
      & { events: Array<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | (
        { __typename?: 'Event' }
        & FullEventFragment
      ) | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
      & PagedInfoFragment
    ) }
  )> }
);

export type GetPagedEventsQueryVariables = Exact<{
  paged?: Maybe<PagedInput>;
  eventFilter?: Maybe<EventFilterInput>;
}>;


export type GetPagedEventsQuery = (
  { __typename?: 'Query' }
  & { events: (
    { __typename?: 'Paginated' }
    & { events: Array<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | (
      { __typename?: 'Event' }
      & FullEventFragment
    ) | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    & PagedInfoFragment
  ) }
);

export type GetAllEventTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllEventTypesQuery = (
  { __typename?: 'Query' }
  & { namespaces?: Maybe<Array<Maybe<(
    { __typename?: 'EventTypeNamespace' }
    & Pick<EventTypeNamespace, 'name' | 'label'>
    & { types?: Maybe<Array<Maybe<(
      { __typename?: 'EventType' }
      & Pick<EventType, 'id' | 'name' | 'label'>
    )>>> }
  )>>> }
);

export type GetUserNotificationsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<NotificationFilterInput>;
}>;


export type GetUserNotificationsQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { notifications?: Maybe<(
      { __typename?: 'Connection' }
      & { edges?: Maybe<Array<Maybe<(
        { __typename?: 'Edge' }
        & Pick<Edge, 'cursor'>
        & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | (
          { __typename?: 'Notification' }
          & ListNotificationFragment
        ) | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
      )>>> }
      & PageInfoFragment
    )> }
  ) }
);

export type MarkNotificationReadMutationVariables = Exact<{
  ids: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type MarkNotificationReadMutation = (
  { __typename?: 'Mutation' }
  & { markRead?: Maybe<(
    { __typename?: 'MultipleNotificationsPayload' }
    & { notifications: Array<Maybe<(
      { __typename?: 'Notification' }
      & ListNotificationFragment
    )>> }
    & BasePayload_MultipleNotificationsPayload_Fragment
  )> }
);

export type GetSignatureEventsQueryVariables = Exact<{
  id: Scalars['ID'];
  appLabel: Scalars['String'];
  model: Scalars['String'];
}>;


export type GetSignatureEventsQuery = (
  { __typename?: 'Query' }
  & { contentType?: Maybe<(
    { __typename?: 'ContentType' }
    & Pick<ContentType, 'id'>
    & { events: (
      { __typename?: 'Connection' }
      & { edges?: Maybe<Array<Maybe<(
        { __typename?: 'Edge' }
        & Pick<Edge, 'cursor'>
        & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | (
          { __typename?: 'Event' }
          & ListSignatureEventFragment
        ) | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
      )>>> }
      & PageInfoFragment
    ) }
  )> }
);

export type GetFilteredInventoryItemsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<InventoryItemFilterInput>;
}>;


export type GetFilteredInventoryItemsQuery = (
  { __typename?: 'Query' }
  & { inventoryData?: Maybe<(
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | (
        { __typename?: 'InventoryItem' }
        & BaseInventoryItemFragment
      ) | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    )>>> }
    & PageInfoFragment
  )> }
);

export type GetFilteredPartsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<InventoryItemFilterInput>;
}>;


export type GetFilteredPartsQuery = (
  { __typename?: 'Query' }
  & { partData?: Maybe<(
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | (
        { __typename?: 'InventoryItem' }
        & ActiveBaseInventoryItemFragment
      ) | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    )>>> }
    & PageInfoFragment
  )> }
);

export type GetPartsAndServicesQueryVariables = Exact<{
  paged?: Maybe<PagedInput>;
  filter?: Maybe<InventoryItemFilterInput>;
}>;


export type GetPartsAndServicesQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'Paginated' }
    & { items: Array<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | (
      { __typename?: 'InventoryItem' }
      & ActiveBaseInventoryItemFragment
    ) | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    & PagedInfoFragment
  ) }
);

export type GetFilteredServicesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<InventoryItemFilterInput>;
}>;


export type GetFilteredServicesQuery = (
  { __typename?: 'Query' }
  & { serviceData?: Maybe<(
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | (
        { __typename?: 'InventoryItem' }
        & BaseInventoryItemFragment
      ) | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    )>>> }
    & PageInfoFragment
  )> }
);

export type GetFilteredProductsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<InventoryItemFilterInput>;
}>;


export type GetFilteredProductsQuery = (
  { __typename?: 'Query' }
  & { productData?: Maybe<(
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | (
        { __typename?: 'InventoryItem' }
        & BaseInventoryItemFragment
      ) | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    )>>> }
    & PageInfoFragment
  )> }
);

export type GetCityOptionsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  filter?: Maybe<CityFilterInput>;
}>;


export type GetCityOptionsQuery = (
  { __typename?: 'Query' }
  & { options?: Maybe<(
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | (
        { __typename?: 'City' }
        & CityOptionFragment
      ) | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    )>>> }
  )> }
);

export type GetCompanyOptionsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  filter?: Maybe<CompanyFilterInput>;
}>;


export type GetCompanyOptionsQuery = (
  { __typename?: 'Query' }
  & { options?: Maybe<(
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | (
        { __typename?: 'Company' }
        & CompanyOptionFragment
      ) | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    )>>> }
  )> }
);

export type GetCountyOptionsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  filter?: Maybe<CountyFilterInput>;
}>;


export type GetCountyOptionsQuery = (
  { __typename?: 'Query' }
  & { options?: Maybe<(
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | (
        { __typename?: 'County' }
        & CountyOptionFragment
      ) | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    )>>> }
  )> }
);

export type GetBasinOptionsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  filter?: Maybe<BasinFilterInput>;
}>;


export type GetBasinOptionsQuery = (
  { __typename?: 'Query' }
  & { options: (
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | (
        { __typename?: 'Basin' }
        & BasinOptionFragment
      ) | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    )>>> }
  ) }
);

export type GetProductOptionsQueryVariables = Exact<{
  paged?: Maybe<PagedInput>;
  filter?: Maybe<InventoryItemFilterInput>;
}>;


export type GetProductOptionsQuery = (
  { __typename?: 'Query' }
  & { options: (
    { __typename?: 'Paginated' }
    & { objects: Array<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | (
      { __typename?: 'InventoryItem' }
      & BaseInventoryItemFragment
    ) | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
  ) }
);

export type GetRunningProcedureOptionsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  filter?: Maybe<RunningProcedureFilterInput>;
}>;


export type GetRunningProcedureOptionsQuery = (
  { __typename?: 'Query' }
  & { options?: Maybe<(
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | (
        { __typename?: 'RunningProcedure' }
        & RunningProcedureOptionFragment
      ) | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    )>>> }
  )> }
);

export type GetUserOptionsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  filter?: Maybe<UserFilterInput>;
}>;


export type GetUserOptionsQuery = (
  { __typename?: 'Query' }
  & { options?: Maybe<(
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | (
        { __typename?: 'User' }
        & UserOptionFragment
      ) | { __typename?: 'Well' }> }
    )>>> }
  )> }
);

export type GetWellOptionsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  filter?: Maybe<WellFilterInput>;
}>;


export type GetWellOptionsQuery = (
  { __typename?: 'Query' }
  & { options: (
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | (
        { __typename?: 'Well' }
        & { company?: Maybe<(
          { __typename?: 'Company' }
          & CompanyOptionFragment
        )> }
        & WellOptionFragment
      )> }
    )>>> }
  ) }
);

export type GetPartTypesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<PartTypeFilterInput>;
}>;


export type GetPartTypesQuery = (
  { __typename?: 'Query' }
  & { partTypeData?: Maybe<(
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | (
        { __typename?: 'PartType' }
        & ListPartTypeFragment
      ) | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    )>>> }
    & PageInfoFragment
  )> }
);

export type GetPartTypeByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPartTypeByIdQuery = (
  { __typename?: 'Query' }
  & { partType?: Maybe<(
    { __typename?: 'PartType' }
    & FullPartTypeFragment
  )> }
);

export type CreatePartTypeMutationVariables = Exact<{
  input: PartTypeInput;
}>;


export type CreatePartTypeMutation = (
  { __typename?: 'Mutation' }
  & { partTypeData?: Maybe<(
    { __typename?: 'PartTypePayload' }
    & { partType?: Maybe<(
      { __typename?: 'PartType' }
      & Pick<PartType, 'id'>
    )> }
    & BasePayload_PartTypePayload_Fragment
  )> }
);

export type UpdatePartTypeMutationVariables = Exact<{
  id: Scalars['ID'];
  input: PartTypeInput;
  apply?: Maybe<Scalars['Boolean']>;
}>;


export type UpdatePartTypeMutation = (
  { __typename?: 'Mutation' }
  & { partTypeData?: Maybe<(
    { __typename?: 'PartTypePayload' }
    & Pick<PartTypePayload, 'mutationError'>
    & { partType?: Maybe<(
      { __typename?: 'PartType' }
      & FullPartTypeFragment
    )> }
    & BasePayload_PartTypePayload_Fragment
  )> }
);

export type UploadPartTypeSheetMutationVariables = Exact<{
  id: Scalars['ID'];
  file: Scalars['Upload'];
  apply?: Maybe<Scalars['Boolean']>;
}>;


export type UploadPartTypeSheetMutation = (
  { __typename?: 'Mutation' }
  & { uploadPartTypeSheet?: Maybe<(
    { __typename?: 'PartTypePayload' }
    & Pick<PartTypePayload, 'mutationError'>
    & { partType?: Maybe<(
      { __typename?: 'PartType' }
      & FullPartTypeFragment
    )> }
    & BasePayload_PartTypePayload_Fragment
  )> }
);

export type GetChangeRecordsQueryVariables = Exact<{
  partTypeId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<ChangeRecordFilterInput>;
}>;


export type GetChangeRecordsQuery = (
  { __typename?: 'Query' }
  & { changeRecordData?: Maybe<(
    { __typename?: 'PartType' }
    & Pick<PartType, 'id' | 'hasDirty' | 'totalChanges' | 'failedChanges' | 'newChanges' | 'updatedChanges'>
    & { changes?: Maybe<(
      { __typename?: 'Connection' }
      & { edges?: Maybe<Array<Maybe<(
        { __typename?: 'Edge' }
        & Pick<Edge, 'cursor'>
        & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | (
          { __typename?: 'ChangeRecord' }
          & ListChangeRecordFragment
        ) | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
      )>>> }
      & PageInfoFragment
    )> }
  )> }
);

export type GetRunningProcedureByIdQueryVariables = Exact<{
  runningProcedureId: Scalars['ID'];
}>;


export type GetRunningProcedureByIdQuery = (
  { __typename?: 'Query' }
  & { procedure?: Maybe<(
    { __typename?: 'RunningProcedure' }
    & BaseRunningProcedureFragment
  )> }
);

export type GetRunningProceduresQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<RunningProcedureFilterInput>;
}>;


export type GetRunningProceduresQuery = (
  { __typename?: 'Query' }
  & { procedureData?: Maybe<(
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | (
        { __typename?: 'RunningProcedure' }
        & ListRunningProcedureFragment
      ) | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    )>>> }
    & PageInfoFragment
  )> }
);

export type CreateRunningProcedureMutationVariables = Exact<{
  input: CreateRunningProcedureInput;
}>;


export type CreateRunningProcedureMutation = (
  { __typename?: 'Mutation' }
  & { procedureData?: Maybe<(
    { __typename?: 'RunningProcedurePayload' }
    & { procedure?: Maybe<(
      { __typename?: 'RunningProcedure' }
      & Pick<RunningProcedure, 'id'>
    )> }
    & BasePayload_RunningProcedurePayload_Fragment
  )> }
);

export type CreateRunningProcedureVersionMutationVariables = Exact<{
  input: RunningProcedureVersionInput;
}>;


export type CreateRunningProcedureVersionMutation = (
  { __typename?: 'Mutation' }
  & { versionData?: Maybe<(
    { __typename?: 'RunningProcedureVersionPayload' }
    & { version?: Maybe<(
      { __typename?: 'RunningProcedureVersion' }
      & Pick<RunningProcedureVersion, 'id' | 'number'>
      & { revisions?: Maybe<Array<Maybe<(
        { __typename?: 'RunningProcedureRevision' }
        & BaseRunningProcedureRevisionFragment
      )>>> }
    )> }
    & BasePayload_RunningProcedureVersionPayload_Fragment
  )> }
);

export type CreateRunningProcedureRevisionMutationVariables = Exact<{
  input: RunningProcedureRevisionInput;
}>;


export type CreateRunningProcedureRevisionMutation = (
  { __typename?: 'Mutation' }
  & { revisionData?: Maybe<(
    { __typename?: 'RunningProcedureRevisionPayload' }
    & { revision?: Maybe<(
      { __typename?: 'RunningProcedureRevision' }
      & BaseRunningProcedureRevisionFragment
    )> }
    & BasePayload_RunningProcedureRevisionPayload_Fragment
  )> }
);

export type SaveRunningProcedureStepResultsMutationVariables = Exact<{
  ticketId: Scalars['UUID'];
  input: Array<Maybe<RunningProcedureTicketStepResultInput>> | Maybe<RunningProcedureTicketStepResultInput>;
}>;


export type SaveRunningProcedureStepResultsMutation = (
  { __typename?: 'Mutation' }
  & { procedureStepData: (
    { __typename?: 'RunningProcedureTicketStepResultPayload' }
    & { ticket?: Maybe<(
      { __typename?: 'ServiceTicket' }
      & Pick<ServiceTicket, 'id' | 'number'>
      & { steps: Array<(
        { __typename?: 'RunningProcedureTicketStepResult' }
        & BaseRunningProcedureTicketStepResultFragment
      )> }
    )> }
    & BasePayload_RunningProcedureTicketStepResultPayload_Fragment
  ) }
);

export type DeleteRunningProcedureStepResultsMutationVariables = Exact<{
  ticketId: Scalars['UUID'];
  input: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type DeleteRunningProcedureStepResultsMutation = (
  { __typename?: 'Mutation' }
  & { procedureStepData: (
    { __typename?: 'RunningProcedureTicketStepResultPayload' }
    & { ticket?: Maybe<(
      { __typename?: 'ServiceTicket' }
      & Pick<ServiceTicket, 'id' | 'number'>
      & { steps: Array<(
        { __typename?: 'RunningProcedureTicketStepResult' }
        & BaseRunningProcedureTicketStepResultFragment
      )> }
    )> }
    & BasePayload_RunningProcedureTicketStepResultPayload_Fragment
  ) }
);

export type UpdateRunningProcedureMutationVariables = Exact<{
  runningProcedureId: Scalars['ID'];
  input: UpdateRunningProcedureInput;
}>;


export type UpdateRunningProcedureMutation = (
  { __typename?: 'Mutation' }
  & { procedureData?: Maybe<(
    { __typename?: 'RunningProcedurePayload' }
    & { procedure?: Maybe<(
      { __typename?: 'RunningProcedure' }
      & BaseRunningProcedureFragment
    )> }
    & BasePayload_RunningProcedurePayload_Fragment
  )> }
);

export type GetPagedFilteredSalesOrdersQueryVariables = Exact<{
  paged?: Maybe<PagedInput>;
  filter?: Maybe<SalesOrderFilterInput>;
}>;


export type GetPagedFilteredSalesOrdersQuery = (
  { __typename?: 'Query' }
  & { salesOrderData: (
    { __typename?: 'Paginated' }
    & { objects: Array<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | (
      { __typename?: 'SalesOrder' }
      & ListSalesOrderFragmentFragment
    ) | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    & PagedInfoFragment
  ) }
);

export type GetSalesOrderByIdQueryVariables = Exact<{
  number: Scalars['String'];
}>;


export type GetSalesOrderByIdQuery = (
  { __typename?: 'Query' }
  & { salesOrder?: Maybe<(
    { __typename?: 'SalesOrder' }
    & FullSalesOrderFragment
  )> }
);

export type GetSalesOrderByNumberQueryVariables = Exact<{
  salesOrderNumber: Scalars['String'];
}>;


export type GetSalesOrderByNumberQuery = (
  { __typename?: 'Query' }
  & { salesOrder?: Maybe<(
    { __typename?: 'SalesOrder' }
    & FullSalesOrderFragment
  )> }
);

export type GetSalesOrderPrintDataQueryVariables = Exact<{
  salesOrderNumber: Scalars['String'];
}>;


export type GetSalesOrderPrintDataQuery = (
  { __typename?: 'Query' }
  & { salesOrder?: Maybe<(
    { __typename?: 'SalesOrder' }
    & FullSalesOrderFragment
    & PrintSalesOrderDetailsFragment
  )> }
);

export type TransitionSalesOrderMutationVariables = Exact<{
  id: Scalars['ID'];
  method: Scalars['String'];
}>;


export type TransitionSalesOrderMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderData: (
    { __typename?: 'SalesOrderPayload' }
    & { salesOrder?: Maybe<(
      { __typename?: 'SalesOrder' }
      & FullSalesOrderFragment
    )> }
    & BasePayload_SalesOrderPayload_Fragment
  ) }
);

export type AddSignatureMutationVariables = Exact<{
  input: AddDocumentInput;
}>;


export type AddSignatureMutation = (
  { __typename?: 'Mutation' }
  & { documentData?: Maybe<(
    { __typename?: 'DocumentPayload' }
    & { document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'versionId'>
      & BaseDocumentFragment
    ) | (
      { __typename?: 'PartialDocument' }
      & Pick<PartialDocument, 'uuid' | 'type' | 'label'>
    )> }
    & BasePayload_DocumentPayload_Fragment
  )> }
);

export type CreateSignatureResponseMutationVariables = Exact<{
  input: SignatureResponseInput;
  id: Scalars['ID'];
  keys: ContentTypeKeys;
  requestId?: Maybe<Scalars['ID']>;
}>;


export type CreateSignatureResponseMutation = (
  { __typename?: 'Mutation' }
  & { signatureData?: Maybe<(
    { __typename?: 'NodeMutation' }
    & { response: (
      { __typename?: 'SignatureResponsePayload' }
      & { signature?: Maybe<(
        { __typename?: 'SignatureResponse' }
        & ListSignatureResponseFragment
      )>, event?: Maybe<(
        { __typename?: 'Event' }
        & Pick<Event, 'id'>
      )> }
      & BasePayload_SignatureResponsePayload_Fragment
    ) }
  )> }
);

export type RejectSignatureResponseMutationVariables = Exact<{
  input: RejectSignatureResponseInput;
  id: Scalars['ID'];
  keys: ContentTypeKeys;
  requestId?: Maybe<Scalars['ID']>;
}>;


export type RejectSignatureResponseMutation = (
  { __typename?: 'Mutation' }
  & { signatureData?: Maybe<(
    { __typename?: 'NodeMutation' }
    & { response?: Maybe<(
      { __typename?: 'SignatureResponsePayload' }
      & { signature?: Maybe<(
        { __typename?: 'SignatureResponse' }
        & ListSignatureResponseFragment
      )>, event?: Maybe<(
        { __typename?: 'Event' }
        & Pick<Event, 'id'>
      )> }
      & BasePayload_SignatureResponsePayload_Fragment
    )> }
  )> }
);

export type CreateSignatureRequestMutationVariables = Exact<{
  input: SignatureRequestInput;
  id: Scalars['ID'];
  keys: ContentTypeKeys;
}>;


export type CreateSignatureRequestMutation = (
  { __typename?: 'Mutation' }
  & { mutateNode?: Maybe<(
    { __typename?: 'NodeMutation' }
    & { data: (
      { __typename?: 'SignatureRequestPayload' }
      & { request?: Maybe<(
        { __typename?: 'SignatureRequest' }
        & { object: (
          { __typename?: 'ServiceTicket' }
          & Pick<ServiceTicket, 'id' | 'status' | 'signatureState'>
        ) | (
          { __typename?: 'RepairTicket' }
          & Pick<RepairTicket, 'id' | 'status' | 'signatureState'>
        ) | (
          { __typename?: 'SalesOrder' }
          & Pick<SalesOrder, 'id' | 'signatureState'>
          & { soStatus: SalesOrder['status'] }
        ) }
        & ListSignatureRequestFragment
      )> }
      & BasePayload_SignatureRequestPayload_Fragment
    ) }
  )> }
);

export type ResendSignatureRequestMutationVariables = Exact<{
  requestId: Scalars['ID'];
}>;


export type ResendSignatureRequestMutation = (
  { __typename?: 'Mutation' }
  & { data: (
    { __typename?: 'SignatureRequestPayload' }
    & { request?: Maybe<(
      { __typename?: 'SignatureRequest' }
      & ListSignatureRequestFragment
    )> }
    & BasePayload_SignatureRequestPayload_Fragment
  ) }
);

export type CancelSignatureRequestMutationVariables = Exact<{
  requestId: Scalars['ID'];
}>;


export type CancelSignatureRequestMutation = (
  { __typename?: 'Mutation' }
  & { data: (
    { __typename?: 'SignatureRequestPayload' }
    & { request?: Maybe<(
      { __typename?: 'SignatureRequest' }
      & ListSignatureRequestFragment
    )> }
    & BasePayload_SignatureRequestPayload_Fragment
  ) }
);

export type GetObjectSubscriptionsQueryVariables = Exact<{
  id: Scalars['ID'];
  appLabel: Scalars['String'];
  model: Scalars['String'];
}>;


export type GetObjectSubscriptionsQuery = (
  { __typename?: 'Query' }
  & { contentType?: Maybe<(
    { __typename?: 'ContentType' }
    & Pick<ContentType, 'id'>
    & { eventsTypes: Array<Maybe<(
      { __typename?: 'EventType' }
      & SubscribedEventTypeFragment
    )>> }
  )> }
);

export type SubscribeToObjectEventsMutationVariables = Exact<{
  id: Scalars['ID'];
  eventIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  appLabel: Scalars['String'];
  model: Scalars['String'];
}>;


export type SubscribeToObjectEventsMutation = (
  { __typename?: 'Mutation' }
  & { contentType?: Maybe<(
    { __typename?: 'ContentTypeMutation' }
    & Pick<ContentTypeMutation, 'id'>
    & { payload: (
      { __typename?: 'EventContentSubscriptionPayload' }
      & { events: Array<Maybe<(
        { __typename?: 'EventType' }
        & SubscribedEventTypeFragment
      )>> }
      & BasePayload_EventContentSubscriptionPayload_Fragment
    ) }
  )> }
);

export type RemoveObjectSubscriptionsMutationVariables = Exact<{
  id: Scalars['ID'];
  eventIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  appLabel: Scalars['String'];
  model: Scalars['String'];
}>;


export type RemoveObjectSubscriptionsMutation = (
  { __typename?: 'Mutation' }
  & { contentType?: Maybe<(
    { __typename?: 'ContentTypeMutation' }
    & Pick<ContentTypeMutation, 'id'>
    & { payload: (
      { __typename?: 'EventContentSubscriptionPayload' }
      & { events: Array<Maybe<(
        { __typename?: 'EventType' }
        & SubscribedEventTypeFragment
      )>> }
      & BasePayload_EventContentSubscriptionPayload_Fragment
    ) }
  )> }
);

export type SyncChargesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filter?: Maybe<ChargeFilterInput>;
}>;


export type SyncChargesQuery = (
  { __typename?: 'Query' }
  & { charges?: Maybe<(
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | (
        { __typename?: 'Charge' }
        & BaseChargeFragment
      ) | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    )>>> }
    & PageInfoFragment
  )> }
);

export type SyncDocumentsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filter?: Maybe<DocumentFilterInput>;
}>;


export type SyncDocumentsQuery = (
  { __typename?: 'Query' }
  & { documents?: Maybe<(
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | (
        { __typename?: 'Document' }
        & Pick<Document, 'cachedThumbnail'>
        & { originalDownload: Document['original'] }
        & BaseDocumentFragment
      ) | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    )>>> }
    & PageInfoFragment
  )> }
);

export type SyncPriceRulesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filter?: Maybe<PriceRuleFilterInput>;
}>;


export type SyncPriceRulesQuery = (
  { __typename?: 'Query' }
  & { priceRules?: Maybe<(
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | (
        { __typename?: 'PriceRule' }
        & BasePriceRuleFragment
      ) | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    )>>> }
    & PageInfoFragment
  )> }
);

export type SyncProductsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filter?: Maybe<InventoryItemFilterInput>;
}>;


export type SyncProductsQuery = (
  { __typename?: 'Query' }
  & { products?: Maybe<(
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | (
        { __typename?: 'InventoryItem' }
        & BaseInventoryItemFragment
      ) | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    )>>> }
    & PageInfoFragment
  )> }
);

export type SyncRunningProceduresQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filter?: Maybe<RunningProcedureFilterInput>;
}>;


export type SyncRunningProceduresQuery = (
  { __typename?: 'Query' }
  & { procedures?: Maybe<(
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | (
        { __typename?: 'RunningProcedure' }
        & BaseRunningProcedureFragment
      ) | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    )>>> }
    & PageInfoFragment
  )> }
);

export type SyncSalesOrdersQueryVariables = Exact<{
  paged?: Maybe<PagedInput>;
  filter?: Maybe<SalesOrderFilterInput>;
}>;


export type SyncSalesOrdersQuery = (
  { __typename?: 'Query' }
  & { salesOrders: (
    { __typename?: 'Paginated' }
    & { objects: Array<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | (
      { __typename?: 'SalesOrder' }
      & FullSalesOrderFragment
      & ListSalesOrderFragmentFragment
      & PrintSalesOrderDetailsFragment
    ) | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    & PagedInfoFragment
  ) }
);

export type SyncTicketsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filter?: Maybe<TicketFilterInput>;
}>;


export type SyncTicketsQuery = (
  { __typename?: 'Query' }
  & { tickets: (
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | (
        { __typename?: 'ServiceTicket' }
        & ListTicketFragmentFragment
        & FullTicketFragment
        & PrintTicketDetailsFragment
      ) | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    )>>> }
    & PageInfoFragment
  ) }
);

export type SyncUsersQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filter?: Maybe<UserFilterInput>;
}>;


export type SyncUsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<(
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | (
        { __typename?: 'User' }
        & { groups?: Maybe<Array<Maybe<(
          { __typename?: 'Group' }
          & Pick<Group, 'id' | 'name'>
        )>>> }
        & LargeUserFragment
      ) | { __typename?: 'Well' }> }
    )>>> }
    & PageInfoFragment
  )> }
);

export type SyncWellsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  filter?: Maybe<WellFilterInput>;
}>;


export type SyncWellsQuery = (
  { __typename?: 'Query' }
  & { wells: (
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | (
        { __typename?: 'Well' }
        & { company?: Maybe<(
          { __typename?: 'Company' }
          & CompanyOptionFragment
        )> }
        & WellOptionFragment
      )> }
    )>>> }
    & PageInfoFragment
  ) }
);

export type GetTicketByNumberQueryVariables = Exact<{
  ticketNumber: Scalars['String'];
}>;


export type GetTicketByNumberQuery = (
  { __typename?: 'Query' }
  & { ticket?: Maybe<(
    { __typename?: 'ServiceTicket' }
    & FullTicketFragment
  )> }
);

export type GetTicketPrintUrlByNumberQueryVariables = Exact<{
  ticketNumber: Scalars['String'];
}>;


export type GetTicketPrintUrlByNumberQuery = (
  { __typename?: 'Query' }
  & { ticket?: Maybe<(
    { __typename?: 'ServiceTicket' }
    & Pick<ServiceTicket, 'printedPDF'>
  )> }
);

export type TicketPhaseOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type TicketPhaseOptionsQuery = (
  { __typename?: 'Query' }
  & PhaseOptionsFragment
);

export type GetTicketPrintDataQueryVariables = Exact<{
  ticketNumber: Scalars['String'];
}>;


export type GetTicketPrintDataQuery = (
  { __typename?: 'Query' }
  & { ticket?: Maybe<(
    { __typename?: 'ServiceTicket' }
    & FullTicketFragment
    & PrintTicketDetailsFragment
  )> }
);

export type GetFilteredTicketsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<TicketFilterInput>;
}>;


export type GetFilteredTicketsQuery = (
  { __typename?: 'Query' }
  & { ticketData: (
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | (
        { __typename?: 'ServiceTicket' }
        & ListTicketFragmentFragment
      ) | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    )>>> }
    & PageInfoFragment
  ) }
);

export type GetPagedFilteredTicketsQueryVariables = Exact<{
  paged?: Maybe<PagedInput>;
  filter?: Maybe<TicketFilterInput>;
}>;


export type GetPagedFilteredTicketsQuery = (
  { __typename?: 'Query' }
  & { ticketData: (
    { __typename?: 'Paginated' }
    & { tickets: Array<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | (
      { __typename?: 'ServiceTicket' }
      & ListTicketFragmentFragment
    ) | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | { __typename?: 'Well' }> }
    & PagedInfoFragment
  ) }
);

export type CreateTicketMutationVariables = Exact<{
  input: ServiceTicketInput;
}>;


export type CreateTicketMutation = (
  { __typename?: 'Mutation' }
  & { ticketData: (
    { __typename?: 'ServiceTicketPayload' }
    & { ticket?: Maybe<(
      { __typename?: 'ServiceTicket' }
      & Pick<ServiceTicket, 'number'>
    )> }
    & BasePayload_ServiceTicketPayload_Fragment
  ) }
);

export type UpdateTicketDataMutationVariables = Exact<{
  ticketId: Scalars['UUID'];
  input: ServiceTicketInput;
}>;


export type UpdateTicketDataMutation = (
  { __typename?: 'Mutation' }
  & { ticketData: (
    { __typename?: 'ServiceTicketPayload' }
    & { ticket?: Maybe<(
      { __typename?: 'ServiceTicket' }
      & FullTicketFragment
    )> }
    & BasePayload_ServiceTicketPayload_Fragment
  ) }
);

export type TransitionTicketMutationVariables = Exact<{
  ticketId: Scalars['UUID'];
  transition: TicketStatusTransitions;
}>;


export type TransitionTicketMutation = (
  { __typename?: 'Mutation' }
  & { ticketData: (
    { __typename?: 'ServiceTicketPayload' }
    & { ticket?: Maybe<(
      { __typename?: 'ServiceTicket' }
      & { charges: Array<(
        { __typename?: 'Charge' }
        & BaseChargeFragment
      )> }
      & FullTicketFragment
    )> }
    & BasePayload_ServiceTicketPayload_Fragment
  ) }
);

export type GetSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSettingsQuery = (
  { __typename?: 'Query' }
  & { userSettings: (
    { __typename?: 'UserSettings' }
    & Pick<UserSettings, 'darkModeSet' | 'darkMode'>
  ) }
);

export type DetermineDarkModeQueryVariables = Exact<{ [key: string]: never; }>;


export type DetermineDarkModeQuery = (
  { __typename?: 'Query' }
  & { userSettings: (
    { __typename?: 'UserSettings' }
    & Pick<UserSettings, 'darkModeSet' | 'darkMode'>
  ) }
);

export type ChangeUserSettingsMutationVariables = Exact<{
  input: UserSettingsInput;
}>;


export type ChangeUserSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserSettings: (
    { __typename?: 'UserSettings' }
    & Pick<UserSettings, 'darkModeSet' | 'darkMode'>
  ) }
);

export type GetFilteredUsersQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<UserFilterInput>;
}>;


export type GetFilteredUsersQuery = (
  { __typename?: 'Query' }
  & { userData?: Maybe<(
    { __typename?: 'Connection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'Edge' }
      & Pick<Edge, 'cursor'>
      & { node?: Maybe<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | (
        { __typename?: 'User' }
        & ListUserFragment
      ) | { __typename?: 'Well' }> }
    )>>> }
    & PageInfoFragment
  )> }
);

export type GetUserDetailByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserDetailByIdQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & DetailUserFragment
  )> }
);

export type GetCurrentUserDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserDashboardQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'followerCount'>
  ) }
);

export type GetWaffleBitsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWaffleBitsQuery = (
  { __typename?: 'Query' }
  & { waffle?: Maybe<(
    { __typename?: 'Waffle' }
    & Pick<Waffle, 'flagDefault' | 'switchDefault' | 'sampleDefault'>
    & { flags?: Maybe<Array<Maybe<(
      { __typename?: 'WaffleFlag' }
      & WaffleBits_WaffleFlag_Fragment
    )>>>, switches?: Maybe<Array<Maybe<(
      { __typename?: 'WaffleSwitch' }
      & WaffleBits_WaffleSwitch_Fragment
    )>>>, samples?: Maybe<Array<Maybe<(
      { __typename?: 'WaffleSample' }
      & WaffleBits_WaffleSample_Fragment
    )>>> }
  )> }
);

type WaffleBits_WaffleFlag_Fragment = (
  { __typename?: 'WaffleFlag' }
  & Pick<WaffleFlag, 'name' | 'active'>
);

type WaffleBits_WaffleSample_Fragment = (
  { __typename?: 'WaffleSample' }
  & Pick<WaffleSample, 'name' | 'active'>
);

type WaffleBits_WaffleSwitch_Fragment = (
  { __typename?: 'WaffleSwitch' }
  & Pick<WaffleSwitch, 'name' | 'active'>
);

export type WaffleBitsFragment = WaffleBits_WaffleFlag_Fragment | WaffleBits_WaffleSample_Fragment | WaffleBits_WaffleSwitch_Fragment;

export type CreateWellMutationVariables = Exact<{
  input: WellInput;
}>;


export type CreateWellMutation = (
  { __typename?: 'Mutation' }
  & { wellData: (
    { __typename?: 'WellPayload' }
    & { well?: Maybe<(
      { __typename?: 'Well' }
      & Pick<Well, 'id'>
    )> }
    & BasePayload_WellPayload_Fragment
  ) }
);

export type UpdateWellDetailsMutationVariables = Exact<{
  wellId: Scalars['UUID'];
  input: WellDetailInput;
}>;


export type UpdateWellDetailsMutation = (
  { __typename?: 'Mutation' }
  & { wellData: (
    { __typename?: 'WellPayload' }
    & { well?: Maybe<(
      { __typename?: 'Well' }
      & WellDetailFragment
    )> }
    & BasePayload_WellPayload_Fragment
  ) }
);

export type GetPagedFilteredWellsQueryVariables = Exact<{
  paged?: Maybe<PagedInput>;
  filter?: Maybe<WellFilterInput>;
}>;


export type GetPagedFilteredWellsQuery = (
  { __typename?: 'Query' }
  & { pagedData: (
    { __typename?: 'Paginated' }
    & { wells: Array<{ __typename?: 'Address' } | { __typename?: 'Assignment' } | { __typename?: 'Basin' } | { __typename?: 'Cabinet' } | { __typename?: 'ChangeRecord' } | { __typename?: 'Charge' } | { __typename?: 'City' } | { __typename?: 'Company' } | { __typename?: 'ConversionTable' } | { __typename?: 'Country' } | { __typename?: 'County' } | { __typename?: 'Document' } | { __typename?: 'Event' } | { __typename?: 'EventSubscription' } | { __typename?: 'Follow' } | { __typename?: 'Group' } | { __typename?: 'InventoryItem' } | { __typename?: 'LogEntry' } | { __typename?: 'NodeMutation' } | { __typename?: 'Notification' } | { __typename?: 'ObjectEventSubscription' } | { __typename?: 'PartType' } | { __typename?: 'PartTypeField' } | { __typename?: 'Permission' } | { __typename?: 'PressureRecording' } | { __typename?: 'PressureTransducer' } | { __typename?: 'PriceRule' } | { __typename?: 'RepairTicket' } | { __typename?: 'RequiredCharge' } | { __typename?: 'RunningProcedure' } | { __typename?: 'RunningProcedureRevision' } | { __typename?: 'RunningProcedureStep' } | { __typename?: 'RunningProcedureTicketStepResult' } | { __typename?: 'RunningProcedureVersion' } | { __typename?: 'SalesOrder' } | { __typename?: 'SalesOrderAddress' } | { __typename?: 'SalesOrderItem' } | { __typename?: 'ServiceTicket' } | { __typename?: 'SignatureRequest' } | { __typename?: 'SignatureResponse' } | { __typename?: 'State' } | { __typename?: 'TorqueWrench' } | { __typename?: 'User' } | (
      { __typename?: 'Well' }
      & WellListItemFragment
    )> }
    & PagedInfoFragment
  ) }
);

export type GetWellByIdQueryVariables = Exact<{
  uuid: Scalars['UUID'];
}>;


export type GetWellByIdQuery = (
  { __typename?: 'Query' }
  & { well?: Maybe<(
    { __typename?: 'Well' }
    & WellDetailFragment
  )> }
);

export type UpdateWellNameMutationVariables = Exact<{
  id: Scalars['UUID'];
  input: WellRenameInput;
}>;


export type UpdateWellNameMutation = (
  { __typename?: 'Mutation' }
  & { wellData: (
    { __typename?: 'WellPayload' }
    & { well?: Maybe<(
      { __typename?: 'Well' }
      & WellDetailFragment
    )> }
    & BasePayload_WellPayload_Fragment
  ) }
);

export type CompanyRenamesWellMutationVariables = Exact<{
  id: Scalars['UUID'];
  input: CompanyRenamesWellInput;
}>;


export type CompanyRenamesWellMutation = (
  { __typename?: 'Mutation' }
  & { wellData: (
    { __typename?: 'WellPayload' }
    & { well?: Maybe<(
      { __typename?: 'Well' }
      & WellDetailFragment
    )> }
    & BasePayload_WellPayload_Fragment
  ) }
);

export type CompanyAcquiresWellMutationVariables = Exact<{
  id: Scalars['UUID'];
  input: CompanyAcquiresWellInput;
}>;


export type CompanyAcquiresWellMutation = (
  { __typename?: 'Mutation' }
  & { wellData: (
    { __typename?: 'WellPayload' }
    & { well?: Maybe<(
      { __typename?: 'Well' }
      & WellDetailFragment
    )> }
    & BasePayload_WellPayload_Fragment
  ) }
);
