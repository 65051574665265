const overrides = (palette, typography) => ({
  root: {
    padding: "4px 10px",
    alignItems: "center"
  },
  message: {
    padding: "4px 0"
  }
})

export default overrides
