import moment from "moment"
import { UseSyncQueryPromise } from "../useSyncQuery"

// Downsync helpers
export type ProductSyncFilter = {
  modifiedGt: string
}
export const convertSyncDateToFilter = (
  date: Date | undefined
): ProductSyncFilter | undefined =>
  date ? { modifiedGt: moment(date).format("YYYY-MM-DDTHH:mmZ") } : undefined

export const processSyncQuery = (propName: string) => (
  syncQuery: UseSyncQueryPromise
): Promise<{ sync: string; success: boolean }> =>
  syncQuery
    .then(({ count, batchCount }) => {
      console.log(`Synced ${count} ${propName} in ${batchCount} batches.`)

      return {
        sync: propName,
        success: true
      }
    })
    .catch(err => {
      console.error(`Sync error for ${propName}: `, err)

      return {
        sync: propName,
        success: false
      }
    })
