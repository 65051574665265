import clsx from "clsx"
import { Button, ButtonProps } from "@material-ui/core"
import { lighten, makeStyles } from "@material-ui/core/styles"
import reduce from "lodash/reduce"

import * as colors from "theme/palettes/colors"

export enum TitanButtonColor {
  Blue = "blue",
  Brand = "brand",
  Gold = "gold",
  Grey = "gray",
  Green = "green",
  Indigo = "indigo",
  Orange = "orange",
  Red = "red",
  Standard = "standard"
}

export enum TitanButtonTextColor {
  Blue = "blueText",
  Red = "redText",
  Standard = "standardText"
}

export interface IButtonBaseProps
  extends Omit<ButtonProps, "color" | "variant"> {
  color?: TitanButtonColor
  condensed?: boolean
  icon?: boolean
  textColor?: TitanButtonTextColor
  variant?: "outlined" | "contained"
}

const _buttonColorMap = {
  [TitanButtonColor.Blue]: colors.blue[500],
  [TitanButtonColor.Brand]: colors.atlasMaroon,
  // [TitanButtonColor.Grey]: colors.grey[550],
  [TitanButtonColor.Green]: colors.green[500],
  [TitanButtonColor.Gold]: colors.gold,
  [TitanButtonColor.Indigo]: colors.indigo[500],
  [TitanButtonColor.Orange]: colors.orange[500],
  [TitanButtonColor.Red]: colors.red[500]
}

const useStyles = makeStyles(theme => {
  const buttonColorMap = {
    ..._buttonColorMap,
    [TitanButtonColor.Grey]:
      "light" === theme.palette.type ? colors.grey[550] : colors.grey[400]
  }
  const coloredButtonStyles = reduce(
    TitanButtonColor,
    (acc, color) => {
      const buttonColor = buttonColorMap[color]
      return buttonColor
        ? {
            ...acc,
            [color]: {
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: buttonColor,
              color: buttonColor
            },
            [`${color}-contained`]: {
              color:
                "light" === theme.palette.type
                  ? colors.white
                  : theme.palette.background.paper,
              backgroundColor: buttonColor,
              borderWidth: 0
            },
            [`${color}-contained-disabled`]: {
              backgroundColor:
                "light" === theme.palette.type
                  ? colors.grey[200]
                  : colors.grey[550]
            },
            [`${color}-contained-hover`]: {
              "&:hover": { backgroundColor: lighten(buttonColor, 0.25) }
            }
          }
        : acc
    },
    {}
  )

  return {
    root:
      "light" === theme.palette.type
        ? { whiteSpace: "nowrap" }
        : {
            whiteSpace: "nowrap",
            backgroundColor: theme.palette.background.paper,
            borderColor: colors.grey[600],
            "&:hover": {
              backgroundColor: colors.grey[800]
            }
          },
    normal: {
      height: 40,
      "&$icon": {
        width: 38
      }
    },
    condensed: {
      height: 20,
      paddingTop: 0,
      paddingRight: 6,
      paddingBottom: 0,
      paddingLeft: 6,
      "&$icon": {
        width: 24
      },
      "& .MuiButton-startIcon": {
        marginRight: 2
      },
      "& .MuiButton-endIcon": {
        marginLeft: 2
      }
    },
    icon: {
      minWidth: 0,
      padding: 0,
      "& .MuiButton-label": {
        dsiplay: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiButton-startIcon": {
          margin: 0
        }
      }
    },
    ...coloredButtonStyles,
    [TitanButtonTextColor.Blue]: {
      color: colors.blue[500]
    },
    [TitanButtonTextColor.Red]: {
      color: colors.red[500]
    },
    [TitanButtonTextColor.Standard]: {}
  }
})

const ButtonBase: React.FunctionComponent<IButtonBaseProps> = ({
  color = TitanButtonColor.Standard,
  condensed = false,
  icon = false,
  textColor = TitanButtonTextColor.Blue,
  variant = "outlined",
  className,
  children,
  endIcon,
  ...props
}) => {
  const classes = useStyles()

  const { disabled = false } = props
  const contained = "contained" === variant

  return (
    <Button
      className={clsx(className, classes.root, classes[color], {
        [classes[`${color}-contained`]]: contained,
        [classes[`${color}-contained-disabled`]]: contained && disabled,
        [classes[`${color}-contained-hover`]]: contained && !disabled,
        [classes[textColor]]: TitanButtonColor.Standard === color,
        [classes.condensed]: condensed,
        [classes.normal]: !condensed,
        [classes.icon]: icon
      })}
      variant={variant}
      children={icon ? undefined : children}
      endIcon={icon ? undefined : endIcon}
      {...props}
    />
  )
}

export default ButtonBase
