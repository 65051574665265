import { FieldType, FileUploadStatus } from "client/types"

export const statusesWithThumbnails: Array<
  FileUploadStatus | null | undefined
> = [
  FileUploadStatus.Attached,
  FileUploadStatus.AttachedOffline,
  FileUploadStatus.Uploaded,
  FileUploadStatus.UploadPending,
  FileUploadStatus.Uploading,
  FileUploadStatus.RemovalError
]

export const unitsMap = {
  [FieldType.Size]: "in",
  [FieldType.Duration]: "min",
  [FieldType.Grade]: "",
  [FieldType.Length]: "in",
  [FieldType.LengthFt]: ["ft", "in"],
  [FieldType.Pressure]: "psi",
  [FieldType.Temperature]: "\u00b0F",
  [FieldType.Text]: "",
  [FieldType.Torque]: "ft\u22c5lbs",
  [FieldType.Weight]: "lbs",

  [FieldType.PressureAndDuration]: ["psi", "min"],
  [FieldType.SizeWeightGrade]: ["in", "lb/ft", ""]
}
