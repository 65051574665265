import { useMemo } from "react"
import compact from "lodash/compact"
import keys from "lodash/keys"
import values from "lodash/values"
import { ContentDescriptionMap } from "./useAttachments"

export const defaultDescription = "all file types"
export const useContentDescriptionMap: (
  c?: ContentDescriptionMap
) => [Array<string> | undefined, string] = contentDescriptionMap => {
  const contentTypes = useMemo(
    () => (contentDescriptionMap ? keys(contentDescriptionMap) : undefined),
    [contentDescriptionMap]
  )

  const contentDescriptions = useMemo(
    () =>
      contentDescriptionMap
        ? compact(values(contentDescriptionMap)).join(", ")
        : defaultDescription,
    [contentDescriptionMap]
  )

  return [contentTypes, contentDescriptions]
}
