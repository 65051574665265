import { TypePolicies } from "@apollo/client/cache/inmemory/policies"
import { appVars } from "./localVars"

const typePolicies: TypePolicies = {
  Query: {
    fields: {
      appVars: {
        read() {
          return appVars()
        }
      },
      getTicketCharges: {
        merge: false
      }
    }
  },
  Mutation: {
    fields: {
      mutateNodeByKey: {
        merge(existing, incoming) {
          return incoming
        }
      }
    }
  },
  User: {
    fields: {
      groups: {
        merge: false
      }
    }
  },
  City: {
    merge: true
  },
  Country: {
    merge: true
  },
  County: {
    merge: true
  },
  Document: {
    keyFields: ["uuid", "versionId"]
  },
  PartType: {
    fields: {
      fields: {
        merge(existing, incoming) {
          return incoming
        }
      },
      steps: {
        merge(existing, incoming) {
          return incoming
        }
      }
    }
  },
  RunningProcedure: {
    fields: {
      versions: {
        merge: false
      }
    }
  },
  RunningProcedureStep: {
    keyFields: ["id", "order"]
  },
  RunningProcedureVersion: {
    fields: {
      revisions: {
        merge: false
      }
    }
  },
  SalesOrder: {
    fields: {
      availableStatusTransitions: {
        merge: false
      },
      company: {
        merge: true
      },
      billTo: {
        merge: true
      },
      shipTo: {
        merge: true
      }
    }
  },
  ServiceTicket: {
    fields: {
      availableStatusTransitions: {
        merge: false
      },
      charges: {
        merge: false
      },
      snapshotDocuments: {
        merge: false
      }
    }
  },
  SignatureRequest: {
    merge: false
  },
  State: {
    merge: true
  },
  Well: {
    merge: true,
    fields: {
      company: {
        merge: false
      }
    }
  }
}

export default typePolicies
