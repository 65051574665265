import { useCallback } from "react"
import useFilters, { IFilterUndo } from "./useFilters"

interface PagingState {
  page: number
  pageSize: number
}

// Suggested rows per page
export const rowsPerPageOptions = [15, 50, 100]

export const suggestedGridProps = {
  // Styling
  headerHeight: 32,
  rowHeight: 46,
  // Auto-height expands the table to full height of result set, but does not change paging
  autoHeight: true,
  autoPageSize: false
}
export interface IUsePagingArgs<TPagingState> {
  name: string
  defaultFilters?: TPagingState
  maxDeltas?: number
}

export interface IUsePagingResult<TPagingState> {
  paging: TPagingState
  setPaging: React.Dispatch<React.SetStateAction<TPagingState>>
  resetPaging: () => void
  undo: IFilterUndo<TPagingState>
  handlePageChange: (page: number, details: any) => void
  handlePageSizeChange: (pageSize: number, details: any) => void
}

export default function usePaging<
  TPagingState extends PagingState = PagingState
>({
  name,
  defaultFilters = {
    page: 1,
    pageSize: 50
  } as TPagingState
}: IUsePagingArgs<TPagingState>): IUsePagingResult<TPagingState> {
  const {
    filters: paging,
    setFilters: setPaging,
    resetFilters: resetPaging,
    undo
  } = useFilters<TPagingState>({
    name: `${name}.paging`,
    defaultFilters
  })

  const handlePageChange = usePagingChange("page", paging, setPaging)
  const handlePageSizeChange = usePagingChange("pageSize", paging, setPaging)

  return {
    paging,
    setPaging,
    resetPaging,
    undo,
    handlePageChange,
    handlePageSizeChange
  }
}

export const usePagingChange = (prop, paging, setPaging) => {
  return useCallback(
    (value, details, ...args) => {
      // Set limits on page to the calculated page count
      if (prop === "page") {
        value = Math.max(
          1,
          Math.min(value + 1, details.api.getState().pagination.pageCount)
        )
      }
      // Reset page when page size changes
      if (prop === "pageSize" && paging.pageSize !== value) {
        paging.page = 1
      }
      const paged = {
        ...paging,
        [prop]: value
      }
      setPaging(paged)
    },
    [setPaging, paging, prop]
  )
}
