import { useState } from "react"
import { matchPath, useLocation } from "react-router-dom"
import clsx from "clsx"
import { List, ListItem, Collapse } from "@material-ui/core"
import some from "lodash/some"

import { NavLinkListButton } from "components"
import { createNavNode, NavLinkNode, NavLinkListNode } from "./utils"
import useNavBarStyles from "./useNavBarStyles"
import useWaffle from "hooks/useWaffle"

export type NavLinkListProps = NavLinkListNode & { className?: string }
const NavLinkList: React.FunctionComponent<NavLinkListProps> = ({
  className = "nav-link-list",
  title,
  icon: Icon,
  items,
  dataTestid
}) => {
  const classes = useNavBarStyles()
  const waffle = useWaffle()
  const { pathname } = useLocation()
  const initialOpen = some(items, item =>
    Boolean(matchPath((item as NavLinkNode).href, pathname))
  )

  const [open, setOpen] = useState(initialOpen)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }
  return (
    <ListItem
      className={clsx(classes.listItem, className)}
      disableGutters
      key={title}
      data-testid={dataTestid}
    >
      <NavLinkListButton
        onClick={handleToggle}
        active={initialOpen}
        open={open}
        Icon={Icon}
        title={title}
      />
      <Collapse in={open}>
        {/* The TypeScript defs prevent NavLinkLists from containing NavSections,
            so this won't double nest */}
        <List className={classes.list}>
          {createNavNode(items, undefined, waffle)}
        </List>
      </Collapse>
    </ListItem>
  )
}

export default NavLinkList
