import { gql } from "@apollo/client"

const baseInventoryItem = gql`
  fragment BaseInventoryItem on InventoryItem {
    id
    number
    description
    longDescription
    itemUom {
      long
      short
    }
    currency {
      name
    }
    created
    modified
    suggestedPrice
    standardCost
    price
    revision
    partType {
      id
      name
    }
  }
`

const inventoryFragments = {
  baseInventoryItem,
  activeBaseInventoryItem: gql`
    fragment ActiveBaseInventoryItem on InventoryItem {
      ...BaseInventoryItem
      active
    }
    ${baseInventoryItem}
  `
}

export default inventoryFragments
