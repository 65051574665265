import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import WebFont from "webfontloader"

import App from "./App"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import * as Sentry from "@sentry/react"
import takeRight from "lodash/takeRight"

import { MessageTypes } from "context/ServiceWorkerContext"

import "./fonts.css"
import { updateAppVars } from "client/localVars"
import { db } from "hooks/sync/useSyncDatabase"

WebFont.load({
  google: {
    families: ["La Belle Aurore"]
  }
})

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    release: "badger-frontend@" + process.env.REACT_APP_VERSION,
    environment: process.env.REACT_APP_SENTRY_ENV ?? "unknown",
    dsn: process.env.REACT_APP_SENTRY_DSN,
    beforeSend: evt => {
      // Default to the browser's online state if the error happens prior to
      // <OnlineContext /> setting the Sentry context
      const online = evt?.contexts?.appState?.["online"] ?? navigator.onLine
      const upsync = evt?.contexts?.upsync?.upsync

      // produce causes "object is not extensible errors", so modify object directly
      // Limit the number of breadcrumbs to help keep POST data under 100KB
      evt.breadcrumbs = takeRight(evt.breadcrumbs, 40)

      if (online) {
        return evt
      } else {
        if (upsync) {
          return evt
        } else {
          db.sentry.put(evt)
          return null
        }
      }
    }
  })
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: registration => {
    updateAppVars({ updateAvailable: true })
  },
  // The UI is set not to display unless there's a service worker controller.
  // This causes issues on the first page load, because there's not a
  // previously registered controller. So if we don't currently have a
  // controller, reload
  onSuccess: registration => {
    if (navigator.serviceWorker?.controller === null) {
      window.location.reload()
    }
  }
})

navigator.serviceWorker?.ready.then(registration => {
  // Check if we have a waiting service worker
  if (registration.waiting && registration.active) {
    updateAppVars({ updateAvailable: true })
  }

  // Flush expired tickets on page load
  navigator.serviceWorker?.controller?.postMessage({
    type: MessageTypes.FlushStaleTickets
  })
  navigator.serviceWorker?.controller?.postMessage({
    type: MessageTypes.RegisterSentryClient
  })
})
