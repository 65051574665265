import { Typography, TypographyProps } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import enums from "client/enums"
import {
  SignatureRequestTypes,
  SignatureResponseTypes,
  SignatureStateDisplayFragment,
  SignatureStates
} from "client/types"
import clsx from "clsx"
import { green, grey, red } from "theme/palettes/colors"
import { labelColors } from "./index"

enum SignatureInfoDisplayEnum {
  NoSig = "NO_SIG",
  Requested = "REQUESTED",
  Signed = "SIGNED",
  None = "NONE"
}

export enum SignatureInfoDisplayLabels {
  NO_SIG = "No Signature",
  REQUESTED = "Request",
  SIGNED = "Signed",
  NONE = "None"
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 0,
    flexShrink: 0,
    borderRadius: theme.shape.borderRadius,
    whiteSpace: "nowrap",
    fontWeight: "bold"
  },
  normal: {
    padding: theme.spacing(0, 1.5),
    height: 40
  },
  condensed: {
    padding: theme.spacing(0, 0.5),
    height: 20,
    textTransform: "uppercase"
  },
  [SignatureInfoDisplayEnum.Signed]: labelColors(theme, green[100], green[500]),
  [SignatureInfoDisplayEnum.Requested]: labelColors(
    theme,
    grey[100],
    grey[500]
  ),
  [SignatureInfoDisplayEnum.NoSig]: labelColors(theme, red[100], red[500])
}))

interface ISignatureInfoLabelProps extends TypographyProps {
  object: SignatureStateDisplayFragment
  condensed?: boolean
}

const SignatureInfoLabel: React.FunctionComponent<ISignatureInfoLabelProps> = ({
  object,
  condensed = false,
  ...props
}) => {
  const classes = useStyles()

  const { signatureState, latestResponse, latestRequest } = object

  let displayOption = SignatureInfoDisplayEnum.None
  let response = latestResponse
  let label = ""

  // If the request has a response and there is no floating response, use it
  if (!response && latestRequest && latestRequest.latestResponse) {
    response = latestRequest.latestResponse
  }

  if (response) {
    displayOption = SignatureInfoDisplayEnum.Signed
  }

  if (
    signatureState === SignatureStates.Rejected ||
    (latestResponse && latestResponse.type === SignatureResponseTypes.Rejected)
  ) {
    displayOption = SignatureInfoDisplayEnum.NoSig
  }

  if (
    !response &&
    latestRequest &&
    latestRequest.type === SignatureRequestTypes.Email
  ) {
    displayOption = SignatureInfoDisplayEnum.Requested
    // Custom label for this case, will expand when more request types are added.
    label = "Request Emailed"
  } else {
    label = SignatureInfoDisplayLabels[displayOption]
  }

  // When no displayOption is chosen, don't return a component
  if (displayOption === SignatureInfoDisplayEnum.None) return <></>

  return (
    <Typography
      className={clsx(
        classes.root,
        { [classes.normal]: !condensed, [classes.condensed]: condensed },
        classes[displayOption]
      )}
      {...props}
    >
      {displayOption === SignatureInfoDisplayEnum.Signed && response?.type
        ? `${label} - ${enums.SignatureResponseTypes.description(
            response?.type
          )}`
        : label}
    </Typography>
  )
}

export default SignatureInfoLabel
