import { useEffect, useState } from "react"
import DropZonePreview from "components/DropZonePreview"
import { useAttachmentsContext } from "../hooks/useAttachments"
import { useOnlineContext } from "context/OnlineContext"
import { FileUploadStatus } from "client/types"

import useSyncDatabase, { SyncTable } from "hooks/sync/useSyncDatabase"
import {
  Document,
  FullThumbnailFragment,
  SmallThumbnailFragment
} from "client/types"

export {
  PreviewHandlerNames,
  SettablePreviewPropNames
} from "components/DropZonePreview"
export type {
  InternalPreviewProps,
  PreviewHandlers
} from "components/DropZonePreview"
export type PreviewProps = {}

export const dataTestid = "preview"
const Preview: React.FunctionComponent<PreviewProps> = () => {
  const [onlineAwareDocuments, setOnlineAwareDocuments] = useState<
    Array<Document & FullThumbnailFragment & SmallThumbnailFragment>
  >([])
  const { appOnline } = useOnlineContext()
  const {
    documents = [],
    previewProps,
    previewHandlers
  } = useAttachmentsContext()
  const { db } = useSyncDatabase()

  useEffect(() => {
    const mapDocuments = async () => {
      if (appOnline) {
        setOnlineAwareDocuments(
          documents as Array<
            Document & FullThumbnailFragment & SmallThumbnailFragment
          >
        )
      } else {
        const parsedDocuments = await Promise.all(
          (documents as Array<
            Document & FullThumbnailFragment & SmallThumbnailFragment
          >).map(async document => {
            if (FileUploadStatus.Attached !== document.status) return document

            const docData = await db[SyncTable.DocumentData]
              .get(document.id)
              .catch(() => {})

            // This is a front-end only field, so it's processed here instead
            // of in the service worker.
            const status = docData
              ? FileUploadStatus.AttachedOfflineEnabled
              : FileUploadStatus.AttachedOffline

            return {
              ...document,
              status
            }
          })
        )
        setOnlineAwareDocuments(parsedDocuments)
      }
    }

    mapDocuments()
  }, [appOnline, documents, db])

  return (
    <DropZonePreview
      documents={onlineAwareDocuments}
      previewProps={previewProps}
      previewHandlers={previewHandlers}
    />
  )
}

export default Preview
