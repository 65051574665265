import {
  CardActions,
  CardHeader,
  Divider,
  Popover,
  PopoverProps
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { ListNotificationFragment, Scalars } from "client/types"
import { Link as RouterLink } from "react-router-dom"
import { EmptyList, NotificationList } from "./components"
import { Button } from "components"

const useStyles = makeStyles(() => ({
  root: {
    width: 350,
    maxWidth: "100%"
  },
  actions: {
    justifyContent: "center"
  }
}))

interface NotificationsPopoverProps extends PopoverProps {
  notifications: Array<ListNotificationFragment>
  markRead: (...ids: Array<Scalars["ID"]>) => void
}

const NotificationsPopover: React.ComponentType<NotificationsPopoverProps> = ({
  notifications,
  markRead,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Popover
      {...rest}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
    >
      <div className={classes.root}>
        <CardHeader title="Notifications" />
        <Divider />
        {notifications.length > 0 ? (
          <NotificationList notifications={notifications} markRead={markRead} />
        ) : (
          <EmptyList />
        )}
        <Divider />
        <CardActions className={classes.actions}>
          <RouterLink to="/dashboard/notifications">
            <Button>See all</Button>
          </RouterLink>
        </CardActions>
      </div>
    </Popover>
  )
}

export default NotificationsPopover
