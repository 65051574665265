import { updateAppVars } from "client/localVars"
import { updateMessages, UpdateMessageType } from "components/AppUpdateButton"
import { useConfirm } from "components/ConfirmDialog"
import { useServiceWorkerContext } from "context/ServiceWorkerContext"
import { useCallback, useEffect, useState } from "react"
import useUpdateAvailable from "./useUpdateAvailable"

export const useUpdateConfirm = (messageType: UpdateMessageType) => {
  const [updateShown, setUpdateShown] = useState(false)
  const updateAvailable = useUpdateAvailable()

  const confirm = useConfirm()
  const { skipWaiting } = useServiceWorkerContext()

  const confirmReload = useCallback(() => {
    confirm({
      title: "Update Available!",
      confirmationText: "Install Update Now!",
      description: updateMessages[messageType]
    }).then(() => {
      updateAppVars({ updateAvailable: false })
      skipWaiting()
    })
  }, [confirm, messageType, skipWaiting])

  useEffect(() => {
    if (updateAvailable && !updateShown) {
      confirmReload()
      setUpdateShown(true)
    }
  }, [updateShown, confirmReload, updateAvailable])

  return {
    confirmReload,
    updateAvailable
  }
}

export default useUpdateConfirm
