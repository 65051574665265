import { gql } from "@apollo/client"

// import { default as wellFragments } from "fragments/wells";
import { default as payloadFragments } from "fragments/payload"
import wellFragments from "fragments/wells"
import listPageFragments from "fragments/listPages"

export const CREATE_WELL = gql`
  mutation createWell($input: WellInput!) {
    wellData: introduceWell(input: $input) {
      ...BasePayload
      well: object {
        id
      }
    }
  }
  ${payloadFragments.payloadFragment}
`

export const UPDATE_WELL_DETAILS = gql`
  mutation updateWellDetails($wellId: UUID!, $input: WellDetailInput!) {
    wellData: updateWellDetails(wellId: $wellId, input: $input) {
      ...BasePayload
      well: object {
        ...WellDetail
      }
    }
  }
  ${payloadFragments.payloadFragment}
  ${wellFragments.wellDetail}
`

export const GET_PAGED_WELLS = gql`
  query getPagedFilteredWells($paged: PagedInput, $filter: WellFilterInput) {
    pagedData: getPagedWells(paged: $paged, filter: $filter) {
      wells: objects {
        ...WellListItem
      }
      ...PagedInfo
    }
  }
  ${wellFragments.wellListItem}
  ${listPageFragments.pagedInfo}
`

export const GET_WELL_BY_ID = gql`
  query getWellById($uuid: UUID!) {
    well: well(wellId: $uuid) {
      ...WellDetail
    }
  }
  ${wellFragments.wellDetail}
`

export const UPDATE_WELL_NAME = gql`
  mutation updateWellName($id: UUID!, $input: WellRenameInput!) {
    wellData: renameWell(wellId: $id, input: $input) {
      ...BasePayload
      well: object {
        ...WellDetail
      }
    }
  }
  ${payloadFragments.payloadFragment}
  ${wellFragments.wellDetail}
`

export const UPDATE_WEll_NAME_FOR_COMPANY = gql`
  mutation companyRenamesWell($id: UUID!, $input: CompanyRenamesWellInput!) {
    wellData: companyRenameWell(wellId: $id, input: $input) {
      ...BasePayload
      well: object {
        ...WellDetail
      }
    }
  }
  ${payloadFragments.payloadFragment}
  ${wellFragments.wellDetail}
`

export const WELL_ACQUIRED = gql`
  mutation companyAcquiresWell($id: UUID!, $input: CompanyAcquiresWellInput!) {
    wellData: companyAcquiresWell(wellId: $id, input: $input) {
      ...BasePayload
      well: object {
        ...WellDetail
      }
    }
  }
  ${payloadFragments.payloadFragment}
  ${wellFragments.wellDetail}
`
