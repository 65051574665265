import { useMutation } from "@apollo/client"
import { useFormik } from "formik"
import * as Yup from "yup"

import {
  CreateWellMutation,
  CreateWellMutationVariables,
  WellInput
} from "client/types"
import {
  CreateWellData,
  defaultCreateWellValues
} from "components/forms/CreateWellForm"
import { FormStateContextType } from "hooks/forms/useFormState"
import { CREATE_WELL } from "queries/wells"
import { createCastId } from "utils/yup"
import {
  latitude,
  longitude
} from "views/WellDetails/components/wellDetailSchema"

type useWellCreateProps = {
  onAdd: (...args: any[]) => any
  onError: FormStateContextType["setFormErrors"]
  onFieldError: FormStateContextType["setFieldErrors"]
  initialValues?: Partial<CreateWellData>
}

export const createWellSchema = Yup.object().shape({
  apiNumber: Yup.string(),
  company: createCastId(
    Yup.object()
      .shape({
        id: Yup.number()
      })
      .nullable()
      .required("Company is required")
  ),
  city: createCastId(
    Yup.object()
      .shape({
        id: Yup.number()
      })
      .nullable()
      .required("City is required")
  ),
  country: Yup.object().strip(true),
  basin: createCastId(
    Yup.object()
      .shape({
        id: Yup.number()
      })
      .nullable()
      .required("Basin is required")
  ),
  name: Yup.string().required("A well name is required"),
  directions: Yup.string(),
  latitude,
  longitude
})

const useWellCreate = ({
  onAdd,
  onError,
  onFieldError,
  initialValues = {}
}: useWellCreateProps) => {
  const [createWell] = useMutation<
    CreateWellMutation,
    CreateWellMutationVariables
  >(CREATE_WELL)

  const formik = useFormik<CreateWellData>({
    initialValues: {
      ...defaultCreateWellValues,
      ...initialValues
    },
    enableReinitialize: true,
    validationSchema: createWellSchema,
    onSubmit: async (values, actions) => {
      const castValues = createWellSchema.cast(values, {
        context: { cast: "create" }
      }) as WellInput

      try {
        const {
          data: {
            // @ts-ignore
            wellData: { well, success, errors }
          }
        } = await createWell({
          variables: { input: castValues }
        })

        if (success) {
          onAdd(well)
        } else {
          console.error(errors)
          onFieldError(errors)
        }
      } catch (err) {
        onError(err)
      }

      actions.setSubmitting(false)
    }
  })

  return { formik }
}

export default useWellCreate
