import { gql } from "@apollo/client"

const payloadFragments = {
  payloadFragment: gql`
    fragment BasePayload on Payload {
      success
      errors {
        name
        values {
          error
          code
        }
      }
    }
  `
}

export default payloadFragments
