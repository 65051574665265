import { Maybe, RunningProcedure, RunningProcedureVersion } from "client/types"
import isNil from "lodash/isNil"

// RP Versions have format {RPTitle}-{VersionInt}
export const convertToDisplayText: (
  versionNumber: RunningProcedureVersion["number"]
) => Maybe<string> = versionNumber => {
  if (null == versionNumber) return null

  const base = versionNumber.substring(0, versionNumber.lastIndexOf("-"))
  if ("" === base) return null
  const suffixString = versionNumber.substring(
    versionNumber.lastIndexOf("-") + 1
  )
  if (suffixString.match(/\D/)) return null
  const convertedSuffix = convertToLetters(Number(suffixString))

  return null === convertedSuffix ? null : `${base}-${convertedSuffix}`
}

const baseCharCode = "A".charCodeAt(0) - 1
export const convertToLetters: (i: number) => Maybe<string> = i => {
  if (isNaN(i) || Infinity === Math.abs(i) || i < 1) return null
  i = Math.floor(i)

  // base case
  if (27 > i) {
    return String.fromCharCode(baseCharCode + i)
  } else {
    const remainder = Math.floor(i / 26)
    const lastDigit = i - remainder * 26
    return `${convertToLetters(remainder)}${String.fromCharCode(
      baseCharCode + lastDigit
    )}`
  }
}

// RP Versions have format {RPTitle}-{VersionInt}
export const incrementRunningProcedureVersion: (
  currentVersionNumber: RunningProcedureVersion["number"],
  title?: RunningProcedure["title"]
) => RunningProcedureVersion["number"] = (currentVersionNumber, title) => {
  // TODO: Add format validation for version numbers
  if (isNil(currentVersionNumber) || "0" === currentVersionNumber) {
    if (isNil(title) || "" === title) return null
    return `${title}-1`
  }

  const base = currentVersionNumber.substring(
    0,
    currentVersionNumber.lastIndexOf("-")
  )
  if ("" === base) return null

  const suffixString = currentVersionNumber.substring(
    currentVersionNumber.lastIndexOf("-") + 1
  )
  if (suffixString.match(/\D/)) return null

  const suffix = Number(suffixString) + 1
  return `${base}-${suffix}`
}
