import clsx from "clsx"
import { CircularProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import ButtonBase, { TitanButtonColor } from "../ButtonBase"
import ConfirmButtonRed, { IConfirmButtonRedProps } from "./ConfirmButtonRed"

const defaultInProgressText = <>Submitting&hellip;</>

interface IReactiveConfirmButtonRedProps extends IConfirmButtonRedProps {
  inProgress?: boolean
  inProgressText?: JSX.Element | string
}

const useStyles = makeStyles(theme => ({
  progressCondensed: {
    marginLeft: 2,
    marginRight: 2
  }
}))

const ReactiveConfirmButtonRed: React.FunctionComponent<IReactiveConfirmButtonRedProps> = ({
  inProgress = false,
  inProgressText = defaultInProgressText,
  ...props
}) => {
  const { children, disabled, onClick, ...inProgressProps } = props
  const { condensed = false } = props
  const classes = useStyles()

  return inProgress ? (
    <ButtonBase
      {...inProgressProps}
      startIcon={
        <CircularProgress
          className={clsx({ [classes.progressCondensed]: condensed })}
          size={condensed ? "0.5em" : "0.75em"}
          color="inherit"
        />
      }
      color={TitanButtonColor.Grey}
      variant="contained"
      disableRipple
      disableFocusRipple
    >
      {inProgressText}
    </ButtonBase>
  ) : (
    <ConfirmButtonRed {...props} />
  )
}

export default ReactiveConfirmButtonRed
