import {
  LazyQueryResult,
  OperationVariables,
  QueryHookOptions
} from "@apollo/client"
import { PagedInfo } from "client/types"
import { DocumentNode } from "graphql"
import get from "lodash/get"
import useListError from "./useListError"
import { useSkipQuery } from "hooks/graphql"

export interface IUseListPagedQueryArgs<TData, TVars>
  extends QueryHookOptions<TData, TVars> {
  query: DocumentNode
  propName: string
  pagePropName: string
}

export type UseListPagedQueryResult<
  ItemType,
  TData = any,
  TVariables = OperationVariables
> = LazyQueryResult<TData, TVariables> & {
  displayData: Array<ItemType>
  pagedInfo: PagedInfo
}

export default function useListPagedQuery<
  ItemType = any,
  QueryData = any,
  QueryVariables = OperationVariables
>({
  query,
  propName,
  pagePropName,
  skip = false,
  ...queryArgs
}: // @ts-ignore
IUseListPagedQueryArgs<QueryData, QueryVariables>): UseListPagedQueryResult<
  ItemType,
  QueryData,
  QueryVariables
> {
  const queryResult = useSkipQuery<QueryData, QueryVariables>(query, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    ...queryArgs
  })

  const { data, error } = queryResult
  useListError({ error })

  const displayData = get(data, propName, []) as Array<ItemType>
  const pagedInfo = get(data, pagePropName, {
    page: 1,
    hasNextPage: false,
    hasPreviousPage: false
  }) as PagedInfo

  return { ...queryResult, displayData, pagedInfo }
}
