import uniqueId from "lodash/uniqueId"
import Typography from "@material-ui/core/Typography"
import { generatePath, Link as RouterLink } from "react-router-dom"
import { Link } from "@material-ui/core"
import isFunction from "lodash/isFunction"

// TODO: convert these to use defaultRichTextElements of intlShape config

export const strong = (...c) => <strong key={uniqueId()}>{c}</strong>

export const p = (...c) => (
  <Typography key={uniqueId()} variant="body1" gutterBottom={true}>
    {c}
  </Typography>
)

export const ul = (...c) => <ul>{c}</ul>

export const li = (...c) => (
  <Typography variant="body1" component="li" key={uniqueId()}>
    {c}
  </Typography>
)

export const pathBuilder = (path, transform = params => params) => {
  return params => {
    params = transform(params)
    if (isFunction(path)) {
      path = path(params)
    }
    return generatePath(path, params)
  }
}

const getLinkFromType = object => {
  try {
    return linkTypeMap[object.__typename]
  } catch {
    return linkTypeMap["None"]
  }
}

export const linkTypeMap = {
  None: () => "",
  User: pathBuilder("/users/:id"),
  Well: pathBuilder("/wells/:id"),
  ServiceTicket: pathBuilder("/tickets/:number"),
  RunningProcedure: pathBuilder("/running-procedures/:id"),
  PartType: pathBuilder("/part-types/:id"),
  SalesOrder: pathBuilder("/sales-orders/:number"),
  SignatureResponse: pathBuilder(
    params =>
      getLinkFromType(params.objectSnapshot)(params.objectSnapshot) +
      "/signatures"
  ),
  SignatureRequest: pathBuilder(
    params =>
      getLinkFromType(params.objectSnapshot)(params.objectSnapshot) +
      "/signatures"
  ),
  Charge: pathBuilder("/tickets/:number/charges", params => ({
    // Extract the ticket number from the nested ticket field for the path param
    number: params.ticket.number,
    ...params
  }))
}

export const links = ({
  actor,
  target,
  object,
  actorId,
  targetId,
  objectId,
  eventData
}) => ({
  actorlink: (...c) => (
    <Link
      to={getLinkFromType(actor)({
        id: actorId,
        ...eventData,
        ...actor
      })}
      component={RouterLink}
    >
      {c}
    </Link>
  ),
  targetlink: (...c) => (
    <Link
      to={getLinkFromType(target)({
        id: targetId,
        ...eventData,
        ...target
      })}
      component={RouterLink}
    >
      {c}
    </Link>
  ),
  objectlink: (...c) => (
    <Link
      to={getLinkFromType(object)({
        id: objectId,
        ...eventData,
        ...object
      })}
      component={RouterLink}
    >
      {c}
    </Link>
  )
})

const messageValues = {
  strong,
  p,
  ul,
  li
}

export default messageValues
