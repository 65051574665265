import { useLocalStorage } from "web-api-hooks"
import { useUndoable } from "state-hooks"
import { MixedSchema } from "yup"

export interface IFilterSchemas {
  filterStripSchema?: MixedSchema<any>
  filterTransformSchema?: MixedSchema<any>
  filterValidationSchema?: MixedSchema<any>
}

export type DefaultFilterStateType = Record<string, any>

export interface IUseFiltersArgs<TFilterState> {
  name: string
  defaultFilters: TFilterState
  maxDeltas?: number
}

export interface IFilterUndo<TFilterState> {
  undo: () => void
  redo: () => void
  past: Array<TFilterState>
  future: Array<TFilterState>
  hasPast: boolean
  hasFuture: boolean
  jump: (delta: number) => void
}

export interface IUseFilterResult<TFilterState> {
  filters: TFilterState
  setFilters: React.Dispatch<React.SetStateAction<TFilterState>>
  resetFilters: () => void
  undo: IFilterUndo<TFilterState>
}

export default function useFilters<
  TFilterState extends DefaultFilterStateType = DefaultFilterStateType
>({
  name,
  defaultFilters,
  maxDeltas = 100
}: IUseFiltersArgs<TFilterState>): IUseFilterResult<TFilterState> {
  const filterKey = `filter:${name}`

  const [filters, setFilters, undo] = useUndoable(
    useLocalStorage(filterKey, defaultFilters),
    maxDeltas
  )
  const resetFilters = () => setFilters(defaultFilters)

  return {
    filters: { ...defaultFilters, ...filters },
    setFilters,
    resetFilters,
    undo: {
      ...undo,
      hasPast: !!undo.past.length,
      hasFuture: !!undo.future.length
    }
  }
}
