import { DocumentNode } from "@apollo/client"
import {
  IntrospectionEnumType,
  IntrospectionQuery,
  IntrospectionType,
  IntrospectionTypeRef,
  visit,
  TypeKind
} from "graphql"
import * as Yup from "yup"
import moment from "moment"
import find from "lodash/find"
import has from "lodash/has"
import isEqual from "lodash/isEqual"
import isObject from "lodash/isObject"
import keys from "lodash/keys"
import map from "lodash/map"
import reduce from "lodash/reduce"
import reject from "lodash/reject"
import set from "lodash/set"
import some from "lodash/some"
import values from "lodash/values"

import introspection from "client/introspection.json"

// pass in edges, get out just the nodes
export const edgeLess = (edges, node = "node") => {
  // If passing in an object that has edges, use them
  if (has(edges, "edges")) {
    edges = edges.edges
  }
  // Node selector
  return reject(map(edges, node), node => undefined === node)
}

// Using a symbol to guarantee there will be no data collisions
export const fragmentPlaceholder = Symbol("fragment placeholder")
export const fragmentNotFoundPlaceholder = Symbol("fragment to merge not found")
export const defaultQueryName = "anonymousQuery"
export type ASTShapeNode =
  | typeof fragmentPlaceholder
  | typeof fragmentNotFoundPlaceholder
  | { [key: string]: ASTShapeNode }

export type ASTShape = {
  queries: Record<string, ASTShapeNode>
  fragments: Record<string, ASTShapeNode>
}

export const containsFragments: (shape: ASTShapeNode) => boolean = shape => {
  if (fragmentPlaceholder === shape) return true
  if (isEqual(shape, {})) return false
  return some(values(shape), containsFragments)
}

export const getASTShapes: (AST: DocumentNode) => ASTShape = AST => {
  let unnamedQueryCounter = 0
  let rootPath = ""
  let objectPath: Array<string> = []
  let queries: Record<string, any> = {}
  let fragments: Record<string, any> = {}
  let rootObject: Record<string, any> | undefined

  visit(AST, {
    OperationDefinition: {
      enter(node) {
        rootPath =
          node?.name?.value ?? `${defaultQueryName}${unnamedQueryCounter++}`
        rootObject = queries
      },
      leave() {
        rootPath = ""
        rootObject = undefined
      }
    },
    FragmentDefinition: {
      enter(node) {
        rootPath = node.name.value
        rootObject = fragments
      },
      leave() {
        rootPath = ""
        rootObject = undefined
      }
    },
    Field: {
      enter(node) {
        const name = node?.alias?.value ?? node?.name?.value
        objectPath.push(name)
        set(rootObject as object, [rootPath, ...objectPath], {})
      },
      leave() {
        objectPath.pop()
      }
    },
    FragmentSpread: {
      enter(node) {
        const name = node?.name?.value
        objectPath.push(name)
        set(
          rootObject as object,
          [rootPath, ...objectPath],
          fragmentPlaceholder
        )
      },
      leave() {
        objectPath.pop()
      }
    }
  })

  return { queries, fragments }
}

export const getQueryShapes: (
  AST: DocumentNode
) => Record<string, ASTShapeNode> = AST => {
  const { queries, fragments } = getASTShapes(AST)

  return reduce(
    queries,
    (acc, query, key) => ({
      ...acc,
      [key]: mergeFragments(fragments)(query)
    }),
    {}
  )
}

export const mergeFragments: (
  fragments: ASTShape["fragments"]
) => (shape: ASTShapeNode) => ASTShapeNode = fragments => shape => {
  const _mergeFragments = (shape: ASTShapeNode) =>
    // An empty object has no keys, so it returns the initial accumulator value
    reduce(
      // @ts-ignore
      shape,
      (acc, value, key) => {
        if (fragmentPlaceholder === value) {
          return fragments[key]
            ? { ...acc, ..._mergeFragments(fragments[key]) }
            : { ...acc, [key]: fragmentNotFoundPlaceholder }
        } else {
          return { ...acc, [key]: _mergeFragments(value) }
        }
      },
      {}
    )

  return _mergeFragments(shape)
}

const dateValidator = value => {
  if (null === value) return true
  if ("number" === typeof value) return false
  if ("bigint" === typeof value) return false
  if (Array.isArray(value)) return false
  if (isObject(value) && !(value instanceof Date) && 0 === keys(value).length)
    return false
  return moment(value).isValid()
}
export const ScalarToYupMap = {
  Boolean: Yup.boolean().nullable().strict(true),
  Date: Yup.mixed()
    // eslint-disable-next-line no-template-curly-in-string
    .test("moment-date-test", "${path} is not a valid date", dateValidator)
    .nullable()
    .strict(true),
  DateTime: Yup.mixed()
    // eslint-disable-next-line no-template-curly-in-string
    .test("moment-date-test", "${path} is not a valid date", dateValidator)
    .nullable()
    .strict(true),
  Float: Yup.number().nullable().strict(true),
  ID: Yup.string().nullable().strict(true),
  Int: Yup.number().integer().nullable().strict(true),
  String: Yup.string().nullable().strict(true),
  Upload: Yup.mixed().nullable(),
  URL: Yup.string().url().nullable().strict(true),
  // string().uuid() not released yet - https://github.com/jquense/yup/issues/954
  UUID: Yup.string().nullable().strict(true)
}

export const enumToYup = (introspection: IntrospectionQuery) => (
  name: string
) => {
  const {
    __schema: { types }
  } = introspection

  const node = find(types, { name }) as IntrospectionEnumType
  if (!node)
    return Yup.mixed().test("nope", `enum ${name} not found`, () => false)

  return Yup.mixed().oneOf([null, ...map(node?.enumValues, "name")])
}

export const shapeReducer = (fn: (type: any) => any) => (
  fieldSet: Array<{ type: any; name: string; [key: string]: any }>
) =>
  reduce(
    fieldSet,
    (acc, field) => ({
      ...acc,
      [field.name]: fn(field.type)
    }),
    {} as { [key: string]: any }
  )

export const typeToYup = (introspection: IntrospectionQuery) => (
  type: IntrospectionTypeRef | IntrospectionType
) => {
  const _enumToYup = enumToYup(introspection)
  const _typeToYup = typeToYup(introspection)
  const _shapeReducer = shapeReducer(_typeToYup)

  switch (type.kind) {
    case TypeKind.ENUM:
      return _enumToYup(type.name)
    case TypeKind.INPUT_OBJECT:
      // @ts-ignore
      if (type.inputFields) {
        return (
          Yup.object()
            // @ts-ignore
            .shape(_shapeReducer(type.inputFields))
            .nullable()
            .strict(true)
        )
      } else {
        const {
          __schema: { types }
        } = introspection

        const node = find(types, { name: type.name })
        // @ts-ignore
        return Yup.object().shape(_shapeReducer(node.inputFields))
      }
    case TypeKind.LIST:
      return Yup.array(_typeToYup(type.ofType)).nullable().strict(true)
    case TypeKind.NON_NULL:
      return _typeToYup(type.ofType).required()
    case TypeKind.OBJECT:
      // @ts-ignore
      if (type.fields) {
        return (
          Yup.object()
            // @ts-ignore
            .shape(_shapeReducer(type.fields))
            .nullable()
            .strict(true)
        )
      } else {
        const {
          __schema: { types }
        } = introspection

        const node = find(types, { name: type.name })
        // @ts-ignore
        return Yup.object().shape(_shapeReducer(node.fields))
      }
    case TypeKind.SCALAR:
      return (
        ScalarToYupMap[type.name] ??
        (console.warn(`Scalar ${type.name} not found`), Yup.mixed())
      )
    default:
      return Yup.mixed()
  }
}

export const typeToYupShape = (introspection: IntrospectionQuery) => (
  type: IntrospectionTypeRef | IntrospectionType
) => {
  const _typeToYupShape = typeToYupShape(introspection)
  const _shapeReducer = shapeReducer(_typeToYupShape)
  switch (type.kind) {
    case TypeKind.INPUT_OBJECT:
      // @ts-ignore
      if (type.inputFields) {
        // @ts-ignore
        return Yup.object().shape(_shapeReducer(type.inputFields))
      } else {
        const {
          __schema: { types }
        } = introspection

        const node = find(types, { name: type.name })
        // @ts-ignore
        return Yup.object().shape(_shapeReducer(node.inputFields))
      }
    case TypeKind.LIST:
      return Yup.array(_typeToYupShape(type.ofType))
    case TypeKind.NON_NULL:
      return _typeToYupShape(type.ofType).required()
    case TypeKind.OBJECT:
      // @ts-ignore
      if (type.fields) {
        // @ts-ignore
        return Yup.object().shape(_shapeReducer(type.fields))
      } else {
        const {
          __schema: { types }
        } = introspection

        const node = find(types, { name: type.name })
        // @ts-ignore
        return Yup.object().shape(_shapeReducer(node.fields))
      }
    default:
      return Yup.mixed()
  }
}

export const introspectionToYup = (introspection: IntrospectionQuery) => (
  name: string
) => {
  const {
    __schema: { types }
  } = introspection

  const node = find(types, { name })
  if (node === undefined) return Yup.mixed()

  return typeToYup(introspection)(node)
}

export const introspectionToYupShape = (introspection: IntrospectionQuery) => (
  name: string
) => {
  const {
    __schema: { types }
  } = introspection

  const node = find(types, { name })
  if (node === undefined) return Yup.mixed()

  return typeToYupShape(introspection)(node)
}

export const getYupSchema = introspectionToYup(
  (introspection as unknown) as IntrospectionQuery
)

export const getYupShape = introspectionToYupShape(
  (introspection as unknown) as IntrospectionQuery
)

// Yup.mixed().cast() will add an object with a shape if an object declared in a
// schema isn't present in the data being validated. From the look of the tests,
// that's intentional: https://github.com/jquense/yup/blob/master/test/object.js#L281
// This causes issues with our API filtering because the presence of some fields,
// even if the values are null, triggers unwanted filtering with API-determined
// default values
export const stripEmptyFieldsFromObject = (object: Record<string, any>) =>
  reduce(
    object,
    (acc, value, key) => {
      // Empty object case -- remove
      // Currently assuming all values to check will be object literals, not classes
      // with potential toString overrides
      const isObject =
        "[object Object]" === Object.prototype.toString.call(value)
      if (isObject && 0 === Object.keys(value).length) {
        return acc
      }
      // Object with keys case -- check if keys are there
      if (isObject && 0 < Object.keys(value).length) {
        const strippedObject = stripEmptyFieldsFromObject(value)
        return Object.keys(strippedObject).length
          ? { ...acc, [key]: strippedObject }
          : acc
      }
      // Undefined case, to catch when Yup adds missing object shapes when casting
      if (value === undefined) return acc
      // Non object case -- keep
      return { ...acc, [key]: value }
    },
    {}
  )
