import { IntlProvider } from "react-intl"
import { useRoutes } from "react-router-dom"
import { LicenseInfo } from "@mui/x-data-grid-pro"

import ThemeProvider from "theme"
import ApolloProvider from "./client"
import routes from "./routes"

import withSentryErrorBoundary from "components/Sentry/withSentryErrorBoundary"
import CrashCard from "components/CrashCard"

LicenseInfo.setLicenseKey(
  process.env.REACT_APP_DATAGRID_LIC ??
    "8c5209e1b1c2bb333ba9b90cf0c95a23T1JERVI6MjY0NjIsRVhQSVJZPTE2NTYyNTY4MTMwMDAsS0VZVkVSU0lPTj0x"
)

function App() {
  const element = useRoutes(routes)
  return (
    <ApolloProvider>
      <IntlProvider locale={"en-US"} defaultLocale={"en-US"}>
        <ThemeProvider>{element}</ThemeProvider>
      </IntlProvider>
    </ApolloProvider>
  )
}

export default withSentryErrorBoundary(App, CrashCard)
