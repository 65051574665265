import {
  TextField as FormikTextField,
  TextFieldProps
} from "formik-material-ui"
import defaultProps from "recompose/defaultProps"
import compact from "lodash/compact"
import set from "lodash/set"
import produce from "immer"

import { useFormStateContext } from "hooks/forms/useFormState"

export const defaultTextFieldProps: Pick<
  TextFieldProps,
  "fullWidth" | "variant" | "inputProps"
> = {
  fullWidth: true,
  variant: "outlined",
  inputProps: {
    autoComplete: "new-password" // This disables autcomplete in modern browsers
  }
}

export const textInputProps = defaultProps<Partial<TextFieldProps>>(
  defaultTextFieldProps
)

const TextField: React.FunctionComponent<TextFieldProps> = props => {
  // useRef() + immer v8 === crash
  // The IMAskMixin inputRef is an older style function ref for a class component,
  // so it still worked without the change.
  const { inputRef, ...otherProps } = props
  const fieldName = props.field.name

  const {
    clearError,
    getError,
    _default: noFormStateContext
  } = useFormStateContext()

  // Normally we'd clone to prevent unexpected mutations to props, but that crashes
  // the app sometimes :-S
  const newProps = produce(otherProps, draft => {
    // hack to prevent null values from GraphQL from causing problems w/ Material UI
    if (null == draft.field.value) draft.field.value = ""

    if (!noFormStateContext) {
      if (undefined === draft.inputProps) draft.inputProps = {}
      if (undefined === draft.inputProps.onBlur) {
        draft.inputProps.onBlur = () => {
          clearError(fieldName)
        }
      } else {
        draft.inputProps.onBlur = (...args) => {
          clearError(fieldName)
          props.inputProps!.onBlur?.(...args)
        }
      }
    }

    // Merging back-end errors at the component level to allow forms to be resubmitted
    // even if back-end errors haven't been cleared.
    const backEndError = getError(fieldName)
    if (backEndError) {
      const frontEndValidationErrors = draft.form.errors?.[fieldName]
      const combinedValidationErrors = compact([
        frontEndValidationErrors,
        backEndError
      ]).join("\n")

      // Use set to handle nested paths correctly
      set(draft.form.errors, fieldName, combinedValidationErrors)
      set(draft.form.touched, fieldName, true)
    }
  })

  return <FormikTextField inputRef={inputRef} {...newProps} />
}

export default textInputProps(TextField)
