import * as Sentry from "@sentry/react"

import useUser from "hooks/useUser"

export interface IFallbackProps {
  error: Error
  componentStack: string | null
  eventId: string | null
  resetError(): void
}

function withSentryErrorBoundary<P extends Record<string, any>>(
  WrappedComponent: React.ComponentType<P>,
  Fallback: React.ComponentType<IFallbackProps>
): React.FC<P> {
  const componentDisplayName =
    WrappedComponent.displayName || WrappedComponent.name || "unknown"

  const Wrapped: React.FC<P> = props => {
    const user = useUser()

    return (
      <Sentry.ErrorBoundary
        showDialog
        fallback={props => <Fallback {...props} />}
        dialogOptions={{
          user: {
            email: user?.email,
            name: user?.displayName
          }
        }}
      >
        <WrappedComponent {...props} />
      </Sentry.ErrorBoundary>
    )
  }

  Wrapped.displayName = `errorBoundary(${componentDisplayName})`

  return Wrapped
}

export default withSentryErrorBoundary
