import { Grid } from "@material-ui/core"
import castArray from "lodash/castArray"
import filter from "lodash/filter"
import includes from "lodash/includes"
import some from "lodash/some"
import uniqueId from "lodash/uniqueId"
import { ConditionalPick } from "type-fest"
import {
  CardDisplayStyle,
  DetailsCard,
  PlaceholderCard
} from "components/Attachments"
import { Document, Scalars } from "client/types"

// Defining an enum of names so that the useAttachments hook has a way to know
// which handlers passed in are handlers for the preview area
export enum PreviewHandlerNames {
  onDelete = "onDelete",
  onImgClick = "onImgClick"
}
// These props can be set via props sent to <Attachments />
export enum SettablePreviewPropNames {
  display = "display",
  disablePreviewDelete = "disablePreviewDelete",
  filterByLabel = "filterByLabel"
}

export type InternalPreviewProps = {
  display?: CardDisplayStyle
  disablePreviewDelete?: boolean
  filterByLabel?: Array<string> | string
} & {
  [P in PreviewHandlerNames]?: (id: Scalars["UUID"], event: Event) => void
}

export type PreviewHandlers = ConditionalPick<
  InternalPreviewProps,
  Function | undefined
>

interface IDropZonePreviewProps {
  documents: Array<Document>
  previewProps?: InternalPreviewProps
  previewHandlers?: PreviewHandlers
}

export const dataTestid = "preview"
const DropZonePreview: React.FunctionComponent<IDropZonePreviewProps> = ({
  documents: _documents,
  previewProps: _previewProps = {} as InternalPreviewProps,
  previewHandlers
}) => {
  const { filterByLabel: _filterByLabel = "", ...previewProps } = _previewProps
  const filterByLabel = castArray(_filterByLabel)

  const documents = filter(_documents, doc =>
    some(filterByLabel, filter => includes(doc.label, filter))
  )

  return (
    <Grid container spacing={2} data-testid={dataTestid}>
      {documents.length > 0 ? (
        documents.map(details => {
          const { uuid } = details
          return (
            <DetailsCard
              key={uuid ?? uniqueId()}
              {...details}
              {...previewProps}
              {...previewHandlers}
            />
          )
        })
      ) : (
        <PlaceholderCard display={previewProps?.display} />
      )}
    </Grid>
  )
}

export default DropZonePreview
