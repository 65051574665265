import { gql } from "@apollo/client"
import inventory from "fragments/inventory"
import priceRule from "fragments/priceRule"

const chargeFragments = {
  baseCharge: gql`
    fragment BaseCharge on Charge {
      id
      deleted
      item {
        ...BaseInventoryItem
      }
      quantity
      price
      suggestedPrice
      total
      priceState
      ticketId
      created
      note
      priceRule {
        ...SmallPriceRule
      }
      fromRequiredCharge
    }
    ${inventory.baseInventoryItem}
    ${priceRule.smallPriceRule}
  `
}

export default chargeFragments
