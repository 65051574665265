import { colors } from "@material-ui/core"
import createPalette from "@material-ui/core/styles/createPalette"

import {
  atlasMaroon,
  blue,
  gold,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  red
} from "./colors"

export default createPalette({
  type: "light",
  // @ts-ignore
  atlasMaroon,
  action: {
    active: grey[500],
    selected: grey[400],
    disabled: grey[200],
    disabledBackground: grey[50]
  },
  // @ts-ignore
  chip: {
    text: colors.blueGrey[900],
    background: colors.blueGrey[50],
    deleteableBackground: colors.blueGrey[100]
  },
  divider: grey[200],
  error: {
    main: red[500]
  },
  icon: grey[950],
  info: {
    main: lightBlue
  },
  primary: {
    main: blue[500]
  },
  secondary: {
    main: indigo[500]
  },
  success: {
    light: lightGreen,
    main: green[500]
  },
  text: {
    primary: grey[900],
    secondary: grey[800],
    disabled: grey[700]
  },
  warning: {
    main: gold
  },
  table: {
    border: grey[200]
  }
})
