import Auth from "@aws-amplify/auth"
// import { Badge, colors, IconButton, Theme } from "@material-ui/core"
import { colors, Theme } from "@material-ui/core"
import InputIcon from "@material-ui/icons/Input"
// import NotificationsIcon from "@material-ui/icons/NotificationsOutlined"
import { makeStyles } from "@material-ui/styles"
// import { NotificationsPopover } from "components"
// import {
//   bindPopover,
//   bindTrigger,
//   usePopupState
// } from "material-ui-popup-state/hooks"
import DarkModeToggle from "./DarkModeToggle"
import { useOnlineContext } from "context/OnlineContext"
// import useNotificationState from "hooks/useNotificationState"
import { Button } from "components"

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: "flex",
    alignItems: "center"
  },
  notificationsButton: {},
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logoutButton: {
    marginLeft: "auto"
  }
}))

// TODO: Re-enable notifications tracking and display, while also fixing
// TI-782.
const NavActions = () => {
  const classes = useStyles()
  // const notificationState = usePopupState({
  //   variant: "popover",
  //   popupId: "notification-menu"
  // })
  // const { online, appOnline } = useOnlineContext()
  const { appOnline } = useOnlineContext()

  // const { notifications, total, markRead } = useNotificationState({
  //   polling: {
  //     polling: Boolean(appOnline) && Boolean(online)
  //   },
  //   variables: {
  //     first: 10,
  //     filter: { read: false }
  //   }
  // })

  const handleLogout = () => {
    Auth.signOut()
  }

  return (
    <>
      <div className={classes.actions} data-testid="nav-actions">
        {/* <IconButton
          className={classes.notificationsButton}
          {...bindTrigger(notificationState)}
          color="inherit"
          size="small"
        >
          <Badge
            badgeContent={total}
            max={99}
            classes={{ badge: classes.notificationsBadge }}
          >
            <NotificationsIcon />
          </Badge>
        </IconButton> */}
        <DarkModeToggle size="small" />
        <Button
          className={classes.logoutButton}
          onClick={handleLogout}
          disabled={!appOnline}
          data-testid="nav-actions-logout"
          endIcon={<InputIcon />}
          condensed
        >
          Sign Out
        </Button>
      </div>
      {/* <NotificationsPopover
        notifications={notifications}
        // @ts-ignore
        markRead={markRead}
        {...bindPopover(notificationState)}
      /> */}
    </>
  )
}

export default NavActions
