import { GraphQLDate, GraphQLDateTime } from "graphql-iso-date"
import GraphQLUUID from "graphql-type-uuid"
import { GET_ALL_SETTINGS } from "queries/userSettings"
import { FileUploadStatus } from "client/types"

// Local resolvers
const resolvers = {
  UUID: GraphQLUUID,
  Date: GraphQLDate,
  DateTime: GraphQLDateTime,
  Document: {
    status: (document, args, { cache }) => {
      return document.status ? document.status : FileUploadStatus.Attached
    },
    errorMessage: (document, args, { cache }) => {
      return document.errorMessage ? document.errorMessage : ""
    }
  },
  Mutation: {
    updateUserSettings: (_, { input }, { cache }) => {
      const previous = cache.readQuery({ query: GET_ALL_SETTINGS }).userSettings
      const newSettings = { ...previous, ...input }
      cache.writeQuery({
        query: GET_ALL_SETTINGS,
        data: { userSettings: newSettings }
      })
      return newSettings
    }
  }
}

export default resolvers
