import MuiAlert from "./MuiAlert"
import MuiButton from "./MuiButton"
import MuiBreadcrumbs from "./MuiBreadcrumbs"
import MuiCardActions from "./MuiCardActions"
import MuiCardContent from "./MuiCardContent"
import MuiCardHeader from "./MuiCardHeader"
import MuiChip from "./MuiChip"
import MuiDialogActions from "./MuiDialogActions"
import MuiDialogContent from "./MuiDialogContent"
import MuiFormLabel from "./MuiFormLabel"
import MuiIconButton from "./MuiIconButton"
import MuiInputLabel from "./MuiInputLabel"
import MuiInputBase from "./MuiInputBase"
import MuiLinearProgress from "./MuiLinearProgress"
import MuiListItem from "./MuiListItem"
import MuiListItemIcon from "./MuiListItemIcon"
import MuiOutlinedInput from "./MuiOutlinedInput"
import MuiPaper from "./MuiPaper"
import MuiTableHead from "./MuiTableHead"
import MuiTableCell from "./MuiTableCell"
import MuiTableRow from "./MuiTableRow"
import MuiDataGrid from "./MuiDataGrid"
import MuiTabs from "./MuiTabs"
import MuiToggleButton from "./MuiToggleButton"
import MuiTypography from "./MuiTypography"

const overrides = (palette, typography) => ({
  MuiAlert: MuiAlert(palette, typography),
  MuiButton: MuiButton(palette, typography),
  MuiBreadcrumbs: MuiBreadcrumbs(palette, typography),
  MuiCardActions: MuiCardActions(palette, typography),
  MuiCardContent: MuiCardContent(palette, typography),
  MuiCardHeader: MuiCardHeader(palette, typography),
  MuiChip: MuiChip(palette, typography),
  MuiDialogActions: MuiDialogActions(palette, typography),
  MuiDialogContent: MuiDialogContent(palette, typography),
  MuiFormLabel: MuiFormLabel(palette, typography),
  MuiIconButton: MuiIconButton(palette, typography),
  MuiInputBase: MuiInputBase(palette, typography),
  MuiInputLabel: MuiInputLabel(palette, typography),
  MuiLinearProgress: MuiLinearProgress(palette, typography),
  MuiListItem: MuiListItem(palette, typography),
  MuiListItemIcon: MuiListItemIcon(palette, typography),
  MuiOutlinedInput: MuiOutlinedInput(palette, typography),
  MuiPaper: MuiPaper(palette, typography),
  MuiTabs: MuiTabs(palette, typography),
  MuiTableHead: MuiTableHead(palette, typography),
  MuiTableCell: MuiTableCell(palette, typography),
  MuiTableRow: MuiTableRow(palette, typography),
  MuiDataGrid: MuiDataGrid(palette, typography),
  MuiToggleButton: MuiToggleButton(palette, typography),
  MuiTypography: MuiTypography(palette, typography)
})

export default overrides
