import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import enums from "client/enums"
import { SignatureStates } from "client/types"
import clsx from "clsx"
import { blue, green, orange, red } from "theme/palettes/colors"
import { labelColors } from "./index"

interface ISignatureStatusLabelProps {
  status?: SignatureStates
  condensed?: boolean
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 0,
    flexShrink: 0,
    borderRadius: theme.shape.borderRadius,
    whiteSpace: "nowrap",
    fontWeight: "bold"
  },
  normal: {
    padding: theme.spacing(0, 1.5),
    height: 40
  },
  condensed: {
    padding: theme.spacing(0, 0.5),
    height: 20,
    textTransform: "uppercase"
  },
  [SignatureStates.Pending]: labelColors(theme, blue[100], blue[500]),
  [SignatureStates.Rejected]: labelColors(theme, red[100], red[500]),
  [SignatureStates.Signed]: labelColors(theme, green[100], green[500]),
  [SignatureStates.Unsigned]: labelColors(theme, orange[100], orange[500])
}))

const SignatureStatusLabel: React.FunctionComponent<ISignatureStatusLabelProps> = ({
  status,
  condensed = false
}) => {
  const classes = useStyles()

  if (undefined === status) return null

  return (
    <Typography
      className={clsx(
        classes.root,
        { [classes.normal]: !condensed, [classes.condensed]: condensed },
        classes[status]
      )}
    >
      {enums.SignatureStates.description(status)}
    </Typography>
  )
}

export default SignatureStatusLabel
