export enum MIMEType {
  pdf = "application/pdf",
  xls = "application/vnd.ms-excel",
  xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
}

// AttachmentTagTypes should only be alphanumeric
export enum AttachmentTagType {
  Base = "BASE",
  Step = "STEP",
  Work = "WORK",
  Print = "PRINT"
}

export enum AttachmentPrintSize {
  Small = "SMALL",
  Full = "FULL"
}

export const createDocumentLabelTag: (
  tagType: AttachmentTagType
) => (identifier?: string) => string = tagType => identifier =>
  identifier ? `[${tagType}-${identifier}]:` : `[${tagType}]:`

export const createBaseLabelTag = createDocumentLabelTag(AttachmentTagType.Base)
export const createStepLabelTag = createDocumentLabelTag(AttachmentTagType.Step)
export const createWorkLabelTag = createDocumentLabelTag(AttachmentTagType.Work)
export const createPrintLabelTag = createDocumentLabelTag(
  AttachmentTagType.Print
)

export const createSearchTagForType: (
  tagType: AttachmentTagType
) => string = tagType => `[${tagType}`
export const searchForBaseTag = createSearchTagForType(AttachmentTagType.Base)
export const searchForStepTag = createSearchTagForType(AttachmentTagType.Step)
export const searchForWorkTag = createSearchTagForType(AttachmentTagType.Work)
export const searchForPrintTag = createSearchTagForType(AttachmentTagType.Print)

export const getMetaTags: (
  label: string,
  acc?: Array<string>
) => Array<string> = (label, acc = []) => {
  const regex = /^\[[^\]]*]:/
  const result = regex.exec(label)
  if (result === null) return acc

  return getMetaTags(label.substring(result[0].length), [...acc, result[0]])
}

export const getTagIdentifiers: (
  label: string
) => Array<string | null> = label => {
  const tags = getMetaTags(label)
  const regex = /^\[[A-Za-z0-9]+-(.+)]:/
  return tags.map(tag => {
    const result = regex.exec(tag)
    return result?.[1] ?? null
  })
}

export const getTagTypes: (label: string) => Array<string | null> = label => {
  const tags = getMetaTags(label)
  const regex = /^\[([A-Za-z0-9]+)(-.*)?]:/
  return tags.map(tag => {
    const result = regex.exec(tag)
    return result?.[1] ?? null
  })
}

export const stripMetaTags: (label: string) => string = label => {
  const regex = /^\[[^\]]*]:/
  const result = regex.exec(label)
  if (result === null) return label

  return stripMetaTags(label.substring(result[0].length))
}
