import { gql } from "@apollo/client"

const priceRuleFragments = {
  smallPriceRule: gql`
    fragment SmallPriceRule on PriceRule {
      id
      company {
        id
      }
    }
  `,
  basePriceRule: gql`
    fragment BasePriceRule on PriceRule {
      id
      created
      modified
      item {
        id
      }
      company {
        id
      }
      price
    }
  `
}

export default priceRuleFragments
