import { ServiceTicketInput, Company, Maybe, WellInput } from "client/types"
import { CreateWellData } from "components/forms/CreateWellForm"
import { PhaseMap } from "hooks/graphql"

export type CreateTicketData = Partial<
  ServiceTicketInput & {
    createNewWell: boolean
    createWell: Partial<CreateWellData>
    well: Maybe<WellInput>
    company: Maybe<Company>
    phaseMap: PhaseMap
    isInitialStateStable?: boolean
  }
>

// Using this as the base for the create ticket form lets formik
// correctly detect dirty vs non-dirty states. Because the modal
// uses that to enable/disable the CREATE TICKET button, that's
// important.
export const defaultCreateTicketValues: CreateTicketData = {
  createNewWell: false,
  company: null,
  companyMan: "",
  companyManPhone: "",
  crewName: "",
  jobDescription: "",
  phase: null,
  procedureRevisionId: null,
  rigName: "",
  scheduledArrivalTime: null,
  spareParts: "",
  type: null,
  well: null
  // This get sets automatically via Yup Validation, and setting it here
  // causes issues w/ validation
  // workRequestDate: null
}

export const createNewWellOption: CreateWellData = {
  id: "__CREATE_NEW_WELL__",
  name: "Create New Well"
}
