import Grid, { GridProps } from "@material-ui/core/Grid"
import {
  AutocompleteField,
  DateField,
  DateTimeField,
  NumberField,
  PhoneNumberField,
  SelectField,
  SwitchField,
  TagSelectField,
  TextField
} from "components/formik"
import { FastField, useFormikContext } from "formik"
import intersection from "lodash/intersection"
import {
  ComponentMap,
  CustomFormInputProps,
  Formik,
  FormInputProps,
  FormikFormInputProps
} from "./FormInput.d"
import { parseDataProps as _parseDataProps } from "../utils/utils"

const formikInputComponent: ComponentMap = {
  [Formik.Autocomplete]: AutocompleteField,
  [Formik.Date]: DateField,
  [Formik.DateTime]: DateTimeField,
  [Formik.Number]: NumberField,
  [Formik.PhoneNumber]: PhoneNumberField,
  [Formik.Select]: SelectField,
  [Formik.Switch]: SwitchField,
  [Formik.TagSelect]: TagSelectField,
  [Formik.Text]: TextField
}

export const defaultWidths: GridProps = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 3
}

const sizeKeys = [`xs`, `sm`, `md`, `lg`, `xl`]

const withGridItem: React.ComponentType<any> = (
  Component: React.ComponentType<any>,
  { gridOptions, ...props }
) => (
  <Grid item {...gridOptions}>
    <Component {...props} />
  </Grid>
)

const getComponentType = type => {
  if (Object.values(Formik).includes(type)) return `formik`
  return `custom`
}

const FormInput: React.ComponentType<FormInputProps> = ({
  name,
  label,
  grid = true,
  type,
  props: inputProps = {},
  data = [],
  ...props
}) => {
  // overwrite all widths if there's any width information present in grid
  const gridOptions: GridProps =
    `object` === typeof grid
      ? intersection(Object.keys(grid), sizeKeys).length > 0
        ? { ...grid }
        : { ...grid, ...defaultWidths }
      : { ...defaultWidths }

  const componentType = getComponentType(type)
  const formik = useFormikContext()
  const parseDataProps = _parseDataProps({ formik })

  const dynamicInputProps = parseDataProps(data)
  const parsedInputProps = {
    ...inputProps,
    ...dynamicInputProps
  }

  let Component
  switch (componentType) {
    case `custom`:
    default:
      Component = (props as CustomFormInputProps).Component
      return grid ? (
        withGridItem(Component, {
          gridOptions,
          name,
          label,
          ...parsedInputProps
        })
      ) : (
        <Component name={name} label={label} {...parsedInputProps} />
      )
    case `formik`:
      Component = formikInputComponent[type]
      const FieldComponent =
        (props as FormikFormInputProps).FieldComponent ?? FastField
      return grid ? (
        withGridItem(FieldComponent, {
          gridOptions,
          name,
          label,
          component: Component,
          ...parsedInputProps
        })
      ) : (
        <FieldComponent
          name={name}
          label={label}
          component={Component}
          {...parsedInputProps}
        />
      )
  }
}

export default FormInput
