import { createContext, useEffect, useMemo, useState } from "react"
import {
  GetWaffleBitsQuery,
  GetWaffleBitsQueryVariables,
  WaffleItem
} from "client/types"
import { Maybe } from "graphql/jsutils/Maybe"
import { GET_WAFFLE_BITS } from "queries/waffle"
import camelCase from "lodash/camelCase"
import { usePollingState, UsePollingStateArgs } from "hooks/graphql"
import { useSmartPollingQuery } from "hooks/graphql"

class ExtendableProxy {
  constructor(defaultValue) {
    return new Proxy(this, {
      get: (object, key, proxy) => {
        return object[key] ?? defaultValue
      }
      // No setting from accessor for now, keep in case of other uses
      // set: (object, key, value, proxy) => {
      //   object[key] = value
      //   return true
      // }
    })
  }
}

class BitAccessor extends ExtendableProxy {
  [x: string]: boolean
  constructor(bits: Array<Maybe<Partial<WaffleItem>>>, defaultValue: boolean) {
    super(defaultValue)
    bits.forEach(b => {
      if (b && b.name) {
        const name = camelCase(b.name)
        if (name in this) {
          // Colissions with methods/properties on proxy or class throw an error
          throw new Error(`${name} already exists on Waffle accessor`)
        }
        this[name] = b.active ?? defaultValue
      }
    })
  }
}

export type WaffleValueType = {
  setPolling: (on: boolean, interval: number) => void
  check: () => void
  flags: BitAccessor
  switches: BitAccessor
  samples: BitAccessor
}

const emptyAccessor = new BitAccessor([], false)

const defaultCtx: WaffleValueType = {
  setPolling(on: boolean, interval: number) {},
  check() {},
  flags: emptyAccessor,
  switches: emptyAccessor,
  samples: emptyAccessor
}

const WaffleContext = createContext<WaffleValueType>(defaultCtx)

export type WaffleProviderProps = UsePollingStateArgs & {
  children: React.ReactNode
}

// It looks like the Apollo-based loading flag gets set to false before the data
// is available via setBits, so this ready flag gives consumers an accurate way
// to know when the waffle data is actually available. Not using a setReady() call
// along with setBits() because calls to useState setters are not guaranteed to
// set their data/trigger rerenders in the order they are called.
const readyFlag: WaffleItem = {
  id: "FRONT_END_READY",
  name: "ready",
  active: true
}

export const WaffleProvider: React.FC<WaffleProviderProps> = ({
  polling = false,
  pollInterval = 5 * 60 * 1000, // five minute polling
  children
}) => {
  const { setPolling, on, interval } = usePollingState({
    polling,
    pollInterval
  })

  const [bits, setBits] = useState({
    flags: emptyAccessor,
    switches: emptyAccessor,
    samples: emptyAccessor
  })

  const { data, refetch } = useSmartPollingQuery<
    GetWaffleBitsQuery,
    GetWaffleBitsQueryVariables
  >({
    query: GET_WAFFLE_BITS,
    on,
    interval,
    enableOffline: true
  })

  useEffect(() => {
    if (!data) return

    const {
      flags,
      flagDefault,
      switches,
      switchDefault,
      samples,
      sampleDefault
    } = data.waffle ?? {}

    setBits({
      flags: new BitAccessor(
        [...(flags ?? []), readyFlag],
        flagDefault ?? false
      ),
      switches: new BitAccessor(switches ?? [], switchDefault ?? false),
      samples: new BitAccessor(samples ?? [], sampleDefault ?? false)
    })
  }, [data])

  // Including loading and polling causes component tree rerendering every time they
  // change, which can reset formik data back to the initial values, particularly in
  // dialogs. The app doesn't currently use that info, so it was removed.
  const waffle = useMemo(
    () =>
      ({
        setPolling,
        check: () => refetch(),
        ...bits
      } as WaffleValueType),
    [setPolling, refetch, bits]
  )

  return (
    <WaffleContext.Provider value={waffle}>{children}</WaffleContext.Provider>
  )
}

export default WaffleContext
