// In order to effectively mock uuid for testing purposes, useAttachments has been
// moved into this file to get around the one-level deep limitation on mocking imports
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { Category } from "client/types"

import DropZone, { InternalDropZoneProps } from "./components/DropZone"
import Preview, { InternalPreviewProps } from "./components/Preview"

import {
  AttachmentsContextType,
  CabinetProps,
  ContentDescriptionMap,
  UseAttachmentsArgs
} from "./hooks/useAttachments"
import useAttachments, { AttachmentsProvider } from "./hooks/useAttachments"

export type AttachmentsProps = InternalDropZoneProps &
  InternalPreviewProps &
  CabinetProps & {
    category: Category
    accept?: ContentDescriptionMap
    collapsed?: boolean
    dropZoneHeader?: string
    filePreviewHeader?: string
    showFilePreview?: boolean
    useAttachmentsHook?: (args: UseAttachmentsArgs) => AttachmentsContextType
    labelOverride?: (filename: string) => string | string
  }

const useStyles = makeStyles(theme => ({
  root: {},
  section: {
    marginBottom: theme.spacing(3)
  }
}))

export const dataTestid = "attachments"
const Attachments: React.FunctionComponent<AttachmentsProps> = ({
  accept,
  collapsed = false,
  dropZoneHeader = "Attach Files",
  filePreviewHeader = "Attached Files",
  showFilePreview = false,
  cabinetId,
  cabinetLabel,
  useAttachmentsHook = useAttachments,
  category,
  labelOverride,
  documentFilter,
  ...props
}) => {
  const classes = useStyles()

  const attachments = useAttachmentsHook({
    accept,
    propsAndHandlers: props,
    cabinetId,
    cabinetLabel,
    category,
    labelOverride,
    documentFilter
  })

  return (
    <AttachmentsProvider value={attachments}>
      <section className={classes.root} data-testid={dataTestid}>
        <section className={classes.section}>
          <header>
            <Typography variant="h4" gutterBottom>
              {dropZoneHeader}
            </Typography>
          </header>
          <DropZone />
        </section>
        {showFilePreview && (
          <section className={classes.section}>
            <header>
              <Typography variant="h4" gutterBottom>
                {filePreviewHeader}
              </Typography>
            </header>
            <Preview />
          </section>
        )}
      </section>
    </AttachmentsProvider>
  )
}

export default Attachments
