import { gql } from "@apollo/client"

const basinsFragments = {
  basinOption: gql`
    fragment BasinOption on Basin {
      id
      name
    }
  `
}

export default basinsFragments
