import { gql } from "@apollo/client"

const fragments = {
  lastActiveTicket: gql`
    fragment LastActiveTicket on TicketInterface {
      number
      ... on ServiceTicket {
        type
        phase
        scheduledArrivalTime
      }
    }
  `
}

export default fragments
