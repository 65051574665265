const overrides = (palette, typography) => ({
  root: {
    color: palette.icon,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.03)"
    }
  }
})

export default overrides
