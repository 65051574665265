import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography
} from "@material-ui/core"
import fire from "./fire.jpg"

import { IFallbackProps } from "components/Sentry/withSentryErrorBoundary"

const CrashCard: React.FunctionComponent<IFallbackProps> = ({
  error,
  componentStack,
  eventId,
  resetError
}) => {
  const { pathname } = useLocation()

  // Reset the error state when navigating to a new page
  useEffect(() => {
    resetError()
  }, [pathname, resetError])

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
        <Card>
          <CardMedia
            component="img"
            title="Yeah, that's about right"
            image={fire}
          />
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Sorry About That&hellip;
            </Typography>
            <Typography variant="body1" gutterBottom>
              We've logged the crash and are attempting to send relevant info
              back to our systems. If that broke too, you might need to copy the
              following information and email it to support.
            </Typography>
            <Typography variant="body1" gutterBottom>
              If this continues to happen, please remember to fill out the
              incident report that should pop up onscreen if you have an
              Internet connection.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Error: <strong>{error.toString()}</strong>
            </Typography>
            <Typography variant="body1" gutterBottom>
              Sentry Event Id: <strong>{eventId}</strong>
            </Typography>
            <Typography variant="body1" gutterBottom>
              Component Stack: <strong>{componentStack}</strong>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default CrashCard
