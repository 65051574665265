import { Dispatch, SetStateAction, useMemo } from "react"
import { useApolloClient } from "@apollo/client"
import { Form, useFormikContext } from "formik"
import { Grid, GridProps } from "@material-ui/core"
import isEmpty from "lodash/isEmpty"
import isEqual from "lodash/isEqual"
import isNil from "lodash/isNil"
import map from "lodash/map"

import enums from "client/enums"
import { Maybe, RunningProcedure, ServiceTicketInput } from "client/types"
import { GET_RUNNING_PROCEDURE_BY_ID } from "queries/runningProcedures"

import FormFactory, {
  DataKinds,
  FormConfig,
  Formik,
  FormSectionKinds
} from "components/FormFactory"
import useInitialFormikValidation from "hooks/forms/useInitialFormikValidation"
import { useFormStateContext } from "hooks/forms/useFormState"

import CompanyInput from "./components/CompanyInput"
import CreateNewWellCard from "./components/CreateNewWellCard"
import RunningProcedureAutocomplete from "components/autocomplete/RunningProcedureAutocomplete"
import WellDetailsWrapper from "./components/WellDetailsWrapper"
import WellInput from "./components/WellInput"

const grid: GridProps = {
  xs: 12,
  md: 6
}

type CreateTicketFormProps = {
  onLookup: Dispatch<SetStateAction<boolean>>
}

const CreateTicketForm: React.ComponentType<CreateTicketFormProps> = ({
  onLookup
}) => {
  const client = useApolloClient()
  const formik = useFormikContext<
    ServiceTicketInput & { procedure?: Maybe<RunningProcedure> }
  >()
  useInitialFormikValidation({ formik, additionalFieldsToTouch: ["procedure"] })
  const { setFormErrors } = useFormStateContext()

  const formConfigs: FormConfig = useMemo(
    () => [
      {
        kind: FormSectionKinds.Flat,
        grid: {
          xs: 12
        },
        contentGrid: true,
        header: "Customer Information",
        fields: [
          {
            name: "well",
            label: "Well",
            type: "custom",
            grid,
            Component: WellInput,
            props: {
              id: "well-input"
            }
          },
          {
            name: "company",
            label: "Company",
            type: "custom",
            grid,
            Component: CompanyInput,
            props: {
              id: "company-input"
            }
          },
          {
            name: "wellDetails",
            label: "Not Used",
            type: "custom",
            grid: { xs: 12 },
            Component: WellDetailsWrapper,
            displayWhen: [
              {
                dataType: DataKinds.Formik,
                dataPath: `well`,
                displayCondition: el =>
                  !isNil(el) && el.id !== `__CREATE_NEW_WELL__`
              }
            ]
          },
          {
            name: "createNewWellCard",
            label: "Not Used",
            type: "custom",
            grid: { xs: 12 },
            Component: CreateNewWellCard,
            displayWhen: [
              {
                dataType: DataKinds.Formik,
                dataPath: `createNewWell`,
                displayCondition: true
              }
            ]
          },
          {
            name: "companyMan",
            label: "Company Man",
            type: Formik.Text,
            grid,
            props: {
              id: "company-man"
            }
          },
          {
            name: "companyManPhone",
            label: "Company Man Phone",
            type: Formik.PhoneNumber,
            grid,
            props: {
              id: "company-man-phone"
            }
          },
          {
            name: "companyManEmail",
            label: "Company Man Email",
            type: Formik.Text,
            grid,
            props: {
              id: "company-man-email",
              inputProps: {
                type: "email"
              }
            }
          }
        ]
      },
      {
        kind: FormSectionKinds.Flat,
        grid: {
          xs: 12
        },
        contentGrid: true,
        header: "Job Information",
        fields: [
          {
            name: "rigName",
            label: "Rig Name",
            type: Formik.Text,
            grid,
            props: {
              id: "rig-name"
            }
          },
          {
            name: "crewName",
            label: "Crew Name / Pump Company",
            type: Formik.Text,
            grid,
            props: {
              id: "crew-name"
            }
          },
          {
            name: "phase",
            label: "Phase",
            type: Formik.Select,
            grid,
            props: {
              id: "ticket-phase",
              options: enums.TicketPhases.options
            }
          },
          {
            name: "type",
            label: "Type",
            type: Formik.Select,
            grid,
            props: {
              id: "ticket-type",
              options: formik => {
                const { phase, phaseMap } = formik.values
                if (!isEmpty(phaseMap) && phase) {
                  return phaseMap[phase]
                }
                return enums.TicketTypes.options
              }
            }
          },
          {
            name: "procedure",
            label: "Running Procedure",
            grid,
            type: Formik.Autocomplete,
            props: {
              id: "running-procedure",
              AutocompleteComponent: RunningProcedureAutocomplete,
              queryVariables: {
                first: 500
              },
              size: "medium",
              onChange: async (_, procedure) => {
                // No change
                if (isEqual(procedure, formik.values.procedure)) return
                if (null === procedure) {
                  formik.setFieldValue("procedure", procedure)
                  return
                }

                // Change to different RP
                // Replicate network-only fetch policy, because this is a new ticket and we need
                // the most recent data.
                onLookup(true)
                const fullProcedure: RunningProcedure = (await client
                  .query({
                    query: GET_RUNNING_PROCEDURE_BY_ID,
                    fetchPolicy: "network-only",
                    variables: {
                      runningProcedureId: procedure.id
                    }
                  })
                  .then(({ data, errors }) => {
                    if (errors) {
                      setFormErrors(map(errors, "message"))
                      return undefined
                    }
                    return data.procedure
                  })
                  .catch(error => {
                    setFormErrors(error.message)
                    return null
                  })) ?? {
                  id: procedure.id,
                  title: procedure.title,
                  description: procedure.description
                }

                formik.setFieldValue("procedure", fullProcedure)
                onLookup(false)
              }
            }
          },
          {
            name: "workRequestDate",
            label: "Work Request Date",
            type: Formik.DateTime,
            grid,
            props: {
              id: "work-request-date"
            }
          },
          {
            name: "scheduledArrivalTime",
            label: "Scheduled Arrival Time",
            type: Formik.DateTime,
            grid,
            props: {
              id: "scheduled-arrival-time"
            }
          },
          {
            name: "jobDescription",
            label: "Job Description and Scope of Work",
            type: Formik.Text,
            grid: {
              xs: 12
            },
            props: {
              id: "job-description",
              multiline: true,
              rows: 3,
              placeholder:
                "This is a full description of the client's work request and any important notes."
            }
          },
          {
            name: "spareParts",
            label: "Spare Products Required",
            type: Formik.Text,
            grid: {
              xs: 12
            },
            props: {
              id: "spare-parts",
              multiline: true,
              rows: 2,
              placeholder: "This is a full list of any required spare products."
            }
          }
        ]
      }
    ],
    [client, formik, onLookup, setFormErrors]
  )

  return (
    <Form id="create-ticket-form">
      <Grid container spacing={2}>
        <FormFactory formConfigs={formConfigs} formik={formik} />
      </Grid>
    </Form>
  )
}

export default CreateTicketForm
