import { gql } from "@apollo/client"

const cityFragments = {
  cityOption: gql`
    fragment CityOption on City {
      id
      name
      county {
        name
      }
      state {
        name
      }
    }
  `
}

export default cityFragments
