import { GridProps, TextFieldProps } from "@material-ui/core"
import { AutocompleteProps } from "@material-ui/lab/Autocomplete"
import {
  DatePickerProps,
  DateTimePickerProps,
  NumberFieldProps,
  SelectFieldProps,
  SwitchFieldProps,
  TagSelectFieldProps
} from "components/formik"
import { DataDefinition } from "../FormSection/FormSection"

export enum DataKinds {
  Formik = "Formik",
  Literal = "Literal"
}

export enum Formik {
  Autocomplete = "Formik.AutoComplete",
  Date = "Formik.Date",
  DateTime = "Formik.DateTime",
  Number = "Formik.Number",
  PhoneNumber = "Formik.PhoneNumber",
  Select = "Formik.Select",
  Switch = "Formik.Switch",
  TagSelect = "Formik.TagSelect",
  Text = "Formik.Text"
}

type GenericFunction = (...args: any[]) => any

export type DisplayCondition<T extends string | number | boolean> =
  | ((el: T) => boolean)
  | T
  | Array<T>

export interface DisplayWhenFormik {
  dataType: DataKinds.Formik
  dataPath: string
  displayCondition: DisplayCondition
}

export interface DisplayWhenLiteral {
  dataType: DataKinds.Literal
  dataPath: any
  displayCondition: DisplayCondition
}

export type DisplayWhen = DisplayWhenFormik | DisplayWhenLiteral

export type FormInputGridOptions = Partial<
  Pick<
    GridProps,
    | "component"
    | "classes"
    | "children"
    | "xs"
    | "sm"
    | "md"
    | "lg"
    | "xl"
    | "zeroMinWidth"
  >
>

export interface FormikAutocompleteProps
  extends Partial<AutocompleteProps<unknown, boolean, boolean, boolean>> {
  AutocompleteComponent: React.ComponentType<any> | null
  required?: boolean
  queryVariables?: Record<string, any>
}

export interface FormikSelectProps extends Partial<SelectFieldProps> {}

export interface FormikSwitchProps extends Partial<SwitchFieldProps> {}
export interface TagOrderSelectFieldProps
  extends Partial<TagSelectFieldProps> {}

export interface FormikMultiRowTextProps extends TextFieldProps {
  multiline: true
  rows: number
  placeholder: string | null
}

export interface FormikDateTimeProps extends Partial<DateTimePickerProps> {}

export interface FormikDateProps extends Partial<DatePickerProps> {}

export interface FormikFormInputProps {
  name: string
  label?: string
  grid?: boolean | FormInputGridOptions
  type: Formik
  displayWhen?: DisplayWhen[]
  data?: DataDefinition[]
  FieldComponent?: React.ComponentType<any>
  props?:
    | TextFieldProps
    | NumberFieldProps
    | FormikAutocompleteProps
    | FormikDateProps
    | FormikDateTimeProps
    | FormikSelectProps
    | FormikMultiRowTextProps
    | FormikSwitchProps
    | TagOrderSelectFieldProps
    | DateTimePickerProps
}

export interface CustomFormInputProps {
  name?: string
  label?: string
  grid?: boolean | FormInputGridOptions
  type: "custom"
  Component: React.ComponentType<any>
  displayWhen?: DisplayWhen[]
  data?: DataDefinition[]
  props?: object
}

export type FormInputProps = FormikFormInputProps | CustomFormInputProps

export interface ComponentMap {
  [key: string]: React.ComponentType<any>
}

export interface PropsGeneratorFunction {
  (data: any): object
}
export interface PropsGeneratorMap {
  [key: string]: PropsGeneratorFunction
}
